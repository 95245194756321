import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SKIP_BACKWARD } from '../../actions/Playback';
import IApplicationState from '../../types/IApplicationState';
import ITransportButtonProps from '../../types/playback/TransportButton';

export function SkipBackButton(props: ITransportButtonProps) {
    const dispatch = useDispatch();
    const { skipBackward } = useSelector(
        (state: IApplicationState) => state.PlaybackStates
    );
    const { mediaId } = useSelector((state: IApplicationState) => state.Media);
    const rewindDispatch = () =>
        dispatch({ type: SKIP_BACKWARD, payload: { mediaId } });

    if (!skipBackward) {
        return <div style={{ display: 'none' }} />;
    }

    return (
        <music-button
            aria-label={skipBackward?.description}
            disabled={false}
            className='hydrated sc-music-button-h'
            onmusicActivate={rewindDispatch}
            variant='primary'
            icon-name='jumpback15'
            icon-only={true}
            size={props.size || 'small'}
        >
            {skipBackward?.description}
        </music-button>
    );
}
