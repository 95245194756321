import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindHandler, dispatchSkyfireMethods } from '../utils';
import ContextMenuButton from './ContextMenuButton';
import * as Styles from './RefinementHeader.scss';
export default function RefinementHeader(props) {
    const dispatch = useDispatch();
    const template = useSelector((state) => state.TemplateStack.currentTemplate);
    useStyles(Styles);
    const onItemSelected = useCallback((methods) => {
        if (template) {
            dispatchSkyfireMethods(dispatch, template, methods);
        }
    }, []);
    const classNames = [
        Styles.refinementWrapper,
        props.classNames || '',
        props.isDisabled ? Styles.invisible : '',
    ].join(' ');
    const buttonClasses = [
        /* inactive */ props.useFilter ? 'glass' : 'outline-refinement',
        /*   active */ props.useFilter ? 'solid' : 'solid-refinement',
    ];
    const { button } = props;
    return (React.createElement("div", { className: classNames, "aria-hidden": props.isDisabled },
        button && (React.createElement("div", { className: Styles.buttonContainer },
            React.createElement("music-button", { key: button.text, variant: "glass", iconName: button.icon, size: "medium", onmusicActivate: bindHandler(onItemSelected, null, button.onItemSelected) }, button.text))),
        React.createElement("music-refinement-bar", { text: props.text, isActive: props.isActive, open: props.isOpened, useFilter: props.useFilter }, props.refinementOptions.map((refinementOption) => {
            if (refinementOption.options) {
                return (React.createElement("span", { className: Styles.refinementOption },
                    React.createElement(ContextMenuButton, { options: refinementOption.options, variant: refinementOption.isActive ? 'solid' : 'glass', isRefinement: true, size: "medium", iconName: "caretdown", text: refinementOption.text, disabled: props.isDisabled })));
            }
            return (React.createElement("music-button", { key: refinementOption.text, className: Styles.refinementOption, variant: refinementOption.isActive ? buttonClasses[1] : buttonClasses[0], refinement: "static", size: props.useFilter ? 'medium' : 'small', onmusicActivate: bindHandler(onItemSelected, null, refinementOption.onItemSelected), disabled: props.isDisabled },
                React.createElement("div", { className: Styles.refinementLabel }, refinementOption.text)));
        }))));
}
