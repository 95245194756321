import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppName, PathChangedEvent, useStateSubscription } from '@amzn/DMWebEventBus';
import MultiSelectBar from '../views/MultiSelectBar';
import { LoadingSpinner } from '../components/LoadingSpinner';
import Navbar from '../components/Navbar';
import SubNavbar from '../components/SubNavbar';
import { SplashScreen } from '../components/SplashScreen';
import { dispatchSkyfireMethods } from '../utils';
import * as chromeStyles from './Chrome.scss';
import SearchSuggestions from './SearchSuggestions';
import View from './View';
export default function Chrome(props) {
    var _a, _b;
    useStyles(chromeStyles);
    const dispatch = useDispatch();
    const { searchBoxInput, searchSuggestionsData, shouldShowSearchSuggestions } = useSelector((state) => state.SearchSuggestions);
    const overlayTemplates = useSelector((state) => state.TemplateStack.overlayTemplates);
    const { innerTemplate, searchBox } = props.template;
    const hasOverlay = overlayTemplates.length > 0;
    const { timestamp } = useSelector((state) => state.InteractionState);
    const { hasSoftRefreshed } = useSelector((state) => state.SoftRefresh, shallowEqual);
    const handleSelected = useCallback((onItemSelected) => dispatchSkyfireMethods(dispatch, props.template, onItemSelected), [dispatch, props.template]);
    useEffect(() => {
        dispatchSkyfireMethods(dispatch, props.template, props.template.onInteraction);
    }, [timestamp]);
    const activeApp = (_b = (_a = useStateSubscription(PathChangedEvent)) === null || _a === void 0 ? void 0 : _a.activeApp) !== null && _b !== void 0 ? _b : AppName.WEBFIRE;
    // Add scroll to the window once we've loaded the splash screen
    // so scrollbars don't appear on top of splash
    useEffect(() => {
        const rootElement = document.documentElement;
        rootElement.style['overflow-y'] =
            activeApp === AppName.WEBFIRE && (innerTemplate === null || innerTemplate === void 0 ? void 0 : innerTemplate.interface) ? 'scroll' : 'auto';
    }, [activeApp, innerTemplate]);
    return (React.createElement("div", { className: chromeStyles.container }, (innerTemplate === null || innerTemplate === void 0 ? void 0 : innerTemplate.interface) ? (React.createElement("div", { className: chromeStyles.innerTemplate },
        !window.amznMusic.isInContainerApp && (React.createElement(Navbar, { template: props.template, searchBox: searchBox })),
        !hasOverlay ? React.createElement(MultiSelectBar, null) : null,
        React.createElement(SubNavbar, { template: props.template }),
        getView())) : hasSoftRefreshed ? (React.createElement(LoadingSpinner, null)) : (React.createElement(SplashScreen, null))));
    function getView() {
        const showSearchSuggestions = !window.amznMusic.isInContainerApp &&
            shouldShowSearchSuggestions &&
            searchSuggestionsData &&
            Boolean(searchBoxInput);
        if (showSearchSuggestions) {
            return (React.createElement(SearchSuggestions, { template: searchSuggestionsData, handleSelected: handleSelected }));
        }
        return React.createElement(View, { template: innerTemplate });
    }
}
