import { channelFor, GetDataEvent, PerformActionEvent } from './utils';

const PLAYBACK_CHANNEL = channelFor('playback');

export interface PlaybackRequest {
  /**
   * @type {string} single mrn used to play a single entity
   */
  mrn?: string;
  /**
   * @type {Array<string>} list of mrns used to play a list of entities. mrnList and mrn should be mutually exclusive.
   */
  mrnList?: Array<string>;
  /**
   * @type {string} mrn used to indicate the starting point of the queue
   */
  selectedMrn?: string;
  sessionId: string;
  initiatedFrom: string;
  startTimeInMs: number;
  isUserInitiated: boolean;
  playMode: string;
}
/**
 * reference: https://code.amazon.com/packages/MusicPlatformCore/blobs/mainline/--/src/commonMain/kotlin/com/amazon/music/platform/playback/state/LoopMode.kt
 */
export enum LoopMode {
  OFF = 'LOOP_OFF',
  ONE = 'LOOP_ONE',
  ALL = 'LOOP_ALL',
}

/**
 * reference: https://code.amazon.com/packages/MusicPlatformCore/blobs/mainline/--/src/commonMain/kotlin/com/amazon/music/platform/playback/state/ShuffleMode.kt
 */
export enum ShuffleMode {
  OFF = 'SHUFFLE_OFF',
  ON = 'SHUFFLE_ON',
}

export interface PlaybackOptions {
  loopMode: string;
  shuffleMode: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MetricsContext {}

export type InitiatePlaybackArgs = {
  playbackRequest: PlaybackRequest;
  playbackOptions: PlaybackOptions;
  metricsContext: MetricsContext;
};

/**
 * reference: https://gitlab.aws.dev/amazonmusic/musicdragonfly/dragonfly/-/blob/main/src/app/nativeModules/Playback/PlaybackStateEvent.types.ts#L19
 */
export interface PlaybackStateEvent {
  playbackState: playbackStateType;
  mrn?: string;
  containerMRN?: string;
  progress?: PlaybackProgress;
  loopMode: string;
  shuffleMode: string;
}

export type playbackStateType = 'WAITING_TO_PLAY' | 'STARTED_PLAYING' | 'PLAYING' | 'PAUSED' | 'ENDED' | 'DETACHED';
/**
 * reference: https://gitlab.aws.dev/amazonmusic/musicdragonfly/dragonfly/-/blob/main/src/app/nativeModules/Playback/PlaybackStateEvent.types.ts#L33
 */

export interface PlaybackProgress {
  /**
   * timestamp (milliseconds since the epoch) of event creation
   */
  timestamp: number;

  /**
   * total track duration at the time of event creation, in milliseconds
   */
  durationMS: number;

  /**
   * buffered amount at the time of event creation, in milliseconds
   */
  bufferedMS: number;

  /**
   * position of the play head at the time of event creation, in milliseconds
   */
  positionMS: number;

  /**
   * playback speed at the time of event creation, where 1.0 is regular speed
   */
  playbackRate: number;
}

/**
 * reference: https://gitlab.aws.dev/amazonmusic/musicdragonfly/dragonfly/-/blob/main/src/app/nativeModules/PlayQueue/NativeQueueEntityMetadata.types.ts#L5
 */
export interface NativeEntityItemMetadata {
  __typename?: 'LiveEvent' | 'PodcastEpisode' | 'Track' | 'Video' | 'Advertisement' | 'Interlude';
  id?: string;
  mrn?: string;
  title?: string;
  duration?: number;
  artworkUrl?: string;
  /**
   * These are being added until we have a more specific GQL/Native/Cache related data
   * sharing solution
   */
  artistName?: string;
  // Adding artist asin for convenience, since the property
  // is already clear this specifies an artist media type
  artistAsin?: string;
  artistMrn?: string;
  albumName?: string;
  // Adding album asin for convenience, since the property
  // is already clear this specifies an artist media type
  albumAsin?: string;
  albumMrn?: string;
  containerName?: string;
  audioQuality?: string;
  rating?: string;
  hasLyrics?: boolean;
  hasXray?: boolean;

  // Ads - but could be generic
  author?: string;
  clickURL?: string;
  blockRef?: string;

  // Podcast
  showTitle?: string;
  showId?: string;
  showMRN?: string;
}

export const InitiatePlaybackEvent = new PerformActionEvent<InitiatePlaybackArgs>({
  channel: PLAYBACK_CHANNEL,
  topic: 'initiatePlayback',
});

export const GetCurrentPlaybackStateEvent: GetDataEvent<PlaybackStateEvent> = {
  channel: PLAYBACK_CHANNEL,
  topic: 'getCurrentPlaybackState',
};

export const PlaybackToggleEvent = new PerformActionEvent<void>({
  channel: PLAYBACK_CHANNEL,
  topic: 'playbackToggle',
});

export const PlayPlaybackEvent = new PerformActionEvent<void>({
  channel: PLAYBACK_CHANNEL,
  topic: 'playPlaybackEvent',
});

export const PausePlaybackEvent = new PerformActionEvent<void>({
  channel: PLAYBACK_CHANNEL,
  topic: 'pausePlaybackEvent',
});

export const PreviousPlaybackEvent = new PerformActionEvent<void>({
  channel: PLAYBACK_CHANNEL,
  topic: 'previousPlayback',
});

export const NextPlaybackEvent = new PerformActionEvent<void>({
  channel: PLAYBACK_CHANNEL,
  topic: 'nextPlayback',
});

export const QueueNextEvent = new PerformActionEvent<string>({
  channel: PLAYBACK_CHANNEL,
  topic: 'queueNext',
});

export const QueueLastEvent = new PerformActionEvent<string>({
  channel: PLAYBACK_CHANNEL,
  topic: 'queueLast',
});

export const UpdatePlaybackStateEvent = new PerformActionEvent<PlaybackStateEvent>({
  channel: PLAYBACK_CHANNEL,
  topic: 'updatePlaybackStateEvent',
});

export const GetCurrentPlayingMetadataEvent: GetDataEvent<NativeEntityItemMetadata> = {
  channel: PLAYBACK_CHANNEL,
  topic: 'getCurrentPlayingMetadataEvent',
};
