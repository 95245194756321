import ISkyfireMethod from './ISkyfireMethod';

export const INVOKE_HTTP = 'InteractionInterface.v1_0.InvokeHttpSkillMethod';
export const INVOKE_HTTP_GLOBAL =
    'InteractionInterface.v1_0.GlobalInvokeHttpSkillMethod';

export default interface ISkyfireUpsellMethod extends ISkyfireMethod {
    readonly interface: typeof INVOKE_HTTP | typeof INVOKE_HTTP_GLOBAL;
    before: ISkyfireMethod[];
    after: ISkyfireMethod[];
    onError: ISkyfireMethod[];
    url: string;
    delay?: number;
}
