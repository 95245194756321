import { PLAYBACK_METRICS_OPERATIONS_CLIENT_INFORMATION } from '../appsync/AppSyncConstants';
import ISkyfireQueue from '../types/ISkyfireQueue';
import { getPodcastPlaybackEnvironment } from '../utils';
import { REPORT_PLAYBACK_METRIC_OPERATION_WEB_SKILL } from '../utils/podcastTargets';
import { SINGLE_THREADED_QUEUE } from '../utils/skyfireContants';

export class PlaybackMetricSetOnReportOperationsMethod {
    public interface: 'InteractionInterface.v1_0.InvokeHttpPostSkillMethod';

    public before: [];

    public after: [];

    public onError: [];

    public url: string = getPodcastPlaybackEnvironment();

    public preset: string = '{}';

    public target: string = REPORT_PLAYBACK_METRIC_OPERATION_WEB_SKILL;

    public queue: ISkyfireQueue = {
        id: 'PLAYBACK_METRICS_SYNC',
        interface: SINGLE_THREADED_QUEUE,
    };

    public forced: boolean = false;

    public clientInformation?: string[] = [
        PLAYBACK_METRICS_OPERATIONS_CLIENT_INFORMATION,
    ];
}
