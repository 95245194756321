import { globals } from '../utils';
import { getInstance as getAudioInstance } from './AudioPlayer';
import { getInstance as getVideoInstance } from './VideoPlayer';
import { getInstance as getSampleInstance } from './SamplePlayer';
import AVPlayer from './AVPlayer';
import { IAVPlayer, IAudioPlayer, IVideoPlayer, ISamplePlayer, ISamplingPlayer } from './interface';
import EmeNotSupportedError from './EmeNotSupportedError';
import SamplingPlayer from './SamplingPlayer';
export { IAVPlayer, IAudioPlayer, IVideoPlayer, ISamplePlayer, ISamplingPlayer, getAudioInstance, getVideoInstance, getSampleInstance, };
let instance;
let sampleInstance;
export async function getInstance() {
    if (!instance) {
        instance = new AVPlayer(getAudioInstance, getVideoInstance);
    }
    // Always return with loaded current Player, so that caller need not to worry about loading
    await instance.loadCurrentPlayer();
    return instance;
}
export async function getSamplingInstance() {
    if (!sampleInstance) {
        sampleInstance = new SamplingPlayer(getSampleInstance);
    }
    // Always return with loaded current Player, so that caller need not to worry about loading
    await sampleInstance.loadCurrentPlayer();
    return sampleInstance;
}
export function getInstanceSync() {
    return instance;
}
let isVideoPlaybackSupportedPromise;
export async function isVideoPlaybackSupported() {
    if (!isVideoPlaybackSupportedPromise) {
        const Theatre = await import(/* webpackChunkName: "theatre" */ '@amzn/Theatrejs');
        const result = Theatre === null || Theatre === void 0 ? void 0 : Theatre.Player.isPlaybackSupported(globals.navigator.userAgent);
        isVideoPlaybackSupportedPromise = result ? Promise.resolve() : Promise.reject();
    }
    return isVideoPlaybackSupportedPromise;
}
let emeCheckLatency;
let isEMESupportedPromise;
/**
 * @throws EmeNotSupportedError
 */
export async function isEMESupported() {
    var _a;
    if (!isEMESupportedPromise) {
        const t0 = window.performance.now();
        const Orchestra = await import(/* webpackChunkName: "orchestra" */ '@amzn/Orchestrajs');
        const { UAParser } = await import(/* webpackChunkName: "metrics" */ 'ua-parser-js');
        const ua = new UAParser(globals.navigator.userAgent);
        const browser = ua.getBrowser();
        const os = ua.getOS();
        const drmInfo = Orchestra === null || Orchestra === void 0 ? void 0 : Orchestra.Player.getBrowserDrmInfo(browser.name, browser.major, os.name);
        const _isEMESupported = drmInfo
            ? Orchestra === null || Orchestra === void 0 ? void 0 : Orchestra.Player.isEMESupported(drmInfo.keySystem, (_a = drmInfo.supportedConfiguration) !== null && _a !== void 0 ? _a : [])
            : Orchestra === null || Orchestra === void 0 ? void 0 : Orchestra.Player.isEMESupported();
        if (_isEMESupported instanceof Promise) {
            isEMESupportedPromise = _isEMESupported.catch(() => Promise.reject(new EmeNotSupportedError()));
        }
        else {
            isEMESupportedPromise = _isEMESupported
                ? Promise.resolve(true)
                : Promise.reject(new EmeNotSupportedError());
        }
        emeCheckLatency = window.performance.now() - t0;
    }
    return isEMESupportedPromise;
}
export const isEMESupportCheckComplete = () => !!isEMESupportedPromise;
export const getEMECheckLatency = () => emeCheckLatency;
export function setPlayerConfig(playerInst, auth, setting) {
    var _a;
    playerInst.setAudioPlayerConfig({
        customerId: auth.customerId,
        oAuthToken: auth.accessToken,
        deviceId: auth.deviceId,
        deviceTypeId: auth.deviceType,
        csrf: auth.csrf,
        ipAddress: globals.amznMusic.appConfig.ipAddress,
        streamingQuality: (setting === null || setting === void 0 ? void 0 : setting.audioQuality) || 'STANDARD',
        enableABR: setting === null || setting === void 0 ? void 0 : setting.enableABR, // undefxined indicates using default abr behavior.
    });
    playerInst.setVideoPlayerConfig({
        videoPlayerToken: ((_a = auth.videoPlayerToken) === null || _a === void 0 ? void 0 : _a.token) || '',
        authToken: auth.accessToken,
    });
    const volume = globals.localStorage.getItem(`${auth.deviceId}_volume`);
    if (volume !== null) {
        playerInst.volume(+volume);
    }
}
export function setSamplePlayerConfig(playerInst, auth) {
    playerInst.setSamplePlayerConfig({
        customerId: auth.customerId,
        oAuthToken: auth.accessToken,
        deviceId: auth.deviceId,
        deviceTypeId: auth.deviceType,
        csrf: auth.csrf,
        ipAddress: globals.amznMusic.appConfig.ipAddress,
    });
}
window.maestro = {
    getInstance,
    getAudioInstance,
    getVideoInstance,
    getSampleInstance,
    getSamplingInstance,
    isEMESupported,
    isVideoPlaybackSupported,
    setPlayerConfig,
};
