import React from 'react';
import Styles from './LoadingSpinner.scss';

export const LoadingSpinner = () => (
    <music-icon
        className={Styles['loading-spinner']}
        name='loader'
        size='large'
    />
);
