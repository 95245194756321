import { ISkyfireSquareVerticalItem } from '../types/templates/widgets/items/ISkyfireVerticalItem';
import {
    GET_BOOKMARK_ITEMS,
    GET_BOOKMARK_SHOVELER_ITEMS,
    GET_FEATURED_BOOKMARK_ITEM,
    GET_FOLLOWED_SHOVELER_ITEMS,
    GET_SAVE_ITEMS,
    GET_FOLLOW_ITEMS,
    UPDATE_PLAY_BUTTON,
} from '../actions/LocalHydration';
import ISkyfireFeaturedElement from '../types/templates/detail/ISkyfireFeaturedElement';
import ISkyfireButtonElement from '../types/templates/widgets/ISkyfireButtonElement';
import ISkyfireVisualRowItem from '../types/templates/widgets/items/ISkyfireVisualRowItem';
import ISkyfireEpisodeFeaturedElement from '../types/templates/detail/ISkyfireEpisodeFeaturedElement';
import ISkyfireEpisodeRowItem from '../types/templates/widgets/items/ISkyfireEpisodeRowItem';

export interface ILocalHydrationState {
    playButton: ISkyfireButtonElement | undefined;
    featuredItem?: ISkyfireFeaturedElement | ISkyfireEpisodeFeaturedElement;
    playButtonText: string;
    playButtonIcon: string;
    bookmarkItems: ISkyfireSquareVerticalItem[];
    saveItems: ISkyfireVisualRowItem[] | ISkyfireEpisodeRowItem[];
    followItems: ISkyfireSquareVerticalItem[];
    featuredBookmarkItem: ISkyfireSquareVerticalItem | undefined;
    followedShovelerItems: ISkyfireSquareVerticalItem[];
    bookmarkShovelerItems: ISkyfireSquareVerticalItem[];
}

const initialState: ILocalHydrationState = {
    playButton: undefined,
    featuredItem: undefined,
    playButtonText: 'Play',
    playButtonIcon: 'play',
    bookmarkItems: [],
    saveItems: [],
    followItems: [],
    featuredBookmarkItem: undefined,
    followedShovelerItems: [],
    bookmarkShovelerItems: [],
};

export function LocalHydrationReducer(state = initialState, action): any {
    switch (action.type) {
        case UPDATE_PLAY_BUTTON:
            return {
                ...state,
                playButton: action.payload.playButton,
                featuredItem: action.payload.featuredItem,
            };
        case GET_BOOKMARK_ITEMS:
            return {
                ...state,
                bookmarkItems: action.payload.bookmarkItems,
                customerId: action.payload.customerId,
            };
        case GET_SAVE_ITEMS:
            return {
                ...state,
                saveItems: action.payload.saveItems,
                customerId: action.payload.customerId,
                language: action.payload.language,
            };
        case GET_FOLLOW_ITEMS:
            return {
                ...state,
                followItems: action.payload.followItems,
                customerId: action.payload.customerId,
            };
        case GET_FEATURED_BOOKMARK_ITEM:
            return {
                ...state,
                featuredBookmarkItem: action.payload.featuredBookmarkItem,
                bookmarkId: action.payload.bookmarkId,
                language: action.payload.language,
            };
        case GET_FOLLOWED_SHOVELER_ITEMS:
            return {
                ...state,
                followedShovelerItems: action.payload.followedShovelerItems,
                customerId: action.payload.customerId,
            };
        case GET_BOOKMARK_SHOVELER_ITEMS:
            return {
                ...state,
                bookmarkShovelerItems: action.payload.bookmarkShovelerItems,
                customerId: action.payload.customerId,
            };
        default:
            return state;
    }
}
