import { IConditionElement, IWriteElement } from '../types/appsync/IAppSync';
import { Entry } from './Entry';

export abstract class SyncData<T extends IWriteElement, S, R> {
    public abstract read(id: string): R | undefined;

    public abstract readStates(): R[];

    public abstract writeStates(element: T, condition?: IConditionElement);

    public abstract writeToOperations(element: T);

    public abstract writeMetadata(element: S);

    public abstract reportOperations();

    public abstract getClientState(name: string): Entry | undefined;

    public abstract deleteOperation(ids: string[]);

    public abstract conditionSatisfied(
        element: T,
        condition: IConditionElement
    ): boolean;
}
