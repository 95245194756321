import { channelFor, PerformActionEvent, StateChangedEvent } from './utils';
import ISkyfireMethod from '../types/ISkyfireMethod';
import ISkyfireQueue from '../types/ISkyfireQueue';

const SKYFIRE_CHANNEL = channelFor('skyfire');

export interface DispatchSkyfireMethodsPayload {
  methods: ISkyfireMethod[];
}

export interface EnqueueSkyfireMethodPayload {
  method: ISkyfireMethod;
  queue: ISkyfireQueue;
}

export const DispatchMethodsEvent = new PerformActionEvent<DispatchSkyfireMethodsPayload>({
  channel: SKYFIRE_CHANNEL,
  topic: 'dispatchMethods',
});

export const EnqueueSkyfireMethod = new PerformActionEvent<EnqueueSkyfireMethodPayload>({
  channel: SKYFIRE_CHANNEL,
  topic: 'enqueueSkyfireMethod',
});

export const SkyfireStorageChangedEvent = new StateChangedEvent<Record<string, any>>({
  channel: SKYFIRE_CHANNEL,
  topic: 'storage',
});
