import { Dispatch } from 'redux';
import ISkyfireTemplate from '../types/templates/ISkyfireTemplate';

export const TEMPLATE_RENDERED = 'TEMPLATE_RENDERED';

export function dispatchTemplateRendered(
    dispatch: Dispatch,
    template: ISkyfireTemplate,
    duration: number
): void {
    if (!template.id?.includes('ChromeTemplate')) {
        dispatch({
            type: TEMPLATE_RENDERED,
            payload: {
                template,
                detail: duration,
            },
        });
    }
}
