import React, { useCallback, useContext } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useDispatch } from 'react-redux';
import * as Styles from './ExclusiveCarouselSlide.scss';
import Template from '../Contexts/Template';
import { dispatchSkyfireMethods } from '../utils';
export default function ExclusiveCarouselSlide(props) {
    const { primaryText, secondaryText, label, callToActionButton, image, imageDescription, } = props.data;
    useStyles(Styles);
    const dispatch = useDispatch();
    const template = useContext(Template);
    const onClick = useCallback((onItemSelected, event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        if (template) {
            dispatchSkyfireMethods(dispatch, template, onItemSelected);
        }
    }, [dispatch, template]);
    return (React.createElement("div", { className: Styles.slideContainer },
        image && (React.createElement("div", { className: Styles.imageContainer },
            React.createElement("img", { className: Styles.backgroundImage, src: image, alt: imageDescription }),
            React.createElement("div", { className: Styles.backgroundImageGlass }))),
        React.createElement("div", { className: Styles.header },
            React.createElement("span", { className: "label-text", id: Styles.labelText }, label),
            React.createElement("div", { className: Styles.slideContainerInner },
                React.createElement("div", { className: "headline-2" }, primaryText),
                React.createElement("div", { className: "secondary-text" }, secondaryText),
                React.createElement("div", null, callToActionButton && (React.createElement("music-button", { class: "cta", slot: "buttons", variant: "glass", size: "large", onClick: onClick.bind(this, callToActionButton === null || callToActionButton === void 0 ? void 0 : callToActionButton.onItemSelected) }, callToActionButton.text)))))));
}
