export class Follow {
    public id: string;

    public preset: string;

    public title: string;

    public podcastDescription: string;

    public publisher?: string;

    public image: string;

    public isFollowed: boolean;

    public updatedTime: number;

    public podcastShowVariantId: string;

    constructor(follow: {
        id?;
        title?;
        podcastDescription?;
        publisher?;
        image?;
        isFollowed?;
        updatedTime?;
        podcastShowVariantId?;
    }) {
        this.id = follow.id;
        this.title = follow.title;
        this.podcastDescription = follow.podcastDescription;
        this.publisher = follow.publisher;
        this.image = follow.image;
        this.isFollowed = follow.isFollowed;
        this.updatedTime = follow.updatedTime;
        this.podcastShowVariantId = follow.podcastShowVariantId;
    }
}
