exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3JdOnU5Lnk_Ry_pJ8lT_G1,.NrDT04RiLPkQwKYykErXz{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._3JdOnU5Lnk_Ry_pJ8lT_G1,.NrDT04RiLPkQwKYykErXz{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._3JdOnU5Lnk_Ry_pJ8lT_G1,.NrDT04RiLPkQwKYykErXz{padding-inline-start:52px;padding-inline-end:52px}}._1o_bEmekkQ7m5ud6Z3I7Yl{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._1o_bEmekkQ7m5ud6Z3I7Yl{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._1o_bEmekkQ7m5ud6Z3I7Yl{margin-inline-start:52px;margin-inline-end:52px}}.NrDT04RiLPkQwKYykErXz{padding-top:12px;padding-bottom:12px}._2hMKAb1Kude9zyeW8lq-dG{display:block}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_3JdOnU5Lnk_Ry_pJ8lT_G1",
	"gridSides": "_3JdOnU5Lnk_Ry_pJ8lT_G1",
	"flexible-text-widget": "NrDT04RiLPkQwKYykErXz",
	"flexibleTextWidget": "NrDT04RiLPkQwKYykErXz",
	"grid-sides-margins": "_1o_bEmekkQ7m5ud6Z3I7Yl",
	"gridSidesMargins": "_1o_bEmekkQ7m5ud6Z3I7Yl",
	"flexible-text-widget-container": "_2hMKAb1Kude9zyeW8lq-dG",
	"flexibleTextWidgetContainer": "_2hMKAb1Kude9zyeW8lq-dG"
};