exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1edXU2a3Za2gL9klyNA2PO{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._1edXU2a3Za2gL9klyNA2PO{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._1edXU2a3Za2gL9klyNA2PO{padding-inline-start:52px;padding-inline-end:52px}}._3XztpGeIe-sYONF4XxQe6d{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._3XztpGeIe-sYONF4XxQe6d{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._3XztpGeIe-sYONF4XxQe6d{margin-inline-start:52px;margin-inline-end:52px}}._3URbjfrwpgvIRJoXxYFivI{max-width:336px;margin:auto;border:solid 2px #ffdf04;border-radius:var(--music-spacer-small);padding:var(--music-spacer-base);padding-bottom:var(--music-spacer-giant);text-align:start}.UsIcSLqma7Xq4N-3SHsr-{padding-bottom:var(--music-spacer-mini)}._3NjbKi21miGY9I3H9EuFNC{padding-bottom:var(--music-spacer-large)}.bStCu630nL7dw16TKoZ0x{position:relative;top:2px}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_1edXU2a3Za2gL9klyNA2PO",
	"gridSides": "_1edXU2a3Za2gL9klyNA2PO",
	"grid-sides-margins": "_3XztpGeIe-sYONF4XxQe6d",
	"gridSidesMargins": "_3XztpGeIe-sYONF4XxQe6d",
	"information-card": "_3URbjfrwpgvIRJoXxYFivI",
	"informationCard": "_3URbjfrwpgvIRJoXxYFivI",
	"spacer-mini": "UsIcSLqma7Xq4N-3SHsr-",
	"spacerMini": "UsIcSLqma7Xq4N-3SHsr-",
	"spacer-large": "_3NjbKi21miGY9I3H9EuFNC",
	"spacerLarge": "_3NjbKi21miGY9I3H9EuFNC",
	"primary-icon": "bStCu630nL7dw16TKoZ0x",
	"primaryIcon": "bStCu630nL7dw16TKoZ0x"
};