import React, { forwardRef } from 'react';
import { bindHandler, preventDefault } from '../../../utils';
import HorizontalItem from './HorizontalItem';
function PlaybackContainerItem(props, ref) {
    const { headerImage, primaryText, onItemSelected, label, item } = props.data;
    const bindHandlerHelper = (fn) => bindHandler(props.handleSelected, this, fn);
    return (React.createElement("music-playback-container-item", { "header-image": headerImage, onClick: preventDefault, "primary-text": primaryText.text, onmusicHeaderActivate: bindHandlerHelper(onItemSelected), label: label },
        React.createElement(HorizontalItem, { data: item, handleSelected: props.handleSelected, style: { width: '100%', maxWidth: '100%', padding: '0' }, alwaysShowButton: true })));
}
export default forwardRef(PlaybackContainerItem);
