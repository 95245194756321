import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { WindowScroller } from 'react-virtualized/dist/commonjs/WindowScroller';
import { FixedSizeGrid } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { computeContainerWidth } from '../../../utils/gridHelpers';
import * as Styles from './InfiniteGrid.scss';
import LoadingWidget from '../../LoadingWidget';
const ItemRenderer = ({ columnIndex, rowIndex, style, data }) => {
    const { colCount, componentType, items, handleSelected, isEnumerated, itemsViewedRef } = data;
    const index = rowIndex * colCount + columnIndex;
    const ComponentType = componentType;
    return items.length > index ? (React.createElement(ComponentType, { ref: itemsViewedRef, data: items[index], handleSelected: handleSelected, style: style, position: isEnumerated ? index + 1 : undefined })) : null;
};
export default function InfiniteGrid(props) {
    useStyles(Styles);
    const gridRef = useRef();
    // Grab data from props and Redux store.
    const { data, handleSelected, gridSizes, isEnumerated, itemsViewedRef, componentType } = props;
    const { onEndOfWidget, items } = data;
    const { colCount, colWidth, rowHeight } = gridSizes;
    // Calculate grid sizing.
    const scrollbarWidth = 8;
    const { windowWidth } = useSelector((state) => state.BrowserState);
    const width = computeContainerWidth(windowWidth - scrollbarWidth);
    // Functions to check when to load more items.
    const loadMoreItems = () => handleSelected(onEndOfWidget);
    const isItemLoaded = (index) => index < ((onEndOfWidget === null || onEndOfWidget === void 0 ? void 0 : onEndOfWidget.length) ? items.length - 1 : items.length);
    const showLoader = (onEndOfWidget === null || onEndOfWidget === void 0 ? void 0 : onEndOfWidget.length) > 0;
    const gridItemData = {
        colCount,
        componentType,
        items,
        handleSelected,
        isEnumerated,
        itemsViewedRef,
    };
    const onGridItemsRendered = (onItemsRendered) => ({ visibleColumnStartIndex, visibleColumnStopIndex, visibleRowStartIndex, visibleRowStopIndex, }) => {
        const startIndex = visibleRowStartIndex * colCount + visibleColumnStartIndex;
        const stopIndex = visibleRowStopIndex * colCount + visibleColumnStopIndex;
        onItemsRendered({
            visibleStartIndex: startIndex,
            visibleStopIndex: stopIndex,
        });
    };
    const grid = (onItemsRendered, ref) => (React.createElement(FixedSizeGrid, { className: Styles.grid, ref: (e) => {
            gridRef.current = e;
            ref(e);
        }, height: window.innerHeight, width: width, rowCount: Math.ceil(items.length / colCount), rowHeight: rowHeight, columnCount: colCount, columnWidth: colWidth, itemData: gridItemData, onItemsRendered: onGridItemsRendered(onItemsRendered) }, ItemRenderer));
    const infiniteLoader = (React.createElement(InfiniteLoader, { isItemLoaded: isItemLoaded, itemCount: items.length, loadMoreItems: loadMoreItems, threshold: 1 }, ({ onItemsRendered, ref }) => grid(onItemsRendered, ref)));
    const onScroll = useCallback(({ scrollTop }) => { var _a; return (_a = gridRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ scrollTop }); }, []);
    const windowScroller = React.createElement(WindowScroller, { onScroll: onScroll }, () => React.createElement("div", null));
    return (React.createElement("div", null,
        windowScroller,
        infiniteLoader,
        showLoader && React.createElement(LoadingWidget, null)));
}
