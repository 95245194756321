exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1uayuYITaWL_pRGmBXMVee,._29cJmx-Gi3BIGGrHAhESlg{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._1uayuYITaWL_pRGmBXMVee,._29cJmx-Gi3BIGGrHAhESlg{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._1uayuYITaWL_pRGmBXMVee,._29cJmx-Gi3BIGGrHAhESlg{padding-inline-start:52px;padding-inline-end:52px}}._3Dz-0dIyRu79jZNZRKNomb{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._3Dz-0dIyRu79jZNZRKNomb{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._3Dz-0dIyRu79jZNZRKNomb{margin-inline-start:52px;margin-inline-end:52px}}@media(min-width: 640px)and (max-width: 1280px),(min-width: 2660px){._29cJmx-Gi3BIGGrHAhESlg music-list-item{width:calc(50% - var(--music-spacer-small));margin-inline-end:var(--music-spacer-small)}}@media(min-width: 1280px)and (max-width: 2660px){._29cJmx-Gi3BIGGrHAhESlg music-list-item{width:calc(25% - var(--music-spacer-small));margin-inline-end:var(--music-spacer-small)}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_1uayuYITaWL_pRGmBXMVee",
	"gridSides": "_1uayuYITaWL_pRGmBXMVee",
	"link-navigator": "_29cJmx-Gi3BIGGrHAhESlg",
	"linkNavigator": "_29cJmx-Gi3BIGGrHAhESlg",
	"grid-sides-margins": "_3Dz-0dIyRu79jZNZRKNomb",
	"gridSidesMargins": "_3Dz-0dIyRu79jZNZRKNomb"
};