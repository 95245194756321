exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3R11amzWqlz_3mhTyBWi_4,._3Ww5sTXytgsJ5AGYlsL029{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._3R11amzWqlz_3mhTyBWi_4,._3Ww5sTXytgsJ5AGYlsL029{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._3R11amzWqlz_3mhTyBWi_4,._3Ww5sTXytgsJ5AGYlsL029{padding-inline-start:52px;padding-inline-end:52px}}.foRqh5fsqidDC9zx3cAeu{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){.foRqh5fsqidDC9zx3cAeu{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){.foRqh5fsqidDC9zx3cAeu{margin-inline-start:52px;margin-inline-end:52px}}._3Ww5sTXytgsJ5AGYlsL029{-webkit-line-clamp:2;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;text-rendering:optimizeLegibility;font-family:\"Sharp Grotesk Semi Bold 20\",\"Helvetica\",\"Arial\",\"sans-serif\";margin:0px;padding-top:24px;font-weight:normal;white-space:normal;text-overflow:ellipsis;font-size:18px;line-height:24px}@media(pointer: fine){._3Ww5sTXytgsJ5AGYlsL029{font-size:20px;line-height:24px}}@media all and (-ms-high-contrast: none),(-ms-high-contrast: active){._3Ww5sTXytgsJ5AGYlsL029{font-size:20px;line-height:24px}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_3R11amzWqlz_3mhTyBWi_4",
	"gridSides": "_3R11amzWqlz_3mhTyBWi_4",
	"descriptive-widget-header": "_3Ww5sTXytgsJ5AGYlsL029",
	"descriptiveWidgetHeader": "_3Ww5sTXytgsJ5AGYlsL029",
	"grid-sides-margins": "foRqh5fsqidDC9zx3cAeu",
	"gridSidesMargins": "foRqh5fsqidDC9zx3cAeu"
};