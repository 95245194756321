import { useSelector } from 'react-redux';
import IApplicationState from '../types/IApplicationState';
import ISkyfireObserver from '../types/templates/observers/ISkyfireObserver';
import { useObserver } from './ObserverHooks';

export function useItemSelectedState(
    isSelected?: ISkyfireObserver,
    selectable?: boolean
): 'on' | 'off' | undefined {
    const multiSelectIsPopulated = useSelector(
        (state: IApplicationState) =>
            state.Storage.MULTISELECT &&
            Object.keys(state.Storage.MULTISELECT).length > 0
    );

    const selected = !!useObserver({ observer: isSelected }) as boolean;

    if (isSelected && selectable !== null && multiSelectIsPopulated) {
        return selected ? 'on' : 'off';
    }

    return undefined;
}

export function useSelectedRowCount(): number {
    return useSelector(
        (state: IApplicationState): number =>
            Object.keys(state.Storage.MULTISELECT ?? {}).filter(
                (k) => !k.startsWith('_')
            ).length
    );
}
