import withStyles from 'isomorphic-style-loader/withStyles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import { dispatchSkyfireMethods } from '../utils';
import { END_TRANSPORT_HOVERING, START_TRANSPORT_HOVERING } from '../actions/TransportOverlay';
import { WINDOW_SIZE_ENUM } from '../types/IWindowSize';
import ContextMenuButton from './ContextMenuButton';
import FullScreenButton, { exitFullscreen } from './FullScreenButton';
import VideoUITimeoutMS from '../utils/VideoUITimeoutMS';
import * as Styles from './VideoContainerTopSection.scss';
import * as AVToggleButtonStyles from '../views/TemplateContainer.scss';
import ToggleAudioVideoButton from './ToggleAudioVideoButton';
class VideoContainerTopSection extends Component {
    constructor(props) {
        super(props);
        this.handleMouseOver = () => {
            const timestamp = Date.now();
            this.setState({ transportHoverTimestamp: timestamp });
            this.props.dispatch({ type: START_TRANSPORT_HOVERING, payload: { timestamp } });
            this.debouncedEndHover();
        };
        this.handleMouseLeave = () => {
            this.props.dispatch({ type: END_TRANSPORT_HOVERING });
        };
        this.handleSelected = (event, methods = []) => {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            dispatchSkyfireMethods(this.props.dispatch, this.props.template, methods);
        };
        this.handleEscape = (e) => {
            if (e.key === 'Escape' && !this.props.isTransportOverlayOpen) {
                exitFullscreen();
                this.handleSelected(e, this.props.template.closeButton.onItemSelected);
            }
        };
        this.onClose = (e) => {
            exitFullscreen();
            this.handleSelected(e, this.props.template.closeButton.onItemSelected);
        };
        this.state = {
            transportHoverTimestamp: 0,
        };
        this.debouncedEndHover = debounce(() => {
            this.props.dispatch({ type: END_TRANSPORT_HOVERING });
        }, VideoUITimeoutMS.Hover4s);
    }
    async componentDidUpdate() {
        const { lastHoverTimestamp } = this.props;
        if (this.state.transportHoverTimestamp &&
            lastHoverTimestamp !== this.state.transportHoverTimestamp) {
            this.debouncedEndHover.clear();
            this.setState({ transportHoverTimestamp: undefined });
        }
        window.addEventListener('keydown', this.handleEscape);
    }
    async componentWillUnmount() {
        window.removeEventListener('keydown', this.handleEscape);
    }
    render() {
        var _a;
        const { isTransportHovering, isTransportOverlayOpen, media, playbackState, showControls, windowWidth, } = this.props;
        const buttonSize = windowWidth < WINDOW_SIZE_ENUM.SM ? 'small' : 'medium';
        const showContainerSection = (showControls || isTransportHovering) && !isTransportOverlayOpen;
        const containerSectionClassName = showContainerSection ? '' : Styles.hidden;
        return (React.createElement("div", { id: "videoContainerTopSection", className: containerSectionClassName, onMouseOver: this.handleMouseOver, onMouseLeave: this.handleMouseLeave },
            React.createElement("music-button", { className: Styles.closeButton, onmusicActivate: this.onClose, variant: "primary", size: buttonSize, "icon-name": "goback", id: "vnpvCloseButton", ariaLabelText: "Close" }),
            ((_a = playbackState.showAVToggle) === null || _a === void 0 ? void 0 : _a.state) === 'SHOW' && (React.createElement("div", { className: AVToggleButtonStyles.avToggleButtonContainer },
                React.createElement(ToggleAudioVideoButton, null))),
            React.createElement(FullScreenButton, { size: buttonSize }),
            (media === null || media === void 0 ? void 0 : media.contextMenu) && Object.keys(media.contextMenu).length !== 0 && (React.createElement("div", { className: Styles.contextMenuContainer },
                React.createElement(ContextMenuButton, { options: media.contextMenu.options, disabled: media.contextMenu.disabled, variant: "primary", iconName: "overflow", size: buttonSize })))));
    }
}
function mapStateToProps(state) {
    return {
        isTransportHovering: state.TransportOverlay.isTransportHovering,
        isTransportOverlayOpen: state.TransportOverlay.isTransportOverlayOpen,
        lastHoverTimestamp: state.TransportOverlay.lastHoverTimestamp,
        media: state.Media,
        playbackState: state.PlaybackStates,
        showControls: state.VideoNPVState.showControls,
        windowWidth: state.BrowserState.windowWidth,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}
export default withStyles(Styles)(connect(mapStateToProps, mapDispatchToProps)(VideoContainerTopSection));
