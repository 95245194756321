import {
    FollowPrompt,
    HIDE_PROMPT_PREFERENCE,
    HIDE_PROMPT_PREFERENCE_CLIENT_INFORMATION,
} from '../FollowPrompt';
import {
    FOLLOWER_OPERATION_CLIENT_INFORMATION,
    SAVE_OPERATIONS_CLIENT_INFORMATION,
    BOOKMARK_OPERATIONS_CLIENT_INFORMATION,
    FOLLOW_LAST_SYNC_TIME_CLIENT_INFORMATION,
    COMPLETED_OPERATIONS_CLIENT_INFORMATION,
    SAVE_LAST_SYNC_TIME_CLIENT_INFORMATION,
    BOOKMARKS_LAST_SYNC_TIME_CLIENT_INFORMATION,
    COMPLETED_LAST_SYNC_TIME_CLIENT_INFORMATION,
    PLAYBACK_METRICS_OPERATIONS_CLIENT_INFORMATION,
    UI_METRICS_OPERATIONS_CLIENT_INFORMATION,
    AppSyncTypes,
    BOOKMARKS_LAST_SYNC_TIME,
    BOOKMARKS_LAST_SYNC_TIME_CLIENT_STATE,
    BOOKMARK_OPERATIONS_CLIENT_STATE,
    FOLLOWS_LAST_SYNC_TIME,
    FOLLOWS_LAST_SYNC_TIME_CLIENT_STATE,
    FOLLOW_OPERATIONS_CLIENT_STATE,
    SAVES_LAST_SYNC_TIME,
    SAVES_LAST_SYNC_TIME_CLIENT_STATE,
    SAVE_OPERATIONS_CLIENT_STATE,
    COMPLETED_LAST_SYNC_TIME,
    COMPLETED_LAST_SYNC_TIME_CLIENT_STATE,
    COMPLETED_OPERATIONS_CLIENT_STATE,
    USER_TIER,
} from '../appsync/AppSyncConstants';
import { HidePromptPreferenceClientState } from '../FollowPrompt/HidePromptPreferenceClientState';
import {
    UIMetricOperationDaoSessionStorage,
    UIMetricOperationsClientState,
    UIMetricSyncData,
} from '../UIMetrics';
import { OperationElement } from '../appsync/OperationElement';
import {
    BookmarkDaoSessionStorage,
    BookMarkOperationDaoSessionStorage,
    BookmarkOperationsClientState,
    BookmarksLastSyncTimeClientState,
    BookmarkSyncData,
} from '../Bookmark';
import {
    FollowDaoSessionStorage,
    FollowOperationDaoSessionStorage,
    FollowOperationsClientState,
    FollowsLastSyncTimeClientState,
    FollowSyncData,
} from '../Follow';
import {
    CompletedDaoSessionStorage,
    CompletedOperationDaoSessionStorage,
    CompletedOperationsClientState,
    CompletedLastSyncTimeClientState,
    CompletedSyncData,
} from '../Completed';
import {
    PlaybackMetricOperationDaoSessionStorage,
    PlaybackMetricOperationsClientState,
    PlaybackMetricSyncData,
} from '../PlaybackMetrics';
import {
    SaveDaoSessionStorage,
    SaveOperationDaoSessionStorage,
    SaveOperationsClientState,
    SavesLastSyncTimeClientState,
    SaveSyncData,
} from '../Save';

export function getAppSyncClientInformation(method: any, store): [string, any] {
    let operations: OperationElement[] = [];
    let lastSyncTime: number = 0;
    let clientInfoResult: [string, any] = ['', []];
    if ((method.clientInformation || []).length > 0) {
        method.clientInformation.forEach((clientInfo) => {
            if (clientInfo === FOLLOWER_OPERATION_CLIENT_INFORMATION) {
                const followSyncData = new FollowSyncData(
                    new FollowDaoSessionStorage(store),
                    new FollowOperationDaoSessionStorage(store),
                    store
                );
                const entry = followSyncData.getClientState(
                    FOLLOW_OPERATIONS_CLIENT_STATE
                );
                const followClientInfo = entry.clientState as FollowOperationsClientState;
                operations = followClientInfo.operations;
                clientInfoResult = [AppSyncTypes.FOLLOW, operations];
            } else if (clientInfo === SAVE_OPERATIONS_CLIENT_INFORMATION) {
                const saveSyncData = new SaveSyncData(
                    new SaveDaoSessionStorage(store),
                    new SaveOperationDaoSessionStorage(store),
                    store
                );
                const entry = saveSyncData.getClientState(
                    SAVE_OPERATIONS_CLIENT_STATE
                );
                const saveClientInfo = entry.clientState as SaveOperationsClientState;
                operations = saveClientInfo.operations;
                clientInfoResult = ['saves', operations];
            } else if (clientInfo === BOOKMARK_OPERATIONS_CLIENT_INFORMATION) {
                const bookmarkSyncData = new BookmarkSyncData(
                    new BookmarkDaoSessionStorage(store),
                    new BookMarkOperationDaoSessionStorage(store),
                    store
                );
                const entry = bookmarkSyncData.getClientState(
                    BOOKMARK_OPERATIONS_CLIENT_STATE
                );
                const bookmarkClientInfo = entry.clientState as BookmarkOperationsClientState;
                operations = bookmarkClientInfo.operations;
                clientInfoResult = [AppSyncTypes.BOOKMARK, operations];
            } else if (clientInfo === COMPLETED_OPERATIONS_CLIENT_INFORMATION) {
                const completedSyncData = new CompletedSyncData(
                    new CompletedDaoSessionStorage(store),
                    new CompletedOperationDaoSessionStorage(store),
                    store
                );
                const entry = completedSyncData.getClientState(
                    COMPLETED_OPERATIONS_CLIENT_STATE
                );
                const completedClientInfo = entry.clientState as CompletedOperationsClientState;
                operations = completedClientInfo.operations;
                clientInfoResult = [AppSyncTypes.COMPLETED, operations];
            } else if (
                clientInfo === FOLLOW_LAST_SYNC_TIME_CLIENT_INFORMATION
            ) {
                if (isTierChanged()) return;
                const followSyncData = new FollowSyncData(
                    new FollowDaoSessionStorage(store),
                    new FollowOperationDaoSessionStorage(store),
                    store
                );
                const entry = followSyncData.getClientState(
                    FOLLOWS_LAST_SYNC_TIME_CLIENT_STATE
                );
                const followClientInfo = entry.clientState as FollowsLastSyncTimeClientState;
                lastSyncTime = followClientInfo.lastSyncTime;
                clientInfoResult = [FOLLOWS_LAST_SYNC_TIME, lastSyncTime];
            } else if (clientInfo === SAVE_LAST_SYNC_TIME_CLIENT_INFORMATION) {
                if (isTierChanged()) return;
                const saveSyncData = new SaveSyncData(
                    new SaveDaoSessionStorage(store),
                    new SaveOperationDaoSessionStorage(store),
                    store
                );
                const entry = saveSyncData.getClientState(
                    SAVES_LAST_SYNC_TIME_CLIENT_STATE
                );
                const saveClientInfo = entry.clientState as SavesLastSyncTimeClientState;
                lastSyncTime = saveClientInfo.lastSyncTime;
                clientInfoResult = [SAVES_LAST_SYNC_TIME, lastSyncTime];
            } else if (
                clientInfo === BOOKMARKS_LAST_SYNC_TIME_CLIENT_INFORMATION
            ) {
                if (isTierChanged()) return;
                const bookmarkSyncData = new BookmarkSyncData(
                    new BookmarkDaoSessionStorage(store),
                    new BookMarkOperationDaoSessionStorage(store),
                    store
                );
                const entry = bookmarkSyncData.getClientState(
                    BOOKMARKS_LAST_SYNC_TIME_CLIENT_STATE
                );
                const bookmarkClientInfo = entry.clientState as BookmarksLastSyncTimeClientState;
                lastSyncTime = bookmarkClientInfo.lastSyncTime;
                clientInfoResult = [BOOKMARKS_LAST_SYNC_TIME, lastSyncTime];
            } else if (
                clientInfo === COMPLETED_LAST_SYNC_TIME_CLIENT_INFORMATION
            ) {
                const completedSyncData = new CompletedSyncData(
                    new CompletedDaoSessionStorage(store),
                    new CompletedOperationDaoSessionStorage(store),
                    store
                );
                const entry = completedSyncData.getClientState(
                    COMPLETED_LAST_SYNC_TIME_CLIENT_STATE
                );
                const completedClientInfo = entry.clientState as CompletedLastSyncTimeClientState;
                lastSyncTime = completedClientInfo.lastSyncTime;
                clientInfoResult = [COMPLETED_LAST_SYNC_TIME, lastSyncTime];
            } else if (
                clientInfo === PLAYBACK_METRICS_OPERATIONS_CLIENT_INFORMATION
            ) {
                const playbackMetricSyncData = new PlaybackMetricSyncData(
                    new PlaybackMetricOperationDaoSessionStorage(store),
                    store
                );
                const entry = playbackMetricSyncData.getClientState(
                    AppSyncTypes.PLAYBACK_METRIC
                );
                const playbackMetricClientInfo = entry.clientState as PlaybackMetricOperationsClientState;
                operations = playbackMetricClientInfo.operations;
                clientInfoResult = [AppSyncTypes.PLAYBACK_METRIC, operations];
            } else if (
                clientInfo === UI_METRICS_OPERATIONS_CLIENT_INFORMATION
            ) {
                const uiMetricSyncData = new UIMetricSyncData(
                    new UIMetricOperationDaoSessionStorage(store),
                    store
                );
                const entry = uiMetricSyncData.getClientState();
                const uiMetricClientInfo = entry.clientState as UIMetricOperationsClientState;
                operations = uiMetricClientInfo.operations;
                clientInfoResult = [AppSyncTypes.UI_METRIC, operations];
            } else if (
                clientInfo === HIDE_PROMPT_PREFERENCE_CLIENT_INFORMATION
            ) {
                const followPrompt = new FollowPrompt();
                const entry = followPrompt.getHidePromptPreferenceClientState();
                const hidePromptPreferenceClientState = entry.clientState as HidePromptPreferenceClientState;
                const { preferenceMap } = hidePromptPreferenceClientState;
                clientInfoResult = [HIDE_PROMPT_PREFERENCE, preferenceMap];
            }
        });
        return clientInfoResult;
    }
    return clientInfoResult;
}

function isTierChanged() {
    const userCurrentTier = window.sessionStorage.getItem(USER_TIER);
    const userUpdatedTier = window.amznMusic.appConfig.tier;
    return userCurrentTier !== userUpdatedTier;
}
