import { Store } from 'redux';
import { WEB_STORAGE } from '../appsync/AppSyncConstants';
import IApplicationState from '../types/IApplicationState';
import {
    DELETE_FOLLOW_OPERATION,
    SAVE_FOLLOW_OPERATION,
    UPDATE_FOLLOW_OPERATION,
} from './FollowActions';
import { FollowOperation } from './FollowOperation';
import { IFollowOperationDao } from './IFollowOperationDao';

export class FollowOperationDaoSessionStorage implements IFollowOperationDao {
    private store: Store<IApplicationState>;

    constructor(store) {
        this.store = store;
    }

    public insert(followOperation: FollowOperation) {
        this.store.dispatch({
            type: SAVE_FOLLOW_OPERATION,
            payload: { followOperation },
        });
    }

    public getUnProcessed(): FollowOperation[] {
        const unProcessedOperations: FollowOperation[] = [];
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (key.endsWith('FollowOperation')) {
                const followOperation = JSON.parse(value);
                if (followOperation && followOperation.isProcessing === false) {
                    unProcessedOperations.push(followOperation);
                }
            }
        }
        return unProcessedOperations;
    }

    public update(ids: string[], isProcessing: boolean) {
        this.store.dispatch({
            type: UPDATE_FOLLOW_OPERATION,
            payload: { ids, isProcessing },
        });
    }

    public delete(ids: string[]) {
        this.store.dispatch({
            type: DELETE_FOLLOW_OPERATION,
            payload: { ids },
        });
    }
}
