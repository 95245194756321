import { ClientState } from '../types/ClientState';

export class Entry {
    public key: string;

    public clientState: ClientState;

    constructor(key: string, clientState: ClientState) {
        this.key = key;
        this.clientState = clientState;
    }
}
