import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { AppName, PathChangedEvent, useStateSubscription } from '@amzn/DMWebEventBus';
import { RWP_BROWSER_DEVICE_TYPES } from '../utils/deviceTypes';
import { globals } from '../utils/globals';
import { SplashScreen } from '../components/SplashScreen';
import Template from '../Contexts/Template';
import { NOW_PLAYING_TEMPLATE } from '../types/templates/nowPlaying';
import { VIDEO_NOW_PLAYING_TEMPLATE, } from '../types/templates/videoNowPlaying';
import * as Styles from './TemplateContainer.scss';
import View from './View';
import Overlays from './Overlays';
export default function TemplateContainer() {
    var _a, _b;
    useStyles(Styles);
    const activeApp = (_b = (_a = useStateSubscription(PathChangedEvent)) === null || _a === void 0 ? void 0 : _a.activeApp) !== null && _b !== void 0 ? _b : AppName.WEBFIRE;
    const isForMusicExperience = useSelector((state) => state.Media.isForMusicExperience);
    const currentTemplate = useSelector((state) => state.TemplateStack.currentTemplate);
    const overlayTemplates = useSelector((state) => state.TemplateStack.overlayTemplates);
    const displayLanguageId = useSelector((state) => state.Authentication.displayLanguageId);
    const preventUserSelect = useSelector((state) => state.InteractionState.preventUserSelect);
    const hasOverlay = overlayTemplates.length > 0;
    const videoNPVTemplate = overlayTemplates.find((template) => template.interface === VIDEO_NOW_PLAYING_TEMPLATE);
    const isAudioNPVShowing = hasOverlay &&
        !!overlayTemplates.find((template) => template.interface === NOW_PLAYING_TEMPLATE);
    const isVideoNPVShowing = hasOverlay && !!videoNPVTemplate;
    const isMusicExperienceShowing = hasOverlay && !!isForMusicExperience;
    const isNPVShowing = isAudioNPVShowing || isVideoNPVShowing || isMusicExperienceShowing;
    const backgroundClass = isNPVShowing ? Styles.hidden : hasOverlay ? Styles.blurContent : '';
    const viewContainerClass = [backgroundClass, Styles.viewContainer].join(' ');
    const isRWPBrowser = RWP_BROWSER_DEVICE_TYPES.includes(globals.amznMusic.appConfig.deviceType);
    // For ssr we directly show the Overlay component, otherwise we inject it.
    let overlays = null;
    if (globals.amznMusic.ssr) {
        overlays = React.createElement(Overlays, null);
    }
    else if (!window.amznMusic.isInContainerApp) {
        overlays = React.createElement("div", { id: "overlay" });
    }
    const displayWebfire = useSelector((state) => !window.amznMusic.isInContainerApp || state.ContainerAppRender.isRendering);
    return (React.createElement("music-app", { class: ['hydrated', preventUserSelect ? Styles.selectDisabled : null].join(' ') },
        React.createElement(Template.Provider, { value: currentTemplate },
            activeApp === AppName.WEBFIRE &&
                currentTemplate &&
                getSEOHead(currentTemplate, displayLanguageId),
            overlays,
            React.createElement("div", { className: viewContainerClass }, displayWebfire &&
                (currentTemplate ? React.createElement(View, { template: currentTemplate }) : React.createElement(SplashScreen, null))),
            isRWPBrowser && hasOverlay && !isNPVShowing && (React.createElement("div", { className: Styles.fullPageBlur })))));
}
// https://stackoverflow.com/questions/25421233/javascript-removing-undefined-fields-from-an-object
function removeUndefined(obj) {
    Object.keys(obj).forEach(
    // eslint-disable-next-line no-param-reassign
    (key) => (obj[key] === undefined || obj[key] === null) && delete obj[key]);
    return obj;
}
function getSEOHead(template, displayLanguageId) {
    var _a, _b, _c;
    const seoHead = ((_b = (_a = template === null || template === void 0 ? void 0 : template.innerTemplate) === null || _a === void 0 ? void 0 : _a.templateData) === null || _b === void 0 ? void 0 : _b.seoHead) || ((_c = template === null || template === void 0 ? void 0 : template.templateData) === null || _c === void 0 ? void 0 : _c.seoHead);
    if (seoHead) {
        // Remove interface and null fields from objects in script, meta, and link arrays
        const scripts = seoHead.script.map(({ innerHTML }) => removeUndefined({ innerHTML, type: 'application/ld+json' }));
        const metas = seoHead.meta.map(({ name, property, content }) => removeUndefined({ name, property, content }));
        const links = seoHead.link.map(({ rel, href, hreflang }) => removeUndefined({ rel, href, hreflang }));
        return (React.createElement(Helmet, { title: seoHead.title, meta: metas, link: links, script: scripts, lang: displayLanguageId }));
    }
    return null;
}
