/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { parseOrStripHtml } from '../../../utils';
import Styles from './ExpandableTextView.scss';

interface IExpandableTextViewProps {
    description: string;
}

export default function ExpandableTextView(props: IExpandableTextViewProps) {
    const isBot = window.navigator.userAgent.match(/(bot|bingpreview)/i);
    const [shouldTruncate, setShouldTruncate] = useState(!isBot);
    const readMoreEllipsis = (
        <span>
            ...{' '}
            <span
                onClick={() => setShouldTruncate(!shouldTruncate)}
                className={Styles['expandable-text-view-link']}
                role='button'
                tabIndex={0}
            >
                read more
            </span>
        </span>
    );
    const truncateString = (str, length) => (
        <div>
            {str.substring(0, length)}
            {str.length > length && readMoreEllipsis}
        </div>
    );
    const truncateDescription = (
        <div className={Styles['truncated-description']}>
            {truncateString(
                props.description.replace(/(<([^>]+)>)/gi, ''),
                250
            )}
        </div>
    );
    const parseHTMLDescription = (
        <div>
            {parseOrStripHtml(props.description)}
            <span
                onClick={() => setShouldTruncate(!shouldTruncate)}
                className={Styles['expandable-text-view-link']}
                role='button'
                tabIndex={0}
            >
                {' read less'}
            </span>
        </div>
    );
    return (
        <div>{shouldTruncate ? truncateDescription : parseHTMLDescription}</div>
    );
}
