import * as React from 'react';
import Styles from './CompactPillNavigatorWidget.scss';
import ISkyfireMethod from '../../../types/ISkyfireMethod';
import { bindHandler } from '../../../utils';
import ISkyfireCompactPillNavigatorWidgetElement from '../../../types/templates/widgets/ISkyfireCompactPillNavigatorWidgetElement';

interface ICompactPillNavigatorWidgetProps {
    data: ISkyfireCompactPillNavigatorWidgetElement;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    bottomMargin?: boolean;
}

export function CompactPillNavigatorWidget(
    props: ICompactPillNavigatorWidgetProps
) {
    const { items } = props.data;

    function renderItems() {
        return items.map((item, index) => (
            <div className={Styles.pill}>
                <music-pill-item
                    id={`pillNavigatorButton${index + 1}`}
                    text={item.text}
                    onClick={bindHandler(
                        props.handleSelected,
                        null,
                        item.onItemSelected
                    )}
                >
                    {item.text}
                </music-pill-item>
            </div>
        ));
    }
    let className = [Styles.container, Styles.grid].join(' ');
    if (props.bottomMargin === true) {
        className = [className, Styles.margin].join(' ');
    }
    return <div className={className}>{renderItems()}</div>;
}
