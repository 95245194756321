exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1OILx1FikDxjZyQkqxgCC_,._2OJi00CkgQo8bMZueoCVMx{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._1OILx1FikDxjZyQkqxgCC_,._2OJi00CkgQo8bMZueoCVMx{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._1OILx1FikDxjZyQkqxgCC_,._2OJi00CkgQo8bMZueoCVMx{padding-inline-start:52px;padding-inline-end:52px}}._125bIYp5R9pZhlwG3JP8jF{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._125bIYp5R9pZhlwG3JP8jF{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._125bIYp5R9pZhlwG3JP8jF{margin-inline-start:52px;margin-inline-end:52px}}._2OJi00CkgQo8bMZueoCVMx ._2dZmDhoKMf6yIA70us0I2y{font-weight:bold !important}._2OJi00CkgQo8bMZueoCVMx p{margin:16px 0}._2OJi00CkgQo8bMZueoCVMx .Pb4oXFy4YhN4GSRLOQNmu{margin:20px 0}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_1OILx1FikDxjZyQkqxgCC_",
	"gridSides": "_1OILx1FikDxjZyQkqxgCC_",
	"header": "_2OJi00CkgQo8bMZueoCVMx",
	"grid-sides-margins": "_125bIYp5R9pZhlwG3JP8jF",
	"gridSidesMargins": "_125bIYp5R9pZhlwG3JP8jF",
	"primary-text-bold": "_2dZmDhoKMf6yIA70us0I2y",
	"primaryTextBold": "_2dZmDhoKMf6yIA70us0I2y",
	"margin-tb": "Pb4oXFy4YhN4GSRLOQNmu",
	"marginTb": "Pb4oXFy4YhN4GSRLOQNmu"
};