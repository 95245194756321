import ISkyfireLinkElement from '../../ISkyfireLinkElement';
import ISkyfireContextMenuElement from '../contextMenu/ISkyfireContextMenuElement';
import { ISkyfireDetailHeaderItemElement } from '../detail/ISkyfireDetailHeaderItemElement';
import ISkyfireTemplate from '../ISkyfireTemplate';
import ISkyfireTemplateData from '../ISkyfireTemplateData';
import ISkyfireTextElement from '../widgets/ISkyfireTextElement';
import ISkyfireButtonElement from '../widgets/ISkyfireButtonElement';
import SkyfireWidgetElement from '../widgets/ISkyfireWidgetElement';
import ISkyfireUnborderedBadgeElement from '../widgets/ISkyfireUnborderedBadgeElement';
import ISkyfireBorderedBadgeElement from '../widgets/ISkyfireBorderedBadgeElement';
import ISkyfireLightUnborderedBadgeElement from '../widgets/ISkyfireLightUnborderedBadgeElement';
import ISkyfireBookmarkElement from '../widgets/ISkyfireBookmarkElement';
import ISkyfireAccentOutlineBadgeElement from '../widgets/ISkyfireAccentOutlineBadgeElement';
import ISkyfireCompactPillNavigatorWidgetElement from '../widgets/ISkyfireCompactPillNavigatorWidgetElement';

export const PODCAST_COMPACT_DETAIL_TEMPLATE =
    'Podcast.Web.CompactDetailTemplateInterface.CompactDetailTemplate';

export interface ISkyfirePodcastCompactDetailTemplate extends ISkyfireTemplate {
    readonly interface: typeof PODCAST_COMPACT_DETAIL_TEMPLATE;
    readonly templateData: ISkyfireTemplateData;
    readonly description: string;
    readonly headerLabel: string;
    readonly headerText: ISkyfireTextElement;
    readonly headerPrimaryText?: string;
    readonly headerPrimaryTextLink?: ISkyfireLinkElement;
    readonly headerSecondaryText?: string;
    readonly headerSecondaryTextLink?: ISkyfireLinkElement;
    readonly headerTertiaryText?: string;
    readonly contextMenu?: ISkyfireContextMenuElement;
    readonly backgroundImage?: string;
    readonly items: any[];
    readonly headerIcon?: string;
    readonly headerImage: string;
    readonly headerImageAltText: string;
    readonly headerButtons: ISkyfireDetailHeaderItemElement[];
    readonly refinementHeader?: any;
    readonly isSmallHeaderCentered?: boolean;
    readonly badge?:
        | ISkyfireUnborderedBadgeElement
        | ISkyfireBorderedBadgeElement
        | ISkyfireLightUnborderedBadgeElement
        | ISkyfireAccentOutlineBadgeElement;
    readonly button?: ISkyfireButtonElement;
    readonly text?: string;
    readonly widgets?: SkyfireWidgetElement[];
    readonly playButton?: ISkyfireButtonElement;
    readonly bookmarkElement: ISkyfireBookmarkElement;
    readonly compactPillNavigator?: ISkyfireCompactPillNavigatorWidgetElement;
}
