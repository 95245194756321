exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2WVCk8--eKbu6rALIpzmsn{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._2WVCk8--eKbu6rALIpzmsn{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._2WVCk8--eKbu6rALIpzmsn{padding-inline-start:52px;padding-inline-end:52px}}._2lY04vOV8P99QhvNoqUJZO{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._2lY04vOV8P99QhvNoqUJZO{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._2lY04vOV8P99QhvNoqUJZO{margin-inline-start:52px;margin-inline-end:52px}}.LD6SDcZYDKHJ9m7v9DYT_{z-index:3;position:absolute;inset-inline-end:calc(20px + 56px)}@media only screen and (min-width: 480px){.LD6SDcZYDKHJ9m7v9DYT_{inset-inline-end:calc(36px + 56px)}}@media only screen and (min-width: 1280px){.LD6SDcZYDKHJ9m7v9DYT_{inset-inline-end:calc(52px + 56px)}}._3u6uGbLTub6nztT3dajxX5{z-index:3;position:absolute;inset-inline-start:calc(20px - 14px)}@media only screen and (min-width: 480px){._3u6uGbLTub6nztT3dajxX5{inset-inline-start:calc(36px - 14px)}}@media only screen and (min-width: 1280px){._3u6uGbLTub6nztT3dajxX5{inset-inline-start:calc(52px - 14px)}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_2WVCk8--eKbu6rALIpzmsn",
	"gridSides": "_2WVCk8--eKbu6rALIpzmsn",
	"grid-sides-margins": "_2lY04vOV8P99QhvNoqUJZO",
	"gridSidesMargins": "_2lY04vOV8P99QhvNoqUJZO",
	"skip-forward-component": "LD6SDcZYDKHJ9m7v9DYT_",
	"skipForwardComponent": "LD6SDcZYDKHJ9m7v9DYT_",
	"skip-backward-component": "_3u6uGbLTub6nztT3dajxX5",
	"skipBackwardComponent": "_3u6uGbLTub6nztT3dajxX5"
};