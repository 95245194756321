import { UAParser } from 'ua-parser-js';
import { globals } from './globals';
import { IOS_MSHOP, ANDROID_MSHOP, WEBPLAYER_DEVICE_TYPES } from './deviceTypes';
export function isIOSmShop() {
    const ua = new UAParser(globals.navigator.userAgent);
    const os = ua.getOS();
    return ((os.name === 'iOS' || os.name === 'Mac OS') &&
        // to check for iphones/ipads which has more maxTouchPoints
        globals.navigator.maxTouchPoints > 2 &&
        IOS_MSHOP.includes(globals.amznMusic.appConfig.deviceType));
}
export function isAndroidmShop() {
    const ua = new UAParser(globals.navigator.userAgent);
    const os = ua.getOS();
    return os.name === 'Android' && ANDROID_MSHOP.includes(globals.amznMusic.appConfig.deviceType);
}
export function isWebPlayerRequest(deviceType) {
    return WEBPLAYER_DEVICE_TYPES.includes(deviceType);
}
