exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._28KHw38bf_5u1TG2iqsZyb{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._28KHw38bf_5u1TG2iqsZyb{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._28KHw38bf_5u1TG2iqsZyb{padding-inline-start:52px;padding-inline-end:52px}}._2CQPfU7oxrDLaWXtdC-a4E{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._2CQPfU7oxrDLaWXtdC-a4E{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._2CQPfU7oxrDLaWXtdC-a4E{margin-inline-start:52px;margin-inline-end:52px}}._1cCm6S7fIL2mm34Y94BZj3{z-index:6;box-sizing:border-box;position:fixed;inset-inline-end:calc(20px + 56px)}@media only screen and (min-width: 480px){._1cCm6S7fIL2mm34Y94BZj3{inset-inline-end:calc(36px + 56px)}}@media only screen and (min-width: 1280px){._1cCm6S7fIL2mm34Y94BZj3{inset-inline-end:calc(52px + 56px)}}._3z6QXL3qicNjCm6naP_zGC{z-index:6;box-sizing:border-box;position:fixed;inset-inline-end:calc(20px + 56px)}@media only screen and (min-width: 480px){._3z6QXL3qicNjCm6naP_zGC{inset-inline-end:calc(36px + 56px)}}@media only screen and (min-width: 1280px){._3z6QXL3qicNjCm6naP_zGC{inset-inline-end:calc(52px + 56px)}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_28KHw38bf_5u1TG2iqsZyb",
	"gridSides": "_28KHw38bf_5u1TG2iqsZyb",
	"grid-sides-margins": "_2CQPfU7oxrDLaWXtdC-a4E",
	"gridSidesMargins": "_2CQPfU7oxrDLaWXtdC-a4E",
	"enter-fullscreen-component": "_1cCm6S7fIL2mm34Y94BZj3",
	"enterFullscreenComponent": "_1cCm6S7fIL2mm34Y94BZj3",
	"exit-fullscreen-component": "_3z6QXL3qicNjCm6naP_zGC",
	"exitFullscreenComponent": "_3z6QXL3qicNjCm6naP_zGC"
};