import AbstractVideoPlayer from './AbstractVideoPlayer';
export default class NativeVideoPlayer extends AbstractVideoPlayer {
    static getInstance() {
        if (!NativeVideoPlayer.instance) {
            NativeVideoPlayer.instance = new NativeVideoPlayer();
            NativeVideoPlayer.instance.initialize();
        }
        return NativeVideoPlayer.instance;
    }
    constructor() {
        super();
        this.addEventListener = (event, callback) => {
            if (!event.nativeEvent) {
                throw new Error('Event is not supported for NativeVideoPlayer');
            }
            this.videoElement.addEventListener(event.nativeEvent, callback);
        };
        this.destroyPlayer = () => {
            NativeVideoPlayer.instance = undefined;
        };
        this.getCurrentTime = () => this.videoElement.currentTime;
        this.getDuration = () => this.videoElement.duration;
        this.getLatestError = () => this.videoElement.error;
        this.getVolume = () => this.videoElement.volume;
        this.isEnded = () => this.videoElement.ended;
        this.isPaused = () => this.videoElement.paused;
        this.loadMedia = () => {
            this.videoElement.load();
        };
        this.pause = () => {
            this.videoElement.pause();
        };
        this.playMedia = () => {
            this.videoElement.play();
        };
        this.removeEventListener = (event, callback) => {
            if (event.nativeEvent) {
                this.videoElement.removeEventListener(event.nativeEvent, callback);
            }
        };
        this.seekToPosition = (position) => {
            this.videoElement.currentTime = position;
        };
        this.setAutoplay = (autoplay) => {
            this.videoElement.autoplay = autoplay;
        };
        this.setSource = (playbackUri) => {
            this.sourceElement.src = playbackUri;
        };
        this.unloadMedia = () => {
            this.videoElement.pause();
            this.sourceElement.src = '';
            this.videoElement.load();
        };
        this.volume = (volume) => {
            this.videoElement.volume = volume;
        };
        this.sourceElement = document.createElement('source');
        this.sourceElement.type = 'application/x-mpegURL';
        this.videoElement.appendChild(this.sourceElement);
    }
}
