import { BIND_TEMPLATE, CREATE_AND_BIND_TEMPLATE, CREATE_TEMPLATE, PARK_SKYFIRE_METHOD, TEMPLATE_RENDERED, URL_CHANGE, } from '../actions';
import { recordPageView } from '../utils/cloudwatchRumUtils';
import Performance from '../utils/performance';
import { reportPagePerformance } from '../utils/reportClientMetrics';
const getTemplateId = (action) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return ((_c = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.template) === null || _b === void 0 ? void 0 : _b.innerTemplate) === null || _c === void 0 ? void 0 : _c.id) ||
        ((_e = (_d = action === null || action === void 0 ? void 0 : action.payload) === null || _d === void 0 ? void 0 : _d.template) === null || _e === void 0 ? void 0 : _e.id) ||
        ((_j = (_h = (_g = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.method) === null || _g === void 0 ? void 0 : _g.template) === null || _h === void 0 ? void 0 : _h.innerTemplate) === null || _j === void 0 ? void 0 : _j.id);
};
const getDeeplink = (action) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return ((_d = (_c = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.template) === null || _b === void 0 ? void 0 : _b.innerTemplate) === null || _c === void 0 ? void 0 : _c.templateData) === null || _d === void 0 ? void 0 : _d.deeplink) ||
        ((_g = (_f = (_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.template) === null || _f === void 0 ? void 0 : _f.templateData) === null || _g === void 0 ? void 0 : _g.deeplink);
};
let addVisibilityChangeListener = true;
let visibilityChangeStartEndTime = [];
export const PagePerformanceMiddleware = (store) => (next) => (action) => {
    var _a, _b, _c;
    let { coldStart } = store.getState().TemplateStack;
    next(action);
    const templateId = getTemplateId(action);
    const deeplink = getDeeplink(action) || '';
    switch (action.type) {
        case CREATE_TEMPLATE:
            if (templateId) {
                Performance.mark(`request:${templateId}`);
                Performance.mark(`create:${templateId}`);
                if (coldStart) {
                    Performance.mark('cold-page-start');
                }
                if (addVisibilityChangeListener) {
                    document.addEventListener('visibilitychange', pageVisibilityListener);
                    addVisibilityChangeListener = false;
                }
            }
            break;
        case CREATE_AND_BIND_TEMPLATE:
            if (templateId) {
                // Same logic as CREATE_TEMPLATE
                Performance.mark(`request:${templateId}`);
                // Same logic as BIND_TEMPLATE
                Performance.mark(`request:done:${templateId}`);
                Performance.mark(`render:${templateId}`);
                Performance.mark(`createAndBind:${templateId}`);
            }
            break;
        case BIND_TEMPLATE:
            if (templateId) {
                Performance.mark(`request:done:${templateId}`);
                Performance.mark(`render:${templateId}`);
                Performance.mark(`bind:${templateId}`);
                if (coldStart) {
                    Performance.measure('cold-request', `request:${templateId}`, `request:done:${templateId}`);
                }
            }
            break;
        case PARK_SKYFIRE_METHOD:
            if (templateId) {
                Performance.clearMarks(`request:${templateId}`);
                Performance.clearMarks(`request:done:${templateId}`);
                Performance.clearMarks(`render:${templateId}`);
                Performance.clearMarks(`create:${templateId}`);
                Performance.clearMarks(`bind:${templateId}`);
                Performance.clearMarks(`createAndBind:${templateId}`);
            }
            break;
        case TEMPLATE_RENDERED:
            if (templateId) {
                let awayDuration = 0;
                if (Performance.hasMark(`render:${templateId}`)) {
                    const id = templateId.split('_').pop();
                    Performance.measure(`page:${id}`, `request:${templateId}`);
                    Performance.measure(`render:${id}`, `render:${templateId}`);
                    Performance.measure(`request:${id}`, `request:${templateId}`, `request:done:${templateId}`);
                    let pageEntry = Performance.getEntriesByName(`page:${id}`).pop();
                    let renderEntry = Performance.getEntriesByName(`render:${id}`).pop();
                    let requestEntry = Performance.getEntriesByName(`request:${id}`).pop();
                    if (coldStart && (requestEntry === null || requestEntry === void 0 ? void 0 : requestEntry.duration) === 0) {
                        Performance.measure('cold-page', 'cold-page-start');
                        pageEntry = Performance.getEntriesByName('cold-page').pop();
                        requestEntry = Performance.getEntriesByName('cold-request').pop();
                    }
                    if (renderEntry !== undefined && pageEntry !== undefined) {
                        window.sessionStorage.setItem(templateId, String(Math.round(pageEntry.duration)));
                    }
                    if (pageEntry && renderEntry && requestEntry) {
                        awayDuration = getTimeAwayFromMusicDuringRender(renderEntry);
                        renderEntry = getUpdatedPerformanceEntry(renderEntry, awayDuration);
                        pageEntry = getUpdatedPerformanceEntry(pageEntry, awayDuration);
                        const summary = {
                            page: pageEntry,
                            render: renderEntry,
                            request: requestEntry,
                        };
                        Performance.reportPageLatency(summary, deeplink, store.getState().Authentication);
                        Performance.reportMeskPerformanceLatency(summary, deeplink, store.getState().Authentication, store.getState().Storage);
                        Performance.reportParameterizedInterfaceLatency(summary, deeplink, store.getState().Authentication, store.getState().Storage);
                        Performance.clearMeasures();
                    }
                }
                else {
                    window.sessionStorage.setItem(templateId, '0');
                }
                // Report to client metrics lambda
                if (Performance.hasMark(`create:${templateId}`) ||
                    Performance.hasMark(`createAndBind:${templateId}`)) {
                    const id = templateId.split('_').pop();
                    const isCreateAndBind = Performance.hasMark(`createAndBind:${templateId}`);
                    if (isCreateAndBind) {
                        Performance.measure(`total:${id}`, `createAndBind:${templateId}`);
                    }
                    else {
                        Performance.measure(`create:${id}`, `create:${templateId}`, `bind:${templateId}`);
                        Performance.measure(`bind:${id}`, `bind:${templateId}`);
                        Performance.measure(`total:${id}`, `create:${templateId}`);
                    }
                    const pageEntry = Performance.getEntriesByName(`total:${id}`).pop();
                    const requestEntry = Performance.getEntriesByName(`create:${id}`).pop();
                    const renderEntry = Performance.getEntriesByName(`bind:${id}`).pop();
                    if (pageEntry) {
                        let pageEntryDuration = pageEntry.duration;
                        if (coldStart) {
                            if (((_b = (_a = window.performance) === null || _a === void 0 ? void 0 : _a.getEntriesByType('navigation')) === null || _b === void 0 ? void 0 : _b.length) > 0 &&
                                ((_c = window.performance) === null || _c === void 0 ? void 0 : _c.timeOrigin)) {
                                const newPageEntry = getColdStartPageLatency(pageEntry);
                                if (newPageEntry) {
                                    pageEntryDuration = newPageEntry.duration;
                                }
                            }
                            else {
                                coldStart = false;
                            }
                        }
                        const summary = {
                            pageDuration: pageEntryDuration - awayDuration,
                            requestDuration: requestEntry === null || requestEntry === void 0 ? void 0 : requestEntry.duration,
                            renderDuration: renderEntry
                                ? renderEntry.duration - awayDuration
                                : undefined,
                        };
                        reportPagePerformance(store.getState().Authentication, deeplink, isCreateAndBind, summary, coldStart);
                    }
                    Performance.clearMarks(`create:${templateId}`);
                    Performance.clearMarks(`bind:${templateId}`);
                    Performance.clearMarks(`createAndBind:${templateId}`);
                    Performance.clearMeasures();
                }
                recordPageView(deeplink);
                visibilityChangeStartEndTime = [];
                document.removeEventListener('visibilitychange', pageVisibilityListener);
                addVisibilityChangeListener = true;
            }
            break;
        case URL_CHANGE:
            Performance.clearMarks();
            Performance.clearMeasures();
            break;
        default:
            break;
    }
};
function getColdStartPageLatency(pageEntry) {
    var _a, _b;
    const navPref = (_a = window.performance) === null || _a === void 0 ? void 0 : _a.getEntriesByType('navigation')[0];
    const reqStartTime = ((_b = window.performance) === null || _b === void 0 ? void 0 : _b.timeOrigin) + navPref.requestStart;
    const coldStartPageLatency = new Date().getTime() - reqStartTime;
    const newPageEntry = {
        duration: coldStartPageLatency,
        entryType: pageEntry.entryType,
        name: pageEntry.name,
        startTime: pageEntry.startTime,
        toJSON() { },
    };
    return newPageEntry;
}
function pageVisibilityListener() {
    visibilityChangeStartEndTime.push(performance.now());
}
function getUpdatedPerformanceEntry(entry, awayFromMusicDuration = 0) {
    return entry.duration
        ? Object.create(PerformanceEntry.prototype, {
            name: { value: entry === null || entry === void 0 ? void 0 : entry.name, writable: false },
            entryType: { value: entry === null || entry === void 0 ? void 0 : entry.entryType, writable: false },
            startTime: { value: entry === null || entry === void 0 ? void 0 : entry.startTime, writable: true },
            duration: { value: (entry === null || entry === void 0 ? void 0 : entry.duration) - awayFromMusicDuration, writable: true },
        })
        : entry;
}
/*
    This method calculates the total time user was away from music.
    There are 2 scenarios to be handled here,
    1. When music page loads in hidden state,that would happen if page loaded in separate tab.
       In this case, there will be odd no of entries in  visibilityChangeStartEndTime array,
       as the first event would be for visible when user switches to music tab, and there would
       not be any entry for hidden state, as that was never triggered.
    2. When music page is loaded in visible state, for ex. user opens a page being on music tab.
       In this case, there would be even number of entries in visibilityChangeStartEndTime array,
       for ex. (hidden, visible), (hidden, visible) and so on.
 */
function getTimeAwayFromMusicDuringRender(renderEntry) {
    let totalTimeAwayFromMusic = 0;
    if (renderEntry) {
        for (let i = 0; i + 1 < visibilityChangeStartEndTime.length; i += 2) {
            const end = visibilityChangeStartEndTime.pop();
            const start = visibilityChangeStartEndTime.pop();
            if (end && end < renderEntry.startTime) {
                break;
            }
            if (start && end) {
                if (start > renderEntry.startTime) {
                    totalTimeAwayFromMusic += end - start;
                }
                else {
                    totalTimeAwayFromMusic += end - renderEntry.startTime;
                }
            }
        }
        if (visibilityChangeStartEndTime.length > 0) {
            const end = visibilityChangeStartEndTime.pop();
            if (end && end > renderEntry.startTime) {
                totalTimeAwayFromMusic += end - renderEntry.startTime;
            }
        }
    }
    return totalTimeAwayFromMusic;
}
