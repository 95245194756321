import {
    SET_PLAYBACK_START_OFFSET,
    SET_PRE_SCRUB_POSITION,
    SET_PRE_SCRUB_START_OFFSET,
    STARTED,
} from '../actions';

export interface IPodcastState {
    podcastEpisodeStartedPlaybackAt: number;
    playbackStartOffset: number;
    playbackPreScrubPosition: number;
    playbackPreScrubStartOffset: number;
}

export const initialState: IPodcastState = {
    podcastEpisodeStartedPlaybackAt: 0,
    playbackStartOffset: 0,
    playbackPreScrubPosition: 0,
    playbackPreScrubStartOffset: 0,
};

export function PodcastStateReducer(
    state = initialState,
    action: any
): IPodcastState {
    switch (action.type) {
        case STARTED:
            return {
                ...state,
                podcastEpisodeStartedPlaybackAt: Date.now(),
            };
        case SET_PLAYBACK_START_OFFSET:
            return {
                ...state,
                playbackStartOffset: action.payload.playbackStartOffset,
            };
        case SET_PRE_SCRUB_POSITION:
            return {
                ...state,
                playbackPreScrubPosition: action.payload.position,
            };
        case SET_PRE_SCRUB_START_OFFSET:
            return {
                ...state,
                playbackPreScrubStartOffset: action.payload.startOffset,
            };
        default:
            return state;
    }
}
