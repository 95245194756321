import IPodcastMethod from '../types/IPodcastMethod';
import ISkyfireMethod from '../types/ISkyfireMethod';
import {
    getAppVersion,
    getMusicDomain,
    getIpAddress,
    getInitialPath,
    getClientStateObject,
    isIOSUserAgent,
} from '.';
import { getDefaultLanguage } from './getDefaultLanguage';
import { USER_TIER } from '../appsync/AppSyncConstants';

const fetchNative: (...args: any) => Promise<Response> =
    window?.fetch && window?.fetch.bind(window);

export async function podcastRequest(request: {
    url: string;
    headers: any;
    store;
    requestInfo: any;
    preset?: string;
    methodBody?: any;
}): Promise<ISkyfireMethod[]> {
    const { headers, url, store, requestInfo, preset, methodBody } = request;
    const {
        customerId,
        deviceId,
        deviceType,
        marketplaceId,
        sessionId,
        accessToken,
        csrf,
        timeZone,
    } = requestInfo;
    const ipAddress = getIpAddress();
    const appVersion = getAppVersion();
    // document.cookie returns serialized string with all cookies separated by delimeter semi-colon (;).
    // The values of each cookie is wrapped under \\
    const isOptedOutOfIBA =
        document.cookie.match('(^|;)\\s*apn-privacy\\s*=\\s*([^;]+)')?.pop() ||
        false;

    const requestBody: IPodcastMethod = {
        preset: preset || '{}',
        identity: {
            __type: 'SOACoreInterface.v1_0#Identity',
            application: getApplicationIdentity(appVersion),
            user: getUserIdentity(customerId, accessToken),
            request: getRequestInfo(sessionId, ipAddress, csrf),
            device: getDeviceInfo(
                deviceId,
                deviceType,
                marketplaceId,
                timeZone,
                store
            ),
        },
        clientStates: getClientStateObject(methodBody, store),
        extra: {},
    };
    const response = await fetchNative(url, {
        method: 'post',
        headers,
        body: JSON.stringify(requestBody),
    });
    const json = await response.json();
    let { methods } = json;
    if (Array.isArray(json.methods)) {
        // eslint-disable-next-line array-callback-return
        methods.map((method) => {
            if (method.content !== null || method.content !== '') {
                methods = JSON.parse(method.content);
                if (isOptedOutOfIBA) {
                    methods.forEach((met) => {
                        updateMediaIdWithPrivacyPrefs(met);
                    });
                }
            }
        });
    }
    setCustomerTier();
    return methods;
}

export function getDeviceInfo(
    deviceId: string,
    deviceType: string,
    marketplaceId: string,
    timeZone: string,
    store
) {
    return {
        __type: 'SOACoreInterface.v1_0#DeviceIdentity',
        id: deviceId,
        typeId: deviceType,
        model: isIOSUserAgent() ? 'MOBILESAFARI' : 'WEBPLAYER',
        timeZone,
        language: getDefaultLanguage(marketplaceId, store),
        height: window.innerHeight.toString(),
        width: window.innerWidth.toString(),
        osVersion: 'n/a',
        manufacturer: 'n/a',
    };
}

export function getPodcastHeaders(target?: any) {
    return {
        'x-amz-target':
            target ||
            'com.amazon.dmpbrowsevisualservice.skills.DMPBrowseVisualService.BootstrapWebSkill',
        'Content-Type': 'application/json; charset=UTF-8',
        'x-amzn-RequestId': generateRequestId(),
        Connection: 'keep-alive',
        'Content-Encoding': 'amz-1.0',
    };
}

export function generateRequestId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function getDeepLink() {
    const url = getInitialPath();
    return {
        deeplink: {
            url,
            __type: 'Podcast.DeeplinkInterface.v1_0#DeeplinkClientState',
        },
    };
}

export function getApplicationIdentity(appVersion: string) {
    return {
        __type: 'SOACoreInterface.v1_0#ApplicationIdentity',
        version: appVersion,
    };
}

export function getUserIdentity(customerId: string, auth: string) {
    return {
        __type: 'SOACoreInterface.v1_0#UserIdentity',
        customerId,
        authentication: auth,
    };
}

export function getRequestInfo(sessionId: string, ipAddress: string, csrf) {
    const request: any = {
        __type: 'SOACoreInterface.v1_0#RequestIdentity',
        id: generateRequestId(),
        sessionId,
        ipAddress,
        timestamp: Date.now(),
        domain: getMusicDomain(),
    };
    if (csrf) {
        request.csrf = {
            __type: 'SOACoreInterface.v1_0#Csrf',
            token: csrf.token,
            ts: csrf.ts,
            rnd: csrf.rnd,
        };
    }
    return request;
}

function setCustomerTier() {
    try {
        window.sessionStorage.setItem(
            USER_TIER,
            window.amznMusic.appConfig.tier
        );
    } catch (error) {
        console.error(error);
    }
}

/**
 * For a given method, updates the corresponding mediaID, aka, the endpoint of the hosted audio file for the podcast
 * This endpoint is owned by Hosting Providers who serves ads in podcast. Hence, passing the privacy prefs as
 * query parameter `sec-gpc`
 * @param method
 */
function updateMediaIdWithPrivacyPrefs(method: any) {
    if (method?.metadata?.mediaId !== undefined) {
        method.metadata.mediaId = getMediaIdWithPrivacyPrefs(
            method.metadata.mediaId
        );
    } else if (method?.mediaId !== undefined) {
        method.mediaId = getMediaIdWithPrivacyPrefs(method.mediaId);
    }
}

/**
 * For a given media ID, updates it by appending `sec-gpc` as a query parameter. If the mediaId is not pointing to
 * a URL, then it's not modified and returned as is.
 * @param mediaId
 */
function getMediaIdWithPrivacyPrefs(mediaId: string) {
    try {
        const mediaIdURL = new URL(mediaId);
        if (!mediaIdURL.searchParams.has('sec-gpc')) {
            mediaIdURL.searchParams.append('sec-gpc', '1');
        }
        return mediaIdURL.href;
    } catch (e) {
        // ignore non url based media Id and return it as is.
        return mediaId;
    }
}
