export const BOOKMARKS_UPPER_BOUND_PERCENTAGE: number = 95;
export const BOOKMARKS_LOWER_BOUND_PROGRESS_MS: number = 15000;
export const IN_PROGRESS_LOWER_BOUND_MILLISECONDS: string =
    'inProgressLowerBoundMilliseconds';
export const IN_PROGRESS_UPPER_BOUND_PERCENTAGE: string =
    'inProgressUpperBoundPercentage';
export const CLEAR_APP_SYNC_STATES_METHOD =
    'Podcast.Web.AppSyncInterface.ClearAppSyncStatesMethod';
export const SET_BOOKMARK_THRESHOLDS_METHOD =
    'Podcast.Web.BookmarkInterface.SetBookmarksThresholdsMethod';
export const SET_BOOKMARKS_LAST_SYNC_TIME_METHOD =
    'Podcast.Web.BookmarkInterface.SetBookmarksLastSyncTimeMethod';
export const SET_SAVES_LAST_SYNC_TIME_METHOD =
    'Podcast.Web.SaveInterface.SetSavesLastSyncTimeMethod';
export const SET_FOLLOWS_LAST_SYNC_TIME_METHOD =
    'Podcast.Web.FollowInterface.SetFollowsLastSyncTimeMethod';
export const SET_COMPLETED_LAST_SYNC_TIME_METHOD =
    'Podcast.Web.FollowInterface.SetCompletedLastSyncTimeMethod';
export const UI_METRICS_SET_ON_REPORT_OPERATIONS_METHOD =
    'Podcast.Web.UIMetricsInterface.SetOnReportOperationsMethod';
export const PLAYBACK_METRICS_SET_ON_REPORT_OPERATIONS_METHOD =
    'Podcast.Web.PlaybackMetricsInterface.SetOnReportOperationsMethod';
export const BOOKMARKS_SET_ON_REPORT_OPERATIONS_METHOD =
    'Podcast.Web.BookmarkInterface.SetOnReportOperationsMethod';
export const SAVES_SET_OP_REPORT_OPERATIONS_METHOD =
    'Podcast.Web.SaveInterface.SetOnReportOperationsMethod';
export const FOLLOWS_SET_ON_REPORT_OPERATIONS_METHOD =
    'Podcast.Web.FollowInterface.SetOnReportOperationsMethod';
export const COMPLETED_SET_ON_REPORT_OPERATIONS_METHOD =
    'Podcast.Web.CompletedInterface.SetOnReportOperationsMethod';
export const APPSYNC_RESOLVE_OPERATIONS_METHOD =
    'Podcast.Web.AppSyncInterface.ResolveOperationMethod';
export const UI_METRICS_WRITE_ELEMENT =
    'Podcast.Web.UIMetricsInterface.UIMetricWriteElement';
export const PLAYBACK_METRICS_WRITE_ELEMENT =
    'Podcast.Web.PlaybackMetricsInterface.PlaybackMetricWriteElement';
export const BOOKMARKS_WRITE_ELEMENT =
    'Podcast.Web.BookmarkInterface.BookmarkWriteElement';
export const SAVES_WRITE_ELEMENT = 'Podcast.Web.SaveInterface.SaveWriteElement';
export const FOLLOWS_WRITE_ELEMENT =
    'Podcast.Web.FollowInterface.FollowWriteElement';
export const COMPLETED_WRITE_ELEMENT =
    'Podcast.Web.CompletedInterface.CompletedWriteElement';
export const APPSYNC_WRITE_METHOD = 'Podcast.Web.AppSyncInterface.WriteMethod';
export const BOOKMARKS_LAST_SYNC_TIME_CLIENT_STATE =
    'BookmarksLastSyncTimeClientState';
export const BOOKMARKS_LAST_SYNC_TIME = 'bookmarksLastSyncTime';
export const SAVES_LAST_SYNC_TIME_CLIENT_STATE = 'SavesLastSyncTimeClientState';
export const SAVES_LAST_SYNC_TIME = 'savesLastSyncTime';
export const FOLLOWS_LAST_SYNC_TIME_CLIENT_STATE =
    'FollowsLastSyncTimeClientState';
export const FOLLOWS_LAST_SYNC_TIME = 'followsLastSyncTime';
export const FOLLOWER_OPERATION_CLIENT_INFORMATION =
    'Podcast.Web.FollowInterface.FollowOperationsClientInformation';
export const SAVE_OPERATIONS_CLIENT_INFORMATION =
    'Podcast.Web.SaveInterface.SaveOperationsClientInformation';
export const BOOKMARK_OPERATIONS_CLIENT_INFORMATION =
    'Podcast.Web.BookmarkInterface.BookmarkOperationsClientInformation';
export const COMPLETED_LAST_SYNC_TIME_CLIENT_STATE =
    'CompletedLastSyncTimeClientState';
export const COMPLETED_LAST_SYNC_TIME = 'completedLastSyncTime';
export const COMPLETED_OPERATIONS_CLIENT_INFORMATION =
    'Podcast.Web.CompletedInterface.CompletedOperationsClientInformation';
export const PLAYBACK_METRICS_OPERATIONS_CLIENT_INFORMATION =
    'Podcast.Web.PlaybackMetricsInterface.PlaybackMetricsOperationsClientInformation';
export const FOLLOW_LAST_SYNC_TIME_CLIENT_INFORMATION =
    'Podcast.Web.FollowInterface.FollowsLastSyncTimeClientInformation';
export const SAVE_LAST_SYNC_TIME_CLIENT_INFORMATION =
    'Podcast.Web.SaveInterface.SavesLastSyncTimeClientInformation';
export const BOOKMARKS_LAST_SYNC_TIME_CLIENT_INFORMATION =
    'Podcast.Web.BookmarkInterface.BookmarksLastSyncTimeClientInformation';
export const COMPLETED_LAST_SYNC_TIME_CLIENT_INFORMATION =
    'Podcast.Web.CompletedInterface.CompletedLastSyncTimeClientInformation';
export const UI_METRICS_OPERATIONS_CLIENT_INFORMATION =
    'Podcast.Web.UIMetricsInterface.UIMetricsOperationsClientInformation';
export const BOOKMARK_OPERATIONS_CLIENT_STATE = 'BookmarkOperationsClientState';
export const SAVE_OPERATIONS_CLIENT_STATE = 'SaveOperationsClientState';
export const FOLLOW_OPERATIONS_CLIENT_STATE = 'FollowOperationsClientState';
export const COMPLETED_OPERATIONS_CLIENT_STATE =
    'CompletedOperationsClientState';
export const USER_TIER = 'UserTier';
// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export const enum AppSyncTypes {
    FOLLOW = 'follow',
    SAVE = 'saves',
    BOOKMARK = 'bookmark',
    COMPLETED = 'completed',
    PLAYBACK_METRIC = 'playbackMetric',
    UI_METRIC = 'uiMetric',
}
export const WEB_STORAGE = window.amznMusic?.appConfig
    ?.isRedhoodLocalStorageEnabled
    ? window.localStorage
    : window.sessionStorage;
