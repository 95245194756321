import DOMPurify from 'dompurify';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import * as Styles from './Banner.scss';
import TextWithLink from './widgets/items/TextWithLink';
import { dispatchSkyfireMethods } from '../utils';
export default function Banner(props) {
    useStyles(Styles);
    const dispatch = useDispatch();
    const banner = useSelector((state) => state.Banner.banner);
    if (!banner)
        return null;
    const { header, text, expandText, collapseText, primaryButtons, secondaryButtons, footer, } = banner;
    return (React.createElement("div", { id: "banner", className: Styles.bannerContainer },
        React.createElement("div", { className: Styles.bannerContent },
            React.createElement("div", { id: "bannerHeader", className: "music-headline-4" }, header),
            React.createElement(BannerBody, { text: text, expandText: expandText, collapseText: collapseText }),
            React.createElement("div", { id: "bannerFooter", className: Styles.bannerFooter },
                primaryButtons.map((button) => {
                    const handleSelected = () => {
                        dispatchSkyfireMethods(dispatch, props.template, button.onItemSelected);
                    };
                    return (React.createElement("music-button", { key: button.text, variant: "solid", onmusicActivate: handleSelected }, button.text));
                }),
                secondaryButtons.map((button) => {
                    const handleSelected = () => {
                        dispatchSkyfireMethods(dispatch, props.template, button.onItemSelected);
                    };
                    return (React.createElement("music-button", { key: button.text, variant: "outline", onmusicActivate: handleSelected }, button.text));
                }),
                footer.map((textWithLink) => (React.createElement(TextWithLink, { key: textWithLink.text, data: textWithLink, template: props.template })))))));
}
function BannerBody(props) {
    var _a;
    const sanitizedText = DOMPurify.sanitize((_a = props.text) !== null && _a !== void 0 ? _a : '');
    const fullBody = (React.createElement("p", { className: "music-primary-text", 
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML: { __html: sanitizedText } }));
    const [truncatedTextCollapsed, setTruncatedTextCollapsed] = useState(true);
    const toggleTruncatedTextCollapsed = () => setTruncatedTextCollapsed((b) => !b);
    const truncatedBody = (React.createElement(Fragment, null,
        React.createElement("p", { className: [
                'music-primary-text',
                truncatedTextCollapsed ? Styles.bannerTruncatedText : '',
            ].join(' '), 
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML: {
                __html: sanitizedText,
            } }),
        React.createElement("div", { className: Styles.expandableTextControls, onClick: toggleTruncatedTextCollapsed },
            React.createElement("span", { className: "bold-font" }, truncatedTextCollapsed ? props.expandText : props.collapseText),
            React.createElement("music-icon", { name: truncatedTextCollapsed ? 'caretdown' : 'caretUp' }))));
    const bannerBody = (React.createElement("div", { id: "bannerBody", className: Styles.bannerBody },
        React.createElement("div", { className: Styles.bannerFull }, fullBody),
        React.createElement("div", { className: Styles.bannerTruncated }, truncatedBody)));
    return bannerBody;
}
