exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2HNUO2-7L9ew8R6ggtZcfo,._110S2V3ftBiqNy4O08LFpj,.pbuWItj5KffTwP7qpecAn{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._2HNUO2-7L9ew8R6ggtZcfo,._110S2V3ftBiqNy4O08LFpj,.pbuWItj5KffTwP7qpecAn{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._2HNUO2-7L9ew8R6ggtZcfo,._110S2V3ftBiqNy4O08LFpj,.pbuWItj5KffTwP7qpecAn{padding-inline-start:52px;padding-inline-end:52px}}._2-H83303HIb3Q7QQfQnpZC{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._2-H83303HIb3Q7QQfQnpZC{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._2-H83303HIb3Q7QQfQnpZC{margin-inline-start:52px;margin-inline-end:52px}}music-container{display:block}._1spnb_2PhDB1fLErNuZlo4{color:#fff;width:100%;height:auto;padding-top:72px;padding-bottom:calc(80px + var(--music-spacer-large));box-sizing:border-box}._1spnb_2PhDB1fLErNuZlo4>*{position:relative}._1spnb_2PhDB1fLErNuZlo4 music-tooltip{position:fixed;bottom:10px;inset-inline-start:0;inset-inline-end:0;margin:auto;margin-bottom:5px}._3Q4avt9T839GnGZ8ys0IBC{padding-top:0}.pbuWItj5KffTwP7qpecAn{margin-top:var(--music-spacer-large);margin-bottom:var(--music-spacer-large);cursor:default;-webkit-user-select:none;-ms-user-select:none;user-select:none}._2bxo6tDlZ4bkSI-4v6wQYD{color:rgba(255,255,255,.6);max-width:560px;margin:0;line-height:var(--music-spacer-medium);white-space:pre-line}._110S2V3ftBiqNy4O08LFpj{margin-top:var(--music-spacer-giant)}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_2HNUO2-7L9ew8R6ggtZcfo",
	"gridSides": "_2HNUO2-7L9ew8R6ggtZcfo",
	"footer": "_110S2V3ftBiqNy4O08LFpj",
	"header": "pbuWItj5KffTwP7qpecAn",
	"grid-sides-margins": "_2-H83303HIb3Q7QQfQnpZC",
	"gridSidesMargins": "_2-H83303HIb3Q7QQfQnpZC",
	"view-content": "_1spnb_2PhDB1fLErNuZlo4",
	"viewContent": "_1spnb_2PhDB1fLErNuZlo4",
	"no-nav": "_3Q4avt9T839GnGZ8ys0IBC",
	"noNav": "_3Q4avt9T839GnGZ8ys0IBC",
	"sub-header": "_2bxo6tDlZ4bkSI-4v6wQYD",
	"subHeader": "_2bxo6tDlZ4bkSI-4v6wQYD"
};