import React, { useLayoutEffect, useRef } from 'react';
import useOnScreen from '../utils/useOnScreen';

interface ILoadingWidgetProps {
    onReached?: () => void;
}

export default function LoadingWidget(props: ILoadingWidgetProps) {
    const ref = useRef();
    const onScreen = useOnScreen(ref, '-50px');
    const { onReached } = props;
    useLayoutEffect(() => {
        if (onScreen && onReached) {
            onReached();
        }
    }, [onScreen, onReached]);

    return (
        <music-shoveler
            ref={ref}
            style={{ display: 'grid', justifyItems: 'center' }}
        >
            <div style={{ width: 24, height: 24 }}>
                <music-icon style={{ width: 24, height: 24 }} name='loader' />
            </div>
        </music-shoveler>
    );
}
