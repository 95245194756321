import { DndContext, closestCenter, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import withStyles from 'isomorphic-style-loader/withStyles';
import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer';
import { WindowScroller } from 'react-virtualized/dist/commonjs/WindowScroller';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { computeContainerWidth } from '../../../utils/gridHelpers';
import LoadingWidget from '../../LoadingWidget';
import * as infiniteListStyles from './InfiniteList.scss';
import InfiniteListItem from './InfiniteListItem';
import { RWP_DESKTOP_BROWSER_DEVICE_TYPES } from '../../../utils/deviceTypes';
import { globals } from '../../../utils';
class InfiniteList extends React.Component {
    constructor(props) {
        super(props);
        this.infiniteLoaderCallback = () => {
            var _a;
            if ((_a = this.props) === null || _a === void 0 ? void 0 : _a.isResizableRowList) {
                // eslint-disable-next-line react/display-name
                return ({ onItemsRendered, ref }) => (React.createElement("div", { className: infiniteListStyles.autoSizeContainer },
                    React.createElement(AutoSizer, null, ({ width, height }) => (React.createElement("div", { ref: ref, className: infiniteListStyles.removeOutline }, 
                    // eslint-disable-next-line react/no-this-in-sfc
                    this.renderList({
                        onItemsRendered,
                        width,
                        height,
                    }))))));
            }
            // eslint-disable-next-line react/display-name
            return ({ onItemsRendered, ref }) => (React.createElement("div", { ref: ref, className: infiniteListStyles.removeOutline }, 
            // eslint-disable-next-line react/no-this-in-sfc
            this.renderList({
                onItemsRendered,
                height: window.innerHeight,
            })));
        };
        this.getMaxIndex = () => this.maxIndex.current;
        this.setMaxIndex = (index) => {
            var _a;
            this.maxIndex.current = Math.max((_a = this.maxIndex.current) !== null && _a !== void 0 ? _a : -1, index);
        };
        this.setStateCallback = (obj) => this.setState(obj);
        this.renderList = (listProps) => {
            const { onItemsRendered, width, height } = listProps;
            const { windowWidth, rowHeight, data, componentType, handleSelected, isDynamicRowHeightEnabled, isResizableRowList, itemsViewedRef, loading, scrollToIndex, isHighlightingEnabled, } = this.props;
            const { items } = data;
            const { rowHoverIndex, rowHoverHeight } = this.state;
            const containerWidth = isResizableRowList ? width : computeContainerWidth(windowWidth || 0);
            const count = items.length;
            const itemSize = (index) => isDynamicRowHeightEnabled && index === rowHoverIndex ? rowHoverHeight : rowHeight;
            const listItemKey = (index, itemData) => index + itemData.items[index].primaryText;
            const listItemData = {
                componentType,
                handleSelected,
                isDynamicRowHeightEnabled,
                items,
                itemsViewedRef,
                loading,
                rowHeight,
                setState: this.setStateCallback,
                getMaxIndex: this.getMaxIndex,
                setMaxIndex: this.setMaxIndex,
                isHighlightingEnabled,
            };
            const isRetailWebDevice = RWP_DESKTOP_BROWSER_DEVICE_TYPES.includes(globals.amznMusic.appConfig.deviceType);
            const bottomMargin = isRetailWebDevice ? infiniteListStyles.lastBottomMargin : {};
            // ref: https://web.dev/articles/virtualize-long-lists-react-window#overscanning
            const overscanCount = Math.max(10, count + 1 - Math.ceil(height / rowHeight));
            return (React.createElement(VariableSizeList, { className: !isResizableRowList ? infiniteListStyles.list : bottomMargin, ref: this.listRef, height: height, width: containerWidth, itemCount: count, itemKey: listItemKey, itemData: listItemData, estimatedItemSize: rowHeight, itemSize: itemSize, onItemsRendered: onItemsRendered, initialScrollOffset: scrollToIndex * rowHeight, overscanCount: overscanCount }, this.renderItem));
        };
        this.renderItem = ({ index, style, data }) => (React.createElement(InfiniteListItem, { index: index, style: style, data: data, sortDisabled: !this.props.isDragAndDropEnabled }));
        this.setState({
            rowHoverIndex: null,
            rowHoverHeight: 0,
        });
        this.listRef = createRef();
        this.maxIndex = createRef();
        this.maxIndex.current = -1;
    }
    componentDidUpdate(prevProps, prevState) {
        var _a, _b, _c;
        if (((_a = this.props) === null || _a === void 0 ? void 0 : _a.isResizableRowList) &&
            ((_b = this.props) === null || _b === void 0 ? void 0 : _b.isDynamicRowHeightEnabled) &&
            this.state.rowHoverIndex !== prevState.rowHoverIndex) {
            (_c = this.listRef.current) === null || _c === void 0 ? void 0 : _c.resetAfterIndex(0);
        }
    }
    render() {
        var _a, _b, _c;
        const { onEndOfWidget, items } = this.props.data;
        const count = items.length;
        // Functions to check when to load more items.
        const loadMore = () => this.props.handleSelected(onEndOfWidget);
        // InfiniteLoader will call loadMoreRows callback when isRowLoaded returns false.
        // We now say the last row is "not loaded" so InfiniteLoader will call loadMoreRows
        // when we get to the last row, if we know onEndOfWidget exists.
        const isItemLoaded = (index) => index < ((onEndOfWidget === null || onEndOfWidget === void 0 ? void 0 : onEndOfWidget.length) ? items.length - 1 : items.length);
        const showLoader = (onEndOfWidget === null || onEndOfWidget === void 0 ? void 0 : onEndOfWidget.length) > 0;
        const visualPlayQueueClass = ((_a = this.props) === null || _a === void 0 ? void 0 : _a.isVisualPlayQueue)
            ? infiniteListStyles.visualPlayQueue
            : '';
        const textRowClass = ((_b = this.props) === null || _b === void 0 ? void 0 : _b.isTextRow) ? infiniteListStyles.isTextRow : '';
        const resizableRowClass = ((_c = this.props) === null || _c === void 0 ? void 0 : _c.isResizableRowList)
            ? infiniteListStyles.isResizableRowList
            : '';
        const infiniteListStyle = [
            infiniteListStyles.infiniteList,
            visualPlayQueueClass,
            textRowClass,
            resizableRowClass,
        ].join(' ');
        const infiniteLoader = (React.createElement(InfiniteLoader, { isItemLoaded: isItemLoaded, itemCount: count, loadMoreItems: loadMore }, this.infiniteLoaderCallback()));
        const onScroll = ({ scrollTop }) => { var _a; return (_a = this.listRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(scrollTop); };
        const windowScroller = React.createElement(WindowScroller, { onScroll: onScroll }, () => React.createElement("div", null));
        return (React.createElement("div", { className: infiniteListStyle },
            windowScroller,
            infiniteLoader,
            showLoader && React.createElement(LoadingWidget, null)));
    }
}
function mapStateToProps(state) {
    return {
        windowWidth: state.BrowserState.windowWidth,
    };
}
function mapDispatchToProps(dispatch) {
    return {};
}
const VirtualList = withStyles(infiniteListStyles)(connect(mapStateToProps, mapDispatchToProps)(InfiniteList));
export default function SortableComponent(props) {
    var _a, _b;
    const isDragAndDropEnabled = ((_b = (_a = props.handleReorder) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0;
    const { items } = props.data;
    const mouseSensor = useSensor(MouseSensor);
    const sensors = useSensors(mouseSensor);
    if (!isDragAndDropEnabled) {
        return React.createElement(VirtualList, Object.assign({}, props, { isDragAndDropEnabled: false }));
    }
    const id = (item, index) => index + item.primaryText;
    const onDragEnd = ({ active, over }) => {
        var _a;
        if (over && active.id !== over.id) {
            (_a = props.handleReorder) === null || _a === void 0 ? void 0 : _a.call(props, items.findIndex((e, i) => id(e, i) === over.id), items.findIndex((e, i) => id(e, i) === active.id));
        }
    };
    return (React.createElement(DndContext, { sensors: sensors, modifiers: [restrictToVerticalAxis, restrictToFirstScrollableAncestor], collisionDetection: closestCenter, onDragEnd: onDragEnd },
        React.createElement(SortableContext, { items: items.map(id), strategy: verticalListSortingStrategy },
            React.createElement(VirtualList, Object.assign({}, props, { isDragAndDropEnabled: true })))));
}
