import { RENDER, UNRENDER } from '../actions';
export function ContainerAppRenderReducer(state = initialState, action) {
    switch (action.type) {
        case RENDER:
            return Object.assign(Object.assign({}, state), { isRendering: true });
        case UNRENDER:
            return Object.assign(Object.assign({}, state), { isRendering: false });
        default:
            return state;
    }
}
const initialState = {
    isRendering: true,
};
