import React from 'preact/compat';
import useStyles from 'isomorphic-style-loader/useStyles';
import { LoadingSpinner } from '../components/LoadingSpinner';
import * as Styles from './SpinnerLoadingOverlay.scss';
/**
 *  This component shows full screen overlay spinning loader with loading message till the methods
 *  are executed and then removes itself from the overlayTemplates once method execution completes.
 *  Executing methods and removing template is handled in
 *  {@link SkyfireMethodExecutionMiddleware}
 */
export default function SpinnerLoadingOverlay(props) {
    useStyles(Styles);
    return (React.createElement("div", null,
        React.createElement("div", { className: Styles.content },
            React.createElement(LoadingSpinner, null),
            props.template.loadingMessage && (React.createElement("p", { className: Styles.loadingMessage }, props.template.loadingMessage)))));
}
