exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3OjqQVr85dwAgCbpWCKph{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._3OjqQVr85dwAgCbpWCKph{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._3OjqQVr85dwAgCbpWCKph{padding-inline-start:52px;padding-inline-end:52px}}._33p4gDYR56Q_lStih4EHoy{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._33p4gDYR56Q_lStih4EHoy{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._33p4gDYR56Q_lStih4EHoy{margin-inline-start:52px;margin-inline-end:52px}}._2YU9diR5jFW2LUCvkYBfzZ{display:flex;position:fixed;justify-content:center;width:100%;height:100%;z-index:6;background-color:rgba(0,0,0,.96);color:#fff;transition:opacity 400ms ease-in}@supports((-webkit-backdrop-filter: blur(32px)) or (backdrop-filter: blur(32px))){._2YU9diR5jFW2LUCvkYBfzZ{background-color:rgba(0,0,0,.6)}}._2YU9diR5jFW2LUCvkYBfzZ ._3oYFNOvxL5Xhr3g94lQYrn{position:fixed;top:50%;text-align:center;white-space:pre-line;margin-top:2.8em;font-size:15px;line-height:20px}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_3OjqQVr85dwAgCbpWCKph",
	"gridSides": "_3OjqQVr85dwAgCbpWCKph",
	"grid-sides-margins": "_33p4gDYR56Q_lStih4EHoy",
	"gridSidesMargins": "_33p4gDYR56Q_lStih4EHoy",
	"content": "_2YU9diR5jFW2LUCvkYBfzZ",
	"loading-message": "_3oYFNOvxL5Xhr3g94lQYrn",
	"loadingMessage": "_3oYFNOvxL5Xhr3g94lQYrn"
};