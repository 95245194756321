import useStyles from 'isomorphic-style-loader/useStyles';
import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import * as Styles from './SignalChain.scss';
import { dispatchSkyfireMethods } from '../../../utils';
import { useObserver } from '../../../utils/ObserverHooks';
function ChainNode(props) {
    const { primaryText, secondaryText, icon } = useObserver(props.data);
    return (React.createElement("div", { className: Styles.chainNode },
        icon && (React.createElement("div", { className: Styles.chainNodeIcon },
            React.createElement("music-icon", { name: icon, size: "small" }))),
        (primaryText || secondaryText) && (React.createElement("div", { className: Styles.chainNodeText },
            React.createElement("div", { className: "music-primary-text" }, primaryText),
            React.createElement("div", { className: "music-secondary-text" }, secondaryText)))));
}
function ChainLink(props) {
    const dispatch = useDispatch();
    const { text, icon, secondaryIcon, link } = useObserver(props.data);
    const handleClick = () => dispatchSkyfireMethods(dispatch, props.template, link === null || link === void 0 ? void 0 : link.onItemSelected);
    const chainLinkContent = (React.createElement(Fragment, null,
        text && React.createElement("div", { className: `music-secondary-text ${Styles.chainLinkText}` }, text),
        secondaryIcon && (React.createElement("div", { className: Styles.chainLinkSecondaryIcon },
            React.createElement("music-icon", { name: secondaryIcon, size: "tiny" })))));
    return (React.createElement("div", { className: Styles.chainLink },
        React.createElement("div", { className: Styles.chainLinkLine }),
        icon && (React.createElement("div", { className: Styles.chainLinkIcon },
            React.createElement("music-icon", { name: icon, size: "tiny" }))),
        link ? (React.createElement("music-link", { onClick: () => handleClick() }, chainLinkContent)) : (chainLinkContent)));
}
function SignalChain(props) {
    const { chainNodeElements, chainLinkElements } = props.data;
    useStyles(Styles);
    if (!(chainNodeElements === null || chainNodeElements === void 0 ? void 0 : chainNodeElements.length))
        return null;
    const elements = [];
    for (let i = 0; i < chainNodeElements.length; i++) {
        elements.push(React.createElement(ChainNode, { data: chainNodeElements[i] }));
        if (chainLinkElements === null || chainLinkElements === void 0 ? void 0 : chainLinkElements[i]) {
            elements.push(React.createElement(ChainLink, { data: chainLinkElements[i], template: props.template }));
        }
    }
    return React.createElement("div", null, elements);
}
export default SignalChain;
