exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2cUDUGxT6joYO9uJuktflt,._1ghzRC0b1j9S0mAyO-UYa2{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._2cUDUGxT6joYO9uJuktflt,._1ghzRC0b1j9S0mAyO-UYa2{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._2cUDUGxT6joYO9uJuktflt,._1ghzRC0b1j9S0mAyO-UYa2{padding-inline-start:52px;padding-inline-end:52px}}._3eqQxzqtvEE5qvU_YgCg8Q{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._3eqQxzqtvEE5qvU_YgCg8Q{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._3eqQxzqtvEE5qvU_YgCg8Q{margin-inline-start:52px;margin-inline-end:52px}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_2cUDUGxT6joYO9uJuktflt",
	"gridSides": "_2cUDUGxT6joYO9uJuktflt",
	"upsellButtonContainer": "_1ghzRC0b1j9S0mAyO-UYa2",
	"grid-sides-margins": "_3eqQxzqtvEE5qvU_YgCg8Q",
	"gridSidesMargins": "_3eqQxzqtvEE5qvU_YgCg8Q"
};