exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2hhJM3lyrsEd91FmS8oSAP{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._2hhJM3lyrsEd91FmS8oSAP{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._2hhJM3lyrsEd91FmS8oSAP{padding-inline-start:52px;padding-inline-end:52px}}._8TrQJmvSuSkqsYfhFXoSA{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._8TrQJmvSuSkqsYfhFXoSA{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._8TrQJmvSuSkqsYfhFXoSA{margin-inline-start:52px;margin-inline-end:52px}}.Gjj-H6Sc128sqHaI-Fg6T,._3aDVWOdho6KdKjPFbgfkaP,._3iHX4XAPhvMW1U6imrkw95{font-family:\"Amazon Ember\",\"sans-serif\"}._3csCBxFxp5SAwn6fRqaM5Q,._3aDVWOdho6KdKjPFbgfkaP{text-transform:capitalize}._2leu1nnyyaPjGkr221xIQ6{margin:0;font-size:20px;line-height:28px;padding-inline-end:5px;color:#fff}.QGtvRVBVtpDPm6cR7Jq5r{margin:0;font-size:16px;line-height:20px;padding-inline-end:5px;color:#fff}@media(pointer: coarse){.QGtvRVBVtpDPm6cR7Jq5r{font-size:15px}}._1HlY23eWGhcoLsMvrB6Sdp{margin:0;font-size:16px;line-height:20px;padding-inline-end:5px;color:rgba(255,255,255,.75)}@media(pointer: coarse){._1HlY23eWGhcoLsMvrB6Sdp{font-size:15px}}._1DLEcRl3-U6OYKl-vDGYqP{margin-bottom:20px}._3iHX4XAPhvMW1U6imrkw95{font-size:16px;line-height:20px;color:rgba(255,255,255,.75)}@media(pointer: coarse){._3iHX4XAPhvMW1U6imrkw95{font-size:15px}}._35zXBTbo0jvNXVPYlyqVQ7{font-size:14px;line-height:20px}@media(pointer: coarse){._35zXBTbo0jvNXVPYlyqVQ7{font-size:13px;line-height:16px}}._3aDVWOdho6KdKjPFbgfkaP{font-weight:bold;font-size:16px;line-height:20px;margin-bottom:16px;color:#fff}@media(pointer: coarse){._3aDVWOdho6KdKjPFbgfkaP{font-size:15px;margin-bottom:8px}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_2hhJM3lyrsEd91FmS8oSAP",
	"gridSides": "_2hhJM3lyrsEd91FmS8oSAP",
	"grid-sides-margins": "_8TrQJmvSuSkqsYfhFXoSA",
	"gridSidesMargins": "_8TrQJmvSuSkqsYfhFXoSA",
	"primary-font": "Gjj-H6Sc128sqHaI-Fg6T",
	"primaryFont": "Gjj-H6Sc128sqHaI-Fg6T",
	"heading": "_3aDVWOdho6KdKjPFbgfkaP",
	"body-text": "_3iHX4XAPhvMW1U6imrkw95",
	"bodyText": "_3iHX4XAPhvMW1U6imrkw95",
	"text-capitalise": "_3csCBxFxp5SAwn6fRqaM5Q",
	"textCapitalise": "_3csCBxFxp5SAwn6fRqaM5Q",
	"primary-text": "_2leu1nnyyaPjGkr221xIQ6",
	"primaryText": "_2leu1nnyyaPjGkr221xIQ6",
	"primary-text-2": "QGtvRVBVtpDPm6cR7Jq5r",
	"primaryText2": "QGtvRVBVtpDPm6cR7Jq5r",
	"secondary-text": "_1HlY23eWGhcoLsMvrB6Sdp",
	"secondaryText": "_1HlY23eWGhcoLsMvrB6Sdp",
	"margin-bottom": "_1DLEcRl3-U6OYKl-vDGYqP",
	"marginBottom": "_1DLEcRl3-U6OYKl-vDGYqP",
	"body-text-2": "_35zXBTbo0jvNXVPYlyqVQ7",
	"bodyText2": "_35zXBTbo0jvNXVPYlyqVQ7"
};