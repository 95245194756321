import { channelFor, PerformActionEvent } from './utils';

const METRICS_LOGGER_CHANNEL = channelFor('metricslogger');

/*
  UIPageView V4 Event Specific Details:
  https://console.harmony.a2z.com/music-metrics/docs/event/uiPageView
*/
interface UIPageView {
  pageType: string; // The emitter of this event should make sure it is a valid enum
  activityId?: string;
  activityIdType?: string;
  associateTag?: string;
  blockRef?: string;
  csSessionId?: string;
  detailPageItemId?: string;
  detailPageItemIdType?: string;
  loadTimeMilliseconds?: string;
  locale?: string;
  pageSubType?: string;
  refMarker?: string;
  referer?: string;
  responseId?: string;
  responseIdType?: string;
  searchQuery?: string;
  url?: string;
  userAgent?: string;
  viewType?: 'LANDSCAPE' | 'PORTRAIT';
}

/*
  UIClick V5 Event Specific Details:
  https://console.harmony.a2z.com/music-metrics/docs/event/uiClick
*/
interface UIClick {
  activityId?: string;
  activityIdType?: string;
  pageSubType?: string;
  pageType?: string;
  refMarker?: string;
  responseId?: string;
  responseIdType?: string;
  searchQuery?: string;
  actionType: string;
  url?: string;
  interactionType?: string;
  entityId?: string;
  entityIdType?: string;
  entityPos?: string;
  entityType?: string; // The emitter of this event should make sure it is a valid enum
  pageEntityId?: string;
  pageEntityIdType?: string;
  pageEntityType?: string;
  blockRef?: string;
  containerIndex?: string;
  contentName?: string;
  contentInfoInstId?: string;
  contentSrc?: string;
}

/*
  UIContentView V6 Event Specific Details:
  https://console.harmony.a2z.com/music-metrics/docs/event/uiContentView
*/
interface UIContentView {
  activityId?: string;
  activityIdType?: string;
  responseId?: string;
  responseIdType?: string;
  searchQuery?: string;
  emittedOnView?: string;
  contentInfo?: Array<Record<string, unknown>>;
}

/*
  FlexEvent V1 Specific Details:
  https://console.harmony.a2z.com/music-metrics/docs/event/flexEvent
*/
interface FlexEvent {
  flexEventName: string;
  flexNum1?: string;
  flexNum2?: string;
  flexNum3?: string;
  flexNum4?: string;
  flexStr1?: string;
  flexStr2?: string;
  flexStr3?: string;
  flexStr4?: string;
  hcFlexStr1?: string;
  hcFlexStr2?: string;
  hcFlexStr3?: string;
  hcFlexStr4?: string;
}

/*
  LatencyInfo V1 Specific Details:
  https://console.harmony.a2z.com/music-metrics/docs/event/latencyInfo
*/
interface LatencyInfoPieceDurations {
  start: number;
  init: number;
  request: number;
  response: number;
  processing: number;
  rendering: number;
}

interface LatencyInfo {
  durationMilliseconds: number;
  latencyCode: string;
  latencyOperation?: string;
  latencySubtype?: string;
  pieceDurations?: LatencyInfoPieceDurations;
}

const EmitUIClickEvent = new PerformActionEvent<UIClick>({
  channel: METRICS_LOGGER_CHANNEL,
  topic: 'emitUIClickEvent',
});

const EmitUIPageViewEvent = new PerformActionEvent<UIPageView>({
  channel: METRICS_LOGGER_CHANNEL,
  topic: 'emitUIPageViewEvent',
});

const EmitUIContentViewEvent = new PerformActionEvent<UIContentView>({
  channel: METRICS_LOGGER_CHANNEL,
  topic: 'emitUIContentViewEvent',
});

const EmitFlexEvent = new PerformActionEvent<FlexEvent>({
  channel: METRICS_LOGGER_CHANNEL,
  topic: 'emitFlexEvent',
});

const SendLatencyInfoEvent = new PerformActionEvent<LatencyInfo>({
  channel: METRICS_LOGGER_CHANNEL,
  topic: 'sendLatencyInfo',
});

export { UIClick, UIPageView, UIContentView, FlexEvent, LatencyInfo, LatencyInfoPieceDurations };
export { EmitUIClickEvent, EmitUIPageViewEvent, EmitUIContentViewEvent, EmitFlexEvent, SendLatencyInfoEvent };
