import { Store } from 'redux';

export async function getPodcastPreset(
    method: any,
    store: Store<any>
): Promise<string> {
    let preset = method.preset ? method.preset : '{}';
    if ((method.clientInformation || []).length > 0) {
        method.clientInformation.forEach((clientInfo: string) => {
            if (
                clientInfo ===
                'Web.TemplatesInterface.v1_0.Touch.SearchTemplateInterface.SearchKeywordClientInformation'
            ) {
                let keyword =
                    method.keyword ||
                    store.getState().SearchSuggestions.searchTerm.text ||
                    '';
                keyword = keyword.replace(/\\/g, '\\\\');
                keyword = keyword.replace(/"/g, '\\"');
                preset = `{"keyword": "${keyword}"}`;
            }
        });
    }

    return preset;
}
