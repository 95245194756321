exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2uwOcwjmS6lDWE1t6Iylmi,div.lP88cclK6KBi3N9JJ2VZ6{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._2uwOcwjmS6lDWE1t6Iylmi,div.lP88cclK6KBi3N9JJ2VZ6{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._2uwOcwjmS6lDWE1t6Iylmi,div.lP88cclK6KBi3N9JJ2VZ6{padding-inline-start:52px;padding-inline-end:52px}}._3Joukv5iN8EzYFmry8ALnK{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._3Joukv5iN8EzYFmry8ALnK{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._3Joukv5iN8EzYFmry8ALnK{margin-inline-start:52px;margin-inline-end:52px}}div.lP88cclK6KBi3N9JJ2VZ6{z-index:5}._2cdOibmhx1Ak156gzZMjXQ{display:none}._2fp343PcvzW9e6ATMuSvi-{width:100%;height:100%;position:fixed;top:0;inset-inline-start:0}._3uuwF8vZd3vDH6Ov1zpYSq{bottom:120px;position:fixed;z-index:5}._2_XEkmHx2e6g0uB8p5gQrV{width:100%;height:100%;top:0;inset-inline-start:0;position:fixed;background:rgba(0,0,0,.3);z-index:5}.z2Cec--UMhuykpIZ0UhWB{position:absolute;top:24px;z-index:6}.T_G27TnWE1sOsAHkwt3M-{width:100%;z-index:1}.hoqFI0xGuzC2BUcpTRoko{display:flex;justify-content:center}.hoqFI0xGuzC2BUcpTRoko ._33y7sluSrHxYWAwIqkvvve{box-sizing:border-box;position:fixed;top:40%}.-QUw51sCxLIW8SE5NCbBy{width:100%;height:100%;position:fixed;top:0;inset-inline-start:0;background-color:#1e2222;background-image:url(\"https://m.media-amazon.com/images/G/01/starlight-webplayer/videoPlaceholder.svg\");background-size:114px 68px;background-position:center;background-repeat:no-repeat}@media only screen and (max-width: 639px)and (orientation: portrait){._3uuwF8vZd3vDH6Ov1zpYSq{bottom:200px}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_2uwOcwjmS6lDWE1t6Iylmi",
	"gridSides": "_2uwOcwjmS6lDWE1t6Iylmi",
	"vnpv": "lP88cclK6KBi3N9JJ2VZ6",
	"grid-sides-margins": "_3Joukv5iN8EzYFmry8ALnK",
	"gridSidesMargins": "_3Joukv5iN8EzYFmry8ALnK",
	"hidden": "_2cdOibmhx1Ak156gzZMjXQ",
	"video-playback-container": "_2fp343PcvzW9e6ATMuSvi-",
	"videoPlaybackContainer": "_2fp343PcvzW9e6ATMuSvi-",
	"video-playback-controls": "_3uuwF8vZd3vDH6Ov1zpYSq",
	"videoPlaybackControls": "_3uuwF8vZd3vDH6Ov1zpYSq",
	"video-container-overlay": "_2_XEkmHx2e6g0uB8p5gQrV",
	"videoContainerOverlay": "_2_XEkmHx2e6g0uB8p5gQrV",
	"top-nav-bar-container": "z2Cec--UMhuykpIZ0UhWB",
	"topNavBarContainer": "z2Cec--UMhuykpIZ0UhWB",
	"loading-overlay-container": "T_G27TnWE1sOsAHkwt3M-",
	"loadingOverlayContainer": "T_G27TnWE1sOsAHkwt3M-",
	"loading-spinner-container": "hoqFI0xGuzC2BUcpTRoko",
	"loadingSpinnerContainer": "hoqFI0xGuzC2BUcpTRoko",
	"loading-spinner": "_33y7sluSrHxYWAwIqkvvve",
	"loadingSpinner": "_33y7sluSrHxYWAwIqkvvve",
	"initial-video-buffering": "-QUw51sCxLIW8SE5NCbBy",
	"initialVideoBuffering": "-QUw51sCxLIW8SE5NCbBy"
};