/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Styles from './CuratedDescription.scss';
import ExpandableTextView from './ExpandableTextView';

interface ICuratedDescriptionElementProps {
    description: string;
    header: string;
}

export default function CuratedDescription(
    props: ICuratedDescriptionElementProps
) {
    return (
        <div className={Styles['curated-description-container']}>
            <div className={Styles['curated-description-header']}>
                <h4> {props.header} </h4>
            </div>
            <div className={Styles['curated-description']}>
                <div>
                    <ExpandableTextView description={props.description} />
                </div>
            </div>
        </div>
    );
}
