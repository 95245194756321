// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum ContentTrait {
    AD_FREE = 'AD_FREE',
    AD_SUPPORTED = 'AD_SUPPORTED',
    PRE_RELEASE = 'PRE_RELEASE',
    BONUS = 'BONUS',
    EXCLUSIVE = 'EXCLUSIVE',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum UpsellType {
    FREE = 'FREE',
    PRIME = 'PRIME',
    UNLIMITED = 'UNLIMITED',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum PlaybackMode {
    NONE = 'NONE',
    FULL_ACCESS = 'FULL_ACCESS',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum UpsellTriggerType {
    INELIGIBLE_CONTENT_REQUEST = 'INELIGIBLE_CONTENT_REQUEST',
    PERSISTENT_UPSELL = 'PERSISTENT_UPSELL',
}
