exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2_pEtEV_AVVRXtShn0wVYI{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._2_pEtEV_AVVRXtShn0wVYI{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._2_pEtEV_AVVRXtShn0wVYI{padding-inline-start:52px;padding-inline-end:52px}}._3auJE8QCOYe37HCs_jLn2e{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._3auJE8QCOYe37HCs_jLn2e{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._3auJE8QCOYe37HCs_jLn2e{margin-inline-start:52px;margin-inline-end:52px}}._2Sz94syj3h4YQWSn8N42_-{text-align:start}._2Sz94syj3h4YQWSn8N42_- music-list-item:last-child{border:none}._2Sz94syj3h4YQWSn8N42_- music-icon{margin-inline-start:var(--music-spacer-base)}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_2_pEtEV_AVVRXtShn0wVYI",
	"gridSides": "_2_pEtEV_AVVRXtShn0wVYI",
	"grid-sides-margins": "_3auJE8QCOYe37HCs_jLn2e",
	"gridSidesMargins": "_3auJE8QCOYe37HCs_jLn2e",
	"dialog_row": "_2Sz94syj3h4YQWSn8N42_-",
	"dialogRow": "_2Sz94syj3h4YQWSn8N42_-"
};