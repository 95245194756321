import React, { useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { GET_FEATURED_BOOKMARK_ITEM } from 'src/actions/LocalHydration';
import IApplicationState from '../../../types/IApplicationState';
import ISkyfireMethod from '../../../types/ISkyfireMethod';
import ISkyfireFeaturedElement from '../../../types/templates/detail/ISkyfireFeaturedElement';
import { getDefaultLanguage } from '../../../utils/getDefaultLanguage';
import { computeContainerWidth } from '../../../utils/gridHelpers';
import Styles from './FeaturedItem.scss';
import ImageRow from './ImageRow';

interface IFeaturedItemProps {
    featuredItem: ISkyfireFeaturedElement;
    handleSelected: (methods: ISkyfireMethod[]) => void;
}

export default function FeaturedItem(props: IFeaturedItemProps) {
    const dispatch = useDispatch();
    const { alternate, alternateLabel, podcastBookmark } = props.featuredItem;

    const { marketplaceId } = useSelector(
        (state: IApplicationState) => state.Authentication
    );
    const language = getDefaultLanguage(marketplaceId, useStore());

    const { featuredBookmarkItem } = useSelector(
        (state: IApplicationState) => state.LocalHydrationState
    );

    const { play } = useSelector(
        (state: IApplicationState) => state.PlaybackStates
    );

    useEffect(() => {
        dispatch({
            type: GET_FEATURED_BOOKMARK_ITEM,
            payload: {
                featuredBookmarkItem: data,
                bookmarkId: podcastBookmark.id,
                language,
            },
        });
    }, [play]);

    const data = featuredBookmarkItem ?? alternate;
    const label = featuredBookmarkItem ? podcastBookmark.label : alternateLabel;

    const { windowWidth } = useSelector(
        (state: IApplicationState) => state.BrowserState
    );
    const width = computeContainerWidth(windowWidth);

    return data && label ? (
        // We set width on the div using style in the exact same way Grid in VisualListing.tsx does
        <div className={Styles['featured-item']} style={{ width }}>
            <div className={`headline-4 ${Styles.label}`}>{label}</div>
            <ImageRow
                key={label}
                data={data}
                handleSelected={props.handleSelected}
            />
        </div>
    ) : (
        <div />
    );
}
