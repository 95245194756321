import ISkyfireSeeMoreItem from './items/ISkyfireSeeMoreItem';
import ISkyfireVerticalItem from './items/ISkyfireVerticalItem';
import ISkyfireMethod from '../../../types/ISkyfireMethod';

export const VISUAL_SHOVELER =
    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.VisualShovelerWidgetElement';
export default interface ISkyfireVisualShoveler {
    interface: typeof VISUAL_SHOVELER;
    header: string;
    items: ISkyfireVerticalItem[];
    seeMoreItem: ISkyfireSeeMoreItem;
    onViewed: ISkyfireMethod[];
    uuid?: string;
    onEndOfWidget?: ISkyfireMethod[];
    isCompact?: boolean;
}
