import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { WindowScroller } from 'react-virtualized/dist/commonjs/WindowScroller';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Dispatch } from 'redux';
import IApplicationState from 'src/types/IApplicationState';
import ISkyfireMethod from '../../../types/ISkyfireMethod';
import ISkyfireDescriptiveTableWidget from '../../../types/templates/widgets/ISkyfireDescriptiveTableWidget';
import IPodcastEpisodeTableWidget from '../../../types/templates/widgets/IPodcastEpisodeTableWidget';
import ISkyfireVisualTableWidget from '../../../types/templates/widgets/ISkyfireVisualTableWidget';
import { EPISODE_ROW_ITEM } from '../../../types/templates/widgets/items/ISkyfireEpisodeRowItem';
import { GROUP_HEADER_ELEMENT } from '../../../types/templates/widgets/items/ISkyfireGroupHeaderElement';

import {
    computeContainerHeight,
    computeContainerWidth,
} from '../../../utils/gridHelpers';
import LoadingWidget from '../../LoadingWidget';
import infiniteListStyles from './InfiniteList.scss';

interface ItemRendererProps {
    index: number;
    itemIndex: number;
    style: any;
    data: any;
    disabled: boolean;
}

const GROUP_HEADER_ELEMENT_ITEM_SIZE = 40;

const ItemRenderer = SortableElement(
    ({ itemIndex: index, style, data }: ItemRendererProps) => {
        const {
            componentType,
            handleSelected,
            items,
            itemsViewedRef,
            loading,
        } = data;
        const ComponentType = componentType;
        const itemData = items[index] ?? { placeholder: true };
        switch (itemData.interface) {
            case EPISODE_ROW_ITEM: {
                let newStyle = style;
                const nextItemIsHeader =
                    index < items.length - 1 &&
                    items[index + 1]?.interface === GROUP_HEADER_ELEMENT;
                if (nextItemIsHeader) {
                    newStyle = { ...style, borderBottom: 'none' };
                }
                return (
                    <ComponentType
                        ref={itemsViewedRef}
                        data={itemData}
                        handleSelected={handleSelected}
                        style={newStyle}
                        index={index}
                        loading={loading}
                    />
                );
            }
            case GROUP_HEADER_ELEMENT: {
                return (
                    <p
                        className={`headline-4 ${infiniteListStyles.header}`}
                        style={style}
                    >
                        {itemData.headerTitle}
                    </p>
                );
            }
            default:
                return (
                    <ComponentType
                        ref={itemsViewedRef}
                        data={itemData}
                        handleSelected={handleSelected}
                        style={style}
                        index={index}
                        loading={loading}
                    />
                );
        }
    }
);
interface InfiniteListState {
    isDragging?: boolean;
}
interface InfiniteListProps {
    handleSelected: (methods: ISkyfireMethod[]) => void;
    handleReorder?: (trackIndex: number, moveToIndex: number) => void;
    componentType: any;
    data:
        | ISkyfireDescriptiveTableWidget
        | ISkyfireVisualTableWidget
        | IPodcastEpisodeTableWidget
        | any;
    rowHeight: number;
    loading?: boolean;
    isVisualPlayQueue?: boolean;
    isDragAndDropEnabled?: boolean;
    windowWidth?: number;
    ref?: any;
    itemsViewedRef?: (HTMLElement) => void;
}
interface IListRef {
    scrollTo(scrollTop: number): void;
    resetAfterIndex(index: number, shouldForceUpdate?: boolean): void;
}
class InfiniteList extends React.Component<
    InfiniteListProps,
    InfiniteListState
> {
    listRef: React.RefObject<IListRef>;

    public constructor(props: InfiniteListProps) {
        super(props);
        this.listRef = createRef<IListRef>();
    }

    public render() {
        const { items, onEndOfWidget } = this.props.data;

        // Find the number of items to render.
        const count = items.length;

        // Functions to check when to load more items.
        const loadMore = () => this.props.handleSelected(onEndOfWidget);
        // InfiniteLoader will call loadMoreRows callback when isRowLoaded returns false.
        // We now say the last row is "not loaded" so InfiniteLoader will call loadMoreRows
        // when we get to the last row, if we know onEndOfWidget exists.
        const isItemLoaded = (index) =>
            index < (onEndOfWidget?.length ? items.length - 1 : items.length);
        const showLoader = onEndOfWidget?.length > 0;

        const infiniteLoader = (
            <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={count}
                loadMoreItems={loadMore}
            >
                {this.infiniteLoaderCallback()}
            </InfiniteLoader>
        );

        const onScroll = ({ scrollTop }) =>
            this.listRef.current?.scrollTo(scrollTop);
        const windowScroller = (
            <WindowScroller onScroll={onScroll}>{() => <div />}</WindowScroller>
        );

        return (
            <div className={infiniteListStyles['infinite-list']}>
                {windowScroller}
                {infiniteLoader}
                {showLoader && <LoadingWidget />}
            </div>
        );
    }

    public infiniteLoaderCallback = () => ({ onItemsRendered, ref }) => (
        <div ref={ref} className={infiniteListStyles.removeOutline}>
            {this.renderList({
                onItemsRendered,
                height: window.innerHeight,
            })}
        </div>
    );

    public renderList = (listProps) => {
        const { onItemsRendered, height } = listProps;
        const {
            windowWidth,
            rowHeight,
            data,
            componentType,
            handleSelected,
            itemsViewedRef,
            loading,
        } = this.props;
        const { items } = data;
        const containerWidth = computeContainerWidth(windowWidth || 0);
        const count = items.length;
        const itemSize = (index) => {
            switch (items[index].interface) {
                case GROUP_HEADER_ELEMENT:
                    return GROUP_HEADER_ELEMENT_ITEM_SIZE;
                default:
                    return (windowWidth || 0) > 840
                        ? rowHeight
                        : computeContainerHeight(
                              this.props.rowHeight,
                              this.props.data.items[index]
                          );
            }
        };

        const listItemKey = (index, itemData) =>
            index + itemData.items[index].primaryText;
        const listItemData = {
            componentType,
            handleSelected,
            items,
            itemsViewedRef,
            loading,
        };
        return (
            <VariableSizeList
                className={infiniteListStyles.list}
                ref={this.listRef}
                height={height}
                width={containerWidth}
                itemCount={count}
                itemKey={listItemKey}
                itemData={listItemData}
                estimatedItemSize={rowHeight}
                itemSize={itemSize}
                onItemsRendered={onItemsRendered}
                overscanCount={5}
            >
                {this.renderItem}
            </VariableSizeList>
        );
    };

    public renderItem = ({ index, style, data }) => (
        <ItemRenderer
            index={index}
            itemIndex={index}
            style={style}
            data={data}
            disabled={!this.props.isDragAndDropEnabled}
        />
    );
}

function mapStateToProps(state: IApplicationState) {
    return {
        windowWidth: state.BrowserState.windowWidth,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {};
}

const SortableVirtualList = SortableContainer(
    connect(mapStateToProps, mapDispatchToProps)(InfiniteList)
);
// tslint:disable-next-line: max-classes-per-file
export default class SortableComponent extends React.Component<
    InfiniteListProps,
    InfiniteListState
> {
    public List?: any;

    constructor(props) {
        super(props);
        this.setState({ isDragging: false });
    }

    public registerListRef = function (sortableList, listInstance) {
        if (this) {
            this.List = listInstance;
        }
        if (sortableList) {
            // Matching Skyfire Ignore
            // eslint-disable-next-line no-param-reassign
            sortableList.List = listInstance;
        }
    };

    public render() {
        if (this.state?.isDragging) {
            this.List?.container?.classList.add(infiniteListStyles.isDragging);
        } else {
            this.List?.container?.classList.remove(
                infiniteListStyles.isDragging
            );
        }
        return (
            <SortableVirtualList
                ref={this.registerListRef.bind(null, this)}
                {...this.props}
                transitionDuration={100}
                // tslint:disable-next-line: jsx-no-bind
                onSortStart={this.dragStart.bind(this)}
                // tslint:disable-next-line: jsx-no-bind
                onSortEnd={this.dragEnd.bind(this)}
                helperClass={infiniteListStyles.dragHelper}
                distance={15}
                // @ts-ignore
                isDragAndDropEnabled={this.isDragAndDropEnabled()}
                useWindowAsScrollContainer={true}
                hideSortableGhost={true}
                lockAxis='y'
            />
        );
    }

    private dragStart() {
        this.setState({ isDragging: true });
    }

    private dragEnd({ oldIndex, newIndex }) {
        this.setState({ isDragging: false });
        if (newIndex === oldIndex) {
            return;
        }
        this.props.handleReorder?.(newIndex, oldIndex);
        (document.activeElement as HTMLElement)?.blur();
    }

    private isDragAndDropEnabled = (): boolean =>
        !!(this.props.handleReorder && this.props.handleReorder.length > 0);
}
