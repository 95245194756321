import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSED_CAPTIONS_OFF, CLOSED_CAPTIONS_ON, HIDE_CLOSED_CAPTIONS, SHOW_CLOSED_CAPTIONS, } from '../../actions/Playback';
export default function ClosedCaptionsButton(props) {
    const dispatch = useDispatch();
    const { closedCaptions } = useSelector((state) => state.PlaybackStates);
    const variant = (closedCaptions === null || closedCaptions === void 0 ? void 0 : closedCaptions.state) === 'ON' && !props.disabled ? 'accent' : 'primary';
    const text = closedCaptions === null || closedCaptions === void 0 ? void 0 : closedCaptions.descriptions[closedCaptions.state];
    function handleClick(e) {
        if (closedCaptions && !e.target.disabled) {
            if (closedCaptions.state === 'ON') {
                dispatch({ type: CLOSED_CAPTIONS_OFF });
                dispatch({ type: HIDE_CLOSED_CAPTIONS });
            }
            else {
                dispatch({ type: CLOSED_CAPTIONS_ON });
                dispatch({ type: SHOW_CLOSED_CAPTIONS });
            }
        }
    }
    return (React.createElement("music-button", { ariaLabelText: text, onClick: handleClick, variant: variant, "icon-name": "closedcaptionsus", "icon-only": true, size: props.size || 'small', disabled: props.disabled }, text));
}
