export const SET_AUTHENTICATION_METHOD = 'SetAuthenticationMethod';
export const SET_CSRF = 'SetCSRF';
export const CHANGE_DISPLAY_LANGUAGE = 'CHANGE_DISPLAY_LANGUAGE';

export interface ISetAuthenticationMethodAction {
    type: typeof SET_AUTHENTICATION_METHOD;
    payload: {
        accessToken: string;
        expiresAt: number;
    };
}

export interface ISetCSRF {
    type: typeof SET_CSRF;
    payload: {
        csrf: {
            token: string;
            ts: string;
            rnd: string;
        };
    };
}

export interface IChangeDisplayLanguageAction {
    type: typeof CHANGE_DISPLAY_LANGUAGE;
    payload: {
        readonly displayLanguageId: string;
    };
}

export function setAuthentication({
    accessToken,
    expiresAt,
}: {
    accessToken: string;
    expiresAt: number;
}) {
    return {
        type: SET_AUTHENTICATION_METHOD,
        payload: {
            accessToken,
            expiresAt,
        },
    };
}
