import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { SET_TOGGLE_STATE_INTERFACE } from '../../../actions';
import { bindHandler } from '../../../utils';
import { useObserver } from '../../../utils/ObserverHooks';
export default function Toggle(props) {
    const toggle = useObserver(props.data);
    const { onItemSelected, disabled, checked, primaryText, secondaryText, primaryTextIconName, textWithLink, onTextWithLinkSelected, } = toggle;
    const dispatch = useDispatch();
    const store = useStore();
    const onSelected = bindHandler(props.handleSelected, null, onItemSelected);
    const checkedStatus = useSelector((state) => state.Storage.checked_status);
    useEffect(() => {
        dispatch({
            type: SET_TOGGLE_STATE_INTERFACE,
            payload: { state: checked },
        });
    }, []);
    const onLinkSelected = bindHandler(props.handleOnTextWithLinkSelected, null, onTextWithLinkSelected);
    const onToggleOn = (event) => {
        var _a;
        event.stopPropagation();
        (_a = event.detail) === null || _a === void 0 ? void 0 : _a.stopPropagation();
        onSelected();
        dispatch({
            type: SET_TOGGLE_STATE_INTERFACE,
            payload: {
                state: !store.getState().Storage.checked_status,
            },
        });
    };
    const onClickLink = (event) => {
        var _a;
        event.stopPropagation();
        (_a = event.detail) === null || _a === void 0 ? void 0 : _a.stopPropagation();
        onLinkSelected();
    };
    return (React.createElement("music-toggle", { disabled: disabled, checked: checkedStatus, ontoggleOn: onToggleOn, "primary-text": primaryText, "secondary-text": secondaryText, "primary-text-icon-name": primaryTextIconName, "text-with-link": textWithLink, onclickLink: onClickLink }));
}
