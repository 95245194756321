import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IApplicationState from 'src/types/IApplicationState';
import { CLOSE_CONTEXT_MENU, OPEN_CONTEXT_MENU } from '../actions/ContextMenu';
import { ISkyfireContextMenuItemElement } from '../types/templates/contextMenu/ISkyfireContextMenuItemElement';
import ISkyfireMethod from '../types/ISkyfireMethod';
import { executeMethods } from '../actions';

interface IContextMenuButtonProps {
    slot?: string;
    id?: string;
    options: ISkyfireContextMenuItemElement[];
    isRefinement?: boolean;
    size?: string;
    variant?: string;
    iconName?: string;
    postFixIconName?: string;
    disabled?: boolean;
    open?: boolean;
    text?: string;
    hover?: boolean;
    header?: string;
    label?: string;
    showSignInButton?: boolean;
    signInButtonText?: string;
    onItemSelected?: ISkyfireMethod[];
    ariaLabelText?: string;
}

export function ContextMenuButton(props: IContextMenuButtonProps) {
    const {
        slot,
        id,
        options,
        isRefinement,
        size,
        variant,
        iconName,
        postFixIconName,
        disabled,
        text,
        hover,
        header,
        label,
        showSignInButton,
        signInButtonText,
        ariaLabelText,
    } = props;

    const CONTEXT_MENU_OVERLAY_ID = 'contextMenuOverlay';
    const CONTEXT_MENU_HOVER_BUTTON_ID = 'contextMenuHoverButton';

    const [active, setActive] = useState<boolean>(false);

    const { open } = useSelector(
        (state: IApplicationState) => state.ContextMenu
    );

    const buttonRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    const openContextMenu = (event) => {
        event.stopPropagation();
        if (event.detail.stopPropagation) {
            event.detail.stopPropagation();
        }
        setActive(true);
        const buttonOffsets: DOMRect =
            buttonRef?.current?.getBoundingClientRect() || new DOMRect();
        const buttonHeight = buttonOffsets.height;
        const padding = 14;
        const xOffset = buttonOffsets.left || event.detail.clientX;
        const yOffset = buttonOffsets.top
            ? buttonOffsets.top + buttonHeight + padding
            : event.detail.clientY + padding;
        if (props.onItemSelected) {
            dispatch(executeMethods(props.onItemSelected));
        }
        // This indicate how many times the mouse was clicked in the same area
        const mouseClickedTimes = event.detail.detail;
        const openedWithKeyboard = mouseClickedTimes === 0;
        const buttonId = hover ? CONTEXT_MENU_HOVER_BUTTON_ID : id;
        dispatch({
            type: OPEN_CONTEXT_MENU,
            payload: {
                buttonId,
                openedWithKeyboard,
                options,
                xOffset,
                yOffset,
                refinement: true,
                hover,
                header,
                label,
                showSignInButton,
                signInButtonText,
            },
        });
    };

    const closeContextMenu = (event) => {
        if (
            event?.toElement?.id !== CONTEXT_MENU_OVERLAY_ID &&
            event?.relatedTarget?.id !== CONTEXT_MENU_OVERLAY_ID
        ) {
            dispatch({ type: CLOSE_CONTEXT_MENU });
        }
    };

    const handleMouseEvent = (event: any) => {
        event.stopPropagation();
    };

    const hoverProps = hover
        ? {
              onMouseEnter: openContextMenu,
              onMouseLeave: closeContextMenu,
              id: CONTEXT_MENU_HOVER_BUTTON_ID,
          }
        : {};

    useEffect(() => {
        if (!open) {
            setActive(false);
        }
    }, [open]);

    return (
        <music-button
            id={id}
            slot={slot}
            onmusicActivate={openContextMenu}
            icon-name={iconName}
            icon-only={!text}
            variant={variant}
            refinement={isRefinement ? (active ? 'open' : 'static') : 'none'}
            size={size}
            disabled={disabled}
            onMouseUp={handleMouseEvent}
            onMouseDown={handleMouseEvent}
            ref={buttonRef}
            ariaLabelText={ariaLabelText}
            {...hoverProps}
        >
            {text || ''}
            {postFixIconName ? (
                <music-icon
                    name={postFixIconName}
                    size='tiny'
                    style={{ paddingLeft: 5 }}
                />
            ) : null}
        </music-button>
    );
}
