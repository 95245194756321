import { InitiatePlaybackEvent, GetCurrentPlaybackStateEvent, provideData, subscribe, PlaybackToggleEvent, PlayPlaybackEvent, PausePlaybackEvent, PreviousPlaybackEvent, NextPlaybackEvent, QueueNextEvent, QueueLastEvent, GetCurrentPlayingMetadataEvent, LoopMode, ShuffleMode, } from '@amzn/DMWebEventBus';
import { PAUSED, PLAYBACK_TOGGLE, PLAY_NEXT, PLAY_PREVIOUS, RESUMED } from '../actions';
import { getAudioInstance, getSampleInstance } from '../player';
import { getSkyfireEnvironment } from '../utils';
import { invokeHttp } from './utils';
import { InitiatePlaybackClientInformation, } from '../types/playback/InitiatePlaybackClientInformation';
let initiatePlaybackArgs;
export function getInitiatePlaybackClientInformation() {
    return Object.assign({ interface: InitiatePlaybackClientInformation }, initiatePlaybackArgs);
}
export const handleInitiatePlayback = (store) => (data) => {
    initiatePlaybackArgs = data;
    const skyfireEnv = getSkyfireEnvironment();
    const url = `${skyfireEnv}/api/playMRN`;
    invokeHttp(store, url, [InitiatePlaybackClientInformation]);
};
export const handleQueueNextPlayback = (store) => (data) => {
    const skyfireEnv = getSkyfireEnvironment();
    const url = `${skyfireEnv}/api/queueNextMRN?mrn=${data}`;
    invokeHttp(store, url, undefined);
};
export const handleQueueLastPlayback = (store) => (data) => {
    const skyfireEnv = getSkyfireEnvironment();
    const url = `${skyfireEnv}/api/queueLastMRN?mrn=${data}`;
    invokeHttp(store, url, undefined);
};
export const handleGetCurrentPlaybackState = (store) => async () => {
    var _a, _b, _c, _d, _e;
    const { isSamplePlayback } = store.getState().SampleMedia;
    const player = isSamplePlayback ? await getSampleInstance() : await getAudioInstance();
    const playbackStateFromStore = isSamplePlayback
        ? store.getState().SamplePlaybackStates
        : store.getState().PlaybackStates;
    const playbackState = ((_a = playbackStateFromStore.play) === null || _a === void 0 ? void 0 : _a.state) || (player.isPaused() && 'PAUSED') || 'DETACHED';
    const progress = {
        timestamp: player.getPlaybackTimestamps(),
        durationMS: player.getDuration() * 1000,
        bufferedMS: player.getBufferedTime() * 1000,
        positionMS: player.getCurrentTime() * 1000,
        playbackRate: 1.0,
    };
    const loopMode = (_c = (_b = playbackStateFromStore.repeat) === null || _b === void 0 ? void 0 : _b.state) !== null && _c !== void 0 ? _c : '';
    const shuffleMode = (_e = (_d = playbackStateFromStore.shuffle) === null || _d === void 0 ? void 0 : _d.state) !== null && _e !== void 0 ? _e : '';
    const playbackStateEvent = {
        playbackState,
        mrn: '',
        containerMRN: '',
        progress,
        loopMode: LoopMode[loopMode],
        shuffleMode: ShuffleMode[shuffleMode],
    };
    return playbackStateEvent;
};
export const handlePlaybackToggle = (store) => async () => {
    const { mediaId } = store.getState().Media;
    store.dispatch({ type: PLAYBACK_TOGGLE, payload: { mediaId } });
};
export const handlePlaybackPlay = (store) => async () => {
    const { mediaId } = store.getState().Media;
    store.dispatch({ type: RESUMED, payload: { mediaId } });
};
export const handlePlaybackPause = (store) => async () => {
    const { mediaId } = store.getState().Media;
    store.dispatch({ type: PAUSED, payload: { mediaId } });
};
export const handlePlaybackPrevious = (store) => async () => {
    const { mediaId } = store.getState().Media;
    store.dispatch({ type: PLAY_PREVIOUS, payload: { mediaId } });
};
export const handlePlaybackNext = (store) => async () => {
    const { mediaId } = store.getState().Media;
    store.dispatch({ type: PLAY_NEXT, payload: { mediaId } });
};
export const handleGetCurrentPlayingMetadata = (store) => async () => {
    const playbackMedia = store.getState().Media;
    const { isSamplePlayback } = store.getState().SampleMedia;
    const player = isSamplePlayback ? await getSampleInstance() : await getAudioInstance();
    const metadata = {
        id: playbackMedia.mediaId,
        title: playbackMedia.title,
        duration: player.getDuration(),
        artworkUrl: playbackMedia.artwork,
        artistName: playbackMedia.artistName,
        artistMrn: '',
        artistAsin: '',
        albumName: playbackMedia.albumName,
        albumAsin: '',
        albumMrn: '',
    };
    return metadata;
};
export function registerPlaybackModule(store) {
    subscribe(InitiatePlaybackEvent, handleInitiatePlayback(store));
    subscribe(PlaybackToggleEvent, handlePlaybackToggle(store));
    subscribe(PlayPlaybackEvent, handlePlaybackPlay(store));
    subscribe(PausePlaybackEvent, handlePlaybackPause(store));
    subscribe(PreviousPlaybackEvent, handlePlaybackPrevious(store));
    subscribe(NextPlaybackEvent, handlePlaybackNext(store));
    subscribe(QueueNextEvent, handleQueueNextPlayback(store));
    subscribe(QueueLastEvent, handleQueueLastPlayback(store));
    provideData(GetCurrentPlaybackStateEvent, handleGetCurrentPlaybackState(store));
    provideData(GetCurrentPlayingMetadataEvent, handleGetCurrentPlayingMetadata(store));
}
