export function getPodcastDeeplinkFromClientInformation(method: any): string {
    let deeplink = '';
    if ((method.clientInformation || []).length > 0) {
        method.clientInformation.forEach((clientInfo) => {
            if (
                clientInfo.includes(
                    'Podcast.Web.DeeplinkInterface.DeeplinkClientInformation'
                )
            ) {
                const deeplinkClientInfo = JSON.parse(clientInfo);
                deeplink = deeplinkClientInfo.deeplink;
            }
        });
    }
    return deeplink;
}
