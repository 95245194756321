import ISkyfireMethod from '../../ISkyfireMethod';
import ISkyfireEpisodeRowItem from './items/ISkyfireEpisodeRowItem';

export const PODCAST_LIBRARY_PLAYLIST_WIDGET =
    'Podcast.Web.WidgetsInterface.LibraryPlaylistWidgetElement';

export default interface IPodcastLibraryPlaylist {
    data: ISkyfireEpisodeRowItem;
    interface: typeof PODCAST_LIBRARY_PLAYLIST_WIDGET;
    items: ISkyfireEpisodeRowItem[];
    fallbackMessage: string;
    onEndOfWidget: ISkyfireMethod[];
    onViewed: ISkyfireMethod[];
    uuid?: string;
}
