import localization from '../resources/localization.json';

export function getEpisodeDuration(
    locale: string = 'en_US',
    durationMilliseconds: number
): string {
    const seconds = Math.floor((durationMilliseconds / 1000) % 60);
    const minutes = Math.floor((durationMilliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((durationMilliseconds / (1000 * 60 * 60)) % 24);
    if (hours <= 0 && minutes <= 0) {
        return seconds === 1
            ? localization[locale].dmp_1_second
            : localization[locale].dmp_number_of_seconds.replace(
                  /%s/g,
                  seconds
              );
    }
    const numberOfHours =
        hours === 1
            ? localization[locale].dmp_1_hr
            : localization[locale].dmp_number_of_hours.replace(/%s/g, hours);
    const numberOfMinutes =
        minutes === 1
            ? localization[locale].dmp_1_min
            : localization[locale].dmp_number_of_minutes.replace(
                  /%s/g,
                  minutes
              );
    if (hours === 0) {
        return numberOfMinutes;
    }
    if (minutes === 0) {
        return numberOfHours;
    }
    return `${numberOfHours} ${numberOfMinutes}`;
}
