import { channelFor, StateChangedEvent } from './utils';
import ISkyfireChromeTemplate from '../types/templates/chrome';

const APP_CHROME_CHANNEL = channelFor('appchrome');

export interface AppChromeState {
  template: ISkyfireChromeTemplate;
  windowWidth: number;
  isRWPRetailNavbarInview: boolean;
}

export const AppChromeTemplateChangedEvent = new StateChangedEvent<AppChromeState>({
  channel: APP_CHROME_CHANNEL,
  topic: 'all',
});
