import { channelFor, PerformActionEvent } from './utils';

const UPSELL_CHANNEL = channelFor('upsell');

export type UpsellReasons = Record<string, string>;
export type UpsellReason = UpsellReasons[keyof UpsellReasons];

// copied from Dragonfly, adjust as needed
// https://gitlab.aws.dev/amazonmusic/musicdragonfly/dragonfly/-/blob/76216439b4132be5d826c6f331259e821523052e/src/app/utils/upsell/upsellDeeplinks.ts#L4-12
export const UPSELL_REASONS: UpsellReasons = {
  PERSISTENT_UPSELL: 'persistent_upsell',
  DOWNLOAD_CONTENT: 'download_content',
  TRACK_SKIP_LIMIT: 'track_skip_limit',
  TRACK_PREVIOUS: 'track_previous',
  TRACK_NEXT: 'track_next',
  AD_SKIP: 'ad_skip',
  TRACK_SEEK: 'track_seek',
};

export type UpsellTriggers = Record<UpsellReason, string>;

// copied from Android, adjust as needed
// https://code.amazon.com/packages/DigitalMusicAndroid3P/blobs/737d5b6d0481aba4c25ed1c89dcb1396887abba2/--/android/src/main/java/com/amazon/mp3/util/DynamicUpsellTranslator.kt#L42-L65
export const UPSELL_TRIGGERS: UpsellTriggers = {
  [UPSELL_REASONS.PERSISTENT_UPSELL]: 'trigger:/persistentUpsell',
  [UPSELL_REASONS.TRACK_SKIP_LIMIT]: 'trigger:/skipLimitReached',
  DEFAULT: 'trigger:/featureUnavailable',
};

export interface ShowUpsellPayload {
  reason: UpsellReason;
}

export const ShowUpsellEvent = new PerformActionEvent<string>({
  channel: UPSELL_CHANNEL,
  topic: 'showUpsell',
});
