import { Follow } from './Follow';
import { FollowOperation } from './FollowOperation';

export const SAVE_FOLLOW = 'SAVE_FOLLOW';
export const SAVE_FOLLOW_OPERATION = 'SAVE_FOLLOW_OPERATION';
export const UPDATE_FOLLOW_OPERATION = 'UPDATE_FOLLOW_OPERATIONS';
export const DELETE_FOLLOW_OPERATION = 'DELETE_FOLLOW_OPERATIONS';

export interface ISaveFollowMethodAction {
    type: typeof SAVE_FOLLOW;
    payload: { follow: Follow };
}

export interface ISaveFollowOperationMethodAction {
    type: typeof SAVE_FOLLOW_OPERATION;
    payload: { followOperation: FollowOperation };
}

export interface IUpdateFollowMethodAction {
    type: typeof UPDATE_FOLLOW_OPERATION;
    payload: { ids: string[]; isProcessing: boolean };
}

export interface IDeleteFollowOperationMethodAction {
    type: typeof DELETE_FOLLOW_OPERATION;
    payload: { ids: string[] };
}
