import { ContentTrait, PlaybackMode, UpsellType } from 'src/types/Eligibility';
import ISkyfireMethod from 'src/types/ISkyfireMethod';
import ISkyfireUpsellMethod, {
    INVOKE_HTTP,
} from 'src/types/ISkyfireUpsellMethod';
import ISkyfireAccentOutlineBadgeElement, {
    ACCENT_OUTLINE_BADGE_ELEMENT,
} from 'src/types/templates/widgets/ISkyfireAccentOutlineBadgeElement';
import ISkyfireBorderedBadgeElement, {
    BORDERED_BADGE_ELEMENT,
} from 'src/types/templates/widgets/ISkyfireBorderedBadgeElement';
import ISkyfireButtonElement from 'src/types/templates/widgets/ISkyfireButtonElement';
import ISkyfireUnborderedBadgeElement, {
    UNBORDERED_BADGE_ELEMENT,
} from 'src/types/templates/widgets/ISkyfireUnborderedBadgeElement';
import localization from '../resources/localization.json';
import { getMESKSkyfireEnvironment } from './getPodcastEnvironment';
import { MULTI_THREADED_QUEUE } from './skyfireContants';

export const DESCRIPTION = 'Description';
export const IMAGE = 'Image';

export function getButtonElement(
    availableUpsells: UpsellType[],
    contentTraits: ContentTrait[],
    onClick: ISkyfireMethod[],
    locale: string = 'en_US'
): ISkyfireButtonElement | undefined {
    const { isRedhoodSonicRushEnabled } = window.amznMusic.appConfig;

    if (!isRedhoodSonicRushEnabled) return undefined;
    if (!contentTraits) return undefined;
    const supportContentTypes =
        contentTraits.includes(ContentTrait.PRE_RELEASE) ||
        contentTraits.includes(ContentTrait.BONUS) ||
        contentTraits.includes(ContentTrait.EXCLUSIVE);
    if (!availableUpsells.length || !supportContentTypes) {
        return undefined;
    }
    const onItemSelected = [
        ...upsellMethods(getUpsellUrl(), availableUpsells),
        ...onClick,
    ];
    const buttonElement: ISkyfireButtonElement = {
        icon: '',
        iconOnly: false,
        disabled: false,
        text: localization[locale].dmp_upgrade_to_listen_now_text,
        onItemSelected,
    };
    return buttonElement;
}

export function getBadgeElement(
    availableUpsells: UpsellType[],
    contentTraits: ContentTrait[],
    badgeType: string,
    onClick: ISkyfireMethod[],
    locale: string = 'en_US'
):
    | ISkyfireUnborderedBadgeElement
    | ISkyfireBorderedBadgeElement
    | ISkyfireAccentOutlineBadgeElement
    | undefined {
    const isPrimeUser = window.amznMusic.appConfig.tier === 'PRIME';
    const {
        isRedhoodSonicRushEnabled,
        isAccentOutlineUpsellBadgeEnabled,
    } = window.amznMusic.appConfig;
    if (!isRedhoodSonicRushEnabled || !contentTraits?.length) {
        return undefined;
    }
    let badgeElement;
    const onItemSelected = [
        ...upsellMethods(getUpsellUrl(), availableUpsells),
        ...onClick,
    ];
    switch (badgeType) {
        case DESCRIPTION:
            if (contentTraits.includes(ContentTrait.AD_FREE)) {
                badgeElement = {
                    interface: UNBORDERED_BADGE_ELEMENT,
                    text: localization[locale].dmp_redhoodplus_ad_free,
                    onItemSelected,
                };
            } else if (contentTraits.includes(ContentTrait.AD_SUPPORTED)) {
                badgeElement = {
                    interface: BORDERED_BADGE_ELEMENT,
                    text:
                        localization[locale].dmp_redhoodplus_available_ad_free,
                    onItemSelected,
                };
            }
            break;
        case IMAGE:
            if (contentTraits.includes(ContentTrait.PRE_RELEASE)) {
                if (isPrimeUser && isAccentOutlineUpsellBadgeEnabled) {
                    badgeElement = {
                        interface: ACCENT_OUTLINE_BADGE_ELEMENT,
                        text: localization[locale].dmp_redhoodplus_early_access,
                        onItemSelected,
                    };
                } else {
                    badgeElement = {
                        interface: UNBORDERED_BADGE_ELEMENT,
                        text: localization[locale].dmp_redhoodplus_early_access,
                        onItemSelected,
                    };
                }
            } else if (contentTraits.includes(ContentTrait.BONUS)) {
                if (isPrimeUser && isAccentOutlineUpsellBadgeEnabled) {
                    badgeElement = {
                        interface: ACCENT_OUTLINE_BADGE_ELEMENT,
                        text: localization[locale].dmp_redhoodplus_exclusive,
                        onItemSelected,
                    };
                } else {
                    badgeElement = {
                        interface: UNBORDERED_BADGE_ELEMENT,
                        text: localization[locale].dmp_redhoodplus_exclusive,
                        onItemSelected,
                    };
                }
            } else if (contentTraits.includes(ContentTrait.EXCLUSIVE)) {
                if (isPrimeUser && isAccentOutlineUpsellBadgeEnabled) {
                    badgeElement = {
                        interface: ACCENT_OUTLINE_BADGE_ELEMENT,
                        text: localization[locale].dmp_redhoodplus_exclusive,
                        onItemSelected,
                    };
                } else {
                    badgeElement = {
                        interface: UNBORDERED_BADGE_ELEMENT,
                        text: localization[locale].dmp_redhoodplus_exclusive,
                        onItemSelected,
                    };
                }
            }
            break;
        default:
            break;
    }
    return badgeElement;
}

export function upsellMethods(
    url: string,
    availableUpsells: UpsellType[]
): ISkyfireMethod[] {
    if (!availableUpsells) return [];

    const upsellType: UpsellType = availableUpsells[0];

    if (!(upsellType in UpsellType)) return [];

    const upsellOnItemSelectedMethods: ISkyfireUpsellMethod = {
        interface: INVOKE_HTTP,
        url,
        before: [],
        after: [],
        onError: [],
        queue: {
            interface: MULTI_THREADED_QUEUE,
            id: 'MT_HTTP',
        },
        forced: false,
    };

    return [upsellOnItemSelectedMethods];
}

export function isPlayable(playbackMode: PlaybackMode) {
    const { isRedhoodSonicRushEnabled } = window.amznMusic.appConfig;
    if (!isRedhoodSonicRushEnabled) return true;
    if (!playbackMode || PlaybackMode.FULL_ACCESS === playbackMode) return true;
    return false;
}

function getUpsellUrl() {
    const SHOW_DYNAMIC_MESSAGE_PATH =
        '/api/showDynamicMessage?event=trigger%3A%2FineligibleContentRequest';
    return getMESKSkyfireEnvironment() + SHOW_DYNAMIC_MESSAGE_PATH;
}
