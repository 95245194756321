import React from 'react';
import { useSelector, useStore } from 'react-redux';
import ISkyfireCompletedElement, {
    COMPLETE_ELEMENT,
} from 'src/types/templates/widgets/ISkyfireCompletedElement';
import {
    DESCRIPTION,
    getBadgeElement,
    getButtonElement,
    IMAGE,
    isPlayable,
} from 'src/utils/RedhoodPlusMethods';
import IApplicationState from '../../types/IApplicationState';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import ISkyfireMessageTemplate from '../../types/templates/message';
import IPodcastLibraryPlaylist from '../../types/templates/widgets/IPodcastLibraryPlaylist';
import { getDefaultLanguage } from '../../utils/getDefaultLanguage';
import Message from '../../views/Message';
import menuStyles from '../../views/Menu.scss';
import InfiniteList from './items/InfiniteList';
import {
    ISkyfireInvokeHttpPostMethod,
    INVOKE_HTTP_POST,
} from '../../types/ISkyfireInvokeHttpPostMethod';
import { SaveDaoSessionStorage } from '../../Save';
import ISkyfireLinkElement from '../../types/ISkyfireLinkElement';
import ISkyfireButtonElement from '../../types/templates/widgets/ISkyfireButtonElement';
import ISkyfireEpisodeRowItem, {
    EPISODE_ROW_ITEM,
} from '../../types/templates/widgets/items/ISkyfireEpisodeRowItem';
import {
    UIMetrics,
    ActionType,
    ContentName,
    PageType,
    EntityType,
    EntityIdType,
} from '../../UIMetrics';
import {
    getPodcastPlaybackEnvironment,
    getSaveButton,
    getEpisodeDuration,
} from '../../utils';
import { PLAY_SAVED_EPISODE_WEB_SKILL } from '../../utils/podcastTargets';
import { SINGLE_THREADED_QUEUE } from '../../utils/skyfireContants';
import { invokeHttpPostSkillMethod } from '../../utils/invokeHttpPostSkillMethod';
import viewStyles from '../../views/View.scss';
import { useItemsInView } from '../../utils/useItemsInView';
import { episodeDeeplink } from '../../utils/deeplinks';
import EpisodeRowItem from './items/EpisodeRowItem';
import ISkyfireBookmarkElement, {
    BOOKMARK_ELEMENT,
} from '../../types/templates/widgets/ISkyfireBookmarkElement';
import ISkyfireObserver from '../../types/templates/observers/ISkyfireObserver';
import { IconConstants } from '../../utils/IconConstants';

interface IPodcastLibraryPlaylistProps {
    data: IPodcastLibraryPlaylist;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    box?: ClientRect;
    loading?: boolean;
    isVisualPlayQueue?: boolean;
    onViewed?: () => void;
}

export function PodcastLibraryPlaylist(props: IPodcastLibraryPlaylistProps) {
    const callbackRef = useItemsInView(props.onViewed);
    const { data } = props;
    const { marketplaceId, customerId } = useSelector(
        (state: IApplicationState) => state.Authentication
    );
    const language = getDefaultLanguage(marketplaceId, useStore());
    const storage = new SaveDaoSessionStorage(useStore());
    const items = storage.getAll(customerId, true).map((saveEntry, index) => {
        const invokePlay: ISkyfireInvokeHttpPostMethod = {
            interface: INVOKE_HTTP_POST,
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'PLAYBACK',
            },
            forced: false,
            before: [],
            after: [],
            onError: [],
            url: getPodcastPlaybackEnvironment(),
            preset: `{"episodeId":"${saveEntry.episodeId}"}`,
            target: PLAY_SAVED_EPISODE_WEB_SKILL,
            extra: {},
        };
        let onEpisodeSelected: ISkyfireMethod[] = [
            // @ts-ignore
            invokeHttpPostSkillMethod(
                `/podcasts/${saveEntry.podcastId}/episodes/${saveEntry.episodeId}`
            ),
        ];
        onEpisodeSelected = onEpisodeSelected.concat(
            UIMetrics.onClicked({
                actionType: ActionType.SELECT_PODCAST_EPISODE,
                contentName: ContentName.PODCASTS_LIBRARY_PLAYLIST,
                pageType: PageType.PODCASTS_LIBRARY_PLAYLIST,
                entityType: EntityType.PODCAST_EPISODE,
                entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                entityId: getEpisodeEntityId(saveEntry),
                entityPos: index,
            })
        );
        const episodeLink: ISkyfireLinkElement = {
            deeplink: episodeDeeplink(saveEntry),
            onItemSelected: onEpisodeSelected,
        };
        let onPlaySelected: ISkyfireMethod[] = [invokePlay];
        onPlaySelected = onPlaySelected.concat(
            UIMetrics.onClicked({
                actionType: ActionType.PLAY_PODCAST_EPISODE,
                contentName: ContentName.PODCASTS_LIBRARY_PLAYLIST,
                pageType: PageType.PODCASTS_LIBRARY_PLAYLIST,
                entityType: EntityType.PODCAST_EPISODE,
                entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                entityId: getEpisodeEntityId(saveEntry),
                entityPos: index,
            })
        );
        const setStorageMethod = {
            interface: 'StorageInterface.v1_0.SetValueInGroupMethod',
            forced: false,
            group: 'PLAYBACK_STATE',
            key: saveEntry.episodeId,
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'PLAYBACK',
            },
            value: 'PLAYING',
        };
        onPlaySelected.push(setStorageMethod);
        const observer: ISkyfireObserver = {
            storageGroup: 'PLAYBACK_STATE',
            storageKey: saveEntry.episodeId,
            states: {
                STOPPED: {
                    icon: IconConstants.PLAY,
                    onItemSelected: onPlaySelected,
                    disabled: false,
                    text: '',
                    iconOnly: true,
                    interface:
                        'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.ButtonElement',
                },
                LOADING: {
                    icon: IconConstants.LOADING,
                    onItemSelected: [],
                    disabled: false,
                    text: '',
                    iconOnly: true,
                    interface:
                        'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.ButtonElement',
                },
                PLAYING: {
                    icon: IconConstants.PAUSED,
                    onItemSelected: [],
                    disabled: false,
                    text: '',
                    iconOnly: true,
                    interface:
                        'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.ButtonElement',
                },
                PAUSED: {
                    icon: IconConstants.RESUMED,
                    onItemSelected: [],
                    disabled: false,
                    text: '',
                    iconOnly: true,
                    interface:
                        'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.ButtonElement',
                },
            },
            defaultState: 'STOPPED',
            defaultValue: null,
        };
        const playButton: ISkyfireButtonElement = {
            icon: IconConstants.PLAY,
            onItemSelected: onPlaySelected,
            disabled: false,
            text: '',
            iconOnly: true,
            observer,
        };
        const onUpsellClick = UIMetrics.onClicked({
            actionType: ActionType.SELECT_PODCAST_UPSELL,
            contentName: ContentName.PODCASTS_LIBRARY_PLAYLIST,
            pageType: PageType.PODCASTS_LIBRARY_PLAYLIST,
            entityType: EntityType.PODCAST_EPISODE,
            entityIdType: EntityIdType.PODCAST_EPISODE_ID,
            entityId: getEpisodeEntityId(saveEntry),
            entityPos: index,
        });
        let saveButton: ISkyfireButtonElement | undefined;
        let descriptionButton: ISkyfireButtonElement | undefined;
        if (isPlayable(saveEntry.playbackMode)) {
            saveButton = getSaveButton(
                'SAVE',
                saveEntry.episodeId,
                saveEntry.podcastId,
                saveEntry.podcastTitle,
                saveEntry.title,
                saveEntry.description,
                saveEntry.image,
                saveEntry.podcastImage,
                saveEntry.authors,
                saveEntry.seasonNumber,
                saveEntry.totalDurationMilliseconds,
                language,
                saveEntry.publishTime,
                ContentName.PODCASTS_LIBRARY_PLAYLIST,
                PageType.PODCASTS_LIBRARY_PLAYLIST,
                saveEntry.playbackMode,
                saveEntry.availableUpsells,
                saveEntry.contentTraits,
                saveEntry.availabilityDate,
                saveEntry.podcastShowVariantId,
                saveEntry.podcastEpisodeVariantId
            );
        } else {
            descriptionButton = getButtonElement(
                saveEntry.availableUpsells,
                saveEntry.contentTraits,
                onUpsellClick,
                language
            );
        }

        const bookmarkElement: ISkyfireBookmarkElement = {
            interface: BOOKMARK_ELEMENT,
            id: saveEntry.episodeId,
            totalDurationMilliseconds: saveEntry.totalDurationMilliseconds,
        };

        const completedElement: ISkyfireCompletedElement = {
            interface: COMPLETE_ELEMENT,
            id: saveEntry.episodeId,
        };

        const { isRedhoodSonicRushEnabled } = window.amznMusic.appConfig;

        const imageBadge = getBadgeElement(
            saveEntry.availableUpsells,
            saveEntry.contentTraits,
            IMAGE,
            onUpsellClick,
            language
        );
        const descriptionBadge = getBadgeElement(
            saveEntry.availableUpsells,
            saveEntry.contentTraits,
            DESCRIPTION,
            onUpsellClick,
            language
        );
        const episodeRowItem: ISkyfireEpisodeRowItem = {
            interface: EPISODE_ROW_ITEM,
            iconButton: playButton,
            button: saveButton,
            image: saveEntry.image,
            primaryLink: episodeLink,
            primaryText: saveEntry.title,
            secondaryText: saveEntry.podcastTitle,
            description: saveEntry.description,
            duration: getEpisodeDuration(
                language,
                saveEntry.totalDurationMilliseconds
            ),
            label: saveEntry.publishTime,
            imageAltText: saveEntry.title,
            bookmarkElement,
            completedElement,
            imageBadge,
            descriptionBadge,
            descriptionButton,
            playbackMode: isRedhoodSonicRushEnabled
                ? saveEntry.playbackMode
                : undefined,
        };
        if (isPlayable(saveEntry.playbackMode)) {
            episodeRowItem.showPlayButton = true;
        }
        return episodeRowItem;
    });

    data.items = items;

    const emptyMessageTemplate = {
        message: data.fallbackMessage,
    };

    return items.length > 0 ? (
        <InfiniteList
            data={data}
            handleSelected={props.handleSelected}
            componentType={EpisodeRowItem}
            rowHeight={160}
            loading={props?.loading}
            isVisualPlayQueue={props?.isVisualPlayQueue}
            itemsViewedRef={callbackRef}
        />
    ) : (
        <div
            className={
                menuStyles['message-container'] + viewStyles['view-content']
            }
        >
            <Message
                template={emptyMessageTemplate as ISkyfireMessageTemplate}
            />
        </div>
    );
}

function getEpisodeEntityId(element) {
    const {
        isRedhoodPlusMetricsEnabled,
        isRedhoodSonicRushEnabled,
    } = window.amznMusic.appConfig;
    return element.podcastEpisodeVariantId &&
        isRedhoodPlusMetricsEnabled &&
        isRedhoodSonicRushEnabled
        ? element.podcastEpisodeVariantId
        : element.episodeId;
}
