import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindHandler, dispatchSkyfireMethods } from '../../utils';
import { useInView } from '../../utils/useItemsInView';
export default function FeaturedCard(props) {
    var _a;
    const { smallImage, mediumImage, imageDescription, item, onViewed, onItemSelected, metricsToken, } = props.data;
    const template = useSelector((state) => state.TemplateStack.currentTemplate);
    const dispatch = useDispatch();
    const callbackRef = useInView(props.onViewed);
    const onClick = useCallback(() => {
        var _a;
        if (template) {
            dispatchSkyfireMethods(dispatch, template, (_a = item.primaryLink) === null || _a === void 0 ? void 0 : _a.onItemSelected);
        }
    }, [dispatch, (_a = item.primaryLink) === null || _a === void 0 ? void 0 : _a.onItemSelected, template]);
    return (React.createElement("div", null,
        React.createElement("music-barker", { ref: callbackRef, href: item.primaryLink, onClick: bindHandler(onClick, null), "small-image": smallImage, "medium-image": mediumImage, "image-alt": imageDescription, item: item, gradient: true })));
}
