import { Store } from 'redux';
import {
    AppSyncTypes,
    FOLLOWS_LAST_SYNC_TIME,
    FOLLOWS_LAST_SYNC_TIME_CLIENT_STATE,
    FOLLOWS_SET_ON_REPORT_OPERATIONS_METHOD,
} from '../appsync/AppSyncConstants';
import { Entry } from '../appsync/Entry';
import { SyncData } from '../appsync/SyncData';
import {
    IConditionElement,
    ITimeConditionElement,
} from '../types/appsync/IAppSync';
import IApplicationState from '../types/IApplicationState';
import { FollowSetOnReportOperationsMethod } from '../appsync/FollowSetOnReportOperationsMethod';
import { OperationElement } from '../appsync/OperationElement';
import { TimeConditionElement } from '../appsync/TimeConditionElement';
import { Follow } from './Follow';
import { FollowOperation } from './FollowOperation';
import { FollowOperationsClientState } from './FollowOperationsClientState';
import { FollowsLastSyncTimeClientState } from './FollowsLastSyncTimeClientState';
import { FollowWriteElement } from './FollowWriteElement';
import { IFollowDao } from './IFollowDao';
import { IFollowOperationDao } from './IFollowOperationDao';
import { FollowMetadataWriteElement } from './FollowMetadataWriteElement';

export class FollowSyncData extends SyncData<
    FollowWriteElement,
    FollowMetadataWriteElement,
    Follow
> {
    private followDao: IFollowDao;

    private followOperationDao: IFollowOperationDao;

    private store: Store<IApplicationState>;

    constructor(
        followDao: IFollowDao,
        followOperationDao: IFollowOperationDao,
        store
    ) {
        super();
        this.followDao = followDao;
        this.followOperationDao = followOperationDao;
        this.store = store;
    }

    public read(id: string): Follow | undefined {
        const { customerId } = this.store.getState().Authentication;
        const follow = this.followDao.getFollow(id, customerId);
        if (follow) {
            return follow;
        }
        return undefined;
    }

    public readStates(): Follow[] {
        const { customerId } = this.store.getState().Authentication;
        return this.followDao.getAll(customerId, true);
    }

    public writeStates(
        element: FollowWriteElement,
        condition?: IConditionElement
    ) {
        const timeCondition = condition as ITimeConditionElement;
        const updatedTime = timeCondition?.updatedTime
            ? timeCondition?.updatedTime
            : Date.now();
        const follow = new Follow({
            id: element.id,
            title: element.title,
            podcastDescription: element.description,
            publisher: element.publisher,
            image: element.image,
            isFollowed: element.followed,
            updatedTime,
            podcastShowVariantId: element.podcastShowVariantId,
        });
        this.followDao.insert(follow);
    }

    public writeToOperations(element: FollowWriteElement) {
        const followOperation = new FollowOperation({
            elementId: element.id,
            title: element.title,
            podcastDescription: element.description,
            image: element.image,
            isFollowed: element.followed,
            updatedTime: Date.now(),
            isProcessing: false,
        });
        this.followOperationDao.insert(followOperation);
    }

    public writeMetadata(element: FollowMetadataWriteElement) {
        const follow = this.read(element.id);
        if (!follow) return;
        const updatedFollow = new Follow({
            id: element.id,
            title: element.title,
            image: element.image,
            podcastDescription: element.description,
            publisher: element.publisher,
            podcastShowVariantId: element.podcastShowVariantId,
        });
        this.followDao.insert(updatedFollow);
    }

    public reportOperations() {
        const operations = new FollowSetOnReportOperationsMethod();
        const onReportOperations = [operations];
        this.store.dispatch({
            type: FOLLOWS_SET_ON_REPORT_OPERATIONS_METHOD,
            payload: { onReportOperations },
        });
    }

    public getClientState(name: string): Entry {
        if (name === FOLLOWS_LAST_SYNC_TIME_CLIENT_STATE) {
            return this.getFollowsLastSyncTimeClientState();
        }
        const operations = this.followOperationDao.getUnProcessed();
        const clientState = this.convertOperationsToClientState(operations);
        const ids: string[] = [];
        operations.forEach((operation) => {
            ids.push(operation.operationId);
        });
        this.followOperationDao.update(ids, true);
        return new Entry(AppSyncTypes.FOLLOW, clientState);
    }

    public getFollowsLastSyncTimeClientState(): Entry {
        const { customerId } = this.store.getState().Authentication;
        const lastSyncTime = this.followDao.getLastSyncTime(customerId);
        return new Entry(
            FOLLOWS_LAST_SYNC_TIME,
            new FollowsLastSyncTimeClientState(lastSyncTime)
        );
    }

    public deleteOperation(ids: string[]) {
        this.followOperationDao.delete(ids);
    }

    public conditionSatisfied(
        element: FollowWriteElement,
        condition: IConditionElement
    ): boolean {
        if (condition == null) {
            return true;
        }
        const timeCondition = condition as ITimeConditionElement;
        if (timeCondition == null) {
            return true;
        }
        const { customerId } = this.store.getState().Authentication;
        const follow = this.followDao.getFollow(element.id, customerId);
        if (follow) {
            return timeCondition.updatedTime >= follow.updatedTime;
        }
        return true;
    }

    private convertOperationsToClientState(
        pendingOperations: FollowOperation[]
    ): FollowOperationsClientState {
        const operations: OperationElement[] = [];
        pendingOperations.forEach((operation) => {
            const writeElement: FollowWriteElement = this.convertOperationToFollowWriteElement(
                operation
            );
            const timeConditionElement: TimeConditionElement = new TimeConditionElement(
                operation.updatedTime
            );
            const operationElement: OperationElement = new OperationElement(
                operation.operationId,
                writeElement,
                timeConditionElement
            );
            operations.push(operationElement);
        });
        return new FollowOperationsClientState(operations);
    }

    private convertOperationToFollowWriteElement(
        operation: FollowOperation
    ): FollowWriteElement {
        return new FollowWriteElement(
            operation.elementId,
            operation.title,
            operation.podcastDescription,
            operation.publisher ?? '',
            operation.image,
            operation.isFollowed
        );
    }
}
