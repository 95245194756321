import React from 'react';
import { useItemsInView } from '../../utils/useItemsInView';
import { RECTANGLE_VERTICAL_ITEM } from '../../types/templates/widgets/items/ISkyfireVerticalItem';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import ISkyfireVisualShoveler from '../../types/templates/widgets/ISkyfireVisualShoveler';
import VerticalItem, {
    getItemSize,
    VerticalItemSize,
} from './items/VerticalItem';

interface IVisualShovelerProps {
    data: ISkyfireVisualShoveler;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    onViewed?: () => void;
}

export default function VisualShoveler(props: IVisualShovelerProps) {
    const { header, items, seeMoreItem, onEndOfWidget, isCompact } = props.data;
    const callbackRef = useItemsInView(props.onViewed);

    if (items?.length) {
        const sizes = items.map((item) => getItemSize(item));
        const maxSize = sizes.reduce(
            (acc, size) => (size > acc ? size : acc),
            VerticalItemSize.Small
        );
        const rectangleItemCount = items.filter(
            (item) => item.interface === RECTANGLE_VERTICAL_ITEM
        ).length;
        const containsRectangleAndNonRectangleItems =
            items.length !== rectangleItemCount && rectangleItemCount > 0;

        return (
            <div>
                <music-shoveler
                    key={header}
                    see-all={seeMoreItem?.text}
                    primary-text={header}
                    onmusicSeeAllActivate={props.handleSelected.bind(
                        null,
                        seeMoreItem?.primaryLink?.onItemSelected
                    )}
                    onmusicScrolledToEndActivate={props.handleSelected.bind(
                        null,
                        onEndOfWidget
                    )}
                    isCompact={isCompact}
                >
                    {items.map((item, i) => (
                        <VerticalItem
                            ref={callbackRef}
                            key={i}
                            data={item}
                            handleSelected={props.handleSelected}
                            parentSize={maxSize}
                            constraint={
                                containsRectangleAndNonRectangleItems
                                    ? 'square'
                                    : undefined
                            }
                        />
                    ))}
                </music-shoveler>
            </div>
        );
    }

    return null;
}
