export class IconConstants {
    public static DONE = 'done';

    public static ADD = 'add';

    public static PLAY = 'play';

    public static LOADING = 'loader';

    public static PAUSED = 'pause';

    public static RESUMED = 'resume';
}
