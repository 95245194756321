import { IWriteElement } from '../types/appsync/IAppSync';

export class UIMetricWriteElement implements IWriteElement {
    public id: string;

    public signalType: string;

    public metricsPreset: string;

    public pageType: string;

    public contentSrc: string;

    public containerIndex: number;

    public containerType: string;

    public entityType: string;

    public entityIdType: string;

    public entityId: string;

    public entityPos: number;

    public detailPageItemId: string;

    public detailPageItemIdType: string;

    public refMarker: string;

    public referer: string;

    public actionType: string;

    public interactionType: string;

    public contentName: string;

    public firstViewableIndex: number;

    public lastViewableIndex: number;

    public __type: string;

    public interface?: string;

    constructor(element: {
        id;
        signalType;
        metricsPreset?;
        pageType?;
        contentSrc?;
        containerIndex?;
        containerType?;
        entityType?;
        entityIdType?;
        entityId?;
        entityPos?;
        detailPageItemId?;
        detailPageItemIdType?;
        refMarker?;
        referer?;
        actionType?;
        interactionType?;
        contentName?;
        firstViewableIndex?;
        lastViewableIndex?;
    }) {
        this.id = element.id;
        this.signalType = element.signalType;
        this.metricsPreset = element.metricsPreset;
        this.pageType = element.pageType;
        this.contentSrc = element.contentSrc;
        this.containerIndex = element.containerIndex;
        this.metricsPreset = element.metricsPreset;
        this.containerType = element.containerType;
        this.entityType = element.entityType;
        this.entityIdType = element.entityIdType;
        this.entityId = element.entityId;
        this.entityPos = element.entityPos;
        this.detailPageItemId = element.detailPageItemId;
        this.detailPageItemIdType = element.detailPageItemIdType;
        this.refMarker = element.refMarker;
        this.referer = element.referer;
        this.actionType = element.actionType;
        this.interactionType = element.interactionType;
        this.contentName = element.contentName;
        this.firstViewableIndex = element.firstViewableIndex;
        this.lastViewableIndex = element.lastViewableIndex;
        this.__type = 'Podcast.UIMetricsInterface.v1_0#UIMetricWriteElement';
        this.interface = 'Podcast.Web.UIMetricsInterface.UIMetricWriteElement';
    }
}
