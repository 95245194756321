import { CLEAR_SAMPLE, SET_SAMPLE_MEDIA, } from '../actions/Playback';
const initialState = {
    mediaId: '',
    isSamplePlayback: false,
};
const reducer = {
    [CLEAR_SAMPLE]: (state, action) => initialState,
    [SET_SAMPLE_MEDIA]: (state, action) => (Object.assign(Object.assign({}, initialState), action.payload.metadata)),
};
export function SampleMediaReducer(state = initialState, action) {
    if (reducer[action.type]) {
        return reducer[action.type](state, action);
    }
    return state;
}
