import { computeElementOffsetFromPage } from './computeElementOffsetFromPage';
/**
 * Encapsulates the behavior of scrolling to a target row index within a tabular widget.
 * Scrolling is performed a single time, after widget render and after 'isEnabled' is
 * flipped to true.
 *
 * @param isEnabled Whether or not scroll-to-row is enabled
 * @param indexToScroll Scrollable widget row index
 * @param rowHeight Height of a row (assumes all rows have equal height)
 * @param containerRef (Optional) Ref of parent container (for calculating y-offset)
 * @param yOffset (Optional) Value added to target Y calculation
 *                           (e.g. to compensate for static navbar)
 */
export const scrollToRow = (isEnabled, indexToScroll, rowHeight, containerRef = null, yOffset = 0) => {
    var _a, _b;
    if (isEnabled && indexToScroll > -1) {
        const offsets = computeElementOffsetFromPage((_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) !== null && _a !== void 0 ? _a : null);
        const containerTop = (_b = offsets === null || offsets === void 0 ? void 0 : offsets.offsetTop) !== null && _b !== void 0 ? _b : 0;
        const rowYOffset = indexToScroll * rowHeight; // offset for top of target row
        const targetYOffset = containerTop + rowYOffset + yOffset;
        window.scrollTo({ top: targetYOffset, behavior: 'auto' });
    }
};
