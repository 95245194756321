import { SET_RWP_RETAIL_NAVBAR_VISIBILITY_STATUS, SET_MSHOP_BOTTOM_OVERRIDE, ENABLE_MSHOP_BOTTOM_OVERRIDE, } from '../actions';
const initialState = {
    isRWPRetailNavbarInview: false,
    mShopBottomOverride: 0,
    enableMShopBottomOverride: false,
};
export function RWPReducer(state = initialState, action) {
    switch (action.type) {
        case SET_RWP_RETAIL_NAVBAR_VISIBILITY_STATUS:
            return Object.assign(Object.assign({}, state), { isRWPRetailNavbarInview: action.payload.isRWPRetailNavbarInview });
        case SET_MSHOP_BOTTOM_OVERRIDE:
            return Object.assign(Object.assign({}, state), { mShopBottomOverride: action.payload.mShopBottomOverride });
        case ENABLE_MSHOP_BOTTOM_OVERRIDE:
            return Object.assign(Object.assign({}, state), { enableMShopBottomOverride: action.payload.enableMShopBottomOverride });
        default:
            return state;
    }
}
