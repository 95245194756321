import { ContentTrait, PlaybackMode, UpsellType } from 'src/types/Eligibility';
import { IWriteElement } from '../types/appsync/IAppSync';

export class SaveWriteElement implements IWriteElement {
    public id: string;

    public podcastId: string;

    public podcastTitle: string;

    public title: string;

    public image: string;

    public podcastImage: string;

    public description: string;

    public authors: string[];

    public seasonNumber?: number;

    public totalDurationMilliseconds: number;

    public saved: boolean;

    public __type: string;

    public interface?: string;

    public publishTime: string;

    public playbackMode: PlaybackMode;

    public availableUpsells: UpsellType[];

    public contentTraits?: ContentTrait[];

    public availabilityDate?: string;

    public podcastShowVariantId?: string;

    public podcastEpisodeVariantId?: string;

    constructor(
        id,
        podcastId,
        podcastTitle,
        title,
        image,
        podcastImage,
        description,
        authors,
        seasonNumber,
        totalDurationMilliseconds,
        saved,
        publishTime,
        playbackMode?,
        availableUpsells?,
        contentTraits?,
        availabilityDate?,
        podcastShowVariantId?,
        podcastEpisodeVariantId?
    ) {
        this.id = id;
        this.podcastId = podcastId;
        this.podcastTitle = podcastTitle;
        this.title = title;
        this.image = image;
        this.podcastImage = podcastImage;
        this.description = description;
        this.authors = authors;
        this.seasonNumber = seasonNumber;
        this.totalDurationMilliseconds = totalDurationMilliseconds;
        this.saved = saved;
        this.__type = 'Podcast.SaveInterface.v1_0#SaveWriteElement';
        this.interface = 'Podcast.Web.SaveInterface.SaveWriteElement';
        this.publishTime = publishTime;
        this.playbackMode = playbackMode;
        this.availableUpsells = availableUpsells;
        this.contentTraits = contentTraits;
        this.availabilityDate = availabilityDate;
        this.podcastShowVariantId = podcastShowVariantId;
        this.podcastEpisodeVariantId = podcastEpisodeVariantId;
    }
}
