import { SET_ACTIVE_TAB } from '../actions';
const initialState = {
    activeSectionId: '',
};
export function TabsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVE_TAB:
            return Object.assign(Object.assign({}, state), { activeSectionId: action.payload.activeSectionId });
        default:
            return state;
    }
}
