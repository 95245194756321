exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3RnOmE2Gr8ytJ-2mW10vx-{display:flex;justify-content:flex-end}._3RnOmE2Gr8ytJ-2mW10vx-._1fQFOgrLSZqW7V8zKXhCep{min-width:80px}", ""]);

// exports
exports.locals = {
	"slots-container": "_3RnOmE2Gr8ytJ-2mW10vx-",
	"slotsContainer": "_3RnOmE2Gr8ytJ-2mW10vx-",
	"enable-min-width": "_1fQFOgrLSZqW7V8zKXhCep",
	"enableMinWidth": "_1fQFOgrLSZqW7V8zKXhCep"
};