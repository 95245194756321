import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Countdown, { zeroPad } from 'react-countdown';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as Styles from './EventHeader.scss';
import { dispatchSkyfireMethods, preventDefault } from '../utils';
import Template from '../Contexts/Template';
import { getICalURL, getLocalizedEventDate, getLocalizedEventTime, } from '../utils/liveEventDateTime';
import { isMobileUserAgent } from '../utils/platform';
import { useInView } from '../utils/useItemsInView';
import { globals, useSourceCasing } from '../utils/globals';
const getPrimaryText = (startDate, endDate, hasMultipleLineups, displayLanguageId, dispatch) => {
    const tz = globals.timezone;
    const formattedLocaleString = displayLanguageId.replace('_', '-');
    // For events with multiple lineups, show start date and end date
    if (hasMultipleLineups) {
        return `${getLocalizedEventDate(startDate, formattedLocaleString, tz, dispatch)} - ${getLocalizedEventDate(endDate, formattedLocaleString, tz, dispatch)}`;
    }
    // For events with just one lineup, show date and time
    return `${getLocalizedEventDate(startDate, formattedLocaleString, tz, dispatch)} ∙ ${getLocalizedEventTime(startDate, formattedLocaleString, tz, dispatch)}`;
};
const countdownRenderer = ({ days, hours, minutes, seconds }) => (React.createElement("div", { className: Styles.counterContainer },
    React.createElement("div", { className: "headline-4", id: Styles.daysText }, zeroPad(days)),
    React.createElement("div", { className: "headline-4", id: Styles.hoursText }, zeroPad(hours)),
    React.createElement("div", { className: "headline-4", id: Styles.minutesText }, zeroPad(minutes)),
    React.createElement("div", { className: "headline-4", id: Styles.secondsText }, zeroPad(seconds))));
export default function EventHeader(props) {
    const { primaryText: headline, secondaryText, tertiaryText, postEventText, calendarDescription, durationUnitText, hasMultipleLineups, image, liveImage, label, buttons, iconButtons, startTime, endTime, daysText, hoursText, minutesText, secondsText, onRefresh, status, nextStartDate, nextEndDate, isExperimentLiveEvent, } = props.data;
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const nextStart = new Date(nextStartDate);
    const nextEnd = new Date(nextEndDate);
    const callbackRef = useInView(props.onViewed);
    const dispatch = useDispatch();
    const template = useContext(Template);
    const displayLanguageId = useSelector((state) => state.Authentication.displayLanguageId);
    const onClick = useCallback((onItemSelected) => {
        if (template) {
            dispatchSkyfireMethods(dispatch, template, onItemSelected);
        }
    }, [dispatch, template]);
    const onCalendarClick = () => {
        dayjs.extend(utc);
        const urlWithRef = new URL(document.URL);
        urlWithRef.searchParams.append('ref', 'dm_aml_cal');
        const icalFormattedStartDate = dayjs(startDate)
            .utc()
            .format('YYYYMMDDTHHmmssZ')
            .replace('+00:00', 'Z');
        const icalFormattedEndDate = dayjs(endDate)
            .utc()
            .format('YYYYMMDDTHHmmssZ')
            .replace('+00:00', 'Z');
        const formattedCalendarDescription = [`${urlWithRef}`, calendarDescription]
            .join('\n\n')
            .replace(/\n/g, '\\n');
        const url = getICalURL(icalFormattedStartDate, icalFormattedEndDate, headline, formattedCalendarDescription);
        if (!isMobileUserAgent()) {
            const filename = `${headline}.ics`;
            const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            window.open(url, '_blank');
        }
    };
    useStyles(Styles);
    return (React.createElement("div", { className: isExperimentLiveEvent
            ? Styles.eventHeaderContainerExperiment
            : Styles.eventHeaderContainer },
        React.createElement("div", { className: Styles.eventDetailPageOverlay }),
        React.createElement("music-detail-header", { ref: callbackRef, key: headline, style: {
                contain: 'layout',
                marginTop: '24px',
            }, label: label, headline: headline, "primary-text": status === 'AFTERGLOW'
                ? postEventText
                : `${getPrimaryText(startDate, endDate, hasMultipleLineups, displayLanguageId, dispatch)}`, "primary-text-href": null, onmusicPrimaryTextActivate: null, "secondary-text": status === 'AFTERGLOW' ? null : secondaryText, "secondary-text-href": null, onmusicSecondaryTextActivate: null, onClick: preventDefault, useFullWidthHeader: isExperimentLiveEvent, templateType: "DEFAULT_TEMPLATE", 
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useSourceCasing: useSourceCasing() }),
        React.createElement("div", { className: isExperimentLiveEvent ? '' : Styles.eventHeaderDetailContainer },
            React.createElement("div", { className: [
                    Styles.imageContainer,
                    status === 'AFTERGLOW' ? Styles.offline : '',
                ].join(' ') }, isExperimentLiveEvent === false && (React.createElement("music-image", { src: status === 'LIVE' ? liveImage : image, alt: headline }))),
            (status === 'UPCOMING' || status === 'BETWEEN') && (React.createElement("div", null,
                React.createElement("div", { className: Styles.countdownContainer },
                    React.createElement("div", { className: Styles.innerCounterContainer },
                        React.createElement(Countdown, { date: status === 'UPCOMING' ? startDate : nextStart, intervalDelay: 0, precision: 4, renderer: countdownRenderer, onComplete: () => {
                                if (template) {
                                    dispatchSkyfireMethods(dispatch, template, onRefresh);
                                }
                            } })),
                    React.createElement("div", { className: Styles.timeTextContainer },
                        React.createElement("div", { className: Styles.timeLabelText },
                            React.createElement("div", { className: "music-tertiary-text" }, daysText)),
                        React.createElement("div", { className: Styles.timeLabelText },
                            React.createElement("div", { className: "music-tertiary-text" }, hoursText)),
                        React.createElement("div", { className: Styles.timeLabelText },
                            React.createElement("div", { className: "music-tertiary-text" }, minutesText)),
                        React.createElement("div", { className: Styles.timeLabelText },
                            React.createElement("div", { className: "music-tertiary-text" }, secondsText)))),
                React.createElement("div", { className: Styles.calendarCtaContainer },
                    buttons.map((button) => (
                    // Add to calendar button
                    React.createElement("music-button", { key: button.icon, "icon-name": button.icon, slot: "buttons", variant: isExperimentLiveEvent ? 'solid' : 'glass', onClick: () => {
                            onCalendarClick();
                            onClick(button.onItemSelected);
                        } }, button.text))),
                    iconButtons.map((button) => (
                    // Share icon button
                    React.createElement("music-button", { key: button.icon, "icon-name": button.icon, "icon-only": true, size: "small", slot: "buttons", variant: "glass", onClick: onClick.bind(this, button.onItemSelected) })))))),
            status === 'LIVE' && (React.createElement("div", null,
                React.createElement("div", { className: Styles.invisible },
                    React.createElement(Countdown, { date: nextEnd, intervalDelay: 0, precision: 4, renderer: countdownRenderer, onComplete: () => {
                            if (template) {
                                dispatchSkyfireMethods(dispatch, template, onRefresh);
                            }
                        } })),
                React.createElement("div", { className: Styles.ctaContainer },
                    buttons.map((button, index) => (
                    // Ways to Watch buttons
                    React.createElement("music-button", { key: button.icon, href: button.href, slot: "buttons", target: "_blank", variant: index % 2 === 0 ? 'solid' : 'outline', onClick: onClick.bind(this, button.onItemSelected) }, button.text))),
                    React.createElement("div", { className: Styles.iconsContainer }, iconButtons.map((btn) => (
                    // Share icon button
                    React.createElement("music-button", { key: btn.icon, iconName: btn.icon, iconOnly: true, size: "small", slot: "buttons", variant: "glass", onClick: onClick.bind(this, btn.onItemSelected) }))))))),
            status === 'AFTERGLOW' && buttons && buttons.length > 0 && (React.createElement("div", { className: Styles.ctaContainer },
                buttons.map((button, index) => (
                // Ways to Watch buttons
                React.createElement("music-button", { key: button.icon, "icon-name": button.icon, href: button.href, slot: "buttons", target: "_blank", variant: index % 2 === 0 ? 'solid' : 'outline', onClick: onClick.bind(this, button.onItemSelected) }, button.text))),
                React.createElement("div", { className: Styles.iconsContainer }, iconButtons.map((btn) => (
                // Share icon button
                React.createElement("music-button", { key: btn.icon, iconName: btn.icon, iconOnly: true, size: "small", slot: "buttons", variant: "glass", onClick: onClick.bind(this, btn.onItemSelected) })))))))));
}
