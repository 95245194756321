import { ITimeConditionElement } from '../types/appsync/IAppSync';
import { ConditionElement } from './ConditionElement';

export class TimeConditionElement
    extends ConditionElement
    implements ITimeConditionElement {
    public updatedTime: number;

    public __type: string;

    constructor(updatedTime: number) {
        super();
        this.updatedTime = updatedTime;
        this.__type = 'SOAAppSyncInterface.v1_0#TimeConditionElement';
    }
}
