import { Store } from 'redux';
import {
    COMPLETED_LAST_SYNC_TIME,
    WEB_STORAGE,
} from '../appsync/AppSyncConstants';
import IApplicationState from '../types/IApplicationState';
import { Completed } from './Completed';
import { SAVE_COMPLETED } from './CompletedActions';
import { ICompletedDao } from './ICompletedDao';

export class CompletedDaoSessionStorage implements ICompletedDao {
    private store: Store<IApplicationState>;

    constructor(store) {
        this.store = store;
    }

    public insert(completed: Completed) {
        this.store.dispatch({
            type: SAVE_COMPLETED,
            payload: { completed },
        });
    }

    public getCompleted(id: string): Completed | undefined {
        const key = `${id}_Completed`;
        const completed = WEB_STORAGE.getItem(key);
        if (completed) {
            return new Completed(JSON.parse(completed));
        }
        return undefined;
    }

    public getAll(isCompleted: boolean, isLimited?: boolean): Completed[] {
        let isCompletedList: Completed[] = [];
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (key.endsWith('Completed')) {
                const completed = JSON.parse(value);
                if (completed && completed.isCompleted === isCompleted) {
                    isCompletedList.push(new Completed(completed));
                }
            }
        }
        isCompletedList = isCompletedList.sort((a, b) =>
            a.updatedTime < b.updatedTime ? 1 : -1
        );
        if (isLimited === true) {
            isCompletedList = isCompletedList.slice(0, 20);
            return isCompletedList;
        }
        return isCompletedList;
    }

    public getLastSyncTime(customerId: string): number {
        const key = COMPLETED_LAST_SYNC_TIME;
        const lastSyncTime = Number(WEB_STORAGE.getItem(key));
        return lastSyncTime ?? 0;
    }

    public clearCompletedData(customerId: string) {
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (
                key.endsWith('Completed') ||
                key.endsWith(COMPLETED_LAST_SYNC_TIME)
            ) {
                WEB_STORAGE.removeItem(key);
            }
        }
    }
}
