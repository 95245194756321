import { PlaybackMode } from 'src/types/Eligibility';
import ImageDimension from '../ImageDimension';
import ISkyfireLinkElement from '../../../ISkyfireLinkElement';
import ISkyfireContextMenuElement from '../../contextMenu/ISkyfireContextMenuElement';
import ISkyfireButtonElement from '../ISkyfireButtonElement';
import ISkyfireBookmarkElement from '../ISkyfireBookmarkElement';
import ISkyfireCompletedElement from '../ISkyfireCompletedElement';
import ISkyfireUnborderedBadgeElement from '../ISkyfireUnborderedBadgeElement';
import ISkyfireBorderedBadgeElement from '../ISkyfireBorderedBadgeElement';
import ISkyfireLightUnborderedBadgeElement from '../ISkyfireLightUnborderedBadgeElement';
import ISkyfireAccentOutlineBadgeElement from '../ISkyfireAccentOutlineBadgeElement';

export const EPISODE_ROW_ITEM =
    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.EpisodeRowItemElement';

export default interface ISkyfireEpisodeRowItem {
    interface: typeof EPISODE_ROW_ITEM;
    image: string;
    imageAltText: string;
    imageDimension?: ImageDimension;
    primaryText: string;
    primaryLink: ISkyfireLinkElement;
    label: string;
    secondaryText?: string;
    secondaryLink?: ISkyfireLinkElement;
    description?: string;
    duration?: string;
    bookmarkElement: ISkyfireBookmarkElement;
    completedElement: ISkyfireCompletedElement;
    button?: ISkyfireButtonElement;
    contextMenu?: ISkyfireContextMenuElement;
    iconButton?: ISkyfireButtonElement;
    imageBadge?:
        | ISkyfireUnborderedBadgeElement
        | ISkyfireBorderedBadgeElement
        | ISkyfireAccentOutlineBadgeElement;
    descriptionBadge?:
        | ISkyfireUnborderedBadgeElement
        | ISkyfireBorderedBadgeElement
        | ISkyfireLightUnborderedBadgeElement
        | ISkyfireAccentOutlineBadgeElement;
    descriptionButton?: ISkyfireButtonElement | undefined;
    playbackMode?: PlaybackMode;
    showPlayButton?: boolean;
}
