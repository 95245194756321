// @ts-ignore
import React, { Fragment, useErrorBoundary } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REPORT_ERROR } from '../actions/Reporting';
import { cleanDeeplink, getCurrentTemplateDeeplink, globals } from '../utils';
import { recordError } from '../utils/cloudwatchRumUtils';
import { reportFlexEvent } from '../utils/reportFlexEvent';
export async function reportError(error, Authentication, deeplink) {
    const { UAParser } = await import(/* webpackChunkName: "metrics" */ 'ua-parser-js');
    const ua = new UAParser(globals.navigator.userAgent);
    const browser = ua.getBrowser();
    reportFlexEvent('WebPlayerClientError', [
        [deeplink, ua.getOS().name, browser.name, error.stack || error.message],
        [Number(browser.major)],
    ], Authentication);
}
export default function ErrorBoundary(props) {
    const dispatch = useDispatch();
    const deeplink = useSelector((state) => cleanDeeplink(getCurrentTemplateDeeplink(state.TemplateStack)));
    // https://preactjs.com/guide/v10/hooks/#useerrorboundary
    useErrorBoundary(async (err) => {
        dispatch({ type: REPORT_ERROR, payload: { error: err, deeplink } });
        recordError(err);
    });
    return React.createElement(Fragment, null, props.children);
}
