import ISkyfireMethod from './ISkyfireMethod';

export const INVOKE_HTTP_POST =
    'InteractionInterface.v1_0.InvokeHttpPostSkillMethod';

export interface ISkyfireInvokeHttpPostMethod extends ISkyfireMethod {
    readonly interface: typeof INVOKE_HTTP_POST;
    before: ISkyfireMethod[];
    after: ISkyfireMethod[];
    onError: ISkyfireMethod[];
    url: string;
    delay?: number;
    preset: string;
    target: string;
    extra: object;
}
