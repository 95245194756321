import { Middleware } from 'redux';
import { episodeDeeplink, podcastDeeplink } from 'src/utils/deeplinks';
import ISkyfireCompletedElement, {
    COMPLETE_ELEMENT,
} from 'src/types/templates/widgets/ISkyfireCompletedElement';

import ISkyfireBookmarkElement, {
    BOOKMARK_ELEMENT,
} from 'src/types/templates/widgets/ISkyfireBookmarkElement';
import { IconConstants } from 'src/utils/IconConstants';
import ISkyfireObserver from 'src/types/templates/observers/ISkyfireObserver';
import {
    DESCRIPTION,
    getBadgeElement,
    getButtonElement,
    IMAGE,
    isPlayable,
} from 'src/utils/RedhoodPlusMethods';
import {
    BOOKMARKS_LOWER_BOUND_PROGRESS_MS,
    BOOKMARKS_UPPER_BOUND_PERCENTAGE,
} from '../appsync/AppSyncConstants';
import {
    ISkyfireSquareVerticalItem,
    SQUARE_VERTICAL_ITEM,
} from '../types/templates/widgets/items/ISkyfireVerticalItem';
import {
    GET_BOOKMARK_ITEMS,
    GET_SAVE_ITEMS,
    UPDATE_PLAY_BUTTON,
    GET_FOLLOW_ITEMS,
    GET_FEATURED_BOOKMARK_ITEM,
    GET_BOOKMARK_SHOVELER_ITEMS,
    GET_FOLLOWED_SHOVELER_ITEMS,
} from '../actions/LocalHydration';
import {
    BookmarkDaoSessionStorage,
    BookMarkOperationDaoSessionStorage,
    BookmarkSyncData,
} from '../Bookmark';
import {
    INVOKE_HTTP_POST,
    ISkyfireInvokeHttpPostMethod,
} from '../types/ISkyfireInvokeHttpPostMethod';
import ISkyfireFeaturedElement from '../types/templates/detail/ISkyfireFeaturedElement';
import ISkyfireEpisodeFeaturedElement from '../types/templates/detail/ISkyfireEpisodeFeaturedElement';
import ISkyfireButtonElement from '../types/templates/widgets/ISkyfireButtonElement';
import {
    getPodcastEnvironment,
    getPodcastPlaybackEnvironment,
    getSaveButton,
} from '../utils';
import ISkyfireLinkElement from '../types/ISkyfireLinkElement';
import localization from '../resources/localization.json';
import ISkyfireEpisodeRowItem, {
    EPISODE_ROW_ITEM,
} from '../types/templates/widgets/items/ISkyfireEpisodeRowItem';
import { SaveDaoSessionStorage } from '../Save';
import {
    FollowDaoSessionStorage,
    HidePromptPreferenceElement,
} from '../Follow';
import ISkyfireTextElement from '../types/templates/widgets/ISkyfireTextElement';
import {
    UIMetrics,
    EntityType,
    EntityIdType,
    ActionType,
    ContentName,
    PageType,
} from '../UIMetrics';
import ISkyfireMethod from '../types/ISkyfireMethod';
import {
    PLAY_PODCAST_WEB_SKILL,
    SHOW_EPISODE_WEB_SKILL,
    SHOW_PODCAST_WEB_SKILL,
    PLAY_SAVED_EPISODE_WEB_SKILL,
} from '../utils/podcastTargets';
import { SINGLE_THREADED_QUEUE } from '../utils/skyfireContants';
import { invokeHttpPostSkillMethod } from '../utils/invokeHttpPostSkillMethod';
import {
    WRITE_HIDE_PROMPT_PREFERENCES_METHOD,
    FOLLOW_PROMPT_MAP,
} from '../FollowPrompt';

export const CURRENTLY_PLAYING_GROUP = 'CURRENTLY_PLAYING_GROUP';
export const CURRENTLY_PLAYING_PODCAST = 'CURRENTLY_PLAYING_PODCAST';
export const CURRENTLY_PLAYING_EPISODE = 'CURRENTLY_PLAYING_EPISODE';
export const PLAYBACK_STATE = 'PLAYBACK_STATE';
export const getLocalizedString = (language: string, key: string) =>
    localization[language][key];

export const LocalHydrationMiddleware: Middleware = (store) => (next) => (
    action
) => {
    switch (action.type) {
        case UPDATE_PLAY_BUTTON:
            action.payload.playButton = getPlayButton(
                action.payload.button,
                store,
                action.payload.featuredItem
            );
            next(action);
            break;
        case GET_BOOKMARK_ITEMS:
            action.payload.bookmarkItems = getBookmarkItems(
                action.payload.customerId,
                store
            );
            next(action);
            break;
        case GET_SAVE_ITEMS:
            action.payload.saveItems = getLibraryPlaylist(
                action.payload.customerId,
                action.payload.language,
                store
            );
            next(action);
            break;
        case GET_FOLLOW_ITEMS:
            action.payload.followItems = getLibraryShows(
                action.payload.customerId,
                store
            );
            next(action);
            break;
        case GET_FEATURED_BOOKMARK_ITEM:
            action.payload.featuredBookmarkItem = getFeaturedItem(
                action.payload.bookmarkId,
                action.payload.language,
                store
            );
            next(action);
            break;
        case GET_FOLLOWED_SHOVELER_ITEMS:
            action.payload.followedShovelerItems = getFollowedShovelerItems(
                action.payload.customerId,
                store
            );
            next(action);
            break;
        case GET_BOOKMARK_SHOVELER_ITEMS:
            action.payload.bookmarkShovelerItems = getBookmarkShovelerItems(
                action.payload.customerId,
                store
            );
            next(action);
            break;
        case WRITE_HIDE_PROMPT_PREFERENCES_METHOD: {
            const { podcastId, preference } = action.payload;
            const updatedTime = preference?.updateTime;
            let followPromptEntry = {};
            if (window.localStorage.getItem(FOLLOW_PROMPT_MAP) != null) {
                const serializedfollowPromptEntry = window.localStorage.getItem(
                    FOLLOW_PROMPT_MAP
                );
                followPromptEntry = JSON.parse(serializedfollowPromptEntry!);
            }
            followPromptEntry[podcastId] = new HidePromptPreferenceElement(
                updatedTime ?? new Date().getTime()
            );
            window.localStorage.setItem(
                FOLLOW_PROMPT_MAP,
                JSON.stringify(followPromptEntry)
            );
            next(action);
            break;
        }
        default:
            next(action);
    }
};

function getBookmarkShovelerItems(
    customerId: string,
    store
): ISkyfireSquareVerticalItem[] {
    const storage = new BookmarkDaoSessionStorage(store);
    const items = storage
        .getAll(
            customerId,
            BOOKMARKS_LOWER_BOUND_PROGRESS_MS,
            BOOKMARKS_UPPER_BOUND_PERCENTAGE,
            true
        )
        .map((bookmarkEntry, index) => {
            const invokePlay: ISkyfireInvokeHttpPostMethod = {
                interface: INVOKE_HTTP_POST,
                queue: {
                    interface: SINGLE_THREADED_QUEUE,
                    id: 'PLAYBACK',
                },
                forced: false,
                before: [],
                after: [],
                onError: [],
                url: getPodcastPlaybackEnvironment(),
                preset: `{"podcastId":"${bookmarkEntry.podcastId}","startAtEpisodeId":"${bookmarkEntry.episodeId}"}`,
                target: PLAY_PODCAST_WEB_SKILL,
                extra: {},
            };
            let onPlaySelected: ISkyfireMethod[] = [invokePlay];
            onPlaySelected = onPlaySelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.PLAY_PODCAST_EPISODE,
                    contentName: ContentName.PODCASTS_BOOKMARKS_SHOVELER,
                    pageType: PageType.PODCASTS_HOME,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            const invokeEpisode: ISkyfireInvokeHttpPostMethod = {
                interface: INVOKE_HTTP_POST,
                queue: {
                    interface: SINGLE_THREADED_QUEUE,
                    id: 'TEMPLATE',
                },
                forced: false,
                before: [],
                after: [],
                onError: [],
                url: getPodcastEnvironment(),
                preset: `{"id":"${bookmarkEntry.episodeId}"}`,
                target: SHOW_EPISODE_WEB_SKILL,
                extra: {},
            };
            let onEpisodeSelected: ISkyfireMethod[] = [invokeEpisode];
            onEpisodeSelected = onEpisodeSelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.SELECT_PODCAST_EPISODE,
                    contentName: ContentName.PODCASTS_BOOKMARKS_SHOVELER,
                    pageType: PageType.PODCASTS_HOME,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            const invokePodcast: ISkyfireInvokeHttpPostMethod = {
                interface: INVOKE_HTTP_POST,
                queue: {
                    interface: SINGLE_THREADED_QUEUE,
                    id: 'TEMPLATE',
                },
                forced: false,
                before: [],
                after: [],
                onError: [],
                url: getPodcastEnvironment(),
                preset: `{"id":"${bookmarkEntry.podcastId}"}`,
                target: SHOW_PODCAST_WEB_SKILL,
                extra: {},
            };
            let onPodcastSelected: ISkyfireMethod[] = [invokePodcast];
            onPodcastSelected = onPodcastSelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.GO_PODCAST_SHOW,
                    contentName: ContentName.PODCASTS_BOOKMARKS_SHOVELER,
                    pageType: PageType.PODCASTS_HOME,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            const playLink: ISkyfireLinkElement = {
                deeplink: podcastDeeplink(bookmarkEntry),
                onItemSelected: onPlaySelected,
            };
            const episodeLink: ISkyfireLinkElement = {
                deeplink: episodeDeeplink(bookmarkEntry),
                onItemSelected: onEpisodeSelected,
            };
            const podcastLink: ISkyfireLinkElement = {
                deeplink: podcastDeeplink(bookmarkEntry),
                onItemSelected: onPodcastSelected,
            };
            // @ts-ignore
            const item: ISkyfireSquareVerticalItem = {
                image: bookmarkEntry.podcastImage,
                imageAltText: bookmarkEntry.title,
                primaryLink: episodeLink,
                secondaryText: bookmarkEntry.podcastTitle,
                secondaryLink: podcastLink,
                actionIconName: 'play',
                actionIconLink: playLink,
                primaryText: {
                    text: bookmarkEntry.title,
                    disabled: false,
                },
                interface: SQUARE_VERTICAL_ITEM,
            };
            return item;
        });
    return items;
}

function getFollowedShovelerItems(
    customerId: string,
    store
): ISkyfireSquareVerticalItem[] {
    const storage = new FollowDaoSessionStorage(store);
    const items = storage
        .getAll(customerId, true, true)
        .map((followEntry, index) => {
            const invokePodcast: ISkyfireInvokeHttpPostMethod = {
                interface: INVOKE_HTTP_POST,
                queue: {
                    interface: SINGLE_THREADED_QUEUE,
                    id: 'TEMPLATE',
                },
                forced: false,
                before: [],
                after: [],
                onError: [],
                url: getPodcastEnvironment(),
                preset: `{"id":"${followEntry.id}","nextToken":null}`,
                target: SHOW_PODCAST_WEB_SKILL,
                extra: {},
            };
            let onPodcastSelected: ISkyfireMethod[] = [invokePodcast];
            onPodcastSelected = onPodcastSelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.SELECT_PODCAST_SHOW,
                    contentName: ContentName.PODCASTS_FOLLOWS_SHOVELER,
                    pageType: PageType.PODCASTS_HOME,
                    entityType: EntityType.PODCAST_SHOW,
                    entityIdType: EntityIdType.PODCAST_SHOW_ID,
                    entityId: getPodcastEntityId(followEntry),
                    entityPos: index,
                })
            );
            const podcastLink: ISkyfireLinkElement = {
                deeplink: podcastDeeplink(followEntry),
                onItemSelected: onPodcastSelected,
            };
            // @ts-ignore
            const item: ISkyfireSquareVerticalItem = {
                image: followEntry.image,
                imageAltText: followEntry.title,
                primaryLink: podcastLink,
                secondaryText: followEntry.publisher!,
                actionIconName: 'caretright',
                actionIconLink: podcastLink,
                primaryText: {
                    text: followEntry.title,
                    disabled: false,
                },
                interface: SQUARE_VERTICAL_ITEM,
            };
            return item;
        });
    return items;
}

function getFeaturedItem(
    bookmarkId: string,
    language: string,
    store
): ISkyfireEpisodeRowItem | undefined {
    const bookmarkSyncData = new BookmarkSyncData(
        new BookmarkDaoSessionStorage(store),
        new BookMarkOperationDaoSessionStorage(store),
        store
    );
    const bookmarks = bookmarkSyncData.readStatesByPodcastId(bookmarkId);
    if (bookmarks === undefined || bookmarks[0] === undefined) {
        return undefined;
    }
    const bookmarkEntry = bookmarks[0];
    const invokePlay: ISkyfireInvokeHttpPostMethod = {
        interface: INVOKE_HTTP_POST,
        queue: {
            interface: SINGLE_THREADED_QUEUE,
            id: 'PLAYBACK',
        },
        forced: false,
        before: [],
        after: [],
        onError: [],
        url: getPodcastPlaybackEnvironment(),
        preset: `{"podcastId":"${bookmarkEntry.podcastId}","startAtEpisodeId":"${bookmarkEntry.episodeId}"}`,
        target: PLAY_PODCAST_WEB_SKILL,
        extra: {},
    };
    let onPlaySelected: ISkyfireMethod[] = [invokePlay];
    onPlaySelected = onPlaySelected.concat(
        UIMetrics.onClicked({
            actionType: ActionType.PLAY_PODCAST_EPISODE,
            contentName: ContentName.PODCASTS_PINNED_EPISODE,
            pageType: PageType.PODCASTS_SHOW_DETAIL,
            entityType: EntityType.PODCAST_EPISODE,
            entityIdType: EntityIdType.PODCAST_EPISODE_ID,
            entityId: getEpisodeEntityId(bookmarkEntry),
        })
    );
    const setStorageMethod = {
        interface: 'StorageInterface.v1_0.SetValueInGroupMethod',
        forced: false,
        group: PLAYBACK_STATE,
        key: bookmarkEntry.episodeId,
        queue: {
            interface: SINGLE_THREADED_QUEUE,
            id: 'PLAYBACK',
        },
        value: 'PLAYING',
    };
    onPlaySelected.push(setStorageMethod);
    let onEpisodeSelected: ISkyfireMethod[] = [
        // @ts-ignore
        invokeHttpPostSkillMethod(
            `/podcasts/${bookmarkEntry.podcastId}/episodes/${bookmarkEntry.episodeId}`
        ),
    ];
    onEpisodeSelected = onEpisodeSelected.concat(
        UIMetrics.onClicked({
            actionType: ActionType.SELECT_PODCAST_EPISODE,
            contentName: ContentName.PODCASTS_PINNED_EPISODE,
            pageType: PageType.PODCASTS_SHOW_DETAIL,
            entityType: EntityType.PODCAST_EPISODE,
            entityIdType: EntityIdType.PODCAST_EPISODE_ID,
            entityId: getEpisodeEntityId(bookmarkEntry),
        })
    );
    const episodeLink: ISkyfireLinkElement = {
        deeplink: episodeDeeplink(bookmarkEntry),
        onItemSelected: onEpisodeSelected,
    };
    const observer: ISkyfireObserver = {
        storageGroup: PLAYBACK_STATE,
        storageKey: bookmarkEntry.episodeId,
        states: {
            STOPPED: {
                icon: IconConstants.PLAY,
                onItemSelected: onPlaySelected,
                disabled: false,
                text: '',
                iconOnly: true,
                interface:
                    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.ButtonElement',
            },
            LOADING: {
                icon: IconConstants.LOADING,
                onItemSelected: [],
                disabled: false,
                text: '',
                iconOnly: true,
                interface:
                    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.ButtonElement',
            },
            PLAYING: {
                icon: IconConstants.PAUSED,
                onItemSelected: [],
                disabled: false,
                text: '',
                iconOnly: true,
                interface:
                    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.ButtonElement',
            },
            PAUSED: {
                icon: IconConstants.RESUMED,
                onItemSelected: [],
                disabled: false,
                text: '',
                iconOnly: true,
                interface:
                    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.ButtonElement',
            },
        },
        defaultState: 'STOPPED',
        defaultValue: null,
    };
    const playButton: ISkyfireButtonElement = {
        icon: IconConstants.PLAY,
        onItemSelected: onPlaySelected,
        disabled: false,
        text: '',
        iconOnly: true,
        observer,
    };
    const onUpsellClick = UIMetrics.onClicked({
        actionType: ActionType.SELECT_PODCAST_UPSELL,
        contentName: ContentName.PODCASTS_LIBRARY_PLAYLIST,
        pageType: PageType.PODCASTS_LIBRARY_PLAYLIST,
        entityType: EntityType.PODCAST_EPISODE,
        entityIdType: EntityIdType.PODCAST_EPISODE_ID,
        entityId: getEpisodeEntityId(bookmarkEntry),
        entityPos: 0,
    });
    let saveButton: ISkyfireButtonElement | undefined;
    let descriptionButton: ISkyfireButtonElement | undefined;
    if (isPlayable(bookmarkEntry.playbackMode)) {
        saveButton = getSaveButton(
            'UNSAVE',
            bookmarkEntry.episodeId,
            bookmarkEntry.podcastId,
            bookmarkEntry.podcastTitle,
            bookmarkEntry.title,
            bookmarkEntry.description,
            bookmarkEntry.image,
            bookmarkEntry.podcastImage,
            bookmarkEntry.authors,
            bookmarkEntry.seasonNumber,
            bookmarkEntry.totalDurationMilliseconds,
            language,
            bookmarkEntry.publishTime,
            ContentName.PODCASTS_PINNED_EPISODE,
            PageType.PODCASTS_LIBRARY_PLAYLIST,
            bookmarkEntry.playbackMode,
            bookmarkEntry.availableUpsells,
            bookmarkEntry.contentTraits,
            bookmarkEntry.availabilityDate,
            bookmarkEntry.podcastShowVariantId,
            bookmarkEntry.podcastEpisodeVariantId
        );
    } else {
        descriptionButton = getButtonElement(
            bookmarkEntry.availableUpsells,
            bookmarkEntry.contentTraits,
            onUpsellClick,
            language
        );
    }
    const completedElement: ISkyfireCompletedElement = {
        interface: COMPLETE_ELEMENT,
        id: bookmarkEntry.episodeId,
    };
    const bookmarkElement: ISkyfireBookmarkElement = {
        interface: BOOKMARK_ELEMENT,
        id: bookmarkEntry.episodeId,
        totalDurationMilliseconds: bookmarkEntry.totalDurationMilliseconds,
    };

    const imageBadge = getBadgeElement(
        bookmarkEntry.availableUpsells,
        bookmarkEntry.contentTraits,
        IMAGE,
        onUpsellClick,
        language
    );

    const descriptionBadge = getBadgeElement(
        bookmarkEntry.availableUpsells,
        bookmarkEntry.contentTraits,
        DESCRIPTION,
        onUpsellClick,
        language
    );
    // @ts-ignore
    const episodeRowItemData: ISkyfireEpisodeRowItem = {
        interface: EPISODE_ROW_ITEM,
        image: bookmarkEntry.image,
        label: bookmarkEntry.publishTime,
        primaryText: bookmarkEntry.title,
        primaryLink: episodeLink,
        iconButton: playButton,
        button: saveButton,
        bookmarkElement,
        completedElement,
        imageBadge,
        descriptionBadge,
        descriptionButton,
        playbackMode: bookmarkEntry.playbackMode,
    };
    if (isPlayable(bookmarkEntry.playbackMode)) {
        episodeRowItemData.showPlayButton = true;
    }
    return episodeRowItemData;
}

function getLibraryShows(
    customerId: string,
    store
): ISkyfireSquareVerticalItem[] {
    const storage = new FollowDaoSessionStorage(store);
    const items = storage.getAll(customerId, true).map((followEntry, index) => {
        const invokePodcast: ISkyfireInvokeHttpPostMethod = {
            interface: INVOKE_HTTP_POST,
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'TEMPLATE',
            },
            forced: false,
            before: [],
            after: [],
            onError: [],
            url: getPodcastEnvironment(),
            preset: `{"id":"${followEntry.id}","nextToken":null}`,
            target: SHOW_PODCAST_WEB_SKILL,
            extra: {},
        };
        let onPodcastSelected: ISkyfireMethod[] = [invokePodcast];
        onPodcastSelected = onPodcastSelected.concat(
            UIMetrics.onClicked({
                actionType: ActionType.SELECT_PODCAST_SHOW,
                contentName: ContentName.PODCASTS_LIBRARY_SHOWS,
                pageType: PageType.PODCASTS_LIBRARY_SHOWS,
                entityType: EntityType.PODCAST_SHOW,
                entityIdType: EntityIdType.PODCAST_SHOW_ID,
                entityId: getPodcastEntityId(followEntry),
                entityPos: index,
            })
        );
        const podcastLink: ISkyfireLinkElement = {
            deeplink: podcastDeeplink(followEntry),
            onItemSelected: onPodcastSelected,
        };
        const podcastText: ISkyfireTextElement = {
            text: followEntry.title,
            disabled: false,
        };
        // @ts-ignore
        const item: ISkyfireSquareVerticalItem = {
            image: followEntry.image,
            imageAltText: followEntry.title,
            primaryLink: podcastLink,
            actionIconLink: podcastLink,
            secondaryText: followEntry.publisher!,
            actionIconName: 'caretright',
            isDisabled: false,
            primaryText: podcastText,
            interface: SQUARE_VERTICAL_ITEM,
        };
        return item;
    });
    return items;
}

function getLibraryPlaylist(
    customerId: string,
    language: string,
    store
): ISkyfireEpisodeRowItem[] {
    const storage = new SaveDaoSessionStorage(store);
    const items = storage.getAll(customerId, true).map((saveEntry, index) => {
        const invokePlay: ISkyfireInvokeHttpPostMethod = {
            interface: INVOKE_HTTP_POST,
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'PLAYBACK',
            },
            forced: false,
            before: [],
            after: [],
            onError: [],
            url: getPodcastPlaybackEnvironment(),
            preset: `{"episodeId":"${saveEntry.episodeId}"}`,
            target: PLAY_SAVED_EPISODE_WEB_SKILL,
            extra: {},
        };
        const invokeEpisode: ISkyfireInvokeHttpPostMethod = {
            interface: INVOKE_HTTP_POST,
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'TEMPLATE',
            },
            forced: false,
            before: [],
            after: [],
            onError: [],
            url: getPodcastEnvironment(),
            preset: `{"id":"${saveEntry.episodeId}"}`,
            target: SHOW_EPISODE_WEB_SKILL,
            extra: {},
        };
        let onEpisodeSelected: ISkyfireMethod[] = [invokeEpisode];
        onEpisodeSelected = onEpisodeSelected.concat(
            UIMetrics.onClicked({
                actionType: ActionType.SELECT_PODCAST_EPISODE,
                contentName: ContentName.PODCASTS_LIBRARY_PLAYLIST,
                pageType: PageType.PODCASTS_LIBRARY_PLAYLIST,
                entityType: EntityType.PODCAST_EPISODE,
                entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                entityId: getEpisodeEntityId(saveEntry),
                entityPos: index,
            })
        );
        const episodeLink: ISkyfireLinkElement = {
            deeplink: episodeDeeplink(saveEntry),
            onItemSelected: onEpisodeSelected,
        };
        let onPlaySelected: ISkyfireMethod[] = [invokePlay];
        onPlaySelected = onPlaySelected.concat(
            UIMetrics.onClicked({
                actionType: ActionType.PLAY_PODCAST_EPISODE,
                contentName: ContentName.PODCASTS_LIBRARY_PLAYLIST,
                pageType: PageType.PODCASTS_LIBRARY_PLAYLIST,
                entityType: EntityType.PODCAST_EPISODE,
                entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                entityId: getEpisodeEntityId(saveEntry),
                entityPos: index,
            })
        );
        const playButton: ISkyfireButtonElement = {
            icon: 'play',
            onItemSelected: onPlaySelected,
            disabled: false,
            text: '',
            iconOnly: true,
        };
        const saveButton: ISkyfireButtonElement | undefined = getSaveButton(
            'SAVE',
            saveEntry.episodeId,
            saveEntry.podcastId,
            saveEntry.podcastTitle,
            saveEntry.title,
            saveEntry.description,
            saveEntry.image,
            saveEntry.podcastImage,
            saveEntry.authors,
            saveEntry.seasonNumber,
            saveEntry.totalDurationMilliseconds,
            saveEntry.publishTime,
            language,
            ContentName.PODCASTS_LIBRARY_PLAYLIST,
            PageType.PODCASTS_LIBRARY_PLAYLIST,
            saveEntry.playbackMode,
            saveEntry.availableUpsells,
            saveEntry.contentTraits,
            saveEntry.availabilityDate,
            saveEntry.podcastShowVariantId,
            saveEntry.podcastEpisodeVariantId
        );
        // @ts-ignore
        const item: ISkyfireEpisodeRowItem = {
            interface: EPISODE_ROW_ITEM,
            iconButton: playButton,
            button: saveButton,
            image: saveEntry.podcastImage,
            primaryLink: episodeLink,
            primaryText: saveEntry.title,
            description: saveEntry.description,
            label: saveEntry.publishTime,
        };
        return item;
    });
    return items;
}

function getBookmarkItems(
    customerId: string,
    store
): ISkyfireSquareVerticalItem[] {
    const storage = new BookmarkDaoSessionStorage(store);
    const items = storage
        .getAll(
            customerId,
            BOOKMARKS_LOWER_BOUND_PROGRESS_MS,
            BOOKMARKS_UPPER_BOUND_PERCENTAGE,
            true
        )
        .map((bookmarkEntry, index) => {
            const invokePlay: ISkyfireInvokeHttpPostMethod = {
                interface: INVOKE_HTTP_POST,
                queue: {
                    interface: SINGLE_THREADED_QUEUE,
                    id: 'PLAYBACK',
                },
                forced: false,
                before: [],
                after: [],
                onError: [],
                url: getPodcastPlaybackEnvironment(),
                preset: `{"podcastId":"${bookmarkEntry.podcastId}","startAtEpisodeId":"${bookmarkEntry.episodeId}"}`,
                target: PLAY_PODCAST_WEB_SKILL,
                extra: {},
            };
            let onPlaySelected: ISkyfireMethod[] = [invokePlay];
            onPlaySelected = onPlaySelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.PLAY_PODCAST_EPISODE,
                    contentName: ContentName.PODCASTS_RECENTLY_PLAYED,
                    pageType: PageType.PODCASTS_RECENTLY_PLAYED,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            const invokeEpisode: ISkyfireInvokeHttpPostMethod = {
                interface: INVOKE_HTTP_POST,
                queue: {
                    interface: SINGLE_THREADED_QUEUE,
                    id: 'TEMPLATE',
                },
                forced: false,
                before: [],
                after: [],
                onError: [],
                url: getPodcastEnvironment(),
                preset: `{"id":"${bookmarkEntry.episodeId}"}`,
                target: SHOW_EPISODE_WEB_SKILL,
                extra: {},
            };
            let onEpisodeSelected: ISkyfireMethod[] = [invokeEpisode];
            onEpisodeSelected = onEpisodeSelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.SELECT_PODCAST_EPISODE,
                    contentName: ContentName.PODCASTS_RECENTLY_PLAYED,
                    pageType: PageType.PODCASTS_RECENTLY_PLAYED,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            const invokePodcast: ISkyfireInvokeHttpPostMethod = {
                interface: INVOKE_HTTP_POST,
                queue: {
                    interface: SINGLE_THREADED_QUEUE,
                    id: 'TEMPLATE',
                },
                forced: false,
                before: [],
                after: [],
                onError: [],
                url: getPodcastEnvironment(),
                preset: `{"id":"${bookmarkEntry.podcastId}"}`,
                target: SHOW_PODCAST_WEB_SKILL,
                extra: {},
            };
            let onPodcastSelected: ISkyfireMethod[] = [invokePodcast];
            onPodcastSelected = onPodcastSelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.GO_PODCAST_SHOW,
                    contentName: ContentName.PODCASTS_RECENTLY_PLAYED,
                    pageType: PageType.PODCASTS_RECENTLY_PLAYED,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            const playLink: ISkyfireLinkElement = {
                deeplink: podcastDeeplink(bookmarkEntry),
                onItemSelected: onPlaySelected,
            };
            const episodeLink: ISkyfireLinkElement = {
                deeplink: episodeDeeplink(bookmarkEntry),
                onItemSelected: onEpisodeSelected,
            };
            const podcastLink: ISkyfireLinkElement = {
                deeplink: podcastDeeplink(bookmarkEntry),
                onItemSelected: onPodcastSelected,
            };
            // @ts-ignore
            const item: ISkyfireSquareVerticalItem = {
                image: bookmarkEntry.podcastImage,
                imageAltText: bookmarkEntry.title,
                primaryLink: episodeLink,
                secondaryText: bookmarkEntry.podcastTitle,
                secondaryLink: podcastLink,
                actionIconName: 'play',
                actionIconLink: playLink,
                primaryText: {
                    text: bookmarkEntry.title,
                    disabled: false,
                },
                interface: SQUARE_VERTICAL_ITEM,
            };
            return item;
        });
    return items;
}

function getPlayButton(
    button: ISkyfireButtonElement,
    store,
    featuredItem?: ISkyfireFeaturedElement | ISkyfireEpisodeFeaturedElement
): ISkyfireButtonElement {
    let onItemSelected;
    let bookmarks;
    const bookmarkSyncData = new BookmarkSyncData(
        new BookmarkDaoSessionStorage(store),
        new BookMarkOperationDaoSessionStorage(store),
        store
    );
    if (featuredItem?.podcastBookmark) {
        bookmarks = bookmarkSyncData.readStatesByPodcastId(
            featuredItem.podcastBookmark.id
        );
    }
    if (featuredItem?.podcastBookmark && bookmarks[0]) {
        const bookmarkEntry = bookmarks[0];
        onItemSelected = [
            {
                interface: INVOKE_HTTP_POST,
                queue: {
                    interface: SINGLE_THREADED_QUEUE,
                    id: 'PLAYBACK',
                },
                forced: false,
                before: [],
                after: [],
                onError: [],
                url: getPodcastPlaybackEnvironment(),
                preset: `{"podcastId":"${bookmarkEntry.podcastId}","startAtEpisodeId":"${bookmarkEntry.episodeId}"}`,
                target: PLAY_PODCAST_WEB_SKILL,
                extra: {},
            },
            {
                forced: false,
                group: CURRENTLY_PLAYING_GROUP,
                interface: 'StorageInterface.v1_0.SetValueInGroupMethod',
                key: CURRENTLY_PLAYING_PODCAST,
                queue: { interface: SINGLE_THREADED_QUEUE, id: 'PLAYBACK' },
                value: bookmarkEntry.podcastId,
            },
            {
                forced: false,
                group: CURRENTLY_PLAYING_GROUP,
                interface: 'StorageInterface.v1_0.SetValueInGroupMethod',
                key: CURRENTLY_PLAYING_EPISODE,
                queue: { interface: SINGLE_THREADED_QUEUE, id: 'PLAYBACK' },
                value: bookmarkEntry.episodeId,
            },
        ];
    } else if (
        featuredItem?.alternate &&
        featuredItem.alternateLabel &&
        featuredItem.alternate?.iconButton
    ) {
        onItemSelected = featuredItem.alternate?.iconButton?.onItemSelected;
    } else {
        return button;
    }

    return {
        icon: 'play',
        text: 'Play',
        iconOnly: true,
        disabled: false,
        onItemSelected,
    };
}

function getEpisodeEntityId(element) {
    const {
        isRedhoodPlusMetricsEnabled,
        isRedhoodSonicRushEnabled,
    } = window.amznMusic.appConfig;
    return element.podcastEpisodeVariantId &&
        isRedhoodPlusMetricsEnabled &&
        isRedhoodSonicRushEnabled
        ? element.podcastEpisodeVariantId
        : element.episodeId;
}

function getPodcastEntityId(element) {
    const {
        isRedhoodPlusMetricsEnabled,
        isRedhoodSonicRushEnabled,
    } = window.amznMusic.appConfig;
    return element.podcastShowVariantId &&
        isRedhoodPlusMetricsEnabled &&
        isRedhoodSonicRushEnabled
        ? element.podcastShowVariantId
        : element.id;
}
