exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3qMVm7g64vYv1LkCsGKQtJ{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._3qMVm7g64vYv1LkCsGKQtJ{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._3qMVm7g64vYv1LkCsGKQtJ{padding-inline-start:52px;padding-inline-end:52px}}._3V3p6G-rHW4DB2U00eInrr{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._3V3p6G-rHW4DB2U00eInrr{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._3V3p6G-rHW4DB2U00eInrr{margin-inline-start:52px;margin-inline-end:52px}}._2zF7KH-4AbMy-jsvn9dEKM{width:300px}.cXYkGyPbNEzxjlkh6kEzM{font-size:14px}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_3qMVm7g64vYv1LkCsGKQtJ",
	"gridSides": "_3qMVm7g64vYv1LkCsGKQtJ",
	"grid-sides-margins": "_3V3p6G-rHW4DB2U00eInrr",
	"gridSidesMargins": "_3V3p6G-rHW4DB2U00eInrr",
	"context-menu-header": "_2zF7KH-4AbMy-jsvn9dEKM",
	"contextMenuHeader": "_2zF7KH-4AbMy-jsvn9dEKM",
	"context-menu-header-list": "cXYkGyPbNEzxjlkh6kEzM",
	"contextMenuHeaderList": "cXYkGyPbNEzxjlkh6kEzM"
};