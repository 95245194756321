import DOMPurify from 'dompurify';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WINDOW_SIZE_ENUM } from '../types/IWindowSize';
import { UPDATE_TEXT_INPUT, CHANGE_PROFILE_STATUS, SET_SELECTION_ITEM_ELEMENT, } from '../actions/Dialog';
import { REPORT_ERROR } from '../actions/Reporting';
import Dropdown from '../components/Dropdown';
import MultiselectList from '../components/MultiselectList';
import { LoadingSpinner } from '../components/LoadingSpinner';
import HorizontalItem from '../components/widgets/items/HorizontalItem';
import VerticalItem from '../components/widgets/items/VerticalItem';
import ImageRow from '../components/widgets/items/ImageRow';
import TextWithLink from '../components/widgets/items/TextWithLink';
import BackgroundImage from '../components/BackgroundImage';
import { IMAGE_DIALOG_BUTTON_ELEMENT, PRIMARY_DIALOG_BUTTON_ELEMENT, DIALOG_GLASS_BUTTON_ELEMENT, } from '../types/templates/dialog/ISkyfireDialogButtonElement';
import { dispatchSkyfireMethods, globals } from '../utils';
import { useObserver } from '../utils/ObserverHooks';
import * as Styles from './Dialog.scss';
import DialogColumn from './DialogColumn';
import DialogQRCard from './DialogQRCard';
import { getDeeplink } from '../utils/getDeeplink';
import { UNLOCK_AUDIO, UPDATE_DISPLAY_LANGUAGE, UNLOCK_AUDIO_DIALOG_SHOWN, SET_AUDIO_QUALITY, UNLOCK_SAMPLE_DIALOG_SHOWN, } from '../actions';
import { getInstanceSync as getAudioInstanceSync } from '../player/AudioPlayer';
import { getInstanceSync as getSampleInstanceSync } from '../player/SamplePlayer';
import InformationCard from '../components/InformationCard';
import { spacersSizeMap } from '../utils/webUIVariables';
import DialogRow from '../components/DialogRow';
const { window } = globals;
export default function Dialog(props) {
    var _a, _b;
    useStyles(Styles);
    const windowWidth = useSelector((state) => state.BrowserState.windowWidth);
    const unlockAudioDialogShown = useSelector((state) => state.Dialog.unlockAudioDialogShown);
    const showSampleUnlockDialog = useSelector((state) => state.Dialog.showSampleUnlockDialog);
    const dispatch = useDispatch();
    const { closeButton, onViewed, header, headerObserver, headerImage, headerImageAltText, dialogVariant, body, buttons, rows, columns, qrCard, background, image, comfortText, subHeader, footer, selectorItems, multiSelectorItems, selectorDefault, selectorHeader, textInputHeader, textInputDefault, textInputPlaceholder, textInputMaxLengthErrorMessage, textInputMaxLength, rowItemElementList, horizontalItem, verticalItem, primaryActionButton, secondaryActionButtons, subBody, role, informationCard, } = props.template;
    useEffect(() => {
        window.addEventListener('keydown', handleKeydown);
        window.addEventListener('popstate', handleBack);
        dispatchSkyfireMethods(dispatch, props.template, onViewed);
        return () => {
            window.removeEventListener('keydown', handleKeydown);
            window.removeEventListener('popstate', handleBack);
        };
    }, []);
    const inputRef = useRef(null);
    const dialogContentRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        else {
            // setTimeout introduced to execute function after full component render
            setTimeout(() => focusFirstDescendent(), 100);
        }
    }, []);
    const headerObserverText = useObserver({ observer: headerObserver });
    const headerText = (_a = header !== null && header !== void 0 ? header : headerObserverText.text) !== null && _a !== void 0 ? _a : headerObserverText;
    const { highLightString } = headerObserverText;
    const firstValue = selectorItems ? selectorItems[0].value : '';
    const isProfileStatusSelector = ['PUBLIC', 'PRIVATE', 'HIDDEN'].includes(firstValue);
    const isAudioQualitySelector = ['HD', 'STANDARD', 'DATA_SAVER'].includes(firstValue);
    const { displayLanguageId } = useSelector((state) => state.Authentication);
    const { audioQuality } = useSelector((state) => state.Setting);
    const [optionId, updateOptionId] = useState(isAudioQualitySelector ? audioQuality : displayLanguageId);
    const { profileStatus } = useSelector((state) => state.Dialog);
    const enableMShopBottomOverride = useSelector((state) => state.RWP.enableMShopBottomOverride);
    const mShopBottomOverride = useSelector((state) => state.RWP.mShopBottomOverride);
    const [status, updateStatus] = useState(profileStatus);
    const audioUnlockRef = useRef(false);
    const textInputDefaultElement = useObserver(textInputDefault);
    const [textInput, setTextInput] = useState((textInputDefaultElement === null || textInputDefaultElement === void 0 ? void 0 : textInputDefaultElement.text) || '');
    const onTextInputChange = (e) => setTextInput(e.target.value);
    const isTextInputMaxLengthReached = textInput.length === textInputMaxLength;
    const noop = () => true;
    const isModerationVariant = dialogVariant === 'MODERATION';
    let variantClass = '';
    if (dialogVariant === 'LEFT_ALIGNED_INFORMATION_CARD') {
        variantClass = Styles.leftAlignedCardContent;
    }
    const rowsElements = rows && rows.map((row) => React.createElement(DialogRow, { row: row, template: props.template }));
    // textInput does not autopopulate on pageload if dialog is called from another dialog
    // since it gets re-rendered with new props
    // (e.g add to playlist selector dialog -> new playlist dialog)
    useEffect(() => {
        if (setTextInput) {
            setTextInput((textInputDefaultElement === null || textInputDefaultElement === void 0 ? void 0 : textInputDefaultElement.text) || '');
        }
    }, [textInputDefaultElement]);
    if (!headerText && !body && !rowItemElementList) {
        return React.createElement(LoadingSpinner, null);
    }
    const modalStyle = enableMShopBottomOverride
        ? { bottom: mShopBottomOverride, height: 'auto' }
        : {};
    return (React.createElement("div", { className: Styles.modal, role: "dialog", "aria-modal": "true", "aria-labelledby": "dialogHeader", "aria-describedby": "dialogBodyText", id: "dialog", style: modalStyle },
        background && React.createElement(BackgroundImage, { gradient: false, src: background }),
        React.createElement("div", { ref: dialogContentRef, className: [Styles.modalContent, variantClass].join(' ') },
            renderCloseButton(),
            renderImage(),
            renderHeaderImage(),
            renderHeader(),
            renderSubHeader(),
            React.createElement("div", { className: Styles.bodyTextContainer },
                renderBody(),
                horizontalItem && (React.createElement(HorizontalItem, { data: horizontalItem, handleSelected: noop })),
                verticalItem && (React.createElement(VerticalItem, { data: verticalItem, handleSelected: noop, role: role }))),
            multiSelectorItems && (React.createElement(MultiselectList, { template: props.template, items: multiSelectorItems })),
            isModerationVariant && rowsElements,
            textInputHeader && (React.createElement("div", { className: Styles.textInputContainer },
                React.createElement("p", { className: isModerationVariant ? 'music-primary-text' : 'music-secondary-text' }, textInputHeader),
                isTextInputMaxLengthReached && (React.createElement("p", { className: [
                        Styles.textInputMaxLengthErrorMessage,
                        'music-secondary-text',
                    ].join(' ') }, textInputMaxLengthErrorMessage)),
                React.createElement("input", { ref: inputRef, className: [
                        Styles.textInput,
                        isTextInputMaxLengthReached
                            ? Styles.textInputMaxLengthErrorOutline
                            : '',
                    ].join(' '), placeholder: textInputPlaceholder, maxLength: textInputMaxLength, value: textInput, disabled: textInputDefaultElement === null || textInputDefaultElement === void 0 ? void 0 : textInputDefaultElement.disabled, onChange: onTextInputChange }))),
            React.createElement("div", { className: Styles.descriptionTextContainer }, subBody && (React.createElement("p", { className: "music-secondary-text", id: "dialogBodyText" },
                subBody.text,
                ' ', (_b = subBody.textWithLinks) === null || _b === void 0 ? void 0 :
                _b.map((textWithLinkElement) => (React.createElement(TextWithLink, { data: textWithLinkElement, template: props.template })))))),
            informationCard && (React.createElement(InformationCard, { data: informationCard, template: props.template })),
            selectorHeader && React.createElement("p", { className: "selector-header" }, selectorHeader),
            renderSelectorItems(),
            renderRowItemElementList(),
            renderColumns(),
            !isModerationVariant && rowsElements,
            renderButtons(buttons),
            renderQRCard(),
            renderActionButtons(primaryActionButton, secondaryActionButtons),
            React.createElement("p", { className: "music-tertiary-text" }, comfortText),
            renderFooter())));
    function renderBody() {
        var _a, _b, _c;
        if (!body)
            return null;
        if (body.paragraphs && body.paragraphs.length > 0) {
            return (_a = body.paragraphs) === null || _a === void 0 ? void 0 : _a.map((paragraph, idx) => (React.createElement("p", { className: "music-primary-text", style: {
                    marginTop: spacersSizeMap.base,
                    textAlign: isModerationVariant ? 'left' : undefined,
                }, id: `dialogBodyText${idx}`, key: paragraph },
                paragraph,
                ' ')));
        }
        if (body.onItemSelected && body.onItemSelected.length > 0) {
            return (React.createElement("music-link", { className: ['music-secondary-text', Styles.dialogBodyText].join(' '), id: "dialogBodyText", onKeyDown: (e) => (e.key === 'Enter' ? handleSelected(body.onItemSelected) : undefined), onClick: () => handleSelected(body.onItemSelected) },
                body.icon && React.createElement("music-icon", { name: body.icon, size: "tiny" }),
                ' ',
                React.createElement("span", null, body.text)));
        }
        if (body.styledText) {
            return (React.createElement("p", { className: "music-primary-text", id: "dialogBodyText", dangerouslySetInnerHTML: { __html: sanitizeHTML(body.styledText) } }, (_b = body.textWithLinks) === null || _b === void 0 ? void 0 : _b.map((textWithLinkElement, index) => (React.createElement(TextWithLink, { key: index + textWithLinkElement.text, data: textWithLinkElement, template: props.template })))));
        }
        return (React.createElement("p", { className: Styles.loneItem, id: "dialogBodyText" },
            body.text,
            ' ', (_c = body.textWithLinks) === null || _c === void 0 ? void 0 :
            _c.map((textWithLinkElement, index) => (React.createElement(TextWithLink, { key: index + textWithLinkElement.text, data: textWithLinkElement, template: props.template })))));
    }
    function renderSelectorItems() {
        var _a;
        if (!selectorItems) {
            return null;
        }
        const currentOption = (_a = selectorItems.find((item) => item.value === optionId)) === null || _a === void 0 ? void 0 : _a.mainText;
        if (isProfileStatusSelector) {
            return (React.createElement(Dropdown, { options: selectorItems, updateOption: updateStatus, defaultValue: selectorDefault }));
        }
        return (React.createElement(Dropdown, { options: selectorItems, currentOption: currentOption, updateOption: updateOptionId, defaultValue: selectorDefault }));
    }
    function renderRowItemElementList() {
        if (!rowItemElementList) {
            return null;
        }
        return (React.createElement("div", { className: Styles.rowItemElementListsContainer }, rowItemElementList.map((rowItemElement) => (React.createElement("div", { className: Styles.rowItemElementListContainer },
            React.createElement("div", { className: Styles.rowItemElementListHeaderButtonContainer },
                React.createElement("h3", null, rowItemElement.header),
                rowItemElement.button &&
                    renderButton(rowItemElement.button, 'small', 0)),
            renderVisualRowItemElementList(rowItemElement.visualRowItemElementList))))));
    }
    function renderVisualRowItemElementList(visualRowItemElementList) {
        return visualRowItemElementList.map((visualRowItemElement, index) => (React.createElement(ImageRow, { template: props.template, disableSelection: true, disableSecondaryTextInline: true, key: index, data: visualRowItemElement, handleSelected: handleSelected })));
    }
    function onSubmit(isDisabled, onItemSelected, e) {
        var _a, _b;
        if (isDisabled) {
            e === null || e === void 0 ? void 0 : e.preventDefault();
            (_b = (_a = e === null || e === void 0 ? void 0 : e.detail) === null || _a === void 0 ? void 0 : _a.preventDefault) === null || _b === void 0 ? void 0 : _b.call(_a);
            return;
        }
        dispatch({ type: UPDATE_TEXT_INPUT, payload: { textInput } });
        handleSelected(onItemSelected, e);
    }
    function handleSelected(onItemSelected, e) {
        var _a, _b, _c, _d;
        e === null || e === void 0 ? void 0 : e.preventDefault();
        (_b = (_a = e === null || e === void 0 ? void 0 : e.detail) === null || _a === void 0 ? void 0 : _a.preventDefault) === null || _b === void 0 ? void 0 : _b.call(_a);
        // Only update state when selected the ok button
        // @ts-ignore
        if (selectorItems && String((_d = (_c = e === null || e === void 0 ? void 0 : e.target) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : '').endsWith('1')) {
            if (isProfileStatusSelector) {
                dispatch({
                    type: CHANGE_PROFILE_STATUS,
                    payload: {
                        profileStatus: status || selectorDefault,
                    },
                });
            }
            else if (isAudioQualitySelector) {
                dispatch({
                    type: SET_AUDIO_QUALITY,
                    payload: {
                        audioQuality: optionId,
                    },
                });
            }
            else if (isModerationVariant) {
                dispatch({
                    type: SET_SELECTION_ITEM_ELEMENT,
                    payload: {
                        selectorItemElement: optionId,
                    },
                });
            }
            else {
                dispatch({
                    type: UPDATE_DISPLAY_LANGUAGE,
                    payload: {
                        displayLanguageId: optionId,
                    },
                });
            }
        }
        dispatch({ type: UPDATE_TEXT_INPUT, payload: { textInput } });
        const isUnlockAudio = onItemSelected === null || onItemSelected === void 0 ? void 0 : onItemSelected.some((method) => method.interface === UNLOCK_AUDIO);
        if (isUnlockAudio && !audioUnlockRef.current) {
            // UNLOCK_AUDIO must be handled here because the functionality is sensitive to
            // the caller context. `attemptAudioUnlock` will only work when called from a
            // click handler; we cannot perform this call from a Redux reducer/middleware.
            // https://quip-amazon.com/LeQ8AUBXRRNe/iOS-Autoplay-Issue-Workaround
            if (showSampleUnlockDialog) {
                const samplePlayer = getSampleInstanceSync();
                samplePlayer === null || samplePlayer === void 0 ? void 0 : samplePlayer.attemptAudioUnlock();
            }
            else {
                const audioPlayer = getAudioInstanceSync();
                audioPlayer === null || audioPlayer === void 0 ? void 0 : audioPlayer.attemptAudioUnlock();
            }
            // REPORT_ERROR is not called for sampling to prevent it from polluting audio unlock
            // metrics
            if (unlockAudioDialogShown) {
                // If UNLOCK_AUDIO dialog is shown more than once in a session,
                // this indicates that the unlock attempt has failed
                dispatch({
                    type: REPORT_ERROR,
                    payload: { error: new Error('UNLOCK_AUDIO_FAILED') },
                });
            }
            else if (showSampleUnlockDialog) {
                dispatch({ type: UNLOCK_SAMPLE_DIALOG_SHOWN });
            }
            else {
                dispatch({ type: UNLOCK_AUDIO_DIALOG_SHOWN });
            }
            // guard against duplicate callbacks
            audioUnlockRef.current = true;
        }
        dispatchSkyfireMethods(dispatch, props.template, onItemSelected);
    }
    function handleKeydown(e) {
        var _a;
        switch (e.key) {
            case 'Escape':
                if (closeButton) {
                    handleSelected(closeButton.primaryLink.onItemSelected, e);
                }
                break;
            case 'Enter':
                if (closeButton && columns) {
                    handleSelected(closeButton.primaryLink.onItemSelected, e);
                }
                break;
            case 'Tab': {
                const focusableEls = (_a = dialogContentRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('[tabindex]:not([tabindex="-1"])');
                const firstFocusableEl = (focusableEls === null || focusableEls === void 0 ? void 0 : focusableEls.length)
                    ? focusableEls[0]
                    : undefined;
                const lastFocusableEl = (focusableEls === null || focusableEls === void 0 ? void 0 : focusableEls.length)
                    ? focusableEls[focusableEls.length - 1]
                    : undefined;
                if (!e.shiftKey && e.target === lastFocusableEl) {
                    e.preventDefault();
                    firstFocusableEl === null || firstFocusableEl === void 0 ? void 0 : firstFocusableEl.focus();
                }
                else if (e.shiftKey && e.target === firstFocusableEl) {
                    e.preventDefault();
                    lastFocusableEl === null || lastFocusableEl === void 0 ? void 0 : lastFocusableEl.focus();
                }
                break;
            }
            default:
                break;
        }
    }
    function focusFirstDescendent() {
        var _a;
        const focusableEls = (_a = dialogContentRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('[tabindex]:not([tabindex="-1"])');
        if (!focusableEls || focusableEls.length === 0)
            return;
        const firstFocusableEl = focusableEls[0];
        const lastFocusableEl = focusableEls[focusableEls.length - 1];
        let focusElement = firstFocusableEl;
        // focus the first focusabile element but not close button
        const elementIconName = firstFocusableEl.getAttribute('icon-name');
        if (elementIconName === 'cancel' && firstFocusableEl !== lastFocusableEl) {
            [, focusElement] = focusableEls;
        }
        focusElement === null || focusElement === void 0 ? void 0 : focusElement.focus();
    }
    function handleBack(e) {
        if (closeButton) {
            handleSelected(closeButton.primaryLink.onItemSelected, e);
            e.preventDefault();
            // Since we can't actually cancel this event, we put the URL back to what it was
            // before anyone notices it has changed
            history.go(1);
        }
    }
    function renderHeader() {
        if (!headerText) {
            return null;
        }
        if (dialogVariant === 'INFORMATION_CARD') {
            // special case to high light the words in the header
            const highLightStart = headerText.indexOf(highLightString);
            const highLightEnd = highLightStart + (highLightString === null || highLightString === void 0 ? void 0 : highLightString.length);
            const plainText1 = highLightString
                ? headerText.slice(0, highLightStart)
                : headerText.split(' ').slice(0, -1).join(' ').concat(' ');
            const highLighted = highLightString
                ? headerText.slice(highLightStart, highLightEnd)
                : headerText.split(' ').pop();
            const plainText2 = highLightString ? headerText.slice(highLightEnd) : '';
            return (React.createElement("h1", { className: ['title-size-3', 'condensed-font', Styles.infoCardHeader].join(' '), id: "dialogHeader" },
                plainText1,
                React.createElement("span", { className: Styles.lastWord }, highLighted),
                plainText2));
        }
        if (dialogVariant === 'LEFT_ALIGNED_INFORMATION_CARD') {
            return (React.createElement("h1", { className: ['music-headline-3', Styles.leftAlignedCardHeader].join(' '), id: "dialogHeader" }, headerText));
        }
        return (React.createElement("h1", { className: ['music-headline-4', Styles.header].join(' '), id: "dialogHeader" }, headerText));
    }
    function renderHeaderImage() {
        if (!headerImage) {
            return null;
        }
        if (dialogVariant === 'CONTEXTUALIZE_MWEB_AUTH_DIALOG') {
            return (React.createElement("div", { className: Styles.smallDetailPageHeaderImage },
                React.createElement("music-image", { src: headerImage, alt: headerImageAltText })));
        }
        return (React.createElement("div", null,
            React.createElement("img", { className: Styles.headerImage, src: headerImage, alt: headerImageAltText })));
    }
    function renderSubHeader() {
        if (!subHeader) {
            return null;
        }
        return React.createElement("h1", { className: "music-headline-4" }, subHeader);
    }
    function renderImage() {
        if (!image) {
            return null;
        }
        return React.createElement("music-icon", { name: image, size: "large" });
    }
    function renderActionButtons(actionButton, actionButtons) {
        if (!actionButton) {
            return null;
        }
        return (React.createElement("div", { className: Styles.buttonControls },
            (actionButtons || []).map((btn) => (React.createElement("music-button", { role: "button", variant: "glass", size: "small", "icon-only": true, "icon-name": btn.icon, onmusicActivate: handleSelected.bind(null, btn.primaryLink.onItemSelected) }, btn.text))),
            React.createElement("music-button", { role: "button", variant: "glass", size: windowWidth <= WINDOW_SIZE_ENUM.MD ? 'small' : 'medium', iconOnly: windowWidth <= WINDOW_SIZE_ENUM.MD, "icon-name": actionButton.icon, onmusicActivate: handleSelected.bind(null, actionButton.primaryLink.onItemSelected) }, actionButton.text)));
    }
    function renderColumns() {
        if (columns) {
            const columnContainerContents = columns.map((column) => (React.createElement(DialogColumn, { id: props.template.id, template: column })));
            return React.createElement("div", { className: Styles.columnsContainer }, columnContainerContents);
        }
        return null;
    }
    function renderQRCard() {
        if (qrCard) {
            return (React.createElement("div", { className: Styles.qrCardContainer },
                React.createElement(DialogQRCard, { template: qrCard })));
        }
        return null;
    }
    function renderButtons(buttonsToRender) {
        if (!buttonsToRender || buttonsToRender.length === 0) {
            return null;
        }
        const buttonHasDescription = buttonsToRender.some((button) => button.description);
        const renderButtonFunction = buttonHasDescription
            ? renderButtonWithDescription
            : renderButton;
        const buttonSize = dialogVariant === 'LEFT_ALIGNED_INFORMATION_CARD' ||
            dialogVariant === 'ARTIST_ONRAMP_DIALOG'
            ? 'large'
            : 'small';
        const defaultButtonSize = dialogVariant === 'IMPROVE_WEB_AUTH_DIALOG' ||
            dialogVariant === 'CONTEXTUALIZE_MWEB_AUTH_DIALOG'
            ? 'small'
            : 'medium';
        const buttonContainerContents = buttonsToRender.length === 1
            ? renderButtonFunction(buttonsToRender[0], defaultButtonSize, 0)
            : buttonsToRender.map((button, idx) => renderButtonFunction(button, buttonSize, idx));
        const buttonContainerStyleClass = dialogVariant === 'LEFT_ALIGNED_INFORMATION_CARD'
            ? Styles.leftAlignedCardButtonContainer
            : '';
        return buttonHasDescription ? (React.createElement("div", { className: Styles.buttonContainerWithDescriptions }, buttonContainerContents)) : (React.createElement("div", { className: [Styles.buttonContainer, buttonContainerStyleClass].join(' ') }, buttonContainerContents));
    }
    function renderButton(button, size = 'small', idx) {
        if (!button) {
            return null;
        }
        const isImageDialogButtonElement = button.interface === IMAGE_DIALOG_BUTTON_ELEMENT;
        const isPrimaryDialogButtonElement = button.interface === PRIMARY_DIALOG_BUTTON_ELEMENT;
        const isDialogGlassButton = button.interface === DIALOG_GLASS_BUTTON_ELEMENT;
        const isDisabled = isPrimaryDialogButtonElement &&
            !!textInputHeader &&
            textInput.trim().length === 0 &&
            !isModerationVariant;
        const onmusicActivate = (e) => onSubmit(isDisabled, button.primaryLink.onItemSelected, e);
        let variant = 'outline';
        let buttonId = 'secondaryDialogButton';
        if (isPrimaryDialogButtonElement) {
            variant = 'solid';
            buttonId = 'primaryDialogButton';
        }
        else if (isImageDialogButtonElement) {
            variant = 'image';
            buttonId = 'imageDialogButton';
        }
        else if (isDialogGlassButton) {
            variant = 'glass';
            buttonId = 'glassDialogButton';
        }
        else if (dialogVariant === 'ARTIST_ONRAMP_DIALOG') {
            variant = 'primary-nocaps';
            buttonId = 'artistOnrampDialogButton';
        }
        return (React.createElement("music-button", { id: `${buttonId}${idx + 1}`, variant: variant, disabled: isDisabled, "icon-name": button.icon, image: button.image, href: getDeeplink(button.primaryLink.deeplink), size: size, onmusicActivate: onmusicActivate, style: isModerationVariant ? { width: '100%' } : {} }, button.text));
    }
    function renderButtonWithDescription(button, size = 'small', idx) {
        return (button && (React.createElement(Fragment, null,
            renderButton(button, size, idx),
            React.createElement("div", { className: "music-tertiary-text" }, button.description))));
    }
    function renderCloseButton() {
        if (!closeButton) {
            return null;
        }
        return (React.createElement("div", { className: Styles.closeButtonContainer, id: "dialogCloseButton" },
            React.createElement("music-button", { role: "button", variant: "glass", size: "small", "icon-only": true, "icon-name": closeButton.icon, onmusicActivate: handleSelected.bind(this, closeButton.primaryLink.onItemSelected), ariaLabelText: closeButton.altText })));
    }
    function renderFooter() {
        var _a;
        if (!footer) {
            return null;
        }
        let handleFooterLinkClicked = () => { };
        if ((_a = footer.primaryLink) === null || _a === void 0 ? void 0 : _a.onItemSelected) {
            handleFooterLinkClicked = () => { var _a; return handleSelected(((_a = footer.primaryLink) === null || _a === void 0 ? void 0 : _a.onItemSelected) || []); };
        }
        let footerSecondaryTextStyle = Styles.footerSecondaryText;
        if (dialogVariant === 'LEFT_ALIGNED_INFORMATION_CARD') {
            footerSecondaryTextStyle = Styles.leftAlignedCardFooterSecondaryText;
        }
        let footerStyledTextStyle = '';
        if (dialogVariant === 'LEFT_ALIGNED_INFORMATION_CARD') {
            footerStyledTextStyle = Styles.leftAlignedCardFooterStyledText;
        }
        return (React.createElement("div", { className: ['music-tertiary-text', Styles.footerContent].join(' ') },
            React.createElement("div", { className: footerStyledTextStyle }, renderHTML(footer.styledText)),
            footer.primaryLink && footer.primaryText && (React.createElement("div", { className: Styles.footerLink },
                React.createElement("music-link", { kind: "accent", onClick: handleFooterLinkClicked }, footer.primaryText))),
            React.createElement("div", { className: footerSecondaryTextStyle }, renderHTML(footer.secondaryText))));
    }
    function sanitizeHTML(text) {
        const newText = (text || '')
            .replace(/<a/g, '<music-link kind="accent" enable-aria=true')
            .replace(/<\/a>/g, '</music-link>');
        const config = {
            ADD_TAGS: ['MUSIC-LINK'],
            ADD_ATTR: ['kind', 'target', 'enable-aria'],
        };
        return DOMPurify.sanitize(newText, config);
    }
    function renderHTML(text) {
        return React.createElement("div", { dangerouslySetInnerHTML: { __html: sanitizeHTML(text) } });
    }
}
