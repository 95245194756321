export const hostnameMap = {
    // retail webplayer
    'www.amazon.com': 'prod',
    'www.amazon.ca': 'prod',
    'www.amazon.com.mx': 'prod',
    'www.amazon.com.br': 'prod',
    'www.amazon.co.uk': 'prod',
    'www.amazon.de': 'prod',
    'www.amazon.fr': 'prod',
    'www.amazon.it': 'prod',
    'www.amazon.es': 'prod',
    'www.amazon.in': 'prod',
    'www.amazon.co.jp': 'prod',
    'www.amazon.com.au': 'prod',
    'pre-prod.amazon.com': 'gamma',
    'ca-pre-prod.amazon.com': 'gamma',
    'mx-pre-prod.amazon.com': 'gamma',
    'br-pre-prod.amazon.com': 'gamma',
    'uk-pre-prod.amazon.com': 'gamma',
    'de-pre-prod.amazon.com': 'gamma',
    'fr-pre-prod.amazon.com': 'gamma',
    'it-pre-prod.amazon.com': 'gamma',
    'es-pre-prod.amazon.com': 'gamma',
    'in-pre-prod.amazon.com': 'gamma',
    'jp-pre-prod.amazon.com': 'gamma',
    'au-pre-prod.amazon.com': 'gamma',
    // webplayer
    'music.amazon.com': 'prod',
    'music.amazon.ca': 'prod',
    'music.amazon.com.mx': 'prod',
    'music.amazon.com.br': 'prod',
    'music.amazon.co.uk': 'prod',
    'music.amazon.de': 'prod',
    'music.amazon.fr': 'prod',
    'music.amazon.it': 'prod',
    'music.amazon.es': 'prod',
    'music.amazon.in': 'prod',
    'music.amazon.co.jp': 'prod',
    'music.amazon.com.au': 'prod',
    'music-gamma.amazon.com': 'gamma',
    'music-ca-iad.iad.proxy.amazon.com': 'gamma',
    'music-mx-iad.iad.proxy.amazon.com': 'gamma',
    'music-br-pre.prod-iad.iad.proxy.amazon.com': 'gamma',
    'music-uk-dub.dub.proxy.amazon.com': 'gamma',
    'music-de-dub.dub.proxy.amazon.com': 'gamma',
    'music-fr-dub.dub.proxy.amazon.com': 'gamma',
    'music-it-dub.dub.proxy.amazon.com': 'gamma',
    'music-es-dub.dub.proxy.amazon.com': 'gamma',
    'music-in-dub.dub.proxy.amazon.com': 'gamma',
    'music-jp-pdx.pdx.proxy.amazon.com': 'gamma',
    'music-au-pdx.pdx.proxy.amazon.com': 'gamma',
    'music.integ.amazon.com': 'integ',
    'music-br.integ.amazon.com': 'integ',
    'music-ae.integ.amazon.com': 'integ',
    'music-de.integ.amazon.com': 'integ',
    'music-es.integ.amazon.com': 'integ',
    'music-fr.integ.amazon.com': 'integ',
    'music-it.integ.amazon.com': 'integ',
    'music-uk.integ.amazon.com': 'integ',
    'music-jp.integ.amazon.com': 'integ',
    'mp3localhost.amazon.com': 'local',
};
