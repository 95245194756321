import { ISkyfireCuratedDescriptionElement } from './ISkyfireCuratedDescriptionsElement';
import ImageDimension from '../widgets/ImageDimension';
import ISkyfireLinkElement from '../../ISkyfireLinkElement';
import ISkyfireContextMenuElement from '../contextMenu/ISkyfireContextMenuElement';
import ISkyfireTemplate from '../ISkyfireTemplate';
import ISkyfireTemplateData from '../ISkyfireTemplateData';
import ISkyfireTextElement from '../widgets/ISkyfireTextElement';
import ISkyfireWidgetElement from '../widgets/ISkyfireWidgetElement';
import { ISkyfireDetailHeaderItemElement } from './ISkyfireDetailHeaderItemElement';
import { ISkyfireMultiSelectBarElement } from './ISkyfireMultiSelectBarElement';

export const PODCAST_DETAIL_TEMPLATE =
    'Podcast.Web.DetailTemplateInterface.DetailTemplate';

export interface ISkyfirePodcastDetailTemplate extends ISkyfireTemplate {
    readonly interface: typeof PODCAST_DETAIL_TEMPLATE;
    readonly widgets: ISkyfireWidgetElement[];
    readonly templateData: ISkyfireTemplateData;
    readonly headerLabel: string;
    readonly headerText: ISkyfireTextElement;
    readonly headerPrimaryText?: string;
    readonly headerPrimaryTextLink?: ISkyfireLinkElement;
    readonly headerSecondaryText?: string;
    readonly headerSecondaryTextLink?: ISkyfireLinkElement;
    readonly headerTertiaryText?: string;
    readonly contextMenu?: ISkyfireContextMenuElement;
    readonly backgroundImage?: string;
    readonly items: any[];
    readonly headerIcon?: string;
    readonly headerImage: string;
    readonly headerImageAltText: string;
    readonly headerImageDimension?: ImageDimension;
    readonly headerButtons: ISkyfireDetailHeaderItemElement[];
    readonly refinementHeader?: any;
    readonly multiSelectBar?: ISkyfireMultiSelectBarElement;
    readonly footer: string;
    readonly description?: string;
    readonly featuredItem?: any;
    readonly isSmallHeaderCentered?: boolean;
    readonly curatedDescriptionItem?: ISkyfireCuratedDescriptionElement;
}
