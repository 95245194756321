import React, { useEffect, Fragment } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { BORDERED_BADGE_ELEMENT } from 'src/types/templates/widgets/ISkyfireBorderedBadgeElement';
import { UNBORDERED_BADGE_ELEMENT } from 'src/types/templates/widgets/ISkyfireUnborderedBadgeElement';
import { LIGHT_UNBORDERED_BADGE_ELEMENT } from 'src/types/templates/widgets/ISkyfireLightUnborderedBadgeElement';
import { ACCENT_OUTLINE_BADGE_ELEMENT } from 'src/types/templates/widgets/ISkyfireAccentOutlineBadgeElement';
import {
    LoadingSpinner,
    Button,
    WidgetList,
    BackgroundImage,
} from '../components';
import ISkyfireMethod from '../types/ISkyfireMethod';
import { ISkyfireDetailTemplateV2 } from '../types/templates/detail/ISkyfireDetailTemplateV2';
import {
    useObserver,
    preventDefault,
    dispatchTemplateRendered,
    dispatchSkyfireMethods,
} from '../utils';
import Styles from './View.scss';
import DetailPlayButton from '../components/widgets/items/DetailPlayButton';
import EpisodeFeaturedItem from '../components/widgets/items/EpisodeFeaturedItem';
import ExpandableTextView from '../components/widgets/items/ExpandableTextView';
import CuratedDescriptionItem from '../components/widgets/items/CuratedDescriptionItem';
import { toSentenceCase } from '../components/widgets/items/Button';
import { CompactPillNavigatorWidget } from '../components/widgets/items/CompactPillNavigatorWidget';

interface IDetailProps {
    template: ISkyfireDetailTemplateV2;
}

export function PodcastDetailV2(props: IDetailProps) {
    const store = useStore();
    const dispatch = useDispatch();
    const {
        widgets,
        headerImage,
        backgroundImage,
        header,
        headerButtons,
        headerSecondaryText,
        headerTertiaryText,
        headerSecondaryTextLink,
        headerImageDimension,
        headerLabel,
        id,
        updatedAt,
        description,
        curatedDescriptionItem,
        featuredItem,
        badge,
        button,
        text,
        playButton,
        compactPillNavigator,
    } = props.template;
    const key = [id, updatedAt].join('-');
    const headerTextElement = useObserver(header);
    const bottomMargin = '20px';

    useEffect(
        () => () => {
            dispatchSkyfireMethods(
                dispatch,
                props.template,
                props.template.onLeave
            );
        },
        []
    );

    return (
        <div className={Styles['view-content']}>
            <BackgroundImage src={backgroundImage} />
            <music-detail-header
                key={headerImage}
                style={{
                    contain: 'layout',
                    marginTop: '40px',
                    display: 'block',
                    marginBottom: bottomMargin,
                }}
                image-src={headerImage}
                image-dimen={headerImageDimension || '1:1'}
                label={headerLabel}
                headline={headerTextElement.text ?? header}
                secondary-text={headerSecondaryText}
                secondary-text-href={headerSecondaryTextLink?.deeplink}
                onmusicSecondaryTextActivate={handleSelected.bind(
                    this,
                    headerSecondaryTextLink?.onItemSelected
                )}
                tertiary-text={headerTertiaryText}
                onClick={preventDefault}
                isSmallHeaderCentered={false}
                lastHeaderTextElement='secondaryHeadline'
                upsellButtonText={toSentenceCase(button?.text)}
                upsellBadgeText={badge?.text}
                upsellText={text}
                onupsellButtonActivate={handleSelected.bind(
                    this,
                    button?.onItemSelected
                )}
                upsellBadgeTier={getUpsellBadgeTier()}
            >
                {renderButtons()}
            </music-detail-header>
            {widgets?.length ? (
                <Fragment>
                    <music-container
                        id={id}
                        key={key}
                        onrendered={widgetsRendered}
                    >
                        {description?.length ? (
                            <div className={`${Styles.description} true`}>
                                <ExpandableTextView description={description} />
                            </div>
                        ) : (
                            <div />
                        )}
                        {curatedDescriptionItem &&
                        curatedDescriptionItem.description &&
                        curatedDescriptionItem.header ? (
                            <CuratedDescriptionItem
                                description={curatedDescriptionItem.description}
                                header={curatedDescriptionItem.header}
                            />
                        ) : (
                            <div />
                        )}

                        {compactPillNavigator?.items?.length ? (
                            <CompactPillNavigatorWidget
                                data={compactPillNavigator}
                                handleSelected={handleSelected}
                                bottomMargin={true}
                            />
                        ) : (
                            <div />
                        )}

                        {featuredItem && (
                            <EpisodeFeaturedItem
                                featuredItem={featuredItem}
                                handleSelected={handleSelected}
                            />
                        )}
                        <WidgetList
                            list={widgets}
                            handleSelected={handleSelected}
                        />
                    </music-container>
                </Fragment>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );

    function renderButtons() {
        const allHeaderButtons = [...headerButtons];
        return allHeaderButtons.map((headerButton, idx) => {
            if (headerButton.icon === 'play') {
                return (
                    <DetailPlayButton
                        button={headerButton}
                        id={`detailHeaderButton${idx + 1}`}
                        handleSelected={handleSelected}
                        featuredItem={featuredItem}
                    />
                );
            }
            if (headerButton.icon === '') {
                return (
                    <Button
                        data={headerButton}
                        handleSelected={handleSelected}
                        id={`detailHeaderButton${idx + 1}`}
                        slot='icons'
                        size={headerButton.iconOnly ? 'small' : 'medium'}
                        variant='outline-primary'
                    />
                );
            }
            return (
                <Button
                    data={headerButton}
                    handleSelected={handleSelected}
                    id={`detailHeaderButton${idx + 1}`}
                    slot='icons'
                    size={headerButton.iconOnly ? 'small' : 'medium'}
                    variant='primary'
                />
            );
        });
    }

    function handleSelected(onItemSelected: ISkyfireMethod[]) {
        if (!onItemSelected) {
            return;
        }
        dispatchSkyfireMethods(store.dispatch, props.template, onItemSelected);
    }

    function widgetsRendered(event) {
        if (event.target.id === props.template.id) {
            dispatchTemplateRendered(
                store.dispatch,
                props.template,
                event.detail
            );
            dispatchSkyfireMethods(
                store.dispatch,
                props.template,
                props.template.onViewed
            );
        }
    }

    function transformHtml(node) {
        if (node.type === 'tag' && node.name === 'a') {
            node.attribs.target = '_blank';
        }
        return node;
    }

    function getUpsellBadgeTier() {
        switch (badge?.interface) {
            case BORDERED_BADGE_ELEMENT:
                return 'availableAdFree';
            case UNBORDERED_BADGE_ELEMENT:
                return 'adFree';
            case LIGHT_UNBORDERED_BADGE_ELEMENT:
                return 'exclusive';
            case ACCENT_OUTLINE_BADGE_ELEMENT:
                return 'primeTierExclusive';
            default:
                return 'adFree';
        }
    }
}
