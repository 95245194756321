import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { globals } from './globals';
import { getSkyfireEnvironment } from './getSkyfireEnvironment';
import { getNodeEnvironment } from './getNodeEnvironment';
const API_KEY = '26b95077fec20f86665fb2529425f30b';
const SESSION_ENDPOINT = 'https://session.app-performance.music.amazon.dev';
const NOTIFICATION_ENDPOINT = 'https://events.app-performance.music.amazon.dev';
const PERFORMANCE_ENDPOINT = 'https://trace.app-performance.music.amazon.dev/v1/traces';
export function initBugsnag() {
    const { amznMusic } = globals;
    const appConfig = amznMusic === null || amznMusic === void 0 ? void 0 : amznMusic.appConfig;
    const isServedViaSwift = amznMusic === null || amznMusic === void 0 ? void 0 : amznMusic.isServedViaSwift;
    const releaseStage = `${getNodeEnvironment()}${isServedViaSwift ? '-swift' : ''}`;
    const enabledReleaseStages = [
        'prod',
        'gamma',
        'integ',
        'prod-swift',
        'gamma-swift',
        'integ-swift',
        'local-swift',
        'undefined',
        'local',
    ];
    Bugsnag.start({
        onError(event) {
            event.addMetadata('Account', {
                skyfireEnv: getSkyfireEnvironment(),
                subscription: appConfig === null || appConfig === void 0 ? void 0 : appConfig.tier,
                territory: appConfig === null || appConfig === void 0 ? void 0 : appConfig.musicTerritory,
                sessionId: appConfig === null || appConfig === void 0 ? void 0 : appConfig.sessionId,
                deviceId: appConfig === null || appConfig === void 0 ? void 0 : appConfig.deviceId,
                siteRegion: appConfig === null || appConfig === void 0 ? void 0 : appConfig.siteRegion,
                deviceType: appConfig === null || appConfig === void 0 ? void 0 : appConfig.deviceType,
                isInContainerApp: amznMusic === null || amznMusic === void 0 ? void 0 : amznMusic.isInContainerApp,
                pathname: globals.location.pathname,
                hostname: globals.location.hostname,
            });
        },
        apiKey: API_KEY,
        plugins: [new BugsnagPluginReact()],
        endpoints: {
            notify: NOTIFICATION_ENDPOINT,
            sessions: SESSION_ENDPOINT,
        },
        appVersion: appConfig === null || appConfig === void 0 ? void 0 : appConfig.version,
        releaseStage,
        enabledReleaseStages,
    });
    BugsnagPerformance.start({
        apiKey: API_KEY,
        endpoint: PERFORMANCE_ENDPOINT,
        sendPageAttributes: {
            url: true,
            title: true,
            referrer: true,
        },
        appVersion: appConfig === null || appConfig === void 0 ? void 0 : appConfig.version,
        releaseStage,
        enabledReleaseStages,
    });
    const bugsnagReactPlugin = Bugsnag.getPlugin('react');
    return (bugsnagReactPlugin === null || bugsnagReactPlugin === void 0 ? void 0 : bugsnagReactPlugin.createErrorBoundary(React)) || React.Fragment;
}
function initBugsnagCustomSpan(spanName) {
    return BugsnagPerformance
        ? BugsnagPerformance.startSpan(spanName, {
            makeCurrentContext: false,
            parentContext: null,
        })
        : null;
}
export function initBugsnagPlaybackSpan(url) {
    const whitelistPathname = [
        '/api/showHome',
        '/api/showHomeBrowse',
        '/api/restorePlayback',
        '/api/restorePodcastPlayback',
        '/api/next',
        '/api/previous',
        '/api/playStation',
        '/api/playStationFromTrack',
        '/api/playCatalogPlaylist',
        '/api/playLibraryPlaylist',
        '/api/playCatalogAlbum',
        '/api/playLibraryAlbum',
        '/api/playCatalogTracks',
        '/api/playCatalogTrack',
        '/api/playbackScrubbed',
        '/api/playbackStarted',
        '/api/playbackResumed',
        '/api/playbackPaused',
        '/api/playbackFinished',
        '/api/playbackStopped',
        '/api/playbackRebuffered',
        '/api/queueNextCatalogTrack',
        '/api/queueNextLibraryTrack',
        '/api/queueNextCatalogAlbum',
        '/api/queueNextLibraryAlbum',
        '/api/queueNextCatalogPlaylist',
        '/api/queueNextLibraryPlaylist',
        '/api/queueLastCatalogTrack',
        '/api/queueLastLibraryTrack',
        '/api/queueLastCatalogAlbum',
        '/api/queueLastLibraryAlbum',
        '/api/queueLastCatalogPlaylist',
        '/api/queueLastLibraryPlaylist',
    ];
    const _url = new URL(url);
    if (!whitelistPathname.includes(`${_url.pathname}`)) {
        return null;
    }
    const spanName = `${_url.host}${_url.pathname}`;
    return initBugsnagCustomSpan(spanName);
}
