import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useInView } from '../utils/useItemsInView';
import ExclusiveCarouselSlide from './ExclusiveCarouselSlide';
import * as Styles from './ExclusiveCarousel.scss';
const CAROUSEL_INTERVAL = 7000;
let interval = setInterval(() => { }, CAROUSEL_INTERVAL);
export default function ExclusiveCarousel({ data: { items }, onViewed, }) {
    const numOfSlides = items.length;
    const shouldShowNavigationButtons = numOfSlides > 1;
    const [activeIndex, setActiveIndex] = useState(0);
    const callbackRef = useInView(onViewed);
    useStyles(Styles);
    useEffect(() => {
        clearInterval(interval);
        interval = setInterval(() => {
            setActiveIndex((activeIndex + 1) % numOfSlides);
        }, CAROUSEL_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, [activeIndex, numOfSlides]);
    const onLeftClick = () => {
        setActiveIndex((activeIndex || numOfSlides) - 1);
    };
    const onRightClick = () => {
        setActiveIndex((activeIndex + 1) % numOfSlides);
    };
    return (React.createElement("div", { className: Styles.exclusiveCarouselContainer, ref: callbackRef },
        React.createElement("div", { className: Styles.exclusiveCarouselInner, style: {
                transform: `translateX(-${(100 / numOfSlides) * activeIndex}%)`,
            } }, items.map((slide) => (React.createElement(ExclusiveCarouselSlide, { key: slide.primaryText, data: slide })))),
        React.createElement("div", { className: Styles.buttonHolder },
            shouldShowNavigationButtons && (React.createElement("div", null,
                React.createElement("music-button", { class: "left", disabled: "false", onClick: onLeftClick, variant: "glass", size: "tiny", "icon-name": "caretleft", noMargin: true }))),
            shouldShowNavigationButtons && (React.createElement("music-button", { class: "right", disabled: "false", onClick: onRightClick, variant: "glass", size: "tiny", "icon-name": "caretright", noMargin: true }))),
        React.createElement("div", { className: Styles.dotContainer }, items.map((item, index) => {
            const activeClass = index === activeIndex ? Styles.active : undefined;
            return (React.createElement("button", { type: "button", className: activeClass, key: `exclusive-carousel-dot-${item.primaryText}`, onClick: () => setActiveIndex(index) }, "\u2022"));
        }))));
}
