import { Store } from 'redux';
import IApplicationState from '../types/IApplicationState';
import { IUIMetricOperationDao } from './IUIMetricOperationDao';
import {
    DELETE_UI_METRIC_OPERATIONS,
    SAVE_UI_METRIC_OPERATION,
    UPDATE_UI_METRIC_OPERATIONS,
} from './UIMetricActions';
import { UIMetricOperation } from './UIMetricOperation';

export class UIMetricOperationDaoSessionStorage
    implements IUIMetricOperationDao {
    private store: Store<IApplicationState>;

    constructor(store) {
        this.store = store;
    }

    public insert(uiMetricOperation: UIMetricOperation) {
        this.store.dispatch({
            type: SAVE_UI_METRIC_OPERATION,
            payload: { uiMetricOperation },
        });
    }

    public getUnProcessed(): UIMetricOperation[] {
        const unProcessedOperations: UIMetricOperation[] = [];
        for (const [key, value] of Object.entries(sessionStorage)) {
            if (key.endsWith('UIMetricOperation')) {
                const uiMetricOperation = JSON.parse(value);
                if (
                    uiMetricOperation &&
                    uiMetricOperation.isProcessing === false
                ) {
                    unProcessedOperations.push(uiMetricOperation);
                }
            }
        }
        return unProcessedOperations;
    }

    public update(ids: string[], isProcessing: boolean) {
        this.store.dispatch({
            type: UPDATE_UI_METRIC_OPERATIONS,
            payload: { ids, isProcessing },
        });
    }

    public delete(ids: string[]) {
        this.store.dispatch({
            type: DELETE_UI_METRIC_OPERATIONS,
            payload: { ids },
        });
    }
}
