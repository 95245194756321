import { DispatchMethodsEvent, EnqueueSkyfireMethod, subscribe, } from '@amzn/DMWebEventBus';
import { enqueueSkyfireMethod, executeMethods } from '../actions';
const handleDispatchMethodEvent = (store) => (payload) => {
    store.dispatch(executeMethods(payload.methods));
};
const handleEnqueueSkyfireMethod = (store) => (payload) => {
    store.dispatch(enqueueSkyfireMethod(payload));
};
export function registerSkyfireModule(store) {
    subscribe(DispatchMethodsEvent, handleDispatchMethodEvent(store));
    subscribe(EnqueueSkyfireMethod, handleEnqueueSkyfireMethod(store));
}
