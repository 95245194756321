import React, { forwardRef, useContext, useRef } from 'react';
import Template from '../../../Contexts/Template';
import TextWithLink from './TextWithLink';
import { bindHandler, preventDefault } from '../../../utils';
import { useObserver } from '../../../utils/ObserverHooks';
import Button from './Button';
function TrackRow(props, ref) {
    const { primaryText, primaryLink, metaText1, metaText2, metaText3, metaText4, textWithLinks, icon, iconButton, rowIndex, button, } = props.data;
    const contextTemplate = useContext(Template);
    const template = props.template || contextTemplate;
    const defaultRef = useRef(null);
    const onEnterPress = (event) => {
        if (event.key === 'Enter') {
            props.handleSelected(primaryLink.onItemSelected);
        }
    };
    const iconButtonElement = useObserver(iconButton);
    const bindHandlerHelper = (fn) => bindHandler(props.handleSelected, null, fn);
    const renderTextWithLinks = () => textWithLinks === null || textWithLinks === void 0 ? void 0 : textWithLinks.map((textWithLinkElement, index) => (React.createElement(TextWithLink, { key: `${index}-${textWithLinkElement.text}`, data: textWithLinkElement, template: template })));
    const key = primaryText + metaText1 + metaText2 + props.index;
    return (React.createElement("music-track-list-row", { ref: ref || defaultRef, key: key, "data-key": key, style: props.style, index: rowIndex || props.index + 1, onClick: preventDefault, onKeyPress: onEnterPress, "icon-name": (iconButtonElement === null || iconButtonElement === void 0 ? void 0 : iconButtonElement.icon) || icon, onmusicIconActivate: bindHandlerHelper(iconButtonElement === null || iconButtonElement === void 0 ? void 0 : iconButtonElement.onItemSelected), "primary-text": primaryText, onmusicPrimaryTextActivate: bindHandlerHelper(primaryLink.onItemSelected), "secondary-text": metaText1, duration: metaText2, "meta-text": metaText3, "meta-text-2": metaText4, onmouseHoverEnter: props.handleMouseEnter, onmouseHoverLeave: props.handleMouseLeave, onfocusEnter: props.handleFocus, onfocusLeave: props.handleBlur },
        button && (React.createElement(Button, { data: button, variant: "glass", handleSelected: () => props.handleSelected(button.onItemSelected), slot: "buttons", size: "tiny", usePlaceholder: true })),
        textWithLinks && (React.createElement("span", { slot: "textWithLinks", className: "secondary-text" }, renderTextWithLinks()))));
}
export default forwardRef(TrackRow);
