exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1FHC8W7_bCRMlBlKHCcLh9,._2Bq85VvyO7weFAsh2aOn-L{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._1FHC8W7_bCRMlBlKHCcLh9,._2Bq85VvyO7weFAsh2aOn-L{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._1FHC8W7_bCRMlBlKHCcLh9,._2Bq85VvyO7weFAsh2aOn-L{padding-inline-start:52px;padding-inline-end:52px}}.FBWHotewt6WffEe6Ylh-r{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){.FBWHotewt6WffEe6Ylh-r{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){.FBWHotewt6WffEe6Ylh-r{margin-inline-start:52px;margin-inline-end:52px}}.CQPJrBwW4Vkxxav_notLP{width:-moz-fit-content;width:fit-content;margin-top:var(--music-spacer-mini);margin-bottom:var(--music-spacer-mini)}.CQPJrBwW4Vkxxav_notLP music-button:not(:last-child){margin-inline-end:var(--music-spacer-base)}.CQPJrBwW4Vkxxav_notLP music-button:is(:last-child){margin-inline-end:var(--music-spacer-large)}._2Bq85VvyO7weFAsh2aOn-L{display:inline-block;margin-top:var(--music-spacer-huge);margin-bottom:var(--music-spacer-mini);width:100%;max-width:1580px}@media(min-width: 1024px){._2Bq85VvyO7weFAsh2aOn-L{display:inline-flex;justify-content:space-between;align-items:center}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_1FHC8W7_bCRMlBlKHCcLh9",
	"gridSides": "_1FHC8W7_bCRMlBlKHCcLh9",
	"header-wrapper": "_2Bq85VvyO7weFAsh2aOn-L",
	"headerWrapper": "_2Bq85VvyO7weFAsh2aOn-L",
	"grid-sides-margins": "FBWHotewt6WffEe6Ylh-r",
	"gridSidesMargins": "FBWHotewt6WffEe6Ylh-r",
	"button-container": "CQPJrBwW4Vkxxav_notLP",
	"buttonContainer": "CQPJrBwW4Vkxxav_notLP"
};