import { IAuthenticationState } from '../types';
import { getParameterByName } from './getAmazonDomain';

let semaphore = false;
let httpcon;

export async function getCSRF(
    authentication: IAuthenticationState
): Promise<string> {
    if (authentication?.csrf?.token) {
        return getCSRFTokenString(authentication.csrf);
    }
    const { csrfTokenString } = await getHttpcon();
    return csrfTokenString;
}

export async function getDeviceId(
    authentication: IAuthenticationState
): Promise<string> {
    if (window.location.host === 'mp3localhost.amazon.com:4000') {
        const { deviceId } = await getHttpcon();
        return deviceId || authentication.sessionId;
    }
    return authentication.deviceId;
}

export async function getHttpcon(): Promise<any> {
    if (!httpcon && !semaphore) {
        const response = await fetch('/horizonte/sample/httpcon', {
            method: 'get',
        });
        const responseJson = await response.json();
        const { csrf_token, csrf_ts, csrf_rnd } = responseJson.CSRFTokenConfig;
        const { deviceId } = responseJson;
        const csrf = {
            token: csrf_token,
            ts: csrf_ts,
            rnd: csrf_rnd,
        };
        httpcon = {
            csrfTokenString: getCSRFTokenString(csrf),
            deviceId,
        };
    }
    semaphore = true;
    return httpcon;
}

export function getIpAddress(): string {
    let { ipAddress } = window.amznMusic.appConfig;
    const skyfireEnv = getParameterByName('skyfireEnv', window.location.href);
    if (skyfireEnv === 'local' || skyfireEnv === 'gamma') {
        const skyfireIp = getParameterByName('skyfireIp', window.location.href);
        ipAddress = skyfireIp || ipAddress;
    }
    return ipAddress;
}

function getCSRFTokenString(csrf) {
    return `{
            "interface": "CSRFInterface.v1_0.CSRFHeaderElement",
            "token": "${csrf.token}",
            "timestamp": "${csrf.ts}",
            "rndNonce": "${csrf.rnd}"
        }`.replace(/\s/g, '');
}
