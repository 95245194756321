exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3DsrWgorCI5OMCvfdrN1V1{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._3DsrWgorCI5OMCvfdrN1V1{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._3DsrWgorCI5OMCvfdrN1V1{padding-inline-start:52px;padding-inline-end:52px}}._1C7RTP3JwDrdQR9RelPXmc{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._1C7RTP3JwDrdQR9RelPXmc{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._1C7RTP3JwDrdQR9RelPXmc{margin-inline-start:52px;margin-inline-end:52px}}._3JcYyed8q1dKoVFyvR9M-G{margin:4px}._3JcYyed8q1dKoVFyvR9M-G ._2JhEkkQYfyzS74h-cKsciV{display:inline}._3JcYyed8q1dKoVFyvR9M-G ._2JhEkkQYfyzS74h-cKsciV span{display:inline;color:var(--music-color-accent);cursor:pointer}._3JcYyed8q1dKoVFyvR9M-G ._3bCzU8hK4aL-JgwSuhuQYt{display:inline}._3JcYyed8q1dKoVFyvR9M-G ._3bCzU8hK4aL-JgwSuhuQYt span{display:inline;color:var(--music-color-accent);cursor:pointer;font-size:24px}._3JcYyed8q1dKoVFyvR9M-G ._2KYeXV6mnorM5zpSFZ8ZJ5{display:inline}._3JcYyed8q1dKoVFyvR9M-G ._2KYeXV6mnorM5zpSFZ8ZJ5 span{display:inline}._3JcYyed8q1dKoVFyvR9M-G ._3zUSkmOrvKxScYTKMXJFEa{display:inline-block}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_3DsrWgorCI5OMCvfdrN1V1",
	"gridSides": "_3DsrWgorCI5OMCvfdrN1V1",
	"grid-sides-margins": "_1C7RTP3JwDrdQR9RelPXmc",
	"gridSidesMargins": "_1C7RTP3JwDrdQR9RelPXmc",
	"search-spell-correction": "_3JcYyed8q1dKoVFyvR9M-G",
	"searchSpellCorrection": "_3JcYyed8q1dKoVFyvR9M-G",
	"search-spell-correction-link": "_2JhEkkQYfyzS74h-cKsciV",
	"searchSpellCorrectionLink": "_2JhEkkQYfyzS74h-cKsciV",
	"search-spell-correction-result": "_3bCzU8hK4aL-JgwSuhuQYt",
	"searchSpellCorrectionResult": "_3bCzU8hK4aL-JgwSuhuQYt",
	"search-spell-correction-text": "_2KYeXV6mnorM5zpSFZ8ZJ5",
	"searchSpellCorrectionText": "_2KYeXV6mnorM5zpSFZ8ZJ5",
	"search-spell-correction-inline": "_3zUSkmOrvKxScYTKMXJFEa",
	"searchSpellCorrectionInline": "_3zUSkmOrvKxScYTKMXJFEa"
};