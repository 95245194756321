import ISkyfireMethod from 'src/types/ISkyfireMethod';
import { enqueueSkyfireMethod } from 'src/actions/SkyfireMethodQueue';
import { Middleware } from 'redux';
import { IAuthenticationState } from '../types/IAuthenticationState';
import {
    DELETE_UI_METRIC_OPERATIONS,
    SAVE_UI_METRIC_OPERATION,
    UIMetricOperationDaoSessionStorage,
    UIMetricSyncData,
    UPDATE_UI_METRIC_OPERATIONS,
} from '../UIMetrics';
import { setAuthentication } from '../actions/Authentication';
import { Sync } from '../appsync/Sync';
import {
    SAVE_BOOKMARK,
    SAVE_BOOKMARK_OPERATION,
    BookmarkDaoSessionStorage,
    BookMarkOperationDaoSessionStorage,
    BookmarkSyncData,
    DELETE_BOOKMARK_OPERATION,
    UPDATE_BOOKMARK_OPERATION,
} from '../Bookmark';
import {
    FollowDaoSessionStorage,
    FollowOperationDaoSessionStorage,
    FollowSyncData,
} from '../Follow';
import {
    DELETE_FOLLOW_OPERATION,
    SAVE_FOLLOW,
    SAVE_FOLLOW_OPERATION,
    UPDATE_FOLLOW_OPERATION,
} from '../Follow/FollowActions';
import {
    CompletedDaoSessionStorage,
    CompletedOperationDaoSessionStorage,
    CompletedSyncData,
} from '../Completed';
import {
    DELETE_COMPLETED_OPERATION,
    SAVE_COMPLETED,
    SAVE_COMPLETED_OPERATION,
    UPDATE_COMPLETED_OPERATION,
} from '../Completed/CompletedActions';
import {
    DELETE_PLAYBACK_METRIC_OPERATION,
    PlaybackMetricOperationDaoSessionStorage,
    PlaybackMetricSyncData,
    SAVE_PLAYBACK_METRIC_OPERATION,
    UPDATE_PLAYBACK_METRIC_OPERATION,
} from '../PlaybackMetrics';
import {
    DELETE_SAVE_OPERATION,
    SAVE_EPISODE,
    SAVE_OPERATION,
    SaveDaoSessionStorage,
    SaveOperationDaoSessionStorage,
    SaveSyncData,
    UPDATE_SAVE_OPERATION,
} from '../Save';
import {
    formatDeviceTimeZone,
    getOAuthToken,
    getPodcastHeaders,
    podcastRequest,
} from '../utils';
import {
    APPSYNC_RESOLVE_OPERATIONS_METHOD,
    APPSYNC_WRITE_METHOD,
    BOOKMARKS_LAST_SYNC_TIME,
    BOOKMARKS_SET_ON_REPORT_OPERATIONS_METHOD,
    BOOKMARKS_WRITE_ELEMENT,
    CLEAR_APP_SYNC_STATES_METHOD,
    FOLLOWS_LAST_SYNC_TIME,
    FOLLOWS_SET_ON_REPORT_OPERATIONS_METHOD,
    FOLLOWS_WRITE_ELEMENT,
    COMPLETED_LAST_SYNC_TIME,
    COMPLETED_SET_ON_REPORT_OPERATIONS_METHOD,
    COMPLETED_WRITE_ELEMENT,
    IN_PROGRESS_LOWER_BOUND_MILLISECONDS,
    IN_PROGRESS_UPPER_BOUND_PERCENTAGE,
    PLAYBACK_METRICS_SET_ON_REPORT_OPERATIONS_METHOD,
    PLAYBACK_METRICS_WRITE_ELEMENT,
    SAVES_LAST_SYNC_TIME,
    SAVES_SET_OP_REPORT_OPERATIONS_METHOD,
    SAVES_WRITE_ELEMENT,
    SET_BOOKMARKS_LAST_SYNC_TIME_METHOD,
    SET_BOOKMARK_THRESHOLDS_METHOD,
    SET_FOLLOWS_LAST_SYNC_TIME_METHOD,
    SET_COMPLETED_LAST_SYNC_TIME_METHOD,
    SET_SAVES_LAST_SYNC_TIME_METHOD,
    UI_METRICS_SET_ON_REPORT_OPERATIONS_METHOD,
    UI_METRICS_WRITE_ELEMENT,
    AppSyncTypes,
    WEB_STORAGE,
} from '../appsync/AppSyncConstants';
import { SET_STORAGE } from '../actions/StorageConstants';
import { FollowPrompt } from '../FollowPrompt';

export const AppSyncMiddleware: Middleware = (store) => (next) => async (
    action
) => {
    next(action);

    const followSync = new Sync(
        AppSyncTypes.FOLLOW,
        new FollowSyncData(
            new FollowDaoSessionStorage(store),
            new FollowOperationDaoSessionStorage(store),
            store
        )
    );
    const saveSync = new Sync(
        AppSyncTypes.SAVE,
        new SaveSyncData(
            new SaveDaoSessionStorage(store),
            new SaveOperationDaoSessionStorage(store),
            store
        )
    );
    const completedSync = new Sync(
        AppSyncTypes.COMPLETED,
        new CompletedSyncData(
            new CompletedDaoSessionStorage(store),
            new CompletedOperationDaoSessionStorage(store),
            store
        )
    );
    const bookmarkSyncData = new BookmarkSyncData(
        new BookmarkDaoSessionStorage(store),
        new BookMarkOperationDaoSessionStorage(store),
        store
    );
    const playbackMetricSyncData = new PlaybackMetricSyncData(
        new PlaybackMetricOperationDaoSessionStorage(store),
        store
    );
    const uiMetricSyncData = new UIMetricSyncData(
        new UIMetricOperationDaoSessionStorage(store),
        store
    );
    if (action.type === APPSYNC_WRITE_METHOD) {
        const { condition } = action.payload;
        const elementArray = action.payload.elements;
        elementArray.forEach((element) => {
            if (element.interface === FOLLOWS_WRITE_ELEMENT) {
                followSync.write(element, condition);
            } else if (element.interface === SAVES_WRITE_ELEMENT) {
                saveSync.write(element, condition);
            } else if (element.interface === COMPLETED_WRITE_ELEMENT) {
                completedSync.write(element, condition);
            } else if (element.interface === BOOKMARKS_WRITE_ELEMENT) {
                bookmarkSyncData.write(element, condition);
            } else if (element.interface === PLAYBACK_METRICS_WRITE_ELEMENT) {
                playbackMetricSyncData.write(element, condition);
            } else if (element.interface === UI_METRICS_WRITE_ELEMENT) {
                uiMetricSyncData.write(element, condition);
            }
        });
    } else if (action.type === APPSYNC_RESOLVE_OPERATIONS_METHOD) {
        if (action.payload.operations.length > 0) {
            followSync.deleteOperation(action.payload.operations);
            saveSync.deleteOperation(action.payload.operations);
            completedSync.deleteOperation(action.payload.operations);
            bookmarkSyncData.deleteOperation(action.payload.operations);
            playbackMetricSyncData.deleteOperation(action.payload.operations);
            uiMetricSyncData.deleteOperation(action.payload.operations);
        }
    } else if (
        action.type === FOLLOWS_SET_ON_REPORT_OPERATIONS_METHOD ||
        action.type === SAVES_SET_OP_REPORT_OPERATIONS_METHOD ||
        action.type === COMPLETED_SET_ON_REPORT_OPERATIONS_METHOD ||
        action.type === BOOKMARKS_SET_ON_REPORT_OPERATIONS_METHOD ||
        action.type === PLAYBACK_METRICS_SET_ON_REPORT_OPERATIONS_METHOD ||
        action.type === UI_METRICS_SET_ON_REPORT_OPERATIONS_METHOD
    ) {
        const { onReportOperations } = action.payload;
        const requestInfo = await getRequestInfo(store);
        onReportOperations.forEach(async (method) => {
            const response = await podcastRequest({
                url: method.url,
                headers: getPodcastHeaders(method.target),
                store,
                requestInfo,
                methodBody: method,
            });
            response.forEach((responseMethod: ISkyfireMethod) => {
                store.dispatch(
                    enqueueSkyfireMethod({
                        queue: responseMethod.queue,
                        method: { ...responseMethod, ...{ owner: 'AppSync' } },
                    })
                );
            });
        });
    } else if (action.type === SAVE_FOLLOW) {
        const { id } = action.payload.follow;
        const key = `${id}_Follow`;
        WEB_STORAGE.setItem(key, JSON.stringify(action.payload.follow));
        if (WEB_STORAGE.getItem(key)) {
            const stateForFollow = JSON.parse(WEB_STORAGE.getItem(key) || '[]');
            store.dispatch({
                type: SET_STORAGE,
                payload: {
                    group: 'FOLLOWS',
                    key: stateForFollow.id,
                    value: stateForFollow.isFollowed ? 'FOLLOW' : 'UNFOLLOW',
                },
            });
        }
    } else if (action.type === SAVE_FOLLOW_OPERATION) {
        const { operationId } = action.payload.followOperation;
        const key = `${operationId}_FollowOperation`;
        WEB_STORAGE.setItem(
            key,
            JSON.stringify(action.payload.followOperation)
        );
    } else if (action.type === UPDATE_FOLLOW_OPERATION) {
        action.payload.ids.forEach((id) => {
            const key = `${id}_FollowOperation`;
            const followOperation = JSON.parse(
                WEB_STORAGE.getItem(key) || '[]'
            );
            if (followOperation !== undefined) {
                followOperation.isProcessing = action.payload.isProcessing;
                WEB_STORAGE.setItem(key, JSON.stringify(followOperation));
            }
        });
    } else if (action.type === DELETE_FOLLOW_OPERATION) {
        action.payload.ids.forEach((id) => {
            const key = `${id}_FollowOperation`;
            WEB_STORAGE.removeItem(key);
        });
    } else if (action.type === SAVE_EPISODE) {
        const { episodeId } = action.payload.save;
        const key = `${episodeId}_Save`;
        WEB_STORAGE.setItem(key, JSON.stringify(action.payload.save));
        if (WEB_STORAGE.getItem(key)) {
            const stateForSave = JSON.parse(WEB_STORAGE.getItem(key) || '[]');
            store.dispatch({
                type: SET_STORAGE,
                payload: {
                    group: 'SAVES',
                    key: stateForSave.episodeId,
                    value: stateForSave.isSaved ? 'SAVE' : 'UNSAVE',
                },
            });
        }
    } else if (action.type === SAVE_OPERATION) {
        const { operationId } = action.payload.saveOperation;
        const key = `${operationId}_SaveOperation`;
        WEB_STORAGE.setItem(key, JSON.stringify(action.payload.saveOperation));
    } else if (action.type === UPDATE_SAVE_OPERATION) {
        action.payload.episodeIds.forEach((id) => {
            const key = `${id}_SaveOperation`;
            const saveOperation = JSON.parse(WEB_STORAGE.getItem(key) || '[]');
            if (saveOperation !== undefined) {
                saveOperation.isProcessing = action.payload.isProcessing;
                WEB_STORAGE.setItem(key, JSON.stringify(saveOperation));
            }
        });
    } else if (action.type === DELETE_SAVE_OPERATION) {
        action.payload.episodeIds.forEach((id) => {
            const key = `${id}_SaveOperation`;
            WEB_STORAGE.removeItem(key);
        });
    } else if (action.type === SAVE_BOOKMARK) {
        const { episodeId } = action.payload.bookmark;
        const key = `${episodeId}_Bookmark`;
        WEB_STORAGE.setItem(key, JSON.stringify(action.payload.bookmark));
    } else if (action.type === SAVE_BOOKMARK_OPERATION) {
        const { operationId } = action.payload.bookmarkOperation;
        const key = `${operationId}_BookmarkOperation`;
        WEB_STORAGE.setItem(
            key,
            JSON.stringify(action.payload.bookmarkOperation)
        );
    } else if (action.type === UPDATE_BOOKMARK_OPERATION) {
        action.payload.ids.forEach((id) => {
            const key = `${id}_BookmarkOperation`;
            const bookmarkOperation = JSON.parse(
                WEB_STORAGE.getItem(key) || '[]'
            );
            if (bookmarkOperation !== undefined) {
                bookmarkOperation.isProcessing = action.payload.isProcessing;
                WEB_STORAGE.setItem(key, JSON.stringify(bookmarkOperation));
            }
        });
    } else if (action.type === SAVE_COMPLETED) {
        const { id } = action.payload.completed;
        const key = `${id}_Completed`;
        WEB_STORAGE.setItem(key, JSON.stringify(action.payload.completed));
        if (WEB_STORAGE.getItem(key)) {
            const stateForCompleted = JSON.parse(
                WEB_STORAGE.getItem(key) || '[]'
            );
            store.dispatch({
                type: SET_STORAGE,
                payload: {
                    group: 'COMPLETED',
                    key: stateForCompleted.id,
                    value: stateForCompleted.isCompleted
                        ? 'COMPLETED'
                        : 'UNCOMPLETED',
                },
            });
        }
    } else if (action.type === SAVE_COMPLETED_OPERATION) {
        const { operationId } = action.payload.completedOperation;
        const key = `${operationId}_CompletedOperation`;
        WEB_STORAGE.setItem(
            key,
            JSON.stringify(action.payload.completedOperation)
        );
    } else if (action.type === UPDATE_COMPLETED_OPERATION) {
        action.payload.ids.forEach((id) => {
            const key = `${id}_CompletedOperation`;
            const completedOperation = JSON.parse(
                WEB_STORAGE.getItem(key) || '[]'
            );
            if (completedOperation !== undefined) {
                completedOperation.isProcessing = action.payload.isProcessing;
                WEB_STORAGE.setItem(key, JSON.stringify(completedOperation));
            }
        });
    } else if (action.type === DELETE_COMPLETED_OPERATION) {
        action.payload.ids.forEach((id) => {
            const key = `${id}completedOperation`;
            WEB_STORAGE.removeItem(key);
        });
    } else if (action.type === DELETE_BOOKMARK_OPERATION) {
        action.payload.ids.forEach((id) => {
            const key = `${id}_BookmarkOperation`;
            WEB_STORAGE.removeItem(key);
        });
    } else if (action.type === SAVE_PLAYBACK_METRIC_OPERATION) {
        const { operationId } = action.payload.playbackMetricOperation;
        const key = `${operationId}_PlaybackMetricOperation`;
        window.sessionStorage.setItem(
            key,
            JSON.stringify(action.payload.playbackMetricOperation)
        );
    } else if (action.type === UPDATE_PLAYBACK_METRIC_OPERATION) {
        action.payload.ids.forEach((id) => {
            const key = `${id}_PlaybackMetricOperation`;
            const playbackMetricOperation = JSON.parse(
                window.sessionStorage.getItem(key) || '[]'
            );
            if (playbackMetricOperation !== undefined) {
                playbackMetricOperation.isProcessing =
                    action.payload.isProcessing;
                window.sessionStorage.setItem(
                    key,
                    JSON.stringify(playbackMetricOperation)
                );
            }
        });
    } else if (action.type === DELETE_PLAYBACK_METRIC_OPERATION) {
        action.payload.ids.forEach((id) => {
            const key = `${id}_PlaybackMetricOperation`;
            window.sessionStorage.removeItem(key);
        });
    } else if (action.type === SAVE_UI_METRIC_OPERATION) {
        const { uiMetricOperation } = action.payload;
        const key = `${uiMetricOperation.operationId}_UIMetricOperation`;
        window.sessionStorage.setItem(key, JSON.stringify(uiMetricOperation));
    } else if (action.type === UPDATE_UI_METRIC_OPERATIONS) {
        action.payload.ids.forEach((id) => {
            const key = `${id}_UIMetricOperation`;
            const operation = window.sessionStorage.getItem(key);
            if (operation) {
                const uiMetricOperation = JSON.parse(operation);
                uiMetricOperation.isProcessing = action.payload.isProcessing;
                window.sessionStorage.setItem(
                    key,
                    JSON.stringify(uiMetricOperation)
                );
            }
        });
    } else if (action.type === DELETE_UI_METRIC_OPERATIONS) {
        action.payload.ids.forEach((id) => {
            const key = `${id}_UIMetricOperation`;
            window.sessionStorage.removeItem(key);
        });
    } else if (action.type === SET_FOLLOWS_LAST_SYNC_TIME_METHOD) {
        const { lastSyncTime } = action.payload;
        WEB_STORAGE.setItem(FOLLOWS_LAST_SYNC_TIME, lastSyncTime);
    } else if (action.type === SET_SAVES_LAST_SYNC_TIME_METHOD) {
        const { lastSyncTime } = action.payload;
        WEB_STORAGE.setItem(SAVES_LAST_SYNC_TIME, lastSyncTime);
    } else if (action.type === SET_COMPLETED_LAST_SYNC_TIME_METHOD) {
        const { lastSyncTime } = action.payload;
        WEB_STORAGE.setItem(COMPLETED_LAST_SYNC_TIME, lastSyncTime);
    } else if (action.type === SET_BOOKMARKS_LAST_SYNC_TIME_METHOD) {
        const { lastSyncTime } = action.payload;
        WEB_STORAGE.setItem(BOOKMARKS_LAST_SYNC_TIME, lastSyncTime);
    } else if (action.type === SET_BOOKMARK_THRESHOLDS_METHOD) {
        const {
            inProgressLowerBoundMilliseconds,
            inProgressUpperBoundPercentage,
        } = action.payload;
        WEB_STORAGE.setItem(
            IN_PROGRESS_LOWER_BOUND_MILLISECONDS,
            inProgressLowerBoundMilliseconds
        );
        WEB_STORAGE.setItem(
            IN_PROGRESS_UPPER_BOUND_PERCENTAGE,
            inProgressUpperBoundPercentage
        );
    } else if (action.type === CLEAR_APP_SYNC_STATES_METHOD) {
        const { customerId } = store.getState().Authentication;
        const bookmarkSessionDao = new BookmarkDaoSessionStorage(store);
        const saveSessionDao = new SaveDaoSessionStorage(store);
        const followSessionDao = new FollowDaoSessionStorage(store);
        const followPrompt = new FollowPrompt();
        const completedSessionDao = new CompletedDaoSessionStorage(store);
        bookmarkSessionDao.clearBookmarkData(customerId);
        saveSessionDao.clearSaveData(customerId);
        followSessionDao.clearFollowData(customerId);
        completedSessionDao.clearCompletedData(customerId);
        followPrompt.clearFollowPromptPreferences();
    }
};

async function getRequestInfo(store) {
    const authenticationState: IAuthenticationState = store.getState()
        .Authentication;
    let accessToken;
    if (
        authenticationState.isCirrusAuthExpired ||
        !authenticationState.customerId
    ) {
        accessToken = '';
    } else {
        accessToken = authenticationState.accessToken;
        const THREE_MINUTES = 3 * 60 * 1000;
        if (Date.now() > authenticationState.expiresAt - THREE_MINUTES) {
            const {
                accessToken: newAccessToken,
                expiresAt,
            } = await getOAuthToken();
            accessToken = newAccessToken;
            store.dispatch(setAuthentication({ accessToken, expiresAt }));
        }
    }
    const {
        customerId,
        deviceId,
        deviceType,
        marketplaceId,
        sessionId,
        csrf,
    } = store.getState().Authentication;
    const now = new Date();
    const timeZone = formatDeviceTimeZone(now.getTimezoneOffset());
    return {
        customerId,
        deviceId,
        deviceType,
        marketplaceId,
        sessionId,
        accessToken,
        csrf,
        timeZone,
    };
}
