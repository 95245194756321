exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._38aMhp73WSIGrLiZJWAlZn{overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;white-space:pre-wrap}.VRq8xRpQ_AsdiUcIUhPmy{margin-top:0}", ""]);

// exports
exports.locals = {
	"text-clamp": "_38aMhp73WSIGrLiZJWAlZn",
	"textClamp": "_38aMhp73WSIGrLiZJWAlZn",
	"paragraph": "VRq8xRpQ_AsdiUcIUhPmy"
};