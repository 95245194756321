import { v4 as uuidv4 } from 'uuid';
import { UIMetricWriteElement } from './UIMetricWriteElement';
import ISkyfireMethod from '../types/ISkyfireMethod';
import { IWriteMethod } from '../types/appsync/IAppSync';
import { SINGLE_THREADED_QUEUE } from '../utils/skyfireContants';

export class UIMetrics {
    public static onClicked(click: {
        actionType: ActionType;
        contentName: ContentName;
        pageType: PageType;
        entityType?: EntityType;
        entityIdType?: EntityIdType;
        entityId?: string;
        entityPos?: number;
    }): ISkyfireMethod[] {
        const uiMetricWriteElement = new UIMetricWriteElement({
            id: uuidv4(),
            signalType: SignalType.UI_CLICK,
            pageType: click.pageType,
            contentSrc: ContentSrc.CLIENT,
            entityType: click.entityType,
            entityIdType: click.entityIdType,
            entityId: click.entityId,
            entityPos: click.entityPos,
            actionType: click.actionType,
            interactionType: InteractionType.CLICK,
            contentName: click.contentName,
        });
        const writeMethod: IWriteMethod = {
            interface: 'Podcast.Web.AppSyncInterface.WriteMethod',
            condition: null,
            forced: false,
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'UI_METRICS_SYNC',
            },
            elements: [uiMetricWriteElement],
        };
        return [writeMethod];
    }

    public static onPageViewed(page: {
        pageType: PageType;
        detailPageItemId?: string;
        detailPageItemIdType?: DetailPageItemIdType;
    }): ISkyfireMethod[] {
        const uiMetricWriteElement = new UIMetricWriteElement({
            id: uuidv4(),
            signalType: SignalType.UI_PAGE_VIEW,
            pageType: page.pageType,
            detailPageItemId: page.detailPageItemId,
            detailPageItemIdType: page.detailPageItemIdType,
        });
        const writeMethod: IWriteMethod = {
            interface: 'Podcast.Web.AppSyncInterface.WriteMethod',
            condition: null,
            forced: false,
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'UI_METRICS_SYNC',
            },
            elements: [uiMetricWriteElement],
        };
        return [writeMethod];
    }

    public static onContentViewed(content: {
        contentName: ContentName;
        containerType: ContainerType;
        pageType: PageType;
    }): ISkyfireMethod[] {
        const uiMetricWriteElement = new UIMetricWriteElement({
            id: uuidv4(),
            signalType: SignalType.UI_CONTENT_VIEW,
            contentSrc: ContentSrc.CLIENT,
            containerIndex: 0,
            containerType: content.containerType,
            contentName: content.contentName,
            pageType: content.pageType,
        });
        const writeMethod: IWriteMethod = {
            interface: 'Podcast.Web.AppSyncInterface.WriteMethod',
            condition: null,
            forced: false,
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'UI_METRICS_SYNC',
            },
            elements: [uiMetricWriteElement],
        };
        return [writeMethod];
    }
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum EntityIdType {
    PODCAST_SHOW_ID = 'PODCAST_SHOW_ID',
    PODCAST_EPISODE_ID = 'PODCAST_EPISODE_ID',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum EntityType {
    PODCAST_SHOW = 'PODCAST_SHOW',
    PODCAST_EPISODE = 'PODCAST_EPISODE',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum PageType {
    PODCASTS_LIBRARY_SHOWS = 'podcastsLibraryShows',
    PODCASTS_LIBRARY_PLAYLIST = 'podcastsLibraryPlaylist',
    PODCASTS_LIBRARY_DOWNLOADS = 'podcastsLibraryDownloads',
    PODCASTS_LIBRARY_EPISODE_DETAIL = 'podcastsLibraryEpisodeDetail',
    PODCASTS_RECENTLY_PLAYED = 'podcastsRecentlyPlayedEpisodes',
    PODCASTS_SHOW_DETAIL = 'podcastsShowDetail',
    PODCASTS_HOME = 'podcastsHomePage',
    NONE = '',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum ActionType {
    SELECT_PODCAST_SHOW = 'selectPodcastShow',
    SELECT_PODCAST_EPISODE = 'selectPodcastEpisode',
    SAVE_PODCAST_EPISODE = 'savePodcastEpisode',
    UNSAVE_PODCAST_EPISODE = 'unsavePodcastEpisode',
    FOLLOW_PODCAST_SHOW = 'followPodcastShow',
    UNFOLLOW_PODCAST_SHOW = 'unfollowPodcastShow',
    DOWNLOAD_PODCAST_EPISODE = 'downloadPodcastEpisode',
    REMOVE_DOWNLOAD_PODCAST_EPISODE = 'removeDownloadPodcastEpisode',
    PLAY_PODCAST_EPISODE = 'playPodcastEpisode',
    PLAY_PAUSE_PODCAST_EPISODE = 'playPausePodcastEpisode',
    FAST_FORWARD_PODCAST_EPISODE = 'fastForwardPodcastEpisode',
    REWIND_PODCAST_EPISODE = 'rewindPodcastEpisode',
    SELECT_PODCAST_PLAYBACK_SPEED = 'selectPodcastPlaybackSpeed',
    SELECT_PODCAST_SLEEP_TIMER = 'selectPodcastSleepTimer',
    GO_PODCAST_SHOW = 'goPodcastShow',
    SELECT_PODCAST_UPSELL = 'selectPodcastUpsell',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum ContainerType {
    ITEM_LIST = 'ITEM_LIST',
    GALLERY = 'GALLERY',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum ContentName {
    PODCASTS_LIBRARY_SHOWS = 'podcastsLibraryShows',
    PODCASTS_LIBRARY_PLAYLIST = 'podcastsLibraryPlaylist',
    PODCASTS_LIBRARY_DOWNLOADS = 'podcastsLibraryDownloads',
    PODCASTS_LIBRARY_EPISODE_DETAIL = 'podcastsLibraryEpisodeDetail',
    PODCAST_PLAYBACK_BUTTONS = 'podcastPlaybackButtons',
    PODCASTS_RECENTLY_PLAYED = 'podcastsRecentlyPlayedEpisodes',
    PODCASTS_LIBRARY_NAV = 'podcastsLibraryNav',
    PODCASTS_FOLLOWS_SHOVELER = 'podcastsFollowsShoveler',
    PODCASTS_BOOKMARKS_SHOVELER = 'podcastsBookmarksShoveler',
    PODCASTS_PINNED_EPISODE = 'podcastsPinnedEpisode',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum DetailPageItemIdType {
    PODCAST_SHOW_ID = 'PODCAST_SHOW_ID',
    PODCAST_EPISODE_ID = 'PODCAST_EPISODE_ID',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum ContentSrc {
    CLIENT = 'client',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum InteractionType {
    CLICK = 'CLICK',
}

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum SignalType {
    UI_CLICK = 'UI_CLICK',
    UI_CONTENT_VIEW = 'UI_CONTENT_VIEW',
    UI_PAGE_VIEW = 'UI_PAGE_VIEW',
}
