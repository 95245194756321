import { v4 } from 'uuid';

export class FollowOperation {
    public operationId: string;

    public elementId: string;

    public title: string;

    public preset: string;

    public podcastDescription: string;

    public publisher?: string;

    public image: string;

    public isFollowed: boolean;

    public updatedTime: number;

    public isProcessing: boolean;

    constructor(operation: {
        operationId?;
        elementId?;
        title?;
        preset?;
        podcastDescription?;
        publisher?;
        image?;
        isFollowed?;
        updatedTime?;
        isProcessing?;
    }) {
        this.operationId = operation.operationId ?? v4();
        this.elementId = operation.elementId;
        this.title = operation.title;
        this.preset = operation.preset;
        this.podcastDescription = operation.podcastDescription;
        this.publisher = operation.publisher;
        this.image = operation.image;
        this.isFollowed = operation.isFollowed;
        this.updatedTime = operation.updatedTime;
        this.isProcessing = operation.isProcessing;
    }
}
