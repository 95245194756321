import { getPodcastEnvironment } from '.';
import { INVOKE_HTTP_POST } from '..';
import { BOOTSTRAP_WEB_SKILL } from './podcastTargets';
import { SINGLE_THREADED_QUEUE } from './skyfireContants';

export function invokeHttpPostSkillMethod(deeplink: string) {
    const deeplinkClientInfo = JSON.stringify({
        interface: 'Podcast.Web.DeeplinkInterface.DeeplinkClientInformation',
        deeplink,
    });
    return {
        interface: INVOKE_HTTP_POST,
        queue: {
            interface: SINGLE_THREADED_QUEUE,
            id: 'TEMPLATE',
        },
        forced: false,
        before: [],
        after: [],
        onError: [],
        url: getPodcastEnvironment(),
        target: BOOTSTRAP_WEB_SKILL,
        clientInformation: [deeplinkClientInfo],
        extra: {},
    };
}
