import React, { useCallback, useContext } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useDispatch } from 'react-redux';
import { dispatchSkyfireMethods } from '../utils/dispatchSkyfireMethods';
import Template from '../Contexts/Template';
import * as Styles from './ImageGroupElement.scss';
export default function ImageGroupElementProps(props) {
    const { imageSrc, href, onItemSelected } = props.data;
    useStyles(Styles);
    const dispatch = useDispatch();
    const template = useContext(Template);
    const onClick = useCallback(() => {
        if (template) {
            dispatchSkyfireMethods(dispatch, template, onItemSelected);
        }
    }, [dispatch, template, onItemSelected]);
    return (React.createElement("div", { className: Styles.imageContainer },
        React.createElement("music-link", { disabled: href === null, href: href, target: "_blank", onClick: onClick },
            React.createElement("img", { src: imageSrc, alt: "sponsor" }))));
}
