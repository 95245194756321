exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LpcVb_9wO3glYwjfkaRbL{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){.LpcVb_9wO3glYwjfkaRbL{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){.LpcVb_9wO3glYwjfkaRbL{padding-inline-start:52px;padding-inline-end:52px}}._2SuBpv-Fq64muNU-8d0Cr6{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._2SuBpv-Fq64muNU-8d0Cr6{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._2SuBpv-Fq64muNU-8d0Cr6{margin-inline-start:52px;margin-inline-end:52px}}.tmsWVZw39-8uMao44pVcq{display:none}._1t6UsApYIHRa1ARNRl_xCX{z-index:6;box-sizing:border-box;position:fixed;inset-inline-end:calc(20px - 14px)}@media only screen and (min-width: 480px){._1t6UsApYIHRa1ARNRl_xCX{inset-inline-end:calc(36px - 14px)}}@media only screen and (min-width: 1280px){._1t6UsApYIHRa1ARNRl_xCX{inset-inline-end:calc(52px - 14px)}}._3ET61n9NP41C6GmmdTlgsd{z-index:6;box-sizing:border-box;position:fixed;transform:rotate(270deg);inset-inline-start:calc(20px - 14px)}@media only screen and (min-width: 480px){._3ET61n9NP41C6GmmdTlgsd{inset-inline-start:calc(36px - 14px)}}@media only screen and (min-width: 1280px){._3ET61n9NP41C6GmmdTlgsd{inset-inline-start:calc(52px - 14px)}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "LpcVb_9wO3glYwjfkaRbL",
	"gridSides": "LpcVb_9wO3glYwjfkaRbL",
	"grid-sides-margins": "_2SuBpv-Fq64muNU-8d0Cr6",
	"gridSidesMargins": "_2SuBpv-Fq64muNU-8d0Cr6",
	"hidden": "tmsWVZw39-8uMao44pVcq",
	"context-menu-container": "_1t6UsApYIHRa1ARNRl_xCX",
	"contextMenuContainer": "_1t6UsApYIHRa1ARNRl_xCX",
	"close-button": "_3ET61n9NP41C6GmmdTlgsd",
	"closeButton": "_3ET61n9NP41C6GmmdTlgsd"
};