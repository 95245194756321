import { IWriteElement } from '../types/appsync/IAppSync';

export class FollowWriteElement implements IWriteElement {
    public id: string;

    public title: string;

    public description: string;

    public publisher?: string;

    public image: string;

    public followed: boolean;

    public __type: string;

    public interface?: string;

    public podcastShowVariantId?: string;

    constructor(
        id,
        title,
        description,
        publisher,
        image,
        followed,
        podcastShowVariantId?
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.publisher = publisher;
        this.image = image;
        this.followed = followed;
        this.podcastShowVariantId = podcastShowVariantId;
        this.__type = 'Podcast.FollowInterface.v1_0#FollowWriteElement';
        this.interface = 'Podcast.Web.FollowInterface.FollowWriteElement';
    }
}
