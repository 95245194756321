export type IwindowWidth =
    | 'XS'
    | 'SM'
    | 'MD'
    | 'LG'
    | 'XL'
    | 'XL2'
    | 'XL3'
    | 'XL4'
    | 'TV';

// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum WINDOW_SIZE_ENUM {
    XS = 0,
    SM = 360,
    MD = 480,
    LG = 600,
    XL = 840,
    XL2 = 1024,
    XL3 = 1280,
    XL4 = 1440,
    TV = 2260,
}

export default IwindowWidth;
