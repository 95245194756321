import { v4 } from 'uuid';
import { UIMetricWriteElement } from './UIMetricWriteElement';

export class UIMetricOperation {
    public operationId: string;

    public updatedTime: number;

    public isProcessing: boolean;

    public uiMetricWriteElement: UIMetricWriteElement;

    constructor(operationId, updatedTime, isProcessing, uiMetricWriteElement) {
        this.operationId = operationId === '' ? v4() : operationId;
        this.updatedTime = updatedTime;
        this.isProcessing = isProcessing;
        this.uiMetricWriteElement = uiMetricWriteElement;
    }
}
