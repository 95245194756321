import { REMOVE_QUERY_PARAMS_FROM_PAGE_URL } from '../actions';
import { globals, rewriteDeeplink } from '../utils';
export const PageUrlMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        case REMOVE_QUERY_PARAMS_FROM_PAGE_URL: {
            const url = new URL(globals.location.href);
            const currentSearchParams = url.searchParams;
            const queryParams = action.payload.queryParams || [];
            queryParams.forEach((queryParam) => {
                if (currentSearchParams.has(queryParam)) {
                    currentSearchParams.delete(queryParam);
                }
            });
            globals.history.replaceState({}, '', rewriteDeeplink(url.toString(), false));
            break;
        }
        default:
            break;
    }
    next(action);
};
