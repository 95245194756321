import React, { useCallback, useContext } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useDispatch } from 'react-redux';
import { dispatchSkyfireMethods } from '../../../utils';
import { useInView } from '../../../utils/useItemsInView';
import Template from '../../../Contexts/Template';
import * as Styles from './ImageGroupWidget.scss';
import ImageElement from '../../ImageGroupElement';
export default function ImageGroupWidget(props) {
    const { primaryText, images, onViewed } = props.data;
    useStyles(Styles);
    const dispatch = useDispatch();
    const template = useContext(Template);
    const onView = useCallback(() => {
        if (template) {
            dispatchSkyfireMethods(dispatch, template, onViewed);
        }
    }, [dispatch, template, onViewed]);
    const callbackRef = useInView(onView);
    const imageGroup = images.map((image) => React.createElement(ImageElement, { data: image }));
    return (React.createElement("div", { className: Styles.imageGroupWidget, ref: callbackRef },
        React.createElement("div", { className: Styles.imageGroupHeader },
            React.createElement("h1", { className: "music-headline-4" }, primaryText)),
        React.createElement("div", { className: Styles.imageGroupContainer }, imageGroup)));
}
