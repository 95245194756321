exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1zTBeS0u0009bjYjXl3Xmj,._1cHmr102aEhfOG0D32r1zB,._2HDLEpl4nytn7veH1ek_6w{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._1zTBeS0u0009bjYjXl3Xmj,._1cHmr102aEhfOG0D32r1zB,._2HDLEpl4nytn7veH1ek_6w{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._1zTBeS0u0009bjYjXl3Xmj,._1cHmr102aEhfOG0D32r1zB,._2HDLEpl4nytn7veH1ek_6w{padding-inline-start:52px;padding-inline-end:52px}}._22bc-kkDntp3G2ZN2DFuWk{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._22bc-kkDntp3G2ZN2DFuWk{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._22bc-kkDntp3G2ZN2DFuWk{margin-inline-start:52px;margin-inline-end:52px}}._2HDLEpl4nytn7veH1ek_6w{margin-bottom:24px}._1cHmr102aEhfOG0D32r1zB{margin-top:var(--music-spacer-large);margin-bottom:0;cursor:default;-webkit-user-select:none;-ms-user-select:none;user-select:none}._1cHmr102aEhfOG0D32r1zB._148gOuv0N1CmYiiLgbkrtG{display:flex;justify-content:space-between;align-items:center}@media only screen and (max-width: 480px){._1cHmr102aEhfOG0D32r1zB music-button{margin-inline-end:20px}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_1zTBeS0u0009bjYjXl3Xmj",
	"gridSides": "_1zTBeS0u0009bjYjXl3Xmj",
	"header": "_1cHmr102aEhfOG0D32r1zB",
	"button-container": "_2HDLEpl4nytn7veH1ek_6w",
	"buttonContainer": "_2HDLEpl4nytn7veH1ek_6w",
	"grid-sides-margins": "_22bc-kkDntp3G2ZN2DFuWk",
	"gridSidesMargins": "_22bc-kkDntp3G2ZN2DFuWk",
	"action-btn": "_148gOuv0N1CmYiiLgbkrtG",
	"actionBtn": "_148gOuv0N1CmYiiLgbkrtG"
};