import { IWriteElement } from '../types/appsync/IAppSync';

export class PlaybackMetricWriteElement implements IWriteElement {
    public id: string;

    public mediaCollectionType: number;

    public playbackSignalType: string;

    public currentProgressMilliseconds: number;

    public playbackRequestedAtTimestampMilliseconds: number;

    public metricsPreset: string;

    public isMediaDownloaded: boolean;

    public currentPlaybackSpeed: number;

    public playbackStartOffsetMilliseconds: number;

    public playbackStartedAtTimestampMilliseconds: number;

    public initialPlaybackStartDelayMilliseconds: number;

    public rebufferDurationMilliseconds: number;

    public rebufferCount: number;

    public pageType: string;

    public audioUri: string;

    public __type: string;

    public interface?: string;

    public podcastShowVariantId: string;

    public podcastEpisodeVariantId: string;

    constructor(
        id,
        mediaCollectionType,
        playbackSignalType,
        currentProgressMilliseconds,
        playbackRequestedAtTimestampMilliseconds,
        metricsPreset,
        isMediaDownloaded,
        currentPlaybackSpeed,
        playbackStartOffsetMilliseconds,
        playbackStartedAtTimestampMilliseconds,
        initialPlaybackStartDelayMilliseconds,
        rebufferDurationMilliseconds,
        rebufferCount,
        pageType,
        audioUri,
        podcastShowVariantId,
        podcastEpisodeVariantId
    ) {
        this.id = id;
        this.mediaCollectionType = mediaCollectionType;
        this.playbackSignalType = playbackSignalType;
        this.currentProgressMilliseconds = currentProgressMilliseconds;
        this.playbackRequestedAtTimestampMilliseconds = playbackRequestedAtTimestampMilliseconds;
        this.metricsPreset = metricsPreset;
        this.isMediaDownloaded = isMediaDownloaded;
        this.currentPlaybackSpeed = currentPlaybackSpeed;
        this.playbackStartOffsetMilliseconds = playbackStartOffsetMilliseconds;
        this.playbackStartedAtTimestampMilliseconds = playbackStartedAtTimestampMilliseconds;
        this.initialPlaybackStartDelayMilliseconds = initialPlaybackStartDelayMilliseconds;
        this.rebufferDurationMilliseconds = rebufferDurationMilliseconds;
        this.rebufferCount = rebufferCount;
        this.pageType = pageType;
        this.audioUri = audioUri;
        this.podcastShowVariantId = podcastShowVariantId;
        this.podcastEpisodeVariantId = podcastEpisodeVariantId;
        this.__type =
            'Podcast.PlaybackMetricsInterface.v1_0#PlaybackMetricWriteElement';
        this.interface =
            'Podcast.Web.PlaybackMetricsInterface.PlaybackMetricWriteElement';
    }
}
