import { STORE_PROFILE_ID_METHOD, setAuthentication } from '../actions';
import { getOAuthToken } from '../utils';
export const ProfileMiddleware = (store) => (next) => async (action) => {
    next(action);
    switch (action.type) {
        case STORE_PROFILE_ID_METHOD: {
            const { profileId } = action.payload;
            const { accessToken, expiresAt } = await getOAuthToken(profileId);
            store.dispatch(setAuthentication({ accessToken, expiresAt }));
            break;
        }
        default:
            break;
    }
};
