import React from 'react';
import { useSelector, useStore } from 'react-redux';
import {
    BOOKMARKS_LOWER_BOUND_PROGRESS_MS,
    BOOKMARKS_UPPER_BOUND_PERCENTAGE,
} from 'src/appsync/AppSyncConstants';
import IApplicationState from 'src/types/IApplicationState';
import { SINGLE_THREADED_QUEUE } from 'src/utils/skyfireContants';
import { BookmarkDaoSessionStorage } from '../../Bookmark';
import {
    INVOKE_HTTP_POST,
    ISkyfireInvokeHttpPostMethod,
} from '../../types/ISkyfireInvokeHttpPostMethod';
import ISkyfireLinkElement from '../../types/ISkyfireLinkElement';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import IPodcastBookmarksShoveler from '../../types/templates/widgets/IPodcastBookmarksShoveler';
import {
    ISkyfireSquareVerticalItem,
    SQUARE_VERTICAL_ITEM,
} from '../../types/templates/widgets/items/ISkyfireVerticalItem';
import { getPodcastPlaybackEnvironment } from '../../utils';
import VerticalItem from './items/VerticalItem';
import {
    UIMetrics,
    EntityType,
    EntityIdType,
    ActionType,
    ContentName,
    PageType,
} from '../../UIMetrics';
import { PLAY_PODCAST_WEB_SKILL } from '../../utils/podcastTargets';
import { invokeHttpPostSkillMethod } from '../../utils/invokeHttpPostSkillMethod';
import { useItemsInView } from '../../utils/useItemsInView';
import { episodeDeeplink, podcastDeeplink } from '../../utils/deeplinks';

interface IPodcastBookmarksShovelerProps {
    data: IPodcastBookmarksShoveler;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    onViewed?: () => void;
}

export function PodcastBookmarksShoveler(
    props: IPodcastBookmarksShovelerProps
) {
    const callbackRef = useItemsInView(props.onViewed);
    const { header, seeMoreItem } = props.data;
    const { customerId } = useSelector(
        (state: IApplicationState) => state.Authentication
    );
    const storage = new BookmarkDaoSessionStorage(useStore());
    const items = storage
        .getAll(
            customerId,
            BOOKMARKS_LOWER_BOUND_PROGRESS_MS,
            BOOKMARKS_UPPER_BOUND_PERCENTAGE,
            true
        )
        .map((bookmarkEntry, index) => {
            const invokePlay: ISkyfireInvokeHttpPostMethod = {
                interface: INVOKE_HTTP_POST,
                queue: {
                    interface: SINGLE_THREADED_QUEUE,
                    id: 'PLAYBACK',
                },
                forced: false,
                before: [],
                after: [],
                onError: [],
                url: getPodcastPlaybackEnvironment(),
                preset: `{"podcastId":"${bookmarkEntry.podcastId}","startAtEpisodeId":"${bookmarkEntry.episodeId}"}`,
                target: PLAY_PODCAST_WEB_SKILL,
                extra: {},
            };
            let onPlaySelected: ISkyfireMethod[] = [invokePlay];
            onPlaySelected = onPlaySelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.PLAY_PODCAST_EPISODE,
                    contentName: ContentName.PODCASTS_BOOKMARKS_SHOVELER,
                    pageType: PageType.PODCASTS_HOME,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            let onEpisodeSelected: ISkyfireMethod[] = [
                // @ts-ignore
                invokeHttpPostSkillMethod(
                    `/podcasts/${bookmarkEntry.podcastId}/episodes/${bookmarkEntry.episodeId}`
                ),
            ];
            onEpisodeSelected = onEpisodeSelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.SELECT_PODCAST_EPISODE,
                    contentName: ContentName.PODCASTS_BOOKMARKS_SHOVELER,
                    pageType: PageType.PODCASTS_HOME,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            let onPodcastSelected: ISkyfireMethod[] = [
                // @ts-ignore
                invokeHttpPostSkillMethod(
                    `/podcasts/${bookmarkEntry.podcastId}`
                ),
            ];
            onPodcastSelected = onPodcastSelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.GO_PODCAST_SHOW,
                    contentName: ContentName.PODCASTS_BOOKMARKS_SHOVELER,
                    pageType: PageType.PODCASTS_HOME,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            const playLink: ISkyfireLinkElement = {
                deeplink: podcastDeeplink(bookmarkEntry),
                onItemSelected: onPlaySelected,
            };
            const episodeLink: ISkyfireLinkElement = {
                deeplink: episodeDeeplink(bookmarkEntry),
                onItemSelected: onEpisodeSelected,
            };
            const podcastLink: ISkyfireLinkElement = {
                deeplink: podcastDeeplink(bookmarkEntry),
                onItemSelected: onPodcastSelected,
            };
            // @ts-ignore
            const item: ISkyfireSquareVerticalItem = {
                image: bookmarkEntry.podcastImage,
                imageAltText: bookmarkEntry.title,
                primaryLink: episodeLink,
                secondaryText: bookmarkEntry.podcastTitle,
                secondaryLink: podcastLink,
                actionIconName: 'play',
                actionIconLink: playLink,
                primaryText: {
                    text: bookmarkEntry.title,
                    disabled: false,
                },
                interface: SQUARE_VERTICAL_ITEM,
            };
            return item;
        });

    if (items?.length) {
        return (
            <div>
                <music-shoveler
                    key={header}
                    see-all={items.length >= 7 ? seeMoreItem?.text : undefined}
                    primary-text={header}
                    onmusicSeeAllActivate={props.handleSelected.bind(
                        null,
                        seeMoreItem?.primaryLink?.onItemSelected
                    )}
                >
                    {items.map((item, i) => (
                        <VerticalItem
                            data={item}
                            handleSelected={props.handleSelected}
                            ref={callbackRef}
                            key={i}
                        />
                    ))}
                </music-shoveler>
            </div>
        );
    }

    return null;
}

function getEpisodeEntityId(element) {
    const {
        isRedhoodPlusMetricsEnabled,
        isRedhoodSonicRushEnabled,
    } = window.amznMusic.appConfig;
    return element.podcastEpisodeVariantId &&
        isRedhoodPlusMetricsEnabled &&
        isRedhoodSonicRushEnabled
        ? element.podcastEpisodeVariantId
        : element.episodeId;
}
