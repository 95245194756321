import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RWP_MOBILE_DEVICE_TYPES } from '../../utils/deviceTypes';
import { dispatchPlaybackMethods } from '../../utils/dispatchPlaybackMethods';
import { globals } from '../../utils';
export default function TransportDownloadButton(props) {
    const dispatch = useDispatch();
    const onTransportDownloadButtonRequired = useSelector((state) => state.Media.onTransportDownloadButtonRequired);
    const transportDownloadButton = useSelector((state) => state.Media.transportDownloadButton);
    const onTransportDownloadButtonViewed = useSelector((state) => state.Media.onTransportDownloadButtonViewed);
    const mediaId = useSelector((state) => state.Media.mediaId);
    const [isDownloadButtonViewed, setIsDownloadButtonViewed] = useState(false);
    const isRWPMobileDevice = RWP_MOBILE_DEVICE_TYPES.includes(globals.amznMusic.appConfig.deviceType);
    const shouldDispatchDownloadButtonMethods = isRWPMobileDevice ? props.isNPVShowing : true;
    const showButtonForRWPMobileDevice = !!transportDownloadButton && props.isNPVShowing;
    useEffect(() => {
        if ((onTransportDownloadButtonRequired === null || onTransportDownloadButtonRequired === void 0 ? void 0 : onTransportDownloadButtonRequired.length) &&
            !transportDownloadButton &&
            shouldDispatchDownloadButtonMethods) {
            dispatchPlaybackMethods(dispatch, mediaId, onTransportDownloadButtonRequired);
        }
        if (transportDownloadButton && !isDownloadButtonViewed) {
            setIsDownloadButtonViewed(true);
            dispatchPlaybackMethods(dispatch, mediaId, onTransportDownloadButtonViewed);
        }
    }, [mediaId, transportDownloadButton, props.isNPVShowing]);
    const showTransportDownloadButton = isRWPMobileDevice
        ? showButtonForRWPMobileDevice
        : !!transportDownloadButton;
    return showTransportDownloadButton ? (React.createElement("music-button", { onmusicActivate: handleClick, "icon-name": isRWPMobileDevice ? null : transportDownloadButton === null || transportDownloadButton === void 0 ? void 0 : transportDownloadButton.icon, variant: "outline", refinement: "none", class: "hydrated", ariaLabelText: transportDownloadButton === null || transportDownloadButton === void 0 ? void 0 : transportDownloadButton.text }, transportDownloadButton === null || transportDownloadButton === void 0 ? void 0 : transportDownloadButton.text)) : null;
    function handleClick(e) {
        dispatchPlaybackMethods(dispatch, mediaId, transportDownloadButton === null || transportDownloadButton === void 0 ? void 0 : transportDownloadButton.onItemSelected);
    }
}
