import { ShowUpsellEvent, UPSELL_TRIGGERS, subscribe } from '@amzn/DMWebEventBus';
import { getSkyfireEnvironment } from '../utils';
import { invokeHttp } from './utils';
export const handleShowUpsell = (store) => (reason) => {
    var _a;
    const trigger = (_a = UPSELL_TRIGGERS[reason]) !== null && _a !== void 0 ? _a : UPSELL_TRIGGERS.DEFAULT;
    const skyfireEnv = getSkyfireEnvironment();
    const url = `${skyfireEnv}/api/showDynamicMessage?event=${trigger}`;
    invokeHttp(store, url);
};
export function registerUpsellModule(store) {
    subscribe(ShowUpsellEvent, handleShowUpsell(store));
}
