export const SHIFT_CLICK = 'SHIFT_CLICK';
export const REORDER_TRACK = 'REORDER_TRACK';
export const REORDER_PLAY_QUEUE = 'REORDER_PLAY_QUEUE';
export const REMOVE_FROM_PLAY_QUEUE = 'REMOVE_FROM_PLAY_QUEUE';
export const TEMPLATE_RENDERED = 'TEMPLATE_RENDERED';
export const ADD_ITEMS_TO_WIDGET_BY_ID = 'Web.PageInterface.v1_0.AddItemsToWidgetMethod';
export const ADD_VERTICAL_ITEMS_TO_LAST_WIDGET = 'Web.PageInterface.v1_0.AddVerticalItemsToLastWidgetMethod';
export const ADD_HORIZONTAL_ITEMS_TO_LAST_WIDGET = 'Web.PageInterface.v1_0.AddHorizontalItemsToLastWidgetMethod';
export const REPLACE_HORIZONTAL_ITEMS_IN_WIDGET = 'Web.PageInterface.v1_0.ReplaceHorizontalItemsInWidgetMethod';
export const ADD_VISUAL_ROW_ITEMS_TO_LAST_WIDGET = 'Web.PageInterface.v1_0.AddVisualRowItemsToLastWidgetMethod';
export const ADD_VISUAL_ROW_ITEMS_TO_FIRST_WIDGET = 'Web.PageInterface.v1_0.AddVisualRowItemsToFirstWidgetMethod';
export const ADD_EPISODE_ROW_ITEMS_TO_LAST_WIDGET = 'Web.PageInterface.v1_0.AddEpisodeRowItemsToLastWidgetMethod';
export const ADD_EPISODE_GROUP_ITEMS_TO_LAST_WIDGET = 'Web.PageInterface.v1_0.AddEpisodeGroupItemsToLastWidgetMethod';
export const ADD_VISUAL_ROW_ITEMS_TO_WIDGET_START = 'Web.PageInterface.v1_0.AddVisualRowItemsToWidgetStartMethod';
export const ADD_DESCRIPTIVE_ROW_ITEMS_TO_LAST_WIDGET = 'Web.PageInterface.v1_0.AddDescriptiveRowItemsToLastWidgetMethod';
export const ADD_WIDGETS_TO_TEMPLATE = 'Web.PageInterface.v1_0.AddWidgetsToTemplateMethod';
export const REMOVE_ROW_ITEM_FROM_WIDGET = 'Web.PageInterface.v1_0.RemoveRowItemFromWidgetMethod';
export const REMOVE_ITEM_FROM_WIDGET_BY_ID = 'Web.PageInterface.v1_0.RemoveItemFromWidgetByIdMethod';
export const REPLACE_ROW_ITEM_FROM_LAST_WIDGET = 'Web.PageInterface.v1_0.ReplaceRowItemWithRemainingForLastWidgetMethod';
export const CREATE_TEMPLATE = 'TemplateListInterface.v1_0.CreateTemplateMethod';
export const BIND_TEMPLATE = 'TemplateListInterface.v1_0.BindTemplateMethod';
export const CREATE_AND_BIND_TEMPLATE = 'TemplateListInterface.v1_0.CreateAndBindTemplateMethod';
export const URL_CHANGE = 'URL_CHANGE';
export const REMOVE_TEMPLATE = 'TemplateListInterface.v1_0.RemoveTemplateMethod';
export const CLEAR_OVERLAY_TEMPLATES = 'TemplateListInterface.v1_0.ClearOverlayTemplatesMethod';
export const INVALIDATE_TEMPLATE = 'TemplateListInterface.v1_0.InvalidateTemplatesMethod';
export const SOFT_REFRESH_TEMPLATES = 'Web.TemplateListInterface.v1_0.SoftRefreshTemplatesMethod';
