import { Store } from 'redux';
import { WEB_STORAGE } from '../appsync/AppSyncConstants';
import IApplicationState from '../types/IApplicationState';
import { ISaveDao } from './ISaveDao';
import { Save } from './Save';
import { SAVE_EPISODE } from './SaveActions';

export class SaveDaoSessionStorage implements ISaveDao {
    private store: Store<IApplicationState>;

    constructor(store) {
        this.store = store;
    }

    public insert(save: Save) {
        this.store.dispatch({
            type: SAVE_EPISODE,
            payload: { save },
        });
    }

    public getSaveEpisode(
        episodeId: any,
        customerId: string
    ): Save | undefined {
        const key = `${episodeId}_Save`;
        const save = WEB_STORAGE.getItem(key);
        if (save) {
            return new Save(JSON.parse(save));
        }
        return undefined;
    }

    public getAll(
        customerId: string,
        isSaved: boolean,
        isLimited?: boolean
    ): Save[] {
        let isSavedList: Save[] = [];
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (key.endsWith('Save')) {
                const save = JSON.parse(value);
                if (save && save.isSaved === isSaved) {
                    isSavedList.push(new Save(save));
                }
            }
        }
        isSavedList = isSavedList.sort((a, b) =>
            a.updatedTime < b.updatedTime ? 1 : -1
        );
        if (isLimited === true) {
            isSavedList = isSavedList.slice(0, 20);
            return isSavedList;
        }
        return isSavedList;
    }

    public getLastSyncTime(customerId: string): number {
        const key = 'savesLastSyncTime';
        const lastSyncTime = Number(WEB_STORAGE.getItem(key));
        return lastSyncTime || 0;
    }

    public clearSaveData(customerId: string) {
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (key.endsWith('Save') || key.endsWith('savesLastSyncTime')) {
                WEB_STORAGE.removeItem(key);
            }
        }
    }
}
