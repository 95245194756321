import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import * as Styles from './FullScreenButton.scss';
import { globals } from '../utils';
export const onFullscreenToggle = (isFullscreen) => {
    const elementId = globals.amznMusic.isInContainerApp ? 'container' : 'root';
    const fullscreenContainer = document.getElementById(elementId);
    if (isFullscreen) {
        exitFullscreen();
    }
    else {
        enterFullscreen(fullscreenContainer);
    }
};
export const enterFullscreen = (fullscreenContainer) => {
    if (!fullscreenContainer)
        return;
    //  Enter fullscreen
    if (fullscreenContainer.requestFullscreen) {
        fullscreenContainer.requestFullscreen();
    }
    else if (fullscreenContainer.webkitRequestFullscreen) {
        //  Safari
        fullscreenContainer.webkitRequestFullscreen();
    }
    else if (fullscreenContainer.msRequestFullscreen) {
        //  IE11
        fullscreenContainer.msRequestFullscreen();
    }
    else if (fullscreenContainer.mozRequestFullScreen) {
        fullscreenContainer.mozRequestFullScreen();
    }
};
export const exitFullscreen = () => {
    const fullscreenContainer = document;
    if (!fullscreenContainer)
        return;
    //  Exit fullscreen
    if (fullscreenContainer.exitFullscreen) {
        fullscreenContainer.exitFullscreen();
    }
    else if (fullscreenContainer.webkitExitFullscreen) {
        //  Safari
        fullscreenContainer.webkitExitFullscreen();
    }
    else if (fullscreenContainer.msExitFullscreen) {
        //  IE11
        fullscreenContainer.msExitFullscreen();
    }
    else if (fullscreenContainer.mozCancelFullScreen) {
        fullscreenContainer.mozCancelFullScreen();
    }
};
const isFullscreen = () => {
    const fullscreenContainer = document;
    const browserFullscreenElement = fullscreenContainer.fullscreenElement ||
        fullscreenContainer.webkitFullscreenElement ||
        fullscreenContainer.mozFullScreenElement ||
        fullscreenContainer.msFullscreenElement;
    const elementId = globals.amznMusic.isInContainerApp ? 'container' : 'root';
    const rootElement = document.getElementById(elementId);
    return browserFullscreenElement !== null && browserFullscreenElement === rootElement;
};
export default function FullScreenButton(props) {
    useStyles(Styles);
    // TODO change text to be rendered for all locales
    const text = 'Fullscreen';
    const isNPVinFullscreen = isFullscreen();
    const selectedClass = isNPVinFullscreen
        ? Styles.exitFullscreenComponent
        : Styles.enterFullscreenComponent;
    return (React.createElement("div", { className: selectedClass },
        React.createElement("music-button", { ariaLabelText: text, onmusicActivate: handleClick, variant: props.variant || 'primary', "icon-name": isNPVinFullscreen ? 'contract' : 'expand', "icon-only": true, disabled: props.disabled, size: props.size || 'medium' })));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClick(e) {
        if (!e.target.disabled) {
            onFullscreenToggle(isNPVinFullscreen);
        }
    }
}
