import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDesktop } from '../utils/platform';
import { dispatchSkyfireMethods } from '../utils';
import { useInView } from '../utils/useItemsInView';
import * as Styles from './Notification.scss';
import TextWithLink from './widgets/items/TextWithLink';
import { MSHOP, RWP_BROWSER_DEVICE_TYPES, RWP_MINI_MSHOP } from '../utils/deviceTypes';
import { globals } from '../utils/globals';
export default function NotificationRibbon(props) {
    var _a, _b, _c, _d, _e, _f;
    useStyles(Styles);
    const dispatch = useDispatch();
    const ribbon = useSelector((state) => state.Ribbon.ribbon);
    const mediaId = useSelector((state) => state.Media.mediaId);
    const overlayTemplates = useSelector((state) => state.TemplateStack.overlayTemplates);
    const mShopBottomOverride = useSelector((state) => state.RWP.mShopBottomOverride);
    const enableMShopBottomOverride = useSelector((state) => state.RWP.enableMShopBottomOverride);
    const [mobileTransportBottom, setMobileTransportBottom] = useState(85);
    const [mShopBottomPositionOverride, setMShopBottomOverride] = useState(0);
    const hasOverlay = overlayTemplates.length > 0;
    const closeCallback = useCallback((event) => {
        if (!ribbon)
            return;
        event === null || event === void 0 ? void 0 : event.preventDefault();
        const { onItemSelected } = ribbon.closeButton;
        dispatchSkyfireMethods(dispatch, props.template, onItemSelected);
    }, [dispatch, ribbon]);
    const leftButtonCallback = useCallback((event) => {
        if (!ribbon)
            return;
        event === null || event === void 0 ? void 0 : event.preventDefault();
        const { onItemSelected } = ribbon.leftButton.primaryLink;
        dispatchSkyfireMethods(dispatch, props.template, onItemSelected);
    }, [dispatch, ribbon]);
    const rightButtonCallback = useCallback((event) => {
        if (!ribbon)
            return;
        event === null || event === void 0 ? void 0 : event.preventDefault();
        const { onItemSelected } = ribbon.rightButton.primaryLink;
        dispatchSkyfireMethods(dispatch, props.template, onItemSelected);
    }, [dispatch, ribbon]);
    const leftCloseButtonCallback = useCallback((event) => {
        if (!ribbon)
            return;
        event === null || event === void 0 ? void 0 : event.preventDefault();
        const { onItemSelected } = ribbon.leftCloseButton;
        dispatchSkyfireMethods(dispatch, props.template, onItemSelected);
    }, [dispatch, ribbon]);
    const onItemSelectedCallback = useCallback((event) => {
        if (!ribbon)
            return;
        event === null || event === void 0 ? void 0 : event.preventDefault();
        const { onItemSelected } = ribbon;
        dispatchSkyfireMethods(dispatch, props.template, onItemSelected);
    }, [dispatch, ribbon]);
    useEffect(() => {
        if (!ribbon)
            return () => { };
        const timer = setTimeout(() => {
            // use the leftCloseButton first if that exist
            if (ribbon.leftCloseButton != null) {
                leftCloseButtonCallback();
            }
            else {
                closeCallback();
            }
        }, ribbon.timeoutMilliseconds);
        return () => clearTimeout(timer);
    }, [ribbon]);
    useEffect(() => {
        if (enableMShopBottomOverride) {
            const bottomValue = mShopBottomOverride + 85;
            setMobileTransportBottom(bottomValue);
            setMShopBottomOverride(mShopBottomOverride);
        }
        else {
            setMobileTransportBottom(85);
            setMShopBottomOverride(0);
        }
    }, [enableMShopBottomOverride, mShopBottomOverride]);
    const onViewed = useCallback(() => {
        if (ribbon === null || ribbon === void 0 ? void 0 : ribbon.onViewed) {
            dispatchSkyfireMethods(dispatch, props.template, ribbon.onViewed);
        }
    }, [dispatch, ribbon === null || ribbon === void 0 ? void 0 : ribbon.onViewed]);
    const callbackRef = useInView(onViewed);
    if (!ribbon ||
        (hasOverlay &&
            overlayTemplates[0].interface !== props.template.interface &&
            !props.isNowPlaying)) {
        return null;
    }
    const leftButton = ribbon.leftButton && (React.createElement("music-button", { href: ribbon.leftButton.primaryLink.deeplink, onClick: leftButtonCallback, slot: "buttons", variant: "outline" }, ribbon.leftButton.text));
    const rightButton = ribbon.rightButton && (React.createElement("music-button", { href: ribbon.rightButton.primaryLink.deeplink, onClick: rightButtonCallback, slot: "buttons", variant: "solid" }, ribbon.rightButton.text));
    let transportHeight;
    if (!isDesktop(window)) {
        transportHeight = props.isNowPlaying ? mShopBottomPositionOverride : mobileTransportBottom;
    }
    else {
        transportHeight = props.isNowPlaying ? 125 : 85;
    }
    const bottomPosition = mediaId ? transportHeight : mShopBottomPositionOverride;
    const { deviceType } = globals.amznMusic.appConfig;
    let rwpRibbonStyleOverride;
    if (RWP_BROWSER_DEVICE_TYPES.includes(deviceType)) {
        rwpRibbonStyleOverride = Styles.rwpZIndexOverride;
    }
    else if (!RWP_MINI_MSHOP.includes(deviceType) && MSHOP.includes(deviceType)) {
        rwpRibbonStyleOverride = Styles.rwpMshopZIndexOverride;
    }
    else {
        rwpRibbonStyleOverride = '';
    }
    const notificationRibbonClassName = [
        Styles.musicNotificationRibbon,
        rwpRibbonStyleOverride,
    ].join(' ');
    return (React.createElement("music-notification-ribbon", { ref: callbackRef, className: notificationRibbonClassName, style: {
            bottom: `${bottomPosition}px`,
        }, kind: "accent", closable: !!(ribbon === null || ribbon === void 0 ? void 0 : ribbon.closeButton), closeButtonIcon: (_a = ribbon === null || ribbon === void 0 ? void 0 : ribbon.closeButton) === null || _a === void 0 ? void 0 : _a.icon, leftCloseButtonIcon: (_b = ribbon === null || ribbon === void 0 ? void 0 : ribbon.leftCloseButton) === null || _b === void 0 ? void 0 : _b.icon, onmusicRibbonCloseButtonActivate: closeCallback, onmusicRibbonLeftCloseButtonActivate: leftCloseButtonCallback, onClick: onItemSelectedCallback }, (_d = (_c = ribbon.message) === null || _c === void 0 ? void 0 : _c.text) !== null && _d !== void 0 ? _d : (_f = (_e = ribbon.message) === null || _e === void 0 ? void 0 : _e.textWithLinks) === null || _f === void 0 ? void 0 : _f.map((textWithLinkElement) => (React.createElement(TextWithLink, { data: textWithLinkElement, template: props.template }))),
        leftButton,
        rightButton));
}
