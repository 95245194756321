import useStyles from 'isomorphic-style-loader/useStyles';
import React, { Fragment, useEffect, useLayoutEffect, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'debounce';
import BackgroundImage from '../components/BackgroundImage';
import ContextMenuButton from '../components/ContextMenuButton';
import { LoadingSpinner } from '../components/LoadingSpinner';
import RefinementHeader from '../components/RefinementHeader';
import Button, { placeholderSizeMap } from '../components/widgets/items/Button';
import WidgetList from '../components/widgets/WidgetList';
import { dispatchSkyfireMethods, dispatchTemplateRendered, preventDefault } from '../utils';
import { useObserver, useObservers } from '../utils/ObserverHooks';
import * as Styles from './View.scss';
import * as detailStyles from './Detail.scss';
import { computeContainerWidth } from '../utils/gridHelpers';
import { useSourceCasing } from '../utils/globals';
import { spacersSizeMap } from '../utils/webUIVariables';
import { checkTouch } from '../utils/platform';
import { getDeeplink } from '../utils/getDeeplink';
import { WINDOW_SIZE_ENUM } from '../types/IWindowSize';
import HintedButton from '../components/widgets/items/HintedButton';
import { TemplateType } from '../utils/Enums';
import UpsellButton from '../components/widgets/items/UpsellButton';
import Histogram from '../components/widgets/Histogram';
import TabsItemsGroupWidget from '../components/widgets/TabsItemsGroupWidget';
import DescriptiveChildrenWrapper from '../components/DescriptiveChildrenWrapper';
import * as textStyles from '../styles/text.scss';
import TextClamp from '../components/TextClamp';
import scrollToSection from '../utils/tabsUtils';
import Toggle from '../components/widgets/items/Toggle';
import { VISUAL_TABLE_WIDGET } from '../types/templates/widgets/ISkyfireVisualTableWidget';
export default function Detail(props) {
    var _a;
    const dispatch = useDispatch();
    const targetRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [hasHeaderRendered, setHasHeaderRendered] = useState(false);
    const details = 'details';
    useLayoutEffect(() => {
        if (targetRef.current) {
            const { offsetWidth } = targetRef.current;
            setWidth(offsetWidth);
        }
    }, []);
    useEffect(() => {
        const debouncedResize = debounce(() => {
            setWidth(window.innerWidth);
        }, 10);
        window.addEventListener('resize', debouncedResize);
        return () => {
            window.removeEventListener('resize', debouncedResize);
        };
    });
    const { widgets, headerImage, headerImageKind, headerImageDimension, backgroundImage, contextMenu, headerText, headerButtons, headerIsEmptyTemplate, headerEmptyTemplatePrimaryText, templatePrimaryText, purchaseOptions, headerPrimaryText, headerSecondaryText, headerPrimaryText2, headerTertiaryText, headerPrimaryTextLink, headerSecondaryTextLink, headerNavigationTexts, headerNavigationTextLinks, headerBadges, headerBadgeLabel, headerLabel, id, footer, isSmallHeaderCentered, updatedAt, upsellButtonElement, toggleElement, refinementHeader, isLargeFormat, shouldHideContent, templateType, ratingsHistogram, tabs, entityDetails, } = props.template;
    const key = [id, updatedAt].join('-');
    useStyles(detailStyles, Styles, textStyles);
    const handleSelected = useCallback((onItemSelected) => dispatchSkyfireMethods(dispatch, props.template, onItemSelected), [dispatch, props.template]);
    const headerTextElement = useObserver(headerText);
    const headerButtonsRow1 = [];
    const headerButtonsRow2 = [];
    const detailHeaderWidth = Math.max(0, computeContainerWidth(Math.max(width, 320)));
    let currentWidth = 0;
    if (purchaseOptions) {
        // image container size is calculated for button placement
        // not calculated for breakpoint LG devices as the image and detail breaks to 2 rows
        const imageContainerWidth = width > WINDOW_SIZE_ENUM.LG ? computeImageContainerSize() : 0;
        currentWidth += imageContainerWidth;
    }
    const spacerSize = spacersSizeMap.small;
    const isMobile = checkTouch(window);
    const textButtonsIndexes = [];
    const textButtons = [];
    const textButtonsMap = {};
    const visualTableWidget = widgets.find((widget) => widget.interface === VISUAL_TABLE_WIDGET);
    const tracksCount = (_a = visualTableWidget === null || visualTableWidget === void 0 ? void 0 : visualTableWidget.items) === null || _a === void 0 ? void 0 : _a.length;
    const isHeaderButtonDisabled = visualTableWidget && !(tracksCount && tracksCount > 0);
    headerButtons.forEach((button, idx) => {
        if (!button.iconOnly) {
            textButtonsIndexes.push(idx);
            textButtons.push(button);
        }
    });
    const textButtonsProps = useObservers(textButtons);
    for (let i = 0; i < textButtonsIndexes.length; i++) {
        // map text button indexes to their properties
        textButtonsMap[textButtonsIndexes[i]] = textButtonsProps[i];
    }
    headerButtons.forEach((button, idx) => {
        var _a;
        let buttonSize;
        let textSize = 0;
        if (button.iconOnly) {
            buttonSize = isMobile || idx === 0 ? 'medium' : 'small';
        }
        else {
            const text = ((_a = textButtonsMap[idx]) === null || _a === void 0 ? void 0 : _a.text) || '';
            // Approximating px size per character is ~7.2-7.5, so rounding up to 8 to be safe
            // Due to this  issue(https://issues.amazon.com/issues/DMSTORE-13904) px per
            // japanese character should be increased to 12
            textSize = text.length * 12;
            buttonSize = isMobile ? 'large' : 'medium';
        }
        let buttonElement;
        if (idx === 0 && 'hintIcon' in button && button.hintIcon != null) {
            buttonSize = 'large';
            buttonElement = (React.createElement("span", { className: detailStyles.detailButtonSlot },
                React.createElement(HintedButton, { data: button, handleSelected: handleSelected, id: `detailHeaderButton${idx + 1}`, slot: "icons", primaryIcon: button.icon, hintIcon: button.hintIcon, size: buttonSize })));
        }
        else {
            const variant = idx === 0 ? 'solid' : 'primary';
            buttonElement = (React.createElement("span", { className: detailStyles.detailButtonSlot },
                React.createElement(Button, { data: button, isDisabled: isHeaderButtonDisabled, handleSelected: handleSelected, id: `detailHeaderButton${idx + 1}`, slot: "icons", size: buttonSize, variant: button.outline ? 'outline' : variant })));
        }
        currentWidth +=
            placeholderSizeMap[button.iconOnly ? 'medium' : 'large'] + spacerSize + textSize;
        if (currentWidth >= detailHeaderWidth) {
            headerButtonsRow2.push(buttonElement);
        }
        else {
            headerButtonsRow1.push(buttonElement);
        }
        if (idx === 0 && purchaseOptions) {
            const purchaseTextSize = (purchaseOptions === null || purchaseOptions === void 0 ? void 0 : purchaseOptions.text) ? purchaseOptions.text.length * 12 : 0;
            const purchaseButtonSize = isMobile ? 'large' : 'medium';
            const purchaseOptionsButton = (React.createElement("span", { className: detailStyles.detailButtonSlot },
                React.createElement(ContextMenuButton, { id: "purchaseOptionsButton", text: purchaseOptions === null || purchaseOptions === void 0 ? void 0 : purchaseOptions.text, postFixIconName: purchaseOptions === null || purchaseOptions === void 0 ? void 0 : purchaseOptions.postfixIcon, options: purchaseOptions === null || purchaseOptions === void 0 ? void 0 : purchaseOptions.options, disabled: purchaseOptions === null || purchaseOptions === void 0 ? void 0 : purchaseOptions.disabled, onItemSelected: purchaseOptions === null || purchaseOptions === void 0 ? void 0 : purchaseOptions.onItemSelected, slot: "icons", variant: "outline-refinement", size: purchaseButtonSize })));
            currentWidth += placeholderSizeMap.large + spacerSize + purchaseTextSize;
            if (currentWidth >= detailHeaderWidth) {
                headerButtonsRow2.push(purchaseOptionsButton);
            }
            else {
                headerButtonsRow1.push(purchaseOptionsButton);
            }
        }
    });
    if (contextMenu) {
        const contextMenuElement = (React.createElement(ContextMenuButton, { id: "detailHeaderContextButton", options: contextMenu.options, disabled: contextMenu.disabled, slot: "icons", variant: "primary", iconName: "more", size: checkTouch(window) ? 'medium' : 'small' }));
        currentWidth += placeholderSizeMap.medium + spacerSize;
        if (currentWidth >= detailHeaderWidth) {
            headerButtonsRow2.push(contextMenuElement);
        }
        else {
            headerButtonsRow1.push(contextMenuElement);
        }
    }
    const viewContentClassName = [
        Styles.viewContent,
        window.amznMusic.isInContainerApp ? Styles.noNav : '',
    ].join(' ');
    const primaryText = (isHeaderButtonDisabled && headerEmptyTemplatePrimaryText) || templatePrimaryText;
    const renderAwards = () => (React.createElement("div", { className: detailStyles.awardsContainer },
        React.createElement("div", { className: textStyles.heading }, entityDetails === null || entityDetails === void 0 ? void 0 : entityDetails.awardsHeading), entityDetails === null || entityDetails === void 0 ? void 0 :
        entityDetails.awards.map((award, index) => (React.createElement("div", { className: detailStyles.awardItem, key: index },
            React.createElement("span", { className: textStyles.primaryText2 }, award.yearOfAward),
            React.createElement("span", { className: textStyles.primaryText2 }, award.status),
            React.createElement("span", { className: textStyles.secondaryText }, award.awardType),
            React.createElement("br", null),
            React.createElement("span", { className: [textStyles.secondaryText, textStyles.marginBottom].join(' ') }, award.awardCategory))))));
    const renderLops = () => (React.createElement("div", { className: detailStyles.languageContainer },
        React.createElement("div", { className: textStyles.heading }, entityDetails === null || entityDetails === void 0 ? void 0 : entityDetails.languageOfPerformanceHeading),
        React.createElement("span", { className: textStyles.secondaryText }, entityDetails === null || entityDetails === void 0 ? void 0 : entityDetails.languageOfPerformances.join(', '))));
    const renderBiography = () => (React.createElement("div", { className: detailStyles.biographyContainer },
        React.createElement("div", { className: textStyles.heading }, entityDetails === null || entityDetails === void 0 ? void 0 : entityDetails.biographyHeading),
        React.createElement("div", { className: textStyles.bodyText, id: "bioText" }, entityDetails === null || entityDetails === void 0 ? void 0 : entityDetails.biographies.map((bio, index) => (React.createElement(TextClamp, { key: `${bio.textClampCta}-${index}`, readMoreText: bio.textUnclampCta, readLessText: bio.textClampCta, linesToClamp: 2, handleOnClick: () => scrollToSection(details), text: bio.text }))))));
    return (React.createElement("div", { className: viewContentClassName, ref: targetRef },
        React.createElement(BackgroundImage, { src: backgroundImage }),
        React.createElement(DescriptiveChildrenWrapper, { data: props.template, handleSelected: handleSelected },
            React.createElement("div", { id: "atf" },
                templateType !== TemplateType.SR_Template.valueOf() && (React.createElement("div", { style: {
                        minHeight: '40px',
                        paddingTop: '10px',
                    } }, refinementHeader && (React.createElement(RefinementHeader, { text: refinementHeader.text, refinementOptions: refinementHeader.refinementOptions, isActive: refinementHeader.isActive, isDisabled: refinementHeader.isDisabled, isOpened: refinementHeader.isOpened, useFilter: refinementHeader.useFilter })))),
                React.createElement("music-detail-header", { key: headerImage, style: {
                        contain: 'layout',
                        marginTop: spacersSizeMap.large,
                        display: 'block',
                    }, "image-src": headerImage, "image-dimen": headerImageDimension || '1:1', label: headerLabel, badgeLabel: headerBadgeLabel, headline: headerTextElement.text || headerText, "primary-text": headerPrimaryText, "primary-text-href": getDeeplink(headerPrimaryTextLink === null || headerPrimaryTextLink === void 0 ? void 0 : headerPrimaryTextLink.deeplink), "primary-text-2": headerPrimaryText2, onmusicPrimaryTextActivate: handleSelected.bind(this, headerPrimaryTextLink === null || headerPrimaryTextLink === void 0 ? void 0 : headerPrimaryTextLink.onItemSelected), "secondary-text": headerSecondaryText, "secondary-text-href": getDeeplink(headerSecondaryTextLink === null || headerSecondaryTextLink === void 0 ? void 0 : headerSecondaryTextLink.deeplink), onmusicSecondaryTextActivate: handleSelected.bind(this, headerSecondaryTextLink === null || headerSecondaryTextLink === void 0 ? void 0 : headerSecondaryTextLink.onItemSelected), "tertiary-text": headerTertiaryText, badges: headerBadges, onClick: preventDefault, useSourceCasing: useSourceCasing(), isSmallHeaderCentered: isSmallHeaderCentered, "image-kind": headerImageKind, isLargeFormat: isLargeFormat, shouldHideContent: shouldHideContent, templateType: templateType, onrendered: () => setHasHeaderRendered(true) },
                    React.createElement("div", { slot: "icons" }, headerButtonsRow1),
                    headerButtonsRow2.length > 0 && (React.createElement("div", { slot: "icons", className: detailStyles.detailButtonsSecondaryRow }, headerButtonsRow2)),
                    headerNavigationTexts && (React.createElement("div", { className: "nav-container", slot: "navLinks" }, headerNavigationTexts === null || headerNavigationTexts === void 0 ? void 0 : headerNavigationTexts.map((item, idx) => {
                        var _a, _b, _c;
                        return (React.createElement("music-link", { disabled: !(headerNavigationTextLinks &&
                                getDeeplink((_a = headerNavigationTextLinks[idx]) === null || _a === void 0 ? void 0 : _a.deeplink)), title: item, href: headerNavigationTextLinks
                                ? getDeeplink((_b = headerNavigationTextLinks[idx]) === null || _b === void 0 ? void 0 : _b.deeplink)
                                : undefined, onClick: handleSelected.bind(this, headerNavigationTextLinks
                                ? (_c = headerNavigationTextLinks[idx]) === null || _c === void 0 ? void 0 : _c.onItemSelected
                                : undefined) },
                            React.createElement("span", { className: "fakeLink" }, item)));
                    })))),
                React.createElement("div", { className: "detail-button-container" },
                    upsellButtonElement && (React.createElement(UpsellButton, { data: upsellButtonElement, handleSelected: handleSelected })),
                    toggleElement && (React.createElement("div", { className: "toggle-container" },
                        React.createElement(Toggle, { data: toggleElement, handleSelected: handleSelected, handleOnTextWithLinkSelected: handleSelected }))))),
            tabs && React.createElement(TabsItemsGroupWidget, { data: tabs, handleSelected: handleSelected }),
            (widgets === null || widgets === void 0 ? void 0 : widgets.length) ? (React.createElement("music-container", { id: id, key: key, onrendered: widgetsRendered, className: templateType },
                primaryText && (React.createElement(Fragment, null,
                    React.createElement("div", { className: detailStyles.emptyTexts },
                        React.createElement("div", { className: `primary-text ${detailStyles.primaryText}` }, primaryText)),
                    React.createElement("div", { className: detailStyles.emptyTextsVertical },
                        React.createElement("div", { className: `secondary-text ${detailStyles.secondaryText}` }, primaryText)))),
                React.createElement(WidgetList, { list: widgets, handleSelected: handleSelected, 
                    // perform widget scroll only after header is rendered so that
                    // we calculate the correct Y-offset for the scroll
                    canScroll: hasHeaderRendered }))) : (React.createElement(LoadingSpinner, null)),
            footer && (React.createElement("div", { className: Styles.footer },
                React.createElement("span", { className: "music-tertiary-text" }, footer))),
            tabs && (React.createElement(Fragment, null,
                React.createElement("music-divider", null),
                React.createElement("music-container", { className: detailStyles.details, id: details },
                    React.createElement("div", { className: detailStyles.albumDetails },
                        shouldRenderBiography() && renderBiography(),
                        shouldRenderAwards() && renderAwards(),
                        shouldRenderLanguageOfPerformances() && renderLops()),
                    ratingsHistogram && (React.createElement(Histogram, { data: ratingsHistogram, handleSelected: handleSelected }))))))));
    function widgetsRendered(event) {
        if (event.target.id === props.template.id) {
            dispatchTemplateRendered(dispatch, props.template, event.detail);
            dispatchSkyfireMethods(dispatch, props.template, props.template.onViewed);
        }
    }
    function computeImageContainerSize() {
        // Image container size from detail-header component CSS declaration
        let containerWidth;
        if (width <= WINDOW_SIZE_ENUM.MD) {
            containerWidth = 192;
        }
        else if (width <= WINDOW_SIZE_ENUM.XL) {
            containerWidth = 256;
        }
        else {
            containerWidth = 280;
        }
        return containerWidth;
    }
    function shouldRenderBiography() {
        var _a, _b;
        return (((_a = entityDetails === null || entityDetails === void 0 ? void 0 : entityDetails.biographies) === null || _a === void 0 ? void 0 : _a.length) !== undefined &&
            ((_b = entityDetails === null || entityDetails === void 0 ? void 0 : entityDetails.biographies) === null || _b === void 0 ? void 0 : _b.length) > 0);
    }
    function shouldRenderAwards() {
        var _a, _b;
        return ((_a = entityDetails === null || entityDetails === void 0 ? void 0 : entityDetails.awards) === null || _a === void 0 ? void 0 : _a.length) !== undefined && ((_b = entityDetails === null || entityDetails === void 0 ? void 0 : entityDetails.awards) === null || _b === void 0 ? void 0 : _b.length) > 0;
    }
    function shouldRenderLanguageOfPerformances() {
        return !!(entityDetails === null || entityDetails === void 0 ? void 0 : entityDetails.languageOfPerformances);
    }
}
