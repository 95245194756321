import { MediaPlayer } from 'dashjs';
export default class HTML5VideoPlayerEvent {
    constructor(nativeEvent, dashEvent) {
        this.nativeEvent = nativeEvent;
        this.dashEvent = dashEvent;
    }
}
HTML5VideoPlayerEvent.PLAY = new HTML5VideoPlayerEvent('play', MediaPlayer.events.PLAYBACK_STARTED);
HTML5VideoPlayerEvent.PLAYING = new HTML5VideoPlayerEvent('playing', MediaPlayer.events.PLAYBACK_PLAYING);
HTML5VideoPlayerEvent.PAUSE = new HTML5VideoPlayerEvent('pause', MediaPlayer.events.PLAYBACK_PAUSED);
HTML5VideoPlayerEvent.ENDED = new HTML5VideoPlayerEvent('ended', MediaPlayer.events.PLAYBACK_ENDED);
HTML5VideoPlayerEvent.TIME_UPDATE = new HTML5VideoPlayerEvent('timeupdate', MediaPlayer.events.PLAYBACK_TIME_UPDATED);
HTML5VideoPlayerEvent.ERROR = new HTML5VideoPlayerEvent('error', MediaPlayer.events.ERROR);
HTML5VideoPlayerEvent.WAITING = new HTML5VideoPlayerEvent('waiting', MediaPlayer.events.PLAYBACK_WAITING);
