// eslint-disable-next-line no-shadow
export var DEVICE_TYPES_ENUM;
(function (DEVICE_TYPES_ENUM) {
    DEVICE_TYPES_ENUM["AWW0SCLEVHN20"] = "RetailWebPlayer.web";
    DEVICE_TYPES_ENUM["A2IYUBDFJ0JQHI"] = "RetailWebPlayer.mobile";
    DEVICE_TYPES_ENUM["A3GUQNOIIEF57X"] = "RetailWebPlayer.mshop";
    DEVICE_TYPES_ENUM["A2YTAK36ZW6KLD"] = "RetailWebPlayer.mshop.ios";
    DEVICE_TYPES_ENUM["A1OC181LHTSSL5"] = "RetailWebPlayer.mshop.android";
    DEVICE_TYPES_ENUM["A3VJHPKPE607WI"] = "RetailWebPlayer.mini.web";
    DEVICE_TYPES_ENUM["ARDHPQTE1DODJ"] = "RetailWebPlayer.mini.mshop.android";
    DEVICE_TYPES_ENUM["A39E3ZTQY6K0YY"] = "RetailWebPlayer.mini.mshop.ios";
    DEVICE_TYPES_ENUM["A34OIAVFALWTNH"] = "MobileWebPlayer";
    DEVICE_TYPES_ENUM["A16ZV8BU3SN1N3"] = "WebPlayer";
})(DEVICE_TYPES_ENUM || (DEVICE_TYPES_ENUM = {}));
// eslint-disable-next-line no-shadow
export var DEVICE_TYPES_ENUM_FEEDBACK_DIALOG;
(function (DEVICE_TYPES_ENUM_FEEDBACK_DIALOG) {
    DEVICE_TYPES_ENUM_FEEDBACK_DIALOG["AWW0SCLEVHN20"] = "RetailWebPlayer.web";
    DEVICE_TYPES_ENUM_FEEDBACK_DIALOG["A2IYUBDFJ0JQHI"] = "RetailWebPlayer.mobile";
    DEVICE_TYPES_ENUM_FEEDBACK_DIALOG["A3GUQNOIIEF57X"] = "RetailWebPlayer.mshop";
    DEVICE_TYPES_ENUM_FEEDBACK_DIALOG["A2YTAK36ZW6KLD"] = "RetailWebPlayer.mshop.ios";
    DEVICE_TYPES_ENUM_FEEDBACK_DIALOG["A1OC181LHTSSL5"] = "RetailWebPlayer.mshop.android";
    DEVICE_TYPES_ENUM_FEEDBACK_DIALOG["A3VJHPKPE607WI"] = "RetailWebPlayer.mini.web";
    DEVICE_TYPES_ENUM_FEEDBACK_DIALOG["ARDHPQTE1DODJ"] = "RetailWebPlayer.mini.mshop.android";
    DEVICE_TYPES_ENUM_FEEDBACK_DIALOG["A39E3ZTQY6K0YY"] = "RetailWebPlayer.mini.mshop.ios";
    DEVICE_TYPES_ENUM_FEEDBACK_DIALOG["A34OIAVFALWTNH"] = "SKYFIRE-MOBILE-WEB";
    DEVICE_TYPES_ENUM_FEEDBACK_DIALOG["A16ZV8BU3SN1N3"] = "SKYFIRE";
})(DEVICE_TYPES_ENUM_FEEDBACK_DIALOG || (DEVICE_TYPES_ENUM_FEEDBACK_DIALOG = {}));
export const MOBILE_DEVICES = [
    'A34OIAVFALWTNH',
    'A2IYUBDFJ0JQHI',
    'A3GUQNOIIEF57X',
    'A2YTAK36ZW6KLD',
    'A1OC181LHTSSL5',
    'ARDHPQTE1DODJ',
    'A39E3ZTQY6K0YY',
];
export const UPSELL_ON_PLAYBACK_ERROR_DEVICES = [
    'A3GUQNOIIEF57X',
    'A2YTAK36ZW6KLD',
    'A1OC181LHTSSL5',
    'ARDHPQTE1DODJ',
    'A39E3ZTQY6K0YY',
];
export const MSHOP = [
    'A3GUQNOIIEF57X',
    'A2YTAK36ZW6KLD',
    'A1OC181LHTSSL5',
    'ARDHPQTE1DODJ',
    'A39E3ZTQY6K0YY',
];
/*
 mShop DTID 'A3GUQNOIIEF57X' will be deprecated
 behind the weblab AMAZON_MUSIC_RMX_MSHOP_DTID_SEGREGATION_763710
 */
export const IOS_MSHOP = ['A3GUQNOIIEF57X', 'A2YTAK36ZW6KLD', 'A39E3ZTQY6K0YY'];
export const ANDROID_MSHOP = ['A3GUQNOIIEF57X', 'A1OC181LHTSSL5', 'ARDHPQTE1DODJ'];
export const RWP_MINI_MSHOP = ['ARDHPQTE1DODJ', 'A39E3ZTQY6K0YY'];
export const RWP_BROWSER_DEVICE_TYPES = ['AWW0SCLEVHN20', 'A2IYUBDFJ0JQHI', 'A3VJHPKPE607WI'];
export const RWP_DESKTOP_BROWSER_DEVICE_TYPES = ['AWW0SCLEVHN20', 'A3VJHPKPE607WI'];
export const RWP_MOBILE_BROWSER_DEVICE_TYPES = ['A2IYUBDFJ0JQHI'];
export const RWP_DEVICE_TYPES = [
    'AWW0SCLEVHN20',
    'A2IYUBDFJ0JQHI',
    'A3GUQNOIIEF57X',
    'A2YTAK36ZW6KLD',
    'A1OC181LHTSSL5',
    'A3VJHPKPE607WI',
    'ARDHPQTE1DODJ',
    'A39E3ZTQY6K0YY',
];
export const RWP_MOBILE_DEVICE_TYPES = [
    'A2IYUBDFJ0JQHI',
    'A3GUQNOIIEF57X',
    'A2YTAK36ZW6KLD',
    'A1OC181LHTSSL5',
];
export const WEBPLAYER_DEVICE_TYPES = ['A34OIAVFALWTNH', 'A16ZV8BU3SN1N3'];
