import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import * as Styles from './LyricsWidget.scss';
import { useItemsInView } from '../../utils/useItemsInView';
export default function LyricsWidget(props) {
    useStyles(Styles);
    const callbackRef = useItemsInView(props.onViewed);
    const getWidgetHeader = () => (React.createElement("header", { className: Styles.widgetHeaderContainer, title: props.data.header },
        React.createElement("div", { className: Styles.widgetHeader },
            " ",
            props.data.header,
            " ")));
    const getLyrics = () => {
        if (props.data.lines) {
            const lyrics = props.data.lines.map((item, index) => (React.createElement("div", { className: Styles.line, key: index }, item)));
            return lyrics;
        }
        return null;
    };
    const getActionText = () => (React.createElement("div", { className: Styles.actionTextElement }, props.data.actionText));
    const getActionButton = () => {
        var _a, _b;
        return (React.createElement("div", { className: Styles.actionButtonContainer },
            React.createElement("music-button", { slot: "buttons", variant: "outline", size: "small", onmusicActivate: props.handleSelected.bind(this, (_a = props.data.actionButton) === null || _a === void 0 ? void 0 : _a.onItemSelected) }, (_b = props.data.actionButton) === null || _b === void 0 ? void 0 : _b.text)));
    };
    return (React.createElement("div", { ref: callbackRef, className: Styles.lyricsWidgetContainer },
        React.createElement("div", { className: Styles.dynamicContainer },
            getWidgetHeader(),
            getLyrics(),
            getActionText(),
            props.data.actionButton && getActionButton())));
}
