import React from 'react';

import { RefinementHeader } from '../components';
import MessageWidget from '../components/widgets/MessageWidget';
import ISkyfireMessageTemplate from '../types/templates/message';
import ISkyfireMessageWidget, {
    MESSAGE_WIDGET,
} from '../types/templates/widgets/ISkyfireMessageWidget';
import * as viewStyles from './View.scss';

interface IMessageProps {
    template: ISkyfireMessageTemplate;
}

const noop = () => true;

export default function Message(props: IMessageProps) {
    const {
        message,
        image,
        header,
        refinementHeader,
        onViewed,
        buttonText,
        buttonPrimaryLink,
    } = props.template;

    const widgetData: ISkyfireMessageWidget = {
        interface: MESSAGE_WIDGET,
        header,
        message,
        image,
        onViewed,
        buttonText,
        buttonPrimaryLink,
    };

    return (
        <div className={viewStyles['view-content']}>
            {refinementHeader && (
                <RefinementHeader
                    text={refinementHeader.text}
                    refinementOptions={refinementHeader.refinementOptions}
                    isActive={refinementHeader.isActive}
                    isDisabled={refinementHeader.isDisabled}
                    isOpened={refinementHeader.isOpened}
                />
            )}
            <MessageWidget
                data={widgetData}
                handleSelected={noop}
                key={message}
            />
        </div>
    );
}
