import { subscribe, UpdateSearchSuggestionsEvent, RemoveSearchSuggestionsEvent, UpdateSearchTermEvent, UpdateSearchBoxEvent, } from '@amzn/DMWebEventBus';
import { REMOVE_SEARCH_SUGGESTIONS, UPDATE_SEARCH_BOX, UPDATE_SEARCH_SUGGESTIONS, UPDATE_SEARCH_TERM, } from '../actions';
const handleUpdateSearchSuggestionsEvent = (store) => (payload) => {
    store.dispatch({
        type: UPDATE_SEARCH_SUGGESTIONS,
        payload,
    });
};
const handleRemoveSearchSuggestionsEvent = (store) => () => {
    store.dispatch({ type: REMOVE_SEARCH_SUGGESTIONS });
};
const handleUpdateSearchTermEvent = (store) => (payload) => {
    store.dispatch({
        type: UPDATE_SEARCH_TERM,
        payload: { searchTerm: payload },
    });
};
const handleUpdateSearchBoxEvent = (store) => (payload) => {
    store.dispatch({
        type: UPDATE_SEARCH_BOX,
        payload,
    });
};
export function registerSearchModule(store) {
    subscribe(UpdateSearchSuggestionsEvent, handleUpdateSearchSuggestionsEvent(store));
    subscribe(RemoveSearchSuggestionsEvent, handleRemoveSearchSuggestionsEvent(store));
    subscribe(UpdateSearchTermEvent, handleUpdateSearchTermEvent(store));
    subscribe(UpdateSearchBoxEvent, handleUpdateSearchBoxEvent(store));
}
