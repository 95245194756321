import * as PodcastPlaybackActions from './actions/Playback';
import {
    PODCAST_DETAIL_TEMPLATE,
    ISkyfirePodcastDetailTemplate,
} from './types/templates/detail';
import {
    PODCAST_COMPACT_DETAIL_TEMPLATE,
    ISkyfirePodcastCompactDetailTemplate,
} from './types/templates/compactDetail';
import {
    PODCAST_DETAIL_TEMPLATE_V2,
    ISkyfireDetailTemplateV2,
} from './types/templates/detail/ISkyfireDetailTemplateV2';
import { INVOKE_HTTP_POST, ISkyfireInvokeHttpPostMethod } from './types';

export * from './types/ISkyfireInvokeHttpPostMethod';
export {
    PodcastPlaybackMiddleware,
    AppSyncMiddleware,
    LocalHydrationMiddleware,
} from './middlewares';
export {
    ILocalHydrationState,
    LocalHydrationReducer,
    PodcastStateReducer,
    IPodcastState,
} from './reducers';
export {
    podcastRequest,
    getPodcastHeaders,
    getPodcastPreset,
    getPodcastDeeplinkFromClientInformation,
} from './utils';
export { PodcastCompactDetail, PodcastDetail, PodcastDetailV2 } from './views';
export {
    SkipBackButton,
    SkipForwardButton,
    SleepTimerButton,
    SpeedButton,
    PodcastFollowsShoveler,
    PodcastLibraryPlaylist,
    PodcastLibraryRecents,
    PodcastLibraryShows,
    PodcastBookmarksShoveler,
    PodcastEpisodeTableWidget,
    EpisodeDescriptiveShoveler,
} from './components';
export {
    PODCAST_BOOKMARKS_SHOVELER_WIDGET,
    PODCAST_FOLLOWS_SHOVELER_WIDGET,
    PODCAST_LIBRARY_PLAYLIST_WIDGET,
    PODCAST_LIBRARY_RECENTS_WIDGET,
    PODCAST_LIBRARY_SHOWS_WIDGET,
    PODCAST_EPISODE_TABLE_WIDGET,
    EPISODE_DESCRIPTIVE_SHOVELER,
    UNBORDERED_BADGE_ELEMENT,
    LIGHT_UNBORDERED_BADGE_ELEMENT,
    ACCENT_OUTLINE_BADGE_ELEMENT,
    IPodcastBookmarksShoveler,
    IPodcastFollowsShoveler,
    IPodcastLibraryPlaylist,
    IPodcastLibraryRecents,
    IPodcastLibraryShows,
    IPodcastEpisodeTableWidget,
    IEpisodeDescriptiveShoveler,
    ISkyfireUnborderedBadgeElement,
    ISkyfireLightUnborderedBadgeElement,
    ISkyfireAccentOutlineBadgeElement,
} from './types';
export {
    PodcastPlaybackActions,
    PODCAST_DETAIL_TEMPLATE,
    ISkyfirePodcastDetailTemplate,
    ISkyfirePodcastCompactDetailTemplate,
    PODCAST_COMPACT_DETAIL_TEMPLATE,
    PODCAST_DETAIL_TEMPLATE_V2,
    ISkyfireDetailTemplateV2,
    INVOKE_HTTP_POST,
    ISkyfireInvokeHttpPostMethod,
};
// Disabling no-shadow for this next line to unblock linter work.
// Work to remove this is being tracked here SIM: https://sim.amazon.com/issues/DMP-28334
// eslint-disable-next-line no-shadow
export enum PODCAST_MENU_INTERFACES {
    PODCAST_LIBRARY_RECENTS_WIDGET = 'Podcast.Web.WidgetsInterface.BookmarkedEpisodesWidgetElement',
    PODCAST_LIBRARY_PLAYLIST_WIDGET = 'Podcast.Web.WidgetsInterface.LibraryPlaylistWidgetElement',
    PODCAST_LIBRARY_SHOWS_WIDGET = 'Podcast.Web.WidgetsInterface.LibraryShowsWidgetElement',
}
