import useStyles from 'isomorphic-style-loader/useStyles';
import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { UPDATE_SEARCH_TERM } from '../actions';
import { SEARCH_SUGGESTION_ITEM_ELEMENT, } from '../types/templates/searchSuggestions/ISkyfireSearchSuggestionItemElement';
import * as Styles from './SearchSuggestions.scss';
import HorizontalItem from '../components/widgets/items/HorizontalItem';
export default function SearchSuggestions(props) {
    useStyles(Styles);
    const dispatch = useDispatch();
    const { items, keyword, title, noSuggestionsString, showLineSeparator } = props.template;
    return (React.createElement("div", { id: "searchSuggestions", className: Styles.searchSuggestionList }, renderSuggestionsContent()));
    function handleTextSelected(onItemSelected, suggestedSearchTerm) {
        dispatch({
            type: UPDATE_SEARCH_TERM,
            payload: { searchTerm: { text: suggestedSearchTerm, forceUpdateTime: Date.now() } },
        });
        props.handleSelected(onItemSelected);
    }
    function renderSuggestion(item, index) {
        return isTextSuggestion(item)
            ? renderTextSuggestion(item, index)
            : renderEntitySuggestion(item, index);
    }
    function renderTextSuggestion(item, index) {
        return (React.createElement("div", { id: `searchSuggestion${index + 1}`, tabIndex: 0, "aria-label": item.text, className: Styles.searchSuggestionTextItem, style: {
                // @ts-ignore
                '--borderBottomWidth': `${showLineSeparator ? '1' : '0'}px`,
            }, onClick: handleTextSelected.bind(this, item.onItemSelected, item.text) }, decorateMatchedAndCompleted(item.text)));
    }
    function renderEntitySuggestion(item, index) {
        var _a;
        return (React.createElement("div", { id: `searchSuggestion${index + 1}`, tabIndex: 0, onClick: props.handleSelected.bind(this, (_a = item.primaryLink) === null || _a === void 0 ? void 0 : _a.onItemSelected), className: Styles.searchSuggestionEntityItem },
            React.createElement(HorizontalItem, { key: item.id, data: item, style: { minWidth: '100%', paddingRight: '0px' }, role: "suggestionitem", handleSelected: props.handleSelected })));
    }
    function renderSuggestionsContent() {
        return (React.createElement(Fragment, null,
            React.createElement("h1", { className: Styles.searchSuggestionTitle }, title),
            items.map((item, index) => renderSuggestion(item, index))));
    }
    function isTextSuggestion(item) {
        return item.interface === SEARCH_SUGGESTION_ITEM_ELEMENT;
    }
    function renderNoSuggestions() {
        return React.createElement("p", { className: Styles.noSuggestionsText }, noSuggestionsString);
    }
    function decorateMatchedAndCompleted(searchSuggestion) {
        try {
            const decoratedSuggestions = searchSuggestion
                .split(new RegExp(keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')) // replace characters that cause invalid regular expression
                .map((autoCompletedTerm) => autoCompletedTerm
                ? [
                    /* eslint-disable react/jsx-indent */
                    React.createElement("span", { className: Styles.nonMatched }, autoCompletedTerm),
                    React.createElement("span", null, keyword.toLowerCase()),
                    /* eslint-enable react/jsx-indent */
                ]
                : [React.createElement("span", { className: Styles.matched }, keyword.toLowerCase())])
                .reduce((curElement, newElement) => [...curElement, ...newElement], []);
            return decoratedSuggestions.slice(0, -1);
        }
        catch (error) {
            return [React.createElement("span", null, searchSuggestion)];
        }
    }
}
