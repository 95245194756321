import { useEffect, useState } from 'react';
import { StateChangedEvent, subscribe } from '../events/utils';

const postal = typeof window !== 'undefined' ? window.postal : undefined;

export function useStateSubscription<T>(stateChangedEvent: StateChangedEvent<T>): T | undefined {
  if (postal === undefined) {
    throw new Error('Event Bus cannot be used in SSR');
  }
  const envelope = postal.preserve.store[stateChangedEvent.channel]?.[stateChangedEvent.topic];
  const [state, setState] = useState<T>(envelope?.data);

  useEffect(() => {
    const subscriptionDefinition = subscribe(stateChangedEvent, setState);
    return () => {
      subscriptionDefinition.unsubscribe();
    };
  }, []);

  return state;
}
