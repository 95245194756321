import {
    IConditionElement,
    IOperationElement,
    IWriteElement,
} from '../types/appsync/IAppSync';

export class OperationElement implements IOperationElement {
    public id: string;

    public element: IWriteElement;

    public condition: IConditionElement;

    public __type: string;

    constructor(id, element, condition) {
        this.id = id;
        this.element = element;
        this.condition = condition;
        this.__type = 'SOAAppSyncInterface.v1_0#OperationElement';
    }
}
