exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2-9Rflrq_bZmai4DzYTFk_{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._2-9Rflrq_bZmai4DzYTFk_{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._2-9Rflrq_bZmai4DzYTFk_{padding-inline-start:52px;padding-inline-end:52px}}._2G7R5Rjx9AcydTRtgnY3oR{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._2G7R5Rjx9AcydTRtgnY3oR{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._2G7R5Rjx9AcydTRtgnY3oR{margin-inline-start:52px;margin-inline-end:52px}}._3DczdzDqGoSvNjNnLPzmu7{text-align:start;margin:var(--music-spacer-medium) 0}._3DczdzDqGoSvNjNnLPzmu7 #_31mvYpjWxr2CSCPbBhWS8f{margin-bottom:var(--music-spacer-mini)}._3DczdzDqGoSvNjNnLPzmu7 .oNWVSOhbWNSc9rPek-MjU,._3DczdzDqGoSvNjNnLPzmu7 ._2XRgPW-e-XBbfE83SI3A2z{white-space:nowrap;text-overflow:ellipsis;overflow:hidden;display:block;text-decoration:none}._3DczdzDqGoSvNjNnLPzmu7 .oNWVSOhbWNSc9rPek-MjU[href]:hover,._3DczdzDqGoSvNjNnLPzmu7 ._2XRgPW-e-XBbfE83SI3A2z[href]:hover{text-decoration:underline}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_2-9Rflrq_bZmai4DzYTFk_",
	"gridSides": "_2-9Rflrq_bZmai4DzYTFk_",
	"grid-sides-margins": "_2G7R5Rjx9AcydTRtgnY3oR",
	"gridSidesMargins": "_2G7R5Rjx9AcydTRtgnY3oR",
	"header": "_3DczdzDqGoSvNjNnLPzmu7",
	"headline": "_31mvYpjWxr2CSCPbBhWS8f",
	"title": "oNWVSOhbWNSc9rPek-MjU",
	"sub-title": "_2XRgPW-e-XBbfE83SI3A2z",
	"subTitle": "_2XRgPW-e-XBbfE83SI3A2z"
};