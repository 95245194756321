import { ContentTrait, PlaybackMode, UpsellType } from '../types/Eligibility';
import { APPSYNC_WRITE_METHOD } from '../appsync/AppSyncConstants';
import localization from '../resources/localization.json';
import { SaveWriteElement } from '../Save';
import { IWriteMethod } from '../types/appsync/IAppSync';
import ISkyfireButtonElement from '../types/templates/widgets/ISkyfireButtonElement';
import { IconConstants } from './IconConstants';
import { SINGLE_THREADED_QUEUE } from './skyfireContants';
import {
    UIMetrics,
    EntityType,
    EntityIdType,
    ActionType,
    ContentName,
    PageType,
} from '../UIMetrics';

export function getSaveButton(
    defaultState: string,
    episodeId: string,
    podcastId: string,
    podcastTitle: string,
    title: string,
    description: string,
    image: string,
    podcastImage: string,
    authors: string,
    seasonNumber: number,
    totalDurationMilliseconds: number,
    locale: string = 'en_US',
    publishTime: string,
    contentName: ContentName,
    pageType: PageType,
    playbackMode: PlaybackMode,
    availableUpsells: UpsellType[],
    contentTraits: ContentTrait[],
    availabilityDate: string,
    podcastShowVariantId: string,
    podcastEpisodeVariantId: string
): ISkyfireButtonElement | undefined {
    const getObserverState = (
        saved: boolean,
        localizedText: string,
        icon: string
    ) => {
        const saveElement = new SaveWriteElement(
            episodeId,
            podcastId,
            podcastTitle,
            title,
            image,
            podcastImage,
            description,
            authors,
            seasonNumber,
            totalDurationMilliseconds,
            saved,
            publishTime,
            playbackMode,
            availableUpsells,
            contentTraits,
            availabilityDate,
            podcastShowVariantId,
            podcastEpisodeVariantId
        );
        const saveWriteMethod: IWriteMethod = {
            interface: APPSYNC_WRITE_METHOD,
            condition: null,
            forced: false,
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'SAVE_SYNC',
            },
            elements: [saveElement],
        };
        const setStorageMethod = {
            interface: 'StorageInterface.v1_0.SetValueInGroupMethod',
            forced: false,
            group: 'SAVES',
            key: episodeId,
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'SAVE_SYNC',
            },
            value: saved === true ? 'SAVE' : 'UNSAVE',
        };
        let metrics;
        const {
            isRedhoodPlusMetricsEnabled,
            isRedhoodSonicRushEnabled,
        } = window.amznMusic.appConfig;
        const entityId =
            podcastEpisodeVariantId &&
            isRedhoodPlusMetricsEnabled &&
            isRedhoodSonicRushEnabled
                ? podcastEpisodeVariantId
                : episodeId;
        if (saved) {
            metrics = UIMetrics.onClicked({
                actionType: ActionType.SAVE_PODCAST_EPISODE,
                contentName,
                pageType,
                entityType: EntityType.PODCAST_EPISODE,
                entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                entityId,
            });
        } else {
            metrics = UIMetrics.onClicked({
                actionType: ActionType.UNSAVE_PODCAST_EPISODE,
                contentName,
                pageType,
                entityType: EntityType.PODCAST_EPISODE,
                entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                entityId,
            });
        }
        return {
            disabled: false,
            icon,
            iconOnly: true,
            observer: null,
            text: localizedText,
            onItemSelected: [
                ...metrics,
                setStorageMethod,
                saveWriteMethod,
                savedRibbon(saved),
            ],
        };
    };
    function savedRibbon(isSaved: boolean) {
        const closeNotification = {
            interface:
                'Web.TemplatesInterface.v1_0.Touch.ChromeTemplateInterface.CloseNotificationMethod',
            id: 'PODCAST_SAVED_RIBBON',
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'TEMPLATE',
            },
            forced: false,
        };
        if (!isSaved) return closeNotification;
        const closeButtonElement = {
            interface:
                'Web.TemplatesInterface.v1_0.Touch.ChromeTemplateInterface.RibbonCloseButtonElement',
            icon: 'close',
            text: localization[locale].dmp_close,
            onItemSelected: [closeNotification],
        };
        const ribbon = {
            interface:
                'Web.TemplatesInterface.v1_0.Touch.ChromeTemplateInterface.RibbonNotificationElement',
            id: 'PODCAST_SAVED_RIBBON',
            message: {
                interface:
                    'Web.TemplatesInterface.v1_0.Touch.ChromeTemplateInterface.RibbonNotificationMessageElement',
                text: localization[locale].dmp_adding_to_playlist,
            },
            closeButton: closeButtonElement,
            timeoutSeconds: 8,
        };
        return {
            interface:
                'Web.TemplatesInterface.v1_0.Touch.ChromeTemplateInterface.ShowNotificationMethod',
            notification: ribbon,
            queue: {
                interface: SINGLE_THREADED_QUEUE,
                id: 'TEMPLATE',
            },
        };
    }
    return {
        icon: '',
        text: '',
        iconOnly: true,
        disabled: false,
        onItemSelected: [],
        observer: {
            defaultState,
            defaultValue: null,
            states: {
                SAVE: getObserverState(
                    false,
                    localization[locale].dmp_added_to_episode_playlist,
                    IconConstants.DONE
                ),
                UNSAVE: getObserverState(
                    true,
                    localization[locale].dmp_adding_to_playlist,
                    IconConstants.ADD
                ),
            },
            storageGroup: 'SAVES',
            storageKey: episodeId,
        },
    };
}
