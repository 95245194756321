import React from 'react';
import { getEpisodeRowHeight } from 'src/utils/gridHelpers';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import IPodcastEpisodeTableWidget from '../../types/templates/widgets/IPodcastEpisodeTableWidget';
import EpisodeRowItem from './items/EpisodeRowItem';
import InfiniteList from './items/InfiniteList';
import { useItemsInView } from '../../utils/useItemsInView';

interface IPodcastEpisodeTableWidgetProps {
    data: IPodcastEpisodeTableWidget;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    box?: ClientRect;
    loading?: boolean;
    isVisualPlayQueue?: boolean;
    onViewed?: () => void;
}

export function PodcastEpisodeTableWidget(
    props: IPodcastEpisodeTableWidgetProps
) {
    const callbackRef = useItemsInView(props.onViewed);

    return (
        <InfiniteList
            data={props.data}
            handleSelected={props.handleSelected}
            componentType={EpisodeRowItem}
            rowHeight={getEpisodeRowHeight(props.data.items)}
            loading={props?.loading}
            isVisualPlayQueue={props?.isVisualPlayQueue}
            itemsViewedRef={callbackRef}
        />
    );
}
