import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MShopHandler from './MShopHandler';
import { NOW_PLAYING_TEMPLATE } from '../types/templates/nowPlaying';
import { VIDEO_NOW_PLAYING_TEMPLATE } from '../types/templates/videoNowPlaying';
import { globals } from '../utils/globals';
import useIntersectionObserver from '../utils/useIntersectionObserver';
import { RWP_BROWSER_DEVICE_TYPES, MSHOP } from '../utils/deviceTypes';
import { SET_RWP_RETAIL_NAVBAR_VISIBILITY_STATUS } from '../actions';
let oldScrollHandler;
const RWPBrowserHandler = (props) => {
    const { target } = props;
    const dispatch = useDispatch();
    const callback = ([entry]) => {
        if (!entry.isIntersecting) {
            // retail navbar is out of the view
            dispatch({
                type: SET_RWP_RETAIL_NAVBAR_VISIBILITY_STATUS,
                payload: { isRWPRetailNavbarInview: false },
            });
        }
        else {
            // retail navbar is in the view
            dispatch({
                type: SET_RWP_RETAIL_NAVBAR_VISIBILITY_STATUS,
                payload: { isRWPRetailNavbarInview: true },
            });
        }
    };
    useIntersectionObserver(target, callback);
    return null;
};
export default function RWPhandler() {
    const contextMenuOpen = useSelector((state) => state.ContextMenu.open);
    const overlayTemplates = useSelector((state) => state.TemplateStack.overlayTemplates);
    const isMusicExperienceShowing = !!useSelector((state) => state.Media.isForMusicExperience);
    const isVideoNPVShowing = !!overlayTemplates.find((template) => template.interface === VIDEO_NOW_PLAYING_TEMPLATE);
    const hasOverlay = overlayTemplates.length > 0;
    const isAudioNPVShowing = !!overlayTemplates.find((template) => template.interface === NOW_PLAYING_TEMPLATE);
    const isNPVShowing = hasOverlay && (isAudioNPVShowing || isVideoNPVShowing || isMusicExperienceShowing);
    const [scrollEventListenerAdded, setScrollEventAdded] = useState(false);
    /**
     * Scroll to top when there is a URL change but don't so when there is trackAsin to avoid
     * scroll to track feature
     */
    useEffect(() => {
        const originalPushState = window.history.pushState;
        window.history.pushState = new Proxy(originalPushState, {
            apply: (target, thisArg, argArray) => {
                scrollToTopHandler();
                return target.apply(thisArg, argArray);
            },
        });
        return () => {
            window.history.pushState = originalPushState;
        };
    }, []);
    /**
     * Disable page scroll when there is an overlay like context menu for better CX
     */
    useEffect(() => {
        if (contextMenuOpen && !isNPVShowing && !scrollEventListenerAdded) {
            preventScrollHandler();
            setScrollEventAdded(true);
        }
        else if (scrollEventListenerAdded) {
            window.onscroll = oldScrollHandler;
            setScrollEventAdded(false);
        }
    }, [isNPVShowing, contextMenuOpen]);
    function scrollToTopHandler() {
        const url = new URL(globals.location.href);
        const currentSearchParams = url.searchParams;
        const trackAsin = currentSearchParams.get('trackAsin');
        if (!trackAsin) {
            setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0);
        }
    }
    function preventScrollHandler() {
        const top = window.pageYOffset || document.documentElement.scrollTop;
        const left = window.pageXOffset || document.documentElement.scrollLeft;
        oldScrollHandler = window.onscroll;
        window.onscroll = () => window.scrollTo({ top, left, behavior: 'smooth' });
    }
    /**
     * This works on RWP Browser device types
     * For RWP browsers - we need to know when the Retail navbar is in view
     */
    if (RWP_BROWSER_DEVICE_TYPES.includes(globals.amznMusic.appConfig.deviceType)) {
        const retailNavbar = document.getElementById('navbar-main') || document.getElementById('nav-main');
        return retailNavbar ? React.createElement(RWPBrowserHandler, { target: retailNavbar }) : null;
    }
    /**
     * This works on RWP MShop device types
     * For mShop we need to handle bottom navbar and other mShop specific things
     */
    if (MSHOP.includes(globals.amznMusic.appConfig.deviceType)) {
        return React.createElement(MShopHandler, null);
    }
    return null;
}
