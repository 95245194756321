import { cleanDeeplink, globals } from '.';
export function recordPageView(pageId) {
    if (isCloudWatchRumEnabled()) {
        const pageType = cleanDeeplink(pageId);
        cwr('recordPageView', pageType);
    }
}
export function recordError(error) {
    if (isCloudWatchRumEnabled()) {
        cwr('recordError', error);
    }
}
function isCloudWatchRumEnabled() {
    var _a;
    return !!((_a = globals.amznMusic.appConfig) === null || _a === void 0 ? void 0 : _a.isCloudWatchRumEnabled);
}
