import React, { useContext } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useDispatch } from 'react-redux';
import Template from '../../../Contexts/Template';
import * as Styles from './TabButton.scss';
import { dispatchSkyfireMethods } from '../../../utils';
export default function TabButton(props) {
    const { isActive, text, href, onItemSelected } = props.data;
    const className = props.active ? Styles.headerTextActive : Styles.headerTextInactive;
    useStyles(Styles);
    const dispatch = useDispatch();
    const template = useContext(Template);
    const OnButtonSelected = (methods) => {
        if (!props.active) {
            props.updateParentWithIndexProp(props.index);
            dispatchSkyfireMethods(dispatch, template, methods);
        }
    };
    return (React.createElement("div", { className: Styles.container },
        React.createElement("button", { type: "button", className: Styles.buttonClass, onClick: OnButtonSelected.bind(this, onItemSelected) },
            React.createElement("h4", { className: "label-text", id: className }, text),
            props.active && React.createElement("div", { className: Styles.highlight }))));
}
