import { CLEAR_PLAYBACK_DURATION_METHODS, SET_PLAYBACK_DURATION_METHODS, } from '../actions/Playback';
const initialState = {
    playbackDurationMethods: {},
};
const reducer = {
    [SET_PLAYBACK_DURATION_METHODS]: (state, action) => (Object.assign(Object.assign({}, initialState), action.payload)),
    [CLEAR_PLAYBACK_DURATION_METHODS]: (state, action) => {
        const nextState = Object.assign({}, state);
        delete nextState.playbackDurationMethods[action.payload.currentTime];
        return nextState;
    },
};
export function PlaybackDurationStateReducer(state = initialState, action) {
    if (reducer[action.type]) {
        return reducer[action.type](state, action);
    }
    return state;
}
