import { Store } from 'redux';
import {
    AppSyncTypes,
    SAVES_LAST_SYNC_TIME,
    SAVES_LAST_SYNC_TIME_CLIENT_STATE,
    SAVES_SET_OP_REPORT_OPERATIONS_METHOD,
} from '../appsync/AppSyncConstants';
import { Entry } from '../appsync/Entry';
import { OperationElement } from '../appsync/OperationElement';
import { SyncData } from '../appsync/SyncData';
import { TimeConditionElement } from '../appsync/TimeConditionElement';
import {
    IConditionElement,
    ITimeConditionElement,
} from '../types/appsync/IAppSync';
import IApplicationState from '../types/IApplicationState';
import { ISaveDao } from './ISaveDao';
import { ISaveOperationDao } from './ISaveOperationDao';
import { Save } from './Save';
import { SaveMetadataWriteElement } from './SaveMetadataWriteElement';
import { SaveOperation } from './SaveOperation';
import { SaveOperationsClientState } from './SaveOperationsClientState';
import { SaveSetOnReportOperationsMethod } from './SaveSetOnReportOperationsMethod';
import { SavesLastSyncTimeClientState } from './SavesLastSyncTimeClientState';
import { SaveWriteElement } from './SaveWriteElement';

export class SaveSyncData extends SyncData<
    SaveWriteElement,
    SaveMetadataWriteElement,
    Save
> {
    private saveDao: ISaveDao;

    private saveOperationsDao: ISaveOperationDao;

    private store: Store<IApplicationState>;

    constructor(saveDao: ISaveDao, saveOperationDao: ISaveOperationDao, store) {
        super();
        this.saveDao = saveDao;
        this.saveOperationsDao = saveOperationDao;
        this.store = store;
    }

    public read(id: string): Save | undefined {
        const { customerId } = this.store.getState().Authentication;
        const save = this.saveDao.getSaveEpisode(id, customerId);
        if (save) {
            return save;
        }
        return undefined;
    }

    public readStates(): Save[] {
        const { customerId } = this.store.getState().Authentication;
        return this.saveDao.getAll(customerId, true);
    }

    public writeStates(
        element: SaveWriteElement,
        condition?: IConditionElement
    ) {
        const timeCondition = condition as ITimeConditionElement;
        const updatedTime = timeCondition?.updatedTime
            ? timeCondition?.updatedTime
            : Date.now();
        const save = new Save({
            ...element,
            episodeId: element.id,
            isSaved: element.saved,
            updatedTime,
            publishTime: element.publishTime,
        });
        this.saveDao.insert(save);
    }

    public writeToOperations(element: SaveWriteElement) {
        const saveOperation = new SaveOperation({
            episodeId: element.id,
            podcastId: element.podcastId,
            podcastTitle: element.podcastTitle,
            title: element.title,
            image: element.image,
            podcastImage: element.podcastImage,
            description: element.description,
            authors: element.authors,
            seasonNumber: element.seasonNumber,
            totalDurationMilliseconds: element.totalDurationMilliseconds,
            isSaved: element.saved,
            updatedTime: Date.now(),
            isProcessing: false,
            publishTime: element.publishTime,
        });
        this.saveOperationsDao.insert(saveOperation);
    }

    public writeMetadata(element: SaveMetadataWriteElement) {
        const save = this.read(element.id);
        if (!save) return;
        const updatedSave = new Save({
            episodeId: element.id,
            podcastId: element.podcastId,
            podcastTitle: element.podcastTitle,
            title: element.title,
            image: element.image,
            podcastImage: element.podcastImage,
            description: element.description,
            authors: element.authors,
            seasonNumber: element.seasonNumber,
            publishTime: element.publishTime,
            availabilityDate: element.availabilityDate,
            contentTraits: element.contentTraits,
            availableUpsells: element.availableUpsells,
            playbackMode: element.playbackMode,
            podcastShowVariantId: element.podcastShowVariantId,
            podcastEpisodeVariantId: element.podcastEpisodeVariantId,
        });
        this.saveDao.insert(updatedSave);
    }

    public reportOperations() {
        const operations = new SaveSetOnReportOperationsMethod();
        const onReportOperations = [operations];
        this.store.dispatch({
            type: SAVES_SET_OP_REPORT_OPERATIONS_METHOD,
            payload: { onReportOperations },
        });
    }

    public getClientState(name: string): Entry {
        if (name === SAVES_LAST_SYNC_TIME_CLIENT_STATE) {
            return this.getSavesLastSyncTimeClientState();
        }
        const operations = this.saveOperationsDao.getUnProcessed();
        const clientState = this.convertOperationsToClientState(operations);
        const ids: string[] = [];
        operations.forEach((operation) => {
            ids.push(operation.operationId);
        });
        this.saveOperationsDao.update(ids, true);
        return new Entry(AppSyncTypes.SAVE, clientState);
    }

    public getSavesLastSyncTimeClientState(): Entry {
        const { customerId } = this.store.getState().Authentication;
        const lastSyncTime = this.saveDao.getLastSyncTime(customerId);
        return new Entry(
            SAVES_LAST_SYNC_TIME,
            new SavesLastSyncTimeClientState(lastSyncTime)
        );
    }

    public deleteOperation(ids: string[]) {
        this.saveOperationsDao.delete(ids);
    }

    public conditionSatisfied(
        element: SaveWriteElement,
        condition: IConditionElement
    ): boolean {
        if (condition == null) {
            return true;
        }
        const timeCondition = condition as ITimeConditionElement;
        if (timeCondition == null) {
            return true;
        }
        const { customerId } = this.store.getState().Authentication;
        const save = this.saveDao.getSaveEpisode(element.id, customerId);
        if (save) {
            return timeCondition.updatedTime >= save.updatedTime;
        }
        return true;
    }

    private convertOperationsToClientState(
        pendingOperations: SaveOperation[]
    ): SaveOperationsClientState {
        const operations: OperationElement[] = [];
        pendingOperations.forEach((operation) => {
            const writeElement: SaveWriteElement = this.convertOperationToSaveWriteElement(
                operation
            );
            const timeConditionElement: TimeConditionElement = new TimeConditionElement(
                operation.updatedTime
            );
            const operationElement: OperationElement = new OperationElement(
                operation.operationId,
                writeElement,
                timeConditionElement
            );
            operations.push(operationElement);
        });
        return new SaveOperationsClientState(operations);
    }

    private convertOperationToSaveWriteElement(
        operation: SaveOperation
    ): SaveWriteElement {
        return new SaveWriteElement(
            operation.episodeId,
            operation.podcastId,
            operation.podcastTitle,
            operation.title,
            operation.image,
            operation.podcastImage,
            operation.description,
            operation.authors,
            operation.seasonNumber,
            operation.totalDurationMilliseconds,
            operation.isSaved,
            operation.publishTime
        );
    }
}
