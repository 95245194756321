import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UNBORDERED_BADGE_ELEMENT, LIGHT_UNBORDERED_BADGE_ELEMENT, ACCENT_OUTLINE_BADGE_ELEMENT, } from '@amzn/dm-podcast-web-player';
import { getLocalizedEventTime } from '../../../utils/liveEventDateTime';
import { CIRCLE_HORIZONTAL_ITEM, RECTANGLE_HORIZONTAL_ITEM, PODCAST_SQUARE_HORIZONTAL_ITEM, } from '../../../types/templates/widgets/items/ISkyfireHorizontalItem';
import { bindHandler, buildTags, globals, preventDefault } from '../../../utils';
import { getDeeplink } from '../../../utils/getDeeplink';
import { useObserver } from '../../../utils/ObserverHooks';
import ContextMenuButton from '../../ContextMenuButton';
import Button from './Button';
import { STORAGE_ALBUM_RATINGS, STORAGE_ARTIST_RATINGS, STORAGE_TRACK_RATINGS, } from '../../../constants/storageConstants';
import LikeStateButton from './LikeStateButton';
// eslint-disable-next-line no-shadow
export var HorizontalItemSize;
(function (HorizontalItemSize) {
    HorizontalItemSize[HorizontalItemSize["Small"] = 0] = "Small";
    HorizontalItemSize[HorizontalItemSize["Medium"] = 1] = "Medium";
    HorizontalItemSize[HorizontalItemSize["Large"] = 2] = "Large";
})(HorizontalItemSize || (HorizontalItemSize = {}));
// eslint-disable-next-line no-shadow
export var HorizontalItemBadgeStyle;
(function (HorizontalItemBadgeStyle) {
    HorizontalItemBadgeStyle["adFree"] = "adFree";
    HorizontalItemBadgeStyle["exclusive"] = "exclusive";
    HorizontalItemBadgeStyle["primeTierExclusive"] = "primeTierExclusive";
})(HorizontalItemBadgeStyle || (HorizontalItemBadgeStyle = {}));
const horizontalItemSizeProperties = {
    [HorizontalItemSize.Small]: 'small',
    [HorizontalItemSize.Medium]: 'medium',
    [HorizontalItemSize.Large]: 'large',
};
/**
 * Calculates horizontal item size based on internal content.
 * @param item horizontal item
 */
export function getItemSize(item) {
    // TODO account for tertiary text once we support this
    const hasSecondaryTextAndLabel = !!item.secondaryText && !!item.label;
    if (hasSecondaryTextAndLabel) {
        return getBottomTags(item).length ? HorizontalItemSize.Large : HorizontalItemSize.Medium;
    }
    return HorizontalItemSize.Small;
}
/**
 * Returns non-explicit tags, which are displayed on a separate row.
 * @param item horizontal item
 */
function getBottomTags(item) {
    if (!item.tags)
        return [];
    return item.tags.filter((t) => t !== 'E');
}
/**
 * Returns the horizontal item type based on the Interface returned from Skyfire.
 * Possible values are circle, rectangle and square
 * @param interfaceType String
 */
function getHorizontalItemType(interfaceType) {
    switch (interfaceType) {
        case CIRCLE_HORIZONTAL_ITEM:
            return 'circle';
        case RECTANGLE_HORIZONTAL_ITEM:
            return 'rectangle';
        default:
            return 'square';
    }
}
const getSecondaryTextForLiveEvent = (liveEventDate, displayLanguageId, dispatch) => {
    const tz = globals.timezone;
    const formattedLocaleString = displayLanguageId.replace('_', '-');
    return getLocalizedEventTime(liveEventDate, formattedLocaleString, tz, dispatch);
};
function HorizontalItem(props, ref) {
    var _a;
    const { image, labelText, primaryText, primaryLink, secondaryText, secondaryLink, tertiaryText, tertiaryLink, badgeText, isDisabled, isFeatured, isLocked, isCompressed, label, actionIconName, actionIconLink, iconButton, hintIconName, tags, button, contextMenu, liveEventDate, showNavigationIcon, tertiaryBadge, isActive, secondaryText2, secondaryLink2, secondaryTextSeparator, } = props.data;
    const { position, role } = props;
    const dispatch = useDispatch();
    const displayLanguageId = useSelector((state) => state.Authentication.displayLanguageId);
    const bindHandlerHelper = (fn) => bindHandler(props.handleSelected, null, fn);
    const primaryTextElement = useObserver(primaryText);
    const iconButtonElement = useObserver(iconButton);
    const bottomTags = getBottomTags(props.data);
    const isActivityFeed = role === 'feeditem';
    const onItemClick = (e) => {
        e.preventDefault();
        bindHandlerHelper(primaryLink === null || primaryLink === void 0 ? void 0 : primaryLink.onItemSelected)();
    };
    const key = ((primaryTextElement === null || primaryTextElement === void 0 ? void 0 : primaryTextElement.text) || primaryText) + (secondaryText || '') + props.index;
    const storageGroup = (_a = button === null || button === void 0 ? void 0 : button.observer) === null || _a === void 0 ? void 0 : _a.storageGroup;
    const isLikesEveryWhereEnabled = storageGroup === STORAGE_TRACK_RATINGS ||
        storageGroup === STORAGE_ALBUM_RATINGS ||
        storageGroup === STORAGE_ARTIST_RATINGS;
    return (React.createElement("music-horizontal-item", { ref: ref, key: key, "data-key": key, role: role, style: props.style, disabled: isDisabled, featured: isFeatured, locked: isLocked, compressed: isCompressed, label: label, "image-src": image, onClick: isActivityFeed || isCompressed ? onItemClick : preventDefault, "label-text": labelText, "primary-text": `${position ? `${position}. ` : ''}${(primaryTextElement === null || primaryTextElement === void 0 ? void 0 : primaryTextElement.text) || primaryText}`, size: horizontalItemSizeProperties[props.size || HorizontalItemSize.Small], parentSize: horizontalItemSizeProperties[props.parentSize || HorizontalItemSize.Small], kind: getHorizontalItemType(props.data.interface), "primary-href": getDeeplink(primaryLink === null || primaryLink === void 0 ? void 0 : primaryLink.deeplink), "icon-name": (iconButtonElement === null || iconButtonElement === void 0 ? void 0 : iconButtonElement.icon) || actionIconName, "hint-icon-name": hintIconName, "show-action-button": (iconButtonElement === null || iconButtonElement === void 0 ? void 0 : iconButtonElement.onItemSelected.length) > 0 || !!actionIconLink, onmusicPrimaryTextActivate: isActivityFeed ? preventDefault : bindHandlerHelper(primaryLink === null || primaryLink === void 0 ? void 0 : primaryLink.onItemSelected), "secondary-text": isFeatured && liveEventDate
            ? getSecondaryTextForLiveEvent(new Date(liveEventDate), displayLanguageId, dispatch)
            : secondaryText, "secondary-href": getDeeplink(secondaryLink === null || secondaryLink === void 0 ? void 0 : secondaryLink.deeplink), "secondary-text-2": secondaryText2, "secondary-href-2": getDeeplink(secondaryLink2 === null || secondaryLink2 === void 0 ? void 0 : secondaryLink2.deeplink), "secondary-text-separator": secondaryTextSeparator, "tertiary-text": tertiaryText, "tertiary-href": getDeeplink(tertiaryLink === null || tertiaryLink === void 0 ? void 0 : tertiaryLink.deeplink), "badge-text": badgeText, "tertiary-badge-text": tertiaryBadge === null || tertiaryBadge === void 0 ? void 0 : tertiaryBadge.text, onmusicTertiaryBadgeActivate: bindHandlerHelper(tertiaryBadge === null || tertiaryBadge === void 0 ? void 0 : tertiaryBadge.onItemSelected), tertiaryBadgeTier: getUpsellBadgeTier(tertiaryBadge === null || tertiaryBadge === void 0 ? void 0 : tertiaryBadge.interface), "bottom-tags": bottomTags, onmusicSecondaryTextActivate: bindHandlerHelper(secondaryLink ? secondaryLink.onItemSelected : []), onmusicTertiaryTextActivate: bindHandlerHelper(tertiaryLink ? tertiaryLink.onItemSelected : []), onmusicActionButtonActivate: bindHandlerHelper((iconButtonElement === null || iconButtonElement === void 0 ? void 0 : iconButtonElement.onItemSelected) ||
            (actionIconLink ? actionIconLink.onItemSelected : [])), onmusicSecondaryText2Activate: bindHandlerHelper(secondaryLink2 ? secondaryLink2.onItemSelected : []), alwaysShowButton: props.alwaysShowButton, showNavigationIcon: showNavigationIcon, includesUpsellText: props.data.interface === PODCAST_SQUARE_HORIZONTAL_ITEM, isHeader: props.isHeader, active: isActive },
        tags.includes('E') ? buildTags(['E'], isDisabled, true) : null,
        button &&
            (isLikesEveryWhereEnabled ? (React.createElement(LikeStateButton, { data: button, slot: "buttons", size: "small", handleSelected: props.handleSelected })) : (React.createElement(Button, { data: button, handleSelected: props.handleSelected, slot: "buttons", size: isActive ? 'medium' : 'small', variant: getButtonVariant(button.icon, isActive), updatedTrackPosition: props.updatedTrackPosition, isDisabled: isDisabled }))),
        contextMenu && (React.createElement(ContextMenuButton, { options: contextMenu.options, disabled: contextMenu.disabled, slot: "buttons", variant: "primary", iconName: "more", size: "small", onItemSelected: contextMenu.onItemSelected }))));
}
function getButtonVariant(icon, isActive) {
    if (icon === 'play') {
        return 'glass';
    }
    if (isActive) {
        return 'accent';
    }
    return undefined;
}
function getUpsellBadgeTier(badgeInterface) {
    switch (badgeInterface) {
        case UNBORDERED_BADGE_ELEMENT:
            return HorizontalItemBadgeStyle.adFree;
        case LIGHT_UNBORDERED_BADGE_ELEMENT:
            return HorizontalItemBadgeStyle.exclusive;
        case ACCENT_OUTLINE_BADGE_ELEMENT:
            return HorizontalItemBadgeStyle.primeTierExclusive;
        default:
            return HorizontalItemBadgeStyle.adFree;
    }
}
export default forwardRef(HorizontalItem);
