import { ClientState } from '../types/ClientState';

export class HidePromptPreferenceClientState extends ClientState {
    public preferenceMap: {};

    public __type: string;

    constructor(preferenceMap) {
        super();
        this.preferenceMap = preferenceMap;
        this.__type =
            'Podcast.FollowPromptInterface.v1_0#HidePromptPreferenceClientState';
    }
}
