import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import HorizontalProgressBar from './HorizontalProgressBar';
import * as Styles from './Histogram.scss';
import * as textStyles from '../../styles/text.scss';
import { bindHandler } from '../../utils';
import Widget from './index';
export default function Histogram(props) {
    var _a, _b;
    useStyles(Styles, textStyles);
    const { heading, horizontalProgressBars, buttons, widgetElement, explanationHeader, explanation, } = props.data;
    const [expanded, setExpanded] = React.useState(false);
    const onClick = () => {
        setExpanded(!expanded);
    };
    const labelLength = ((_b = (_a = horizontalProgressBars[0]) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.length) || 0;
    const labelWidth = `${labelLength * 7}px`;
    return (React.createElement("div", { className: Styles.histogramContainer, "aria-labelledby": "histogramHeading", "aria-describedby": "histogramHeading", "aria-live": "polite", tabIndex: 0 },
        React.createElement("div", { className: textStyles.heading, "aria-label": heading, id: "histogramHeading" }, heading),
        widgetElement && (React.createElement("div", { className: Styles.marginBottom },
            React.createElement(Widget, { data: widgetElement, handleSelected: props.handleSelected }))),
        React.createElement("div", { className: Styles.marginBottom }, horizontalProgressBars.map((horizontalProgressBar) => (React.createElement("div", { className: Styles.histogramRow, key: horizontalProgressBar.label },
            React.createElement(HorizontalProgressBar, { data: horizontalProgressBar, handleSelected: props.handleSelected, labelWidth: labelWidth }))))),
        React.createElement("div", { onClick: onClick, onKeyDown: onClick, className: Styles.expander, tabIndex: 0, role: "button", "aria-live": "polite", "aria-label": explanationHeader, "aria-describedby": "explanation" },
            React.createElement("p", { className: Styles.explanationHeader },
                explanationHeader,
                React.createElement("span", { className: Styles.expanderIcon },
                    React.createElement("music-button", { "icon-name": expanded ? 'caretUp' : 'caretdown', variant: "primary", size: "small", "icon-only": "true", onmusicActivate: onClick }))),
            expanded && (React.createElement("p", { className: Styles.explanation, id: "explanation" }, explanation))),
        React.createElement("div", null, buttons
            .filter((button) => button && button.text && button.onItemSelected)
            .map((button, index) => (React.createElement("div", { className: Styles.histogramButtons },
            React.createElement("music-button", { key: `${index}-${button.text}`, role: "button", disabled: button.disabled, size: "medium", onmusicActivate: bindHandler(props.handleSelected, null, button.onItemSelected), variant: "glass", style: { width: '100%' } }, button.text)))))));
}
