import { channelFor, PerformActionEvent } from './utils';

const VIDEO_STORY_PLAYBACK_CHANNEL = channelFor('videoStoryPlayback');

/**
 * Follows:
 * https://gitlab.aws.dev/amazonmusic/musicdragonfly/dragonfly/-/blob/main/apps/DragonFly/app/nativeModules/VideoStoryPlayback/VideoStoryPlaybackRequest.types.ts#L13
 */
export interface VideoStoryMetadata {
  title?: string;
  duration?: number;
  artworkUrl?: string;
}

/**
 * Excluding the metadata field, all the fields are the query parameters required by PlayVideoStorySkill
 * @see https://code.amazon.com/packages/DMSF_Skills/blobs/bad7a22ba6cb25484f47d3da625160f2ed0eabdb/--/skills/Tracks.json#L27
 */
export interface StartVideoStoryPlaybackPayload {
  catalogId: string;
  autoplay?: boolean;
  artistAsin?: string;
  deeplink?: string;
  metadata?: VideoStoryMetadata;
}

export const StartVideoStoryPlaybackEvent = new PerformActionEvent<StartVideoStoryPlaybackPayload>({
  channel: VIDEO_STORY_PLAYBACK_CHANNEL,
  topic: 'startVideoStoryPlayback',
});
