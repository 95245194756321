import { useEffect, useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { throttle } from '../utils/eventUtils';
import { NOW_PLAYING_TEMPLATE } from '../types/templates/nowPlaying';
import { VIDEO_NOW_PLAYING_TEMPLATE, } from '../types/templates/videoNowPlaying';
import { ENABLE_MSHOP_BOTTOM_OVERRIDE } from '../actions';
export default function IOSMShopHandler() {
    const dispatch = useDispatch();
    const { overlayTemplates } = useSelector((state) => state.TemplateStack);
    const media = useSelector((state) => state.Media);
    const throttledScrollEventListener = useCallback(throttle(scrollEventCallback, 100), []);
    const resizeObserver = useRef(new ResizeObserver(() => {
        throttledScrollEventListener();
    }));
    const rootElement = useRef(null);
    const [scrollEventListenerAdded, setScrollEventAdded] = useState(false);
    const hasOverlay = overlayTemplates.length > 0;
    const isAudioNPVShowing = hasOverlay &&
        !!overlayTemplates.find((template) => template.interface === NOW_PLAYING_TEMPLATE);
    const videoNPVTemplate = overlayTemplates.find((template) => template.interface === VIDEO_NOW_PLAYING_TEMPLATE);
    const isVideoNPVShowing = hasOverlay && !!videoNPVTemplate;
    const isNowPlaying = isAudioNPVShowing || isVideoNPVShowing;
    const dismissUAPBottomBar = () => {
        var _a, _b;
        return (_b = (_a = window.P) === null || _a === void 0 ? void 0 : _a.when('UAPBottomBar')) === null || _b === void 0 ? void 0 : _b.execute((feature) => {
            feature.dismiss();
        });
    };
    useEffect(() => {
        rootElement.current = document.getElementById('root');
        return () => {
            window.removeEventListener('scroll', throttledScrollEventListener);
            if (resizeObserver.current && rootElement.current) {
                resizeObserver.current.unobserve(rootElement.current);
            }
        };
    }, []);
    useEffect(() => {
        // Assign listeners only when playback started and transport component is visible
        if (media && (media === null || media === void 0 ? void 0 : media.mediaId) && !isNowPlaying && !scrollEventListenerAdded) {
            setScrollEventAdded(true);
            // scroll event listener to check whether we have reached end of page
            window.addEventListener('scroll', throttledScrollEventListener);
            // ResizeObserver to check for height modifications happening due to view engine
            if (window.ResizeObserver && rootElement.current) {
                resizeObserver.current.observe(rootElement.current);
            }
        }
    }, [isNowPlaying, media === null || media === void 0 ? void 0 : media.mediaId]);
    /**
     * Dismiss the UnifiedAudioPlayer bottombar component for iOS mShop device.
     */
    useEffect(() => {
        if (window.app) {
            dismissUAPBottomBar();
            /**
             * We are adding willReappear method which is called whenever the RWP page reappears on
             * page switches in mShop application.
             * Reference sage: https://sage.amazon.dev/posts/600676?t=7
             * */
            window.app.willReappear = dismissUAPBottomBar;
        }
        return () => {
            window.app.willReappear = null;
        };
    }, []);
    function scrollEventCallback() {
        // check whether we have reached the bottom of the page by calculating root element height
        // and vertical scroll
        const enableMShopBottomOverride = rootElement.current &&
            window.innerHeight + window.scrollY >= rootElement.current.offsetHeight;
        dispatch({
            type: ENABLE_MSHOP_BOTTOM_OVERRIDE,
            payload: { enableMShopBottomOverride: !enableMShopBottomOverride },
        });
    }
    return null;
}
