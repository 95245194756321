exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3-_Zo-vekkXOsIkuz-W1Zp{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._3-_Zo-vekkXOsIkuz-W1Zp{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._3-_Zo-vekkXOsIkuz-W1Zp{padding-inline-start:52px;padding-inline-end:52px}}._35Bdl6IbxDlJ4y7Nay-AY2{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._35Bdl6IbxDlJ4y7Nay-AY2{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._35Bdl6IbxDlJ4y7Nay-AY2{margin-inline-start:52px;margin-inline-end:52px}}._3uFZzNFW7xLgRrL8vyVoq0{position:relative;margin-top:var(--music-spacer-huge);margin-bottom:var(--music-spacer-huge);margin-inline-start:52px}@media(max-width: 1280px){._3uFZzNFW7xLgRrL8vyVoq0{margin-inline-start:var(--music-spacer-giant)}}@media(max-width: 480px){._3uFZzNFW7xLgRrL8vyVoq0{margin-inline-start:var(--music-spacer-medium);margin-inline-end:var(--music-spacer-medium)}}._1O3U8Kr_2bDj_ZJTsYL7-2{margin:auto;width:-moz-fit-content;width:fit-content;align-items:flex-start}._1O3U8Kr_2bDj_ZJTsYL7-2 div{display:flex;justify-content:center;text-align:center}._1O3U8Kr_2bDj_ZJTsYL7-2 ._3sAx2Z_hZsRlsfofv_8GV5{text-align:center;margin-top:var(--music-spacer-medium);margin-bottom:var(--music-spacer-small)}._1O3U8Kr_2bDj_ZJTsYL7-2 music-link{margin-inline-end:var(--music-spacer-medium)}._1O3U8Kr_2bDj_ZJTsYL7-2 music-image{margin-inline-end:var(--music-spacer-small)}._3sAx2Z_hZsRlsfofv_8GV5{font-size:20px;margin-top:0;margin-bottom:var(--music-spacer-mini)}@media(max-width: 639px){._3sAx2Z_hZsRlsfofv_8GV5{font-size:18px}}._4AzwwTqh5KAkFmeiAlmL0{color:rgba(255,255,255,.6);max-width:560px;margin:0;line-height:var(--music-spacer-medium);white-space:pre-line}@media(max-width: 639px){._3YT3KMNBCQO1JHyKfS13GR{width:100%}._3YT3KMNBCQO1JHyKfS13GR music-button{width:100%}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_3-_Zo-vekkXOsIkuz-W1Zp",
	"gridSides": "_3-_Zo-vekkXOsIkuz-W1Zp",
	"grid-sides-margins": "_35Bdl6IbxDlJ4y7Nay-AY2",
	"gridSidesMargins": "_35Bdl6IbxDlJ4y7Nay-AY2",
	"section-container": "_3uFZzNFW7xLgRrL8vyVoq0",
	"sectionContainer": "_3uFZzNFW7xLgRrL8vyVoq0",
	"is-center-items": "_1O3U8Kr_2bDj_ZJTsYL7-2",
	"isCenterItems": "_1O3U8Kr_2bDj_ZJTsYL7-2",
	"primary-header": "_3sAx2Z_hZsRlsfofv_8GV5",
	"primaryHeader": "_3sAx2Z_hZsRlsfofv_8GV5",
	"text-element": "_4AzwwTqh5KAkFmeiAlmL0",
	"textElement": "_4AzwwTqh5KAkFmeiAlmL0",
	"button-container": "_3YT3KMNBCQO1JHyKfS13GR",
	"buttonContainer": "_3YT3KMNBCQO1JHyKfS13GR"
};