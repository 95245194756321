import { Store } from 'redux';
import {
    AppSyncTypes,
    BOOKMARKS_LAST_SYNC_TIME,
    BOOKMARKS_LOWER_BOUND_PROGRESS_MS,
    BOOKMARKS_SET_ON_REPORT_OPERATIONS_METHOD,
    BOOKMARKS_UPPER_BOUND_PERCENTAGE,
    BOOKMARKS_LAST_SYNC_TIME_CLIENT_STATE,
} from '../appsync/AppSyncConstants';
import { Entry } from '../appsync/Entry';
import { OperationElement } from '../appsync/OperationElement';
import { SyncData } from '../appsync/SyncData';
import { TimeConditionElement } from '../appsync/TimeConditionElement';
import {
    IConditionElement,
    ITimeConditionElement,
} from '../types/appsync/IAppSync';
import IApplicationState from '../types/IApplicationState';
import { Bookmark } from './Bookmark';
import { BookmarkMetadataWriteElement } from './BookmarkMetadataWriteElement';
import { BookmarkOperation } from './BookmarkOperation';
import { BookmarkOperationsClientState } from './BookmarkOperationClientState';
import { BookmarkSetOnReportOperationsMethod } from './BookmarkSetOnReportOperationsMethod';
import { BookmarksLastSyncTimeClientState } from './BookmarksLastSyncTimeClientState';
import { BookmarkWriteElement } from './BookmarkWriteElement';
import { IBookmarkDao } from './IBookmarkDao';
import { IBookmarkOperationDao } from './IBookmarkOperationDao';

export class BookmarkSyncData extends SyncData<
    BookmarkWriteElement,
    BookmarkMetadataWriteElement,
    Bookmark
> {
    private bookmarkDao: IBookmarkDao;

    private bookmarkOperationDao: IBookmarkOperationDao;

    private store: Store<IApplicationState>;

    constructor(
        bookmarkDao: IBookmarkDao,
        bookmarkOperationDao: IBookmarkOperationDao,
        store
    ) {
        super();
        this.bookmarkDao = bookmarkDao;
        this.bookmarkOperationDao = bookmarkOperationDao;
        this.store = store;
    }

    public read(id: string): Bookmark | undefined {
        const { customerId } = this.store.getState().Authentication;
        const bookmark = this.bookmarkDao.getBookmark(id, customerId);
        if (bookmark) {
            return bookmark;
        }
        return undefined;
    }

    public readWithBounds(id: string): Bookmark | undefined {
        const { customerId } = this.store.getState().Authentication;
        const bookmark = this.bookmarkDao.getBookmarkBounds(
            id,
            customerId,
            BOOKMARKS_LOWER_BOUND_PROGRESS_MS,
            BOOKMARKS_UPPER_BOUND_PERCENTAGE
        );
        if (bookmark) {
            return bookmark;
        }
        return undefined;
    }

    public readStates(): Bookmark[] {
        const { customerId } = this.store.getState().Authentication;
        return this.bookmarkDao.getAll(
            customerId,
            BOOKMARKS_LOWER_BOUND_PROGRESS_MS,
            BOOKMARKS_UPPER_BOUND_PERCENTAGE
        );
    }

    public readStatesByPodcastId(podcastId: string): Bookmark[] {
        const { customerId } = this.store.getState().Authentication;
        return this.bookmarkDao.getPodcastAll(
            customerId,
            podcastId,
            BOOKMARKS_LOWER_BOUND_PROGRESS_MS,
            BOOKMARKS_UPPER_BOUND_PERCENTAGE
        );
    }

    public async write(
        element: BookmarkWriteElement,
        condition?: IConditionElement
    ) {
        const timeCondition = condition as ITimeConditionElement;
        const isConditionSatified = this.conditionSatisfied(
            element,
            timeCondition!
        );
        if (isConditionSatified === false) {
            return;
        }
        const updatedElement = await this.writeStates(element, timeCondition);
        if (condition != null) {
            return;
        }
        this.writeToOperations(updatedElement);
        this.reportOperations();
    }

    public async writeStates(
        element: BookmarkWriteElement,
        condition?: IConditionElement
    ): Promise<BookmarkWriteElement> {
        const timeCondition = condition as ITimeConditionElement;
        const updatedTime = timeCondition?.updatedTime ?? Date.now();

        if (element.progressMilliSeconds == null) {
            const progressFromLocal = this.read(element.id)
                ?.progressMilliSeconds;
            if (progressFromLocal && progressFromLocal > 0) {
                element.progressMilliSeconds = progressFromLocal;
            } else {
                element.progressMilliSeconds = 0;
            }
        }

        element.progressMilliSeconds = await this.progress(
            element,
            timeCondition
        );

        const bookmark = new Bookmark({
            episodeId: element.id,
            podcastId: element.podcastId,
            podcastTitle: element.podcastTitle,
            title: element.title,
            image: element.image,
            podcastImage: element.podcastImage,
            description: element.description,
            authors: element.authors,
            seasonNumber: element.seasonNumber,
            availabilityDate: element.availabilityDate,
            availableUpsells: element.availableUpsells,
            playbackMode: element.playbackMode,
            contentTraits: element.contentTraits,
            totalDurationMilliseconds: element.totalDurationMilliseconds,
            progressMilliSeconds: element.progressMilliSeconds,
            updatedTime,
            publishTime: element.publishTime,
            podcastShowVariantId: element.podcastShowVariantId,
            podcastEpisodeVariantId: element.podcastEpisodeVariantId,
        });
        this.bookmarkDao.insert(bookmark);
        return element;
    }

    public writeToOperations(element: BookmarkWriteElement) {
        const bookmarkOperation = new BookmarkOperation({
            episodeId: element.id,
            podcastId: element.podcastId,
            podcastTitle: element.podcastTitle,
            title: element.title,
            image: element.image,
            podcastImage: element.podcastImage,
            description: element.description,
            authors: element.authors,
            seasonNumber: element.seasonNumber,
            availabilityDate: element.availabilityDate,
            availableUpsells: element.availableUpsells,
            playbackMode: element.playbackMode,
            contentTraits: element.contentTraits,
            totalDurationMilliseconds: element.totalDurationMilliseconds,
            progressMilliSeconds: element.progressMilliSeconds,
            podcastShowVariantId: element.podcastShowVariantId,
            podcastEpisodeVariantId: element.podcastEpisodeVariantId,
            updatedTime: Date.now(),
            isProcessing: false,
            publishTime: element.publishTime,
        });
        this.bookmarkOperationDao.insert(bookmarkOperation);
    }

    public writeMetadata(element: BookmarkMetadataWriteElement) {
        const bookmark = this.read(element.id);
        if (!bookmark) return;
        const updatedBookmark = new Bookmark({
            episodeId: element.id,
            podcastId: element.podcastId,
            podcastTitle: element.podcastTitle,
            title: element.title,
            image: element.image,
            podcastImage: element.podcastImage,
            description: element.description,
            authors: element.authors,
            seasonNumber: element.seasonNumber,
            publishTime: element.publishTime,
            availabilityDate: element.availabilityDate,
            availableUpsells: element.availableUpsells,
            playbackMode: element.playbackMode,
            contentTraits: element.contentTraits,
            podcastShowVariantId: element.podcastShowVariantId,
            podcastEpisodeVariantId: element.podcastEpisodeVariantId,
        });
        this.bookmarkDao.insert(updatedBookmark);
    }

    public reportOperations() {
        const operations = new BookmarkSetOnReportOperationsMethod();
        const onReportOperations = [operations];
        this.store.dispatch({
            type: BOOKMARKS_SET_ON_REPORT_OPERATIONS_METHOD,
            payload: { onReportOperations },
        });
    }

    public getClientState(name: string): Entry {
        if (name === BOOKMARKS_LAST_SYNC_TIME_CLIENT_STATE) {
            return this.getBookmarksLastSyncTimeClientState();
        }
        const operations = this.bookmarkOperationDao.getUnProcessed();
        const clientState = this.convertOperationsToClientState(operations);
        const ids: string[] = [];
        operations.forEach((operation) => {
            ids.push(operation.operationId);
        });
        this.bookmarkOperationDao.update(ids, true);
        return new Entry(AppSyncTypes.BOOKMARK, clientState);
    }

    public getBookmarksLastSyncTimeClientState(): Entry {
        const { customerId } = this.store.getState().Authentication;
        const lastSyncTime = this.bookmarkDao.getLastSyncTime(customerId);
        return new Entry(
            BOOKMARKS_LAST_SYNC_TIME,
            new BookmarksLastSyncTimeClientState(lastSyncTime)
        );
    }

    public deleteOperation(ids: string[]) {
        this.bookmarkOperationDao.delete(ids);
    }

    public conditionSatisfied(
        element: BookmarkWriteElement,
        condition: IConditionElement
    ): boolean {
        if (condition == null) {
            return true;
        }
        const timeCondition = condition as ITimeConditionElement;
        if (timeCondition == null) {
            return true;
        }
        const { customerId } = this.store.getState().Authentication;
        const bookmark = this.bookmarkDao.getBookmark(element.id, customerId);
        if (bookmark) {
            return timeCondition.updatedTime >= bookmark.updatedTime;
        }
        return true;
    }

    private convertOperationsToClientState(
        pendingOperations: BookmarkOperation[]
    ): BookmarkOperationsClientState {
        const operations: OperationElement[] = [];
        pendingOperations.forEach((operation) => {
            const writeElement: BookmarkWriteElement = this.convertOperationToBookmarkWriteElement(
                operation
            );
            const timeConditionElement: TimeConditionElement = new TimeConditionElement(
                operation.updatedTime
            );
            const operationElement: OperationElement = new OperationElement(
                operation.operationId,
                writeElement,
                timeConditionElement
            );
            operations.push(operationElement);
        });
        return new BookmarkOperationsClientState(operations);
    }

    private convertOperationToBookmarkWriteElement(
        operation: BookmarkOperation
    ): BookmarkWriteElement {
        return new BookmarkWriteElement(
            operation.episodeId,
            operation.podcastId,
            operation.podcastTitle,
            operation.title,
            operation.image,
            operation.podcastImage,
            operation.description,
            operation.authors,
            operation.seasonNumber,
            operation.totalDurationMilliseconds,
            operation.progressMilliSeconds,
            operation.publishTime
        );
    }

    private async progress(
        bookmarkWriteElement: BookmarkWriteElement,
        timeConditionElement: IConditionElement
    ): Promise<number> {
        const progressFromBookmarkElement =
            bookmarkWriteElement?.progressMilliSeconds ?? 0;
        if (timeConditionElement != null) {
            return progressFromBookmarkElement;
        }
        const player = await window.maestro.getInstance();
        if (player === false) {
            return progressFromBookmarkElement;
        }
        const progress = Math.round(player.getCurrentTime() * 1000);
        return progress === 0 ? progressFromBookmarkElement : progress;
    }
}
