import HTMLReactParser from 'html-react-parser';
import DOMPurify from 'dompurify';

export function parseOrStripHtml(text) {
    const cleanText = DOMPurify.sanitize(text);
    try {
        return HTMLReactParser(cleanText, {
            replace: transformHtml,
        });
    } catch (error) {
        return stripHtml(cleanText);
    }
}

export function parseOrStripEncodedHtml(text) {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(
        `<!doctype html><body>${text}`,
        'text/html'
    );
    const parsedText = parsedDocument?.body?.textContent ?? '';
    const cleanText = DOMPurify.sanitize(parsedText);
    try {
        return HTMLReactParser(cleanText, {
            replace: transformHtml,
        });
    } catch (error) {
        return stripHtml(cleanText);
    }
}

const stripHtml = (text: string) =>
    text == null ? '' : text.replace(/(<[^>]+>)|(&nbsp;)/gi, '');

export function transformHtml(node) {
    if (node.type === 'tag' && node.name === 'a') {
        node.attribs.target = '_blank';
    }
    return node;
}

export function addEscapedBackslashToDoubleQuotes(text) {
    return text == null ? '' : text.replace(/"/g, '\\"');
}
