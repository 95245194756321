const postal = typeof window !== 'undefined' ? window.postal : undefined;

const NAMESPACE_SEPARATOR = '.';
const EVENT_NAMESPACE = 'com.amazon.music.events';

export const WILDCARD_TOPIC = '*';

export function channelFor(moduleName: string): string {
  return EVENT_NAMESPACE + NAMESPACE_SEPARATOR + moduleName;
}

export interface IEvent {
  channel: string;
  topic: string;
}

// By specifying the type <TReturn, TArgs> of GetDataEvent we can enforce the return type of
// getData and the type of the provider function for provideData.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface GetDataEvent<TReturn, TArgs = never> {
  channel: string;
  topic: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class PerformActionEvent<TArgs = never> {
  channel: string;
  topic: string;
  preserve: boolean;

  constructor(event: IEvent) {
    this.channel = event.channel;
    this.topic = event.topic;
    this.preserve = false;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class StateChangedEvent<TState> {
  channel: string;
  topic: string;
  preserve: boolean;

  constructor(event: IEvent) {
    this.channel = event.channel;
    this.topic = event.topic;
    this.preserve = true;
  }
}

export async function getData<TReturn, TArgs>(event: GetDataEvent<TReturn, TArgs>, args?: TArgs): Promise<TReturn> {
  if (postal === undefined) {
    throw new Error('Event Bus cannot be used in SSR');
  }
  if (
    postal.getSubscribersFor({
      channel: event.channel,
      topic: event.topic,
    }).length === 0
  ) {
    return Promise.reject(new Error(`No providers registered for ${event.channel}.${event.topic}`));
  }
  return await postal.channel<TReturn>(event.channel).request({
    topic: event.topic,
    data: args,
  });
}

export function provideData<TReturn, TArgs>(
  event: GetDataEvent<TReturn, TArgs>,
  provider: (_: TArgs) => Promise<TReturn>,
) {
  if (postal === undefined) {
    throw new Error('Event Bus cannot be used in SSR');
  }
  const callback = async (data: TArgs, envelope: IEnvelope<TArgs, TReturn>) => {
    try {
      const providedData = await provider(data);
      envelope.reply(null, providedData);
    } catch (error) {
      envelope.reply(error, null);
    }
  };
  return postal.channel<TArgs>(event.channel).subscribe(event.topic, callback);
}

export function publish<T>(event: PerformActionEvent<T> | StateChangedEvent<T>, data?: T): void {
  if (postal === undefined) {
    throw new Error('Event Bus cannot be used in SSR');
  }
  postal.channel<T>(event.channel).publish({
    topic: event.topic,
    data: data,
    headers: {
      preserve: event.preserve,
    },
  });
}

export function subscribe<T>(
  event: PerformActionEvent<T> | StateChangedEvent<T>,
  handler: (data: T) => void,
  enlistPreserved = false,
) {
  if (postal === undefined) {
    throw new Error('Event Bus cannot be used in SSR');
  }
  const subscriptionDefinition = postal.channel<T>(event.channel).subscribe(event.topic, handler);
  if (enlistPreserved) {
    return subscriptionDefinition.enlistPreserved();
  }
  return subscriptionDefinition;
}

/**
 * Returns the latest state for a previously emitted StateChangedEvent or undefined if there was
 * no previously emitted event.
 */
export function getState<TState>(event: StateChangedEvent<TState>): TState | undefined {
  if (postal === undefined) {
    throw new Error('Event Bus cannot be used in SSR');
  }
  const envelope = postal.preserve.store[event.channel]?.[event.topic];
  return envelope?.data;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventType = PerformActionEvent | StateChangedEvent<any>;
