import ISkyfireMethod from '../../ISkyfireMethod';
import ISkyfireEpisodeGroupItemElement from './items/ISkyfireEpisodeGroupItemElement';

export const EPISODE_GROUP_ITEMS_WIDGET =
    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.EpisodeGroupItemElements';

export default interface IEpisodeGroupItemsWidget {
    interface: typeof EPISODE_GROUP_ITEMS_WIDGET;
    items: ISkyfireEpisodeGroupItemElement[];
    onEndOfWidget: ISkyfireMethod[];
    onViewed: ISkyfireMethod[];
}
