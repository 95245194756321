import React, { Fragment } from 'react';
import { useSelector, useStore } from 'react-redux';
import IApplicationState from '../../types/IApplicationState';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import ISkyfireMessageTemplate from '../../types/templates/message';
import { getVisualListingGridSizes } from '../../utils/gridHelpers';
import Message from '../../views/Message';
import InfiniteGrid from './items/InfiniteGrid';
import VerticalItem from './items/VerticalItem';
import menuStyles from '../../views/Menu.scss';
import Styles from './VisualListing.scss';
import IPodcastLibraryShows from '../../types/templates/widgets/IPodcastLibraryShows';
import { FollowDaoSessionStorage } from '../../Follow/FollowDaoSessionStorage';
import ISkyfireLinkElement from '../../types/ISkyfireLinkElement';
import { invokeHttpPostSkillMethod } from '../../utils/invokeHttpPostSkillMethod';
import ISkyfireTextElement from '../../types/templates/widgets/ISkyfireTextElement';
import { SQUARE_VERTICAL_ITEM } from '../../types/templates/widgets/items/ISkyfireVerticalItem';
import {
    UIMetrics,
    ActionType,
    ContentName,
    PageType,
    EntityType,
    EntityIdType,
} from '../../UIMetrics';
import viewStyles from '../../views/View.scss';
import { useItemsInView } from '../../utils/useItemsInView';
import { podcastDeeplink } from '../../utils/deeplinks';

interface IPodcastLibraryShowsProps {
    data: IPodcastLibraryShows;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    onViewed?: () => void;
}

export function PodcastLibraryShows(props: IPodcastLibraryShowsProps) {
    const callbackRef = useItemsInView(props.onViewed);
    const { data, handleSelected } = props;
    const { header, button, fallbackMessage } = data;
    const { windowWidth, customerId } = useSelector(
        (state: IApplicationState) => state.BrowserState
    );

    const storage = new FollowDaoSessionStorage(useStore());
    const items = storage.getAll(customerId, true).map((followEntry, index) => {
        let onPodcastSelected: ISkyfireMethod[] = [
            // @ts-ignore
            invokeHttpPostSkillMethod(`/podcasts/${followEntry.id}`),
        ];
        onPodcastSelected = onPodcastSelected.concat(
            UIMetrics.onClicked({
                actionType: ActionType.SELECT_PODCAST_SHOW,
                contentName: ContentName.PODCASTS_LIBRARY_SHOWS,
                pageType: PageType.PODCASTS_LIBRARY_SHOWS,
                entityType: EntityType.PODCAST_SHOW,
                entityIdType: EntityIdType.PODCAST_SHOW_ID,
                entityId: getPodcastEntityId(followEntry),
                entityPos: index,
            })
        );
        const podcastLink: ISkyfireLinkElement = {
            deeplink: podcastDeeplink(followEntry),
            onItemSelected: onPodcastSelected,
        };
        const podcastText: ISkyfireTextElement = {
            text: followEntry.title,
            disabled: false,
        };
        // @ts-ignore
        const item: ISkyfireSquareVerticalItem = {
            image: followEntry.image,
            imageAltText: followEntry.title,
            primaryLink: podcastLink,
            actionIconLink: podcastLink,
            secondaryText: followEntry.publisher!,
            actionIconName: 'caretright',
            isDisabled: false,
            primaryText: podcastText,
            interface: SQUARE_VERTICAL_ITEM,
        };
        return item;
    });

    data.items = items;

    const onButtonClick = button
        ? () => handleSelected(button?.primaryLink?.onItemSelected)
        : null;

    const emptyMessageTemplate = {
        message: fallbackMessage,
    };

    return data.items.length > 0 ? (
        <Fragment>
            {header && (
                <h1 className={`music-header-4 ${Styles.header}`}>{header}</h1>
            )}
            {button && (
                <div className={Styles['button-container']}>
                    <music-button
                        icon-name={button.icon}
                        size='medium'
                        onmusicActivate={onButtonClick}
                    >
                        {button.text}
                    </music-button>
                </div>
            )}
            <InfiniteGrid
                data={data}
                handleSelected={props.handleSelected}
                componentType={VerticalItem}
                gridSizes={getVisualListingGridSizes(windowWidth)}
                itemsViewedRef={callbackRef}
            />
        </Fragment>
    ) : (
        <div
            className={
                menuStyles['message-container'] + viewStyles['view-content']
            }
        >
            <Message
                template={emptyMessageTemplate as ISkyfireMessageTemplate}
            />
        </div>
    );
}

function getPodcastEntityId(element) {
    const {
        isRedhoodPlusMetricsEnabled,
        isRedhoodSonicRushEnabled,
    } = window.amznMusic.appConfig;
    return element.podcastShowVariantId &&
        isRedhoodPlusMetricsEnabled &&
        isRedhoodSonicRushEnabled
        ? element.podcastShowVariantId
        : element.id;
}
