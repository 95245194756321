import { channelFor, PerformActionEvent, StateChangedEvent } from './utils';
import ISkyfireElement from '../types/ISkyfireElement';
import ISkyfireQueue from '../types/ISkyfireQueue';
import ISkyfireSearchSuggestionsData from '../types/templates/searchSuggestions';

const SEARCH_CHANNEL = channelFor('search');

export interface SearchState {
  isSearchBoxFocused: boolean;
  isSearchBoxEmpty: boolean;
  shouldShowSearchSuggestions: boolean;
  searchSuggestionsData?: ISkyfireSearchSuggestionsData;
  searchTerm: { text: string; forceUpdateTime: number };
  searchBoxInput: string;
}

export const SearchStateChangedEvent = new StateChangedEvent<SearchState>({
  channel: SEARCH_CHANNEL,
  topic: 'all',
});

export interface UpdateSearchSuggestionsPayload {
  queue: ISkyfireQueue;
  title: string;
  items: ISkyfireElement[];
  keyword: string;
  noSuggestionsString: string;
  showLineSeparator: boolean;
}

export interface UpdateSearchTermPayload {
  text: string;
  forceUpdateTime: number;
}

export interface UpdateSearchBoxPayload {
  isSearchBoxFocused: boolean;
  searchBoxInput?: string;
}

export const UpdateSearchSuggestionsEvent = new PerformActionEvent<UpdateSearchSuggestionsPayload>({
  channel: SEARCH_CHANNEL,
  topic: 'updateSearchSuggestions',
});

export const RemoveSearchSuggestionsEvent = new PerformActionEvent({
  channel: SEARCH_CHANNEL,
  topic: 'removeSearchSuggestions',
});

export const UpdateSearchTermEvent = new PerformActionEvent<UpdateSearchTermPayload>({
  channel: SEARCH_CHANNEL,
  topic: 'updateSearchTerm',
});

export const UpdateSearchBoxEvent = new PerformActionEvent<UpdateSearchBoxPayload>({
  channel: SEARCH_CHANNEL,
  topic: 'updateSearchBox',
});
