import { channelFor, PerformActionEvent, StateChangedEvent, WILDCARD_TOPIC } from './utils';
import { AppName } from '../constants';

const DEEPLINK_CHANNEL = channelFor('deeplink');

export interface UpdateHistoryPayload {
  sourceApp: AppName;
  path: string;
  search: string;
  replace: boolean;
}

export interface GoHistoryPayload {
  sourceApp: AppName;
  delta: number;
}

export interface PopHistoryPayload {
  sourceApp: AppName;
}

export interface OnPopStatePayload {
  newApp: AppName;
  newUrl: string;
}

export interface PathChangedPayload {
  activeApp: AppName;
  path: string;
}

export const GoToDeeplinkEvent = new PerformActionEvent<string>({
  channel: DEEPLINK_CHANNEL,
  topic: 'goToDeeplink',
});

export function GoToDeeplinkInternalEvent(
  appName: AppName | typeof WILDCARD_TOPIC = WILDCARD_TOPIC,
): PerformActionEvent<string> {
  return new PerformActionEvent<string>({
    channel: DEEPLINK_CHANNEL,
    topic: `goToDeeplink.${appName}`,
  });
}

export const UpdateHistoryEvent = new PerformActionEvent<UpdateHistoryPayload>({
  channel: DEEPLINK_CHANNEL,
  topic: 'updateHistory',
});

export const GoHistoryEvent = new PerformActionEvent<GoHistoryPayload>({
  channel: DEEPLINK_CHANNEL,
  topic: 'goHistory',
});

export const PopHistoryEvent = new PerformActionEvent<PopHistoryPayload>({
  channel: DEEPLINK_CHANNEL,
  topic: 'popHistory',
});

export const OnPopStateEvent = new StateChangedEvent<OnPopStatePayload>({
  channel: DEEPLINK_CHANNEL,
  topic: 'onPopState',
});

export const PathChangedEvent = new StateChangedEvent<PathChangedPayload>({
  channel: DEEPLINK_CHANNEL,
  topic: 'pathChanged',
});
