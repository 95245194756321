import { SHOW_VIDEO_CONTROL, HIDE_VIDEO_CONTROL, SHOW_VIDEO_ANIMATION, HIDE_VIDEO_ANIMATION, } from '../actions/Playback';
const initialState = {
    showControls: false,
    showAnimation: true,
};
export function VideoNPVStateReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_VIDEO_CONTROL:
            return Object.assign(Object.assign({}, state), { showAnimation: !state.showControls, showControls: true });
        case HIDE_VIDEO_CONTROL:
            return Object.assign(Object.assign({}, state), { showControls: false, showAnimation: false });
        case SHOW_VIDEO_ANIMATION:
            return Object.assign(Object.assign({}, state), { showAnimation: true });
        case HIDE_VIDEO_ANIMATION:
            return Object.assign(Object.assign({}, state), { showAnimation: false });
        default:
            return state;
    }
}
