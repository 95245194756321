import _postal from 'postal';
import postalRR from 'postal.request-response';

declare global {
  interface Window {
    postal?: IPostal;
  }
}

// When loading postal.request-response via webpack, the default import is an object, when loading
// it in unit tests, the default import is a function.
function getPostal() {
  if (typeof postalRR === 'function') {
    return postalRR(_postal);
  } else {
    return postalRR;
  }
}

function setUpPostalPreserve(postal: IPostal) {
  const plugin: IPreserve = (postal.preserve = {
    store: {},
  });

  postal.addWireTap((data, envelope) => {
    const topic = envelope.topic;
    const channel = envelope.channel ?? postal.configuration.DEFAULT_CHANNEL;
    if (envelope.headers?.preserve) {
      plugin.store[channel] = plugin.store[channel] || {};
      plugin.store[channel][topic] = envelope;
    }
  });

  postal.SubscriptionDefinition.prototype.enlistPreserved = function () {
    const { channel, topic } = this;
    // Currently this does a direct match of the topic. If we need wildcard support, it can be
    // added by looping through all the topics and using postal.configuration.resolver.compare()
    // to compare them.
    const envelope = plugin.store[channel]?.[topic];
    if (envelope) {
      this.callback.call(
        this.context || (this.callback.context && this.callback.context()) || this,
        envelope.data,
        envelope,
      );
    }
    return this;
  };
}

export default function setUpPostalJS() {
  if (typeof window === 'undefined') return;
  if (window.postal !== undefined) return;
  window.postal = getPostal();

  window.postal.configuration.promise.createDeferred = function <T>() {
    const deferred: Partial<IDeferred<T>> = {};
    deferred.promise = new Promise<T>((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });
    return deferred as IDeferred<T>;
  };

  window.postal.configuration.promise.getPromise = function <T>(deferred: IDeferred<T>) {
    return deferred.promise;
  };

  // When passing in a "preserve" header, store the latest value.
  setUpPostalPreserve(window.postal);
}
