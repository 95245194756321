exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1vO9K-GG10rEBFm7ZrMxYm{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._1vO9K-GG10rEBFm7ZrMxYm{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._1vO9K-GG10rEBFm7ZrMxYm{padding-inline-start:52px;padding-inline-end:52px}}._7soDpuJJrUt4AYJibkrxA{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._7soDpuJJrUt4AYJibkrxA{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._7soDpuJJrUt4AYJibkrxA{margin-inline-start:52px;margin-inline-end:52px}}._2mtIvNP16HnWvjgFFlajFD{position:fixed;inset-inline-start:0;bottom:0;width:100%;padding:var(--music-spacer-base);z-index:10;background-color:#0e2f36}._1cwyg50EgUjzXqIRpddTcJ{max-width:1600px;margin:auto;display:flex;flex-direction:column;gap:var(--music-spacer-medium);color:#fff}.y3ci-AEIoFItEIvud5YgK a{color:#1ad2fb;text-decoration:none}._z3h-8Y2-687EJnhugD7G{display:block}@media(max-width: 840px){._z3h-8Y2-687EJnhugD7G{display:none}}.-_DXjUsDDEcbUzyqcKW8l{display:none}@media(max-width: 840px){.-_DXjUsDDEcbUzyqcKW8l{display:block}}.qw5VArLD-x88jBm7br8r{overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:4;line-clamp:4}._2k4_uChWrrBryfOq8c3nfP{display:flex;flex-direction:row;align-items:center;cursor:pointer;margin-top:var(--music-spacer-small)}._3BXe4PBR2jHlWln-2rt3vd{display:flex;flex-direction:row;align-items:center;gap:var(--music-spacer-base)}._3BXe4PBR2jHlWln-2rt3vd music-link{cursor:pointer}@media(max-width: 640px){._3BXe4PBR2jHlWln-2rt3vd{flex-direction:column}._3BXe4PBR2jHlWln-2rt3vd music-button{width:100%;max-width:480px}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_1vO9K-GG10rEBFm7ZrMxYm",
	"gridSides": "_1vO9K-GG10rEBFm7ZrMxYm",
	"grid-sides-margins": "_7soDpuJJrUt4AYJibkrxA",
	"gridSidesMargins": "_7soDpuJJrUt4AYJibkrxA",
	"banner-container": "_2mtIvNP16HnWvjgFFlajFD",
	"bannerContainer": "_2mtIvNP16HnWvjgFFlajFD",
	"banner-content": "_1cwyg50EgUjzXqIRpddTcJ",
	"bannerContent": "_1cwyg50EgUjzXqIRpddTcJ",
	"banner-body": "y3ci-AEIoFItEIvud5YgK",
	"bannerBody": "y3ci-AEIoFItEIvud5YgK",
	"banner-full": "_z3h-8Y2-687EJnhugD7G",
	"bannerFull": "_z3h-8Y2-687EJnhugD7G",
	"banner-truncated": "-_DXjUsDDEcbUzyqcKW8l",
	"bannerTruncated": "-_DXjUsDDEcbUzyqcKW8l",
	"banner-truncated-text": "qw5VArLD-x88jBm7br8r",
	"bannerTruncatedText": "qw5VArLD-x88jBm7br8r",
	"expandable-text-controls": "_2k4_uChWrrBryfOq8c3nfP",
	"expandableTextControls": "_2k4_uChWrrBryfOq8c3nfP",
	"banner-footer": "_3BXe4PBR2jHlWln-2rt3vd",
	"bannerFooter": "_3BXe4PBR2jHlWln-2rt3vd"
};