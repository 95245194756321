import ISkyfireLinkElement from '../../ISkyfireLinkElement';
import { ISkyfireDetailHeaderItemElement } from './ISkyfireDetailHeaderItemElement';
import ISkyfireTemplateData from '../ISkyfireTemplateData';
import ISkyfireTextElement from '../widgets/ISkyfireTextElement';
import ISkyfireEpisodeFeaturedElement from './ISkyfireEpisodeFeaturedElement';
import SkyfireWidgetElement from '../widgets/ISkyfireWidgetElement';
import ISkyfireButtonElement from '../widgets/ISkyfireButtonElement';
import ISkyfireCompactPillNavigatorWidgetElement from '../widgets/ISkyfireCompactPillNavigatorWidgetElement';
import ImageDimension from '../widgets/ImageDimension';
import { ISkyfireCuratedDescriptionElement } from './ISkyfireCuratedDescriptionsElement';
import ISkyfireTemplate from '../ISkyfireTemplate';
import ISkyfireBorderedBadgeElement from '../widgets/ISkyfireBorderedBadgeElement';
import ISkyfireUnborderedBadgeElement from '../widgets/ISkyfireUnborderedBadgeElement';
import ISkyfireLightUnborderedBadgeElement from '../widgets/ISkyfireLightUnborderedBadgeElement';
import ISkyfireAccentOutlineBadgeElement from '../widgets/ISkyfireAccentOutlineBadgeElement';

export const PODCAST_DETAIL_TEMPLATE_V2 =
    'Podcast.Web.DetailTemplateInterface.DetailTemplateV2';

export interface ISkyfireDetailTemplateV2 extends ISkyfireTemplate {
    interface: typeof PODCAST_DETAIL_TEMPLATE_V2;
    header: ISkyfireTextElement;
    templateData: ISkyfireTemplateData;
    featuredItem?: ISkyfireEpisodeFeaturedElement;
    backgroundImage?: string;
    description?: string;
    widgets: SkyfireWidgetElement[];
    curatedDescriptionItem?: ISkyfireCuratedDescriptionElement;
    headerLabel?: string;
    headerButtons: ISkyfireDetailHeaderItemElement[];
    headerSecondaryText?: string;
    headerSecondaryTextLink?: ISkyfireLinkElement;
    headerTertiaryText?: string;
    headerIcon?: string;
    headerIconAltText?: string;
    headerImage?: string;
    headerImageAltText?: string;
    headerImageDimension?: ImageDimension;
    badge?:
        | ISkyfireUnborderedBadgeElement
        | ISkyfireBorderedBadgeElement
        | ISkyfireLightUnborderedBadgeElement
        | ISkyfireAccentOutlineBadgeElement;
    button?: ISkyfireButtonElement;
    text?: string;
    playButton?: ISkyfireButtonElement;
    compactPillNavigator?: ISkyfireCompactPillNavigatorWidgetElement;
}
