import { FOLLOWER_OPERATION_CLIENT_INFORMATION } from './AppSyncConstants';
import ISkyfireQueue from '../types/ISkyfireQueue';
import { getPodcastLibraryEnvironment } from '../utils';
import { SINGLE_THREADED_QUEUE } from '../utils/skyfireContants';
import { REPORT_FOLLOW_OPERATION_WEB_SKILL } from '../utils/podcastTargets';

export class FollowSetOnReportOperationsMethod {
    public interface: 'InteractionInterface.v1_0.InvokeHttpPostSkillMethod';

    public before: [];

    public after: [];

    public onError: [];

    public url: string = getPodcastLibraryEnvironment();

    public preset: string = '{}';

    public target: string = REPORT_FOLLOW_OPERATION_WEB_SKILL;

    public queue: ISkyfireQueue = {
        id: 'APPSYNC',
        interface: SINGLE_THREADED_QUEUE,
    };

    public forced: boolean = false;

    public clientInformation?: string[] = [
        FOLLOWER_OPERATION_CLIENT_INFORMATION,
    ];
}
