import { MediaPlayer } from 'dashjs';
import AbstractVideoPlayer from './AbstractVideoPlayer';
import HTML5VideoPlayerEvent from './HTML5VideoPlayerEvent';
export default class DashVideoPlayer extends AbstractVideoPlayer {
    static getInstance() {
        if (!DashVideoPlayer.instance) {
            DashVideoPlayer.instance = new DashVideoPlayer();
            DashVideoPlayer.instance.initialize();
        }
        return DashVideoPlayer.instance;
    }
    constructor() {
        super();
        this.addEventListener = (event, callback) => {
            if (!event.dashEvent) {
                throw new Error('Event is not supported for DashVideoPlayer');
            }
            this.player.on(event.dashEvent, callback);
        };
        this.destroyPlayer = () => {
            this.player.destroy();
            DashVideoPlayer.instance = undefined;
        };
        this.getCurrentTime = () => this.player.time();
        this.getDuration = () => this.player.duration();
        this.getLatestError = () => this.latestError;
        this.getVolume = () => this.player.getVolume();
        this.isEnded = () => this.ended;
        this.isPaused = () => this.player.isPaused();
        this.loadMedia = () => {
            this.player.preload();
            this.latestError = null;
        };
        this.pause = () => {
            this.player.pause();
        };
        this.playMedia = () => {
            this.player.play();
        };
        this.removeEventListener = (event, callback) => {
            if (event.dashEvent) {
                this.player.off(event.dashEvent, callback);
            }
        };
        this.seekToPosition = (position) => {
            this.ended = position === this.getDuration();
            this.player.seek(position);
        };
        this.setSource = (playbackUri) => {
            this.player.attachSource(playbackUri);
        };
        this.setAutoplay = (autoplay) => {
            this.player.setAutoPlay(autoplay);
        };
        this.unloadMedia = () => {
            this.latestError = null;
            this.player.reset();
            this.player.attachView(this.videoElement);
        };
        this.volume = (volume) => {
            this.player.setVolume(volume);
        };
        this.player = MediaPlayer().create();
        this.player.initialize(this.videoElement, undefined, false);
        this.ended = false;
        this.latestError = null;
        this.addEventListener(HTML5VideoPlayerEvent.PLAYING, () => {
            this.ended = false;
        });
        this.addEventListener(HTML5VideoPlayerEvent.ENDED, () => {
            this.ended = true;
        });
        this.addEventListener(HTML5VideoPlayerEvent.ERROR, (event) => {
            this.latestError = event;
        });
    }
}
