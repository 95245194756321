import * as PlaybackActions from '../actions/Playback';
import { getInstance } from '../player';
import { dispatchPlaybackMethods } from '../utils/dispatchPlaybackMethods';
import Performance from '../utils/performance';
import { PlaybackTimingActionPlay, PlaybackTimingMetricType, reportClientMetric, } from '../utils/reportClientMetrics';
export const PlaybackEventsMiddleware = (store) => (next) => (action) => {
    var _a, _b, _c, _d, _e, _f;
    const { isSamplePlayback } = store.getState().SampleMedia;
    const playbackEvents = isSamplePlayback
        ? store.getState().SamplePlaybackEvents
        : store.getState().PlaybackEvents;
    const playbackActions = store.getState().PlaybackActions;
    const playbackStates = store.getState().PlaybackStates;
    const { mediaId } = isSamplePlayback ? store.getState().SampleMedia : store.getState().Media;
    const template = store.getState().TemplateStack.currentTemplate;
    const auth = store.getState().Authentication;
    if (!template) {
        next(action);
        return;
    }
    switch (action.type) {
        case PlaybackActions.STARTED:
            dispatchPlaybackMethods(store.dispatch, mediaId, playbackEvents.onStarted);
            reportPlaybackMarks(PlaybackTimingActionPlay);
            break;
        case PlaybackActions.RESUMED:
            dispatchPlaybackMethods(store.dispatch, mediaId, playbackEvents.onResumed);
            break;
        case PlaybackActions.SCRUBBED:
            dispatchPlaybackMethods(store.dispatch, mediaId, playbackEvents.onScrubbed);
            if ((_a = playbackEvents.onCompletedThresholdElapsed) === null || _a === void 0 ? void 0 : _a.length) {
                handleOnCompletedThresholdElapsed();
            }
            break;
        case PlaybackActions.PAUSED:
            dispatchPlaybackMethods(store.dispatch, mediaId, playbackEvents.onPaused);
            if ((_b = playbackEvents.onCompletedThresholdElapsed) === null || _b === void 0 ? void 0 : _b.length) {
                handleOnCompletedThresholdElapsed();
            }
            break;
        case PlaybackActions.ENDED:
            dispatchPlaybackMethods(store.dispatch, mediaId, playbackEvents.onFinished);
            if ((_c = playbackEvents.onCompletedThresholdElapsed) === null || _c === void 0 ? void 0 : _c.length) {
                handleOnCompletedThresholdElapsed();
            }
            break;
        case PlaybackActions.STOPPED:
        case PlaybackActions.CLEAR:
            if ((_d = playbackEvents.onCompletedThresholdElapsed) === null || _d === void 0 ? void 0 : _d.length) {
                handleOnCompletedThresholdElapsed();
            }
            setPreviousPlaybackState();
            dispatchPlaybackMethods(store.dispatch, mediaId, playbackEvents.onStopped);
            break;
        case PlaybackActions.REBUFFERED:
            dispatchPlaybackMethods(store.dispatch, mediaId, playbackEvents.onRebuffered);
            break;
        case PlaybackActions.ERROR:
            dispatchPlaybackMethods(store.dispatch, mediaId, playbackEvents.onError);
            break;
        case PlaybackActions.PLAY_NEXT:
            if (playbackActions.onNext) {
                setPreviousPlaybackState();
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onNext);
            }
            if ((_e = playbackEvents.onCompletedThresholdElapsed) === null || _e === void 0 ? void 0 : _e.length) {
                handleOnCompletedThresholdElapsed();
            }
            break;
        case PlaybackActions.PLAY_PREVIOUS:
            if (playbackActions.onPrevious) {
                setPreviousPlaybackState();
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onPrevious);
            }
            if ((_f = playbackEvents.onCompletedThresholdElapsed) === null || _f === void 0 ? void 0 : _f.length) {
                handleOnCompletedThresholdElapsed();
            }
            break;
        case PlaybackActions.CLOSED_CAPTIONS_OFF:
            if (playbackActions.onClosedCaptionsOff) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onClosedCaptionsOff);
            }
            break;
        case PlaybackActions.CLOSED_CAPTIONS_ON:
            if (playbackActions.onClosedCaptionsOn) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onClosedCaptionsOn);
            }
            break;
        case PlaybackActions.REPEAT_ALL:
            if (playbackActions.onRepeatAll) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onRepeatAll);
            }
            break;
        case PlaybackActions.REPEAT_OFF:
            if (playbackActions.onRepeatOff) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onRepeatOff);
            }
            break;
        case PlaybackActions.REPEAT_DISABLED_SELECT:
            // eslint-disable-next-line no-case-declarations
            const { repeat } = store.getState().PlaybackStates;
            if (repeat && repeat.isDisabled) {
                dispatchPlaybackMethods(store.dispatch, mediaId, repeat.onDisabledRepeatControlSelected);
            }
            break;
        case PlaybackActions.REPEAT_ONE:
            if (playbackActions.onRepeatOne) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onRepeatOne);
            }
            break;
        case PlaybackActions.SHUFFLE_OFF:
            if (playbackActions.onShuffleOff) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onShuffleOff);
            }
            break;
        case PlaybackActions.SHUFFLE_DISABLED_SELECT:
            // eslint-disable-next-line no-case-declarations
            const { shuffle } = store.getState().PlaybackStates;
            if (shuffle && shuffle.isDisabled) {
                dispatchPlaybackMethods(store.dispatch, mediaId, shuffle.onDisabledShuffleControlSelected);
            }
            break;
        case PlaybackActions.SHUFFLE_ON:
            if (playbackActions.onShuffleOn) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onShuffleOn);
            }
            break;
        case PlaybackActions.THUMBS_DOWN:
            if (playbackActions.onThumbsDown) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onThumbsDown);
            }
            break;
        case PlaybackActions.THUMBS_UP:
            if (playbackActions.onThumbsUp) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onThumbsUp);
            }
            break;
        case PlaybackActions.UNDO_THUMBS_DOWN:
            if (playbackActions.onUndoThumbsDown) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onUndoThumbsDown);
            }
            break;
        case PlaybackActions.UNDO_THUMBS_UP:
            if (playbackActions.onUndoThumbsUp) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onUndoThumbsUp);
            }
            break;
        case PlaybackActions.SET_VOLUME:
            if (playbackActions.onVolumeChange) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onVolumeChange);
            }
            break;
        case PlaybackActions.TOGGLE_TO_AUDIO:
            if (playbackActions.onToggleToAudio) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onToggleToAudio);
            }
            break;
        case PlaybackActions.TOGGLE_TO_VIDEO:
            if (playbackActions.onToggleToVideo) {
                dispatchPlaybackMethods(store.dispatch, mediaId, playbackActions.onToggleToVideo);
            }
            break;
        default:
            break;
    }
    next(action);
    function setPreviousPlaybackState() {
        var _a;
        store.dispatch({
            type: PlaybackActions.SET_PREVIOUS_PLAYBACK_STATE,
            payload: (_a = playbackStates.play) === null || _a === void 0 ? void 0 : _a.state,
        });
    }
    async function handleOnCompletedThresholdElapsed() {
        const player = await getInstance();
        if (!player) {
            return;
        }
        const progress = player.getCurrentTime() * 1000;
        const totalDurationMilliseconds = player.getDuration() * 1000;
        if (progress / totalDurationMilliseconds > 0.95) {
            dispatchPlaybackMethods(store.dispatch, mediaId, playbackEvents.onCompletedThresholdElapsed);
        }
    }
    function reportPlaybackMarks(name) {
        var _a;
        const markName = `${name}:${mediaId}`;
        Performance.measure(markName, markName);
        const entry = Performance.getEntriesByName(markName).pop();
        reportClientMetric(PlaybackTimingMetricType, { name, mediaId, duration: (_a = entry === null || entry === void 0 ? void 0 : entry.duration) !== null && _a !== void 0 ? _a : 0 }, auth);
        Performance.clearMarks(markName);
        Performance.clearMeasures();
    }
};
