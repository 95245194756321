const HTTPS = 'https://';

const AMAZON_DOMAINS = [
    'amazon.com',
    'amazon.ca',
    'amazon.com.mx',
    'amazon.com.br',
    'amazon.co.uk',
    'amazon.de',
    'amazon.fr',
    'amazon.it',
    'amazon.es',
    'amazon.in',
    'amazon.co.jp',
    'amazon.com.au',
];

export function getMusicDomain(): string {
    let musicDomain = window.location.hostname ?? 'music.amazon.com';
    const skyfireEnv = getParameterByName('skyfireEnv', window.location.href);
    if (skyfireEnv === 'local' || skyfireEnv === 'gamma') {
        const skyfireDomain = getParameterByName(
            'skyfireDomain',
            window.location.href
        );
        musicDomain = skyfireDomain || musicDomain;
    }
    return musicDomain;
}

export function getAmazonDomain(path?: string): string {
    const domain =
        AMAZON_DOMAINS.find((amazonDomain) =>
            window.location.hostname.includes(amazonDomain)
        ) ?? 'amazon.com';
    let httpsDomain = HTTPS.concat(domain);
    if (path) {
        httpsDomain = path.startsWith('/')
            ? httpsDomain.concat(path)
            : httpsDomain.concat('/', path);
    }
    return httpsDomain;
}

// Polyfill for IE11 and Edge URLSearchParams
export function getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
