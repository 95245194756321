import React from 'react';
import { useSelector, useStore } from 'react-redux';
import IApplicationState from 'src/types/IApplicationState';
import { FollowDaoSessionStorage } from '../../Follow';
import ISkyfireLinkElement from '../../types/ISkyfireLinkElement';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import IPodcastFollowsShoveler from '../../types/templates/widgets/IPodcastFollowsShoveler';
import {
    ISkyfireSquareVerticalItem,
    SQUARE_VERTICAL_ITEM,
} from '../../types/templates/widgets/items/ISkyfireVerticalItem';
import VerticalItem from './items/VerticalItem';
import {
    UIMetrics,
    EntityType,
    EntityIdType,
    ActionType,
    ContentName,
    PageType,
} from '../../UIMetrics';
import { invokeHttpPostSkillMethod } from '../../utils/invokeHttpPostSkillMethod';
import { useItemsInView } from '../../utils/useItemsInView';
import { podcastDeeplink } from '../../utils/deeplinks';

interface IPodcastFollowsShovelerProps {
    data: IPodcastFollowsShoveler;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    onViewed?: () => void;
}

export function PodcastFollowsShoveler(props: IPodcastFollowsShovelerProps) {
    const callbackRef = useItemsInView(props.onViewed);
    const { header, seeMoreItem } = props.data;
    const { customerId } = useSelector(
        (state: IApplicationState) => state.Authentication
    );
    const storage = new FollowDaoSessionStorage(useStore());
    const items = storage
        .getAll(customerId, true, true)
        .map((followEntry, index) => {
            let onPodcastSelected: ISkyfireMethod[] = [
                // @ts-ignore
                invokeHttpPostSkillMethod(`/podcasts/${followEntry.id}`),
            ];
            onPodcastSelected = onPodcastSelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.SELECT_PODCAST_SHOW,
                    contentName: ContentName.PODCASTS_FOLLOWS_SHOVELER,
                    pageType: PageType.PODCASTS_HOME,
                    entityType: EntityType.PODCAST_SHOW,
                    entityIdType: EntityIdType.PODCAST_SHOW_ID,
                    entityId: getPodcastEntityId(followEntry),
                    entityPos: index,
                })
            );
            const podcastLink: ISkyfireLinkElement = {
                deeplink: podcastDeeplink(followEntry),
                onItemSelected: onPodcastSelected,
            };
            // @ts-ignore
            const item: ISkyfireSquareVerticalItem = {
                image: followEntry.image,
                imageAltText: followEntry.title,
                primaryLink: podcastLink,
                secondaryText: followEntry.publisher!,
                actionIconName: 'caretright',
                actionIconLink: podcastLink,
                primaryText: {
                    text: followEntry.title,
                    disabled: false,
                },
                interface: SQUARE_VERTICAL_ITEM,
            };
            return item;
        });
    if (items?.length) {
        return (
            <div>
                <music-shoveler
                    key={header}
                    see-all={items.length >= 7 ? seeMoreItem?.text : undefined}
                    primary-text={header}
                    onmusicSeeAllActivate={props.handleSelected.bind(
                        null,
                        seeMoreItem?.primaryLink?.onItemSelected
                    )}
                >
                    {items.map((item, i) => (
                        <VerticalItem
                            data={item}
                            handleSelected={props.handleSelected}
                            ref={callbackRef}
                            key={i}
                        />
                    ))}
                </music-shoveler>
            </div>
        );
    }

    return null;
}

function getPodcastEntityId(element) {
    const {
        isRedhoodPlusMetricsEnabled,
        isRedhoodSonicRushEnabled,
    } = window.amznMusic.appConfig;
    return element.podcastShowVariantId &&
        isRedhoodPlusMetricsEnabled &&
        isRedhoodSonicRushEnabled
        ? element.podcastShowVariantId
        : element.id;
}
