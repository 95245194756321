import { Store } from 'redux';
import { DeeplinkClientState } from '../appsync/deeplinkClientState';
import { getPodcastDeeplinkFromClientInformation } from './getPodcastDeeplinkFromClientInformation';
import { getInitialPath } from './getInitialPath';
import { getAppSyncClientInformation } from './getAppSyncClientInformation';
import IApplicationState from '../types/IApplicationState';

export function getClientStateObject(
    method: any,
    store: Store<IApplicationState>
) {
    const deeplink =
        getPodcastDeeplinkFromClientInformation(method) || getInitialPath();
    const clientInfoResult: [string, any] = getAppSyncClientInformation(
        method,
        store
    );
    if (
        clientInfoResult[0] === 'follow' &&
        clientInfoResult[1] &&
        clientInfoResult[1].length > 0
    ) {
        return {
            deeplink: new DeeplinkClientState(deeplink),
            follow: {
                operations: clientInfoResult[1],
                __type:
                    'Podcast.FollowInterface.v1_0#FollowOperationsClientState',
            },
        };
    }
    if (
        clientInfoResult[0] === 'saves' &&
        clientInfoResult[1] &&
        clientInfoResult[1].length > 0
    ) {
        return {
            deeplink: new DeeplinkClientState(deeplink),
            saves: {
                operations: clientInfoResult[1],
                __type: 'Podcast.SaveInterface.v1_0#SaveOperationsClientState',
            },
        };
    }
    if (
        clientInfoResult[0] === 'bookmark' &&
        clientInfoResult[1] &&
        clientInfoResult[1].length > 0
    ) {
        return {
            deeplink: new DeeplinkClientState(deeplink),
            bookmark: {
                operations: clientInfoResult[1],
                __type:
                    'Podcast.BookmarkInterface.v1_0#BookmarkOperationsClientState',
            },
        };
    }
    if (
        clientInfoResult[0] === 'completed' &&
        clientInfoResult[1] &&
        clientInfoResult[1].length > 0
    ) {
        return {
            deeplink: new DeeplinkClientState(deeplink),
            completed: {
                operations: clientInfoResult[1],
                __type:
                    'Podcast.CompletedInterface.v1_0#CompletedOperationsClientState',
            },
        };
    }
    if (
        clientInfoResult[0] === 'playbackMetric' &&
        clientInfoResult[1] &&
        clientInfoResult[1].length > 0
    ) {
        return {
            deeplink: new DeeplinkClientState(deeplink),
            playbackMetric: {
                operations: clientInfoResult[1],
                __type:
                    'Podcast.PlaybackMetricsInterface.v1_0#PlaybackMetricsOperationsClientState',
            },
        };
    }
    if (
        clientInfoResult[0] === 'uiMetric' &&
        clientInfoResult[1] &&
        clientInfoResult[1].length > 0
    ) {
        return {
            deeplink: new DeeplinkClientState(deeplink),
            uiMetric: {
                operations: clientInfoResult[1],
                __type:
                    'Podcast.UIMetricsInterface.v1_0#UIMetricsOperationsClientState',
            },
        };
    }
    if (clientInfoResult[0] === 'followsLastSyncTime') {
        return {
            deeplink: new DeeplinkClientState('/podcasts/library/shows'),
            followsLastSyncTime: {
                lastSyncTime: clientInfoResult[1],
                __type:
                    'Podcast.FollowInterface.v1_0#FollowsLastSyncTimeClientState',
            },
        };
    }
    if (clientInfoResult[0] === 'savesLastSyncTime') {
        return {
            deeplink: new DeeplinkClientState('/podcasts/library/playlist'),
            savesLastSyncTime: {
                lastSyncTime: clientInfoResult[1],
                __type:
                    'Podcast.SaveInterface.v1_0#SavesLastSyncTimeClientState',
            },
        };
    }
    if (clientInfoResult[0] === 'bookmarksLastSyncTime') {
        return {
            deeplink: new DeeplinkClientState(
                '/podcasts/library/recently-played'
            ),
            bookmarksLastSyncTime: {
                lastSyncTime: clientInfoResult[1],
                __type:
                    'Podcast.BookmarkInterface.v1_0#BookmarksLastSyncTimeClientState',
            },
        };
    }
    if (clientInfoResult[0] === 'completedLastSyncTime') {
        return {
            deeplink: new DeeplinkClientState('/podcasts/library/completed'),
            completedLastSyncTime: {
                lastSyncTime: clientInfoResult[1],
                __type:
                    'Podcast.CompletedInterface.v1_0#CompletedLastSyncTimeClientState',
            },
        };
    }
    if (clientInfoResult[0] === 'hidePromptPreference' && clientInfoResult[1]) {
        return {
            deeplink: new DeeplinkClientState(deeplink),
            hidePromptPreference: {
                preferenceMap: clientInfoResult[1],
                __type:
                    'Podcast.FollowPromptInterface.v1_0#HidePromptPreferenceClientState',
            },
        };
    }
    return {
        deeplink: new DeeplinkClientState(deeplink),
    };
}
