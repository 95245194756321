import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { UAParser } from 'ua-parser-js';
import { isIOSmshop } from 'src/utils/deviceTypes';
import Styles from './BackgroundImage.scss';
import IApplicationState from '../types/IApplicationState';

interface IBackgroundImageProps {
    src: string | undefined;
    isNowPlaying?: boolean;
}

export function BackgroundImage(props: IBackgroundImageProps) {
    const { src, isNowPlaying = false } = props;

    const { windowWidth, windowHeight } = useSelector(
        (state: IApplicationState) => state.BrowserState,
        shallowEqual
    );

    const [backgroundHeight, setBackgroundHeight] = useState(windowHeight);

    useEffect(() => {
        setBackgroundHeight(windowHeight);
    }, [windowWidth]);

    const containerClassName = [
        Styles.container,
        isIOSmshop() ? Styles.iosMshopPositionOverride : '',
    ].join(' ');

    const ua = new UAParser(window.navigator.userAgent);
    const browser = ua.getBrowser();
    const isMobileSafariWebView = browser.name === 'Mobile Safari';

    return src && !isMobileSafariWebView ? (
        <div className={Styles['background-container']}>
            <div
                className={
                    isNowPlaying ? Styles.container2 : containerClassName
                }
                style={{
                    backgroundImage: `url(${src.replace(/_QL\d+_/, '_QL50_')})`,
                    // @ts-ignore
                    '--backgroundHeight': `${backgroundHeight}px`,
                }}
            />
            {!isNowPlaying ? <div className={Styles.gradient} /> : null}
        </div>
    ) : null;
}
