import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useRef } from 'react';
import cssVariables from '../../styles/variables.scss';
import { useItemsInView } from '../../utils/useItemsInView';
import InfiniteList from './items/InfiniteList';
import { scrollToRow } from '../../utils/scrollToRow';
import { TABLE_WIDGET_ROW_HEIGHT } from '../../utils/gridHelpers';
import TextRow from './items/TextRow';
import * as Styles from './DescriptiveTableWidget.scss';
import { useScrollingOffset } from '../../utils/tabsUtils';
const NAVBAR_HEIGHT = parseInt(cssVariables.navbarHeight, 10);
export default function DescriptiveTableWidget(props) {
    useStyles(Styles);
    const callbackRef = useItemsInView(props.onViewed);
    const scrollToIndex = props.data.items.findIndex((item) => !props.data.header && item.shouldScrollTo);
    const containerRef = useRef(null);
    const scrollingOffset = useScrollingOffset();
    scrollToRow(!!props.canScroll, scrollToIndex, TABLE_WIDGET_ROW_HEIGHT, containerRef, scrollingOffset * -1);
    return (React.createElement(React.Fragment, null,
        props.data.header && props.data.items.length > 0 && (React.createElement("h2", { className: Styles.descriptiveWidgetHeader }, props.data.header)),
        React.createElement("div", { ref: containerRef },
            React.createElement(InfiniteList, { data: props.data, handleSelected: props.handleSelected, componentType: TextRow, rowHeight: TABLE_WIDGET_ROW_HEIGHT, isTextRow: true, itemsViewedRef: callbackRef, scrollToIndex: scrollToIndex, isHighlightingEnabled: !props.data.header }))));
}
