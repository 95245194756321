import { Store } from 'redux';
import {
    AppSyncTypes,
    PLAYBACK_METRICS_SET_ON_REPORT_OPERATIONS_METHOD,
} from '../appsync/AppSyncConstants';
import { Entry } from '../appsync/Entry';
import { OperationElement } from '../appsync/OperationElement';
import { SyncData } from '../appsync/SyncData';
import { TimeConditionElement } from '../appsync/TimeConditionElement';
import { IConditionElement } from '../types/appsync/IAppSync';
import IApplicationState from '../types/IApplicationState';
import { IPlaybackMetricOperationDao } from './IPlaybackMetricOperationDao';
import { PlaybackMetricOperation } from './PlaybackMetricOperation';
import { PlaybackMetricOperationsClientState } from './PlaybackMetricOperationsClientState';
import { PlaybackMetricSetOnReportOperationsMethod } from './PlaybackMetricSetOnReportOperationsMethod';
import { PlaybackMetricWriteElement } from './PlaybackMetricWriteElement';

export class PlaybackMetricSyncData extends SyncData<
    PlaybackMetricWriteElement,
    void,
    void
> {
    private playbackMetricOperationDao: IPlaybackMetricOperationDao;

    private store: Store<IApplicationState>;

    constructor(
        playbackMetricOperationDao: IPlaybackMetricOperationDao,
        store
    ) {
        super();
        this.playbackMetricOperationDao = playbackMetricOperationDao;
        this.store = store;
    }

    public read(id: string): void {
        return undefined;
    }

    public readStates(): void[] {
        return [];
    }

    public async write(
        element: PlaybackMetricWriteElement,
        condition?: IConditionElement
    ) {
        await this.writeToOperations(element);
        this.reportOperations();
    }

    public writeStates(
        element: PlaybackMetricWriteElement,
        condition?: IConditionElement
    ) {}

    public async writeToOperations(element: PlaybackMetricWriteElement) {
        element = await this.updateElementWithPlaybackData(element);
        const playbackMetricOperation = new PlaybackMetricOperation({
            mediaId: element.id,
            mediaCollectionType: element.mediaCollectionType,
            playbackSignalType: element.playbackSignalType,
            currentProgressMilliseconds:
                element.currentProgressMilliseconds ?? 0,
            playbackRequestedAtTimestampMilliseconds:
                element.playbackRequestedAtTimestampMilliseconds ?? 0,
            metricsPreset: element.metricsPreset ?? '',
            isMediaDownloaded: element.isMediaDownloaded ?? false,
            currentPlaybackSpeed: element.currentPlaybackSpeed ?? 1,
            playbackStartOffsetMilliseconds:
                element.playbackStartOffsetMilliseconds ?? 0,
            playbackStartedAtTimestampMilliseconds:
                element.playbackStartedAtTimestampMilliseconds ?? 0,
            initialPlaybackStartDelayMilliseconds:
                element.initialPlaybackStartDelayMilliseconds ?? 0,
            rebufferDurationMilliseconds:
                element.rebufferDurationMilliseconds ?? 0,
            rebufferCount: element.rebufferCount ?? 0,
            updatedTime: Date.now(),
            isProcessing: false,
            pageType: element.pageType ?? '',
            audioUri: element.audioUri ?? '',
            podcastShowVariantId: element.podcastShowVariantId ?? '',
            podcastEpisodeVariantId: element.podcastShowVariantId ?? '',
        });
        this.playbackMetricOperationDao.insert(playbackMetricOperation);
    }

    public writeMetadata(element: void) {}

    public reportOperations() {
        const operations = new PlaybackMetricSetOnReportOperationsMethod();
        const onReportOperations = [operations];
        this.store.dispatch({
            type: PLAYBACK_METRICS_SET_ON_REPORT_OPERATIONS_METHOD,
            payload: { onReportOperations },
        });
    }

    public getClientState(name: string): Entry {
        const operations = this.playbackMetricOperationDao.getUnProcessed();
        const clientState = this.convertOperationsToClientState(operations);
        const ids: string[] = [];
        operations.forEach((operation) => {
            ids.push(operation.operationId);
        });
        this.playbackMetricOperationDao.update(ids, true);
        return new Entry(AppSyncTypes.PLAYBACK_METRIC, clientState);
    }

    public deleteOperation(ids: string[]) {
        this.playbackMetricOperationDao.delete(ids);
    }

    public conditionSatisfied(
        element: PlaybackMetricWriteElement,
        condition: IConditionElement
    ): boolean {
        return true;
    }

    private convertOperationsToClientState(
        pendingOperations: PlaybackMetricOperation[]
    ): PlaybackMetricOperationsClientState {
        const operations: OperationElement[] = [];
        pendingOperations.forEach((operation) => {
            const writeElement: PlaybackMetricWriteElement = this.convertOperationToPlaybackMetricWriteElement(
                operation
            );
            const timeConditionElement: TimeConditionElement = new TimeConditionElement(
                operation.updatedTime
            );
            const operationElement: OperationElement = new OperationElement(
                operation.operationId,
                writeElement,
                timeConditionElement
            );
            operations.push(operationElement);
        });
        return new PlaybackMetricOperationsClientState(operations);
    }

    private convertOperationToPlaybackMetricWriteElement(
        operation: PlaybackMetricOperation
    ): PlaybackMetricWriteElement {
        return new PlaybackMetricWriteElement(
            operation.mediaId,
            operation.mediaCollectionType,
            operation.playbackSignalType,
            operation.currentProgressMilliseconds,
            operation.playbackRequestedAtTimestampMilliseconds,
            operation.metricsPreset,
            operation.isMediaDownloaded,
            operation.currentPlaybackSpeed,
            operation.playbackStartOffsetMilliseconds,
            operation.playbackStartedAtTimestampMilliseconds,
            operation.initialPlaybackStartDelayMilliseconds,
            operation.rebufferDurationMilliseconds,
            operation.rebufferCount,
            operation.pageType,
            operation.audioUri,
            operation.podcastShowVariantId,
            operation.podcastEpisodeVariantId
        );
    }

    private async updateElementWithPlaybackData(
        element: PlaybackMetricWriteElement
    ): Promise<PlaybackMetricWriteElement> {
        const player = await window.maestro.getInstance();
        if (player === false) {
            return element;
        }
        const progress = Math.round(player.getCurrentTime() * 1000);
        element.currentProgressMilliseconds =
            element.playbackSignalType === 'PLAYBACK_PRE_SCRUBBED'
                ? this.store.getState().PodcastState.playbackPreScrubPosition
                : progress;
        element.playbackStartOffsetMilliseconds =
            element.playbackSignalType === 'PLAYBACK_PRE_SCRUBBED'
                ? this.store.getState().PodcastState.playbackPreScrubStartOffset
                : this.store.getState().PodcastState.playbackStartOffset;
        element.playbackStartedAtTimestampMilliseconds = this.store.getState().PodcastState.podcastEpisodeStartedPlaybackAt;
        element.initialPlaybackStartDelayMilliseconds =
            element.playbackStartedAtTimestampMilliseconds -
            element.playbackRequestedAtTimestampMilliseconds;
        element.audioUri = this.store.getState().Media.mediaId ?? '';
        return element;
    }
}
