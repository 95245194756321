import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { generateQRLogo } from '../utils/DynamicQRCodeGenerator';
import * as Styles from './Dialog.scss';
export default function DialogQRCard(props) {
    useStyles(Styles);
    const { header, body, imageAltText, deeplink } = props.template;
    const [qrWithLogo, setQrWithlogo] = useState(null);
    useEffect(() => {
        setQRCodeWithLogo();
    }, []);
    return (React.createElement("div", { className: Styles.qrContainer },
        renderQRCode(),
        React.createElement("div", { className: Styles.qrCardTextContainer },
            React.createElement("h1", { className: ['music-headline-5', Styles.qrCardHeader].join(' '), id: "dialogQRCardHeader" }, header),
            React.createElement("p", { className: ['music-secondary-text', Styles.qrCardBodyTextContainer].join(' '), id: "dialogQRCardBodyText" }, body))));
    async function setQRCodeWithLogo() {
        const qrCodeImage = await generateQRLogo(deeplink);
        setQrWithlogo(qrCodeImage);
    }
    function renderQRCode() {
        return (React.createElement("div", null,
            React.createElement("music-image", { src: qrWithLogo, alt: imageAltText })));
    }
}
