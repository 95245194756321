import { registerDeeplinkModule } from './deeplink';
import { registerOverlayModule } from './overlay';
import { registerSearchModule } from './search';
import { registerSkyfireModule } from './skyfire';
import { registerVideoStoryPlaybackModule } from './videoStoryPlayback';
import { registerPlaybackModule } from './playback';
import { registerUpsellModule } from './upsell';
export function registerNativeModules(store) {
    registerDeeplinkModule(store);
    registerOverlayModule(store);
    registerSearchModule(store);
    registerSkyfireModule(store);
    registerVideoStoryPlaybackModule(store);
    registerPlaybackModule(store);
    registerUpsellModule(store);
}
