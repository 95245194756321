import axios from 'axios';
import { getParameterByName } from './getSkyfireEnvironment';
import { globals } from './globals';
const whitelistedArtistAsinsForCDN = [
    'B002F0BWIM',
    'B000QJRIHS',
    'B000QKAGGM',
    'B000T2D81C',
    'B0016SK9OW',
    'B0015H24HO',
    'B0143OIF9G',
    'B00181MLFM',
    'B00157GJ20',
    'B0014DEFTE',
    'B0065IDFGM',
    'B00137HDI6',
    'B006ZO8YNU',
    'B000QK9UDW',
    'B000QK6NA0',
];
const whitelistedAlbumAsinsForCDN = [
    'B08V9FKY53',
    'B073JXNPL4',
    'B01NBTSVDN',
    'B07N7PBZGF',
    'B07ZZLH3X1',
    'B07SYHQPPD',
    'B07C89TZ1C',
    'B079VSDTZP',
    'B09R1LLKZL',
    'B09WTVK4TR',
    'B0C55SD2QC',
    'B074W73HV1',
    'B002ATV5ZS',
    'B0CBVYDQ37',
    'B01N5CX09G',
];
const whitelistedTrackAsinsForCDN = [
    'B0BS2S4WXN',
    'B07ZQ7Z83Y',
    'B085W883JZ',
    'B09CKWVXVQ',
    'B0859QJS6T',
    'B08Q8GWGN6',
    'B0BCMH2WC3',
    'B09J1TWHZL',
    'B002FT8LM8',
    'B08DJ75JYW',
    'B0CNKW9MTP',
    'B003DEGOIC',
    'B086C79ZBP',
    'B09RQVR67N',
    'B0B3V7FWH8',
];
export const whitelistedAsinsForCdnFetch = [
    ...whitelistedArtistAsinsForCDN,
    ...whitelistedAlbumAsinsForCDN,
    ...whitelistedTrackAsinsForCDN,
];
export function getEnv() {
    const DEFAULT_SKYFIRE_ENVIRONMENT_NAME = 'prod';
    const paramSkyfireEnvName = getParameterByName('skyfireEnv', globals.location.href);
    const skyfireEnvironmentName = paramSkyfireEnvName || DEFAULT_SKYFIRE_ENVIRONMENT_NAME;
    return skyfireEnvironmentName;
}
export function entityExtraction(url) {
    const decodedURL = decodeURIComponent(url).replace(/\\/g, '');
    const newURL = new URL(decodedURL);
    if (newURL.pathname === '/api/cosmicTrack/displayCatalogTrack') {
        return ['TRACK', newURL.searchParams.get('id') || ''];
    }
    if (newURL.pathname === '/api/explore/v1/showCatalogArtist') {
        return ['ARTIST', newURL.searchParams.get('id') || ''];
    }
    if (newURL.pathname === '/api/showCatalogAlbum') {
        return ['ALBUM', newURL.searchParams.get('id') || ''];
    }
    return ['', ''];
}
export function isLocaleSubdirectoryPresent(deeplink) {
    if (!deeplink)
        return false;
    const localeRegex = new RegExp('^/[A-Za-z]{2}[-]{1}[A-Za-z]{2}');
    return localeRegex.test(deeplink);
}
export async function fetchPageFromCDN(entityType, asin, domain, locale, musicTerritory) {
    let cdnResponse = [];
    let err = '';
    await axios
        .get(`https://d19q0p3if0yvys.cloudfront.net/${entityType}/${domain}/${musicTerritory}/${locale}/${asin}.json`)
        .then((response) => {
        if (response.data.methods) {
            cdnResponse = response.data.methods;
        }
        else {
            err = 'CDN response has 0 length';
        }
    })
        .catch((error) => {
        err = error.message;
    });
    return [cdnResponse, err];
}
