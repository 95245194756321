export class HidePromptPreferenceElement {
    public updatedTime?: number;

    public __type: string;

    constructor(updatedTime) {
        this.updatedTime = updatedTime;
        this.__type =
            'Podcast.FollowPromptInterface.v1_0#HidePromptPreferenceElement';
    }
}
