import { Store } from 'redux';
import { WEB_STORAGE } from '../appsync/AppSyncConstants';
import IApplicationState from '../types/IApplicationState';
import {
    SAVE_BOOKMARK_OPERATION,
    DELETE_BOOKMARK_OPERATION,
    UPDATE_BOOKMARK_OPERATION,
} from './BookmarkActions';
import { BookmarkOperation } from './BookmarkOperation';
import { IBookmarkOperationDao } from './IBookmarkOperationDao';

export class BookMarkOperationDaoSessionStorage
    implements IBookmarkOperationDao {
    private store: Store<IApplicationState>;

    constructor(store) {
        this.store = store;
    }

    public insert(bookmarkOperation: BookmarkOperation) {
        this.store.dispatch({
            type: SAVE_BOOKMARK_OPERATION,
            payload: { bookmarkOperation },
        });
    }

    public getUnProcessed(): BookmarkOperation[] {
        const unProcessedOperations: BookmarkOperation[] = [];
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (key.endsWith('BookmarkOperation')) {
                const bookmarkOperation = JSON.parse(value);
                if (
                    bookmarkOperation &&
                    bookmarkOperation.isProcessing === false
                ) {
                    unProcessedOperations.push(bookmarkOperation);
                }
            }
        }
        return unProcessedOperations;
    }

    public update(ids: string[], isProcessing: boolean) {
        this.store.dispatch({
            type: UPDATE_BOOKMARK_OPERATION,
            payload: { ids, isProcessing },
        });
    }

    public delete(ids: string[]) {
        this.store.dispatch({
            type: DELETE_BOOKMARK_OPERATION,
            payload: { ids },
        });
    }
}
