import { Store } from 'redux';
import {
    IN_PROGRESS_LOWER_BOUND_MILLISECONDS,
    IN_PROGRESS_UPPER_BOUND_PERCENTAGE,
    WEB_STORAGE,
} from '../appsync/AppSyncConstants';
import IApplicationState from '../types/IApplicationState';
import { Bookmark } from './Bookmark';
import { SAVE_BOOKMARK } from './BookmarkActions';
import { IBookmarkDao } from './IBookmarkDao';

export class BookmarkDaoSessionStorage implements IBookmarkDao {
    private store: Store<IApplicationState>;

    constructor(store) {
        this.store = store;
    }

    public insert(bookmark: Bookmark) {
        this.store.dispatch({
            type: SAVE_BOOKMARK,
            payload: { bookmark },
        });
    }

    public getBookmark(
        episodeId: string,
        customerId: string
    ): Bookmark | undefined {
        const key = `${episodeId}_Bookmark`;
        const bookmark = WEB_STORAGE.getItem(key);
        if (bookmark) {
            return JSON.parse(bookmark);
        }
        return undefined;
    }

    public getBookmarkBounds(
        episodeId: string,
        customerId: string,
        inProgressLowerBoundMilliseconds: number,
        inProgressUpperBoundPercentage: number
    ): Bookmark | undefined {
        const key = `${episodeId}_Bookmark`;
        const bookmarkString = WEB_STORAGE.getItem(key);
        const localStorageLowerBound = WEB_STORAGE.getItem(
            IN_PROGRESS_LOWER_BOUND_MILLISECONDS
        );
        const localStorageUpperBoundPercentage = WEB_STORAGE.getItem(
            IN_PROGRESS_UPPER_BOUND_PERCENTAGE
        );
        if (localStorageLowerBound && localStorageUpperBoundPercentage) {
            inProgressLowerBoundMilliseconds = Number(localStorageLowerBound);
            inProgressUpperBoundPercentage = Number(
                localStorageUpperBoundPercentage
            );
        }
        if (!bookmarkString) {
            return undefined;
        }
        const bookmark = JSON.parse(bookmarkString);
        if (
            bookmark &&
            bookmark.progressMilliSeconds > inProgressLowerBoundMilliseconds &&
            (bookmark.progressMilliSeconds * 100) /
                bookmark.totalDurationMilliseconds <
                inProgressUpperBoundPercentage
        ) {
            return bookmark;
        }
        return undefined;
    }

    public getAll(
        customerId: string,
        inProgressLowerBoundMilliseconds: number,
        inProgressUpperBoundPercentage: number,
        isLimited?: boolean
    ): Bookmark[] {
        const localStorageLowerBound = WEB_STORAGE.getItem(
            IN_PROGRESS_LOWER_BOUND_MILLISECONDS
        );
        const localStorageUpperBoundPercentage = WEB_STORAGE.getItem(
            IN_PROGRESS_UPPER_BOUND_PERCENTAGE
        );
        if (localStorageLowerBound && localStorageUpperBoundPercentage) {
            inProgressLowerBoundMilliseconds = Number(localStorageLowerBound);
            inProgressUpperBoundPercentage = Number(
                localStorageUpperBoundPercentage
            );
        }
        let isBookmarkList: Bookmark[] = [];
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (key.endsWith('_Bookmark')) {
                const bookmark = JSON.parse(value);
                if (
                    bookmark &&
                    bookmark.progressMilliSeconds >
                        inProgressLowerBoundMilliseconds &&
                    (bookmark.progressMilliSeconds * 100) /
                        bookmark.totalDurationMilliseconds <
                        inProgressUpperBoundPercentage
                ) {
                    isBookmarkList.push(new Bookmark(bookmark));
                }
            }
        }

        isBookmarkList = isBookmarkList.sort((a, b) =>
            a.updatedTime < b.updatedTime ? 1 : -1
        );

        if (isLimited === true) {
            isBookmarkList = isBookmarkList.slice(0, 20);
            return isBookmarkList;
        }
        return isBookmarkList;
    }

    public getPodcastAll(
        customerId: string,
        podcastId: string,
        inProgressLowerBoundMilliseconds: number,
        inProgressUpperBoundPercentage: number
    ): Bookmark[] {
        const localStorageLowerBound = WEB_STORAGE.getItem(
            IN_PROGRESS_LOWER_BOUND_MILLISECONDS
        );
        const localStorageUpperBoundPercentage = WEB_STORAGE.getItem(
            IN_PROGRESS_UPPER_BOUND_PERCENTAGE
        );
        if (localStorageLowerBound && localStorageUpperBoundPercentage) {
            inProgressLowerBoundMilliseconds = Number(localStorageLowerBound);
            inProgressUpperBoundPercentage = Number(
                localStorageUpperBoundPercentage
            );
        }
        let isBookmarkList: Bookmark[] = [];
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (key.endsWith('_Bookmark')) {
                const bookmark = JSON.parse(value);
                if (
                    bookmark &&
                    bookmark.podcastId === podcastId &&
                    bookmark.progressMilliSeconds >
                        inProgressLowerBoundMilliseconds &&
                    (bookmark.progressMilliSeconds * 100) /
                        bookmark.totalDurationMilliseconds <
                        inProgressUpperBoundPercentage
                ) {
                    isBookmarkList.push(new Bookmark(bookmark));
                }
            }
        }
        isBookmarkList = isBookmarkList.sort((a, b) =>
            a.updatedTime < b.updatedTime ? 1 : -1
        );
        return isBookmarkList;
    }

    public getLastSyncTime(customerId: string): number {
        const key = 'bookmarksLastSyncTime';
        const lastSyncTime = Number(WEB_STORAGE.getItem(key));
        return lastSyncTime || 0;
    }

    public clearBookmarkData(customerId: string) {
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (
                key.endsWith('_Bookmark') ||
                key.endsWith('bookmarksLastSyncTime')
            ) {
                WEB_STORAGE.removeItem(key);
            }
        }
    }
}
