exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1zx1qulK3A4ovb90GX7DKM{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._1zx1qulK3A4ovb90GX7DKM{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._1zx1qulK3A4ovb90GX7DKM{padding-inline-start:52px;padding-inline-end:52px}}._3FWoba7Uoc7bDaBS5h8Ngh{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._3FWoba7Uoc7bDaBS5h8Ngh{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._3FWoba7Uoc7bDaBS5h8Ngh{margin-inline-start:52px;margin-inline-end:52px}}._1eYWQgAkh_ip7nSVfMuKwv{position:relative;inset-inline-start:calc(-50vw + 50%);aspect-ratio:16/9;width:100vw}._1eYWQgAkh_ip7nSVfMuKwv iframe{border:0;height:100%;width:100%}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_1zx1qulK3A4ovb90GX7DKM",
	"gridSides": "_1zx1qulK3A4ovb90GX7DKM",
	"grid-sides-margins": "_3FWoba7Uoc7bDaBS5h8Ngh",
	"gridSidesMargins": "_3FWoba7Uoc7bDaBS5h8Ngh",
	"twitch-video-player": "_1eYWQgAkh_ip7nSVfMuKwv",
	"twitchVideoPlayer": "_1eYWQgAkh_ip7nSVfMuKwv"
};