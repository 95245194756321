import React, { useCallback, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ISkyfireIconButtonElement from 'src/types/templates/widgets/ISkyfireIconButtonElement';
import { Button, ContextMenuButton } from 'src/components';
import IApplicationState from 'src/types/IApplicationState';
import { WINDOW_SIZE_ENUM } from '../../../types/IWindowSize';
import ISkyfireMethod from '../../../types/ISkyfireMethod';
import ISkyfireTemplate from '../../../types/templates/ISkyfireTemplate';
import ISkyfireVisualRowItem from '../../../types/templates/widgets/items/ISkyfireVisualRowItem';
import { bindHandler, buildTags, preventDefault } from '../../../utils';
import { useItemSelectedState } from '../../../utils/MultiSelectHook';
import { useObserver } from '../../../utils/ObserverHooks';
import useKeyPress from '../../../utils/useKeyPress';
import RowItemButton from './RowItemButton';

export const SHIFT_CLICK = 'SHIFT_CLICK';

interface IImageRowProps {
    data: ISkyfireVisualRowItem;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    handleReorder?: (methods: ISkyfireMethod[]) => void;
    style?: React.CSSProperties;
    loading?: boolean;
    index?: number;
    disableSecondaryTextInline?: boolean;
    disableSelection?: boolean;
    template?: ISkyfireTemplate;
}

function ImageRow(props: IImageRowProps, ref) {
    const {
        image,
        imageDimension,
        primaryText,
        primaryLink,
        secondaryText1,
        secondaryText1Link,
        secondaryText2,
        secondaryText2Link,
        secondaryText3,
        isDisabled,
        isDisabledObserver,
        badges,
        buttons,
        icon,
        iconButton,
        contextMenu,
        button,
        isSelected,
        onCheckboxSelected,
    } = props.data;
    const dispatch = useDispatch();
    let template = useSelector(
        (state: IApplicationState) => state.TemplateStack.currentTemplate
    );
    template = template || props.template;
    const isRowSelected = useItemSelectedState(
        isSelected,
        !!props.disableSelection
    );
    const onSelected = useObserver({
        observer: onCheckboxSelected,
    }) as ISkyfireMethod[];
    const { windowWidth } = useSelector(
        (state: IApplicationState) => state.BrowserState
    );

    const onEnterPress = (event) => {
        if (event.key === 'Enter') {
            props.handleSelected(primaryLink.onItemSelected);
        }
    };

    const iconButtonElement = useObserver(
        iconButton
    ) as ISkyfireIconButtonElement;
    const disabledByObserver = useObserver({
        observer: isDisabledObserver,
    }) as boolean;
    // ImageRow should be disabled if either
    // isDisabledObserver object exists and useObserver hook returns true or
    // isDisabled boolean field is true
    const disabled = (isDisabledObserver && !!disabledByObserver) || isDisabled;
    const shiftKeyPressed = useKeyPress('Shift');
    const renderSecondaryTextInline =
        !!props.disableSecondaryTextInline &&
        windowWidth > WINDOW_SIZE_ENUM.LG &&
        windowWidth <= WINDOW_SIZE_ENUM.XL2;

    const bindHandlerHelper = (fn: any) =>
        bindHandler(props.handleSelected, null, fn);
    const handleSelectedChange = useCallback(
        (e) => {
            const methods = Array.isArray(onSelected)
                ? onSelected
                : primaryLink.onItemSelected;
            if (template && methods.length > 0) {
                dispatch({
                    type: SHIFT_CLICK,
                    payload: {
                        methods,
                        shiftKeyPressed,
                        template,
                        index: props.index,
                    },
                });
            }
        },
        [dispatch, template, onSelected, shiftKeyPressed]
    );

    const parseHtml = (text: string) =>
        text == null ? '' : text.replace(/(<[^>]+>)|(&nbsp;)/gi, '');

    if (props?.loading) {
        return <music-image-row loading={true} />;
    }
    const key = primaryText + secondaryText1 + secondaryText2 + props.index;
    return (
        <music-image-row
            ref={ref}
            key={key}
            data-key={key}
            style={props.style}
            onKeyPress={onEnterPress}
            onClick={preventDefault}
            icon-name={iconButtonElement?.icon || icon}
            show-action-button={iconButtonElement?.onItemSelected.length > 0}
            onmusicIconActivate={bindHandlerHelper(
                iconButtonElement?.onItemSelected
            )}
            disabled={disabled}
            selected={isRowSelected}
            onmusicSelectedChange={bindHandlerHelper(
                primaryLink.onItemSelected
            )}
            primary-text={primaryText}
            primary-href={primaryLink.deeplink}
            onmusicPrimaryTextActivate={bindHandlerHelper(
                primaryLink.onItemSelected
            )}
            secondary-text-1={parseHtml(secondaryText1)}
            secondary-href-1={secondaryText1Link?.deeplink}
            onmusicSecondaryText1Activate={bindHandlerHelper(
                secondaryText1Link?.onItemSelected
            )}
            secondary-text-2={secondaryText2}
            secondary-href-2={secondaryText2Link?.deeplink}
            onmusicSecondaryText2Activate={bindHandlerHelper(
                secondaryText2Link?.onItemSelected
            )}
            render-secondary-text-inline={renderSecondaryTextInline}
            duration={secondaryText3}
            image-src={image}
            image-dimen={imageDimension}
        >
            {buildTags(badges, isDisabled)}
            {buttons.map((element) => (
                <RowItemButton slot='buttons' button={element} />
            ))}
            {button && (
                <Button
                    data={button}
                    handleSelected={props.handleSelected}
                    slot='buttons'
                    size='small'
                    usePlaceholder={buttons.length === 0}
                />
            )}
            {contextMenu && (
                <ContextMenuButton
                    options={contextMenu.options}
                    disabled={disabled || contextMenu.disabled}
                    slot='contextMenu'
                    variant='primary'
                    iconName='more'
                    size='small'
                />
            )}
        </music-image-row>
    );
}

export default forwardRef(ImageRow);
