declare let process: {
    env: {
        accessToken: string;
        customerId: string;
        deviceId: string;
        deviceType: string;
        marketplaceId: string;
    };
};

export function getExpirationTimestamp(expiresIn: string): number {
    return Date.now() + +expiresIn * 1000;
}

// Ignore this function for test coverage purposes for now
/* istanbul ignore next */
export async function getOAuthToken(): Promise<{
    accessToken: string;
    expiresAt: number;
}> {
    try {
        const deviceType =
            window?.amznMusic?.appConfig?.deviceType || process.env.deviceType;
        let deviceTypeParam = '';
        if (deviceType) {
            deviceTypeParam = `?deviceType=${deviceType}`;
        }
        const response = await window.fetch(
            `/horizonte/pandaToken${deviceTypeParam}`,
            {
                method: 'get',
            }
        );
        const json = await response.json();
        if (json.error) {
            return { accessToken: '', expiresAt: Date.now() + 900 };
        }
        return {
            accessToken: json.accessToken as string,
            expiresAt: getExpirationTimestamp(json.expiresIn),
        };
    } catch (e) {
        // Default to empty token if error, will show bourne experience
        return { accessToken: '', expiresAt: Date.now() + 900 };
    }
}
