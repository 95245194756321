import React, { Fragment, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import * as Styles from './TextClamp.scss';
import * as textStyles from '../styles/text.scss';
export default function TextClamp(props) {
    useStyles(Styles, textStyles);
    const { handleOnClick } = props;
    const [isClamped, setIsClamped] = useState(true);
    const onClick = () => {
        setIsClamped(!isClamped);
        handleOnClick === null || handleOnClick === void 0 ? void 0 : handleOnClick();
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: [textStyles.secondaryText, Styles.textClamp].join(' '), style: {
                WebkitLineClamp: isClamped ? props.linesToClamp : 'initial',
            } }, props.text.split('\n').map((paragraph) => (React.createElement("p", { className: Styles.paragraph }, paragraph)))),
        React.createElement("span", { onClick: onClick, className: textStyles.primaryText2 }, isClamped ? props.readMoreText : props.readLessText)));
}
