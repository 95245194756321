import { useSelectedRowCount } from './MultiSelectHook';
import { isRWPDesktop, isRWPMobileWeb } from './retailPlayerHelper';
import cssVariables from '../styles/variables.scss';
const NAVBAR_HEIGHT = parseInt(cssVariables.navbarHeight, 10);
export const useScrollingOffset = () => {
    const selectedRowCount = useSelectedRowCount();
    /*
     * Offset for music navbar having a height of 72px.
     */
    let offset = NAVBAR_HEIGHT;
    /*
     * Offset for the TabsNavigationBar.
     */
    if (isRWPDesktop()) {
        offset += 80;
    }
    else if (isRWPMobileWeb()) {
        offset += 72;
    }
    /*
     * If the selected row count is greater than zero, that means the multiSelectBar is
     * present on the webpage.
     * In that case, we add 72, which is the height of the multiSelectBar; otherwise, we add zero.
     */
    offset += selectedRowCount > 0 ? 72 : 0;
    return offset;
};
const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const scrollingOffset = useScrollingOffset();
    if (section) {
        window.scrollTo({
            top: window.scrollY + section.getBoundingClientRect().y - scrollingOffset,
            behavior: 'smooth',
        });
    }
};
export default scrollToSection;
