import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { UPDATE_PLAY_BUTTON } from '../../../actions/LocalHydration';
import ISkyfireMethod from '../../../types/ISkyfireMethod';
import ISkyfireFeaturedElement from '../../../types/templates/detail/ISkyfireFeaturedElement';
import ISkyfireEpisodeFeaturedElement from '../../../types/templates/detail/ISkyfireEpisodeFeaturedElement';
import ISkyfireButtonElement from '../../../types/templates/widgets/ISkyfireButtonElement';
import { Button } from './Button';
import {
    CURRENTLY_PLAYING_GROUP,
    getLocalizedString,
} from '../../../middlewares/LocalHydrationMiddleware';
import { getDefaultLanguage } from '../../../utils/getDefaultLanguage';
import { INVOKE_HTTP_POST } from '../../../types/ISkyfireInvokeHttpPostMethod';
import { PLAYBACK_TOGGLE } from '../../../actions/Playback';
import IApplicationState from '../../../types/IApplicationState';
import Styles from './DetailPlayButton.scss';

interface IDetailPlayButtonProps {
    button: ISkyfireButtonElement;
    id: string;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    featuredItem?: ISkyfireFeaturedElement | ISkyfireEpisodeFeaturedElement;
    progressMilliseconds?: number;
    totalDurationMilliseconds?: number;
    playButtonStateSetter?: React.Dispatch<React.SetStateAction<string>>;
}

export default function DetailPlayButton(props: IDetailPlayButtonProps) {
    const dispatch = useDispatch();

    const {
        progressMilliseconds,
        totalDurationMilliseconds,
        button,
        featuredItem,
        handleSelected,
        id,
        playButtonStateSetter,
    } = props;

    const { playButton } = useSelector(
        (state: IApplicationState) => state.LocalHydrationState
    );

    const { play } = useSelector(
        (state: IApplicationState) => state.PlaybackStates
    );

    const { marketplaceId } = useSelector(
        (state: IApplicationState) => state.Authentication
    );

    const { mediaId } = useSelector((state: IApplicationState) => state.Media);

    const {
        CURRENTLY_PLAYING_PODCAST,
        CURRENTLY_PLAYING_EPISODE,
    } = useSelector(
        (state: IApplicationState) =>
            state.Storage[CURRENTLY_PLAYING_GROUP] || {}
    );

    useEffect(() => {
        dispatch({
            type: UPDATE_PLAY_BUTTON,
            payload: {
                playButton: button,
                featuredItem,
            },
        });
    }, [featuredItem, button, dispatch]);

    const playbackToggle = useCallback(() => {
        dispatch({ type: PLAYBACK_TOGGLE, payload: { mediaId } });
    }, [mediaId, dispatch]);

    const data = featuredItem ? playButton ?? button : button;
    const language = getDefaultLanguage(marketplaceId, useStore());
    const onPlaySelectedMethod = data.onItemSelected.find(
        (method) => method.queue.id === 'PLAYBACK'
    );
    let buttonData = data;
    let isPlaybackToggle = false;
    if (
        onPlaySelectedMethod &&
        onPlaySelectedMethod.interface === INVOKE_HTTP_POST
    ) {
        const onPlayPreset = JSON.parse(onPlaySelectedMethod.preset);
        isPlaybackToggle = featuredItem
            ? CURRENTLY_PLAYING_PODCAST === onPlayPreset.podcastId
            : CURRENTLY_PLAYING_EPISODE === onPlayPreset.startAtEpisodeId;
        if (isPlaybackToggle) {
            if (play?.state === 'PLAYING') {
                if (playButtonStateSetter) {
                    playButtonStateSetter('pauseIcon');
                }
                buttonData = {
                    ...data,
                    icon: 'pause',
                    text: getLocalizedString(language, 'dmp_pause'),
                };
            } else {
                if (playButtonStateSetter) {
                    playButtonStateSetter('playIcon');
                }
                buttonData = {
                    ...data,
                    text: getLocalizedString(language, 'dmp_play'),
                };
            }
        } else {
            buttonData = {
                ...data,
                text: getLocalizedString(language, 'dmp_play'),
            };
        }
    }
    const currentProgress =
        totalDurationMilliseconds && progressMilliseconds
            ? progressMilliseconds / totalDurationMilliseconds
            : 0;
    const detailPlayButton = (
        <div className={Styles['playback-button-container']}>
            <Button
                data={buttonData}
                handleSelected={
                    isPlaybackToggle ? playbackToggle : handleSelected
                }
                id={id}
                slot='icons'
                size={button.iconOnly ? 'small' : 'medium'}
                variant='solid'
            />
            <circular-progress-bar
                progress={currentProgress}
                status='paused'
                size='small'
                innerOutline='true'
            />
        </div>
    );
    return detailPlayButton;
}
