import { ContentTrait, PlaybackMode, UpsellType } from 'src/types/Eligibility';
import { v4 } from 'uuid';

export class BookmarkOperation {
    public operationId: string;

    public episodeId: string;

    public podcastId: string;

    public podcastTitle: string;

    public title: string;

    public image: string;

    public podcastImage: string;

    public description: string;

    public authors: string;

    public seasonNumber: number;

    public totalDurationMilliseconds: number;

    public progressMilliSeconds: number;

    public updatedTime: number;

    public isProcessing: boolean;

    public publishTime: string;

    public playbackMode: PlaybackMode;

    public availableUpsells: UpsellType[];

    public contentTraits: ContentTrait[];

    public availabilityDate: string;

    public podcastShowVariantId: string;

    public podcastEpisodeVariantId: string;

    constructor(operation: {
        operationId?;
        episodeId?;
        podcastId?;
        podcastTitle?;
        title?;
        image?;
        podcastImage?;
        description?;
        authors?;
        seasonNumber?;
        totalDurationMilliseconds?;
        progressMilliSeconds?;
        updatedTime?;
        isProcessing?;
        publishTime;
        playbackMode?;
        availableUpsells?;
        contentTraits?;
        availabilityDate?;
        podcastShowVariantId?;
        podcastEpisodeVariantId?;
    }) {
        this.operationId = operation.operationId ?? v4();
        this.episodeId = operation.episodeId;
        this.podcastId = operation.podcastId;
        this.podcastTitle = operation.podcastTitle;
        this.title = operation.title;
        this.image = operation.image;
        this.podcastImage = operation.podcastImage;
        this.description = operation.description;
        this.authors = operation.authors;
        this.seasonNumber = operation.seasonNumber;
        this.totalDurationMilliseconds = operation.totalDurationMilliseconds;
        this.progressMilliSeconds = operation.progressMilliSeconds;
        this.updatedTime = operation.updatedTime;
        this.isProcessing = operation.isProcessing;
        this.publishTime = operation.publishTime;
        this.playbackMode = operation.playbackMode;
        this.availableUpsells = operation.availableUpsells;
        this.contentTraits = operation.contentTraits;
        this.availabilityDate = operation.availabilityDate;
        this.podcastShowVariantId = operation.podcastShowVariantId;
        this.podcastEpisodeVariantId = operation.podcastEpisodeVariantId;
    }
}
