import React, { useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
function InfiniteListItem({ index, style, data, sortDisabled }) {
    var _a;
    const { componentType, handleSelected, isDynamicRowHeightEnabled, items, itemsViewedRef, loading, rowHeight, setState, getMaxIndex, setMaxIndex, isHighlightingEnabled, } = data;
    const ComponentType = componentType;
    const itemData = (_a = items[index]) !== null && _a !== void 0 ? _a : { placeholder: true };
    const isInitialRender = index > getMaxIndex();
    useEffect(() => {
        if (isInitialRender)
            setMaxIndex(index);
    }, [index, isInitialRender, setMaxIndex]);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: index + items[index].primaryText,
        disabled: sortDisabled,
    });
    const itemStyle = sortDisabled
        ? style
        : Object.assign(Object.assign({}, (style !== null && style !== void 0 ? style : {})), { transform: CSS.Transform.toString(transform), transition });
    const item = (React.createElement(ComponentType, { ref: (e) => {
            setNodeRef(e);
            itemsViewedRef(e);
        }, data: itemData, handleSelected: handleSelected, style: itemStyle, index: index, isInitialRender: isInitialRender, isHighlightingEnabled: isHighlightingEnabled, loading: loading, attributes: attributes, listeners: listeners }));
    if (!isDynamicRowHeightEnabled) {
        return item;
    }
    const setHoverIndexAndHeight = (event) => {
        resetHoverIndexAndHeight();
        const { target } = event;
        if (target) {
            target.style.height = 'auto';
            setState({ rowHoverIndex: index, rowHoverHeight: target.clientHeight });
        }
    };
    const resetHoverIndexAndHeight = () => setState({ rowHoverIndex: null, rowHoverHeight: rowHeight });
    const handleMouseLeave = (event) => {
        const { target = {} } = event;
        const { activeElement } = document;
        /**
         * condition to avoid reset when hovered away while still focus present in target
         * or its children
         */
        if (target !== activeElement && !target.contains(activeElement)) {
            resetHoverIndexAndHeight();
        }
    };
    const handleMouseEnter = (event) => {
        const { target = {} } = event;
        const { activeElement } = document;
        /**
         * condition to check whether the user hovers over other rows while focus is still active
         * and reset focus and height to avoid UI distortion
         */
        if (!target.contains(activeElement) || target !== activeElement) {
            // @ts-ignore
            activeElement === null || activeElement === void 0 ? void 0 : activeElement.blur();
            setHoverIndexAndHeight(event);
        }
    };
    const handleBlur = (event) => {
        const { target = {}, detail = {} } = event;
        /**
         * condition to reset row height only when the navigation happens from one row to another
         * instead of parent to child which has tabbable elements
         */
        if (!target.contains(document.activeElement) && !target.contains(detail === null || detail === void 0 ? void 0 : detail.relatedTarget)) {
            resetHoverIndexAndHeight();
        }
    };
    const eventsProps = {
        handleMouseEnter,
        handleMouseLeave,
        handleFocus: setHoverIndexAndHeight,
        handleBlur,
    };
    item.props = Object.assign(Object.assign({}, item.props), eventsProps);
    return item;
}
export default InfiniteListItem;
