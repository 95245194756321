import { RENDER, executeMethodsThenDispatchAction } from '../actions';
import { INVOKE_HTTP } from '../types/ISkyfireInvokeHttpMethod';
import { getSkyfireEnvironment } from '../utils';
export function invokeHttp(store, url, clientInformation = []) {
    const method = {
        interface: INVOKE_HTTP,
        url,
        clientInformation,
        queue: {
            interface: 'QueuesInterface.v1_0.SingleThreadedQueue',
            id: 'TEMPLATE',
        },
        forced: false,
        before: [],
        after: [],
        onError: [],
    };
    const { currentTemplate } = store.getState().TemplateStack;
    if (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.id) {
        store.dispatch(executeMethodsThenDispatchAction([method], currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.id, { type: RENDER }));
    }
}
export function getSkyfireSkillUrl(skillName, params) {
    const skillUrl = new URL(`/api/${skillName}`, getSkyfireEnvironment());
    if (params) {
        skillUrl.search = new URLSearchParams(params).toString();
    }
    return skillUrl.toString();
}
