import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useDispatch, useSelector } from 'react-redux';
import { SCRUBBED, UPDATE_SCRUB_INFO } from '../actions';
import * as Styles from './SkipButton.scss';
function SkipButton(props) {
    const dispatch = useDispatch();
    const { mediaId } = useSelector((state) => state.Media);
    useStyles(Styles);
    // TODO change text to be rendered for all locales
    const text = 'Skip button';
    const selectedClass = props.skipDirection === 'forward'
        ? Styles.skipForwardComponent
        : Styles.skipBackwardComponent;
    return (React.createElement("div", { className: selectedClass },
        React.createElement("music-button", { ariaLabelText: text, onmusicActivate: handleClick, variant: props.variant || 'image', "icon-name": props.skipDirection === 'forward' ? 'jumpforward10' : 'jumpback10', "icon-only": true, disabled: props.disabled, size: props.size || 'large' })));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClick(e) {
        if (!e.target.disabled) {
            const position = getPosition();
            dispatch({ type: SCRUBBED, payload: { position, mediaId } });
            dispatch({
                type: UPDATE_SCRUB_INFO,
                payload: {
                    currentTime: props.currentTime,
                    scrubTime: position,
                },
            });
            props.onSeekUpdate(position);
        }
    }
    function getPosition() {
        if (props.skipDirection === 'forward') {
            return Math.min(props.trackDuration, props.currentTime + props.skipTime);
        }
        if (props.skipDirection === 'backward') {
            return Math.max(0, props.currentTime - props.skipTime);
        }
        return 0;
    }
}
export default SkipButton;
