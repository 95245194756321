import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { AppChromeTemplateChangedEvent, ContextMenuOpenedEvent, OverlaysChangedEvent, publish, SearchStateChangedEvent, SkyfireStorageChangedEvent, } from '@amzn/DMWebEventBus';
import { VIDEO_NOW_PLAYING_TEMPLATE, } from '../types/templates/videoNowPlaying';
import { NOW_PLAYING_TEMPLATE } from '../types/templates/nowPlaying';
import { CHROME_TEMPLATE } from '../types/templates/chrome';
function OverlayListener() {
    const isForMusicExperience = useSelector((state) => state.Media.isForMusicExperience);
    const overlayTemplates = useSelector((state) => state.TemplateStack.overlayTemplates);
    const searchState = useSelector((state) => state.SearchSuggestions);
    const hasOverlay = overlayTemplates.length > 0;
    const videoNPVTemplate = overlayTemplates.find((template) => template.interface === VIDEO_NOW_PLAYING_TEMPLATE);
    const isAudioNPVShowing = hasOverlay &&
        !!overlayTemplates.find((template) => template.interface === NOW_PLAYING_TEMPLATE);
    const isVideoNPVShowing = hasOverlay && !!videoNPVTemplate;
    const isMusicExperienceShowing = hasOverlay && !!isForMusicExperience;
    const isNPVShowing = isAudioNPVShowing || isVideoNPVShowing || isMusicExperienceShowing;
    const isSearchSuggestionsShowing = searchState.shouldShowSearchSuggestions &&
        searchState.searchSuggestionsData &&
        Boolean(searchState.searchBoxInput);
    publish(OverlaysChangedEvent, {
        isNPVShowing,
        hasOverlay,
        isSearchSuggestionsShowing,
    });
    return null;
}
function ContextMenuListener() {
    const open = useSelector((state) => state.ContextMenu.open);
    publish(ContextMenuOpenedEvent, open);
    return null;
}
function SearchSuggestionsListener() {
    const searchState = useSelector((state) => state.SearchSuggestions);
    publish(SearchStateChangedEvent, searchState);
    return null;
}
function AppChromeTemplateListener() {
    const currentTemplate = useSelector((state) => state.TemplateStack.currentTemplate, shallowEqual);
    const windowWidth = useSelector((state) => state.BrowserState.windowWidth, shallowEqual);
    const isRWPRetailNavbarInview = useSelector((state) => state.RWP.isRWPRetailNavbarInview, shallowEqual);
    if ((currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.interface) === CHROME_TEMPLATE) {
        const appChromeState = {
            template: currentTemplate,
            windowWidth,
            isRWPRetailNavbarInview,
        };
        publish(AppChromeTemplateChangedEvent, appChromeState);
    }
    return null;
}
function StorageListener() {
    const storageState = useSelector((state) => state.Storage);
    publish(SkyfireStorageChangedEvent, storageState);
    return null;
}
export function ReduxStateListeners() {
    return (React.createElement(React.Fragment, null,
        React.createElement(OverlayListener, null),
        React.createElement(ContextMenuListener, null),
        React.createElement(SearchSuggestionsListener, null),
        React.createElement(AppChromeTemplateListener, null),
        React.createElement(StorageListener, null)));
}
