import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAUSED } from '../actions/Playback';
import { APP_PAUSE, APP_RESUME } from '../mash/MashEvents';
import { isIOSmShop } from '../utils/deviceUtils';
import { globals } from '../utils';
const { window } = globals;
/**
 * Registers visibilitychange event on navigation.
 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 */
export default function PageVisibilityController() {
    const dispatch = useDispatch();
    const { mediaId } = useSelector((state) => state.Media);
    const { play } = useSelector((state) => state.PlaybackStates);
    const isMediaPlaying = (play === null || play === void 0 ? void 0 : play.state) === 'PLAYING';
    let isAppPaused = false;
    const handleAppPauseEvent = useCallback((event) => {
        if ((event === null || event === void 0 ? void 0 : event.type) === APP_PAUSE) {
            isAppPaused = true;
        }
        else if ((event === null || event === void 0 ? void 0 : event.type) === APP_RESUME) {
            isAppPaused = false;
        }
        const shouldPausePlayback = !isAppPaused && document.visibilityState === 'hidden';
        if (shouldPausePlayback && mediaId && isMediaPlaying) {
            dispatch({ type: PAUSED, payload: { mediaId } });
        }
    }, [mediaId, isMediaPlaying]);
    /**
     * Pause playback if the webview is moved to background in mShop ios app.
     */
    useEffect(() => {
        var _a;
        if (isIOSmShop()) {
            document.addEventListener('visibilitychange', handleAppPauseEvent, false);
            (_a = window.P) === null || _a === void 0 ? void 0 : _a.when('mash').execute((mash) => {
                if (mash && mash.addEventListener) {
                    mash.addEventListener(APP_PAUSE, handleAppPauseEvent);
                    mash.addEventListener(APP_RESUME, handleAppPauseEvent);
                }
            });
            return () => {
                var _a;
                document.removeEventListener('visibilitychange', handleAppPauseEvent, false);
                (_a = window.P) === null || _a === void 0 ? void 0 : _a.when('mash').execute((mash) => {
                    if (mash && mash.removeEventListener) {
                        mash.removeEventListener(APP_PAUSE, handleAppPauseEvent);
                        mash.removeEventListener(APP_RESUME, handleAppPauseEvent);
                    }
                });
            };
        }
        return () => { };
    }, [mediaId, play]);
    return null;
}
