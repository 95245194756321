import React, { useCallback } from 'react';
import { useStore, useSelector } from 'react-redux';
import ISkyfireRefinementDropdown from '../types/templates/refinement/ISkyfireRefinementDropdown';
import { bindHandler, dispatchSkyfireMethods } from '../utils';
import { ContextMenuButton } from './ContextMenuButton';
import Styles from './RefinementHeader.scss';
import IApplicationState from '../types/IApplicationState';

interface IRefinementHeaderProps {
    classNames?: string;
    refinementOptions: ISkyfireRefinementDropdown[];
    text: string;
    isActive: boolean;
    isDisabled: boolean;
    isOpened: boolean;
}

export function RefinementHeader(props: IRefinementHeaderProps) {
    const store = useStore();
    const template = useSelector(
        (state: IApplicationState) => state.TemplateStack.currentTemplate
    );

    const onItemSelected = useCallback((methods) => {
        if (template) {
            dispatchSkyfireMethods(store.dispatch, template, methods);
        }
    }, []);

    const classNames = [
        Styles['refinement-wrapper'],
        props.classNames || '',
        props.isDisabled ? Styles.invisible : '',
    ].join(' ');

    return (
        <div className={classNames} aria-hidden={props.isDisabled}>
            <music-refinement-bar
                text={props.text}
                isActive={props.isActive}
                open={props.isOpened}
            >
                {props.refinementOptions.map((refinementOption) => {
                    if (refinementOption.options) {
                        return (
                            <span className={Styles['refinement-option']}>
                                <ContextMenuButton
                                    options={refinementOption.options}
                                    variant={
                                        refinementOption.isActive
                                            ? 'solid'
                                            : 'glass'
                                    }
                                    isRefinement={true}
                                    size='medium'
                                    iconName='caretdown'
                                    text={refinementOption.text}
                                    disabled={props.isDisabled}
                                />
                            </span>
                        );
                    }
                    return (
                        <music-button
                            className={Styles['refinement-option']}
                            variant={
                                refinementOption.isActive ? 'solid' : 'glass'
                            }
                            refinement='static'
                            size='medium'
                            onmusicActivate={bindHandler(
                                onItemSelected,
                                null,
                                refinementOption.onItemSelected
                            )}
                            disabled={props.isDisabled}
                        >
                            {refinementOption.text}
                        </music-button>
                    );
                })}
            </music-refinement-bar>
        </div>
    );
}
