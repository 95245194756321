import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useInView } from '../../../utils/useItemsInView';
import * as Styles from './TwitchVideoPlayerWidget.scss';
const getTwitchVideoPlayerUrl = (source, sourceType, autoPlay, muted) => `https://player.twitch.tv/?${sourceType}=${source}` +
    `&parent=${window.location.host}` +
    `&autoplay=${autoPlay}` +
    `&muted=${muted}`;
// NOTE: To test this component locally, update value of `port` constant in webpack.config.js and
// port value of `local` key in `SKYFIRE_ENVIRONMENTS` map in getSkyfireEnvironment.js to 443.
export default function TwitchVideoPlayerWidget(props) {
    const { title, source, sourceType, autoPlay, muted } = props.data;
    useStyles(Styles);
    return (React.createElement("div", { className: Styles.twitchVideoPlayer },
        React.createElement("iframe", { title: title, ref: useInView(props.onViewed), src: getTwitchVideoPlayerUrl(source, sourceType, autoPlay, muted), allowFullScreen: true })));
}
