import ISkyfireButtonElement from 'src/types/templates/widgets/ISkyfireButtonElement';
import ISkyfireMethod from '../../ISkyfireMethod';
import ISkyfireTemplate from '../ISkyfireTemplate';
import ISkyfireSearchSuggestionsData from '../searchSuggestions';
import ISettingsSection from '../settings/ISettingsSection';
import { ISkyfireChromeTemplateMenuElement } from './ISkyfireChromeTemplateMenuElement';
import ISkyfireChromeTemplateSearchBox from './ISkyfireChromeTemplateSearchBox';
import ISkyfireChromeTemplateSubNavMenuItemElement from './ISkyfireChromeTemplateSubNavMenuItemElement';
import ISkyfireContextMenuWidget from '../widgets/ISkyfireContextMenuWidget';

export const CHROME_TEMPLATE = 'Web.TemplatesInterface.v1_0.Touch.ChromeTemplateInterface.ChromeTemplate';
export default interface ISkyfireChromeTemplate extends ISkyfireTemplate {
  readonly interface: typeof CHROME_TEMPLATE;
  readonly menuItems: ISkyfireChromeTemplateMenuElement[];
  readonly subNavMenuItems: ISkyfireChromeTemplateSubNavMenuItemElement[];
  readonly searchSuggestionsData: ISkyfireSearchSuggestionsData;
  readonly searchBox: ISkyfireChromeTemplateSearchBox;
  readonly logoImage: string;
  readonly logoImageSmall: string;
  readonly logoDeeplink: string;
  readonly logoAltText: string;
  readonly logoOnItemSelected: ISkyfireMethod[];
  readonly onViewed: ISkyfireMethod[];
  readonly onInteraction: ISkyfireMethod[];
  readonly selectedMenuItem: number;
  readonly innerTemplate: ISkyfireTemplate;
  readonly settingsIcon?: string;
  readonly settingsImage?: string;
  readonly settingsSections?: ISettingsSection[];
  readonly showSignInButton: boolean;
  readonly signInButtonText: string;
  readonly activityFeedIcon?: string;
  readonly activityFeedAltText?: string;
  readonly activityFeed?: ISkyfireContextMenuWidget;
  readonly navbarActionButton?: ISkyfireButtonElement;
}

export interface IChromeTemplateProps {
  template: ISkyfireChromeTemplate;
}
