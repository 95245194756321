import React, { Component, Fragment } from 'react';
import Widget from '.';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import ISkyfireWidgetElement from '../../types/templates/widgets/ISkyfireWidgetElement';
import LoadingWidget from '../LoadingWidget';

interface IWidgetListProps {
    list: any[];
    isEnumerated?: boolean;
    onEndOfList?: () => void;
    handleSelected: (methods: ISkyfireMethod[]) => void;
}

interface IWidgetListState {
    index: number;
}

export class WidgetList extends Component<IWidgetListProps, IWidgetListState> {
    constructor(props: IWidgetListProps) {
        super(props);
        this.state = { index: window.amznMusic.ssr ? props.list.length : 0 };
    }

    public componentWillMount() {
        this.addNext(2);
    }

    public componentDidUpdate(prevProps: IWidgetListProps) {
        if (prevProps.list.length < this.props.list.length) {
            if (prevProps.list.length === this.state.index) {
                this.addNext();
            }
        }
    }

    private addNext(num = 1) {
        if (this.state.index >= this.props.list.length) {
            return;
        }

        const start = this.state.index;
        const end = Math.min(num + start, this.props.list.length);
        this.setState({ index: end });
    }

    private rendered = () => {
        if ('requestAnimationFrame' in window) {
            window.requestAnimationFrame(() => this.addNext());
        } else {
            setTimeout(() => this.addNext(), 10);
        }
    };

    private getWidgets(widgetsToRender: ISkyfireWidgetElement[]) {
        return widgetsToRender.map((widget: ISkyfireWidgetElement) => (
            <Widget
                data={widget}
                rendered={this.rendered}
                handleSelected={this.props.handleSelected}
                isEnumerated={this.props.isEnumerated}
            />
        ));
    }

    // tslint:disable-next-line: member-ordering
    public render() {
        const showLoader =
            this.state.index >= this.props.list.length - 1 &&
            !!this.props.onEndOfList;
        return (
            <Fragment>
                {this.getWidgets(this.props.list.slice(0, this.state.index))}
                {showLoader && (
                    <LoadingWidget onReached={this.props.onEndOfList} />
                )}
            </Fragment>
        );
    }
}
