import ISkyfireMethod from '../../ISkyfireMethod';
import ISkyfireVisualListingButtonElement from './ISkyfireVisualListingButtonElement';
import ISkyfireVerticalItem from './items/ISkyfireVerticalItem';

export const PODCAST_LIBRARY_SHOWS_WIDGET =
    'Podcast.Web.WidgetsInterface.LibraryShowsWidgetElement';

export default interface IPodcastLibraryShows {
    interface: typeof PODCAST_LIBRARY_SHOWS_WIDGET;
    header: string;
    button?: ISkyfireVisualListingButtonElement;
    items: ISkyfireVerticalItem[];
    fallbackMessage: string;
    onEndOfWidget: ISkyfireMethod[];
    onViewed: ISkyfireMethod[];
    uuid?: string;
}
