import {
    IConditionElement,
    ITimeConditionElement,
    IWriteElement,
} from '../types/appsync/IAppSync';
import { Entry } from './Entry';
import { SyncData } from './SyncData';

export class Sync<T extends IWriteElement, S, R> {
    public owner: string;

    public syncData: SyncData<T, S, R>;

    constructor(owner: string, syncData: SyncData<T, S, R>) {
        this.owner = owner;
        this.syncData = syncData;
    }

    public read(id: string): R | undefined {
        return this.syncData.read(id);
    }

    public readStates(): R[] {
        return this.syncData.readStates();
    }

    public write(writeElement: T, condition?: IConditionElement) {
        const timeCondition = condition as ITimeConditionElement;
        const isConditionSatified = this.conditionSatisfied(
            writeElement,
            timeCondition!
        );
        if (isConditionSatified === false) {
            return;
        }
        this.writeStates(writeElement, timeCondition);
        if (condition) {
            return;
        }
        this.writeToOperations(writeElement);
    }

    public writeStates(element: T, condition?: IConditionElement) {
        this.syncData.writeStates(element, condition);
    }

    public writeToOperations(element: T) {
        this.syncData.writeToOperations(element);
        this.reportOperations();
    }

    public writeMetadata(element: S) {
        this.syncData.writeMetadata(element);
    }

    public getClientState(name: string): Entry | undefined {
        return this.syncData.getClientState(name);
    }

    public deleteOperation(ids: string[]) {
        this.syncData.deleteOperation(ids);
    }

    public reportOperations() {
        this.syncData.reportOperations();
    }

    public retryOperations() {
        this.syncData.reportOperations();
    }

    public conditionSatisfied(
        element: T,
        condition: IConditionElement
    ): boolean {
        return this.syncData.conditionSatisfied(element, condition);
    }
}
