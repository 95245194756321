import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOGGLE_TO_AUDIO, TOGGLE_TO_VIDEO } from '../actions/Playback';
import * as ToggleButtonStyles from '../views/TemplateContainer.scss';
export default function ToggleAudioVideoButton() {
    const dispatch = useDispatch();
    const { audioVideo } = useSelector((state) => state.PlaybackStates);
    const toggleAlignmentClassName = (audioVideo === null || audioVideo === void 0 ? void 0 : audioVideo.state) === 'VIDEO'
        ? ToggleButtonStyles.avToggleAlignmentRight
        : ToggleButtonStyles.avToggleAlignmentLeft;
    return (React.createElement("div", { className: ToggleButtonStyles.avToggleButton },
        React.createElement("span", { className: toggleAlignmentClassName }),
        React.createElement("span", { className: ToggleButtonStyles.avToggleIconContainer },
            React.createElement("music-button", { onClick: handleToggleToAudio, "icon-name": "song", "icon-only": true, variant: "primary", size: "small" })),
        React.createElement("span", { className: ToggleButtonStyles.avToggleIconContainer },
            React.createElement("music-button", { onClick: handleToggleToVideo, "icon-name": "videoCamera", "icon-only": true, variant: "primary", size: "small" }))));
    function handleToggleToAudio(e) {
        if ((audioVideo === null || audioVideo === void 0 ? void 0 : audioVideo.state) === 'VIDEO') {
            dispatch({ type: TOGGLE_TO_AUDIO });
        }
    }
    function handleToggleToVideo(e) {
        if ((audioVideo === null || audioVideo === void 0 ? void 0 : audioVideo.state) === 'AUDIO') {
            dispatch({ type: TOGGLE_TO_VIDEO });
        }
    }
}
