exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1liu0T-kaq2FpQZVxUMV6a{display:flex;justify-content:center}._1liu0T-kaq2FpQZVxUMV6a ._2xFKnSjnlUw-74oVB_FFiq{box-sizing:border-box;position:fixed;top:50%}", ""]);

// exports
exports.locals = {
	"container": "_1liu0T-kaq2FpQZVxUMV6a",
	"loading-spinner": "_2xFKnSjnlUw-74oVB_FFiq",
	"loadingSpinner": "_2xFKnSjnlUw-74oVB_FFiq"
};