import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useRef, useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import * as videoStoryStyles from './VideoStory.scss';
import * as Styles from './View.scss';
import { getInstance as getHtml5VideoPlayerInstance } from '../player/html5VideoPlayer';
import { dispatchSkyfireMethods } from '../utils';
import HTML5VideoPlayerEvent from '../player/html5VideoPlayer/players/HTML5VideoPlayerEvent';
import { isDesktop } from '../utils/platform';
// eslint-disable-next-line no-shadow
var PlaybackIconState;
(function (PlaybackIconState) {
    PlaybackIconState["NONE"] = "none";
    PlaybackIconState["PLAY"] = "play";
    PlaybackIconState["LOADER"] = "loader";
})(PlaybackIconState || (PlaybackIconState = {}));
const videoStoryPlayerContainerId = 'videoStoryPlayerContainer';
function VideoStory(props) {
    useStyles(videoStoryStyles, Styles);
    const { playbackURL, asin, pid, posterUrl, title, durationSeconds, artistInfo, pillNavigator, closeButton, logo, autoPlay, onViewed, onInteraction, onStarted, onResumed, onPaused, onFinished, onStopped, onError, } = props.template;
    const [progress, setProgress] = useState(0);
    const [playbackIconState, setPlaybackIconState] = useState(PlaybackIconState.LOADER);
    const isStartedRef = useRef(false);
    const executeOnStopped = useRef(() => { });
    const videoPlayerRef = useRef();
    const timeUpdateEventHandler = () => {
        if (videoPlayerRef.current) {
            setProgress(getProgress(videoPlayerRef.current));
        }
    };
    const playEventHandler = () => {
        setPlaybackIconState(PlaybackIconState.LOADER);
    };
    const pauseEventHandler = () => {
        if (videoPlayerRef.current && !videoPlayerRef.current.isEnded()) {
            if (isStartedRef.current) {
                dispatchSkyfireMethods(props.dispatch, props.template, onPaused);
            }
            else {
                dispatchSkyfireMethods(props.dispatch, props.template, onStopped);
                executeOnStopped.current();
                executeOnStopped.current = () => { };
            }
        }
        setPlaybackIconState(PlaybackIconState.PLAY);
    };
    const playingEventHandler = () => {
        if (isStartedRef.current) {
            dispatchSkyfireMethods(props.dispatch, props.template, onResumed);
        }
        else {
            dispatchSkyfireMethods(props.dispatch, props.template, onStarted);
            isStartedRef.current = true;
        }
        setPlaybackIconState(PlaybackIconState.NONE);
    };
    const endedEventHandler = () => {
        dispatchSkyfireMethods(props.dispatch, props.template, onFinished);
        isStartedRef.current = false;
        setPlaybackIconState(PlaybackIconState.PLAY);
    };
    const errorEventHandler = () => {
        dispatchSkyfireMethods(props.dispatch, props.template, onError);
        isStartedRef.current = false;
        setPlaybackIconState(PlaybackIconState.LOADER);
    };
    const waitingEventHandler = () => {
        setPlaybackIconState(PlaybackIconState.LOADER);
    };
    useEffect(() => {
        const oldBodyOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
        if (!playbackURL) {
            return () => {
                document.body.style.overflow = oldBodyOverflow;
            };
        }
        dispatchSkyfireMethods(props.dispatch, props.template, onViewed);
        const player = getHtml5VideoPlayerInstance();
        videoPlayerRef.current = player;
        player.setPoster(posterUrl !== null && posterUrl !== void 0 ? posterUrl : '');
        player.addEventListener(HTML5VideoPlayerEvent.TIME_UPDATE, timeUpdateEventHandler);
        player.addEventListener(HTML5VideoPlayerEvent.PLAY, playEventHandler);
        player.addEventListener(HTML5VideoPlayerEvent.PLAYING, playingEventHandler);
        player.addEventListener(HTML5VideoPlayerEvent.PAUSE, pauseEventHandler);
        player.addEventListener(HTML5VideoPlayerEvent.ENDED, endedEventHandler);
        player.addEventListener(HTML5VideoPlayerEvent.ERROR, errorEventHandler);
        player.addEventListener(HTML5VideoPlayerEvent.WAITING, waitingEventHandler);
        player.setAutoplay(autoPlay);
        player.attachToElement(videoStoryPlayerContainerId);
        player.attachSource(playbackURL);
        if (!player.isEnded() && autoPlay) {
            player.play();
        }
        else {
            setPlaybackIconState(PlaybackIconState.PLAY);
        }
        return () => {
            document.body.style.overflow = oldBodyOverflow;
            if (isStartedRef.current && !player.isEnded()) {
                dispatchSkyfireMethods(props.dispatch, props.template, onStopped);
            }
            player.removeEventListener(HTML5VideoPlayerEvent.TIME_UPDATE, timeUpdateEventHandler);
            player.removeEventListener(HTML5VideoPlayerEvent.PLAY, playEventHandler);
            player.removeEventListener(HTML5VideoPlayerEvent.PLAYING, playingEventHandler);
            player.removeEventListener(HTML5VideoPlayerEvent.PAUSE, pauseEventHandler);
            player.removeEventListener(HTML5VideoPlayerEvent.ENDED, endedEventHandler);
            player.removeEventListener(HTML5VideoPlayerEvent.ERROR, errorEventHandler);
            player.removeEventListener(HTML5VideoPlayerEvent.WAITING, waitingEventHandler);
        };
    }, [playbackURL]);
    const handleVideoClick = () => {
        dispatchSkyfireMethods(props.dispatch, props.template, onInteraction);
        if (!videoPlayerRef.current) {
            return;
        }
        const videoPlayer = videoPlayerRef.current;
        const wasPlaying = videoPlayer.isPlaying();
        videoPlayer.pause();
        if (!wasPlaying) {
            if (videoPlayer.isEnded()) {
                videoPlayer.seekTo(0);
            }
            videoPlayer.play();
        }
    };
    const handleJOPSelected = (event, onSelectedCallback = () => { }) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        const wasStarted = isStartedRef.current;
        const videoPlayer = videoPlayerRef.current;
        isStartedRef.current = false;
        if (videoPlayer === null || videoPlayer === void 0 ? void 0 : videoPlayer.isPaused()) {
            if (wasStarted) {
                dispatchSkyfireMethods(props.dispatch, props.template, onStopped);
            }
            onSelectedCallback();
        }
        else {
            executeOnStopped.current = onSelectedCallback;
            videoPlayer === null || videoPlayer === void 0 ? void 0 : videoPlayer.pause();
        }
    };
    const onPillNavigatorSelected = (event) => {
        handleJOPSelected(event, () => {
            var _a, _b;
            dispatchSkyfireMethods(props.dispatch, props.template, (_b = (_a = pillNavigator === null || pillNavigator === void 0 ? void 0 : pillNavigator.primaryLink) === null || _a === void 0 ? void 0 : _a.onItemSelected) !== null && _b !== void 0 ? _b : []);
        });
    };
    const onClose = (event) => {
        handleJOPSelected(event, async () => {
            var _a, _b;
            dispatchSkyfireMethods(props.dispatch, props.template, (_a = closeButton === null || closeButton === void 0 ? void 0 : closeButton.onItemSelected) !== null && _a !== void 0 ? _a : []);
            await new Promise((f) => setTimeout(f, 1));
            (_b = videoPlayerRef.current) === null || _b === void 0 ? void 0 : _b.unload();
        });
    };
    return (React.createElement("div", { className: videoStoryStyles.videoStoryContainer },
        playbackURL && (React.createElement("div", { id: videoStoryPlayerContainerId, className: videoStoryStyles.videoStoryPlayerContainer, onClick: handleVideoClick })),
        playbackIconState !== PlaybackIconState.NONE && (React.createElement("music-button", { className: videoStoryStyles.videoPlaybackIcon, onmusicActivate: handleVideoClick, "icon-name": playbackIconState, "icon-only": true, variant: "glass", size: "large", role: "button", "aria-disabled": "false", tabindex: "0", refinement: "none" })),
        React.createElement("music-button", { className: videoStoryStyles.videoClose, onmusicActivate: onClose, "icon-name": "cancel", "icon-only": true, variant: "glass", size: "small", role: "button", "aria-disabled": "false", tabindex: "0", refinement: "none" }),
        React.createElement("div", { className: videoStoryStyles.videoInfo },
            title && artistInfo && artistInfo.image && artistInfo.primaryText.text && (React.createElement("div", { className: videoStoryStyles.videoMetadata },
                React.createElement("div", { className: videoStoryStyles.videoTitle }, title),
                React.createElement(ArtistInfo, { horizontalItemKind: "circle", imageSrc: artistInfo === null || artistInfo === void 0 ? void 0 : artistInfo.image, primaryText: artistInfo === null || artistInfo === void 0 ? void 0 : artistInfo.primaryText.text }))),
            pillNavigator && (React.createElement("music-button", { className: videoStoryStyles.pillNavigator, variant: "glass", type: "pill", size: isDesktop(window) ? 'large' : 'small', role: "button", ariaDisabled: true, tabindex: "0", refinement: "none", "remove-text-transform": true, text: pillNavigator.text, onClick: onPillNavigatorSelected }, pillNavigator.text))),
        logo && React.createElement("img", { className: videoStoryStyles.musicLogo, src: logo }),
        React.createElement(VideoControls, { progress: progress })));
}
function getProgress(video) {
    return (video.getCurrentTime() / video.getDuration()) * 100;
}
function ArtistInfo(props) {
    return (React.createElement("div", { className: videoStoryStyles.artistInfo },
        React.createElement("music-image", { size: "56", src: props.imageSrc, kind: props.horizontalItemKind, alt: props.primaryText }),
        React.createElement("music-link", { title: props.primaryText },
            props.primaryText,
            React.createElement("slot", { name: "tags", slot: "tags" }))));
}
function VideoControls(props) {
    return (React.createElement("div", { className: videoStoryStyles.videoControls },
        React.createElement("div", { className: videoStoryStyles.videoTimeline },
            React.createElement("div", { className: videoStoryStyles.videoBar },
                React.createElement("div", { className: videoStoryStyles.videoBarInner, style: { width: `${props.progress}%` } })))));
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}
export default withStyles(Styles)(connect(null, mapDispatchToProps)(VideoStory));
