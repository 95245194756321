export const BUFFER = 'Podcast.Web.PlaybackInterface.BufferMediaMethod';
export const RESTORE_MEDIA = 'Podcast.Web.PlaybackInterface.RestoreMediaMethod';
export const PLAY = 'Podcast.Web.PlaybackInterface.PlayMediaMethod';
export const CLEAR = 'Podcast.Web.PlaybackInterface.ClearMediaMethod';
export const PLAY_NEXT = 'PlaybackInterface.v1_0.PlayNextMediaMethod';
export const PLAY_PREVIOUS = 'PlaybackInterface.v1_0.PlayPreviousMediaMethod';
export const PAUSED = 'Podcast.Web.PlaybackInterface.PauseMediaMethod';
export const STORE_RESTORE_TOKEN =
    'Podcast.Web.PlaybackInterface.StoreRestoreTokenMethod';
export const RESTORE_PLAYBACK =
    'PlaybackInterface.v1_0.RestorePodcastPlaybackMethod';
export const RESUMED = 'PlaybackInterface.v1_0.ResumeMediaMethod';
export const STARTED = 'PLAYBACK_STARTED';
export const SCRUBBED = 'PLAYBACK_SCRUBBED';
export const PRE_SCRUBBED = 'PLAYBACK_PRE_SCRUBBED';
export const POST_SCRUBBED = 'PLAYBACK_POST_SCRUBBED';
export const ENDED = 'PLAYBACK_ENDED';
export const STOPPED = 'PLAYBACK_STOPPED';
export const REBUFFERED = 'PLAYBACK_REBUFFERED';
export const ERROR = 'PLAYBACK_ERROR';
export const PLAYBACK_TOGGLE = 'PLAYBACK_TOGGLE';
export const PLAYBACK_NOT_SUPPORTED = 'PLAYBACK_NOT_SUPPORTED';
export const SKIP_FORWARD = 'SKIP_FORWARD';
export const SKIP_BACKWARD = 'SKIP_BACKWARD';
export const SET_PLAYBACK_SPEED = 'SET_PLAYBACK_SPEED';
export const SET_SLEEP_TIMER = 'SET_SLEEP_TIMER';
export const SET_DURATION_SECONDS = 'SET_DURATION_SECONDS';
export const SET_PLAYBACK_START_OFFSET = 'SET_PLAYBACK_START_OFFSET';
export const SET_PRE_SCRUB_POSITION = 'SET_PRE_SCRUB_POSITION';
export const SET_PRE_SCRUB_START_OFFSET = 'SET_PRE_SCRUB_START_OFFSET';
