exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2VAGFRAS11BW55WlANKjxc{width:100vw;height:100vh;background-image:url(https://m.media-amazon.com/images/G/01/introducing_profiles_music/educational_splash_background.png);display:flex}._7vqj8D45GgSWog8Q5AEHJ{width:40%;min-width:300px;margin:auto;text-align:center;-ms-grid-column-align:center;justify-self:center}._7vqj8D45GgSWog8Q5AEHJ music-button{margin-top:10%;width:80%}._3vnE97ZkuxQ2AF17V6SDDG{text-align:start;display:flex;flex-direction:row;justify-content:start;margin:10% auto}._3vnE97ZkuxQ2AF17V6SDDG music-icon{margin-inline-end:10%}", ""]);

// exports
exports.locals = {
	"container": "_2VAGFRAS11BW55WlANKjxc",
	"splashScreen": "_7vqj8D45GgSWog8Q5AEHJ",
	"infoBlock": "_3vnE97ZkuxQ2AF17V6SDDG"
};