import React, { useCallback, useContext } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useDispatch } from 'react-redux';
import Template from '../../../../Contexts/Template';
import { dispatchSkyfireMethods } from '../../../../utils';
import * as Styles from './LinkButton.scss';
export default function LinkButton({ href, text, onItemSelected, openInNewTab, image, }) {
    useStyles(Styles);
    const dispatch = useDispatch();
    const template = useContext(Template);
    const target = openInNewTab ? '_blank' : '_self';
    const handleClick = useCallback(() => {
        if (template) {
            dispatchSkyfireMethods(dispatch, template, onItemSelected);
        }
    }, [dispatch, template, onItemSelected]);
    return (React.createElement("div", { className: Styles.container },
        image ? React.createElement("music-image", { src: image }) : React.createElement("music-icon", { name: "open", size: "micro" }),
        React.createElement("music-link", { kind: "accent", href: href, onClick: handleClick, target: target }, text)));
}
