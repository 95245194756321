import React from 'react';
import { getEpisodeRowHeight } from 'src/utils/gridHelpers';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import IEpisodeGroupItemElements from '../../types/templates/widgets/IEpisodeGroupItemElements';
import EpisodeRowItem from './items/EpisodeRowItem';
import InfiniteList from './items/InfiniteList';
import { useItemsInView } from '../../utils/useItemsInView';
import ISkyfireEpisodeRowItem from '../../types/templates/widgets/items/ISkyfireEpisodeRowItem';
import ISkyfireGroupHeaderElement from '../../types/templates/widgets/items/ISkyfireGroupHeaderElement';
import ISkyfireEpisodeGroupItemElement from '../../types/templates/widgets/items/ISkyfireEpisodeGroupItemElement';

interface InterfaceEpisodeGroupItemElements {
    data: IEpisodeGroupItemElements;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    box?: ClientRect;
    loading?: boolean;
    isVisualPlayQueue?: boolean;
    onViewed?: () => void;
}

type ListItem = ISkyfireEpisodeRowItem | ISkyfireGroupHeaderElement;

export function EpisodeGroupItemsWidget(
    props: InterfaceEpisodeGroupItemElements
) {
    const callbackRef = useItemsInView(props.onViewed);
    let listOfEpisodesAndHeaders: ListItem[] = [];
    const { items } = props.data;
    const existingHeaders: String[] = [];
    items.forEach(
        (episodeGroupItemElement: ISkyfireEpisodeGroupItemElement) => {
            if (
                !existingHeaders.includes(
                    episodeGroupItemElement.header.headerTitle
                )
            ) {
                existingHeaders.push(
                    episodeGroupItemElement.header.headerTitle
                );
                listOfEpisodesAndHeaders.push(episodeGroupItemElement.header);
            }
            listOfEpisodesAndHeaders = listOfEpisodesAndHeaders.concat(
                episodeGroupItemElement.items
            );
        }
    );
    let rowItems: ISkyfireEpisodeRowItem[] = [];
    if (props.data.items[0] !== null) {
        rowItems = props.data.items[0].items;
    }
    const listData = {
        items: listOfEpisodesAndHeaders,
        onEndOfWidget: props.data.onEndOfWidget,
    };
    return (
        <InfiniteList
            data={listData}
            handleSelected={props.handleSelected}
            componentType={EpisodeRowItem}
            rowHeight={getEpisodeRowHeight(rowItems)}
            loading={props?.loading}
            isVisualPlayQueue={props?.isVisualPlayQueue}
            itemsViewedRef={callbackRef}
        />
    );
}
