import { Store } from 'redux';
import {
    FOLLOWS_LAST_SYNC_TIME,
    WEB_STORAGE,
} from '../appsync/AppSyncConstants';
import IApplicationState from '../types/IApplicationState';
import { Follow } from './Follow';
import { SAVE_FOLLOW } from './FollowActions';
import { IFollowDao } from './IFollowDao';

export class FollowDaoSessionStorage implements IFollowDao {
    private store: Store<IApplicationState>;

    constructor(store) {
        this.store = store;
    }

    public insert(follow: Follow) {
        this.store.dispatch({
            type: SAVE_FOLLOW,
            payload: { follow },
        });
    }

    public getFollow(id: string, customerId: string): Follow | undefined {
        const key = `${id}_Follow`;
        const follow = WEB_STORAGE.getItem(key);
        if (follow) {
            return new Follow(JSON.parse(follow));
        }
        return undefined;
    }

    public getAll(
        customerId: string,
        isFollowed: boolean,
        isLimited?: boolean
    ): Follow[] {
        let isFollowedList: Follow[] = [];
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (key.endsWith('Follow')) {
                const follow = JSON.parse(value);
                if (follow && follow.isFollowed === isFollowed) {
                    isFollowedList.push(new Follow(follow));
                }
            }
        }
        isFollowedList = isFollowedList.sort((a, b) =>
            a.updatedTime < b.updatedTime ? 1 : -1
        );
        if (isLimited === true) {
            isFollowedList = isFollowedList.slice(0, 20);
            return isFollowedList;
        }
        return isFollowedList;
    }

    public getLastSyncTime(customerId: string): number {
        const key = FOLLOWS_LAST_SYNC_TIME;
        const lastSyncTime = Number(WEB_STORAGE.getItem(key));
        return lastSyncTime ?? 0;
    }

    public clearFollowData(customerId: string) {
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (
                key.endsWith('Follow') ||
                key.endsWith(FOLLOWS_LAST_SYNC_TIME)
            ) {
                WEB_STORAGE.removeItem(key);
            }
        }
    }
}
