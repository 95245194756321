import { StartVideoStoryPlaybackEvent, subscribe, } from '@amzn/DMWebEventBus';
import { CREATE_TEMPLATE, enqueueSkyfireMethod, REMOVE_TEMPLATE } from '../actions';
import { VIDEO_STORY_PLAYBACK_TEMPLATE } from '../types/templates/videoStoryPlayback';
import { INVOKE_HTTP } from '../types/ISkyfireInvokeHttpMethod';
import { getSkyfireSkillUrl } from './utils';
import { getGenericErrorDialogMethod } from '../utils/genericErrorDialog';
function getUrlForPlayVideoStorySkill(storyAsin, autoplay, artistAsin, deeplink) {
    const params = { storyAsin };
    if (autoplay !== undefined) {
        params.autoPlay = String(autoplay);
    }
    if (artistAsin) {
        params.artistAsin = artistAsin;
    }
    if (deeplink) {
        params.deeplink = deeplink;
    }
    return getSkyfireSkillUrl('playVideoStory', params);
}
function getVideoStoryPlaybackRequestMethod(playVideoStorySkillUrl, posterUrl, title, durationSeconds) {
    return {
        interface: CREATE_TEMPLATE,
        template: {
            interface: VIDEO_STORY_PLAYBACK_TEMPLATE,
            closeButton: {
                interface: 'Web.TemplatesInterface.v1_0.Touch.NowPlayingTemplateInterface.CloseButtonElement',
                text: '',
                icon: 'cancel',
                onItemSelected: [
                    {
                        interface: REMOVE_TEMPLATE,
                        queue: {
                            interface: 'QueuesInterface.v1_0.SingleThreadedQueue',
                            id: 'TEMPLATE',
                        },
                        forced: false,
                    },
                ],
            },
            logo: 'https://m.media-amazon.com/images/G/01/music-tv/music_logo._SX260_SY50_BL0_QL100_FMpng_.png',
            posterUrl,
            title,
            durationSeconds,
            onCreated: [
                {
                    interface: INVOKE_HTTP,
                    url: playVideoStorySkillUrl,
                    clientInformation: [
                        'VideoStoryPlaybackInterface.v1_0.VideoPlaybackCapabilitiesClientInformation',
                    ],
                    before: [],
                    after: [],
                    onSuccess: [],
                    onError: [getGenericErrorDialogMethod()],
                    queue: {
                        interface: 'QueuesInterface.v1_0.MultiThreadedQueue',
                        id: 'MT_HTTP',
                    },
                    forced: false,
                },
            ],
            launchMode: 'standard',
        },
        screenMode: 'templateList',
        queue: {
            interface: 'QueuesInterface.v1_0.SingleThreadedQueue',
            id: 'TEMPLATE',
        },
        forced: false,
    };
}
export const handleStartVideoStoryPlaybackEvent = (store) => (playbackRequest) => {
    const { catalogId, autoplay, artistAsin, deeplink, metadata } = playbackRequest;
    const playVideoStorySkillUrl = getUrlForPlayVideoStorySkill(catalogId, autoplay, artistAsin, deeplink);
    const method = getVideoStoryPlaybackRequestMethod(playVideoStorySkillUrl, metadata === null || metadata === void 0 ? void 0 : metadata.artworkUrl, metadata === null || metadata === void 0 ? void 0 : metadata.title, metadata === null || metadata === void 0 ? void 0 : metadata.duration);
    const { currentTemplate } = store.getState().TemplateStack;
    store.dispatch(enqueueSkyfireMethod({
        queue: method.queue,
        method: Object.assign(Object.assign({}, method), { owner: currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.id }),
    }));
};
export function registerVideoStoryPlaybackModule(store) {
    subscribe(StartVideoStoryPlaybackEvent, handleStartVideoStoryPlaybackEvent(store));
}
