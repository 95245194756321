export function formatDeviceTimeZone(offsetInMinutes) {
    if (!offsetInMinutes) {
        return '';
    }

    const offset = -1 * offsetInMinutes;

    const sign = offset < 0 ? '-' : '+';
    const hours = `0${Math.abs(offset) / 60}`.slice(-2);
    const minutes = `0${offset % 60}`.slice(-2);

    return `${sign}${hours}:${minutes}`;
}
