import * as React from 'react';
import SkyfireItemTag from '../types/templates/widgets/items/SkyfireItemTag';

export function buildTags(
    tags: SkyfireItemTag[],
    isDisabled: boolean = false,
    isInline: boolean = false
) {
    return (
        tags && (
            <music-tag-group slot='tags'>
                {tags.map((tag: SkyfireItemTag) => (
                    <music-tag disabled={isDisabled} inline={isInline}>
                        {tag}
                    </music-tag>
                ))}
            </music-tag-group>
        )
    );
}
