import { IWriteElement } from '../types/appsync/IAppSync';

export class CompletedWriteElement implements IWriteElement {
    public id: string;

    public podcastId: string;

    public podcastTitle: string;

    public podcastImage: string;

    public authors: string[];

    public seasonNumber?: number;

    public title: string;

    public description: string;

    public image: string;

    public completed: boolean;

    public __type: string;

    public interface?: string;

    public publishTime: string;

    constructor(
        id,
        podcastId,
        podcastTitle,
        podcastImage,
        authors,
        seasonNumber,
        title,
        description,
        image,
        completed,
        publishTime
    ) {
        this.id = id;
        this.podcastId = podcastId;
        this.podcastTitle = podcastTitle;
        this.podcastImage = podcastImage;
        this.authors = authors;
        this.seasonNumber = seasonNumber;
        this.title = title;
        this.description = description;
        this.image = image;
        this.completed = completed;
        this.__type = 'Podcast.CompletedInterface.v1_0#CompletedWriteElement';
        this.interface = 'Podcast.Web.CompletedInterface.CompletedWriteElement';
        this.publishTime = publishTime;
    }
}
