import { SET_SAMPLE_MEDIA, PLAY_SAMPLE, CLEAR_SAMPLE, } from '../actions/Playback';
const initialState = {
    content: {
        state: 'READY',
    },
};
function updateState(state, props) {
    const newPropsValue = props.reduce((newProps, prop) => {
        if (!state[prop.name]) {
            return newProps;
        }
        return Object.assign(Object.assign({}, newProps), { [prop.name]: Object.assign(Object.assign({}, state[prop.name]), { state: prop.value }) });
    }, {});
    return Object.assign(Object.assign({}, state), newPropsValue);
}
const reducer = {
    [CLEAR_SAMPLE]: () => initialState,
    [SET_SAMPLE_MEDIA]: (state, action) => {
        if (action.payload.states) {
            return Object.assign(Object.assign({}, state), action.payload.states);
        }
        return state;
    },
    [PLAY_SAMPLE]: (state, action) => updateState(state, [{ name: 'play', value: 'PLAYING' }]),
};
export function SamplePlaybackStatesReducer(state = initialState, action) {
    if (reducer[action.type]) {
        return reducer[action.type](state, action);
    }
    return state;
}
