import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingSpinner } from '../components/LoadingSpinner';
import RefinementHeader from '../components/RefinementHeader';
import Widget from '../components/widgets';
import { dispatchSkyfireMethods, dispatchTemplateRendered } from '../utils';
import * as menuStyles from './Menu.scss';
import Message from './Message';
import { PILL_NAVIGATOR_WIDGET } from '../types/templates/widgets/ISkyfirePillNavigatorWidget';
import { useObserver } from '../utils/ObserverHooks';
import UpsellButton from '../components/widgets/items/UpsellButton';
export default function SubNav({ template }) {
    const { widgets, id, updatedAt, header, button, emptyTemplateImage, emptyTemplateText, emptyTemplateTitle, emptyTemplateButtonText, emptyTemplateButtonPrimaryLink, refinementHeader, shouldHideSubNav, upsellButtonElement, isExploreButtonAndEmptyPromptNeeded, } = template;
    useStyles(menuStyles);
    const dispatch = useDispatch();
    const handleSelected = useCallback((onItemSelected, event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        dispatchSkyfireMethods(dispatch, template, onItemSelected);
    }, [dispatch, template]);
    const widgetsRendered = useCallback((event) => {
        if (event.target.id === template.id) {
            dispatchTemplateRendered(dispatch, template, event.detail);
            dispatchSkyfireMethods(dispatch, template, template.onViewed);
        }
    }, [dispatch, template]);
    const key = [id, updatedAt].join('-');
    const emptyMessageTemplate = {
        header: emptyTemplateTitle,
        message: emptyTemplateText,
        image: emptyTemplateImage,
        buttonText: emptyTemplateButtonText,
        buttonPrimaryLink: emptyTemplateButtonPrimaryLink,
    };
    const headerButton = useObserver(button);
    const onButtonClick = headerButton ? () => handleSelected(headerButton.onItemSelected) : null;
    // In the container app, the navbar is a separate component, so we don't need extra spacing.
    const menuViewClass = [];
    if (window.amznMusic.isInContainerApp) {
        menuViewClass.push(menuStyles.menuViewContentContainerApp);
    }
    else {
        menuViewClass.push(menuStyles.menuViewContent);
    }
    if (shouldHideSubNav) {
        menuViewClass.push(menuStyles.noSubNav);
    }
    return (React.createElement("div", { className: menuViewClass.join(' ') },
        refinementHeader && (React.createElement(RefinementHeader, { text: refinementHeader.text, refinementOptions: refinementHeader.refinementOptions, isActive: refinementHeader.isActive, isDisabled: refinementHeader.isDisabled, isOpened: refinementHeader.isOpened, useFilter: refinementHeader.useFilter })),
        (header || headerButton) && (React.createElement("div", { className: !refinementHeader
                ? [menuStyles.topMargin, menuStyles.headers].join(' ')
                : menuStyles.headers },
            header && React.createElement("div", { className: "music-headline-2" }, header),
            headerButton && (React.createElement("div", { className: menuStyles.buttons }, (button === null || button === void 0 ? void 0 : button.hintIcon) ? (React.createElement("music-hinted-button", { primaryIcon: headerButton.icon, hintIcon: button === null || button === void 0 ? void 0 : button.hintIcon, hintVariant: "solid-white", onmusicHintedButtonActivate: onButtonClick })) : (React.createElement("music-button", { "icon-name": headerButton.icon, "icon-only": headerButton.iconOnly, variant: headerButton.iconOnly ? 'glass' : 'solid', size: "medium", onmusicActivate: onButtonClick }, headerButton.text)))))),
        upsellButtonElement && (React.createElement(UpsellButton, { data: upsellButtonElement, handleSelected: handleSelected })),
        widgets.length > 0 ||
            emptyTemplateTitle ||
            emptyTemplateText ||
            emptyTemplateImage ||
            (emptyTemplateButtonText && emptyTemplateButtonPrimaryLink) ? (React.createElement("music-container", { id: id, key: key, onrendered: widgetsRendered },
            widgets.map((widget) => widget.interface === PILL_NAVIGATOR_WIDGET && (React.createElement(Widget, { data: widget, handleSelected: handleSelected }))),
            isExploreButtonAndEmptyPromptNeeded && (React.createElement("div", { className: menuStyles.messageContainer },
                React.createElement(Message, { template: emptyMessageTemplate }))),
            widgets
                .filter((widget) => widget.interface !== PILL_NAVIGATOR_WIDGET)
                .find((widget) => { var _a; return ((_a = widget.items) === null || _a === void 0 ? void 0 : _a.length) > 0; }) ? (widgets
                .filter((widget) => widget.interface !== PILL_NAVIGATOR_WIDGET)
                .map((widget) => (React.createElement(Widget, { data: widget, handleSelected: handleSelected })))) : (React.createElement("div", { className: menuStyles.messageContainer },
                React.createElement(Message, { template: emptyMessageTemplate }))))) : (React.createElement(LoadingSpinner, null))));
}
