import * as qrcode from 'qrcode';
import * as Jimp from 'jimp';
import { AMAZON_MUSIC_DESKTOP_IMAGE_LOGO } from '../constants/retailPlayerConstants';
export const generateQRLogo = async (url) => {
    try {
        const qrCodeDataUrl = await qrcode.toDataURL(url, {
            errorCorrectionLevel: 'H',
        });
        const qrCodeImage = await Jimp.read(qrCodeDataUrl);
        const logoImage = await Jimp.read(AMAZON_MUSIC_DESKTOP_IMAGE_LOGO);
        const logoSize = qrCodeImage.bitmap.width / 3;
        logoImage.resize(logoSize, logoSize);
        const xPos = (qrCodeImage.bitmap.width - logoSize) / 2;
        const yPos = (qrCodeImage.bitmap.height - logoSize) / 2;
        qrCodeImage.composite(logoImage, xPos, yPos);
        const finalImageUrl = await qrCodeImage.getBase64Async(Jimp.MIME_PNG);
        return finalImageUrl;
    }
    catch (error) {
        console.error(`Error generating QR code with logo: ${error}`);
    }
    return null;
};
