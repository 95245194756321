import { CloseContextMenuEvent, OpenContextMenuEvent, ShowAppDownloadDialogEvent, subscribe, } from '@amzn/DMWebEventBus';
import { CLOSE_CONTEXT_MENU, OPEN_CONTEXT_MENU } from '../actions/ContextMenu';
import { getSkyfireSkillUrl, invokeHttp } from './utils';
export const handleOpenContextMenuEvent = (store) => (payload) => {
    store.dispatch({
        type: OPEN_CONTEXT_MENU,
        payload,
    });
};
const handleCloseContextMenuEvent = (store) => () => {
    store.dispatch({ type: CLOSE_CONTEXT_MENU });
};
export const handleShowAppDownloadDialogEvent = (store) => (payload) => {
    const { deeplink, forVideoStory, artistName } = payload;
    const params = { deeplink };
    if (forVideoStory !== undefined) {
        params.forVideoStory = String(forVideoStory);
    }
    if (artistName) {
        params.artistName = artistName;
    }
    invokeHttp(store, getSkyfireSkillUrl('getAmazonMusicApplication', params));
};
export function registerOverlayModule(store) {
    subscribe(OpenContextMenuEvent, handleOpenContextMenuEvent(store));
    subscribe(CloseContextMenuEvent, handleCloseContextMenuEvent(store));
    subscribe(ShowAppDownloadDialogEvent, handleShowAppDownloadDialogEvent(store));
}
