exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1FKSff9tvkXSBW6WEr0SZH,.DIgeXiRszL1WXawd7alpt{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._1FKSff9tvkXSBW6WEr0SZH,.DIgeXiRszL1WXawd7alpt{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._1FKSff9tvkXSBW6WEr0SZH,.DIgeXiRszL1WXawd7alpt{padding-inline-start:52px;padding-inline-end:52px}}._1Z6aK7f-9G08rTcPqVAPkg,._1oRndmJwM_-4yOqpjuCd3X{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._1Z6aK7f-9G08rTcPqVAPkg,._1oRndmJwM_-4yOqpjuCd3X{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._1Z6aK7f-9G08rTcPqVAPkg,._1oRndmJwM_-4yOqpjuCd3X{margin-inline-start:52px;margin-inline-end:52px}}.DIgeXiRszL1WXawd7alpt{margin-top:var(--music-spacer-large);margin-bottom:0;cursor:default;-webkit-user-select:none;-ms-user-select:none;user-select:none;display:flex;justify-content:space-between;align-items:center}._3yh14gOTElG9z7hbqH8AJP{width:100%;overflow-x:scroll;scrollbar-width:none;-ms-overflow-style:none}._1oRndmJwM_-4yOqpjuCd3X{display:inline-flex;width:100%}._3yh14gOTElG9z7hbqH8AJP::-webkit-scrollbar{width:0;height:0}._3FqehgZuxpsif4pFhvbmST{appearance:auto}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_1FKSff9tvkXSBW6WEr0SZH",
	"gridSides": "_1FKSff9tvkXSBW6WEr0SZH",
	"header-with-button-classname": "DIgeXiRszL1WXawd7alpt",
	"headerWithButtonClassname": "DIgeXiRszL1WXawd7alpt",
	"grid-sides-margins": "_1Z6aK7f-9G08rTcPqVAPkg",
	"gridSidesMargins": "_1Z6aK7f-9G08rTcPqVAPkg",
	"tab-button-container": "_1oRndmJwM_-4yOqpjuCd3X",
	"tabButtonContainer": "_1oRndmJwM_-4yOqpjuCd3X",
	"outer-tab-button-container": "_3yh14gOTElG9z7hbqH8AJP",
	"outerTabButtonContainer": "_3yh14gOTElG9z7hbqH8AJP",
	"see-more-item": "_3FqehgZuxpsif4pFhvbmST",
	"seeMoreItem": "_3FqehgZuxpsif4pFhvbmST"
};