import ISkyfireSeeMoreItem from './items/ISkyfireSeeMoreItem';
import ISkyfireMethod from '../../ISkyfireMethod';
import ISkyfireEpisodeRowItem from './items/ISkyfireEpisodeRowItem';

export const EPISODE_DESCRIPTIVE_SHOVELER =
    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.EpisodeDescriptiveShovelerElement';

export default interface IEpisodeDescriptiveShoveler {
    interface: typeof EPISODE_DESCRIPTIVE_SHOVELER;
    header: string;
    items: ISkyfireEpisodeRowItem[];
    seeMoreItem: ISkyfireSeeMoreItem;
    onViewed: ISkyfireMethod[];
    uuid?: string;
    onEndOfWidget?: ISkyfireMethod[];
}
