import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import ISkyfireIconButtonElement from 'src/types/templates/widgets/ISkyfireIconButtonElement';
import { Button, ContextMenuButton } from 'src/components';
import IApplicationState from 'src/types/IApplicationState';
import { getDefaultLanguage } from 'src/utils/getDefaultLanguage';
import { PlaybackMode } from 'src/types/Eligibility';
import { BORDERED_BADGE_ELEMENT } from 'src/types/templates/widgets/ISkyfireBorderedBadgeElement';
import { ACCENT_OUTLINE_BADGE_ELEMENT } from 'src/types/templates/widgets/ISkyfireAccentOutlineBadgeElement';
import ISkyfireMethod from '../../../types/ISkyfireMethod';
import ISkyfireTemplate from '../../../types/templates/ISkyfireTemplate';
import ISkyfireEpisodeRowItem from '../../../types/templates/widgets/items/ISkyfireEpisodeRowItem';
import { bindHandler, preventDefault } from '../../../utils';
import { useObserver } from '../../../utils/ObserverHooks';
import { INVOKE_HTTP_POST } from '../../../types/ISkyfireInvokeHttpPostMethod';
import { PLAYBACK_TOGGLE } from '../../../actions/Playback';
import { UPDATE_PLAY_BUTTON } from '../../../actions/LocalHydration';
import {
    CURRENTLY_PLAYING_GROUP,
    PLAYBACK_STATE,
} from '../../../middlewares/LocalHydrationMiddleware';
import localization from '../../../resources/localization.json';
import {
    BookmarkDaoSessionStorage,
    BookMarkOperationDaoSessionStorage,
    BookmarkSyncData,
} from '../../../Bookmark';
import {
    CompletedDaoSessionStorage,
    CompletedOperationDaoSessionStorage,
    CompletedSyncData,
} from '../../../Completed';

export const SHIFT_CLICK = 'SHIFT_CLICK';

export const PLAYING = 'PLAYING';

interface IEpisodeRowItemProps {
    data: ISkyfireEpisodeRowItem;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    handleReorder?: (methods: ISkyfireMethod[]) => void;
    style?: React.CSSProperties;
    loading?: boolean;
    index?: number;
    disableSecondaryTextInline?: boolean;
    disableSelection?: boolean;
    template?: ISkyfireTemplate;
    isDescriptiveShoveler?: boolean;
    maxSize?: EpisodeRowItemSize;
}

function EpisodeRowItem(props: IEpisodeRowItemProps, ref) {
    const {
        button,
        contextMenu,
        description,
        iconButton,
        image,
        imageAltText,
        label,
        imageDimension,
        primaryLink,
        primaryText,
        secondaryLink,
        secondaryText,
        bookmarkElement,
        completedElement,
        descriptionButton,
        descriptionBadge,
        imageBadge,
        playbackMode,
        showPlayButton,
    } = props.data;
    let template = useSelector(
        (state: IApplicationState) => state.TemplateStack.currentTemplate
    );
    template = template || props.template;

    const removeEmbeddedHtml = /(<[^>]+>)|(&nbsp;)/gi;

    const store = useStore();

    const { marketplaceId, customerId } = useSelector(
        (state: IApplicationState) => state.Authentication
    );

    const language = getDefaultLanguage(marketplaceId, useStore());

    const { CURRENTLY_PLAYING_EPISODE } = useSelector(
        (state: IApplicationState) =>
            state.Storage[CURRENTLY_PLAYING_GROUP] || {}
    );

    const [currentPlaying, setCurrentPlaying] = useState('');

    const [progressMilliseconds, setProgressMilliseconds] = useState(
        progressInMillisecondsBookmark(store, bookmarkElement?.id)
    );

    const [totalDurationMilliseconds, setTotalDurationMilliseconds] = useState(
        bookmarkElement?.totalDurationMilliseconds
    );

    const [durationText, setDurationText] = useState('');

    const onEnterPress = (event) => {
        if (event.key === 'Enter') {
            props.handleSelected(primaryLink.onItemSelected);
        }
    };

    const iconButtonElement = useObserver(
        iconButton
    ) as ISkyfireIconButtonElement;

    const dispatch = useDispatch();

    const { playButton } = useSelector(
        (state: IApplicationState) => state.LocalHydrationState
    );

    const { play } = useSelector(
        (state: IApplicationState) => state.PlaybackStates
    );

    const { mediaId } = useSelector((state: IApplicationState) => state.Media);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    useEffect(() => {
        dispatch({
            type: UPDATE_PLAY_BUTTON,
            payload: {
                playButton: iconButton,
            },
        });
    }, [iconButton, dispatch]);

    const playbackToggle = useCallback(() => {
        dispatch({ type: PLAYBACK_TOGGLE, payload: { mediaId } });
    }, [mediaId, dispatch]);

    const data = playButton ?? iconButton;
    const onPlaySelectedMethod = data.onItemSelected.find(
        (method) => method.queue.id === 'PLAYBACK'
    );

    let iconName = data.icon;
    let isPlaybackToggle = false;

    if (
        onPlaySelectedMethod &&
        onPlaySelectedMethod.interface === INVOKE_HTTP_POST
    ) {
        const onPlayPreset = JSON.parse(onPlaySelectedMethod.preset);
        const currentlyPlayingEpisode =
            CURRENTLY_PLAYING_EPISODE || currentPlaying;
        isPlaybackToggle =
            currentlyPlayingEpisode ===
            (onPlayPreset.episodeId || onPlayPreset.startAtEpisodeId);
        if (isPlaybackToggle) {
            if (play && play.state === PLAYING) {
                iconName = 'pause';
                updateDuration();
            } else {
                iconName = data.icon;
            }
        } else {
            iconName = data.icon;
        }
    }

    useEffect(() => {
        let player;
        async function progress() {
            player = await window.maestro.getInstance();
            player.addEventListener('timeupdate', onTimeupdate);
        }
        setProgressMilliseconds(
            progressInMillisecondsBookmark(store, bookmarkElement?.id)
        );
        setTotalDurationMilliseconds(
            bookmarkElement?.totalDurationMilliseconds
        );
        const inProgress =
            progressMilliseconds > 0 &&
            Math.floor(totalDurationMilliseconds - progressMilliseconds) > 0;
        setDurationText(
            durationLeft(
                progressMilliseconds,
                totalDurationMilliseconds,
                language,
                inProgress
            )
        );
        setCurrentPlaying(setUpdatedCurrentPlaying(store.getState()));

        function onTimeupdate(time: number) {
            if (
                iconButtonElement?.icon === 'pause' &&
                player?.getDuration() > 0
            ) {
                setProgressMilliseconds(Math.floor(time * 1000));
                setDurationText(
                    durationLeft(
                        time * 1000,
                        player.getDuration() * 1000,
                        language,
                        true
                    )
                );
            }
        }
        progress();
        return () => {
            player?.removeEventListener('timeupdate', onTimeupdate);
        };
    }, [iconButtonElement?.icon]);

    async function updateDuration() {
        const player = await window.maestro.getInstance();
        setTotalDurationMilliseconds(player?.getDuration() * 1000);
    }

    const bindHandlerHelper = (fn: any) =>
        bindHandler(props.handleSelected, null, fn);

    function parseHtml(text: string) {
        const parsedText =
            text == null ? '' : text.replace(removeEmbeddedHtml, '');
        return parsedText;
    }

    if (props?.loading) {
        return <music-episode-row-item loading={true} />;
    }
    const key = primaryText + secondaryText + description + props.index;

    const { isAnonymousFreeTierDialogEnabled } = window.amznMusic.appConfig;
    const viewPlayButton = isAnonymousFreeTierDialogEnabled
        ? showPlayButton
        : playbackMode !== PlaybackMode.NONE;
    return (
        <music-episode-row-item
            ref={ref}
            key={key}
            data-key={key}
            style={props.style}
            onKeyPress={onEnterPress}
            onClick={preventDefault}
            icon-name={iconName}
            icon-hover-name={data.iconHover}
            show-play-button={viewPlayButton}
            onmusicIconActivate={
                isPlaybackToggle
                    ? playbackToggle
                    : bindHandlerHelper(iconButtonElement?.onItemSelected)
            }
            onmusicSelectedChange={bindHandlerHelper(
                primaryLink.onItemSelected
            )}
            primary-text={primaryText.replace(/<\/?[^>]+(>|$)/g, '')}
            primary-href={primaryLink.deeplink}
            onmusicPrimaryTextActivate={bindHandlerHelper(
                primaryLink.onItemSelected
            )}
            secondary-text={secondaryText}
            onmusicSecondaryTextActivate={bindHandlerHelper(
                secondaryLink?.onItemSelected
            )}
            description={parseHtml(description || '')}
            duration={durationText}
            label={label}
            image-src={image}
            image-dimen={imageDimension}
            image-alt={imageAltText}
            totalDurationMilliseconds={totalDurationMilliseconds}
            progressMilliseconds={
                progressMilliseconds > totalDurationMilliseconds
                    ? totalDurationMilliseconds
                    : progressMilliseconds
            }
            isCompleted={
                iconName === 'pause'
                    ? false
                    : isCompleted(store, completedElement?.id)
            }
            playbackMode={playbackMode}
            imageBadgeText={imageBadge?.text}
            descriptionBadgeText={descriptionBadge?.text}
            ondescriptionBadgeActivate={bindHandlerHelper(
                descriptionBadge?.onItemSelected
            )}
            enableBadgeClick={!!descriptionBadge?.onItemSelected.length}
            onimageBadgeActivate={bindHandlerHelper(imageBadge?.onItemSelected)}
            isBordered={descriptionBadge?.interface === BORDERED_BADGE_ELEMENT}
            isAccentOutline={
                imageBadge?.interface === ACCENT_OUTLINE_BADGE_ELEMENT
            }
            isDescriptiveShoveler={props.isDescriptiveShoveler}
            imageBadgeFontSize={getImageBadgeFontSize(
                context,
                imageBadge?.text
            )}
            maxSize={
                episodeRowItemSizeProperties[
                    props.maxSize || EpisodeRowItemSize.Small
                ]
            }
        >
            {button && (
                <Button
                    data={button}
                    handleSelected={props.handleSelected}
                    slot='buttons'
                    size='small'
                />
            )}
            {viewPlayButton && descriptionButton && (
                <Button
                    data={descriptionButton}
                    handleSelected={props.handleSelected}
                    slot='buttons'
                    size='small'
                />
            )}
            {!viewPlayButton && descriptionButton && (
                <div className='upsell-button' slot='descriptionButton'>
                    <Button
                        data={{ ...descriptionButton, iconOnly: false }}
                        size='small'
                        variant='outline-upgrade'
                        handleSelected={props.handleSelected}
                        icon-only={false}
                        isTextSentenceCase={true}
                    />
                </div>
            )}
            {contextMenu && (
                <ContextMenuButton
                    options={contextMenu.options}
                    disabled={contextMenu.disabled}
                    slot='contextMenu'
                    variant='primary'
                    iconName='more'
                    size='small'
                />
            )}
        </music-episode-row-item>
    );
}

function progressInMillisecondsBookmark(store, id: string): number {
    if (id) {
        const bookmarkSyncData = new BookmarkSyncData(
            new BookmarkDaoSessionStorage(store),
            new BookMarkOperationDaoSessionStorage(store),
            store
        );
        const bookmark = bookmarkSyncData.readWithBounds(id);
        if (bookmark && bookmark.progressMilliSeconds > 0) {
            return bookmark.progressMilliSeconds;
        }
    }
    return 0;
}

function setUpdatedCurrentPlaying(state): string {
    if (
        state.Storage[PLAYBACK_STATE] &&
        Object.keys(state.Storage[PLAYBACK_STATE]).length > 0
    ) {
        for (const [episodeId, playbackState] of Object.entries(
            state.Storage[PLAYBACK_STATE]
        )) {
            if (playbackState === PLAYING) {
                return episodeId;
            }
        }
    }
    return '';
}

function isCompleted(store, id: string): boolean {
    if (id) {
        const completedSyncData = new CompletedSyncData(
            new CompletedDaoSessionStorage(store),
            new CompletedOperationDaoSessionStorage(store),
            store
        );
        const completed = completedSyncData.read(id);
        if (completed && completed.isCompleted) {
            return true;
        }
    }
    return false;
}

function durationLeft(
    progress: number,
    totalDuration: number,
    locale: string = 'en_US',
    inProgress: boolean
) {
    let duration;
    if (Math.floor(totalDuration - progress) > 0) {
        duration = totalDuration - progress;
    } else {
        duration = totalDuration;
    }
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor(duration / (1000 * 60 * 60));

    let textDuration;
    if (hours > 0 && minutes > 0) {
        textDuration = `${hours} hr ${minutes} min`;
    } else if (hours > 0) {
        textDuration = `${hours} hr`;
    } else if (minutes > 0) {
        textDuration = `${minutes} min`;
    } else {
        textDuration = `${seconds} seconds`;
    }
    if (inProgress) {
        textDuration = localization[locale].dmp_time_left.replace(
            /%s/g,
            textDuration
        );
    }
    return textDuration;
}

// Method To Find Dynamic Image Badge Font Size
function getImageBadgeFontSize(
    context: any,
    imageBadgeText: string | undefined
) {
    // Default Font Size
    const fontSizeUpper: number = 12;
    const fontSizeLower: number = 8;
    const maxBadgeTextWidth: number = 50;

    if (!imageBadgeText) {
        return fontSizeUpper;
    }
    // Creates Array of Words of Image Badge Text
    const words = imageBadgeText.split(' ');

    let maxFontSize = fontSizeUpper;

    // Iterates All Font Size Options
    for (let i = fontSizeUpper; i > fontSizeLower; i--) {
        // Iterates Through Every Word
        for (const word of words) {
            context.font = `${i}px Ember Helvetica Arial sans-serif`;
            const wordWidth = context.measureText(word.toUpperCase()).width;
            // Reduces Font Size if Word is Longer Then Break Point
            if (wordWidth > maxBadgeTextWidth) {
                maxFontSize--;
                break;
            }
        }
        // Return Value if Words Don't Overflowß
        if (maxFontSize === i) {
            break;
        }
    }
    return maxFontSize;
}

// eslint-disable-next-line no-shadow
export enum EpisodeRowItemSize {
    Small = 0,
    Medium = 1,
    Large = 2,
}

const episodeRowItemSizeProperties: { [key in EpisodeRowItemSize]: string } = {
    [EpisodeRowItemSize.Small]: 'small',
    [EpisodeRowItemSize.Medium]: 'medium',
    [EpisodeRowItemSize.Large]: 'large',
};

/**
 * Calculates vertical item size based on internal content.
 * @param item vertical item
 */
export function getItemSize(item: ISkyfireEpisodeRowItem): EpisodeRowItemSize {
    if (item.descriptionBadge) {
        return EpisodeRowItemSize.Medium;
    }
    return EpisodeRowItemSize.Small;
}

export default forwardRef(EpisodeRowItem);
