import React from 'react';
import IEpisodeDescriptiveShoveler from 'src/types/templates/widgets/IEpisodeDescriptiveShoveler';
import { useSelector } from 'react-redux';
import IApplicationState from 'src/types/IApplicationState';
import { useItemsInView } from '../../utils/useItemsInView';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import EpisodeRowItem, {
    EpisodeRowItemSize,
    getItemSize,
} from './items/EpisodeRowItem';

interface IEpisodeDescriptiveShovelerProps {
    data: IEpisodeDescriptiveShoveler;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    onViewed?: () => void;
}

export function EpisodeDescriptiveShoveler(
    props: IEpisodeDescriptiveShovelerProps
) {
    const { header, items, seeMoreItem } = props.data;
    const callbackRef = useItemsInView(props.onViewed);
    const { windowWidth } = useSelector(
        (state: IApplicationState) => state.BrowserState
    );
    if (items?.length) {
        const sizes = items.map((item) => getItemSize(item));
        const maxSize = sizes.reduce(
            (acc, size) => (size > acc ? size : acc),
            EpisodeRowItemSize.Small
        );
        return (
            <div>
                <music-shoveler
                    key={header}
                    see-all={seeMoreItem?.text}
                    primary-text={header}
                    wrap='1'
                    onmusicSeeAllActivate={props.handleSelected.bind(
                        null,
                        seeMoreItem?.primaryLink?.onItemSelected
                    )}
                    wrapSize='large'
                >
                    {items.map((item, i) => (
                        <EpisodeRowItem
                            ref={callbackRef}
                            key={i}
                            data={item}
                            handleSelected={props.handleSelected}
                            isDescriptiveShoveler={true}
                            maxSize={maxSize}
                        />
                    ))}
                </music-shoveler>
            </div>
        );
    }

    return null;
}
