import { v4 } from 'uuid';

export class SaveOperation {
    public operationId: string;

    public episodeId: string;

    public podcastId: string;

    public podcastTitle: string;

    public title: string;

    public image: string;

    public podcastImage: string;

    public description: string;

    public authors: string;

    public seasonNumber: number;

    public totalDurationMilliseconds: number;

    public isSaved: boolean;

    public updatedTime: number;

    public isProcessing: boolean;

    public publishTime: string;

    constructor(operation: {
        operationId?;
        episodeId?;
        podcastId?;
        podcastTitle?;
        title?;
        image?;
        podcastImage?;
        description?;
        authors?;
        seasonNumber?;
        totalDurationMilliseconds?;
        isSaved?;
        updatedTime?;
        isProcessing?;
        publishTime;
    }) {
        this.operationId = operation.operationId ?? v4();
        this.episodeId = operation.episodeId;
        this.podcastId = operation.podcastId;
        this.podcastTitle = operation.podcastTitle;
        this.title = operation.title;
        this.image = operation.image;
        this.podcastImage = operation.podcastImage;
        this.description = operation.description;
        this.authors = operation.authors;
        this.seasonNumber = operation.seasonNumber;
        this.totalDurationMilliseconds = operation.totalDurationMilliseconds;
        this.isSaved = operation.isSaved;
        this.updatedTime = operation.updatedTime;
        this.isProcessing = operation.isProcessing;
        this.publishTime = operation.publishTime;
    }
}
