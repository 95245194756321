import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IEpisodeDescriptiveShoveler, {
    EPISODE_DESCRIPTIVE_SHOVELER,
} from 'src/types/templates/widgets/IEpisodeDescriptiveShoveler';
import { dispatchSkyfireMethods } from '../../utils';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import { VISUAL_TABLE_WIDGET } from '../../types/templates/widgets/ISkyfireVisualTableWidget';
import SkyfireWidgetElement from '../../types/templates/widgets/ISkyfireWidgetElement';
import VisualTableWidget from './VisualTableWidget';
import { PODCAST_EPISODE_TABLE_WIDGET } from '../../types/templates/widgets/IPodcastEpisodeTableWidget';
import { EPISODE_GROUP_ITEMS_WIDGET } from '../../types/templates/widgets/IEpisodeGroupItemElements';
import { PodcastEpisodeTableWidget } from './PodcastEpisodeTableWidget';
import { EpisodeGroupItemsWidget } from './EpisodeGroupItemsWidget';
import IApplicationState from '../../types/IApplicationState';
import ISkyfireVisualShoveler, {
    VISUAL_SHOVELER,
} from '../../types/templates/widgets/ISkyfireVisualShoveler';
import VisualShoveler from './VisualShoveler';
import { EpisodeDescriptiveShoveler } from './EpisodeDescriptiveShoveler';

interface IWidgetProps {
    data: SkyfireWidgetElement;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    isEnumerated?: boolean;
    rendered?: () => void;
}

export default function Widget(props: IWidgetProps) {
    const dispatch = useDispatch();
    const currTemplate = useSelector(
        (state: IApplicationState) => state.TemplateStack.currentTemplate
    );

    const getWidget = () => {
        switch (props.data.interface) {
            case EPISODE_GROUP_ITEMS_WIDGET:
                return (
                    <EpisodeGroupItemsWidget
                        data={props.data}
                        handleSelected={props.handleSelected}
                        onViewed={onViewed}
                    />
                );
            case PODCAST_EPISODE_TABLE_WIDGET:
                return (
                    <PodcastEpisodeTableWidget
                        data={props.data}
                        handleSelected={props.handleSelected}
                        onViewed={onViewed}
                    />
                );
            case VISUAL_TABLE_WIDGET:
                return (
                    <VisualTableWidget
                        data={props.data}
                        handleSelected={props.handleSelected}
                        onViewed={onViewed}
                    />
                );
            case VISUAL_SHOVELER:
                return (
                    <VisualShoveler
                        data={props.data as ISkyfireVisualShoveler}
                        handleSelected={props.handleSelected}
                        onViewed={onViewed}
                    />
                );
            case EPISODE_DESCRIPTIVE_SHOVELER:
                return (
                    <EpisodeDescriptiveShoveler
                        data={props.data as IEpisodeDescriptiveShoveler}
                        handleSelected={props.handleSelected}
                        onViewed={onViewed}
                    />
                );
            default:
                // @ts-ignore
                return (
                    <p>
                        Attempted to render unknown widget:{' '}
                        {props.data.interface}
                    </p>
                );
        }
    };

    const onViewed = useCallback(() => {
        const onViewedMethods = (props.data as SkyfireWidgetElement).onViewed;
        if (currTemplate && onViewedMethods?.length > 0) {
            dispatchSkyfireMethods(dispatch, currTemplate, onViewedMethods);
        }
    }, [currTemplate.innerTemplate.widgets]);

    const onrendered = () => {
        if (props.rendered) {
            props.rendered();
        }
    };

    return (
        <music-container onrendered={onrendered}>{getWidget()}</music-container>
    );
}
