import { shallowEqual, useSelector } from 'react-redux';
export function useObserver(element) {
    const observer = element === null || element === void 0 ? void 0 : element.observer;
    return useSelector((state) => {
        var _a, _b, _c, _d;
        if (!observer)
            return element;
        const { Storage } = state;
        const storedState = (_a = Storage[observer.storageGroup]) === null || _a === void 0 ? void 0 : _a[observer.storageKey];
        const currentState = storedState !== null && storedState !== void 0 ? storedState : observer.defaultState;
        return (_d = (_c = (_b = observer === null || observer === void 0 ? void 0 : observer.states) === null || _b === void 0 ? void 0 : _b[currentState]) !== null && _c !== void 0 ? _c : currentState) !== null && _d !== void 0 ? _d : observer.defaultValue;
    }, shallowEqual);
}
export function useObservers(elements) {
    const observers = elements.map((element) => element === null || element === void 0 ? void 0 : element.observer);
    const filteredObservers = observers.filter((value) => value !== undefined);
    return useSelector((state) => {
        if (filteredObservers.length === 0)
            return elements;
        const { Storage } = state;
        return observers.map((observer, idx) => {
            var _a, _b, _c, _d;
            if (!observer)
                return elements[idx];
            const storedState = (_a = Storage[observer.storageGroup]) === null || _a === void 0 ? void 0 : _a[observer.storageKey];
            const currentState = storedState !== null && storedState !== void 0 ? storedState : observer.defaultState;
            return (_d = (_c = (_b = observer === null || observer === void 0 ? void 0 : observer.states) === null || _b === void 0 ? void 0 : _b[currentState]) !== null && _c !== void 0 ? _c : currentState) !== null && _d !== void 0 ? _d : observer.defaultValue;
        });
    }, shallowEqual);
}
