import { Dispatch } from 'redux';
// import { enqueueSkyfireMethod } from '../actions';
import ISkyfireMethod from '../types/ISkyfireMethod';
import ISkyfireQueue from '../types/ISkyfireQueue';

export const ENQUEUE_SKYFIRE_METHOD = 'ENQUEUE_SKYFIRE_METHOD';

export function dispatchPlaybackMethods(
    dispatch: Dispatch,
    mediaId: string,
    methods: ISkyfireMethod[] = []
): void {
    methods.forEach((method) => {
        dispatch(
            enqueueSkyfireMethod({
                queue: method.queue,
                method: { ...method, owner: mediaId },
            })
        );
    });
}

export function enqueueSkyfireMethod(payload: {
    queue: ISkyfireQueue;
    method: ISkyfireMethod;
}): IEnqueueSkyfireMethodAction {
    return {
        payload,
        type: ENQUEUE_SKYFIRE_METHOD,
    };
}

export interface IEnqueueSkyfireMethodAction {
    type: typeof ENQUEUE_SKYFIRE_METHOD;
    payload: {
        queue: ISkyfireQueue;
        method: ISkyfireMethod;
    };
}
