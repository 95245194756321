import React, { Fragment, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useDispatch, useSelector } from 'react-redux';
import TabsItemsGroupWidget from './widgets/TabsItemsGroupWidget';
import { setActiveTab } from '../actions';
import * as tabStyles from './widgets/TabsItemsGroupWidget.scss';
import { isRWPDesktop } from '../utils/retailPlayerHelper';
import { WINDOW_SIZE_ENUM } from '../types/IWindowSize';
export const ScrollSpy = (props) => {
    useStyles(tabStyles);
    const { children, tabs, handleSelected, tabsAlreadyInsertedWithinChildren } = props;
    const windowWidth = useSelector((state) => state.BrowserState.windowWidth);
    const dispatch = useDispatch();
    /*
        To update the selected menu on the Scroll Spy component.
     */
    useEffect(() => {
        const tabList = tabs === null || tabs === void 0 ? void 0 : tabs.items.map((item) => item.tab);
        const sections = tabList === null || tabList === void 0 ? void 0 : tabList.map((tab) => document.getElementById(tab.id));
        const observer = new IntersectionObserver((entries) => {
            let firstIntersectingElementIndex = Number.MAX_SAFE_INTEGER;
            entries.forEach((entry) => {
                const currentIndex = sections.indexOf(entry.target);
                const previousIndex = currentIndex - 1;
                if (entry.isIntersecting) {
                    firstIntersectingElementIndex = Math.min(firstIntersectingElementIndex, currentIndex);
                }
                else if (previousIndex >= 0 && tabList) {
                    firstIntersectingElementIndex = Math.min(firstIntersectingElementIndex, previousIndex);
                }
            });
            if (firstIntersectingElementIndex !== Number.MAX_SAFE_INTEGER) {
                dispatch(setActiveTab(tabList[firstIntersectingElementIndex].id));
            }
        }, {
            threshold: calculateThreshold(),
        });
        if (tabList) {
            tabList.forEach((tab, index) => {
                const associatedSection = sections[index];
                if (associatedSection) {
                    observer.observe(associatedSection);
                }
            });
        }
        return () => {
            if (tabList) {
                tabList.forEach((tab, index) => {
                    const associatedSection = sections[index];
                    if (associatedSection) {
                        observer.unobserve(associatedSection);
                    }
                });
            }
        };
    }, []);
    /*
        To track the pinned/unpinned state of the scrollSpy component.
     */
    useEffect(() => {
        const tabNavBar = document.getElementById('tabsItemsGroupWidgetNavBar');
        const navBar = document.getElementById('music-navbar');
        const handleIntersection = ([entry]) => {
            const multiSelectBar = document.getElementById('multiSelectBar');
            if (entry.intersectionRatio === 0) {
                tabNavBar === null || tabNavBar === void 0 ? void 0 : tabNavBar.classList.add(tabStyles.tabNavBarPinned);
                if (isRWPDesktop()) {
                    tabNavBar === null || tabNavBar === void 0 ? void 0 : tabNavBar.classList.add(tabStyles.bottomBorderRadius);
                    if (multiSelectBar) {
                        multiSelectBar.classList.add(tabStyles.zeroBottomBorderRadius);
                    }
                    else {
                        navBar === null || navBar === void 0 ? void 0 : navBar.classList.add(tabStyles.zeroBottomBorderRadius);
                    }
                }
            }
            else {
                tabNavBar === null || tabNavBar === void 0 ? void 0 : tabNavBar.classList.remove(tabStyles.tabNavBarPinned);
                if (isRWPDesktop()) {
                    tabNavBar === null || tabNavBar === void 0 ? void 0 : tabNavBar.classList.remove(tabStyles.bottomBorderRadius);
                    if (multiSelectBar) {
                        multiSelectBar.classList.remove(tabStyles.zeroBottomBorderRadius);
                    }
                    else {
                        navBar === null || navBar === void 0 ? void 0 : navBar.classList.remove(tabStyles.zeroBottomBorderRadius);
                    }
                }
            }
        };
        const options = { threshold: [0, 1], rootMargin: `-${calculateRootMargin()}px` };
        const observer = new IntersectionObserver(handleIntersection, options);
        /*
            The tabNawBar is sticky hence it will always remain in the viewport.
            Because of this we're tracking it's previous sibling and when that element
            is out of view we can conclude that tabNavBar has now been pinned at top of viewport.
         */
        const previousElementSibling = tabNavBar === null || tabNavBar === void 0 ? void 0 : tabNavBar.previousElementSibling;
        if (previousElementSibling) {
            observer.observe(previousElementSibling);
        }
        return () => {
            if (previousElementSibling) {
                observer.unobserve(previousElementSibling);
            }
        };
    }, []);
    const calculateRootMargin = () => {
        let rootMargin = 40;
        // Add 8 to rootMargin if it's a retail desktop view
        rootMargin += isRWPDesktop() ? 8 : 0;
        // Check for the existence of multiSelectBar once
        const multiSelectBar = document.getElementById('multiSelectBar');
        if (multiSelectBar) {
            // If multiSelectBar exists, add its height to rootMargin
            rootMargin += multiSelectBar.getBoundingClientRect().height;
        }
        return rootMargin;
    };
    /**
     * Method to determine threshold at which we consider a section intersecting
     * with the viewport based on viewPort's width of the device.
     */
    const calculateThreshold = () => {
        let threshold = 0.15;
        if (windowWidth >= WINDOW_SIZE_ENUM.XL2) {
            threshold = 0.3;
        }
        else if (windowWidth >= WINDOW_SIZE_ENUM.XL) {
            threshold = 0.25;
        }
        else if (windowWidth >= WINDOW_SIZE_ENUM.LG) {
            threshold = 0.2;
        }
        return [threshold];
    };
    return (React.createElement(Fragment, null,
        !tabsAlreadyInsertedWithinChildren && (React.createElement(TabsItemsGroupWidget, { data: tabs, handleSelected: handleSelected })),
        children));
};
