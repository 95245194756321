import { OperationElement } from '../appsync/OperationElement';
import { ClientState } from '../types/ClientState';

export class BookmarkOperationsClientState extends ClientState {
    public operations: OperationElement[];

    public __type: string;

    constructor(operations: OperationElement[]) {
        super();
        this.operations = operations;
        this.__type =
            'Podcast.BookmarkInterface.v1_0#BookmarkOperationsClientState';
    }
}
