import { OperationElement } from '../appsync/OperationElement';
import { ClientState } from '../types/ClientState';

export class SaveOperationsClientState extends ClientState {
    public operations: OperationElement[];

    public __type: string;

    constructor(operations: OperationElement[]) {
        super();
        this.operations = operations;
        this.__type = 'Podcast.SaveInterface.v1_0#SaveOperationsClientState';
    }
}
