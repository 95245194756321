import { channelFor, GetDataEvent } from './utils';

const DEVICE_INFO_CHANNEL = channelFor('deviceinfo');

export const GetDeviceIdEvent: GetDataEvent<string> = {
  channel: DEVICE_INFO_CHANNEL,
  topic: 'getDeviceId',
};

export const GetDeviceTypeEvent: GetDataEvent<string> = {
  channel: DEVICE_INFO_CHANNEL,
  topic: 'getDeviceType',
};

export const GetLocaleEvent: GetDataEvent<string> = {
  channel: DEVICE_INFO_CHANNEL,
  topic: 'getLocale',
};

export const GetManufacturerEvent: GetDataEvent<string> = {
  channel: DEVICE_INFO_CHANNEL,
  topic: 'getManufacturer',
};

export const GetModelEvent: GetDataEvent<string> = {
  channel: DEVICE_INFO_CHANNEL,
  topic: 'getModel',
};

export const GetOsVersionEvent: GetDataEvent<string> = {
  channel: DEVICE_INFO_CHANNEL,
  topic: 'getOsVersion',
};

export const GetTimeZoneEvent: GetDataEvent<string> = {
  channel: DEVICE_INFO_CHANNEL,
  topic: 'getTimeZone',
};

export const GetUserAgentEvent: GetDataEvent<string> = {
  channel: DEVICE_INFO_CHANNEL,
  topic: 'getUserAgent',
};
