import React, { Fragment, useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import IApplicationState from 'src/types/IApplicationState';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import ISkyfireMessageTemplate from '../../types/templates/message';
import IPodcastLibraryRecents from '../../types/templates/widgets/IPodcastLibraryRecents';
import { getVisualListingGridSizes } from '../../utils/gridHelpers';
import Message from '../../views/Message';
import InfiniteGrid from './items/InfiniteGrid';
import VerticalItem from './items/VerticalItem';
import menuStyles from '../../views/Menu.scss';
import viewStyles from '../../views/View.scss';
import Styles from './VisualListing.scss';
import {
    ISkyfireInvokeHttpPostMethod,
    INVOKE_HTTP_POST,
} from '../../types/ISkyfireInvokeHttpPostMethod';
import {
    BookmarkDaoSessionStorage,
    BookMarkOperationDaoSessionStorage,
    BookmarkSyncData,
} from '../../Bookmark';
import ISkyfireLinkElement from '../../types/ISkyfireLinkElement';
import { SQUARE_VERTICAL_ITEM } from '../../types/templates/widgets/items/ISkyfireVerticalItem';
import {
    UIMetrics,
    ActionType,
    ContentName,
    PageType,
    EntityType,
    EntityIdType,
} from '../../UIMetrics';
import { getPodcastPlaybackEnvironment } from '../../utils';
import { PLAY_PODCAST_WEB_SKILL } from '../../utils/podcastTargets';
import { SINGLE_THREADED_QUEUE } from '../../utils/skyfireContants';
import { invokeHttpPostSkillMethod } from '../../utils/invokeHttpPostSkillMethod';
import { useItemsInView } from '../../utils/useItemsInView';
import { episodeDeeplink, podcastDeeplink } from '../../utils/deeplinks';
import { PlaybackMode } from '../../types/Eligibility';

interface IPodcastLibraryRecentsProps {
    data: IPodcastLibraryRecents;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    onViewed?: () => void;
}

export function PodcastLibraryRecents(props: IPodcastLibraryRecentsProps) {
    const callbackRef = useItemsInView(props.onViewed);
    const { data, handleSelected } = props;
    const { header, button, fallbackMessage } = data;
    const { windowWidth } = useSelector(
        (state: IApplicationState) => state.BrowserState
    );
    const { customerId } = useSelector(
        (state: IApplicationState) => state.Authentication
    );

    useEffect(() => {
        window?.scrollTo?.(0, 0);
    }, []);
    const store = useStore();
    const bookmarkSyncData = new BookmarkSyncData(
        new BookmarkDaoSessionStorage(store),
        new BookMarkOperationDaoSessionStorage(store),
        store
    );
    const items = bookmarkSyncData
        .readStates()
        .filter((element) => element.playbackMode !== PlaybackMode.NONE)
        .map((bookmarkEntry, index) => {
            const invokePlay: ISkyfireInvokeHttpPostMethod = {
                interface: INVOKE_HTTP_POST,
                queue: {
                    interface: SINGLE_THREADED_QUEUE,
                    id: 'PLAYBACK',
                },
                forced: false,
                before: [],
                after: [],
                onError: [],
                url: getPodcastPlaybackEnvironment(),
                preset: `{"podcastId":"${bookmarkEntry.podcastId}","startAtEpisodeId":"${bookmarkEntry.episodeId}"}`,
                target: PLAY_PODCAST_WEB_SKILL,
                extra: {},
            };
            let onPlaySelected: ISkyfireMethod[] = [invokePlay];
            onPlaySelected = onPlaySelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.PLAY_PODCAST_EPISODE,
                    contentName: ContentName.PODCASTS_RECENTLY_PLAYED,
                    pageType: PageType.PODCASTS_RECENTLY_PLAYED,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            let onEpisodeSelected: ISkyfireMethod[] = [
                // @ts-ignore
                invokeHttpPostSkillMethod(
                    `/podcasts/${bookmarkEntry.podcastId}/episodes/${bookmarkEntry.episodeId}`
                ),
            ];
            onEpisodeSelected = onEpisodeSelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.SELECT_PODCAST_EPISODE,
                    contentName: ContentName.PODCASTS_RECENTLY_PLAYED,
                    pageType: PageType.PODCASTS_RECENTLY_PLAYED,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            let onPodcastSelected: ISkyfireMethod[] = [
                // @ts-ignore
                invokeHttpPostSkillMethod(
                    `/podcasts/${bookmarkEntry.podcastId}`
                ),
            ];
            onPodcastSelected = onPodcastSelected.concat(
                UIMetrics.onClicked({
                    actionType: ActionType.GO_PODCAST_SHOW,
                    contentName: ContentName.PODCASTS_RECENTLY_PLAYED,
                    pageType: PageType.PODCASTS_RECENTLY_PLAYED,
                    entityType: EntityType.PODCAST_EPISODE,
                    entityIdType: EntityIdType.PODCAST_EPISODE_ID,
                    entityId: getEpisodeEntityId(bookmarkEntry),
                    entityPos: index,
                })
            );
            const playLink: ISkyfireLinkElement = {
                deeplink: podcastDeeplink(bookmarkEntry),
                onItemSelected: onPlaySelected,
            };
            const episodeLink: ISkyfireLinkElement = {
                deeplink: episodeDeeplink(bookmarkEntry),
                onItemSelected: onEpisodeSelected,
            };
            const podcastLink: ISkyfireLinkElement = {
                deeplink: podcastDeeplink(bookmarkEntry),
                onItemSelected: onPodcastSelected,
            };
            // @ts-ignore
            const item: ISkyfireSquareVerticalItem = {
                image: bookmarkEntry.podcastImage,
                imageAltText: bookmarkEntry.title,
                primaryLink: episodeLink,
                secondaryText: bookmarkEntry.podcastTitle,
                secondaryLink: podcastLink,
                actionIconName: 'play',
                actionIconLink: playLink,
                primaryText: {
                    text: bookmarkEntry.title,
                    disabled: false,
                },
                interface: SQUARE_VERTICAL_ITEM,
            };
            return item;
        });

    data.items = items;

    const onButtonClick = button
        ? () => handleSelected(button?.primaryLink?.onItemSelected)
        : null;

    const emptyMessageTemplate = {
        message: fallbackMessage,
    };

    return data.items.length > 0 ? (
        <Fragment>
            {header && (
                <div className={Styles.header}>
                    <h1 className='music-headline-4'>{header}</h1>
                </div>
            )}
            {button && (
                <div className={Styles['button-container']}>
                    <music-button
                        icon-name={button.icon}
                        size='medium'
                        onmusicActivate={onButtonClick}
                    >
                        {button.text}
                    </music-button>
                </div>
            )}
            <InfiniteGrid
                data={data}
                handleSelected={props.handleSelected}
                componentType={VerticalItem}
                gridSizes={getVisualListingGridSizes(windowWidth)}
                itemsViewedRef={callbackRef}
            />
        </Fragment>
    ) : (
        <div
            className={
                menuStyles['message-container'] + viewStyles['view-content']
            }
        >
            <Message
                template={emptyMessageTemplate as ISkyfireMessageTemplate}
            />
        </div>
    );
}

function getEpisodeEntityId(element) {
    const {
        isRedhoodPlusMetricsEnabled,
        isRedhoodSonicRushEnabled,
    } = window.amznMusic.appConfig;
    return element.podcastEpisodeVariantId &&
        isRedhoodPlusMetricsEnabled &&
        isRedhoodSonicRushEnabled
        ? element.podcastEpisodeVariantId
        : element.episodeId;
}
