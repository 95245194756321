import ISkyfireMethod from '../types/ISkyfireMethod';
import ISkyfireQueue from '../types/ISkyfireQueue';

export const ENQUEUE_SKYFIRE_METHOD = 'ENQUEUE_SKYFIRE_METHOD';
export interface IEnqueueSkyfireMethodAction {
    type: typeof ENQUEUE_SKYFIRE_METHOD;
    payload: {
        queue: ISkyfireQueue;
        method: ISkyfireMethod;
    };
}
export function enqueueSkyfireMethod(payload: {
    queue: ISkyfireQueue;
    method: ISkyfireMethod;
}): IEnqueueSkyfireMethodAction {
    return {
        payload,
        type: ENQUEUE_SKYFIRE_METHOD,
    };
}

export const EXECUTE_SKYFIRE_QUEUED_METHOD = 'EXECUTE_SKYFIRE_QUEUED_METHOD';
export interface IExecuteSkyfireQueuedMethodAction {
    type: typeof EXECUTE_SKYFIRE_QUEUED_METHOD;
    payload: {
        queue: ISkyfireQueue;
    };
}
export function executeSkyfireQueuedMethod(
    queue: ISkyfireQueue
): IExecuteSkyfireQueuedMethodAction {
    return {
        payload: {
            queue,
        },
        type: EXECUTE_SKYFIRE_QUEUED_METHOD,
    };
}

export const DEQUEUE_SKYFIRE_METHOD = 'DEQUEUE_SKYFIRE_METHOD';
export interface IDequeueSkyfireMethodAction {
    type: typeof DEQUEUE_SKYFIRE_METHOD;
    payload: {
        queue: ISkyfireQueue;
    };
}
export function dequeueSkyfireMethod(
    queue: ISkyfireQueue
): IDequeueSkyfireMethodAction {
    return {
        payload: {
            queue,
        },
        type: DEQUEUE_SKYFIRE_METHOD,
    };
}

export const PARK_SKYFIRE_METHOD = 'PARK_SKYFIRE_METHOD';
export interface IParkSkyfireMethodAction {
    type: typeof PARK_SKYFIRE_METHOD;
    payload: {
        queue: ISkyfireQueue;
        method: ISkyfireMethod;
    };
}
export function parkSkyfireMethod(payload: {
    queue: ISkyfireQueue;
    method: ISkyfireMethod;
}): IParkSkyfireMethodAction {
    return {
        payload,
        type: PARK_SKYFIRE_METHOD,
    };
}

export const UNPARK_SKYFIRE_METHOD = 'UNPARK_SKYFIRE_METHOD';
export interface IUnparkSkyfireMethodAction {
    type: typeof UNPARK_SKYFIRE_METHOD;
    payload: {
        ownerId: string;
    };
}
export function unparkSkyfireMethod(
    ownerId: string
): IUnparkSkyfireMethodAction {
    return {
        payload: {
            ownerId,
        },
        type: UNPARK_SKYFIRE_METHOD,
    };
}

export type ISkyfireMethodQueueAction =
    | IEnqueueSkyfireMethodAction
    | IDequeueSkyfireMethodAction
    | IExecuteSkyfireQueuedMethodAction
    | IParkSkyfireMethodAction
    | IUnparkSkyfireMethodAction;
