import { channelFor, GetDataEvent } from './utils';

const WEBLAB_CHANNEL = channelFor('weblab');

export type GetWeblabTreatmentArgs = {
  key: string;
  shouldTrigger: boolean;
};
export const GetWeblabTreatmentEvent: GetDataEvent<string, GetWeblabTreatmentArgs> = {
  channel: WEBLAB_CHANNEL,
  topic: 'getWeblabTreatment',
};

export type GetWeblabTreatmentsArgs = {
  keys: Array<string>;
  shouldTrigger: boolean;
};
export const GetWeblabTreatmentsEvent: GetDataEvent<Array<string>, GetWeblabTreatmentsArgs> = {
  channel: WEBLAB_CHANNEL,
  topic: 'getWeblabTreatments',
};
