import React, { useCallback } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useDispatch } from 'react-redux';
import * as Styles from './BreadcrumbsWidget.scss';
import { dispatchSkyfireMethods, preventDefault } from '../../utils';
export default function BreadcrumbsWidget(props) {
    var _a;
    const dispatch = useDispatch();
    useStyles(Styles);
    const totalItems = props.data && props.data.items ? (_a = props.data.items) === null || _a === void 0 ? void 0 : _a.length : 0;
    const handleSelected = useCallback((onItemSelected) => {
        if (props.currTemplate) {
            dispatchSkyfireMethods(dispatch, props.currTemplate, onItemSelected);
        }
    }, [dispatch, props.currTemplate]);
    return props.data && Array.isArray(props.data.items) && props.data.items.length > 0 ? (React.createElement("div", { className: Styles.breadCrumbs }, props.data.items.map((item, index) => {
        var _a, _b;
        return (React.createElement("span", { className: Styles.breadcrumbsContainer },
            item.text && item.text.length > 0 ? (React.createElement("music-breadcrumbs", { onClick: preventDefault, label: item.text, href: (_a = item.link) === null || _a === void 0 ? void 0 : _a.deeplink, onmusicBreadcrumbsTextActivate: handleSelected.bind(this, (_b = item.link) === null || _b === void 0 ? void 0 : _b.onItemSelected) })) : null,
            index + 1 < totalItems && item.text && item.text.length > 0 ? (React.createElement("span", { className: Styles.breadcrumbsArrow }, '>')) : null));
    }))) : null;
}
