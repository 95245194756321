import ISkyfireMethod from '../../ISkyfireMethod';
import ISkyfireEpisodeRowItem from './items/ISkyfireEpisodeRowItem';

export const PODCAST_EPISODE_TABLE_WIDGET =
    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.EpisodeTableWidgetElement';

export default interface IPodcastEpisodeTableWidget {
    interface: typeof PODCAST_EPISODE_TABLE_WIDGET;
    items: ISkyfireEpisodeRowItem[];
    onEndOfWidget: ISkyfireMethod[];
    onViewed: ISkyfireMethod[];
    uuid?: string;
}
