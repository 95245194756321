export class Completed {
    public id: string;

    public podcastId: string;

    public podcastTitle: string;

    public podcastImage: string;

    public authors: string;

    public seasonNumber: number;

    public title: string;

    public description: string;

    public image: string;

    public isCompleted: boolean;

    public updatedTime: number;

    public publishTime: string;

    constructor(completed: {
        id?;
        podcastId?;
        podcastTitle?;
        podcastImage?;
        authors?;
        seasonNumber?;
        title?;
        description?;
        image?;
        isCompleted?;
        updatedTime?;
        publishTime?;
    }) {
        this.id = completed.id;
        this.podcastId = completed.podcastId;
        this.podcastTitle = completed.podcastTitle;
        this.podcastImage = completed.podcastImage;
        this.authors = completed.authors;
        this.seasonNumber = completed.seasonNumber;
        this.title = completed.title;
        this.description = completed.description;
        this.image = completed.image;
        this.isCompleted = completed.isCompleted;
        this.updatedTime = completed.updatedTime;
        this.publishTime = completed.publishTime;
    }
}
