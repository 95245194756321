import React from 'react';
import { useDispatch } from 'react-redux';
import * as styles from './SplashScreen.scss';
import { dispatchSkyfireMethods } from '../utils';
const InformationBlock = ({ data }) => (React.createElement("div", { className: styles.infoBlock },
    React.createElement("music-icon", { name: data.icon, slot: "left", size: "small" }),
    React.createElement("div", null,
        React.createElement("h4", { className: "headline-4" }, data.header),
        React.createElement("p", { className: "secondary-test" }, data.text))));
const IntroProfiles = ({ template }) => {
    const dispatch = useDispatch();
    const handleButton = () => dispatchSkyfireMethods(dispatch, template, template.confirmButton.onItemSelected);
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.splashScreen },
            React.createElement("img", { src: template.headerImage, alt: template.headerImageAlt }),
            React.createElement("h3", { className: "headline-3" }, template.title),
            template.infoBlocks.map((item) => (React.createElement(InformationBlock, { data: item, key: item.header }))),
            React.createElement("music-button", { size: "large", onClick: handleButton }, template.confirmButton.text))));
};
export default IntroProfiles;
