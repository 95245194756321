import { useSelector } from 'react-redux';
import { useObserver } from './ObserverHooks';
export function useItemSelectedState(isSelected, selectable) {
    const multiSelectIsPopulated = useSelector((state) => state.Storage.MULTISELECT && Object.keys(state.Storage.MULTISELECT).length > 0);
    const selected = !!useObserver({ observer: isSelected });
    if (isSelected && selectable !== null && multiSelectIsPopulated) {
        return selected ? 'on' : 'off';
    }
    return undefined;
}
export function useSelectedRowCount() {
    return useSelector((state) => { var _a; return Object.keys((_a = state.Storage.MULTISELECT) !== null && _a !== void 0 ? _a : {}).filter((k) => !k.startsWith('_')).length; });
}
