import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IApplicationState from 'src/types/IApplicationState';
import ISkyfireRowItemButton from '../../../types/templates/widgets/items/ISkyfireRowItemButtonElement';
import { bindHandler, dispatchSkyfireMethods } from '../../../utils';

interface IRowItemButtonProps {
    button: ISkyfireRowItemButton;
    slot?: string;
}

export default function RowItemButton(props: IRowItemButtonProps) {
    const { button, slot } = props;
    const template = useSelector(
        (state: IApplicationState) => state.TemplateStack.currentTemplate
    );
    const dispatch = useDispatch();
    const debouncedClickHandler = bindHandler(() => {
        if (template) {
            dispatchSkyfireMethods(
                dispatch,
                template,
                button.primaryLink.onItemSelected
            );
        }
    }, null);

    const onClick = useCallback(
        (event: MouseEvent) => {
            event?.preventDefault();
            event?.stopPropagation();
            debouncedClickHandler();
        },
        [dispatch]
    );

    return (
        <music-button
            slot={slot}
            disabled={button.isDisabled}
            icon-name={button.icon}
            onClick={onClick}
            size='small'
            variant='primary'
            icon-only={true}
            title={button.text}
        >
            {button.text}
        </music-button>
    );
}
