import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DetailHeader from '../components/DetailHeader';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { CLEAR_SAMPLE } from '../actions';
import TextWithLink from '../components/widgets/items/TextWithLink';
import WidgetList from '../components/widgets/WidgetList';
import { dispatchSkyfireMethods, dispatchTemplateRendered, rewriteDeeplink, globals, } from '../utils';
import TemplateDialog from '../components/TemplateDialog';
import * as Styles from './DescriptiveRowDialog.scss';
import useBackNav from '../utils/navigationsUtils';
export default function DescriptiveRowDialog(props) {
    const { headerText, headerButtonText, widgets, headerPrimaryText, headerSecondaryText, headerTertiaryText, headerMetaText, headerMetaText2, textWithLinks, closeButton, id, onViewed, updatedAt, widgetsHeaderText, onItemSelected, disabled, starRating, } = props.template;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatchSkyfireMethods(dispatch, props.template, onViewed);
        return () => {
            checkAndRemovePurchaseReviewInvokeFromURL();
        };
    }, []);
    useBackNav(true, () => {
        if (closeButton && closeButton.primaryLink.onItemSelected) {
            handleSelectedAndClearSamplePlayback(closeButton.primaryLink.onItemSelected);
        }
    });
    const key = [id, updatedAt].join('-');
    useStyles(Styles);
    return (React.createElement(TemplateDialog, { closeButton: closeButton, Styles: Styles, handleSelected: handleSelectedAndClearSamplePlayback },
        React.createElement(DetailHeader, { headline: headerText, primaryText: headerPrimaryText, secondaryText: headerSecondaryText, tertiaryText: headerTertiaryText, primaryTextBold: headerMetaText, secondaryTextBold: headerMetaText2, widgetElement: starRating, handleSelected: handleSelected },
            React.createElement("music-button", { role: "button", disabled: disabled, variant: "solid", size: "large", onClick: () => handleSelected(onItemSelected), style: { width: '100%' } }, headerButtonText)),
        textWithLinks && (React.createElement("p", { id: "dialogBodyText", className: ['music-tertiary-text', Styles.textWithLinks].join(' ') }, textWithLinks === null || textWithLinks === void 0 ? void 0 : textWithLinks.map((textWithLinkElement, index) => (React.createElement(TextWithLink, { key: `${index}-${textWithLinkElement.text}`, data: textWithLinkElement, template: props.template }))))),
        widgetsHeaderText && (React.createElement("p", { className: Styles.widgetsHeader },
            React.createElement("span", { className: "music-secondary-text" }, widgetsHeaderText))),
        (widgets === null || widgets === void 0 ? void 0 : widgets.length) ? (React.createElement("music-container", { id: id, key: key, onrendered: widgetsRendered },
            React.createElement(WidgetList, { list: widgets, handleSelected: handleSelected }))) : (React.createElement(LoadingSpinner, null))));
    function handleSelected(onItemSelectedMethods, e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        dispatchSkyfireMethods(dispatch, props.template, onItemSelectedMethods);
    }
    function widgetsRendered(event) {
        if (event.target.id === props.template.id) {
            dispatchTemplateRendered(dispatch, props.template, event.detail);
            dispatchSkyfireMethods(dispatch, props.template, props.template.onViewed);
        }
    }
    function handleSelectedAndClearSamplePlayback(onItemSelectedMethods, e) {
        dispatch({ type: CLEAR_SAMPLE });
        handleSelected(onItemSelectedMethods, e);
    }
    function checkAndRemovePurchaseReviewInvokeFromURL() {
        // If a user is redirected to sign-in from PR dialog. From sign-in when the user lands on
        // the page with auto invoke of PR dialog, then after viewing dialog the url params are
        // removed to avoid re-opening when refreshed.
        const url = new URL(globals.location.href);
        const currentSearchParams = url.searchParams;
        const doParameter = currentSearchParams.get('do');
        if (doParameter && doParameter === 'purchaseReview') {
            currentSearchParams.delete('do');
            const trackAsin = currentSearchParams.get('trackAsin');
            if (trackAsin)
                currentSearchParams.delete('trackAsin');
            globals.history.replaceState({}, '', rewriteDeeplink(url.toString(), false));
        }
    }
}
