export const CLEAR_STORAGE = 'StorageInterface.v1_0.ClearGroupMethod';
export const SET_STORAGE = 'StorageInterface.v1_0.SetValueInGroupMethod';
export const REMOVE_KEY = 'StorageInterface.v1_0.RemoveKeyFromGroupMethod';
export const BATCH_REMOVE_BY_VALUE = 'StorageInterface.v1_0.BatchRemoveByValueFromGroupMethod';
export const BATCH_REMOVE_KEY = 'StorageInterface.v1_0.BatchRemoveKeyFromGroupMethod';
export const BATCH_SET = 'StorageInterface.v1_0.BatchSetValueInGroupMethod';
export const BATCH_SET_UNIQUE = 'StorageInterface.v1_0.BatchSetUniqueValueInGroupMethod';
export const WRITE_METHOD_TO_STORAGE = 'StorageInterface.Persistent.v1_0.WriteMethodsToStorageMethod';
export const READ_METHOD_FROM_STORAGE = 'StorageInterface.Persistent.v1_0.ReadMethodsFromStorageMethod';
export const BUILD_PARAMETERIZED_INTERFACE = 'StorageInterface.v1_0.BuildParameterizedTemplateMethod';
export const SET_INTERFACE = 'StorageInterface.v1_0.SetParameterizedInterfaceMethod';
export const SET_TOGGLE_STATE_INTERFACE = 'StorageInterface.v1_0.SetToggleStateMethod';
