import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { bindHandler } from '../../utils';
import * as Styles from './HorizontalProgressBar.scss';
export default function HorizontalProgressBar(props) {
    const { label, progress, primaryLink, showProgress } = props.data;
    useStyles(Styles);
    const isClickable = (primaryLink === null || primaryLink === void 0 ? void 0 : primaryLink.onItemSelected.length) > 0;
    const bindHandlerHelper = (fn) => bindHandler(props.handleSelected, null, fn);
    return (React.createElement("div", { className: isClickable ? Styles.cursorPointer : '' },
        React.createElement("music-horizontal-progress-bar", { label: label, labelWidth: props.labelWidth, progress: progress, onmusicActivate: bindHandlerHelper(primaryLink === null || primaryLink === void 0 ? void 0 : primaryLink.onItemSelected), showProgress: showProgress })));
}
