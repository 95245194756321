exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._24B5zX0AXNsaMHNjF2xdnh,._2-y__gec_2biyVQlOkX7uU{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._24B5zX0AXNsaMHNjF2xdnh,._2-y__gec_2biyVQlOkX7uU{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._24B5zX0AXNsaMHNjF2xdnh,._2-y__gec_2biyVQlOkX7uU{padding-inline-start:52px;padding-inline-end:52px}}.K4Xv0dqUEvlCJr_uT-BTK{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){.K4Xv0dqUEvlCJr_uT-BTK{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){.K4Xv0dqUEvlCJr_uT-BTK{margin-inline-start:52px;margin-inline-end:52px}}._2-y__gec_2biyVQlOkX7uU{display:flex;flex-direction:column;margin-top:var(--music-spacer-large);width:100%}._2EhUoH5LOA_ov-deOhrO8j{margin-bottom:var(--music-spacer-mini)}._3fuj5BLwpeSXVhyHn8RBNc{display:flex;gap:20px}._3ngK9r3FVt7HiBJXuRyjm6{flex-direction:row}._1eyOrRKaQP2gGdA6xOAS0Y{flex-direction:column}._26r73KkZJU4c1Q40kB-V45{justify-content:flex-start}.ae2RWZT5hmSyoyoikDgjY{justify-content:center}._13x20VCbRKU64NLs0IkPlk{justify-content:flex-end}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_24B5zX0AXNsaMHNjF2xdnh",
	"gridSides": "_24B5zX0AXNsaMHNjF2xdnh",
	"live-streaming-options": "_2-y__gec_2biyVQlOkX7uU",
	"liveStreamingOptions": "_2-y__gec_2biyVQlOkX7uU",
	"grid-sides-margins": "K4Xv0dqUEvlCJr_uT-BTK",
	"gridSidesMargins": "K4Xv0dqUEvlCJr_uT-BTK",
	"live-streaming-options__heading": "_2EhUoH5LOA_ov-deOhrO8j",
	"liveStreamingOptionsHeading": "_2EhUoH5LOA_ov-deOhrO8j",
	"live-streaming-options__buttons": "_3fuj5BLwpeSXVhyHn8RBNc",
	"liveStreamingOptionsButtons": "_3fuj5BLwpeSXVhyHn8RBNc",
	"horizontal": "_3ngK9r3FVt7HiBJXuRyjm6",
	"vertical": "_1eyOrRKaQP2gGdA6xOAS0Y",
	"left-align": "_26r73KkZJU4c1Q40kB-V45",
	"leftAlign": "_26r73KkZJU4c1Q40kB-V45",
	"center-align": "ae2RWZT5hmSyoyoikDgjY",
	"centerAlign": "ae2RWZT5hmSyoyoikDgjY",
	"right-align": "_13x20VCbRKU64NLs0IkPlk",
	"rightAlign": "_13x20VCbRKU64NLs0IkPlk"
};