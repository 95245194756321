import { BUILD_PARAMETERIZED_INTERFACE, READ_METHOD_FROM_STORAGE } from '../actions/Storage';
import { processParameterizedInterfaces } from '../utils/parameterizedInterfaceUtils';
export const StorageMiddleware = (store) => (next) => (action) => {
    const storage = store.getState().Storage;
    const { METHODS } = storage;
    switch (action.type) {
        case READ_METHOD_FROM_STORAGE:
            if (action.payload.key !== undefined) {
                const methods = METHODS === null || METHODS === void 0 ? void 0 : METHODS[action.payload.key].methods;
                methods.forEach((method) => {
                    store.dispatch({
                        type: method.interface,
                        payload: method,
                    });
                });
            }
            break;
        case BUILD_PARAMETERIZED_INTERFACE:
            const { methodName, screenMode, parameterizedTemplate } = action.payload;
            const template = JSON.parse(parameterizedTemplate);
            const processedTemplate = processParameterizedInterfaces(template, storage);
            store.dispatch({
                type: methodName,
                payload: {
                    template: processedTemplate,
                    screenMode,
                    owner: action.payload.owner,
                },
            });
            break;
        default:
            break;
    }
    next(action);
};
