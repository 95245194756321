import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IApplicationState from 'src/types/IApplicationState';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import ISkyfireVisualTableWidget from '../../types/templates/widgets/ISkyfireVisualTableWidget';
import { dispatchSkyfireMethods } from '../../utils';
import ImageRow from './items/ImageRow';
import InfiniteList from './items/InfiniteList';
import { useItemsInView } from '../../utils/useItemsInView';

interface IVisualTableWidgetProps {
    data: ISkyfireVisualTableWidget;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    box?: ClientRect;
    loading?: boolean;
    isVisualPlayQueue?: boolean;
    onViewed?: () => void;
}

export default function VisualTableWidget(props: IVisualTableWidgetProps) {
    const callbackRef = useItemsInView(props.onViewed);
    const dispatch = useDispatch();
    const template = useSelector(
        (state: IApplicationState) => state.TemplateStack.currentTemplate
    );
    const { onTrackReorder } = props.data;
    const handleReorder = (moveToIndex, trackIndex) => {
        sessionStorage.setItem('moveTrackIndex', trackIndex);
        sessionStorage.setItem('dropIndex', moveToIndex);
        dispatch({
            type: 'REORDER_TRACK',
            payload: {
                trackIndex,
                moveToIndex,
                isVisualPlayQueue: !!props.isVisualPlayQueue,
            },
        });
        dispatchSkyfireMethods(dispatch, template, onTrackReorder);
    };

    return (
        <InfiniteList
            data={props.data}
            handleSelected={props.handleSelected}
            handleReorder={
                onTrackReorder?.length > 0 ? handleReorder : undefined
            }
            componentType={ImageRow}
            rowHeight={80}
            loading={props?.loading}
            isVisualPlayQueue={props?.isVisualPlayQueue}
            itemsViewedRef={callbackRef}
        />
    );
}
