import { useStore } from 'react-redux';
import { JSONPath } from 'jsonpath-plus';
/**
 * React Hook used to convert a parameterized interface into a de-parameterized one
 */
export function useParameterizedInterface(element) {
    const store = useStore();
    const { parameterizedInterface } = element;
    if (!parameterizedInterface)
        return element;
    const { Storage } = store.getState();
    const storedInterface = JSON.parse(Storage.INTERFACES[parameterizedInterface.interfaceKey]);
    Object.keys(parameterizedInterface.inputs).forEach((key) => {
        JSONPath({
            path: key,
            json: storedInterface,
            resultType: 'all',
            callback: (result) => {
                // eslint-disable-next-line no-param-reassign
                result.parent[result.parentProperty] = parameterizedInterface.inputs[key];
            },
        });
    });
    return storedInterface;
}
/**
 * Recursively traverses the template to process any parameterized interfaces.
 */
export function processParameterizedInterfaces(element, storage) {
    if (!(element instanceof Object)) {
        return element;
    }
    let processedElement = element;
    const { parameterizedInterface } = element;
    if (parameterizedInterface) {
        processedElement = JSON.parse(storage.INTERFACES[parameterizedInterface.interfaceKey]);
        Object.keys(parameterizedInterface.inputs).forEach((key) => {
            JSONPath({
                path: key,
                json: processedElement,
                resultType: 'all',
                callback: (result) => {
                    // eslint-disable-next-line no-param-reassign
                    result.parent[result.parentProperty] = parameterizedInterface.inputs[key];
                },
            });
        });
    }
    Object.keys(processedElement).forEach((key) => {
        processedElement[key] = processParameterizedInterfaces(processedElement[key], storage);
    });
    return processedElement;
}
