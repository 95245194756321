import getVideoPlaybackCapabilities from '../../utils/getVideoPlaybackCapabilities';
import DashVideoPlayer from './players/DashVideoPlayer';
import NativeVideoPlayer from './players/NativeVideoPlayer';
export function getInstance() {
    const { isDashSupported } = getVideoPlaybackCapabilities();
    if (isDashSupported) {
        return DashVideoPlayer.getInstance();
    }
    return NativeVideoPlayer.getInstance();
}
