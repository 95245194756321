import React from 'react';
import { useSelector } from 'react-redux';
import { bindHandler } from '../../../utils';
import { useObserver } from '../../../utils/ObserverHooks';
export default function LikeStateButton(props) {
    var _a, _b;
    const button = useObserver(props.data);
    const { icon, disabled, onItemSelected, text } = button;
    const buttonStorageGroup = ((_a = props.data.observer) === null || _a === void 0 ? void 0 : _a.storageGroup) || null;
    const musicEntityAsin = (_b = props.data.observer) === null || _b === void 0 ? void 0 : _b.storageKey;
    const musicEntityRating = useSelector((state) => {
        var _a;
        const RATING_TYPES = ['RATINGS', 'TRACK_RATINGS', 'ALBUM_RATINGS', 'ARTIST_FOLLOW_STATE'];
        let rating = null;
        if (buttonStorageGroup != null && RATING_TYPES.includes(buttonStorageGroup)) {
            const entityRating = state.Storage[buttonStorageGroup];
            rating = musicEntityAsin && (entityRating === null || entityRating === void 0 ? void 0 : entityRating[musicEntityAsin]);
        }
        return rating || ((_a = props.data.observer) === null || _a === void 0 ? void 0 : _a.defaultState) || 'NEUTRAL';
    });
    const onSelected = bindHandler(props.handleSelected, null, onItemSelected);
    const onActivate = (event) => {
        var _a;
        event.stopPropagation();
        (_a = event.detail) === null || _a === void 0 ? void 0 : _a.stopPropagation();
        onSelected();
    };
    const handleMouseEvent = (event) => {
        event.stopPropagation();
    };
    const variant = icon === 'favoriteon' ||
        musicEntityRating === 'THUMBS_UP' ||
        musicEntityRating === 'POSITIVE'
        ? 'accent'
        : 'primary';
    return (React.createElement("music-button", { slot: props.slot, ariaLabelText: text, title: text, variant: variant, "icon-name": icon, "icon-only": true, disabled: disabled, size: props.size || 'small', onmusicActivate: onActivate, onMouseUp: handleMouseEvent, onMouseDown: handleMouseEvent }));
}
