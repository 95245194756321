import { useEffect, useRef, useCallback } from 'react';
import { globals } from './globals';
/**
 * Hook for using Intersection Observer
 * Observes a single element and executes the callback being passed
 * @param target - element to observe | ref to element to observe
 * @param callback - callback for IntersectionObserver to execute on intersection change
 * @param options - options for IntersectionObserver
 * @returns targetRef | undefined
 */
const useIntersectionObserver = (target, callback, options) => {
    const targetRef = useRef(null);
    const createIntersectionObserver = useCallback(() => new globals.window.IntersectionObserver(callback, options), []);
    useEffect(() => {
        const observer = createIntersectionObserver();
        const targetElement = typeof target === 'object' && 'current' in target ? target.current : target;
        if (targetElement) {
            observer.observe(targetElement);
            targetRef.current = targetElement;
        }
        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, [target, callback, options]);
    return targetRef;
};
export default useIntersectionObserver;
