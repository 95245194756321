import { Store } from 'redux';
import { WEB_STORAGE } from '../appsync/AppSyncConstants';
import IApplicationState from '../types/IApplicationState';
import { ISaveOperationDao } from './ISaveOperationDao';
import {
    DELETE_SAVE_OPERATION,
    SAVE_OPERATION,
    UPDATE_SAVE_OPERATION,
} from './SaveActions';
import { SaveOperation } from './SaveOperation';

export class SaveOperationDaoSessionStorage implements ISaveOperationDao {
    private store: Store<IApplicationState>;

    constructor(store) {
        this.store = store;
    }

    public insert(saveOperation: SaveOperation) {
        this.store.dispatch({
            type: SAVE_OPERATION,
            payload: { saveOperation },
        });
    }

    public getUnProcessed(): SaveOperation[] {
        const unProcessedOperations: SaveOperation[] = [];
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (key.endsWith('SaveOperation')) {
                const saveOperation = JSON.parse(value);
                if (saveOperation && saveOperation.isProcessing === false) {
                    unProcessedOperations.push(saveOperation);
                }
            }
        }
        return unProcessedOperations;
    }

    public update(episodeIds: string[], isProcessing: boolean) {
        this.store.dispatch({
            type: UPDATE_SAVE_OPERATION,
            payload: { episodeIds, isProcessing },
        });
    }

    public delete(episodeIds: string[]) {
        this.store.dispatch({
            type: DELETE_SAVE_OPERATION,
            payload: { episodeIds },
        });
    }
}
