import debounce from 'debounce';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { SET_CONTENT_VIEWED_INDICES } from '../actions/ContentViewed';

const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.75,
};

export function setViewedItemIndices(
    dispatch: Dispatch,
    firstViewableIndex: number,
    lastViewableIndex: number
) {
    dispatch({
        type: SET_CONTENT_VIEWED_INDICES,
        payload: {
            firstViewableIndex,
            lastViewableIndex,
        },
    });
}

/**
 * Hook for ui content view
 * Observes a single element and performs an action the first time it enters the view
 * @param onViewed - function called when item enters view
 * @returns callbackRef | undefined
 */
export function useInView(
    onViewed: () => void = () => {}
): ((element: HTMLElement | null) => void) | undefined {
    const node = useRef<Element>();
    const observerRef = useRef<IntersectionObserver | null>(null);
    const dispatch = useDispatch();

    const createIntersectionObserver = useCallback(() => {
        function handleIntersect(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    observer.unobserve(entry.target);
                    setViewedItemIndices(dispatch, 0, 0);
                    onViewed();
                }
            });
        }
        return new window.IntersectionObserver(handleIntersect, options);
    }, [dispatch, onViewed]);

    useEffect(() => {
        observerRef.current = createIntersectionObserver();
        if (node.current) {
            observerRef.current?.observe(node.current);
        }
        return () => observerRef.current?.disconnect();
    }, [createIntersectionObserver]);

    const callbackRef = useCallback((element) => {
        node.current = element;
    }, []);
    return callbackRef;
}

/**
 * Hook for ui content view
 * Observes multiple items within a widget and performs an action when any items enter the view
 * Note that the HTML elements or web components being observed must have a data-key attribute
 * @param onItemsViewed - function called when any items enter view
 * @returns callbackRef | undefined
 */
export function useItemsInView(
    onItemsViewed: () => void = () => {}
): ((element: HTMLElement | null) => void) | undefined {
    const nodes = useRef<HTMLElement[]>([]);
    const elementKeyIndexMap = useRef<Map<string, number>>(new Map());
    const observerRef = useRef<IntersectionObserver | null>(null);
    const observedElementsSet = useRef<Set<HTMLElement>>(new Set());
    const index = useRef<number>(0);
    const dispatch = useDispatch();

    const createIntersectionObserver = useCallback(() => {
        const viewedElements: Set<HTMLElement> = new Set();
        function handleIntersect(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    observer.unobserve(entry.target);
                    viewedElements.add(entry.target);
                }
            });

            debounce(() => {
                if (viewedElements.size > 0) {
                    const viewedElementIndices2: number[] = [];
                    viewedElements.forEach((element) => {
                        const key: string = element.dataset.key!;
                        const localIndex: number = elementKeyIndexMap.current.get(
                            key
                        )!;
                        viewedElementIndices2.push(localIndex);
                    });

                    const firstViewableIndex = Math.min(
                        ...viewedElementIndices2
                    );
                    const lastViewableIndex = Math.max(
                        ...viewedElementIndices2
                    );
                    setViewedItemIndices(
                        dispatch,
                        firstViewableIndex,
                        lastViewableIndex
                    );
                    onItemsViewed();
                }
                viewedElements.clear();
            }, 1000)();
        }
        return new window.IntersectionObserver(handleIntersect, options);
    }, [dispatch, onItemsViewed]);

    useEffect(() => {
        observerRef.current = createIntersectionObserver();
        return () => observerRef.current?.disconnect();
    }, [createIntersectionObserver]);

    useEffect(() => {
        nodes.current.forEach((element) => {
            if (!observedElementsSet.current.has(element)) {
                observerRef.current?.observe(element);
                observedElementsSet.current.add(element);
            }
        });
    }, [nodes.current.length]);

    const callbackRef = useCallback((element) => {
        if (element && !elementKeyIndexMap.current.has(element.dataset.key)) {
            elementKeyIndexMap.current.set(
                element.dataset.key,
                index.current++
            );
            nodes.current.push(element);
        }
    }, []);
    return callbackRef;
}
