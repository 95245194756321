import { Store } from 'redux';
import { IPlaybackMetricOperationDao } from './IPlaybackMetricOperationDao';
import {
    DELETE_PLAYBACK_METRIC_OPERATION,
    SAVE_PLAYBACK_METRIC_OPERATION,
    UPDATE_PLAYBACK_METRIC_OPERATION,
} from './PlaybackMetricActions';
import { PlaybackMetricOperation } from './PlaybackMetricOperation';
import IApplicationState from '../types/IApplicationState';

export class PlaybackMetricOperationDaoSessionStorage
    implements IPlaybackMetricOperationDao {
    private store: Store<IApplicationState>;

    constructor(store) {
        this.store = store;
    }

    public insert(playbackMetricOperation: PlaybackMetricOperation) {
        this.store.dispatch({
            type: SAVE_PLAYBACK_METRIC_OPERATION,
            payload: { playbackMetricOperation },
        });
    }

    public getUnProcessed(): PlaybackMetricOperation[] {
        const unProcessedOperations: PlaybackMetricOperation[] = [];
        for (const [key, value] of Object.entries(sessionStorage)) {
            if (key.endsWith('PlaybackMetricOperation')) {
                const playbackMetricOperation = JSON.parse(value);
                if (
                    playbackMetricOperation &&
                    playbackMetricOperation.isProcessing === false
                ) {
                    unProcessedOperations.push(playbackMetricOperation);
                }
            }
        }
        return unProcessedOperations;
    }

    public update(ids: string[], isProcessing: boolean) {
        this.store.dispatch({
            type: UPDATE_PLAYBACK_METRIC_OPERATION,
            payload: { ids, isProcessing },
        });
    }

    public delete(ids: string[]) {
        this.store.dispatch({
            type: DELETE_PLAYBACK_METRIC_OPERATION,
            payload: { ids },
        });
    }
}
