import React, { Fragment } from 'react';
import { ScrollSpy } from './ScrollSpy';
import { TabsItemsGroupWidgetElementEnum } from '../utils/Enums';
const DescriptiveChildrenWrapper = (props) => {
    const { tabs } = props.data;
    const renderWrappedChildren = () => {
        if ((tabs === null || tabs === void 0 ? void 0 : tabs.variant) === TabsItemsGroupWidgetElementEnum.Scroll_Spy) {
            return (React.createElement(ScrollSpy, { tabs: tabs, tabsAlreadyInsertedWithinChildren: true, handleSelected: props.handleSelected }, props.children));
        }
        return React.createElement(Fragment, null, props.children);
    };
    return renderWrappedChildren();
};
export default DescriptiveChildrenWrapper;
