// eslint-disable-next-line no-shadow
export var TemplateType;
(function (TemplateType) {
    TemplateType["Default_Template"] = "DEFAULT_TEMPLATE";
    TemplateType["SR_Template"] = "SR_TEMPLATE";
})(TemplateType || (TemplateType = {}));
// eslint-disable-next-line no-shadow
export var ComponentType;
(function (ComponentType) {
    ComponentType["Default_Component"] = "DEFAULT_COMPONENT";
    ComponentType["SR_Component"] = "SR_COMPONENT";
})(ComponentType || (ComponentType = {}));
// eslint-disable-next-line no-shadow
export var TabsItemsGroupWidgetElementEnum;
(function (TabsItemsGroupWidgetElementEnum) {
    TabsItemsGroupWidgetElementEnum["Tabs"] = "tabs";
    TabsItemsGroupWidgetElementEnum["Scroll_Spy"] = "scroll_spy";
})(TabsItemsGroupWidgetElementEnum || (TabsItemsGroupWidgetElementEnum = {}));
