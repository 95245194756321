import { Bookmark } from '../Bookmark';
import { Follow } from '../Follow';
import { Save } from '../Save';

/*
  Central place to define deeplink generation on the front-end
  TODO: In the future we should consider adding these as a property
  to the sync interface so that they are generated in the back-end
  and simply displayed on the front-end
*/

const REGEXP = /[|"`!#$%^&*<>()+.,\/:;=?{}\[\]~\s]+/g;
const PODCAST = 'podcasts';
const EPISODE = 'episodes';

function normalizeTitle(title: string) {
    return encodeURIComponent(
        title
            .replace(REGEXP, ' ')
            .trim()
            .toLowerCase()
            .replace(/@/g, 'a')
            .replace(/ /g, '-')
    );
}

export function podcastDeeplink(item: Follow | Bookmark) {
    let id = '';
    let title = '';
    if (item instanceof Bookmark) {
        id = item.podcastId;
        title = item.podcastTitle;
    } else if (item instanceof Follow) {
        id = item.id;
        title = item.title;
    } else {
        return `/${PODCAST}`;
    }
    return `/${PODCAST}/${id}/${normalizeTitle(title)}`;
}

export function episodeDeeplink(item: Bookmark | Save) {
    return `/${PODCAST}/${item.podcastId}/${EPISODE}/${
        item.episodeId
    }/${normalizeTitle(`${item.podcastTitle} ${item.title}`)}`;
}
