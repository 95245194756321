import * as React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useObserver } from '../../../utils/ObserverHooks';
import { bindHandler } from '../../../utils';
import * as Styles from './UpsellButton.scss';
export default function UpsellButton(props) {
    const upsellButton = useObserver(props.data);
    const { onItemSelected, text } = upsellButton;
    useStyles(Styles);
    const onSelected = bindHandler(props.handleSelected, null, onItemSelected);
    const onActivate = (event) => {
        var _a;
        event.stopPropagation();
        (_a = event.detail) === null || _a === void 0 ? void 0 : _a.stopPropagation();
        onSelected();
    };
    return (React.createElement("div", { className: Styles.upsellButtonContainer },
        React.createElement("music-button", { onmusicActivate: onActivate, variant: "outline-refinement" }, text)));
}
