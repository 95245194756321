/**
 * Provides timeout values in milliseconds for different cases. These timeout values
 * represent how long the UI should display before hiding.
 */
// eslint-disable-next-line no-shadow
var VideoUITimeoutMS;
(function (VideoUITimeoutMS) {
    /**
     * The amount of time to keep the UI locked while hovering over an element
     * before hiding the UI again.
     */
    VideoUITimeoutMS[VideoUITimeoutMS["Hover4s"] = 4000] = "Hover4s";
    /**
     * The amount of time to keep the UI locked on click to toggle the play state
     * before hiding the UI again.
     */
    VideoUITimeoutMS[VideoUITimeoutMS["ClickToToggle"] = 4000] = "ClickToToggle";
    /**
     * The standard amount of time to lock the UI for before hiding it.
     */
    VideoUITimeoutMS[VideoUITimeoutMS["Standard"] = 4000] = "Standard";
})(VideoUITimeoutMS || (VideoUITimeoutMS = {}));
export default VideoUITimeoutMS;
