import HTMLReactParser from 'html-react-parser';
import React, { Fragment } from 'react';
import { useStore } from 'react-redux';
import {
    ContextMenuButton,
    LoadingSpinner,
    Button,
    WidgetList,
    RefinementHeader,
    BackgroundImage,
} from '../components';
import ISkyfireMethod from '../types/ISkyfireMethod';
import { ISkyfirePodcastDetailTemplate } from '../types/templates/detail/index';
import {
    useObserver,
    preventDefault,
    dispatchTemplateRendered,
    dispatchSkyfireMethods,
} from '../utils';
import Styles from './View.scss';
import DetailPlayButton from '../components/widgets/items/DetailPlayButton';
import FeaturedItem from '../components/widgets/items/FeaturedItem';
import CuratedDescriptionItem from '../components/widgets/items/CuratedDescriptionItem';

interface IDetailProps {
    template: ISkyfirePodcastDetailTemplate;
}

export function PodcastDetail(props: IDetailProps) {
    const store = useStore();
    const {
        widgets,
        headerImage,
        backgroundImage,
        contextMenu,
        headerText,
        headerButtons,
        headerPrimaryText,
        headerSecondaryText,
        headerTertiaryText,
        headerPrimaryTextLink,
        headerSecondaryTextLink,
        headerImageDimension,
        headerLabel,
        id,
        footer,
        updatedAt,
        refinementHeader,
        description,
        curatedDescriptionItem,
        featuredItem,
        isSmallHeaderCentered,
    } = props.template;
    const key = [id, updatedAt].join('-');
    const headerTextElement = useObserver(headerText);
    return (
        <div className={Styles['view-content']}>
            <BackgroundImage src={backgroundImage} />
            {refinementHeader && (
                <RefinementHeader
                    text={refinementHeader.text}
                    refinementOptions={refinementHeader.refinementOptions}
                    isActive={refinementHeader.isActive}
                    isDisabled={refinementHeader.isDisabled}
                    isOpened={refinementHeader.isOpened}
                />
            )}
            <music-detail-header
                key={headerImage}
                style={{
                    contain: 'layout',
                    marginTop: '40px',
                    // marginBottom: '24px',
                    display: 'block',
                }}
                image-src={headerImage}
                image-dimen={headerImageDimension || '1:1'}
                label={headerLabel}
                headline={headerTextElement.text ?? headerText}
                primary-text={headerPrimaryText}
                primary-text-href={headerPrimaryTextLink?.deeplink}
                onmusicPrimaryTextActivate={handleSelected.bind(
                    this,
                    headerPrimaryTextLink?.onItemSelected
                )}
                secondary-text={headerSecondaryText}
                secondary-text-href={headerSecondaryTextLink?.deeplink}
                onmusicSecondaryTextActivate={handleSelected.bind(
                    this,
                    headerSecondaryTextLink?.onItemSelected
                )}
                tertiary-text={headerTertiaryText}
                onClick={preventDefault}
                isSmallHeaderCentered={isSmallHeaderCentered}
            >
                {renderButtons()}
                {contextMenu && (
                    <ContextMenuButton
                        id='detailHeaderContextButton'
                        options={contextMenu.options}
                        disabled={contextMenu.disabled}
                        slot='icons'
                        variant='glass'
                        iconName='more'
                        size='small'
                    />
                )}
            </music-detail-header>
            {widgets?.length ? (
                <Fragment>
                    <music-container
                        id={id}
                        key={key}
                        onrendered={widgetsRendered}
                    >
                        {description?.length ? (
                            <div className={Styles.description}>
                                {HTMLReactParser(description, {
                                    replace: transformHtml,
                                })}
                            </div>
                        ) : (
                            <div />
                        )}
                        {curatedDescriptionItem &&
                        curatedDescriptionItem.description &&
                        curatedDescriptionItem.header ? (
                            <CuratedDescriptionItem
                                description={curatedDescriptionItem.description}
                                header={curatedDescriptionItem.header}
                            />
                        ) : (
                            <music-divider />
                        )}

                        {featuredItem && (
                            <FeaturedItem
                                featuredItem={featuredItem}
                                handleSelected={handleSelected}
                            />
                        )}
                        <WidgetList
                            list={widgets}
                            handleSelected={handleSelected}
                        />
                    </music-container>
                </Fragment>
            ) : (
                <LoadingSpinner />
            )}
            {footer && (
                <div className={Styles.footer}>
                    <span className='music-tertiary-text'>{footer}</span>
                </div>
            )}
        </div>
    );

    function renderButtons() {
        return headerButtons.map((button, idx) => {
            if (button.icon === 'play') {
                return (
                    <DetailPlayButton
                        button={button}
                        id={`detailHeaderButton${idx + 1}`}
                        handleSelected={handleSelected}
                        featuredItem={featuredItem}
                    />
                );
            }
            return (
                <Button
                    data={button}
                    handleSelected={handleSelected}
                    id={`detailHeaderButton${idx + 1}`}
                    slot='icons'
                    size={button.iconOnly ? 'small' : 'medium'}
                    variant='glass'
                />
            );
        });
    }

    function handleSelected(onItemSelected: ISkyfireMethod[]) {
        if (!onItemSelected) {
            return;
        }
        dispatchSkyfireMethods(store.dispatch, props.template, onItemSelected);
    }

    function widgetsRendered(event) {
        if (event.target.id === props.template.id) {
            dispatchTemplateRendered(
                store.dispatch,
                props.template,
                event.detail
            );
            dispatchSkyfireMethods(
                store.dispatch,
                props.template,
                props.template.onViewed
            );
        }
    }

    function transformHtml(node) {
        if (node.type === 'tag' && node.name === 'a') {
            node.attribs.target = '_blank';
        }
        return node;
    }
}
