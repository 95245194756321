import { Completed } from './Completed';
import { CompletedOperation } from './CompletedOperation';

export const SAVE_COMPLETED = 'SAVE_COMPLETED';
export const SAVE_COMPLETED_OPERATION = 'SAVE_COMPLETED_OPERATION';
export const UPDATE_COMPLETED_OPERATION = 'UPDATE_COMPLETED_OPERATIONS';
export const DELETE_COMPLETED_OPERATION = 'DELETE_COMPLETED_OPERATIONS';

export interface ISaveCompletedMethodAction {
    type: typeof SAVE_COMPLETED;
    payload: { completed: Completed };
}

export interface ISaveCompletedOperationMethodAction {
    type: typeof SAVE_COMPLETED_OPERATION;
    payload: { completedOperation: CompletedOperation };
}

export interface IUpdateCompletedMethodAction {
    type: typeof UPDATE_COMPLETED_OPERATION;
    payload: { ids: string[]; isProcessing: boolean };
}

export interface IDeleteCompletedOperationMethodAction {
    type: typeof DELETE_COMPLETED_OPERATION;
    payload: { ids: string[] };
}
