import { Store } from 'redux';
import {
    AppSyncTypes,
    COMPLETED_LAST_SYNC_TIME,
    COMPLETED_LAST_SYNC_TIME_CLIENT_STATE,
    COMPLETED_SET_ON_REPORT_OPERATIONS_METHOD,
} from '../appsync/AppSyncConstants';
import { Entry } from '../appsync/Entry';
import { SyncData } from '../appsync/SyncData';
import {
    IConditionElement,
    ITimeConditionElement,
} from '../types/appsync/IAppSync';
import IApplicationState from '../types/IApplicationState';
import { CompletedSetOnReportOperationsMethod } from '../appsync/CompletedSetOnReportOperationsMethod';
import { OperationElement } from '../appsync/OperationElement';
import { TimeConditionElement } from '../appsync/TimeConditionElement';
import { Completed } from './Completed';
import { CompletedOperation } from './CompletedOperation';
import { CompletedOperationsClientState } from './CompletedOperationsClientState';
import { CompletedLastSyncTimeClientState } from './CompletedLastSyncTimeClientState';
import { CompletedWriteElement } from './CompletedWriteElement';
import { ICompletedDao } from './ICompletedDao';
import { ICompletedOperationDao } from './ICompletedOperationDao';
import { CompletedMetadataWriteElement } from './CompletedMetadataWriteElement';

export class CompletedSyncData extends SyncData<
    CompletedWriteElement,
    CompletedMetadataWriteElement,
    Completed
> {
    private completedDao: ICompletedDao;

    private completedOperationDao: ICompletedOperationDao;

    private store: Store<IApplicationState>;

    constructor(
        completedDao: ICompletedDao,
        completedOperationDao: ICompletedOperationDao,
        store
    ) {
        super();
        this.completedDao = completedDao;
        this.completedOperationDao = completedOperationDao;
        this.store = store;
    }

    public read(id: string): Completed | undefined {
        const { customerId } = this.store.getState().Authentication;
        const completed = this.completedDao.getCompleted(id);
        if (completed) {
            return completed;
        }
        return undefined;
    }

    public readStates(): Completed[] {
        const { customerId } = this.store.getState().Authentication;
        return this.completedDao.getAll(true);
    }

    public async writeStates(
        element: CompletedWriteElement,
        condition?: IConditionElement
    ) {
        const timeCondition = condition as ITimeConditionElement;
        const updatedTime = timeCondition?.updatedTime
            ? timeCondition?.updatedTime
            : Date.now();
        const completed = new Completed({
            id: element.id,
            podcastId: element.podcastId,
            podcastTitle: element.podcastTitle,
            podcastImage: element.podcastImage,
            authors: element.authors,
            seasonNumber: element.seasonNumber,
            title: element.title,
            description: element.description,
            image: element.image,
            isCompleted: element.completed,
            updatedTime,
            publishTime: element.publishTime,
        });
        this.completedDao.insert(completed);
    }

    public writeToOperations(element: CompletedWriteElement) {
        const completedOperation = new CompletedOperation({
            episodeId: element.id,
            podcastId: element.id,
            podcastTitle: element.podcastTitle,
            podcastImage: element.podcastImage,
            authors: element.authors,
            seasonNumber: element.seasonNumber,
            elementId: element.id,
            title: element.title,
            description: element.description,
            image: element.image,
            isCompleted: element.completed,
            updatedTime: Date.now(),
            isProcessing: false,
            publishTime: element.publishTime,
        });
        this.completedOperationDao.insert(completedOperation);
    }

    public writeMetadata(element: CompletedMetadataWriteElement) {
        const completed = this.read(element.id);
        if (!completed) return;
        const updatedCompleted = new Completed({
            id: element.id,
            podcastId: element.podcastId,
            podcastTitle: element.podcastTitle,
            title: element.title,
            image: element.image,
            podcastImage: element.podcastImage,
            description: element.description,
            authors: element.authors,
            seasonNumber: element.seasonNumber,
            publishTime: element.publishTime,
        });
        this.completedDao.insert(updatedCompleted);
    }

    public reportOperations() {
        const operations = new CompletedSetOnReportOperationsMethod();
        const onReportOperations = [operations];
        this.store.dispatch({
            type: COMPLETED_SET_ON_REPORT_OPERATIONS_METHOD,
            payload: { onReportOperations },
        });
    }

    public getClientState(name: string): Entry {
        if (name === COMPLETED_LAST_SYNC_TIME_CLIENT_STATE) {
            return this.getCompletedsLastSyncTimeClientState();
        }
        const operations = this.completedOperationDao.getUnProcessed();
        const clientState = this.convertOperationsToClientState(operations);
        const ids: string[] = [];
        operations.forEach((operation) => {
            ids.push(operation.operationId);
        });
        this.completedOperationDao.update(ids, true);
        return new Entry(AppSyncTypes.COMPLETED, clientState);
    }

    public getCompletedsLastSyncTimeClientState(): Entry {
        const { customerId } = this.store.getState().Authentication;
        const lastSyncTime = this.completedDao.getLastSyncTime(customerId);
        return new Entry(
            COMPLETED_LAST_SYNC_TIME,
            new CompletedLastSyncTimeClientState(lastSyncTime)
        );
    }

    public deleteOperation(ids: string[]) {
        this.completedOperationDao.delete(ids);
    }

    public conditionSatisfied(
        element: CompletedWriteElement,
        condition: IConditionElement
    ): boolean {
        if (condition == null) {
            return true;
        }
        const timeCondition = condition as ITimeConditionElement;
        if (timeCondition == null) {
            return true;
        }
        const { customerId } = this.store.getState().Authentication;
        const completed = this.completedDao.getCompleted(element.id);
        if (completed) {
            return timeCondition.updatedTime > completed.updatedTime;
        }
        return true;
    }

    private convertOperationsToClientState(
        pendingOperations: CompletedOperation[]
    ): CompletedOperationsClientState {
        const operations: OperationElement[] = [];
        pendingOperations.forEach((operation) => {
            const writeElement: CompletedWriteElement = this.convertOperationToCompletedWriteElement(
                operation
            );
            const timeConditionElement: TimeConditionElement = new TimeConditionElement(
                operation.updatedTime
            );
            const operationElement: OperationElement = new OperationElement(
                operation.operationId,
                writeElement,
                timeConditionElement
            );
            operations.push(operationElement);
        });
        return new CompletedOperationsClientState(operations);
    }

    private convertOperationToCompletedWriteElement(
        operation: CompletedOperation
    ): CompletedWriteElement {
        return new CompletedWriteElement(
            operation.elementId,
            operation.podcastId,
            operation.podcastTitle,
            operation.podcastImage,
            operation.authors,
            operation.seasonNumber,
            operation.title,
            operation.description,
            operation.image,
            operation.isCompleted,
            operation.publishTime
        );
    }
}
