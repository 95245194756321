export const REPORT_BOOKMARK_OPERATION_WEB_SKILL =
    'com.amazon.dmplibraryvisualservice.skills.DMPLibraryVisualService.ReportBookmarkOperationsWebSkill';
export const REPORT_FOLLOW_OPERATION_WEB_SKILL =
    'com.amazon.dmplibraryvisualservice.skills.DMPLibraryVisualService.ReportFollowOperationsWebSkill';
export const REPORT_PLAYBACK_METRIC_OPERATION_WEB_SKILL =
    'com.amazon.dmpplaybackvisualservice.skills.DMPPlaybackVisualService.ReportPlaybackMetricOperationsWebSkill';
export const REPORT_SAVE_OPERATION_WEB_SKILL =
    'com.amazon.dmplibraryvisualservice.skills.DMPLibraryVisualService.ReportSaveOperationsWebSkill';
export const REPORT_COMPLETED_OPERATION_WEB_SKILL =
    'com.amazon.dmplibraryvisualservice.skills.DMPLibraryVisualService.ReportCompletedOperationsWebSkill';
export const PLAY_PODCAST_WEB_SKILL =
    'com.amazon.dmpplaybackvisualservice.skills.DMPPlaybackVisualService.PlayPodcastWebSkill';
export const SHOW_EPISODE_WEB_SKILL =
    'com.amazon.dmpbrowsevisualservice.skills.DMPBrowseVisualService.ShowEpisodeWebSkill';
export const SHOW_PODCAST_WEB_SKILL =
    'com.amazon.dmpbrowsevisualservice.skills.DMPBrowseVisualService.ShowPodcastWebSkill';
export const PLAY_SAVED_EPISODE_WEB_SKILL =
    'com.amazon.dmpplaybackvisualservice.skills.DMPPlaybackVisualService.PlaySavedEpisodesWebSkill';
export const REPORT_UI_METRIC_OPERATION_WEB_SKILL =
    'com.amazon.dmpbrowsevisualservice.skills.DMPBrowseVisualService.ReportUIMetricOperationsWebSkill';
export const BOOTSTRAP_WEB_SKILL =
    'com.amazon.dmpbrowsevisualservice.skills.DMPBrowseVisualService.BootstrapWebSkill';
export const RESTORE_PODCAST_PLAYBACK_WEB_SKILL =
    'com.amazon.dmpplaybackvisualservice.skills.DMPPlaybackVisualService.RestorePlaybackWebSkill';
