import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SLEEP_TIMER } from '../../actions/Playback';
import IApplicationState from '../../types/IApplicationState';
import ITransportButtonProps from '../../types/playback/TransportButton';

export function SpeedButton(props: ITransportButtonProps) {
    const dispatch = useDispatch();
    const { playbackSpeed } = useSelector(
        (state: IApplicationState) => state.PlaybackStates
    );
    const { mediaId } = useSelector((state: IApplicationState) => state.Media);
    const sleepTimerDispatch = () =>
        dispatch({ type: SET_SLEEP_TIMER, payload: { mediaId } });

    if (!playbackSpeed) {
        return <div style={{ display: 'none' }} />;
    }

    return (
        <music-button
            aria-label={playbackSpeed?.description}
            disabled={false}
            className='hydrated sc-music-button-h'
            onmusicActivate={sleepTimerDispatch}
            variant='primary'
            icon-name='jumpforward30'
            icon-only={true}
            size={props.size || 'small'}
        >
            {playbackSpeed?.description}
        </music-button>
    );
}
