import * as React from 'react';
import ISkyfireMethod from '../../../types/ISkyfireMethod';
import ISkyfireButtonElement from '../../../types/templates/widgets/ISkyfireButtonElement';
import { bindHandler } from '../../../utils';
import { useObserver } from '../../../utils/ObserverHooks';

interface IButtonProps {
    data: ISkyfireButtonElement;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    slot?: string;
    size?: string;
    id?: string;
    variant?: string;
    usePlaceholder?: boolean;
    isTextSentenceCase?: boolean;
}

const placeholderSizeMap = {
    tiny: 28,
    small: 40,
    medium: 48,
    large: 64,
};

const handleMouseEvent = (event: any) => {
    event.stopPropagation();
};

export function Button(props: IButtonProps) {
    const button = useObserver(props.data) as ISkyfireButtonElement;
    const {
        disabled,
        icon,
        iconHover,
        iconOnly,
        onItemSelected,
        text,
    } = button;
    const { id, slot, usePlaceholder } = props;
    const size = props.size || 'medium';
    const variant = props.variant || 'primary';

    const onSelected = bindHandler(props.handleSelected, null, onItemSelected);

    const onActivate = (event: any) => {
        event.stopPropagation();
        event.detail?.stopPropagation();
        onSelected();
    };

    const buttonText = props.isTextSentenceCase ? toSentenceCase(text) : text;

    return icon || text ? (
        <music-button
            id={id}
            slot={slot}
            onmusicActivate={onActivate}
            variant={variant}
            size={size}
            icon-only={iconOnly}
            icon-name={icon}
            icon-hover-name={iconHover}
            disabled={disabled}
            title={text}
            aria-label={text}
            onMouseUp={handleMouseEvent}
            onMouseDown={handleMouseEvent}
        >
            {!iconOnly && buttonText}
        </music-button>
    ) : usePlaceholder ? (
        <div style={{ minWidth: placeholderSizeMap[size] || 0 }} slot={slot} />
    ) : null;
}

export function toSentenceCase(text: String | undefined) {
    if (!text) return undefined;
    const lowerCaseString = text.toLocaleLowerCase();
    return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
}
