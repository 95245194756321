exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._230lNvo2qP5SnI-kqXYxBB{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._230lNvo2qP5SnI-kqXYxBB{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._230lNvo2qP5SnI-kqXYxBB{padding-inline-start:52px;padding-inline-end:52px}}._3juYWVOfM-PuGcMQAVdE2B{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._3juYWVOfM-PuGcMQAVdE2B{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._3juYWVOfM-PuGcMQAVdE2B{margin-inline-start:52px;margin-inline-end:52px}}._5tx8rIDM1RySt8fahrfv1{padding-inline-end:var(--music-spacer-small);min-width:390px}._1FiTDkMNDOyL1CKJqAF23y{width:300px}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_230lNvo2qP5SnI-kqXYxBB",
	"gridSides": "_230lNvo2qP5SnI-kqXYxBB",
	"grid-sides-margins": "_3juYWVOfM-PuGcMQAVdE2B",
	"gridSidesMargins": "_3juYWVOfM-PuGcMQAVdE2B",
	"context-menu-widget": "_5tx8rIDM1RySt8fahrfv1",
	"contextMenuWidget": "_5tx8rIDM1RySt8fahrfv1",
	"context-menu-widget-variant": "_1FiTDkMNDOyL1CKJqAF23y",
	"contextMenuWidgetVariant": "_1FiTDkMNDOyL1CKJqAF23y"
};