exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2-Zzi6TrgRtRs9uHvsU1fL{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._2-Zzi6TrgRtRs9uHvsU1fL{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._2-Zzi6TrgRtRs9uHvsU1fL{padding-inline-start:52px;padding-inline-end:52px}}.CKAZxCAWsVjkXQzFKcS_h{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){.CKAZxCAWsVjkXQzFKcS_h{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){.CKAZxCAWsVjkXQzFKcS_h{margin-inline-start:52px;margin-inline-end:52px}}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_2-Zzi6TrgRtRs9uHvsU1fL",
	"gridSides": "_2-Zzi6TrgRtRs9uHvsU1fL",
	"grid-sides-margins": "CKAZxCAWsVjkXQzFKcS_h",
	"gridSidesMargins": "CKAZxCAWsVjkXQzFKcS_h"
};