import { AppName, GoToDeeplinkEvent, GoToDeeplinkInternalEvent, publish, subscribe, } from '@amzn/DMWebEventBus';
import { UNRENDER } from '../actions';
import { getSkyfireEnvironment } from '../utils';
import { invokeHttp } from './utils';
export const handleGoToDeeplinkInternal = (store) => (path) => {
    store.dispatch({ type: UNRENDER });
    const skyfireEnv = getSkyfireEnvironment();
    const url = `${skyfireEnv}/api/getMethodsFromDeeplink?deeplink=${encodeURIComponent(path)}`;
    invokeHttp(store, url);
};
export function registerDeeplinkModule(store) {
    subscribe(GoToDeeplinkInternalEvent(AppName.WEBFIRE), handleGoToDeeplinkInternal(store));
}
export function goToDeeplink(url) {
    publish(GoToDeeplinkEvent, url);
}
