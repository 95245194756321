import useStyles from 'isomorphic-style-loader/useStyles';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DescriptiveShowcase from './DescriptiveShowcase';
import * as menuStyles from '../../views/Menu.scss';
import * as trackStyles from '../../views/Track.scss';
import * as tabStyles from './TabsItemsGroupWidget.scss';
import { TabsItemsGroupWidgetElementEnum } from '../../utils/Enums';
import { setActiveTab } from '../../actions';
import scrollToSection from '../../utils/tabsUtils';
export default function TabsItemsGroupWidget(props) {
    const { variant } = props.data;
    const isScrollSpyVariant = variant === TabsItemsGroupWidgetElementEnum.Scroll_Spy;
    const items = isScrollSpyVariant
        ? props.data.items
        : props.data.items.filter((item) => item.showcase.items.length > 0);
    useStyles(menuStyles, trackStyles, tabStyles);
    const activeTabSectionId = useSelector((state) => state.Tabs.activeSectionId);
    const dispatch = useDispatch();
    const showcaseList = items.map((item) => item.showcase);
    const tabList = items.map((item) => item.tab);
    useEffect(() => {
        if (activeTabSectionId === '') {
            dispatch(setActiveTab(tabList[0].id));
        }
    }, []);
    const switchTab = (sectionId) => {
        if (activeTabSectionId !== sectionId) {
            dispatch(setActiveTab(sectionId));
            if (isScrollSpyVariant) {
                scrollToSection(sectionId);
            }
        }
    };
    const renderTabs = (tab, index) => {
        const isActiveTab = activeTabSectionId === tab.id;
        return (React.createElement("li", { id: `${tab.text}-${index}`, className: [
                menuStyles.menubarMenuItem,
                isActiveTab ? menuStyles.selected : '',
            ].join(' ') },
            React.createElement("div", { className: isActiveTab ? menuStyles.hidden : '' },
                React.createElement("music-link", { kind: "secondary", onClick: (e) => {
                        switchTab(tab.id);
                    } }, tab.text)),
            React.createElement("div", { className: isActiveTab ? '' : menuStyles.hidden }, tab.text)));
    };
    const renderTrackList = (showcase, index) => activeTabSectionId === tabList[index].id && (React.createElement(DescriptiveShowcase, { data: showcase, handleSelected: props.handleSelected, key: showcase.header, alwaysShowButtonForHorizontalItem: true, shouldUpdateTrackPosition: true, currTemplate: props.currTemplate }));
    const tabNavBarClasses = isScrollSpyVariant ? [tabStyles.tabNavBar] : [menuStyles.menubar];
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: tabNavBarClasses.join(' '), id: "tabsItemsGroupWidgetNavBar" },
            React.createElement("ul", { className: menuStyles.menubarMenuItems }, tabList.map(renderTabs))),
        React.createElement("div", { id: "verticalTabsItemsGroupWidgetNavBar", className: menuStyles.hidden }, tabList.map((menuItem, index) => (React.createElement("music-list-item", { id: `verticalTabsItemsGroupWidgetNavBarItem${index + 1}`, className: "hydrated indent", size: "medium", "has-border": true, "primary-text": menuItem.text, "show-chevron": true, onClick: () => switchTab(menuItem.id) })))),
        React.createElement("div", { className: trackStyles.trackContainer }, showcaseList.map(renderTrackList))));
}
