import { ClientState } from '../types/ClientState';

export class SavesLastSyncTimeClientState extends ClientState {
    public lastSyncTime: number;

    public __type: string;

    constructor(lastSyncTime: number) {
        super();
        this.lastSyncTime = lastSyncTime;
        this.__type = 'Podcast.SaveInterface.v1_0#SavesLastSyncTimeClientState';
    }
}
