exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2kR6bPojLrfE00wJn-W7oZ{cursor:pointer}", ""]);

// exports
exports.locals = {
	"cursor-pointer": "_2kR6bPojLrfE00wJn-W7oZ",
	"cursorPointer": "_2kR6bPojLrfE00wJn-W7oZ"
};