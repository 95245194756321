import React, { Fragment, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import IApplicationState from '../../types/IApplicationState';
import ISkyfireMethod from '../../types/ISkyfireMethod';
import ISkyfireMessageWidget from '../../types/templates/widgets/ISkyfireMessageWidget';
import { bindHandler, computeElementOffsetFromPage } from '../../utils';
import messageStyles from '../../views/Message.scss';
import { useInView } from '../../utils/useItemsInView';

interface IMessageWidgetProps {
    data: ISkyfireMessageWidget;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    onViewed?: () => void;
}

export default function MessageWidget(props: IMessageWidgetProps) {
    // Grab data from props and Redux store.
    const {
        message,
        subMessage,
        subMessageLink,
        subMessageLinkText,
        subMessageSuffix,
        buttonText,
        buttonPrimaryLink,
        image,
        header,
    } = props.data;
    const callbackRef = useInView(props.onViewed);
    const { windowHeight } = useSelector(
        (state: IApplicationState) => state.BrowserState,
        shallowEqual
    );
    const messageWidgetRef = useRef<HTMLDivElement>(null);
    const [topMarginStyles, setTopMarginStyles] = useState({
        marginTop: 0,
        opacity: 0,
    });
    const [hasRendered, setHasRendered] = useState(false);

    const computeTopMargin = () => {
        let marginTop;
        if (messageWidgetRef?.current) {
            const { offsetTop } = computeElementOffsetFromPage(
                messageWidgetRef.current
            );
            const elementHeight = messageWidgetRef.current.offsetHeight;
            const parent =
                messageWidgetRef.current.parentElement || window.document.body;
            const parentOffset = parent.offsetTop;
            const containerHeight =
                window.document.body.offsetHeight - parentOffset;
            // If we compute again when img loads, add back the existing
            // marginTop since we subtracted in computeElementOffsetFromPage
            marginTop = Math.max(
                0,
                Math.min(
                    Math.max(
                        0,
                        containerHeight / 2 -
                            offsetTop -
                            elementHeight / 2 +
                            topMarginStyles.marginTop
                    ),
                    windowHeight / 3 - elementHeight
                )
            ); // safeguard against multiple callbacks
            setTopMarginStyles({ marginTop, opacity: 1 });
        }
    };

    useEffect(() => {
        if (!hasRendered) {
            setHasRendered(true);
            return;
        }
        computeTopMargin();
    }, [hasRendered]);

    const subMessageLinkElement =
        subMessageLink && subMessageLinkText ? (
            <Fragment key={subMessageLinkText}>
                {' '}
                <music-link
                    className={messageStyles['sub-message-link']}
                    href={subMessageLink?.deeplink}
                    onClick={bindHandler(
                        props.handleSelected,
                        null,
                        subMessageLink?.onItemSelected
                    )}
                >
                    {subMessageLinkText}
                </music-link>
            </Fragment>
        ) : null;

    return (
        <div ref={messageWidgetRef} style={topMarginStyles}>
            <div
                ref={callbackRef}
                className={messageStyles['message-container']}
            >
                {image && (
                    <img
                        className={messageStyles['message-image']}
                        src={image}
                        onLoad={computeTopMargin}
                    />
                )}
                {header && <h1 className='music-headline-4'>{header}</h1>}
                <p className={messageStyles['message-body']}>
                    <p className='music-primary-text'>{message}</p>
                </p>
                {subMessage && (
                    <p className={messageStyles['message-body']}>
                        <p className='music-primary-text'>
                            {subMessage}
                            {subMessageLinkElement}
                            {subMessageSuffix}
                        </p>
                    </p>
                )}
            </div>
        </div>
    );
}
