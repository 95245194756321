import { getParameterByName } from './getAmazonDomain';

const DEFAULT_SKYFIRE_ENVIRONMENT_NAME = 'prod';

const EXPERIENCES = {
    BROWSE: 'browse',
    PLAYBACK: 'playback',
    LIBRARY: 'library',
};

function getGammaEndpoint(region, experience): string {
    switch (region) {
        case 'NA':
            return `https://music-gamma.amazon.com/NA/api/podcast/${experience}/visual`;
        case 'EU':
            return `https://music-uk-dub.dub.proxy.amazon.com/EU/api/podcast/${experience}/visual`;
        case 'FE':
            return `https://music-jp-pdx.pdx.proxy.amazon.com/FE/api/podcast/${experience}/visual`;
        default:
            return `https://music-gamma.amazon.com/NA/api/podcast/${experience}/visual`;
    }
}

function getProdEndpoint(region, experience): string {
    return `https://music.amazon.com/${region}/api/podcast/${experience}/visual`;
}

export function getPodcastEnvironment(): string {
    const region = window.amznMusic.appConfig.siteRegion
        ? window.amznMusic.appConfig.siteRegion.toUpperCase()
        : 'NA';
    const browseEnvironments = {
        local: getGammaEndpoint(region, EXPERIENCES.BROWSE),
        gamma: getGammaEndpoint(region, EXPERIENCES.BROWSE),
        integ: getGammaEndpoint(region, EXPERIENCES.BROWSE),
        prod: getProdEndpoint(region, EXPERIENCES.BROWSE),
    };
    const paramSkyfireEnvName = getParameterByName(
        'skyfireEnv',
        window.location.href
    );
    const skyfireEnvironmentName =
        paramSkyfireEnvName || DEFAULT_SKYFIRE_ENVIRONMENT_NAME;
    return browseEnvironments[skyfireEnvironmentName];
}

export function getPodcastPlaybackEnvironment(): string {
    const region = window.amznMusic.appConfig.siteRegion
        ? window.amznMusic.appConfig.siteRegion.toUpperCase()
        : 'NA';
    const playbackEnvironments = {
        local: getGammaEndpoint(region, EXPERIENCES.PLAYBACK),
        gamma: getGammaEndpoint(region, EXPERIENCES.PLAYBACK),
        integ: getGammaEndpoint(region, EXPERIENCES.PLAYBACK),
        prod: getProdEndpoint(region, EXPERIENCES.PLAYBACK),
    };
    const paramSkyfireEnvName = getParameterByName(
        'skyfireEnv',
        window.location.href
    );
    const skyfireEnvironmentName =
        paramSkyfireEnvName || DEFAULT_SKYFIRE_ENVIRONMENT_NAME;

    return playbackEnvironments[skyfireEnvironmentName];
}

export function getPodcastLibraryEnvironment(): string {
    const region = window.amznMusic.appConfig.siteRegion
        ? window.amznMusic.appConfig.siteRegion.toUpperCase()
        : 'NA';
    const libraryEnvironments = {
        local: getGammaEndpoint(region, EXPERIENCES.LIBRARY),
        gamma: getGammaEndpoint(region, EXPERIENCES.LIBRARY),
        integ: getGammaEndpoint(region, EXPERIENCES.LIBRARY),
        prod: getProdEndpoint(region, EXPERIENCES.LIBRARY),
    };
    const paramSkyfireEnvName = getParameterByName(
        'skyfireEnv',
        window.location.href
    );
    const skyfireEnvironmentName =
        paramSkyfireEnvName || DEFAULT_SKYFIRE_ENVIRONMENT_NAME;
    return libraryEnvironments[skyfireEnvironmentName];
}

export function getMESKSkyfireEnvironment(): string {
    const region = window.amznMusic.appConfig.siteRegion
        ? window.amznMusic.appConfig.siteRegion.toUpperCase()
        : 'NA';
    const integ = `https://dev-${region}.mesk.skill.music.a2z.com`;
    const gamma = `https://qa-${region}.mesk.skill.music.a2z.com`;
    const prod = `https://${region}.mesk.skill.music.a2z.com`;

    const SKYFIRE_ENVIRONMENTS = {
        local: 'https://mp3localhost.amazon.com:4000',
        // @ts-ignore
        devDesktop: process?.env?.devDesktop || '',
        integ,
        gamma,
        dev: integ,
        qa: gamma,
        prod,
    };
    const paramSkyfireEnvName = getParameterByName(
        'skyfireEnv',
        window.location.href
    );
    const skyfireEnvironmentName =
        paramSkyfireEnvName || DEFAULT_SKYFIRE_ENVIRONMENT_NAME;
    return SKYFIRE_ENVIRONMENTS[skyfireEnvironmentName.toLowerCase()];
}
