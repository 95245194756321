import { supportsMediaSource } from 'dashjs';
export default function getVideoPlaybackCapabilities() {
    const video = document.createElement('video');
    const isHLSNativelySupported = Boolean(video.canPlayType('application/x-mpegURL'));
    const isDashSupported = supportsMediaSource();
    return {
        isHLSNativelySupported,
        isDashSupported,
    };
}
