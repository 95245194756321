import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { WindowScroller } from 'react-virtualized/dist/commonjs/WindowScroller';
import { FixedSizeGrid } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import IApplicationState from 'src/types/IApplicationState';
import ISkyfireMethod from '../../../types/ISkyfireMethod';
import ISkyfireDescriptiveListing from '../../../types/templates/widgets/ISkyfireDescriptiveListing';
import ISkyfireVisualListing from '../../../types/templates/widgets/ISkyfireVisualListing';
import { computeContainerWidth } from '../../../utils/gridHelpers';
import Styles from './InfiniteGrid.scss';
import LoadingWidget from '../../LoadingWidget';

interface InfiniteGridProps {
    componentType: any;
    data: ISkyfireDescriptiveListing | ISkyfireVisualListing | any;
    gridSizes: { colCount: number; colWidth: number; rowHeight: number };
    handleSelected: (methods: ISkyfireMethod[]) => void;
    isEnumerated?: boolean;
    itemsViewedRef?: (HTMLElement) => void;
}

interface IGridRef {
    scrollTo(params: { scrollLeft?: number; scrollTop?: number }): void;
}

interface ItemRendererProps {
    columnIndex: number;
    rowIndex: number;
    style: any;
    data: any;
}

const ItemRenderer = ({
    columnIndex,
    rowIndex,
    style,
    data,
}: ItemRendererProps) => {
    const {
        colCount,
        componentType,
        items,
        handleSelected,
        isEnumerated,
        itemsViewedRef,
    } = data;
    const index = rowIndex * colCount + columnIndex;
    const ComponentType = componentType;
    return items.length > index ? (
        <ComponentType
            ref={itemsViewedRef}
            data={items[index]}
            handleSelected={handleSelected}
            style={style}
            position={isEnumerated ? index + 1 : undefined}
        />
    ) : null;
};

export default function InfiniteGrid(props: InfiniteGridProps) {
    const gridRef = useRef<IGridRef>();

    // Grab data from props and Redux store.
    const {
        data,
        handleSelected,
        gridSizes,
        isEnumerated,
        itemsViewedRef,
        componentType,
    } = props;
    const { onEndOfWidget, items } = data;
    const { colCount, colWidth, rowHeight } = gridSizes;

    // Calculate grid sizing.
    const { windowWidth } = useSelector(
        (state: IApplicationState) => state.BrowserState
    );
    const width = computeContainerWidth(windowWidth);

    // Functions to check when to load more items.
    const loadMoreItems = () => handleSelected(onEndOfWidget);
    const isItemLoaded = (index) =>
        index < (onEndOfWidget?.length ? items.length - 1 : items.length);

    const showLoader = onEndOfWidget?.length > 0;

    const gridItemData = {
        colCount,
        componentType,
        items,
        handleSelected,
        isEnumerated,
        itemsViewedRef,
    };

    const onGridItemsRendered = (onItemsRendered) => ({
        visibleColumnStartIndex,
        visibleColumnStopIndex,
        visibleRowStartIndex,
        visibleRowStopIndex,
    }) => {
        const startIndex =
            visibleRowStartIndex * colCount + visibleColumnStartIndex;
        const stopIndex =
            visibleRowStopIndex * colCount + visibleColumnStopIndex;
        onItemsRendered({
            visibleStartIndex: startIndex,
            visibleStopIndex: stopIndex,
        });
    };

    const grid = (onItemsRendered, ref) => (
        <FixedSizeGrid
            className={Styles.grid}
            ref={(e) => {
                gridRef.current = e;
                ref(e);
            }}
            height={window.innerHeight}
            width={width}
            rowCount={Math.ceil(items.length / colCount)}
            rowHeight={rowHeight}
            columnCount={colCount}
            columnWidth={colWidth}
            itemData={gridItemData}
            onItemsRendered={onGridItemsRendered(onItemsRendered)}
        >
            {ItemRenderer}
        </FixedSizeGrid>
    );

    const infiniteLoader = (
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={items.length}
            loadMoreItems={loadMoreItems}
            threshold={1}
        >
            {({ onItemsRendered, ref }) => grid(onItemsRendered, ref)}
        </InfiniteLoader>
    );

    const onScroll = useCallback(
        ({ scrollTop }) => gridRef.current?.scrollTo({ scrollTop }),
        []
    );
    const windowScroller = (
        <WindowScroller onScroll={onScroll}>{() => <div />}</WindowScroller>
    );

    return (
        <div>
            {windowScroller}
            {infiniteLoader}
            {showLoader && <LoadingWidget />}
        </div>
    );
}
