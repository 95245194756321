export class DeeplinkClientState {
    public url: string;

    public __type: string;

    constructor(url) {
        this.url = url;

        this.__type = 'Podcast.DeeplinkInterface.v1_0#DeeplinkClientState';
    }
}
