import React from 'react';
import { useSelector } from 'react-redux';
import { VIDEO_NOW_PLAYING_TEMPLATE, } from '../types/templates/videoNowPlaying';
import View from './View';
import VideoNowPlaying from './VideoNowPlaying';
import NotificationToast from '../components/NotificationToast';
import Banner from '../components/Banner';
import Tooltip from '../components/Tooltip';
import NotificationRibbon from '../components/NotificationRibbon';
import Transport from '../components/Transport';
import ContextMenuOverlay from '../components/ContextMenuOverlay';
import { NOW_PLAYING_TEMPLATE } from '../types/templates/nowPlaying';
import { DIALOG_TEMPLATE } from '../types/templates/dialog';
/**
 * Renders all the overlays that can show up on top of the webplayer.
 * Among others, this includes the transport, the NPS, dialogs, and overflow menu
 */
export default function Overlays() {
    const mediaId = useSelector((state) => state.Media.mediaId);
    const isForMusicExperience = useSelector((state) => state.Media.isForMusicExperience);
    const currentTemplate = useSelector((state) => state.TemplateStack.currentTemplate);
    const overlayTemplates = useSelector((state) => state.TemplateStack.overlayTemplates);
    const currentTooltip = useSelector((state) => state.Tooltip.tooltip);
    const hasOverlay = overlayTemplates.length > 0;
    const videoNPVTemplate = overlayTemplates.find((template) => template.interface === VIDEO_NOW_PLAYING_TEMPLATE);
    const isAudioNPVShowing = hasOverlay &&
        !!overlayTemplates.find((template) => template.interface === NOW_PLAYING_TEMPLATE);
    const isVideoNPVShowing = hasOverlay && !!videoNPVTemplate;
    const isMusicExperienceShowing = hasOverlay && !!isForMusicExperience;
    const isNPVShowing = isAudioNPVShowing || isVideoNPVShowing || isMusicExperienceShowing;
    const isDialogShowing = hasOverlay && !!overlayTemplates.find((template) => template.interface === DIALOG_TEMPLATE);
    const isTooltipShowing = currentTooltip !== null;
    return (React.createElement("div", null,
        React.createElement("div", null, hasOverlay && overlayTemplates[0].interface !== VIDEO_NOW_PLAYING_TEMPLATE && (React.createElement(View, { template: overlayTemplates[0] }))),
        React.createElement(VideoNowPlaying, { template: videoNPVTemplate, isVideoNowPlaying: isVideoNPVShowing }),
        React.createElement(NotificationToast, { isNowPlaying: isNPVShowing, template: currentTemplate }),
        !isTooltipShowing && (React.createElement(NotificationRibbon, { isNowPlaying: isNPVShowing, template: currentTemplate })),
        React.createElement(Banner, { template: currentTemplate }),
        mediaId && (React.createElement(Transport, { isAudioNowPlaying: isAudioNPVShowing, isVideoNowPlaying: isVideoNPVShowing, isMusicExperienceNowPlaying: isMusicExperienceShowing, isDialogShowing: isDialogShowing })),
        React.createElement(Tooltip, { isNowPlaying: isNPVShowing, template: currentTemplate }),
        React.createElement(ContextMenuOverlay, null)));
}
