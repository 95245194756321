import { AnyAction } from 'redux';

import ISkyfireMethod from '../types/ISkyfireMethod';

export const EXECUTE_METHOD = 'EXECUTE_METHOD';
export interface IExecuteMethodAction {
    type: typeof EXECUTE_METHOD;
    payload: AnyAction;
}
export function executeMethod(method: ISkyfireMethod): IExecuteMethodAction {
    return {
        payload: formatMethod(method),
        type: EXECUTE_METHOD,
    };
}

export const EXECUTE_METHODS = 'EXECUTE_METHODS';
export interface IExecuteMethodsAction {
    type: typeof EXECUTE_METHODS;
    payload: AnyAction[];
}
export function executeMethods(
    methods: ISkyfireMethod[]
): IExecuteMethodsAction {
    return {
        payload: methods.map(formatMethod),
        type: EXECUTE_METHODS,
    };
}

function formatMethod(
    method: ISkyfireMethod
): { type: string; payload: ISkyfireMethod } {
    return {
        type: method.interface,
        payload: method,
    };
}
