import { channelFor, PerformActionEvent, StateChangedEvent } from './utils';
import ISkyfireContextMenuWidget from '../types/templates/widgets/ISkyfireContextMenuWidget';
import { ISkyfireContextMenuItemElement } from '../types/templates/contextMenu/ISkyfireContextMenuItemElement';
import ISkyfireContextMenuHeader from '../types/templates/settings/ISkyfireContextMenuHeader';

const OVERLAYS_CHANNEL = channelFor('overlays');

export const CONTEXT_MENU_OVERLAY_ID = 'contextMenuOverlay';
export const CONTEXT_MENU_HOVER_BUTTON_ID = 'contextMenuHoverButton';

export interface Overlays {
  isNPVShowing: boolean;
  hasOverlay: boolean;
  isSearchSuggestionsShowing: boolean;
}

export interface ContextMenuPayload {
  buttonId: string;
  widget?: ISkyfireContextMenuWidget;
  options?: ISkyfireContextMenuItemElement[];
  xOffset: number;
  yOffset: number;
  header?: string;
  label?: string;
  showSignInButton?: boolean;
  signInButtonText?: string;
  hover: boolean;
  contextMenuHeader?: ISkyfireContextMenuHeader;
}

export interface ShowAppDownloadDialogPayload {
  deeplink: string;
  forVideoStory?: boolean;
  artistName?: string;
}

export const OverlaysChangedEvent = new StateChangedEvent<Overlays>({
  channel: OVERLAYS_CHANNEL,
  topic: 'all',
});

export const ContextMenuOpenedEvent = new StateChangedEvent<boolean>({
  channel: OVERLAYS_CHANNEL,
  topic: 'contextmenu.opened',
});

export const OpenContextMenuEvent = new PerformActionEvent<ContextMenuPayload | string>({
  channel: OVERLAYS_CHANNEL,
  topic: 'openContextMenu',
});

export const CloseContextMenuEvent = new PerformActionEvent({
  channel: OVERLAYS_CHANNEL,
  topic: 'closeContextMenu',
});

export const ShowAppDownloadDialogEvent = new PerformActionEvent({
  channel: OVERLAYS_CHANNEL,
  topic: 'showAppDownloadDialog',
});
