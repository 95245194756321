import { useSelector } from 'react-redux';
import IApplicationState from 'src/types/IApplicationState';
import ISkyfireObserver from 'src/types/templates/observers/ISkyfireObserver';

type SkyfireObserverElement = { observer?: ISkyfireObserver };

export function useObserver(element?: SkyfireObserverElement) {
    const Storage = useSelector((state: IApplicationState) => state.Storage);
    const observer = element?.observer;
    if (!observer) {
        return element;
    }
    const storedState =
        Storage[observer.storageGroup] &&
        Storage[observer.storageGroup][observer.storageKey];
    const currentState =
        storedState === undefined ? observer.defaultState : storedState;
    return (
        observer?.states?.[currentState] ??
        currentState ??
        observer.defaultValue
    );
}

export function useObservers(elements: SkyfireObserverElement[]) {
    const Storage = useSelector((state: IApplicationState) => state.Storage);
    const observers = elements.map((element) => element?.observer);

    const filteredObservers = observers.filter((value) => value !== undefined);
    if (filteredObservers.length === 0) {
        return elements;
    }

    return observers.map((observer, idx) => {
        if (!observer) {
            return elements[idx];
        }
        const storedState =
            Storage[observer.storageGroup] &&
            Storage[observer.storageGroup][observer.storageKey];
        const currentState =
            storedState === undefined ? observer.defaultState : storedState;
        return (
            observer?.states?.[currentState] ??
            currentState ??
            observer.defaultValue
        );
    });
}
