import { CLEAR_PLAYBACK_DURATION_METHODS, DISPATCH_PLAYBACK_DURATION_METHODS } from '../actions';
import { dispatchPlaybackMethods } from '../utils/dispatchPlaybackMethods';
export const PlaybackDurationStateDispatchMiddleware = (store) => (next) => async (action) => {
    var _a;
    next(action);
    switch (action.type) {
        case DISPATCH_PLAYBACK_DURATION_METHODS: {
            const state = store.getState();
            const currentPlaybackDurationMethods = (_a = state.PlaybackDurationState) === null || _a === void 0 ? void 0 : _a.playbackDurationMethods[action.payload.currentTime];
            const { mediaId } = state.Media;
            if (currentPlaybackDurationMethods) {
                dispatchPlaybackMethods(store.dispatch, mediaId, currentPlaybackDurationMethods);
                store.dispatch({
                    type: CLEAR_PLAYBACK_DURATION_METHODS,
                    payload: { currentTime: action.payload.currentTime },
                });
            }
            break;
        }
        default:
            break;
    }
};
