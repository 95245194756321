import { getParameterByName } from './getAmazonDomain';

export const podcastDefaultAppVersion = '2.1';

export function getAppVersion(): string {
    let appVersion = podcastDefaultAppVersion;
    const skyfireEnv = getParameterByName('skyfireEnv', location.href);
    if (skyfireEnv === 'local' || skyfireEnv === 'gamma') {
        const skyfireAppVersion = getParameterByName(
            'skyfireAppVersion',
            location.href
        );
        if (skyfireAppVersion) {
            appVersion = skyfireAppVersion;
        }
    }
    return appVersion;
}
