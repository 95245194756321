import React, { forwardRef, useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import Template from '../../../Contexts/Template';
import { bindHandler, buildTags, dispatchSkyfireMethods, preventDefault } from '../../../utils';
import { getDeeplink } from '../../../utils/getDeeplink';
import { useItemSelectedState } from '../../../utils/MultiSelectHook';
import { useObserver } from '../../../utils/ObserverHooks';
import { useParameterizedInterface } from '../../../utils/parameterizedInterfaceUtils';
import ContextMenuButton from '../../ContextMenuButton';
import Button from './Button';
import RowItemButton from './RowItemButton';
import { WINDOW_SIZE_ENUM } from '../../../types/IWindowSize';
import { ComponentType } from '../../../utils/Enums';
import { RWP_DEVICE_TYPES } from '../../../utils/deviceTypes';
import { globals } from '../../../utils/globals';
import { STORAGE_ALBUM_RATINGS, STORAGE_ARTIST_RATINGS, STORAGE_TRACK_RATINGS, } from '../../../constants/storageConstants';
import LikeStateButton from './LikeStateButton';
import * as Styles from './TextRow.scss';
function TextRow(props, ref) {
    var _a;
    const { isHighlightingEnabled } = props;
    const { popularity, primaryText, primaryTextLink, primaryLink, secondaryText1, secondaryText1Link, secondaryText2, secondaryText2Link, secondaryText3, isDisabled, primaryBadges, secondaryBadges, buttons, icon, iconButton, rowIndex, contextMenu, button, isSelected, onCheckboxSelected, shouldFocus, focusDuration, componentType, } = props.data;
    const dispatch = useDispatch();
    const contextTemplate = useContext(Template);
    const template = props.template || contextTemplate;
    const isRowSelected = useItemSelectedState(isSelected, !!props.disableSelection);
    const onSelected = useObserver({ observer: onCheckboxSelected });
    const { windowWidth } = useSelector((state) => state.BrowserState);
    const [isFocused, setIsFocused] = useState(isHighlightingEnabled && shouldFocus && (props.isInitialRender || !focusDuration));
    const defaultRef = useRef(null);
    const { deviceType } = globals.amznMusic.appConfig;
    useStyles(Styles);
    useEffect(() => {
        if (isFocused && focusDuration) {
            setTimeout(() => {
                setIsFocused(false);
            }, focusDuration);
        }
    }, [isFocused, focusDuration]);
    const onEnterPress = (event) => {
        if (event.key === 'Enter') {
            props.handleSelected(primaryLink.onItemSelected);
        }
    };
    const renderSecondaryTextBelowPrimaryText = componentType === ComponentType.SR_Component.valueOf() &&
        windowWidth <= WINDOW_SIZE_ENUM.XL2;
    const parameterizedIconButton = useParameterizedInterface(iconButton);
    const iconButtonElement = useObserver(parameterizedIconButton);
    const bindHandlerHelper = (fn) => bindHandler(props.handleSelected, null, fn);
    const handleSelectedChange = useCallback(() => {
        const methods = Array.isArray(onSelected) ? onSelected : primaryLink.onItemSelected;
        if (template && methods.length > 0) {
            dispatchSkyfireMethods(dispatch, template, methods);
        }
    }, [dispatch, template, onSelected]);
    const key = primaryText + secondaryText1 + secondaryText2 + props.index;
    const storageGroup = (_a = button === null || button === void 0 ? void 0 : button.observer) === null || _a === void 0 ? void 0 : _a.storageGroup;
    const isLikesEveryWhereEnabled = storageGroup === STORAGE_TRACK_RATINGS ||
        storageGroup === STORAGE_ALBUM_RATINGS ||
        storageGroup === STORAGE_ARTIST_RATINGS;
    const slotsContainerClass = [
        Styles.slotsContainer,
        RWP_DEVICE_TYPES.includes(deviceType) ? ` ${Styles.enableMinWidth}` : '',
    ].join('');
    return (React.createElement("music-text-row", { ref: ref || defaultRef, key: key, "data-key": key, style: props.style, index: componentType === ComponentType.SR_Component.valueOf()
            ? null
            : rowIndex || props.index + 1, onClick: preventDefault, onKeyPress: onEnterPress, "icon-name": (iconButtonElement === null || iconButtonElement === void 0 ? void 0 : iconButtonElement.icon) || icon, onmusicIconActivate: bindHandlerHelper(iconButtonElement === null || iconButtonElement === void 0 ? void 0 : iconButtonElement.onItemSelected), "primary-text": primaryText, "primary-href": getDeeplink(primaryTextLink === null || primaryTextLink === void 0 ? void 0 : primaryTextLink.deeplink), onmusicPrimaryText1Activate: bindHandlerHelper(primaryTextLink === null || primaryTextLink === void 0 ? void 0 : primaryTextLink.onItemSelected), selected: isRowSelected, onmusicSelectedChange: handleSelectedChange, onmusicPrimaryTextActivate: bindHandlerHelper(primaryLink.onItemSelected), disabled: isDisabled, "secondary-text-1": secondaryText1, "secondary-href-1": getDeeplink(secondaryText1Link === null || secondaryText1Link === void 0 ? void 0 : secondaryText1Link.deeplink), onmusicSecondaryText1Activate: bindHandlerHelper(secondaryText1Link === null || secondaryText1Link === void 0 ? void 0 : secondaryText1Link.onItemSelected), "secondary-text-2": secondaryText2, "secondary-href-2": getDeeplink(secondaryText2Link === null || secondaryText2Link === void 0 ? void 0 : secondaryText2Link.deeplink), onmusicSecondaryText2Activate: bindHandlerHelper(secondaryText2Link === null || secondaryText2Link === void 0 ? void 0 : secondaryText2Link.onItemSelected), "render-secondary-text-below-primary-text": renderSecondaryTextBelowPrimaryText, duration: secondaryText3, rating: componentType === ComponentType.SR_Component.valueOf() ? null : popularity, focused: isFocused, "secondary-tags": secondaryBadges },
        buildTags(primaryBadges, isDisabled),
        React.createElement("div", { slot: "slots-container", className: slotsContainerClass },
            buttons.map((element) => (React.createElement(RowItemButton, { slot: "buttons", button: element }))),
            button &&
                (isLikesEveryWhereEnabled ? (React.createElement(LikeStateButton, { data: button, slot: "buttons", size: "small", handleSelected: props.handleSelected })) : (React.createElement(Button, { data: button, handleSelected: props.handleSelected, slot: "buttons", size: "small", usePlaceholder: buttons.length === 0, isDisabled: isDisabled }))),
            contextMenu && (React.createElement(ContextMenuButton, { options: contextMenu.options, disabled: contextMenu.disabled, slot: "contextMenu", variant: "primary", iconName: "more", size: "small" })))));
}
export default forwardRef(TextRow);
