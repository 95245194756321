import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import * as Styles from './ArtistBioWidget.scss';
import { useItemsInView } from '../../utils/useItemsInView';
export default function ArtistBioWidget(props) {
    useStyles(Styles);
    const callbackRef = useItemsInView(props.onViewed);
    const characterLimit = 275;
    const isBot = window.navigator.userAgent.match(/(bot|bingpreview)/i);
    const [shouldTruncate, setShouldTruncate] = useState(!isBot);
    const readMoreEllipsis = (React.createElement("span", null,
        "...",
        ' ',
        React.createElement("span", { onClick: () => setShouldTruncate(!shouldTruncate), className: Styles.expandableTextViewLink, role: "button", tabIndex: 0 }, "read more")));
    const truncateString = (text, length) => (React.createElement("div", null,
        text.substring(0, length),
        text.length > length && readMoreEllipsis));
    const getWidgetHeader = () => (React.createElement("header", { className: Styles.widgetHeaderContainer, title: props.data.header },
        React.createElement("div", { className: Styles.widgetHeader },
            " ",
            props.data.header,
            " ")));
    const getArtistBio = () => {
        if (props.data.text) {
            const artistBio = props.data.text.split('\n').map((paragraph, index) => (React.createElement("div", { className: Styles.text, key: index }, paragraph)));
            return (React.createElement("div", null,
                artistBio,
                React.createElement("span", { onClick: () => setShouldTruncate(!shouldTruncate), className: Styles.expandableTextViewLink, role: "button", tabIndex: 0 }, "read less")));
        }
        return null;
    };
    const getInitialArtistBio = () => (React.createElement("div", { className: Styles.text, key: 0 }, truncateString(props.data.text, characterLimit)));
    return (React.createElement("div", { ref: callbackRef, className: Styles.artistBioWidgetContainer },
        React.createElement("div", { className: Styles.dynamicContainer },
            getWidgetHeader(),
            shouldTruncate ? getInitialArtistBio() : getArtistBio())));
}
