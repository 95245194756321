import { CLEAR_SAMPLE, PLAY_SAMPLE, PLAYBACK_NOT_SUPPORTED, BUFFER, PAUSED, RESUMED, ERROR, ENDED, SHOW_SAMPLE_UNLOCK_DIALOG, } from '../actions';
import { PlaybackErrors } from '../types/playback/PlaybackErrorsEnum';
import { dispatchPlaybackMethods } from '../utils/dispatchPlaybackMethods';
import { getSamplingInstance, setSamplePlayerConfig } from '../player';
let samplePlayer;
export const SamplePlaybackMiddleware = (store) => (nextAction) => async (action) => {
    var _a, _b;
    const { onPlaybackNotSupported } = store.getState().Media;
    const { isSamplePlayback } = store.getState().SampleMedia;
    const auth = store.getState().Authentication;
    if (!isSamplePlayback) {
        nextAction(action);
        return;
    }
    switch (action.type) {
        case BUFFER:
            try {
                const { mediaId, audioUri } = action.payload;
                samplePlayer = await getSamplingInstance();
                setSamplePlayerConfig(samplePlayer, auth);
                samplePlayer.load(audioUri, { explicitId: mediaId });
                samplePlayer === null || samplePlayer === void 0 ? void 0 : samplePlayer.addEventListener('ended', handleSamplingEnded);
                samplePlayer === null || samplePlayer === void 0 ? void 0 : samplePlayer.addEventListener('error', handleSamplingError);
            }
            catch (e) {
                store.dispatch({ type: ERROR, payload: { error: e } });
            }
            break;
        case PLAY_SAMPLE:
            try {
                samplePlayer = await getSamplingInstance();
                samplePlayer === null || samplePlayer === void 0 ? void 0 : samplePlayer.play(action.payload.mediaId);
            }
            catch (_c) {
                store.dispatch({ type: PLAYBACK_NOT_SUPPORTED });
                dispatchPlaybackMethods(store.dispatch, action.payload.mediaId, onPlaybackNotSupported);
            }
            break;
        case CLEAR_SAMPLE:
            samplePlayer = await getSamplingInstance();
            samplePlayer === null || samplePlayer === void 0 ? void 0 : samplePlayer.pause();
            store.dispatch({ type: ENDED });
            if (samplePlayer === null || samplePlayer === void 0 ? void 0 : samplePlayer.removeEventListener) {
                samplePlayer === null || samplePlayer === void 0 ? void 0 : samplePlayer.removeEventListener('ended', handleSamplingEnded);
                samplePlayer === null || samplePlayer === void 0 ? void 0 : samplePlayer.removeEventListener('error', handleSamplingError);
            }
            break;
        case PAUSED:
            samplePlayer = await getSamplingInstance();
            samplePlayer === null || samplePlayer === void 0 ? void 0 : samplePlayer.pause();
            break;
        case RESUMED:
            samplePlayer = await getSamplingInstance();
            if (!('mediaId' in (action === null || action === void 0 ? void 0 : action.payload)) ||
                ((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.mediaId) === null || _b === void 0 ? void 0 : _b.includes(samplePlayer === null || samplePlayer === void 0 ? void 0 : samplePlayer.getCurrentTrackId()))) {
                samplePlayer === null || samplePlayer === void 0 ? void 0 : samplePlayer.resume();
            }
            else if (samplePlayer) {
                store.dispatch({ type: PLAY_SAMPLE, payload: action.payload });
            }
            break;
        default:
            break;
    }
    nextAction(action);
    function handleSamplingError(error) {
        if (error === PlaybackErrors.PLAY_NOT_AUTHORIZED) {
            store.dispatch({ type: PAUSED });
            store.dispatch({ type: SHOW_SAMPLE_UNLOCK_DIALOG });
        }
        store.dispatch({ type: ERROR, payload: { error } });
    }
    function handleSamplingEnded() {
        store.dispatch({ type: CLEAR_SAMPLE });
    }
};
