import { ContentTrait, PlaybackMode, UpsellType } from 'src/types/Eligibility';

export class Bookmark {
    public episodeId: string;

    public podcastId: string;

    public podcastTitle: string;

    public title: string;

    public image: string;

    public podcastImage: string;

    public description: string;

    public authors: string;

    public seasonNumber: number;

    public totalDurationMilliseconds: number;

    public progressMilliSeconds: number;

    public updatedTime: number;

    public publishTime: string;

    public playbackMode: PlaybackMode;

    public availableUpsells: UpsellType[];

    public contentTraits: ContentTrait[];

    public availabilityDate: string;

    public podcastShowVariantId: string;

    public podcastEpisodeVariantId: string;

    constructor(bookmark: {
        episodeId?;
        podcastId?;
        podcastTitle?;
        title?;
        image?;
        podcastImage?;
        description?;
        authors?;
        seasonNumber?;
        totalDurationMilliseconds?;
        progressMilliSeconds?;
        updatedTime?;
        publishTime;
        playbackMode?;
        availableUpsells?;
        contentTraits?;
        availabilityDate?;
        podcastShowVariantId?;
        podcastEpisodeVariantId?;
    }) {
        this.episodeId = bookmark.episodeId;
        this.podcastId = bookmark.podcastId;
        this.podcastTitle = bookmark.podcastTitle;
        this.title = bookmark.title;
        this.image = bookmark.image;
        this.podcastImage = bookmark.podcastImage;
        this.description = bookmark.description;
        this.authors = bookmark.authors;
        this.seasonNumber = bookmark.seasonNumber;
        this.totalDurationMilliseconds = bookmark.totalDurationMilliseconds;
        this.progressMilliSeconds = bookmark.progressMilliSeconds;
        this.updatedTime = bookmark.updatedTime;
        this.publishTime = bookmark.publishTime;
        this.playbackMode = bookmark.playbackMode;
        this.availableUpsells = bookmark.availableUpsells;
        this.contentTraits = bookmark.contentTraits;
        this.availabilityDate = bookmark.availabilityDate;
        this.podcastShowVariantId = bookmark.podcastShowVariantId;
        this.podcastEpisodeVariantId = bookmark.podcastEpisodeVariantId;
    }
}
