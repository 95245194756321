import { Store } from 'redux';
import {
    AppSyncTypes,
    UI_METRICS_SET_ON_REPORT_OPERATIONS_METHOD,
} from '../appsync/AppSyncConstants';
import { Entry } from '../appsync/Entry';
import { OperationElement } from '../appsync/OperationElement';
import { SyncData } from '../appsync/SyncData';
import { TimeConditionElement } from '../appsync/TimeConditionElement';
import { IConditionElement } from '../types/appsync/IAppSync';
import IApplicationState from '../types/IApplicationState';
import { IUIMetricOperationDao } from './IUIMetricOperationDao';
import { UIMetricOperation } from './UIMetricOperation';
import { UIMetricOperationsClientState } from './UIMetricOperationsClientState';
import { UIMetricSetOnReportOperationsMethod } from './UIMetricSetOnReportOperationsMethod';
import { UIMetricWriteElement } from './UIMetricWriteElement';
import { SignalType } from './UIMetrics';
import { setViewedItemIndices } from '../utils/useItemsInView';

export class UIMetricSyncData extends SyncData<
    UIMetricWriteElement,
    void,
    void
> {
    private uiMetricOperationDao: IUIMetricOperationDao;

    private store: Store<IApplicationState>;

    constructor(uiMetricOperationDao: IUIMetricOperationDao, store) {
        super();
        this.uiMetricOperationDao = uiMetricOperationDao;
        this.store = store;
    }

    public read(id: string): void {}

    public readStates(): void[] {
        return [];
    }

    public async write(
        element: UIMetricWriteElement,
        condition?: IConditionElement
    ) {
        await this.writeToOperations(element);
        this.reportOperations();
    }

    public writeStates(
        element: UIMetricWriteElement,
        condition?: IConditionElement
    ) {}

    public async writeToOperations(element: UIMetricWriteElement) {
        element.__type =
            element.__type ??
            'Podcast.UIMetricsInterface.v1_0#UIMetricWriteElement';
        if (element.signalType === SignalType.UI_CONTENT_VIEW) {
            this.updateContentViewWithCachedData(element);
        } else if (element.signalType === SignalType.UI_PAGE_VIEW) {
            this.updatePageViewWithCachedData(element);
        }
        const uiMetricOperation = new UIMetricOperation(
            '',
            Date.now(),
            false,
            element
        );
        this.uiMetricOperationDao.insert(uiMetricOperation);
    }

    public writeMetadata(element: void) {}

    public reportOperations() {
        const reportMethod = new UIMetricSetOnReportOperationsMethod();
        const onReportOperations = [reportMethod];
        this.store.dispatch({
            type: UI_METRICS_SET_ON_REPORT_OPERATIONS_METHOD,
            payload: { onReportOperations },
        });
    }

    public getClientState(): Entry {
        const operations = this.uiMetricOperationDao.getUnProcessed();
        const clientState = this.convertOperationsToClientState(operations);
        const ids: string[] = [];
        operations.forEach((operation) => {
            ids.push(operation.operationId);
        });
        this.uiMetricOperationDao.update(ids, true);
        return new Entry(AppSyncTypes.UI_METRIC, clientState);
    }

    public deleteOperation(ids: string[]) {
        this.uiMetricOperationDao.delete(ids);
    }

    public conditionSatisfied(
        element: UIMetricWriteElement,
        condition: IConditionElement
    ): boolean {
        return true;
    }

    private convertOperationsToClientState(
        pendingOperations: UIMetricOperation[]
    ): UIMetricOperationsClientState {
        const operations: OperationElement[] = [];
        pendingOperations.forEach((operation) => {
            const timeConditionElement: TimeConditionElement = new TimeConditionElement(
                operation.updatedTime
            );
            const operationElement: OperationElement = new OperationElement(
                operation.operationId,
                operation.uiMetricWriteElement,
                timeConditionElement
            );
            operations.push(operationElement);
        });
        return new UIMetricOperationsClientState(operations);
    }

    private updateContentViewWithCachedData(element: UIMetricWriteElement) {
        const contentViewedInfo = this.store.getState().ContentViewedInfo;
        element.firstViewableIndex =
            contentViewedInfo.firstViewableIndex ?? null;
        element.lastViewableIndex = contentViewedInfo.lastViewableIndex ?? null;
        setViewedItemIndices(this.store.dispatch, 0, 0);
    }

    private updatePageViewWithCachedData(element: UIMetricWriteElement) {
        const authenticationState = this.store.getState().Authentication;
        const { metricsContext } = authenticationState;
        element.referer =
            this.isInitialPageLoad() && metricsContext?.referer
                ? new URL(metricsContext.referer).hostname
                : '';
        element.refMarker = metricsContext?.refMarker
            ? decodeURIComponent(metricsContext.refMarker)
            : '';
    }

    private isInitialPageLoad() {
        const templateStackState = this.store.getState().TemplateStack;
        return !templateStackState?.previousTemplate?.innerTemplate;
    }
}
