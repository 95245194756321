exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1g_yVFb7xOJi2RktMZ_l9v{align-items:center;display:flex;margin-bottom:var(--music-spacer-mini)}._1g_yVFb7xOJi2RktMZ_l9v music-image{width:34px;height:34px;margin-inline-end:15px}._1g_yVFb7xOJi2RktMZ_l9v music-icon{margin-inline-end:var(--music-spacer-mini)}", ""]);

// exports
exports.locals = {
	"container": "_1g_yVFb7xOJi2RktMZ_l9v"
};