import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useInView } from '../utils/useItemsInView';
import * as Styles from './HorizontalItemHeader.scss';
import HorizontalItem from './widgets/items/HorizontalItem';
import Button from './widgets/items/Button';
export default function HorizontalItemHeader(props) {
    const { item, playButton, shareButton } = props.data;
    const callbackRef = useInView(props.onViewed);
    useStyles(Styles);
    return (React.createElement("div", { className: Styles.headerWrapper, ref: callbackRef },
        React.createElement(HorizontalItem, { data: item, isHeader: true, handleSelected: props.handleSelected }),
        React.createElement("div", { className: Styles.buttonContainer },
            playButton && (React.createElement(Button, { data: playButton, variant: "solid", size: "medium", handleSelected: props.handleSelected })),
            React.createElement(Button, { data: shareButton, variant: "glass", size: "medium", handleSelected: props.handleSelected }))));
}
