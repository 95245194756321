import { useState, useEffect } from 'react';
export default function useKeyDown(key) {
    const [keyPressed, setKeyPressed] = useState(false);
    const keyLookup = (ev) => ev.key === key;
    const downHandler = (ev) => {
        setKeyPressed(keyLookup(ev));
    };
    const upHandler = (ev) => setKeyPressed(false);
    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, []);
    return keyPressed;
}
