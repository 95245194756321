import { CLEAR_SAMPLE, SET_SAMPLE_MEDIA } from '../actions/Playback';
const initialState = {
    onStarted: [],
    onResumed: [],
    onScrubbed: [],
    onPreScrubbed: [],
    onPostScrubbed: [],
    onPaused: [],
    onFinished: [],
    onStopped: [],
    onRebuffered: [],
    onCompletedThresholdElapsed: [],
    onError: [],
    bufferTime: 0,
    currentTime: 0,
    playbackStarted: false,
    playbackStartOffset: 0,
};
const reducer = {
    [CLEAR_SAMPLE]: (state, action) => initialState,
    [SET_SAMPLE_MEDIA]: (state, action) => (Object.assign(Object.assign({}, state), action.payload.events)),
};
export function SamplePlaybackEventsReducer(state = initialState, action) {
    if (reducer[action.type]) {
        return reducer[action.type](state, action);
    }
    return state;
}
