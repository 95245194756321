import React, { Fragment, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import { dispatchSkyfireMethods } from '../../utils';
import HorizontalItem from './items/HorizontalItem';
import * as Styles from './ContextMenuHeader.scss';
export default function ContextMenuHeader(props) {
    var _a;
    const [calledRender, setCalledRender] = useState(false);
    const dispatch = useDispatch();
    const template = useSelector((state) => state.TemplateStack.currentTemplate);
    const { contextMenuHeader, widget, header, label } = props;
    useStyles(Styles);
    if (!calledRender) {
        (_a = props.onContextMenuRender) === null || _a === void 0 ? void 0 : _a.call(props);
        setCalledRender(true);
    }
    const handleSelected = useCallback((methods) => {
        if (template) {
            dispatchSkyfireMethods(dispatch, template, methods);
        }
    }, [template]);
    const onItemSelected = useCallback((event) => {
        var _a;
        const onFooterSelected = (_a = props.contextMenuHeader) === null || _a === void 0 ? void 0 : _a.onFooterSelected;
        if (template && onFooterSelected) {
            event.stopPropagation();
            dispatchSkyfireMethods(dispatch, template, onFooterSelected);
        }
    }, []);
    if (!(widget || contextMenuHeader) && (header || label)) {
        return React.createElement("music-context-menu-header", { "primary-text": header, "label-text": label });
    }
    if (!contextMenuHeader)
        return React.createElement(Fragment, null);
    return (React.createElement("div", { className: Styles.contextMenuHeader },
        contextMenuHeader.header && (React.createElement(HorizontalItem, { data: contextMenuHeader.header, handleSelected: handleSelected, role: "feeditem", alwaysShowButton: true })),
        contextMenuHeader.footerText && (React.createElement("music-list-item", { role: "menuItem", className: Styles.contextMenuHeaderList, "primary-text": contextMenuHeader.footerText, onClick: onItemSelected, variant: "accent", size: "medium", disabled: false }))));
}
