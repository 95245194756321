exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2rJ00tJYuHVY7WG1RazXEK{margin:var(--music-spacer-huge) auto;display:block;max-width:100%}._3fdOQqNvX1vaH62g1uf8-q{text-align:center;justify-content:center;align-items:center;flex-direction:column;height:100%}._19QFGhq6-1tnthhxztubVt{margin-top:var(--music-spacer-small)}._3g-3Q9HD4fzX93hmGqF-tX{display:inline}._3g-3Q9HD4fzX93hmGqF-tX span{display:inline;color:var(--music-color-accent);cursor:pointer}", ""]);

// exports
exports.locals = {
	"message-image": "_2rJ00tJYuHVY7WG1RazXEK",
	"messageImage": "_2rJ00tJYuHVY7WG1RazXEK",
	"message-container": "_3fdOQqNvX1vaH62g1uf8-q",
	"messageContainer": "_3fdOQqNvX1vaH62g1uf8-q",
	"message-body": "_19QFGhq6-1tnthhxztubVt",
	"messageBody": "_19QFGhq6-1tnthhxztubVt",
	"sub-message-link": "_3g-3Q9HD4fzX93hmGqF-tX",
	"subMessageLink": "_3g-3Q9HD4fzX93hmGqF-tX"
};