import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useRef } from 'react';
import * as DialogStyles from '../views/Dialog.scss';
export default function TemplateDialog(props) {
    var _a, _b, _c;
    const { children, closeButton, handleSelected, Styles } = props;
    useStyles(DialogStyles, Styles);
    const dialogContentRef = useRef(null);
    // Add backbutton & escape button event listeners.
    useEffect(() => {
        // setTimeout introduced to execute function after full component render
        setTimeout(() => focusFirstDescendent(), 100);
        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    }, []);
    // dialog styles can be passed/edited from parent
    const modalClass = (_a = Styles === null || Styles === void 0 ? void 0 : Styles.modal) !== null && _a !== void 0 ? _a : DialogStyles.modal;
    const modalContentClass = (_b = Styles === null || Styles === void 0 ? void 0 : Styles.modalContent) !== null && _b !== void 0 ? _b : DialogStyles.modalContent;
    const closeButtonClass = (_c = Styles === null || Styles === void 0 ? void 0 : Styles.closeButtonContainer) !== null && _c !== void 0 ? _c : DialogStyles.closeButtonContainer;
    return (React.createElement("div", { className: modalClass, role: "dialog", "aria-modal": "true", "aria-labelledby": "dialogHeader", "aria-describedby": "dialogBodyText", id: "dialog" },
        React.createElement("div", { className: modalContentClass, ref: dialogContentRef },
            renderCloseButton(),
            children)));
    function renderCloseButton() {
        if (!closeButton) {
            return null;
        }
        return (React.createElement("div", { className: closeButtonClass, id: "dialogCloseButton" },
            React.createElement("music-button", { role: "button", variant: "glass", size: "small", "icon-only": true, "icon-name": closeButton.icon, onmusicActivate: handleSelected.bind(this, closeButton.primaryLink.onItemSelected), ariaLabelText: closeButton.altText })));
    }
    function handleKeydown(e) {
        var _a;
        switch (e.key) {
            case 'Escape':
                if (closeButton) {
                    handleSelected(closeButton.primaryLink.onItemSelected, e);
                }
                break;
            case 'Tab': {
                const focusableEls = (_a = dialogContentRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('[tabindex]:not([tabindex="-1"])');
                const firstFocusableEl = (focusableEls === null || focusableEls === void 0 ? void 0 : focusableEls.length)
                    ? focusableEls[0]
                    : undefined;
                const lastFocusableEl = (focusableEls === null || focusableEls === void 0 ? void 0 : focusableEls.length)
                    ? focusableEls[focusableEls.length - 1]
                    : undefined;
                if (!e.shiftKey && e.target === lastFocusableEl) {
                    e.preventDefault();
                    firstFocusableEl === null || firstFocusableEl === void 0 ? void 0 : firstFocusableEl.focus();
                }
                else if (e.shiftKey && e.target === firstFocusableEl) {
                    e.preventDefault();
                    lastFocusableEl === null || lastFocusableEl === void 0 ? void 0 : lastFocusableEl.focus();
                }
                break;
            }
            default:
                break;
        }
    }
    function focusFirstDescendent() {
        var _a;
        const focusableEls = (_a = dialogContentRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('[tabindex]:not([tabindex="-1"])');
        if (!focusableEls || focusableEls.length === 0)
            return;
        const firstFocusableEl = focusableEls[0];
        const lastFocusableEl = focusableEls[focusableEls.length - 1];
        let focusElement = firstFocusableEl;
        // focus the first focusabile element but not close button
        const elementIconName = firstFocusableEl.getAttribute('icon-name');
        if (elementIconName === 'cancel' && firstFocusableEl !== lastFocusableEl) {
            [, focusElement] = focusableEls;
        }
        focusElement === null || focusElement === void 0 ? void 0 : focusElement.focus();
    }
}
