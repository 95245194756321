import ISkyfireMethod from '../../../types/ISkyfireMethod';
import ISkyfireSeeMoreItem from './items/ISkyfireSeeMoreItem';
import ISkyfireVerticalItem from './items/ISkyfireVerticalItem';

export const PODCAST_BOOKMARKS_SHOVELER_WIDGET =
    'Podcast.Web.WidgetsInterface.BookmarksShovelerWidgetElement';

export default interface IPodcastBookmarksShoveler {
    interface: typeof PODCAST_BOOKMARKS_SHOVELER_WIDGET;
    header: string;
    items: ISkyfireVerticalItem[];
    seeMoreItem: ISkyfireSeeMoreItem;
    onViewed: ISkyfireMethod[];
    uuid?: string;
}
