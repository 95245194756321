import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UAParser } from 'ua-parser-js';
import { NOW_PLAYING_TEMPLATE } from '../../types/templates/nowPlaying';
import { VIDEO_NOW_PLAYING_TEMPLATE } from '../../types/templates/videoNowPlaying';
import { PLAYBACK_TOGGLE } from '../../actions/Playback';
import { meetsTargetBrowserVersion } from '../../utils/platform';
export default function PlayButton(props) {
    const dispatch = useDispatch();
    const { play, content, audioVideo } = useSelector((state) => state.PlaybackStates);
    const { mediaId } = useSelector((state) => state.Media);
    const { overlayTemplates } = useSelector((state) => state.TemplateStack);
    useEffect(() => {
        var _a, _b;
        const ua = new UAParser(navigator.userAgent);
        const browser = ua.getBrowser();
        const isSafariPatched = meetsTargetBrowserVersion('Safari', '16.6');
        const shouldSetMediaSessionHandler = browser.name !== 'Safari' || isSafariPatched;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.mediaSession) === null || _a === void 0 ? void 0 : _a.setActionHandler('play', shouldSetMediaSessionHandler ? playbackToggle : () => null);
        (_b = navigator === null || navigator === void 0 ? void 0 : navigator.mediaSession) === null || _b === void 0 ? void 0 : _b.setActionHandler('pause', shouldSetMediaSessionHandler ? playbackToggle : () => null);
    }, [mediaId]);
    const isVideoNPVShowing = overlayTemplates.findIndex((template) => template.interface === VIDEO_NOW_PLAYING_TEMPLATE) >= 0;
    const isAudioNPVShowing = overlayTemplates.findIndex((template) => template.interface === NOW_PLAYING_TEMPLATE) >= 0;
    const isNPVShowing = isVideoNPVShowing || isAudioNPVShowing;
    const shouldDisplayLoader = (audioVideo === null || audioVideo === void 0 ? void 0 : audioVideo.state) === 'VIDEO' && !isNPVShowing && (content === null || content === void 0 ? void 0 : content.state) === 'WAITING';
    const icon = shouldDisplayLoader ? 'loader' : (play === null || play === void 0 ? void 0 : play.state) === 'PLAYING' ? 'pause' : 'play';
    const text = play === null || play === void 0 ? void 0 : play.descriptions[play === null || play === void 0 ? void 0 : play.state];
    const playbackToggle = useCallback(() => {
        dispatch({ type: PLAYBACK_TOGGLE, payload: { mediaId } });
    }, [mediaId]);
    return (React.createElement("music-button", { ariaLabelText: text || 'play', onmusicActivate: playbackToggle, "icon-name": icon, "icon-only": true, variant: props.variant || 'glass', size: props.size || 'small' }));
}
