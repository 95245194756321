import { parse } from 'query-string';
import { globals } from '../utils';
import { getSkyfireEnvironment } from './getSkyfireEnvironment';
export async function skyfirePostRequest(url, headers, onRequestError) {
    let parsedUrl = url;
    if (url.startsWith('http://localhost:8080')) {
        parsedUrl = url.replace('http://localhost:8080', getSkyfireEnvironment());
    }
    const urlObj = new URL(parsedUrl);
    const searchParams = parse(urlObj.search);
    // Passing custom headers requires preflight requests since SkyFire requests are cross-domain.
    // By passing all data in the body instead of headers, these preflight requests are no longer
    // needed - requiring less round trip network calls to make a SkyFire request.
    searchParams.headers = JSON.stringify(headers);
    let response;
    try {
        response = await globals.fetch(`${urlObj.protocol}//${urlObj.host}${urlObj.pathname}`, {
            method: 'post',
            // We should not pass custom headers to ensure that preflight requests are not required
            // when calling SkyFire. These add additional round trips - increasing latency.
            // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS
            headers: {},
            body: JSON.stringify(searchParams),
        });
        if (response.ok) {
            const json = await response.json();
            return json.methods;
        }
        // Note: non-2XX responses do not cause a Promise rejection.
        // "it will only reject on network failure or if anything prevented
        // the request from completing."
        // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
        return await onRequestError(`Status: ${response.status}`);
    }
    catch (error) {
        console.log(error);
        return onRequestError(error.message);
    }
}
