import React, { memo, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { PodcastFollowsShoveler, PodcastLibraryPlaylist, PodcastLibraryRecents, PodcastLibraryShows, PodcastBookmarksShoveler, PodcastEpisodeTableWidget, EpisodeDescriptiveShoveler, PODCAST_BOOKMARKS_SHOVELER_WIDGET, PODCAST_FOLLOWS_SHOVELER_WIDGET, PODCAST_LIBRARY_PLAYLIST_WIDGET, PODCAST_LIBRARY_RECENTS_WIDGET, PODCAST_LIBRARY_SHOWS_WIDGET, PODCAST_EPISODE_TABLE_WIDGET, EPISODE_DESCRIPTIVE_SHOVELER, } from '@amzn/dm-podcast-web-player';
import { IMAGE_ELEMENT } from '../../types/templates/widgets/ISkyfireImageGroupElement';
import { DESCRIPTIVE_ROW_DIALOG_WIDGET } from '../../types/templates/widgets/ISkyfireDescriptiveRowDialogWidgetElement';
import { TABS_ITEMS_GROUP_WIDGET } from '../../types/templates/widgets/ISkyfireTabsItemsGroupWidgetElement';
import TabsItemsGroupWidget from './TabsItemsGroupWidget';
import { dispatchSkyfireMethods } from '../../utils';
import { DESCRIPTIVE_LISTING } from '../../types/templates/widgets/ISkyfireDescriptiveListing';
import { DESCRIPTIVE_SHOVELER } from '../../types/templates/widgets/ISkyfireDescriptiveShoveler';
import { DESCRIPTIVE_SHOWCASE } from '../../types/templates/widgets/ISkyfireDescriptiveShowcase';
import { DESCRIPTIVE_TABLE_WIDGET } from '../../types/templates/widgets/ISkyfireDescriptiveTableWidget';
import { EVENT_HEADER } from '../../types/templates/widgets/ISkyfireEventHeaderWidget';
import { EXCLUSIVE_CAROUSEL } from '../../types/templates/widgets/ISkyfireExclusiveCarousel';
import { FEATURED_BANNER } from '../../types/templates/widgets/ISkyfireFeaturedBanner';
import { FEATURED_CARD } from '../../types/templates/widgets/ISkyfireFeaturedCard';
import { FEATURED_PLAY } from '../../types/templates/widgets/ISkyfireFeaturedPlay';
import { IMAGE_GROUP_WIDGET } from '../../types/templates/widgets/ISkyfireImageGroupWidget';
import { PODCAST_FEATURED_PLAY } from '../../types/templates/widgets/ISkyfirePodcastFeaturedPlay';
import { FEATURED_SHOVELER } from '../../types/templates/widgets/ISkyfireFeaturedShoveler';
import { FLEXIBLE_TEXT_WIDGET } from '../../types/templates/widgets/ISkyfireFlexibleTextWidget';
import { LINK_NAVIGATOR_WIDGET } from '../../types/templates/widgets/ISkyfireLinkNavigatorWidget';
import { MESSAGE_WIDGET } from '../../types/templates/widgets/ISkyfireMessageWidget';
import { PILL_NAVIGATOR_WIDGET } from '../../types/templates/widgets/ISkyfirePillNavigatorWidget';
import { RETAIL_HEADER } from '../../types/templates/widgets/ISkyfireRetailHeaderWidget';
import { SECTION } from '../../types/templates/widgets/ISkyfireSectionWidget';
import { THUMBNAIL_NAVIGATOR_WIDGET } from '../../types/templates/widgets/ISkyfireThumbnailNavigatorWidget';
import { TWITCH_VIDEO_PLAYER_WIDGET } from '../../types/templates/widgets/ISkyfireTwitchVideoPlayer';
import { VISUAL_LISTING } from '../../types/templates/widgets/ISkyfireVisualListing';
import { VISUAL_SHOVELER, } from '../../types/templates/widgets/ISkyfireVisualShoveler';
import { VISUAL_SHOWCASE } from '../../types/templates/widgets/ISkyfireVisualShowcase';
import { VISUAL_TABLE_WIDGET } from '../../types/templates/widgets/ISkyfireVisualTableWidget';
import { PLAYBACK_CONTAINER_WIDGET } from '../../types/templates/widgets/ISkyfirePlaybackContainerWidget';
import { BUTTON_WIDGET } from '../../types/templates/widgets/ISkyfireButtonWidgetElement';
import { HORIZONTAL_ITEM_HEADER_WIDGET } from '../../types/templates/widgets/ISkyfireHorizontalItemHeaderWidget';
import { REFINEMENT_HEADER } from '../../types/templates/widgets/ISkyfireRefinementHeaderElement';
import DescriptiveListing from './DescriptiveListing';
import DescriptiveShoveler from './DescriptiveShoveler';
import DescriptiveShowcase from './DescriptiveShowcase';
import DescriptiveTableWidget from './DescriptiveTableWidget';
import EventHeader from '../EventHeader';
import ExclusiveCarousel from '../ExclusiveCarousel';
import HorizontalItemHeader from '../HorizontalItemHeader';
import FeaturedBanner from './FeaturedBanner';
import FeaturedCard from './FeaturedCard';
import FeaturedPlay from './FeaturedPlay';
import ImageGroupWidget from './ImageGroupWidget/ImageGroupWidget';
import ImageGroupElement from '../ImageGroupElement';
import PodcastFeaturedPlay from './PodcastFeaturedPlay';
import FeaturedShoveler from './FeaturedShoveler';
import FlexibleTextWidget from './FlexibleTextWidget';
import LinkNavigatorWidget from './LinkNavigatorWidget';
import MessageWidget from './MessageWidget';
import PillNavigatorWidget from './PillNavigatorWidget';
import RetailHeader from '../RetailHeader';
import ThumbnailNavigatorWidget from './ThumbnailNavigatorWidget';
import TwitchVideoPlayerWidget from './TwitchVideoPlayerWidget/TwitchVideoPlayerWidget';
import VisualListing from './VisualListing';
import VisualShoveler from './VisualShoveler';
import VisualTableWidget from './VisualTableWidget';
import Template from '../../Contexts/Template';
import Section from '../Section';
import DescriptiveRowDialogWidget from './DescriptiveRowDialogWidget';
import PlaybackContainerWidget from './PlaybackContainerWidget';
import ButtonWidget from './ButtonWidget';
import RefinementWidget from '../RefinementWidget';
import { LIVE_STREAMING_OPTIONS_WIDGET } from '../../types/templates/widgets/ISkyfireLiveStreamingOptions';
import LiveStreamingOptionsWidget from './LiveStreamingOptionsWidget/LiveStreamingOptionsWidget';
import { STAR_RATING_ELEMENT } from '../../types/templates/widgets/ISkyfireStarRatingElement';
import StarRating from './StarRating';
import BreadcrumbsWidget from './BreadcrumbsWidget';
import { BREADCRUMB_ITEM_ELEMENT } from '../../types/ISkyfireBreadcrumbsElement';
import { LYRICS_WIDGET, } from '../../types/templates/widgets/ISkyfireLyricsWidget';
import LyricsWidget from './LyricsWidget';
import { ARTIST_BIO_WIDGET, } from '../../types/templates/widgets/ISkyfireArtistBioWidget';
import ArtistBioWidget from './ArtistBioWidget';
function Widget(props) {
    const dispatch = useDispatch();
    const currTemplate = useContext(Template);
    const onViewed = useCallback(() => {
        const onViewedMethods = props.data.onViewed;
        if (currTemplate && onViewedMethods && (onViewedMethods === null || onViewedMethods === void 0 ? void 0 : onViewedMethods.length) > 0) {
            dispatchSkyfireMethods(dispatch, currTemplate, onViewedMethods);
        }
    }, [dispatch, props.data]);
    const getWidget = () => {
        switch (props.data.interface) {
            case VISUAL_SHOVELER:
            case VISUAL_SHOWCASE:
                return (React.createElement(VisualShoveler, { data: props.data, handleSelected: props.handleSelected, key: props.data.header, onViewed: onViewed }));
            case DESCRIPTIVE_SHOVELER:
                return (React.createElement(DescriptiveShoveler, { data: props.data, handleSelected: props.handleSelected, key: props.data.header, onViewed: onViewed }));
            case FEATURED_SHOVELER:
                return (React.createElement(FeaturedShoveler, { data: props.data, handleSelected: props.handleSelected, key: props.data.header, onViewed: onViewed }));
            case DESCRIPTIVE_TABLE_WIDGET:
                return (React.createElement(DescriptiveTableWidget, { data: props.data, handleSelected: props.handleSelected, onViewed: onViewed, canScroll: props.canScroll }));
            case DESCRIPTIVE_ROW_DIALOG_WIDGET:
                return (React.createElement(DescriptiveRowDialogWidget, { data: props.data, handleSelected: props.handleSelected, onViewed: onViewed }));
            case IMAGE_GROUP_WIDGET:
                return React.createElement(ImageGroupWidget, { data: props.data });
            case IMAGE_ELEMENT:
                return React.createElement(ImageGroupElement, { data: props.data });
            case VISUAL_TABLE_WIDGET:
                return (React.createElement(VisualTableWidget, { data: props.data, handleSelected: props.handleSelected, onViewed: onViewed, canScroll: props.canScroll }));
            case LINK_NAVIGATOR_WIDGET:
                return (React.createElement(LinkNavigatorWidget, { items: props.data.items, header: props.data.header, handleSelected: props.handleSelected, onViewed: onViewed }));
            case THUMBNAIL_NAVIGATOR_WIDGET:
                return (React.createElement(ThumbnailNavigatorWidget, { header: props.data.header, items: props.data.items, handleSelected: props.handleSelected, onViewed: onViewed }));
            case VISUAL_LISTING:
                return (React.createElement(VisualListing, { data: props.data, handleSelected: props.handleSelected, key: props.data.header, isEnumerated: props.isEnumerated, onViewed: onViewed }));
            case DESCRIPTIVE_SHOWCASE:
                return (React.createElement(DescriptiveShowcase, { data: props.data, handleSelected: props.handleSelected, key: props.data.header, onViewed: onViewed }));
            case DESCRIPTIVE_LISTING:
                return (React.createElement(DescriptiveListing, { data: props.data, handleSelected: props.handleSelected, key: props.data.header, isEnumerated: props.isEnumerated, onViewed: onViewed }));
            case TABS_ITEMS_GROUP_WIDGET:
                return (React.createElement(TabsItemsGroupWidget, { data: props.data, handleSelected: props.handleSelected, onViewed: onViewed, currTemplate: currTemplate }));
            case MESSAGE_WIDGET:
                return (React.createElement(MessageWidget, { data: props.data, handleSelected: props.handleSelected, key: props.data.message, onViewed: onViewed }));
            case FLEXIBLE_TEXT_WIDGET:
                return React.createElement(FlexibleTextWidget, { items: props.data.items, onViewed: onViewed });
            case FEATURED_BANNER:
                return (React.createElement(FeaturedBanner, { data: props.data, key: props.data.imageAltText, onViewed: onViewed }));
            case FEATURED_CARD:
                return (React.createElement(FeaturedCard, { data: props.data, key: props.data.imageDescription, onViewed: onViewed }));
            case FEATURED_PLAY:
                return (React.createElement(FeaturedPlay, { data: props.data, key: props.data.header, handleSelected: props.handleSelected, onViewed: onViewed }));
            case PILL_NAVIGATOR_WIDGET:
                return (React.createElement(PillNavigatorWidget, { data: props.data, handleSelected: props.handleSelected, onViewed: onViewed }));
            case PODCAST_EPISODE_TABLE_WIDGET:
                return (React.createElement(PodcastEpisodeTableWidget, { data: props.data, handleSelected: props.handleSelected, onViewed: onViewed }));
            case PODCAST_LIBRARY_SHOWS_WIDGET:
                return (React.createElement(PodcastLibraryShows, { data: props.data, handleSelected: props.handleSelected, key: props.data.header, onViewed: onViewed }));
            case PODCAST_LIBRARY_RECENTS_WIDGET:
                return (React.createElement(PodcastLibraryRecents, { data: props.data, handleSelected: props.handleSelected, key: props.data.header, onViewed: onViewed }));
            case PODCAST_LIBRARY_PLAYLIST_WIDGET:
                return (React.createElement(PodcastLibraryPlaylist, { data: props.data, handleSelected: props.handleSelected, onViewed: onViewed }));
            case PODCAST_BOOKMARKS_SHOVELER_WIDGET:
                return (React.createElement(PodcastBookmarksShoveler, { data: props.data, handleSelected: props.handleSelected, onViewed: onViewed }));
            case PODCAST_FOLLOWS_SHOVELER_WIDGET:
                return (React.createElement(PodcastFollowsShoveler, { data: props.data, handleSelected: props.handleSelected, onViewed: onViewed }));
            case PODCAST_FEATURED_PLAY:
                return (React.createElement(PodcastFeaturedPlay, { data: props.data, key: props.data.header, handleSelected: props.handleSelected, onViewed: onViewed }));
            case PLAYBACK_CONTAINER_WIDGET:
                return (React.createElement(PlaybackContainerWidget, { data: props.data, handleSelected: props.handleSelected, key: props.data.header }));
            case EPISODE_DESCRIPTIVE_SHOVELER:
                return (React.createElement(EpisodeDescriptiveShoveler, { data: props.data, key: props.data.header, handleSelected: props.handleSelected, onViewed: onViewed }));
            case EVENT_HEADER:
                return React.createElement(EventHeader, { data: props.data, onViewed: onViewed });
            case EXCLUSIVE_CAROUSEL:
                return React.createElement(ExclusiveCarousel, { data: props.data, onViewed: onViewed });
            case RETAIL_HEADER:
                return React.createElement(RetailHeader, { data: props.data, onViewed: onViewed });
            case SECTION:
                return React.createElement(Section, { data: props.data, onViewed: onViewed });
            case BUTTON_WIDGET:
                return (React.createElement(ButtonWidget, { data: props.data, handleSelected: props.handleSelected, variant: props.data.outline ? 'outline-refinement' : 'primary' }));
            case HORIZONTAL_ITEM_HEADER_WIDGET:
                return (React.createElement(HorizontalItemHeader, { data: props.data, handleSelected: props.handleSelected, onViewed: onViewed }));
            case REFINEMENT_HEADER:
                return React.createElement(RefinementWidget, { data: props.data, onViewed: onViewed });
            case LIVE_STREAMING_OPTIONS_WIDGET:
                return React.createElement(LiveStreamingOptionsWidget, { data: props.data, onViewed: onViewed });
            case TWITCH_VIDEO_PLAYER_WIDGET:
                return React.createElement(TwitchVideoPlayerWidget, { data: props.data, onViewed: onViewed });
            case STAR_RATING_ELEMENT:
                return React.createElement(StarRating, { data: props.data, handleSelected: props.handleSelected });
            case BREADCRUMB_ITEM_ELEMENT:
                return (React.createElement(BreadcrumbsWidget, { data: props.data, currTemplate: currTemplate, onViewed: onViewed }));
            case LYRICS_WIDGET:
                return (React.createElement(LyricsWidget, { data: props.data, handleSelected: props.handleSelected, key: props.data.header, onViewed: onViewed }));
            case ARTIST_BIO_WIDGET:
                return (React.createElement(ArtistBioWidget, { data: props.data, key: props.data.header, onViewed: onViewed }));
            default:
                // @ts-ignore
                return React.createElement("p", null,
                    "Attempted to render unknown widget: ",
                    props.data.interface);
        }
    };
    const onrendered = () => {
        if (props.rendered) {
            props.rendered();
        }
    };
    return React.createElement("music-container", { onrendered: onrendered }, getWidget());
}
export default memo(Widget);
