import { UAParser } from 'ua-parser-js';
import { globals } from './globals';
const uaMobileDeviceTypes = [
    'iPhone',
    'iPad',
    'iPod',
    'Android',
    'Kindle',
    'Silk',
    'Windows Phone',
];
const matchMedia = (win, query) => win.matchMedia ? win.matchMedia(query).matches : false;
export const checkTouch = (win) => matchMedia(win, '(any-pointer:coarse)');
export const isDesktop = (win) => !checkTouch(win);
export const isMobileUserAgent = () => {
    for (const device of uaMobileDeviceTypes) {
        if (globals.navigator.userAgent.includes(device)) {
            return true;
        }
    }
    return false;
};
export const meetsTargetBrowserVersion = (targetBrowser, targetVersion) => {
    var _a;
    const ua = new UAParser(navigator.userAgent);
    const { name, version } = ua.getBrowser();
    if (name !== targetBrowser) {
        return false;
    }
    try {
        const targetVersionNumbers = (_a = targetVersion.split('.')) === null || _a === void 0 ? void 0 : _a.map(Number);
        const versionNumbers = (version === null || version === void 0 ? void 0 : version.split('.').map(Number)) || [];
        // Make sure both version arrays have the same length
        while (versionNumbers.length < targetVersionNumbers.length) {
            versionNumbers.push(0);
        }
        let isLaterOrEqual = true;
        for (let i = 0; i < targetVersionNumbers.length; i++) {
            if (versionNumbers[i] < targetVersionNumbers[i]) {
                isLaterOrEqual = false;
                break;
            }
            else if (versionNumbers[i] > targetVersionNumbers[i]) {
                break;
            }
        }
        return isLaterOrEqual;
    }
    catch (e) {
        return false;
    }
};
