exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2VTMowi3azd5Canl6KHa_V{position:relative;display:inline-block}._2A37HSwUCzCv8zFDLwOtsS{position:absolute;display:block;inset-inline-start:12px;top:0;transform:translate(-50%, -50%) rotate(-90deg);padding:5px 15px 3px}._2A37HSwUCzCv8zFDLwOtsS:focus,._2A37HSwUCzCv8zFDLwOtsS input:focus{outline:none}._2A37HSwUCzCv8zFDLwOtsS [type=range]{position:absolute;top:50%;inset-inline-start:50%;margin:0;padding:0;width:90px;height:15px;background:rgba(0,0,0,0)}._2A37HSwUCzCv8zFDLwOtsS [type=range],._2A37HSwUCzCv8zFDLwOtsS [type=range]::-webkit-slider-thumb{-webkit-appearance:none}._2A37HSwUCzCv8zFDLwOtsS [type=range]::-webkit-slider-runnable-track{box-sizing:border-box;border:none;width:90px;height:5px;background:#fff}._2A37HSwUCzCv8zFDLwOtsS [type=range]::-moz-range-track{box-sizing:border-box;border:none;width:90px;height:5px;background:#fff}._2A37HSwUCzCv8zFDLwOtsS [type=range]::-ms-track{box-sizing:border-box;border:none;width:90px;height:5px;background:#fff}._2A37HSwUCzCv8zFDLwOtsS [type=range]::-webkit-slider-thumb{margin-top:-5px;box-sizing:border-box;border:none;width:15px;height:15px;border-radius:50%;background:#fff}._2A37HSwUCzCv8zFDLwOtsS [type=range]::-moz-range-thumb{box-sizing:border-box;border:none;width:15px;height:15px;border-radius:50%;background:#fff}._2A37HSwUCzCv8zFDLwOtsS [type=range]::-ms-thumb{margin-top:0;box-sizing:border-box;border:none;width:15px;height:15px;border-radius:50%;background:#fff}._2A37HSwUCzCv8zFDLwOtsS [type=range]::-ms-tooltip{display:none}[dir=rtl] ._2A37HSwUCzCv8zFDLwOtsS{transform:translate(50%, -50%) rotate(90deg)}._2NaaLMEV-BmbbOUWJb5MQ6 ._2A37HSwUCzCv8zFDLwOtsS{top:140px}", ""]);

// exports
exports.locals = {
	"volume-container": "_2VTMowi3azd5Canl6KHa_V",
	"volumeContainer": "_2VTMowi3azd5Canl6KHa_V",
	"slider": "_2A37HSwUCzCv8zFDLwOtsS",
	"transport-overlay-open": "_2NaaLMEV-BmbbOUWJb5MQ6",
	"transportOverlayOpen": "_2NaaLMEV-BmbbOUWJb5MQ6"
};