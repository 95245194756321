import { v4 as uuidv4 } from 'uuid';
export default class VideoPlaybackMetricAttributes {
    constructor() {
        this.getRecentVideoPlaybackStartedPosition = () => this._recentVideoPlaybackStartedPosition;
        this.incrementPlaybackCount = () => {
            this.playbackCount++;
        };
        this.setPlaybackInstanceIdPerPlayback = () => {
            this.playbackInstanceIdPerPlayback = uuidv4();
        };
        this.setRecentPlaybackStartedPositionAndTrackProgress = (position) => {
            this.trackProgress = position;
            this._recentVideoPlaybackStartedPosition = position;
        };
        this.updatePlaybackDurationAndTrackProgress = (endPosition) => {
            this.trackProgress = endPosition;
            const playbackDuration = endPosition - this._recentVideoPlaybackStartedPosition;
            if (playbackDuration < 0) {
                this.playbackDurationMilliSeconds = endPosition;
                this.totalPlaybackDurationMilliSeconds = endPosition;
            }
            else {
                this.playbackDurationMilliSeconds = playbackDuration;
                this.totalPlaybackDurationMilliSeconds += playbackDuration;
            }
        };
        this.updateLatestPlaybackRequestedTimestamp = () => {
            const currentTime = Date.now();
            if (this._playbackStartRequestedAtTimeStampMilliSeconds === 0) {
                this._playbackStartRequestedAtTimeStampMilliSeconds = currentTime;
            }
            else {
                this._playbackResumeRequestedAtTimeStampMilliSeconds = currentTime;
            }
        };
        this.updateLatestPlaybackTimestampAndIPD = () => {
            const currentTime = Date.now();
            if (this.playbackStartedAtTimeStampMilliSeconds === 0 ||
                this.playbackStartedAtTimeStampMilliSeconds <
                    this._playbackStartRequestedAtTimeStampMilliSeconds) {
                this.playbackStartedAtTimeStampMilliSeconds = currentTime;
                this.playbackInitiationDelayMilliSeconds =
                    currentTime - this._playbackStartRequestedAtTimeStampMilliSeconds;
            }
            else {
                this.playbackResumedAtTimeStampMilliSeconds = currentTime;
                this.playbackInitiationDelayMilliSeconds =
                    currentTime - this._playbackResumeRequestedAtTimeStampMilliSeconds;
            }
        };
        this.playbackCount = 0;
        this.playbackDurationMilliSeconds = 0;
        this.playbackInitiationDelayMilliSeconds = 0;
        this.setPlaybackInstanceIdPerPlayback();
        this.playbackResumedAtTimeStampMilliSeconds = 0;
        this.playbackStartedAtTimeStampMilliSeconds = 0;
        this.totalPlaybackDurationMilliSeconds = 0;
        this.trackProgress = 0;
        this._playbackResumeRequestedAtTimeStampMilliSeconds = 0;
        this._playbackStartRequestedAtTimeStampMilliSeconds = 0;
        this._recentVideoPlaybackStartedPosition = 0;
    }
}
