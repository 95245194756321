import { globals } from './globals';
import { hostnameMap } from './hostnameMap';
export function getNodeEnvironment() {
    const envOverrideMap = [
        ['nodeEnv=dev', 'integ'],
        ['nodeEnv=gamma', 'gamma'],
    ];
    const { hostname, search } = globals.location;
    const entry = envOverrideMap.find(([queryString]) => search.includes(queryString));
    if (entry) {
        return entry[1];
    }
    return hostnameMap[hostname] || 'undefined';
}
