import { ISkyfireSquareVerticalItem } from '../types/templates/widgets/items/ISkyfireVerticalItem';
import ISkyfireFeaturedElement from '../types/templates/detail/ISkyfireFeaturedElement';
import ISkyfireButtonElement from '../types/templates/widgets/ISkyfireButtonElement';
import ISkyfireVisualRowItem from '../types/templates/widgets/items/ISkyfireVisualRowItem';
import ISkyfireEpisodeFeaturedElement from '../types/templates/detail/ISkyfireEpisodeFeaturedElement';
import ISkyfireEpisodeRowItem from '../types/templates/widgets/items/ISkyfireEpisodeRowItem';

export const UPDATE_PLAY_BUTTON = 'UPDATE_PLAY_BUTTON';
export const GET_BOOKMARK_ITEMS = 'GET_BOOKMARK_ITEMS';
export const GET_SAVE_ITEMS = 'GET_SAVE_ITEMS';
export const GET_FOLLOW_ITEMS = 'GET_FOLLOW_ITEMS';
export const GET_FEATURED_BOOKMARK_ITEM = 'GET_FEATURED_BOOKMARK_ITEM';
export const GET_FOLLOWED_SHOVELER_ITEMS = 'GET_FOLLOWED_SHOVELER_ITEMS';
export const GET_BOOKMARK_SHOVELER_ITEMS = 'GET_BOOKMARK_SHOVELER_ITEMS';
export interface IUpdateDetailPlayButtonMethod {
    type: typeof UPDATE_PLAY_BUTTON;
    payload: {
        playButton: ISkyfireButtonElement;
        featuredItem: ISkyfireFeaturedElement | ISkyfireEpisodeFeaturedElement;
    };
}

export interface IGetBookmarkItemsMethod {
    type: typeof GET_BOOKMARK_ITEMS;
    payload: {
        bookmarkItems: ISkyfireSquareVerticalItem;
    };
}

export interface IGetSaveItemsMethod {
    type: typeof GET_SAVE_ITEMS;
    payload: {
        saveItems: ISkyfireVisualRowItem | ISkyfireEpisodeRowItem;
    };
}
export interface IGetFollowItemsMethod {
    type: typeof GET_FOLLOW_ITEMS;
    payload: {
        followItems: ISkyfireSquareVerticalItem;
    };
}
export interface IGetFeaturedBookmarkItemMethod {
    type: typeof GET_FEATURED_BOOKMARK_ITEM;
    payload: {
        featuredBookmarkItem: ISkyfireVisualRowItem | ISkyfireEpisodeRowItem;
    };
}

export interface IGetFollowedShovelerMethod {
    type: typeof GET_FOLLOWED_SHOVELER_ITEMS;
    payload: {
        followedShovelerItems: ISkyfireSquareVerticalItem;
    };
}

export interface IGetBookmarkShovelerMethod {
    type: typeof GET_BOOKMARK_SHOVELER_ITEMS;
    payload: {
        bookmarkShovelerItems: ISkyfireSquareVerticalItem;
    };
}
