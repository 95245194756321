import React, { useCallback, useContext } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useDispatch } from 'react-redux';
import { useInView } from '../../../utils/useItemsInView';
import { dispatchSkyfireMethods } from '../../../utils';
import * as Styles from './LiveStreamingOptionsWidget.scss';
import LinkButton from '../items/LinkButton';
import Template from '../../../Contexts/Template';
const getButtonsStyle = (direction, alignment) => {
    const styles = [Styles.liveStreamingOptionsButtons];
    switch (direction) {
        case 'HORIZONTAL':
            styles.push(Styles.horizontal);
            break;
        case 'VERTICAL':
            styles.push(Styles.vertical);
            break;
        default:
            styles.push(Styles.vertical);
            break;
    }
    switch (alignment) {
        case 'CENTER':
            styles.push(Styles.centerAlign);
            break;
        case 'LEFT':
            styles.push(Styles.leftAlign);
            break;
        case 'RIGHT':
            styles.push(Styles.rightAlign);
            break;
        default:
            styles.push(Styles.leftAlign);
            break;
    }
    return styles.join(' ');
};
export default function LiveStreamingOptionsWidget(props) {
    const { heading, platformOptions, actionOptions, direction, alignment } = props.data;
    // UI view metrics
    const callbackRef = useInView(props.onViewed);
    // UI click metrics
    const dispatch = useDispatch();
    const template = useContext(Template);
    const onClick = useCallback((onItemSelected) => {
        if (template) {
            dispatchSkyfireMethods(dispatch, template, onItemSelected);
        }
    }, [dispatch, template]);
    useStyles(Styles);
    return (React.createElement("div", { className: Styles.liveStreamingOptions, ref: callbackRef },
        React.createElement("div", { className: Styles.liveStreamingOptionsHeading },
            React.createElement("h1", { className: "music-headline-4" }, heading)),
        React.createElement("div", { className: getButtonsStyle(direction, alignment) },
            platformOptions.map((option) => (React.createElement(LinkButton, { key: option.text, href: option.href, text: option.text, image: option.image, openInNewTab: true, onItemSelected: option.onItemSelected }))),
            actionOptions.map((option) => (React.createElement("music-button", { key: option.icon, iconName: option.icon, iconOnly: true, size: "medium", slot: "buttons", variant: "glass", onClick: onClick.bind(this, option.onItemSelected) }))))));
}
