import { v4 } from 'uuid';

export class PlaybackMetricOperation {
    public operationId: string;

    public mediaId: string;

    public mediaCollectionType: number;

    public playbackSignalType: string;

    public currentProgressMilliseconds: number;

    public playbackRequestedAtTimestampMilliseconds: number;

    public metricsPreset: string;

    public isMediaDownloaded: boolean;

    public currentPlaybackSpeed: number;

    public playbackStartOffsetMilliseconds: number;

    public playbackStartedAtTimestampMilliseconds: number;

    public initialPlaybackStartDelayMilliseconds: number;

    public rebufferDurationMilliseconds: number;

    public rebufferCount: number;

    public updatedTime: number;

    public isProcessing: boolean;

    public pageType: string;

    public audioUri: string;

    public podcastShowVariantId: string;

    public podcastEpisodeVariantId: string;

    constructor(operation: {
        operationId?;
        mediaId?;
        mediaCollectionType?;
        playbackSignalType?;
        currentProgressMilliseconds?;
        playbackRequestedAtTimestampMilliseconds?;
        metricsPreset?;
        isMediaDownloaded?;
        currentPlaybackSpeed?;
        playbackStartOffsetMilliseconds?;
        playbackStartedAtTimestampMilliseconds?;
        initialPlaybackStartDelayMilliseconds?;
        rebufferDurationMilliseconds?;
        rebufferCount?;
        updatedTime?;
        isProcessing?;
        pageType?;
        audioUri?;
        podcastShowVariantId?;
        podcastEpisodeVariantId?;
    }) {
        this.operationId = operation.operationId ?? v4();
        this.mediaId = operation.mediaId;
        this.mediaCollectionType = operation.mediaCollectionType;
        this.playbackSignalType = operation.playbackSignalType;
        this.currentProgressMilliseconds =
            operation.currentProgressMilliseconds;
        this.playbackRequestedAtTimestampMilliseconds =
            operation.playbackRequestedAtTimestampMilliseconds;
        this.metricsPreset = operation.metricsPreset;
        this.isMediaDownloaded = operation.isMediaDownloaded;
        this.currentPlaybackSpeed = operation.currentPlaybackSpeed;
        this.playbackStartOffsetMilliseconds =
            operation.playbackStartOffsetMilliseconds;
        this.playbackStartedAtTimestampMilliseconds =
            operation.playbackStartedAtTimestampMilliseconds;
        this.initialPlaybackStartDelayMilliseconds =
            operation.initialPlaybackStartDelayMilliseconds;
        this.rebufferDurationMilliseconds =
            operation.rebufferDurationMilliseconds;
        this.rebufferCount = operation.rebufferCount;
        this.updatedTime = operation.updatedTime;
        this.isProcessing = operation.isProcessing;
        this.pageType = operation.pageType;
        this.audioUri = operation.audioUri;
        this.podcastShowVariantId = operation.podcastShowVariantId;
        this.podcastEpisodeVariantId = operation.podcastEpisodeVariantId;
    }
}
