import ISkyfireMethod from 'src/types/ISkyfireMethod';
import ISkyfireLinkElement from '../../ISkyfireLinkElement';

export const MESSAGE_WIDGET =
    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.MessageWidgetElement';
export default interface ISkyfireMessageWidget {
    interface: typeof MESSAGE_WIDGET;
    message: string;
    header?: string;
    subMessage?: string;
    subMessageLink?: ISkyfireLinkElement;
    subMessageLinkText?: string;
    buttonText?: string;
    buttonPrimaryLink?: ISkyfireLinkElement;
    subMessageSuffix?: string;
    image?: string;
    backgroundImage?: string;
    items?: any; // workaround
    onViewed: ISkyfireMethod[];
    uuid?: string;
    onEndOfWidget?: ISkyfireMethod[];
}
