exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3sfqdwEGKPrw_oJvdxx1kL{height:70px}._3sfqdwEGKPrw_oJvdxx1kL img{object-fit:contain}", ""]);

// exports
exports.locals = {
	"image-container": "_3sfqdwEGKPrw_oJvdxx1kL",
	"imageContainer": "_3sfqdwEGKPrw_oJvdxx1kL"
};