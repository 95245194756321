import { UAParser } from 'ua-parser-js';

// eslint-disable-next-line no-shadow
export enum DEVICE_TYPES_ENUM {
    AWW0SCLEVHN20 = 'RetailWebPlayer.web',
    A2IYUBDFJ0JQHI = 'RetailWebPlayer.mobile',
    A3GUQNOIIEF57X = 'RetailWebPlayer.mshop',
    A2YTAK36ZW6KLD = 'RetailWebPlayer.mshop.ios',
    A1OC181LHTSSL5 = 'RetailWebPlayer.mshop.android',
    A3VJHPKPE607WI = 'RetailWebPlayer.mini.web',
    ARDHPQTE1DODJ = 'RetailWebPlayer.mini.mshop.android',
    A39E3ZTQY6K0YY = 'RetailWebPlayer.mini.mshop.ios',
    A1MPSLFC7L5AFK = 'App.mshop.android',
    A3NWHXTQ4EBCZS = 'App.mshop.ios',
    A34OIAVFALWTNH = 'MobileWebPlayer',
    A16ZV8BU3SN1N3 = 'WebPlayer',
}

// eslint-disable-next-line no-shadow
export enum DEVICE_TYPES_ENUM_FEEDBACK_DIALOG {
    AWW0SCLEVHN20 = 'RetailWebPlayer.web',
    A2IYUBDFJ0JQHI = 'RetailWebPlayer.mobile',
    A3GUQNOIIEF57X = 'RetailWebPlayer.mshop',
    A2YTAK36ZW6KLD = 'RetailWebPlayer.mshop.ios',
    A1OC181LHTSSL5 = 'RetailWebPlayer.mshop.android',
    A3VJHPKPE607WI = 'RetailWebPlayer.mini.web',
    ARDHPQTE1DODJ = 'RetailWebPlayer.mini.mshop.android',
    A39E3ZTQY6K0YY = 'RetailWebPlayer.mini.mshop.ios',
    A1MPSLFC7L5AFK = 'App.mshop.android',
    A3NWHXTQ4EBCZS = 'App.mshop.ios',
    A34OIAVFALWTNH = 'SKYFIRE-MOBILE-WEB',
    A16ZV8BU3SN1N3 = 'SKYFIRE',
}

export const MOBILE_DEVICES = [
    'A34OIAVFALWTNH',
    'A2IYUBDFJ0JQHI',
    'A3GUQNOIIEF57X',
    'A2YTAK36ZW6KLD',
    'A1OC181LHTSSL5',
    'ARDHPQTE1DODJ',
    'A39E3ZTQY6K0YY',
    'A1MPSLFC7L5AFK',
    'A3NWHXTQ4EBCZS',
];

export const UPSELL_ON_PLAYBACK_ERROR_DEVICES = [
    'A3GUQNOIIEF57X',
    'A2YTAK36ZW6KLD',
    'A39E3ZTQY6K0YY',
    'A1OC181LHTSSL5',
    'ARDHPQTE1DODJ',
    'A1MPSLFC7L5AFK',
    'A3NWHXTQ4EBCZS',
];

export const MSHOP = ['A3GUQNOIIEF57X'];
export const IOS_MSHOP = [
    'A3GUQNOIIEF57X',
    'A2YTAK36ZW6KLD',
    'A39E3ZTQY6K0YY',
    'A3NWHXTQ4EBCZS',
];

export function isIOSUserAgent() {
    const ua = new UAParser(navigator.userAgent);
    const os = ua.getOS();
    const browser = ua.getBrowser();
    if (
        browser.name === 'Safari' ||
        os.name === 'iOS' ||
        UPSELL_ON_PLAYBACK_ERROR_DEVICES.includes(
            window.amznMusic.appConfig.deviceType
        )
    ) {
        return true;
    }
    return false;
}

export function isIOSmshop(): boolean {
    const ua = new UAParser(navigator.userAgent);
    const os = ua.getOS();
    return (
        (os.name === 'iOS' || os.name === 'Mac OS') &&
        // to check for iphones/ipads which has more maxTouchPoints
        navigator.maxTouchPoints > 2 &&
        IOS_MSHOP.includes(window.amznMusic.appConfig.deviceType)
    );
}
