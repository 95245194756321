import VideoPlaybackMetricAttributes from '../metrics/VideoPlaybackMetricAttributes';
import HTML5VideoPlayerEvent from './HTML5VideoPlayerEvent';
export default class AbstractVideoPlayer {
    constructor() {
        this.attachToElement = (containerId) => {
            var _a;
            this.containerId = containerId;
            (_a = document.getElementById(containerId)) === null || _a === void 0 ? void 0 : _a.appendChild(this.videoElement);
        };
        this.attachSource = (playbackUri) => {
            if (playbackUri === this.attachedSource) {
                return;
            }
            this.load(playbackUri);
        };
        this.destroy = () => {
            var _a;
            this.unload();
            if (this.containerId) {
                (_a = document.getElementById(this.containerId)) === null || _a === void 0 ? void 0 : _a.removeChild(this.videoElement);
            }
            this.destroyPlayer();
        };
        this.getLatestPlaybackRequestedPosition = () => this.videoPlaybackMetricAttributes.getRecentVideoPlaybackStartedPosition();
        this.getVideoPlaybackMetricAttributes = () => {
            const metrics = this.videoPlaybackMetricAttributes;
            return Object.assign(Object.assign({}, metrics), { trackProgress: convertToMilliseconds(metrics.trackProgress), playbackDurationMilliSeconds: convertToMilliseconds(metrics.playbackDurationMilliSeconds), totalPlaybackDurationMilliSeconds: convertToMilliseconds(metrics.totalPlaybackDurationMilliSeconds) });
        };
        this.initialize = () => {
            this.addEventListener(HTML5VideoPlayerEvent.PLAY, () => {
                this.videoPlaybackMetricAttributes.setRecentPlaybackStartedPositionAndTrackProgress(this.getCurrentTime());
                this.videoPlaybackMetricAttributes.updateLatestPlaybackRequestedTimestamp();
            });
            this.addEventListener(HTML5VideoPlayerEvent.PLAYING, () => {
                this.videoPlaybackMetricAttributes.updateLatestPlaybackTimestampAndIPD();
                this.isCurrentlyPlaying = true;
                this.setTrackProgress();
            });
            this.addEventListener(HTML5VideoPlayerEvent.PAUSE, () => {
                this.updateStateAndMetricsForPause();
            });
            this.addEventListener(HTML5VideoPlayerEvent.ENDED, () => {
                this.videoPlaybackMetricAttributes.incrementPlaybackCount();
                this.isCurrentlyPlaying = false;
                this.setTrackProgress();
            });
            this.addEventListener(HTML5VideoPlayerEvent.ERROR, () => {
                this.updateStateAndMetricsForPause();
            });
            this.addEventListener(HTML5VideoPlayerEvent.WAITING, () => {
                this.updateStateAndMetricsForPause();
            });
        };
        this.load = (playbackUri) => {
            this.initializeValues();
            if (playbackUri) {
                this.setSource(playbackUri);
                this.attachedSource = playbackUri;
            }
            this.loadMedia();
        };
        this.isPlaying = () => this.isCurrentlyPlaying;
        this.play = (playbackUri) => {
            if (playbackUri) {
                this.attachSource(playbackUri);
            }
            this.playMedia();
        };
        this.reload = () => {
            this.pause();
            this.seekTo(0);
            this.load();
        };
        this.setPoster = (posterUrl) => {
            this.videoElement.poster = posterUrl;
        };
        this.seekTo = (position) => {
            this.updateStateAndMetricsForPause();
            this.seekToPosition(position);
            this.setTrackProgress();
        };
        this.unload = () => {
            this.unloadMedia();
            this.initializeValues();
        };
        this.initializeValues = () => {
            this.isCurrentlyPlaying = false;
            this.attachedSource = undefined;
            this.videoPlaybackMetricAttributes = new VideoPlaybackMetricAttributes();
        };
        this.setTrackProgress = () => {
            this.videoPlaybackMetricAttributes.trackProgress = this.getCurrentTime();
        };
        this.updateStateAndMetricsForPause = () => {
            this.isCurrentlyPlaying = false;
            this.videoPlaybackMetricAttributes.updatePlaybackDurationAndTrackProgress(this.getCurrentTime());
        };
        this.videoElement = document.createElement('video');
        this.videoElement.preload = 'auto';
        this.videoElement.controls = false;
        this.videoElement.playsInline = true;
        this.videoElement.disablePictureInPicture = true;
        this.initializeValues();
    }
}
function convertToMilliseconds(seconds) {
    return Math.round(seconds * 1000);
}
