import {
    FOLLOW_PROMPT_MAP,
    HidePromptPreferenceClientState,
    HIDE_PROMPT_PREFERENCE,
} from '.';
import { Entry } from '../appsync/Entry';

export class FollowPrompt {
    public getHidePromptPreferenceClientState(): Entry {
        const preferenceMap = this.getFollowPromptMap();
        return new Entry(
            HIDE_PROMPT_PREFERENCE,
            new HidePromptPreferenceClientState(preferenceMap)
        );
    }

    public getFollowPromptMap() {
        const serializedFollowPromptMap = window.localStorage.getItem(
            FOLLOW_PROMPT_MAP
        );
        if (serializedFollowPromptMap != null) {
            const followPromptMap = JSON.parse(serializedFollowPromptMap!);
            return followPromptMap;
        }
        return {};
    }

    public clearFollowPromptPreferences() {
        window.localStorage.removeItem(FOLLOW_PROMPT_MAP);
    }
}
