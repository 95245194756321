exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._25TLV4_V95MPn3ws4z0MqX{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._25TLV4_V95MPn3ws4z0MqX{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._25TLV4_V95MPn3ws4z0MqX{padding-inline-start:52px;padding-inline-end:52px}}._1Jm0_JPr61ejqEd4CrRJu-{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._1Jm0_JPr61ejqEd4CrRJu-{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._1Jm0_JPr61ejqEd4CrRJu-{margin-inline-start:52px;margin-inline-end:52px}}.BAa2a9l240h3v0PFAs43V{font-size:20px;margin-top:60px}._1AsLEUwgDV0Nz8SqeitqEx{padding-top:72px;padding-inline-start:5%;padding-inline-end:5%;display:block;width:100%;height:calc(100vh - 72px)}._1AsLEUwgDV0Nz8SqeitqEx ._2D_n8tHk1vzIWgOVL3nWaK{font-size:24px;line-height:88px}._1AsLEUwgDV0Nz8SqeitqEx .m-EqfIl6Ss0x3KWm1bWE{color:rgba(255,255,255,.5)}._1AsLEUwgDV0Nz8SqeitqEx ._318yHg1lIC6fh2Ujfjkkr2{font-weight:bold}._1AsLEUwgDV0Nz8SqeitqEx .-Z2uAePc78zS7dhawB5DG{cursor:pointer;transition:background 100ms ease-in;border-bottom:var(--borderBottomWidth) inset rgba(255,255,255,.3);height:8%;min-height:40px;vertical-align:middle;display:flex}._1AsLEUwgDV0Nz8SqeitqEx .-Z2uAePc78zS7dhawB5DG:hover{background-color:rgba(255,255,255,.05)}._1AsLEUwgDV0Nz8SqeitqEx .-Z2uAePc78zS7dhawB5DG:last-child{border:none}._1AsLEUwgDV0Nz8SqeitqEx .-Z2uAePc78zS7dhawB5DG span{margin-top:auto;margin-bottom:auto;white-space:pre-wrap}._1AsLEUwgDV0Nz8SqeitqEx ._253fWUCAo2ZOU4T4XcrxQY{cursor:pointer;transition:background 100ms ease-in;height:auto;vertical-align:middle;display:flex}._1AsLEUwgDV0Nz8SqeitqEx ._253fWUCAo2ZOU4T4XcrxQY:hover{background-color:rgba(255,255,255,.05)}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_25TLV4_V95MPn3ws4z0MqX",
	"gridSides": "_25TLV4_V95MPn3ws4z0MqX",
	"grid-sides-margins": "_1Jm0_JPr61ejqEd4CrRJu-",
	"gridSidesMargins": "_1Jm0_JPr61ejqEd4CrRJu-",
	"no-suggestions-text": "BAa2a9l240h3v0PFAs43V",
	"noSuggestionsText": "BAa2a9l240h3v0PFAs43V",
	"search-suggestion-list": "_1AsLEUwgDV0Nz8SqeitqEx",
	"searchSuggestionList": "_1AsLEUwgDV0Nz8SqeitqEx",
	"search-suggestion-title": "_2D_n8tHk1vzIWgOVL3nWaK",
	"searchSuggestionTitle": "_2D_n8tHk1vzIWgOVL3nWaK",
	"matched": "m-EqfIl6Ss0x3KWm1bWE",
	"non-matched": "_318yHg1lIC6fh2Ujfjkkr2",
	"nonMatched": "_318yHg1lIC6fh2Ujfjkkr2",
	"search-suggestion-text-item": "-Z2uAePc78zS7dhawB5DG",
	"searchSuggestionTextItem": "-Z2uAePc78zS7dhawB5DG",
	"search-suggestion-entity-item": "_253fWUCAo2ZOU4T4XcrxQY",
	"searchSuggestionEntityItem": "_253fWUCAo2ZOU4T4XcrxQY"
};