import { CLOSE_NOTIFICATION, SHOW_NOTIFICATION, } from '../actions/Notification';
import { NOTIFICATION_BANNER_TEMPLATE } from '../types/templates/notification';
export function BannerReducer(state = { banner: null }, action) {
    var _a;
    switch (action.type) {
        case SHOW_NOTIFICATION:
            if (action.payload.notification.interface === NOTIFICATION_BANNER_TEMPLATE) {
                const banner = action.payload.notification;
                return { banner };
            }
            return state;
        case CLOSE_NOTIFICATION:
            if (((_a = state.banner) === null || _a === void 0 ? void 0 : _a.id) === action.payload.id) {
                return { banner: null };
            }
            return state;
        default:
            return state;
    }
}
