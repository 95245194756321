import { ContentTrait, PlaybackMode, UpsellType } from 'src/types/Eligibility';

export class Save {
    public episodeId: string;

    public podcastId: string;

    public podcastTitle: string;

    public title: string;

    public image: string;

    public podcastImage: string;

    public description: string;

    public authors: string;

    public seasonNumber: number;

    public totalDurationMilliseconds: number;

    public isSaved: boolean;

    public updatedTime: number;

    public publishTime: string;

    public playbackMode: PlaybackMode;

    public availableUpsells: UpsellType[];

    public contentTraits: ContentTrait[];

    public availabilityDate: string;

    public podcastShowVariantId: string;

    public podcastEpisodeVariantId: string;

    constructor(save: {
        episodeId?;
        podcastId?;
        podcastTitle?;
        title?;
        image?;
        podcastImage?;
        description?;
        authors?;
        seasonNumber?;
        totalDurationMilliseconds?;
        isSaved?;
        updatedTime?;
        publishTime;
        playbackMode?;
        availableUpsells?;
        contentTraits?;
        availabilityDate?;
        podcastShowVariantId?;
        podcastEpisodeVariantId?;
    }) {
        this.episodeId = save.episodeId;
        this.podcastId = save.podcastId;
        this.podcastTitle = save.podcastTitle;
        this.title = save.title;
        this.image = save.image;
        this.podcastImage = save.podcastImage;
        this.description = save.description;
        this.authors = save.authors;
        this.seasonNumber = save.seasonNumber;
        this.totalDurationMilliseconds = save.totalDurationMilliseconds;
        this.isSaved = save.isSaved;
        this.updatedTime = save.updatedTime;
        this.publishTime = save.publishTime;
        this.playbackMode = save.playbackMode;
        this.availableUpsells = save.availableUpsells;
        this.contentTraits = save.contentTraits;
        this.availabilityDate = save.availabilityDate;
        this.podcastShowVariantId = save.podcastShowVariantId;
        this.podcastEpisodeVariantId = save.podcastEpisodeVariantId;
    }
}
