import { Store } from 'redux';
import { WEB_STORAGE } from '../appsync/AppSyncConstants';
import IApplicationState from '../types/IApplicationState';
import {
    DELETE_COMPLETED_OPERATION,
    SAVE_COMPLETED_OPERATION,
    UPDATE_COMPLETED_OPERATION,
} from './CompletedActions';
import { CompletedOperation } from './CompletedOperation';
import { ICompletedOperationDao } from './ICompletedOperationDao';

export class CompletedOperationDaoSessionStorage
    implements ICompletedOperationDao {
    private store: Store<IApplicationState>;

    constructor(store) {
        this.store = store;
    }

    public insert(completedOperation: CompletedOperation) {
        this.store.dispatch({
            type: SAVE_COMPLETED_OPERATION,
            payload: { completedOperation },
        });
    }

    public getUnProcessed(): CompletedOperation[] {
        const unProcessedOperations: CompletedOperation[] = [];
        for (const [key, value] of Object.entries(WEB_STORAGE)) {
            if (key.endsWith('CompletedOperation')) {
                const completedOperation = JSON.parse(value);
                if (
                    completedOperation &&
                    completedOperation.isProcessing === false
                ) {
                    unProcessedOperations.push(completedOperation);
                }
            }
        }
        return unProcessedOperations;
    }

    public update(ids: string[], isProcessing: boolean) {
        this.store.dispatch({
            type: UPDATE_COMPLETED_OPERATION,
            payload: { ids, isProcessing },
        });
    }

    public delete(ids: string[]) {
        this.store.dispatch({
            type: DELETE_COMPLETED_OPERATION,
            payload: { ids },
        });
    }
}
