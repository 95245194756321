import React, { forwardRef } from 'react';
import ISkyfireIconButtonElement from 'src/types/templates/widgets/ISkyfireIconButtonElement';
import { Button, ContextMenuButton } from 'src/components';
import ISkyfireMethod from '../../../types/ISkyfireMethod';
import ISkyfireTextElement from '../../../types/templates/widgets/ISkyfireTextElement';
import ISkyfireVerticalItem, {
    CIRCLE_VERTICAL_ITEM,
} from '../../../types/templates/widgets/items/ISkyfireVerticalItem';
import { bindHandler, buildTags, preventDefault } from '../../../utils';
import { useObserver } from '../../../utils/ObserverHooks';

interface IVerticalItemProps {
    data: ISkyfireVerticalItem;
    handleSelected: (methods: ISkyfireMethod[]) => void;
    position?: number;
    style?: React.CSSProperties;
    parentSize?: VerticalItemSize;
    constraint?: string;
    role?: string;
}

// eslint-disable-next-line no-shadow
export enum VerticalItemSize {
    Small = 0,
    Medium = 1,
}

const verticalItemSizeProperties: { [key in VerticalItemSize]: string } = {
    [VerticalItemSize.Small]: 'small',
    [VerticalItemSize.Medium]: 'medium',
};

/**
 * Calculates vertical item size based on internal content.
 * @param item vertical item
 */
export function getItemSize(item: ISkyfireVerticalItem): VerticalItemSize {
    return item.secondaryText
        ? VerticalItemSize.Medium
        : VerticalItemSize.Small;
}

function VerticalItem(props: IVerticalItemProps, ref) {
    const {
        image,
        primaryLink,
        secondaryText,
        isDisabled,
        hintIconName,
        actionIconName,
        secondaryLink,
        actionIconLink,
        iconButton,
        tags,
        leftButton,
        primaryText,
        contextMenu,
    } = props.data;
    const { position } = props;

    const bindHandlerHelper = (fn: any) =>
        bindHandler(props.handleSelected, this, fn);

    const primaryTextElement = useObserver(primaryText) as ISkyfireTextElement;
    const iconButtonElement = useObserver(
        iconButton
    ) as ISkyfireIconButtonElement;
    const finalPrimaryText =
        primaryTextElement?.text || primaryTextElement?.text === ''
            ? primaryTextElement.text
            : primaryText;

    const key = finalPrimaryText + (secondaryText || '') + position;

    return (
        <music-vertical-item
            ref={ref}
            key={key}
            data-key={key}
            style={props.style}
            disabled={isDisabled}
            image-src={image}
            onClick={preventDefault}
            primary-text={`${
                position ? `${position}. ` : ''
            }${finalPrimaryText}`}
            primary-href={primaryLink.deeplink}
            kind={props.data.interface === CIRCLE_VERTICAL_ITEM ? 'circle' : ''}
            parentSize={
                verticalItemSizeProperties[
                    props.parentSize || VerticalItemSize.Small
                ]
            }
            hint-icon-name={hintIconName}
            icon-name={iconButtonElement?.icon || actionIconName}
            show-action-button={!!iconButtonElement || !!actionIconLink}
            onmusicPrimaryTextActivate={bindHandlerHelper(
                primaryLink.onItemSelected
            )}
            secondary-text={secondaryText}
            secondary-href={secondaryLink?.deeplink}
            onmusicSecondaryTextActivate={bindHandlerHelper(
                secondaryLink ? secondaryLink.onItemSelected : []
            )}
            onmusicActionButtonActivate={bindHandlerHelper(
                iconButtonElement?.onItemSelected ||
                    (actionIconLink ? actionIconLink.onItemSelected : [])
            )}
        >
            {buildTags(tags, isDisabled)}
            {leftButton && (
                <Button
                    data={leftButton}
                    handleSelected={props.handleSelected}
                    slot='leftButton'
                />
            )}
            {contextMenu && (
                <ContextMenuButton
                    options={contextMenu.options}
                    disabled={contextMenu.disabled}
                    slot='rightButton'
                    variant='primary'
                    iconName='more'
                    onItemSelected={contextMenu.onItemSelected}
                />
            )}
        </music-vertical-item>
    );
}

export default forwardRef(VerticalItem);
