import { useEffect, useCallback } from 'react';
export default function useBackNav(isEnabled, onBackStart) {
    const handleBack = useCallback((e) => {
        if (isEnabled) {
            e === null || e === void 0 ? void 0 : e.preventDefault();
            onBackStart();
            history.go(1);
        }
    }, [onBackStart, isEnabled]);
    useEffect(() => {
        window.addEventListener('popstate', handleBack);
        return () => {
            window.removeEventListener('popstate', handleBack);
        };
    }, [handleBack]);
}
