import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { debounce } from 'debounce';
import { BORDERED_BADGE_ELEMENT } from 'src/types/templates/widgets/ISkyfireBorderedBadgeElement';
import { UNBORDERED_BADGE_ELEMENT } from 'src/types/templates/widgets/ISkyfireUnborderedBadgeElement';
import { LIGHT_UNBORDERED_BADGE_ELEMENT } from 'src/types/templates/widgets/ISkyfireLightUnborderedBadgeElement';
import { ISkyfireDetailHeaderButtonElement } from 'src/types/templates/detail/ISkyfireDetailHeaderButtonElement';
import { ACCENT_OUTLINE_BADGE_ELEMENT } from 'src/types/templates/widgets/ISkyfireAccentOutlineBadgeElement';
import { parseOrStripEncodedHtml } from 'src/utils/textParsingHelpers';
import {
    ContextMenuButton,
    RefinementHeader,
    BackgroundImage,
    Button,
} from '../components';
import ISkyfireMethod from '../types/ISkyfireMethod';
import {
    preventDefault,
    dispatchSkyfireMethods,
    useObserver,
    dispatchTemplateRendered,
} from '../utils';
import {
    BookmarkDaoSessionStorage,
    BookMarkOperationDaoSessionStorage,
    BookmarkSyncData,
} from '../Bookmark';
import Styles from './View.scss';
import { ISkyfirePodcastCompactDetailTemplate } from '../types/templates/compactDetail/index';
import DetailPlayButton from '../components/widgets/items/DetailPlayButton';
import { WidgetList } from '../components/widgets/WidgetList';
import { toSentenceCase } from '../components/widgets/items/Button';
import { CompactPillNavigatorWidget } from '../components/widgets/items/CompactPillNavigatorWidget';

interface IDetailProps {
    template: ISkyfirePodcastCompactDetailTemplate;
}

export function PodcastCompactDetail(props: IDetailProps) {
    const lgBreakpoint = 640;
    const store = useStore();
    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);
    const [widgets, setWidgets] = useState(props.template.widgets);
    const [playButtonState, setPlayButtonState] = useState('playIcon');
    const {
        headerImage,
        backgroundImage,
        contextMenu,
        headerText,
        headerButtons,
        headerPrimaryText,
        headerSecondaryText,
        headerTertiaryText,
        headerPrimaryTextLink,
        headerSecondaryTextLink,
        headerLabel,
        id,
        updatedAt,
        refinementHeader,
        description,
        isSmallHeaderCentered,
        badge,
        button,
        text,
        playButton,
        bookmarkElement,
        compactPillNavigator,
    } = props.template;

    const [progressMilliseconds, setProgressMilliseconds] = useState(
        progressInMillisecondsBookmark(store, bookmarkElement?.id)
    );

    const [totalDurationMilliseconds, setTotalDurationMilliseconds] = useState(
        bookmarkElement?.totalDurationMilliseconds
    );

    useEffect(() => {
        let player;
        async function progress() {
            player = await window.maestro.getInstance();
            player.addEventListener('timeupdate', onTimeupdate);
        }
        setProgressMilliseconds(
            progressInMillisecondsBookmark(store, bookmarkElement?.id)
        );
        setTotalDurationMilliseconds(
            bookmarkElement?.totalDurationMilliseconds
        );

        function onTimeupdate(time: number) {
            if (playButtonState === 'pauseIcon' && player?.getDuration() > 0) {
                setProgressMilliseconds(Math.floor(time * 1000));
            }
        }
        progress();
        return () => {
            player?.removeEventListener('timeupdate', onTimeupdate);
        };
    }, [playButtonState]);

    useEffect(() => {
        dispatchTemplateRendered(store.dispatch, props.template, 0);
        dispatchSkyfireMethods(
            dispatch,
            props.template,
            props.template.onViewed
        );
        window?.scrollTo?.(0, 0);
        const debouncedResize = debounce(() => {
            setWidth(window.innerWidth);
        }, 10);
        window.addEventListener('resize', debouncedResize);
        return () => {
            window.removeEventListener('resize', debouncedResize);
            dispatchSkyfireMethods(
                dispatch,
                props.template,
                props.template.onLeave
            );
        };
    }, [props.template.id]);

    useEffect(() => {
        if (props.template.widgets?.length) {
            setWidgets(props.template.widgets.slice(0, 1));
        }
    }, [props.template.widgets]);

    const headerTextElement = useObserver(headerText);
    const bottomMargin = '20px';
    return (
        <div className={Styles['view-content']}>
            {refinementHeader && (
                <RefinementHeader
                    isOpened={true}
                    text={refinementHeader.text}
                    refinementOptions={refinementHeader.refinementOptions}
                    isActive={refinementHeader.isActive}
                    isDisabled={refinementHeader.isDisabled}
                />
            )}
            <BackgroundImage src={backgroundImage} />
            <music-detail-header
                key={headerImage}
                style={{
                    contain: 'layout',
                    marginTop: '40px',
                    marginBottom: bottomMargin,
                    display: 'block',
                }}
                image-src={headerImage}
                label={headerLabel}
                headline={headerTextElement.text ?? headerText ?? ''}
                primary-text={headerPrimaryText}
                primary-text-href={headerPrimaryTextLink?.deeplink}
                onmusicPrimaryTextActivate={handleSelected.bind(
                    this,
                    headerPrimaryTextLink?.onItemSelected
                )}
                secondary-text={headerSecondaryText}
                secondary-text-href={headerSecondaryTextLink?.deeplink}
                onmusicSecondaryTextActivate={handleSelected.bind(
                    this,
                    headerSecondaryTextLink?.onItemSelected
                )}
                tertiary-text={headerTertiaryText}
                onClick={preventDefault}
                isSmallHeaderCentered={false}
                lastHeaderTextElement='tertiaryHeadline'
                upsellButtonText={toSentenceCase(button?.text)}
                upsellBadgeText={badge?.text}
                upsellText={text}
                onupsellButtonActivate={handleSelected.bind(
                    this,
                    button?.onItemSelected
                )}
                onupsellBadgeActivate={handleSelected.bind(
                    this,
                    badge?.onItemSelected
                )}
                enableBadgeClick={!!badge?.onItemSelected.length}
                upsellBadgeTier={getUpsellBadgeTier()}
                shouldUnderlineSecondary={true}
            >
                {renderButtons()}
                {contextMenu && (
                    <ContextMenuButton
                        options={contextMenu.options}
                        disabled={contextMenu.disabled}
                        slot='icons'
                        variant='glass'
                        iconName='more'
                        size='small'
                    />
                )}
            </music-detail-header>
            {description?.length ? (
                <div
                    className={`${Styles.description} true`}
                    style={{
                        marginBottom: '0px',
                    }}
                >
                    {parseOrStripEncodedHtml(description)}
                </div>
            ) : (
                <div />
            )}
            {compactPillNavigator?.items?.length ? (
                <CompactPillNavigatorWidget
                    data={compactPillNavigator}
                    handleSelected={handleSelected}
                    bottomMargin={false}
                />
            ) : (
                <div />
            )}
            {widgets?.length ? (
                <music-container id={id} key={id} onrendered={widgetsRendered}>
                    <div>
                        <WidgetList
                            list={widgets}
                            handleSelected={handleSelected}
                        />
                    </div>
                </music-container>
            ) : (
                <div />
            )}
        </div>
    );

    function renderButtons() {
        let allHeaderButtons = [...headerButtons];
        if (playButton) {
            if (width > lgBreakpoint) {
                allHeaderButtons = [
                    playButton as ISkyfireDetailHeaderButtonElement,
                    ...allHeaderButtons,
                ];
            } else {
                allHeaderButtons = [
                    ...allHeaderButtons,
                    playButton as ISkyfireDetailHeaderButtonElement,
                ];
            }
        }
        const updatedHeaderButtons = allHeaderButtons.map(
            (headerButton, idx) => {
                if (headerButton.icon === 'play') {
                    return (
                        <div className={Styles.playButton}>
                            <DetailPlayButton
                                playButtonStateSetter={setPlayButtonState}
                                button={headerButton}
                                id={`detailHeaderButton${idx + 1}`}
                                handleSelected={handleSelected}
                                progressMilliseconds={
                                    progressMilliseconds >
                                    totalDurationMilliseconds
                                        ? totalDurationMilliseconds
                                        : progressMilliseconds
                                }
                                totalDurationMilliseconds={
                                    totalDurationMilliseconds
                                }
                            />
                        </div>
                    );
                }
                return (
                    <Button
                        data={headerButton}
                        handleSelected={handleSelected}
                        id={`detailHeaderButton${idx + 1}`}
                        slot='icons'
                        size={headerButton.iconOnly ? 'small' : 'medium'}
                        variant='primary'
                    />
                );
            }
        );
        return (
            <div slot='icons' className={Styles.headerButtonsContainer}>
                {updatedHeaderButtons}
            </div>
        );
    }

    function progressInMillisecondsBookmark(
        storage,
        identifier: string
    ): number {
        if (identifier) {
            const bookmarkSyncData = new BookmarkSyncData(
                new BookmarkDaoSessionStorage(storage),
                new BookMarkOperationDaoSessionStorage(storage),
                storage
            );
            const bookmark = bookmarkSyncData.readWithBounds(identifier);
            if (bookmark && bookmark.progressMilliSeconds > 0) {
                return bookmark.progressMilliSeconds;
            }
        }
        return 0;
    }

    function handleSelected(onItemSelected: ISkyfireMethod[]) {
        if (!onItemSelected) {
            return;
        }
        dispatchSkyfireMethods(dispatch, props.template, onItemSelected);
    }

    function widgetsRendered(event) {
        if (event.target.id === props.template.id) {
            dispatchTemplateRendered(
                store.dispatch,
                props.template,
                event.detail
            );
        }
    }

    function getUpsellBadgeTier() {
        switch (badge?.interface) {
            case BORDERED_BADGE_ELEMENT:
                return 'availableAdFree';
            case UNBORDERED_BADGE_ELEMENT:
                return 'adFree';
            case LIGHT_UNBORDERED_BADGE_ELEMENT:
                return 'exclusive';
            case ACCENT_OUTLINE_BADGE_ELEMENT:
                return 'primeTierExclusive';
            default:
                return 'adFree';
        }
    }
}
