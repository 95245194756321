import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchSkyfireMethods } from '../utils';
import { useInView } from '../utils/useItemsInView';
import * as Styles from './Notification.scss';
import { getDeeplink } from '../utils/getDeeplink';
import { RWP_BROWSER_DEVICE_TYPES } from '../utils/deviceTypes';
import { globals } from '../utils/globals';
import { NOW_PLAYING_TEMPLATE } from '../types/templates/nowPlaying';
const nativeAppImageSrc = 'https://d5fx445wy2wpk.cloudfront.net/static/logo_native_app.png';
export default function Tooltip(props) {
    useStyles(Styles);
    const dispatch = useDispatch();
    const tooltip = useSelector((state) => state.Tooltip.tooltip);
    const mediaId = useSelector((state) => state.Media.mediaId);
    const overlayTemplates = useSelector((state) => state.TemplateStack.overlayTemplates);
    const isSearchBoxFocused = useSelector((state) => state.SearchSuggestions.isSearchBoxFocused);
    const mShopBottomOverride = useSelector((state) => state.RWP.mShopBottomOverride);
    const enableMShopBottomOverride = useSelector((state) => state.RWP.enableMShopBottomOverride);
    const isRetailNavbarInView = useSelector((state) => state.RWP.isRWPRetailNavbarInview);
    const windowWidth = useSelector((state) => state.BrowserState.windowWidth);
    const [bottomPosition, setBottomPosition] = React.useState(null);
    const isSkipLimitTooltip = (tooltip === null || tooltip === void 0 ? void 0 : tooltip.id) === 'skip-limit-tooltip';
    const { isNowPlaying } = props;
    const hasOverlay = overlayTemplates.length > 0;
    // Added condtion to suppress the tooltip for the case when the overlayTemplate stack
    // conatins other templates above NPS.
    const hideTooltipForOverlays = hasOverlay &&
        (!isNowPlaying ||
            overlayTemplates.findIndex((template) => template.interface === NOW_PLAYING_TEMPLATE) >
                0);
    const closeCallback = useCallback((event) => {
        if (!tooltip)
            return;
        event === null || event === void 0 ? void 0 : event.preventDefault();
        const { onItemSelected } = tooltip.closeButton.primaryLink;
        dispatchSkyfireMethods(dispatch, props.template, onItemSelected);
    }, [dispatch, tooltip]);
    const secondaryActionButtonCallback = useCallback((event) => {
        if (!(tooltip === null || tooltip === void 0 ? void 0 : tooltip.secondaryActionButton))
            return;
        event === null || event === void 0 ? void 0 : event.preventDefault();
        const { onItemSelected } = tooltip === null || tooltip === void 0 ? void 0 : tooltip.secondaryActionButton.primaryLink;
        dispatchSkyfireMethods(dispatch, props.template, onItemSelected);
    }, [dispatch, tooltip]);
    const actionButtonCallback = useCallback((event) => {
        if (!tooltip)
            return;
        event.preventDefault();
        const { onItemSelected } = tooltip.actionButton.primaryLink;
        dispatchSkyfireMethods(dispatch, props.template, onItemSelected);
    }, [dispatch, tooltip]);
    useEffect(() => {
        if (!tooltip)
            return () => { };
        const timer = setTimeout(() => {
            closeCallback();
        }, tooltip.timeoutMilliseconds);
        return () => clearTimeout(timer);
    }, [tooltip]);
    const onViewed = useCallback(() => {
        if (tooltip === null || tooltip === void 0 ? void 0 : tooltip.onViewed) {
            dispatchSkyfireMethods(dispatch, props.template, tooltip === null || tooltip === void 0 ? void 0 : tooltip.onViewed);
        }
    }, [dispatch, tooltip === null || tooltip === void 0 ? void 0 : tooltip.onViewed]);
    const callbackRef = useInView(onViewed);
    /**
     * For iOS mShop adjust the tooltip bottom position because of the presence of floating
     * bottom navbar which has different height on iOS.
     */
    useEffect(() => {
        if (tooltip && enableMShopBottomOverride && (tooltip === null || tooltip === void 0 ? void 0 : tooltip.variant) !== 'TopLeft') {
            const TRANSPORT_HEIGHT = 100;
            const NOW_PLAYING_TRANSPORT_HEIGHT = 96;
            const LARGE_TRANSPORT_HEIGHT = 140;
            const WINDOW_WIDTH_MD_MAX = 639;
            const WINDOW_WIDTH_SM_MAX = 479;
            let existingBottomPosition = 10;
            if (isNowPlaying && windowWidth <= WINDOW_WIDTH_SM_MAX && isSkipLimitTooltip) {
                existingBottomPosition = 160;
            }
            else if ((isNowPlaying || isSkipLimitTooltip) && windowWidth <= WINDOW_WIDTH_MD_MAX) {
                existingBottomPosition = LARGE_TRANSPORT_HEIGHT;
            }
            else if (isNowPlaying || isSkipLimitTooltip) {
                existingBottomPosition = NOW_PLAYING_TRANSPORT_HEIGHT;
            }
            else if (mediaId) {
                existingBottomPosition = TRANSPORT_HEIGHT;
            }
            setBottomPosition(existingBottomPosition + mShopBottomOverride);
        }
        else {
            setBottomPosition(null);
        }
    }, [mediaId, isNowPlaying, enableMShopBottomOverride, windowWidth, mShopBottomOverride]);
    if (!tooltip || hideTooltipForOverlays || isSearchBoxFocused) {
        return null;
    }
    const getTooltipClassName = () => {
        if ((tooltip === null || tooltip === void 0 ? void 0 : tooltip.variant) === 'TopLeft') {
            return Styles.topLeftTooltip;
        }
        return Styles.tooltip;
    };
    const getTooltipPlacement = () => {
        if ((tooltip === null || tooltip === void 0 ? void 0 : tooltip.variant) === 'TopLeft') {
            return 'top-left';
        }
        return 'bottom';
    };
    const getTooltipProps = () => {
        if (isRetailNavbarInView && (tooltip === null || tooltip === void 0 ? void 0 : tooltip.variant) === 'TopLeft') {
            return { style: { position: 'absolute' } };
        }
        if (bottomPosition) {
            return { style: { bottom: bottomPosition } };
        }
        return {};
    };
    const actionButton = tooltip.actionButton && (React.createElement("music-button", { size: "small", href: getDeeplink(tooltip.actionButton.primaryLink.deeplink), onClick: actionButtonCallback, slot: "buttons", variant: "solid" }, tooltip.actionButton.text));
    const secondaryActionButton = tooltip.secondaryActionButton && (React.createElement("music-button", { size: "small", href: getDeeplink(tooltip.secondaryActionButton.primaryLink.deeplink), onClick: secondaryActionButtonCallback, slot: "buttons", variant: "outline" }, tooltip.secondaryActionButton.text));
    const image = tooltip.id === 'native-app-upsell' ? nativeAppImageSrc : null;
    const isSamplePlaybackTooltip = tooltip.id === 'sample-playback-tooltip';
    const { deviceType } = globals.amznMusic.appConfig;
    return (React.createElement("music-tooltip", Object.assign({ ref: callbackRef, id: tooltip.id, "primary-text": tooltip.message, "secondary-text": tooltip.title, className: [
            getTooltipClassName(),
            isSkipLimitTooltip ? Styles.skipLimitTooltip : null,
            'hydrated',
            mediaId ? Styles.media : null,
            isNowPlaying ? Styles.nowPlayingView : null,
            isSamplePlaybackTooltip ? Styles.samplePlaybackZIndexOverride : '',
            RWP_BROWSER_DEVICE_TYPES.includes(deviceType) ? Styles.rwpZIndexOverride : '',
        ].join(' '), kind: "accent", closable: !!(tooltip === null || tooltip === void 0 ? void 0 : tooltip.closeButton), placement: getTooltipPlacement(), onmusicTooltipCloseButtonActivate: closeCallback, "image-src": image }, getTooltipProps()),
        secondaryActionButton,
        actionButton));
}
