export default class SamplingPlayer {
    constructor(samplePlayerLoaderMethod) {
        this._listeners = this.getDefaultListeners();
        this._listenersHandler = this.getEventsHandler();
        this.loadCurrentPlayer = async () => {
            await this._samplePlayerLoader;
        };
        this.setSamplePlayerConfig = (config) => { var _a; return (_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.setConfig(config); };
        this.addEventListener = (eventName, callback) => {
            if (this._listeners[eventName]) {
                this._listeners[eventName].push(callback);
            }
        };
        this.removeEventListener = (eventName, callback) => {
            if (this._listeners[eventName]) {
                this._listeners[eventName].splice(this._listeners[eventName].indexOf(callback), 1);
            }
        };
        this.unload = () => { var _a; return (_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.pause(); };
        this.load = (playbackUri, ...args) => {
            var _a;
            if (!this._samplePlayer) {
                throw new Error();
            }
            return (_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.load(playbackUri, ...args);
        };
        this.getCurrentTrackId = () => { var _a; return (_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.getCurrentTrackId(); };
        this.play = (mediaId, ...args) => {
            var _a;
            (_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.play(mediaId, ...args);
        };
        this.pause = () => {
            var _a;
            (_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.pause();
        };
        this.resume = () => {
            var _a;
            (_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.resume();
        };
        this.isPlaying = () => { var _a; return ((_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.isPlaying()) || false; };
        this.isPaused = () => {
            if (this._samplePlayer) {
                return this._samplePlayer.isPaused();
            }
            // Player is pause if _samplePlayer does not exist
            return true;
        };
        this.getDuration = () => { var _a; return ((_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.getDuration()) || 0; };
        this.getCurrentTime = () => { var _a; return ((_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.getCurrentTime()) || 0; };
        this.getBufferedTime = () => { var _a; return ((_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.getBufferedTime()) || 0; };
        this.getVolume = () => { var _a; return ((_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.getVolume()) || 0; };
        this.getConfig = () => { var _a; return (_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.getConfig(); };
        // in seconds
        this.seekTo = async (position) => {
            var _a;
            (_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.seekTo(position);
        };
        this.volume = async (volume) => { var _a; return (_a = this._samplePlayer) === null || _a === void 0 ? void 0 : _a.volume(volume); };
        this._samplePlayerLoaderMethod = samplePlayerLoaderMethod;
        this.initiateSamplePlayerLoading();
    }
    initiateSamplePlayerLoading() {
        if (!this._samplePlayerLoader) {
            this._samplePlayerLoader = this._samplePlayerLoaderMethod()
                .then((player) => {
                this._samplePlayer = player;
                this.attachListeners(this._samplePlayer);
                return player;
            })
                .catch(() => undefined);
        }
    }
    attachListeners(player) {
        Object.keys(this._listeners).forEach((eventName) => {
            player.addEventListener(eventName, this._listenersHandler[eventName]);
        });
    }
    getEventsHandler() {
        const getEventHander = (eventName) => (...args) => {
            this._listeners[eventName].forEach((callback) => callback(...args));
        };
        return Object.keys(this._listeners).reduce((initial, eventName) => {
            // eslint-disable-next-line no-param-reassign
            initial[eventName] = getEventHander(eventName);
            return initial;
        }, {});
    }
    getDefaultListeners() {
        return {
            buffertime: [],
            started: [],
            ended: [],
            error: [],
            playpause: [],
            canplay: [],
        };
    }
}
