exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._297gRwnmwD72ba6cVwtgVX,._1M-4BZLxS3pY1_S27O2tFo{padding-inline-start:20px;padding-inline-end:20px}@media only screen and (min-width: 480px){._297gRwnmwD72ba6cVwtgVX,._1M-4BZLxS3pY1_S27O2tFo{padding-inline-start:36px;padding-inline-end:36px}}@media only screen and (min-width: 1280px){._297gRwnmwD72ba6cVwtgVX,._1M-4BZLxS3pY1_S27O2tFo{padding-inline-start:52px;padding-inline-end:52px}}._2dYKWOrFVOgLkP1DPTrVll{margin-inline-start:20px;margin-inline-end:20px}@media only screen and (min-width: 480px){._2dYKWOrFVOgLkP1DPTrVll{margin-inline-start:36px;margin-inline-end:36px}}@media only screen and (min-width: 1280px){._2dYKWOrFVOgLkP1DPTrVll{margin-inline-start:52px;margin-inline-end:52px}}._1M-4BZLxS3pY1_S27O2tFo{display:flex;flex-direction:column}._1wrOnA0HzMysL0eqL-Xxna{margin-top:var(--music-spacer-large)}._27SoNzyr3sfXod6NkjSb08{margin-top:var(--music-spacer-large);display:flex;gap:10px}", ""]);

// exports
exports.locals = {
	"navbarHeight": "72px",
	"grid-sides": "_297gRwnmwD72ba6cVwtgVX",
	"gridSides": "_297gRwnmwD72ba6cVwtgVX",
	"image-group-widget": "_1M-4BZLxS3pY1_S27O2tFo",
	"imageGroupWidget": "_1M-4BZLxS3pY1_S27O2tFo",
	"grid-sides-margins": "_2dYKWOrFVOgLkP1DPTrVll",
	"gridSidesMargins": "_2dYKWOrFVOgLkP1DPTrVll",
	"image-group-header": "_1wrOnA0HzMysL0eqL-Xxna",
	"imageGroupHeader": "_1wrOnA0HzMysL0eqL-Xxna",
	"image-group-container": "_27SoNzyr3sfXod6NkjSb08",
	"imageGroupContainer": "_27SoNzyr3sfXod6NkjSb08"
};