// eslint-disable-next-line no-shadow
export var WINDOW_SIZE_ENUM;
(function (WINDOW_SIZE_ENUM) {
    WINDOW_SIZE_ENUM[WINDOW_SIZE_ENUM["XS"] = 0] = "XS";
    WINDOW_SIZE_ENUM[WINDOW_SIZE_ENUM["SM"] = 360] = "SM";
    WINDOW_SIZE_ENUM[WINDOW_SIZE_ENUM["MD"] = 480] = "MD";
    WINDOW_SIZE_ENUM[WINDOW_SIZE_ENUM["LG"] = 640] = "LG";
    WINDOW_SIZE_ENUM[WINDOW_SIZE_ENUM["XL"] = 840] = "XL";
    WINDOW_SIZE_ENUM[WINDOW_SIZE_ENUM["XL2"] = 1024] = "XL2";
    WINDOW_SIZE_ENUM[WINDOW_SIZE_ENUM["XL3"] = 1280] = "XL3";
    WINDOW_SIZE_ENUM[WINDOW_SIZE_ENUM["XL4"] = 1440] = "XL4";
    WINDOW_SIZE_ENUM[WINDOW_SIZE_ENUM["TV"] = 2260] = "TV";
})(WINDOW_SIZE_ENUM || (WINDOW_SIZE_ENUM = {}));
