import ISkyfireMethod from '../../ISkyfireMethod';
import ISkyfireVisualRowItem from './items/ISkyfireVisualRowItem';

export const VISUAL_TABLE_WIDGET =
    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.VisualTableWidgetElement';

export default interface ISkyfireVisualTableWidget {
    interface: typeof VISUAL_TABLE_WIDGET;
    items: ISkyfireVisualRowItem[];
    onEndOfWidget: ISkyfireMethod[];
    onTrackReorder: ISkyfireMethod[];
    onViewed: ISkyfireMethod[];
    uuid?: string;
}
