import { Middleware } from 'redux';
import {
    BookmarkDaoSessionStorage,
    BookMarkOperationDaoSessionStorage,
    BookmarkSyncData,
} from '../Bookmark';
import {
    BUFFER,
    SKIP_FORWARD,
    PLAY,
    PLAY_NEXT,
    PLAY_PREVIOUS,
    SKIP_BACKWARD,
    SET_PLAYBACK_SPEED,
    SET_SLEEP_TIMER,
    ERROR,
    PLAYBACK_NOT_SUPPORTED,
    SET_PLAYBACK_START_OFFSET,
    SET_PRE_SCRUB_POSITION,
    SET_PRE_SCRUB_START_OFFSET,
    RESUMED,
    SCRUBBED,
    PRE_SCRUBBED,
    POST_SCRUBBED,
    RESTORE_MEDIA,
    STORE_RESTORE_TOKEN,
    RESTORE_PLAYBACK,
} from '../actions/Playback';
import {
    SHOW_SESSION_RIBBON,
    SHOW_NOTIFICATION,
} from '../Notification/NotificationConstants';
import { dispatchPlaybackMethods } from '../utils/dispatchPlaybackMethods';
import { CURRENTLY_PLAYING_GROUP } from './LocalHydrationMiddleware';
import { CLEAR_STORAGE } from '../actions/StorageConstants';
import { INVOKE_HTTP_POST, ISkyfireInvokeHttpPostMethod } from '../types';
import { SINGLE_THREADED_QUEUE } from '../utils/skyfireContants';
import { getPodcastPlaybackEnvironment } from '../utils';
import { RESTORE_PODCAST_PLAYBACK_WEB_SKILL } from '../utils/podcastTargets';
import ISkyfireMethod from '../types/ISkyfireMethod';
import { addEscapedBackslashToDoubleQuotes } from '../utils/textParsingHelpers';

let player;
const mediaMap = {};

export const PodcastPlaybackMiddleware: Middleware = (store) => (
    nextAction
) => async (action) => {
    const { onPlaybackNotSupported } = store.getState().Media;
    const auth = store.getState().Authentication;
    switch (action.type) {
        case BUFFER:
        case RESTORE_MEDIA:
            try {
                player = await window.maestro.getInstance();
                window.maestro.setPlayerConfig(player, auth);
                mediaMap[action.payload.mediaId] = action.payload.audioUri;
                const progress = getPodcastProgress(action, store);
                player.load(action.payload.mediaId, { position: progress });
                store.dispatch({
                    type: SET_PLAYBACK_START_OFFSET,
                    payload: {
                        playbackStartOffset: Math.round(progress * 1000),
                    },
                });
            } catch {
                dispatchPlaybackMethods(
                    store.dispatch,
                    action.payload.mediaId,
                    onPlaybackNotSupported
                );
            }
            break;
        case STORE_RESTORE_TOKEN: {
            let { restoreToken } = action.payload;
            restoreToken = addEscapedBackslashToDoubleQuotes(restoreToken);
            localStorage.setItem('restoreToken', restoreToken);
            break;
        }
        case RESTORE_PLAYBACK: {
            const restoreToken: string | null = localStorage.getItem(
                'restoreToken'
            );
            if (restoreToken) {
                const invokeRestorePlayback: ISkyfireInvokeHttpPostMethod = {
                    interface: INVOKE_HTTP_POST,
                    queue: {
                        interface: SINGLE_THREADED_QUEUE,
                        id: 'PLAYBACK',
                    },
                    forced: false,
                    before: [],
                    after: [],
                    onError: [],
                    url: getPodcastPlaybackEnvironment(),
                    preset: `{"restoreToken":"${restoreToken}"}`,
                    target: RESTORE_PODCAST_PLAYBACK_WEB_SKILL,
                    extra: {},
                };
                const restorePlaybackMethods: ISkyfireMethod[] = [
                    invokeRestorePlayback,
                ];
                dispatchPlaybackMethods(
                    store.dispatch,
                    'RESTORE_PLAYBACK',
                    restorePlaybackMethods
                );
            }
            break;
        }
        case PLAY:
            try {
                player = await window.maestro.getInstance();
                const episodeId = mediaMap[action.payload.mediaId];
                const progress = progressInMillisecondsBookmark(
                    store,
                    episodeId
                );
                if (progress > 0) {
                    player.play(action.payload.mediaId, progress);
                } else {
                    player.play(action.payload.mediaId);
                }
                store.dispatch({
                    type: SET_PLAYBACK_START_OFFSET,
                    payload: {
                        playbackStartOffset: Math.round(progress * 1000),
                    },
                });
                const { CURRENTLY_PLAYING_EPISODE } =
                    store.getState().Storage[CURRENTLY_PLAYING_GROUP] || {};
                if (CURRENTLY_PLAYING_EPISODE !== episodeId) {
                    store.dispatch({
                        type: CLEAR_STORAGE,
                        payload: {
                            group: CURRENTLY_PLAYING_GROUP,
                        },
                    });
                }
            } catch {
                store.dispatch({ type: PLAYBACK_NOT_SUPPORTED });
                dispatchPlaybackMethods(
                    store.dispatch,
                    action.payload.mediaId,
                    onPlaybackNotSupported
                );
            }
            break;
        case PLAY_NEXT: {
            if (
                !('mediaId' in action?.payload) ||
                action?.payload?.mediaId?.includes(player?.getCurrentTrackId())
            ) {
                const state = store.getState().PlaybackStates.play?.state;
                if (state === 'PLAYING') {
                    dispatchPlaybackMethods(
                        store.dispatch,
                        action.payload.mediaId,
                        store.getState().PlaybackEvents.onStopped
                    );
                }
            }
            break;
        }
        case PLAY_PREVIOUS: {
            store.dispatch({
                type: SET_PLAYBACK_START_OFFSET,
                payload: {
                    playbackStartOffset: 0,
                },
            });
            if (
                !('mediaId' in action?.payload) ||
                action?.payload?.mediaId?.includes(player?.getCurrentTrackId())
            ) {
                const state = store.getState().PlaybackStates.play?.state;
                if (state === 'PLAYING') {
                    dispatchPlaybackMethods(
                        store.dispatch,
                        action.payload.mediaId,
                        store.getState().PlaybackEvents.onStopped
                    );
                }
            }
            break;
        }
        case SKIP_FORWARD: {
            const { skipForward } = store.getState().PlaybackStates;
            const durationSeconds =
                player?.getDuration() !== 0
                    ? player?.getDuration()
                    : store.getState().Media.durationSeconds;

            if (!skipForward) {
                break;
            }

            if (skipForward?.isDisabled) {
                dispatchPlaybackMethods(
                    store.dispatch,
                    action.payload.mediaId,
                    skipForward.onDisabledSkipForwardControlElementSelected
                );
            } else if (player && durationSeconds) {
                const currentTime = player.getCurrentTime();
                const seekTime =
                    currentTime > durationSeconds - 30
                        ? durationSeconds
                        : currentTime + 30;
                store.dispatch({
                    type: PRE_SCRUBBED,
                    payload: {
                        mediaId: action.payload.mediaId,
                        preScrubbedPosition: currentTime,
                    },
                });
                player.seekTo(seekTime);
                store.dispatch({
                    type: SET_PLAYBACK_START_OFFSET,
                    payload: {
                        playbackStartOffset: Math.round(seekTime * 1000),
                    },
                });
                store.dispatch({
                    type: SCRUBBED,
                    payload: {
                        position: seekTime,
                        mediaId: action.payload.mediaId,
                    },
                });
            }
            break;
        }
        case SKIP_BACKWARD: {
            const { skipBackward } = store.getState().PlaybackStates;
            if (!skipBackward) {
                break;
            }
            if (skipBackward?.isDisabled) {
                dispatchPlaybackMethods(
                    store.dispatch,
                    action.payload.mediaId,
                    skipBackward.onDisabledSkipBackwardControlStateElementSelected
                );
            } else if (player) {
                const currentTime = player.getCurrentTime();
                const seekTime = currentTime < 15 ? 0 : currentTime - 15;
                store.dispatch({
                    type: PRE_SCRUBBED,
                    payload: {
                        mediaId: action.payload.mediaId,
                        preScrubbedPosition: currentTime,
                    },
                });
                player.seekTo(seekTime);
                store.dispatch({
                    type: SET_PLAYBACK_START_OFFSET,
                    payload: {
                        playbackStartOffset: Math.round(seekTime * 1000),
                    },
                });
                store.dispatch({
                    type: SCRUBBED,
                    payload: {
                        position: seekTime,
                        mediaId: action.payload.mediaId,
                    },
                });
            }
            break;
        }
        case SET_PLAYBACK_SPEED: {
            const { playbackSpeed } = store.getState().PlaybackStates;
            if (!playbackSpeed) {
                break;
            }
            if (playbackSpeed?.isDisabled) {
                dispatchPlaybackMethods(
                    store.dispatch,
                    action.payload.mediaId,
                    playbackSpeed.onDisabledPlaybackSpeedControlElementSelected
                );
            }
            break;
        }
        case SET_SLEEP_TIMER: {
            const { sleepTimer } = store.getState().PlaybackStates;
            if (!sleepTimer) {
                break;
            }
            if (sleepTimer?.isDisabled) {
                dispatchPlaybackMethods(
                    store.dispatch,
                    action.payload.mediaId,
                    sleepTimer.onDisabledSleepTimerControlElementSelected
                );
            }
            break;
        }
        case ERROR:
            try {
                const id = player?.getCurrentTrackId();
                if (id && player?.isCached(id)) {
                    player?.remove(id);
                }
            } catch {
                break;
            }
            break;
        case RESUMED:
            if (
                !('mediaId' in action?.payload) ||
                action?.payload?.mediaId?.includes(player?.getCurrentTrackId())
            ) {
                store.dispatch({
                    type: SET_PLAYBACK_START_OFFSET,
                    payload: {
                        playbackStartOffset: Math.round(
                            player?.getCurrentTime() * 1000
                        ),
                    },
                });
            }
            break;
        case SCRUBBED: {
            const { scrub } = store.getState().PlaybackStates;
            if (!scrub) {
                break;
            }
            if (!scrub.isDisabled) {
                store.dispatch({
                    type: SET_PLAYBACK_START_OFFSET,
                    payload: {
                        playbackStartOffset: Math.round(
                            action.payload.position * 1000
                        ),
                    },
                });
                store.dispatch({
                    type: POST_SCRUBBED,
                    payload: {
                        mediaId: action.payload.mediaId,
                    },
                });
            }
            break;
        }
        case PRE_SCRUBBED: {
            const playbackEvents = store.getState().PlaybackEvents;
            let preScrubStartOffset: number;
            if (player?.isPlaying()) {
                preScrubStartOffset = store.getState().PodcastState
                    .playbackStartOffset;
            } else {
                preScrubStartOffset = action.payload.preScrubbedPosition * 1000;
            }
            store.dispatch({
                type: SET_PRE_SCRUB_START_OFFSET,
                payload: {
                    startOffset: Math.round(preScrubStartOffset),
                },
            });
            store.dispatch({
                type: SET_PRE_SCRUB_POSITION,
                payload: {
                    position: Math.round(
                        action.payload.preScrubbedPosition * 1000
                    ),
                },
            });
            dispatchPlaybackMethods(
                store.dispatch,
                action.payload.mediaId,
                playbackEvents.onPreScrubbed
            );
            break;
        }
        case POST_SCRUBBED: {
            const playbackEvents = store.getState().PlaybackEvents;
            dispatchPlaybackMethods(
                store.dispatch,
                action.payload.mediaId,
                playbackEvents.onPostScrubbed
            );
            break;
        }
        case SHOW_SESSION_RIBBON: {
            const ribbon = action.payload?.ribbon;
            const sessionRibbonId = `${ribbon?.id} SessionRibbon`;
            if (sessionStorage.getItem(sessionRibbonId) !== sessionRibbonId) {
                sessionStorage.setItem(sessionRibbonId, sessionRibbonId);
                store.dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        notification: action.payload.ribbon,
                    },
                });
            }
            break;
        }
        default:
            break;
    }
    nextAction(action);
};

function getPodcastProgress(action: any, store) {
    if (
        action.payload.progressInMilliSeconds &&
        action.payload.progressInMilliSeconds > 0
    ) {
        return action.payload.progressInMilliSeconds / 1000;
    }
    return progressInMillisecondsBookmark(store, action.payload.audioUri);
}

function progressInMillisecondsBookmark(store, id: string): number {
    const bookmarkSyncData = new BookmarkSyncData(
        new BookmarkDaoSessionStorage(store),
        new BookMarkOperationDaoSessionStorage(store),
        store
    );
    const bookmark = bookmarkSyncData.readWithBounds(id);
    if (bookmark && bookmark.progressMilliSeconds > 0) {
        return bookmark.progressMilliSeconds / 1000;
    }
    return 0;
}
