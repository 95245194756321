import ISkyfireLinkElement from '../../../ISkyfireLinkElement';
import ISkyfireContextMenuElement from '../../contextMenu/ISkyfireContextMenuElement';
import ISkyfireButtonElement from '../ISkyfireButtonElement';
import ISkyfireIconButtonElement from '../ISkyfireIconButtonElement';
import ISkyfireTextElement from '../ISkyfireTextElement';
import SkyfireItemTag from './SkyfireItemTag';

interface ISkyfireVerticalItemBase {
    image: string;
    placeHolder?: string;
    imageAltText: string;
    primaryLink: ISkyfireLinkElement;
    secondaryText: string;
    secondaryLink?: ISkyfireLinkElement;
    tertiaryText?: string;
    tertiaryLink?: ISkyfireLinkElement;
    actionIconName: string;
    actionIconLink?: ISkyfireLinkElement;
    iconButton?: ISkyfireIconButtonElement;
    isDisabled: boolean;
    hintIconName?: string;
    tags: SkyfireItemTag[];
    leftButton?: ISkyfireButtonElement;
    primaryText: ISkyfireTextElement;
    label?: string;
    contextMenu: ISkyfireContextMenuElement;
    rating?: number;
    reviewCount?: number | string;
    role?: string;
}

export const SQUARE_VERTICAL_ITEM =
    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.SquareVerticalItemElement';
export interface ISkyfireSquareVerticalItem extends ISkyfireVerticalItemBase {
    interface: typeof SQUARE_VERTICAL_ITEM;
}

export const CIRCLE_VERTICAL_ITEM =
    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.CircleVerticalItemElement';
export interface ISkyfireCircleVerticalItem extends ISkyfireVerticalItemBase {
    interface: typeof CIRCLE_VERTICAL_ITEM;
}

export const RECTANGLE_VERTICAL_ITEM =
    'Web.TemplatesInterface.v1_0.Touch.WidgetsInterface.RectangleVerticalItemElement';

export interface ISkyfireRectangleVerticalItem
    extends ISkyfireVerticalItemBase {
    interface: typeof RECTANGLE_VERTICAL_ITEM;
}

type ISkyfireVerticalItem =
    | ISkyfireSquareVerticalItem
    | ISkyfireRectangleVerticalItem
    | ISkyfireCircleVerticalItem;

export default ISkyfireVerticalItem;
