import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import * as Styles from './DetailHeader.scss';
import Widget from './widgets';
export default function DetailHeader(props) {
    const { headline, primaryText, secondaryText, tertiaryText, primaryTextBold, children, secondaryTextBold, widgetElement, handleSelected, } = props;
    useStyles(Styles);
    return (React.createElement("header", { className: Styles.header },
        React.createElement("h1", { className: "music-headline-3", id: "dialogHeader" }, headline),
        primaryText && (React.createElement("p", null,
            React.createElement("span", { className: "music-primary-text" }, primaryText))),
        secondaryText && (React.createElement("p", null,
            React.createElement("span", { className: "music-secondary-text" }, secondaryText))),
        tertiaryText && (React.createElement("p", null,
            React.createElement("span", { className: "music-tertiary-text" }, tertiaryText))),
        widgetElement && handleSelected && (React.createElement("div", { className: Styles.marginTb },
            React.createElement(Widget, { data: widgetElement, handleSelected: handleSelected }))),
        primaryTextBold && (React.createElement("p", null,
            React.createElement("span", { className: [Styles.primaryTextBold, 'music-primary-text'].join(' ') }, primaryTextBold))),
        secondaryTextBold && (React.createElement("p", null,
            React.createElement("span", { className: [Styles.primaryTextBold, 'music-primary-text'].join(' ') }, secondaryTextBold))),
        children));
}
