import { channelFor, GetDataEvent } from './utils';

const IDENTITY_CHANNEL = channelFor('identity');

export const GetTokenEvent: GetDataEvent<string> = {
  channel: IDENTITY_CHANNEL,
  topic: 'getToken',
};
export const GetMusicTerritoryEvent: GetDataEvent<string> = {
  channel: IDENTITY_CHANNEL,
  topic: 'getMusicTerritory',
};
export const GetSessionIdEvent: GetDataEvent<string> = {
  channel: IDENTITY_CHANNEL,
  topic: 'getSessionId',
};
