import ISkyfireMethod from '../../ISkyfireMethod';
import ISkyfireVisualListingButtonElement from './ISkyfireVisualListingButtonElement';
import ISkyfireVerticalItem from './items/ISkyfireVerticalItem';

export const PODCAST_LIBRARY_RECENTS_WIDGET =
    'Podcast.Web.WidgetsInterface.BookmarkedEpisodesWidgetElement';

export default interface IPodcastLibraryRecents {
    interface: typeof PODCAST_LIBRARY_RECENTS_WIDGET;
    header: string;
    button?: ISkyfireVisualListingButtonElement;
    items: ISkyfireVerticalItem[];
    fallbackMessage: string;
    onEndOfWidget: ISkyfireMethod[];
    onViewed: ISkyfireMethod[];
    uuid?: string;
}
