import React, { useEffect, useState } from 'react';
import { useDispatch, batch } from 'react-redux';
import IOSMShopHandler from './IOSMShopHandler';
import { isIOSmShop, isAndroidmShop } from '../utils/deviceUtils';
import { SET_MSHOP_BOTTOM_OVERRIDE, ENABLE_MSHOP_BOTTOM_OVERRIDE } from '../actions';
import { globals } from '../utils';
import { APP_BOTTOM_NAVBAR_UPDATE } from '../mash/MashEvents';
import { getCookie } from '../utils/getSkyfireHeaders';
// older mshop versions won't have the api available for getting the height
// fallback to hardcoding in those situations
const FALLBACK_BOTTOM_NAV_HEIGHT = 82;
export default function MShopHandler() {
    var _a;
    const dispatch = useDispatch();
    const [isGetBottomBarHeightAPIAvailable, setGetBottomBarHeightAPIAvailable] = useState(false);
    const [mShopBottomPadding, setBottomPadding] = useState(0);
    const isMshopDeviceType = isIOSmShop() ||
        (isAndroidmShop() && ((_a = globals.amznMusic.appConfig) === null || _a === void 0 ? void 0 : _a.isAndroidMShopBottomTabsAutoHideEnabled));
    const getAndSetBottomPadding = (obj) => {
        const { visibleHeight, bottomContainerHeight, secondaryContainerHeight } = obj;
        const bottomNavbarHeight = bottomContainerHeight !== null && bottomContainerHeight !== void 0 ? bottomContainerHeight : Number(visibleHeight) - Number(secondaryContainerHeight);
        setBottomPadding(Number(isAndroidmShop() ? bottomNavbarHeight : visibleHeight));
    };
    const handleBottomNavbarUpdate = (event) => {
        if ((event === null || event === void 0 ? void 0 : event.type) === APP_BOTTOM_NAVBAR_UPDATE && (event === null || event === void 0 ? void 0 : event.detail)) {
            getAndSetBottomPadding(event.detail);
        }
    };
    /**
     * Getting the height of the bottom nav + any secondary bars
     * https://w.amazon.com/bin/view/NavX/navx-core/BottomTabs/APIs/
     * Since the GetBottomBarHeight is available only in and above mShop version 21.11.0,
     * the fallback is to use the cookie approach already available.
     */
    useEffect(() => {
        var _a, _b;
        if (isMshopDeviceType) {
            (_a = window.P) === null || _a === void 0 ? void 0 : _a.when(isIOSmShop() ? 'ConfigChromeFramework' : 'MShopChrome').execute((ext) => {
                if (ext.getBottomBarsHeight) {
                    ext.getBottomBarsHeight({
                        successCallback(result) {
                            getAndSetBottomPadding(result);
                            setGetBottomBarHeightAPIAvailable(true);
                        },
                        failCallback(result) {
                            setGetBottomBarHeightAPIAvailable(false);
                            console.log('FAILURE: Failed to get bottom bar height: ', result);
                        },
                    });
                }
                else {
                    setGetBottomBarHeightAPIAvailable(false);
                }
            });
            /**
             * This is for when the bottom navbar autohide is enabled, which makes the
             * navbar hide/show during scroll
             */
            (_b = window.P) === null || _b === void 0 ? void 0 : _b.when('mash').execute((mash) => {
                mash.addEventListener(APP_BOTTOM_NAVBAR_UPDATE, handleBottomNavbarUpdate);
            });
        }
        return () => {
            var _a;
            if (isMshopDeviceType) {
                (_a = window.P) === null || _a === void 0 ? void 0 : _a.when('mash').execute((mash) => {
                    if (mash && mash.removeEventListener) {
                        mash.removeEventListener(APP_BOTTOM_NAVBAR_UPDATE, handleBottomNavbarUpdate);
                    }
                });
            }
        };
    }, []);
    /**
     * Fallback in case ConfigChromeFramework/MShopChrome is not available,
     * get the padding from the mash cookie
     */
    useEffect(() => {
        var _a;
        if (isMshopDeviceType && !isGetBottomBarHeightAPIAvailable) {
            const amznAppCookie = getCookie('amzn-app-ctxt');
            if (amznAppCookie) {
                try {
                    const decodedAppContext = decodeURIComponent(amznAppCookie);
                    if (/dm/.test(decodedAppContext) && decodedAppContext.indexOf('{') > -1) {
                        const AMZN_APP_CONTEXT = JSON.parse(decodedAppContext.slice(decodedAppContext.indexOf('{')));
                        /**
                         * https://w.amazon.com/bin/view/MASH/Application_Context_Protocol/1.0
                         * @{dm} - device metrics object
                         * @{pb} - padding bottom value of menubar at the bottom
                         */
                        const { pb = 0 } = (_a = AMZN_APP_CONTEXT === null || AMZN_APP_CONTEXT === void 0 ? void 0 : AMZN_APP_CONTEXT.dm) !== null && _a !== void 0 ? _a : {};
                        setBottomPadding(Number(pb));
                    }
                }
                catch (error) {
                    console.log(error);
                    setBottomPadding(FALLBACK_BOTTOM_NAV_HEIGHT);
                }
            }
        }
    }, [isGetBottomBarHeightAPIAvailable]);
    /**
     * Enable bottom override for mShop devices to avoid bottom fixed components getting obstructed
     * by mShop bottom navbar and searchbar being placed at the bottom.
     */
    useEffect(() => {
        batch(() => {
            dispatch({
                type: ENABLE_MSHOP_BOTTOM_OVERRIDE,
                payload: { enableMShopBottomOverride: true },
            });
            dispatch({
                type: SET_MSHOP_BOTTOM_OVERRIDE,
                payload: { mShopBottomOverride: mShopBottomPadding },
            });
        });
    }, [mShopBottomPadding]);
    /**
     * This works on RWP iOS mShop device type
     * It is required to handle iOS mShop related tasks
     */
    return isIOSmShop() ? React.createElement(IOSMShopHandler, null) : null;
}
