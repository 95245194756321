import { CLEAR, CLEAR_VIDEO, SET_MEDIA, SET_VIDEO_MEDIA, SET_PLAYBACK_START_OFFSET, } from '../actions/Playback';
const initialState = {
    onStarted: [],
    onResumed: [],
    onScrubbed: [],
    onPreScrubbed: [],
    onPostScrubbed: [],
    onPaused: [],
    onFinished: [],
    onStopped: [],
    onRebuffered: [],
    onCompletedThresholdElapsed: [],
    onError: [],
    bufferTime: 0,
    currentTime: 0,
    playbackStarted: false,
    playbackStartOffset: 0,
};
const reducer = {
    [CLEAR]: (state, action) => initialState,
    [CLEAR_VIDEO]: (state, action) => initialState,
    [SET_MEDIA]: (state, action) => (Object.assign(Object.assign({}, state), action.payload.events)),
    [SET_VIDEO_MEDIA]: (state, action) => (Object.assign(Object.assign({}, state), action.payload.events)),
    [SET_PLAYBACK_START_OFFSET]: (state, action) => (Object.assign(Object.assign({}, state), { playbackStartOffset: action.payload.playbackStartOffset })),
};
export function PlaybackEventsReducer(state = initialState, action) {
    if (reducer[action.type]) {
        return reducer[action.type](state, action);
    }
    return state;
}
