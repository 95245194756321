import { ClientState } from '../types/ClientState';

export class CompletedLastSyncTimeClientState extends ClientState {
    public lastSyncTime: number;

    public __type: string;

    constructor(lastSyncTime: number) {
        super();
        this.lastSyncTime = lastSyncTime;
        this.__type =
            'Podcast.CompletedInterface.v1_0#CompletedLastSyncTimeClientState';
    }
}
