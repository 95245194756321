import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import { dispatchSkyfireMethods } from '../../utils';
import HorizontalItem from './items/HorizontalItem';
import Button from './items/Button';
import * as Styles from './ContextMenuWidget.scss';
export default function ContextMenuWidget(props) {
    var _a;
    const [calledRender, setCalledRender] = useState(false);
    const dispatch = useDispatch();
    const template = useSelector((state) => state.TemplateStack.currentTemplate);
    const { header, items } = props.data;
    useStyles(Styles);
    if (!calledRender) {
        (_a = props.onContextMenuRender) === null || _a === void 0 ? void 0 : _a.call(props);
        setCalledRender(true);
    }
    const handleSelected = useCallback((methods) => {
        if (template) {
            dispatchSkyfireMethods(dispatch, template, methods);
        }
    }, [template]);
    const handleSelectedClose = useCallback((methods) => {
        if (template) {
            dispatchSkyfireMethods(dispatch, template, methods);
        }
        props.closeContextMenu();
    }, [template]);
    // If widget has a header, use variant style class w/ fixed width
    const styleClass = header ? Styles.contextMenuWidgetVariant : Styles.contextMenuWidget;
    return (React.createElement("div", { className: styleClass },
        header && (React.createElement("music-context-menu-widget-header", { primaryText: header.primaryText },
            header.leftButton && (React.createElement(Button, { data: header.leftButton, handleSelected: handleSelected, slot: "leftButton" })),
            header.rightButton && (React.createElement(Button, { data: header.rightButton, handleSelected: handleSelectedClose, slot: "rightButton" })))),
        items.map((item, idx) => (React.createElement(HorizontalItem, { data: item, handleSelected: handleSelected, role: "feeditem", index: idx, key: idx })))));
}
