const marketplaceIdToLocale = {
    ATVPDKIKX0DER: 'en_US',
    A1AM78C64UM0Y8: 'es_MX',
    A2EUQ1WTGCTBG2: 'en_CA',
    A2Q3Y263D00KWC: 'pt_BR',
    ART4WZ8MWBX2Y: 'en_US',
    A1F83G8C2ARO7P: 'en_GB',
    A1PA6795UKMFR9: 'de_DE',
    A13V1IB3VIYZZH: 'fr_FR',
    A1RKKUPIHCS9HS: 'es_ES',
    APJ6JRA9NG5V4: 'it_IT',
    A21TJRUUN4KGV: 'en_IN',
    A3K6Y4MI8GDYMT: 'en_US',
    A1VC38T7YXB528: 'ja_JP',
    A39IBJ37TRP1C6: 'en_AU',
    A15PK738MTQHSO: 'en_US',
};
export function getDefaultLanguage(marketplaceId) {
    return marketplaceIdToLocale[marketplaceId] || 'en_US';
}
