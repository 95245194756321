import { Dispatch } from 'redux';
import { enqueueSkyfireMethod } from '../actions/SkyfireMethodQueue';
import ISkyfireMethod from '../types/ISkyfireMethod';
import ISkyfireTemplate from '../types/templates/ISkyfireTemplate';

export function dispatchSkyfireMethods(
    dispatch: Dispatch,
    template: ISkyfireTemplate,
    methods: ISkyfireMethod[] = []
): void {
    methods.forEach((method) => {
        dispatch(
            enqueueSkyfireMethod({
                queue: method.queue,
                method: { ...method, owner: template.id },
            })
        );
    });
}
